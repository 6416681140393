import * as React from 'react';

import './DotCube.css';

type DotCubeType = {
  cubeSize: number;
  container: 'page' | 'modal';
};

const PAGE_SIZE_COEFF = 4 / 3;

const DotCube = ({ cubeSize, container }: DotCubeType) => {
  const dots = [];
  for (let i = 0; i < cubeSize * cubeSize; i++) {
    dots.push(<div key={i} className={`dot-${container}`} />);
  }

  return (
    <div
      style={{
        width: `${
          cubeSize * 15 * (container === 'page' ? PAGE_SIZE_COEFF : 1)
        }px`,
        height: `${
          cubeSize * 15 * (container === 'page' ? PAGE_SIZE_COEFF : 1)
        }px`,
      }}
      className={`dot-container-${container}`}
    >
      {dots}
    </div>
  );
};

export default DotCube;

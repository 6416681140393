import config from '../../../cep.config';
import { SelectItem } from '@autocut/components/atoms/SendLogFile/SendLogFile';
import { fs, os, path } from '@autocut/lib/cep/node';
const id = config.id;

import logLevel from '@autocut/types/logLevel.enum';
import { CURRENT_ENV, Env } from './currentEnv.utils';

export const MAX_MB_SIZE_TO_SEND = 25e4; //0.25MB
import { addBreadcrumb } from '@sentry/react';

import { getSentryLogLevel } from './sentry.utils';
import { manageError } from '@autocut/utils/manageError';

const LOG_NAME_REGEXP = /^[0-9]{4}-[0-9]{2}-[0-9]{2}.log/;

const getLogName = () => {
  const today = new Date().toISOString().split('T')[0];
  return `${today}.log`;
};

export function getLogPath(id: string): string {
  const file = getLogName();

  const homeDir = os.homedir();
  const platform = os.platform();
  let logPath;

  switch (platform) {
    case 'darwin': {
      logPath = path.join(homeDir, 'Library', 'Logs', id);
      break;
    }

    case 'win32': {
      logPath = path.join(homeDir, 'AppData', 'Roaming', id);
      break;
    }

    case 'linux': {
      logPath = path.join(homeDir, '.logs', id);
      break;
    }

    default:
      logPath = path.join(homeDir, '.logs', id);
      break;
  }

  try {
    if (!fs.existsSync(logPath)) {
      fs.mkdirSync(logPath);
    }
  } catch (err: any) {
    console.log(logLevel.crit, `Failed command:  + fs.mkdirSync(${logPath})`);
    console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
    manageError({
      error: err,
      disableModal: true,
    });
  }

  return path.join(logPath, file);
}

export const getLogFolder = () => {
  const homeDir = os.homedir();
  const platform = os.platform();
  let logPath;

  switch (platform) {
    case 'darwin': {
      logPath = path.join(homeDir, 'Library', 'Logs', id);
      break;
    }

    case 'win32': {
      logPath = path.join(homeDir, 'AppData', 'Roaming', id);
      break;
    }

    case 'linux': {
      logPath = path.join(homeDir, '.logs', id);
      break;
    }

    default:
      logPath = path.join(homeDir, '.logs', id);
      break;
  }

  try {
    if (!fs.existsSync(logPath)) {
      fs.mkdirSync(logPath);
    }
  } catch (err: any) {
    console.log(logLevel.crit, `Failed command:  + fs.mkdirSync(${logPath})`);
    console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
    manageError({
      error: err,
      disableModal: true,
    });
  }

  return logPath;
};

export function clearPreviousLogs() {
  const logFolder = getLogFolder();
  const files = fs.readdirSync(logFolder);

  const todayLogFileName = getLogName();

  files.forEach(file => {
    //We don't want to remove today's log file nor the persistenceStorage.log file
    if (LOG_NAME_REGEXP.test(file) && file !== todayLogFileName) {
      const filePath = path.join(logFolder, file);
      const stats = fs.statSync(filePath);

      if (stats.isFile()) {
        fs.unlinkSync(filePath);
      }
    }
  });
}

export function createLogger(logPath: string) {
  if (!logPath)
    return (
      loggerName: string,
      level: logLevel,
      message: string,
      objects = {}
    ) => console.log(loggerName, level, message, objects);

  return (
    loggerName: string,
    level: logLevel,
    message: string,
    objects = {}
  ) => {
    const log = {
      loggerName: loggerName,
      level: level,
      time: new Date().getTime(),
      message: message,
      objects: objects,
    };
    try {
      if (CURRENT_ENV === Env.Development) {
        if (log?.level === logLevel.warn)
          console.warn(log.loggerName, log.message, log.objects);
        else if (log?.level > logLevel.warn)
          console.error(log.loggerName, log.message, log.objects);
        else console.debug(log.loggerName, log.message, log.objects);
      } else {
        addBreadcrumb({
          message: log.message,
          category: log.loggerName,
          data: log.objects,
          level: getSentryLogLevel(log?.level),
        });
      }
      fs.writeFileSync(logPath, safeJSONStringify(log) + '\n', { flag: 'a' });
    } catch (err: any) {
      console.log(
        logLevel.crit,
        `Failed command:  + fs.writeFileSync(${logPath}, ${safeJSONStringify(
          log
        )} + '\n', { flag: 'a' });`
      );
      console.log(logLevel.crit, 'Open ErrorModal for : ' + err.message);
      manageError({
        error: err,
        disableModal: true,
      });
    }
  };
}

const getAllFilesFromDir = (dir: string) => {
  const logFileNameRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}.log/;
  const logFileList: SelectItem[] = [];

  const files = fs.readdirSync(dir);
  const logFiles = files.filter((file: string) => file.match(logFileNameRegex));

  logFiles.forEach((file: string) => {
    logFileList.push({
      value: path.join(dir, file),
      label: file.split('.')[0],
    });
  });

  return logFileList.sort((a, b) => (a.label > b.label ? -1 : 1));
};

const getLogFileSize = (path: string): number => {
  return fs.statSync(path).size;
};

const chunkLogFile = (filePath: string) => {
  const chunks: string[] = [];
  const log = fs.readFileSync(filePath, 'utf8').split('\n') as string[];

  let chunk: string[] = [];
  let lastIndex = 0;
  for (let index = 100; index < log.length; index += 100) {
    const element = log.slice(lastIndex, index);

    chunk.push(...element);

    const size = chunk
      .slice()
      .reduce((acc, line) => acc + new TextEncoder().encode(line).length, 0);

    if (size >= MAX_MB_SIZE_TO_SEND) {
      chunk.splice(-100);
      index -= 100;
      chunks.push(chunk.join('\n'));
      chunk = [];
    }

    if (index + 100 >= log.length) {
      chunks.push(log.slice(-(log.length - lastIndex)).join('\n'));
    }

    lastIndex = index;
  }

  return chunks.length > 0 ? chunks : [chunk.join('\n')];
};

const getLogsSinceLastLaunch = () => {
  const log = fs.readFileSync(logPath, 'utf8').split('\n') as string[];

  const chunks: string[] = [];
  let lastIndex = 0;
  let size = 0;

  do {
    if (lastIndex >= log.length) break;

    const element = log.slice(lastIndex, lastIndex + 1);
    chunks.push(...element);

    size = chunks
      .slice()
      .reduce((acc, line) => acc + new TextEncoder().encode(line).length, 0);

    lastIndex++;
  } while (size < MAX_MB_SIZE_TO_SEND);

  return chunks;
};

// safely handles circular references
const safeJSONStringify = (obj: any, indent = 2) => {
  let cache: any[] = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  );
  cache = [];
  return retVal;
};

export let logError: string;
export const logPath = getLogPath(id);
export const logger = createLogger(logPath);
export {
  chunkLogFile,
  getAllFilesFromDir,
  getLogFileSize,
  getLogsSinceLastLaunch,
  safeJSONStringify,
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';

export const saveAndPrepareSequenceSettings = async (nbTry = 0) => {
  try {
    const userSequenceVideoDisplayFormat =
      autocutStoreVanilla().sequence.infos?.settings.videoDisplayFormat;
    setAutocutStore(
      'onGoingProcess.sequenceParams.userSequenceVideoDisplayFormat',
      userSequenceVideoDisplayFormat
    );

    // Configure l'affichage du timecode de la séquence en frame.
    // Premiere Pro a une gestion particulière des timecodes des séquences, dépendante du framerate de la séquence.
    // Cette opération permet de ne pas s'en soucier et de pouvoir convertir directement un timecode classique (ex: en seconde) en un numéro de frame.
    // C'est particulièrement utile lorsque l'on travaille avec des séquences dont le framerate n'est pas entier.
    await evalTS('setSequenceVideoDisplayFormat', 109);

    return userSequenceVideoDisplayFormat;
  } catch (err: any) {
    try {
      if (nbTry < 3) {
        await saveAndPrepareSequenceSettings(nbTry + 1);
      }
    } catch (err: any) {
      throw new IncrementalError(err, 'saveAndPrepareSequenceSettings');
    }
  }
};

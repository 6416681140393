import React from 'react';
import { Box, BoxProps } from './Variants/Box/Box';
import { Switch, SwitchProps } from './Variants/Switch/Switch';

export type CheckBoxCommonProps = {
  size: number;
  checked: boolean;
  onChange?: (checked: boolean) => void;
};

export type CheckBoxProps = (
  | ({
      variant?: 'box';
    } & BoxProps)
  | ({ variant: 'switch' } & SwitchProps)
) &
  CheckBoxCommonProps;

export const CheckBox = ({ variant = 'box', ...props }: CheckBoxProps) => {
  if (variant === 'box') {
    return <Box {...props} />;
  }

  if (variant === 'switch') {
    return <Switch {...props} />;
  }

  return null;
};

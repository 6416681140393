import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { VscDebugBreakpointUnsupported } from 'react-icons/vsc';

import React from 'react';
import { Button } from '@autocut/components/atoms';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { TbLicense } from 'react-icons/tb';
import { autocutApi, sendStats } from '@autocut/utils';
import { StatType } from '@autocut/types/StatType.enum';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const ConsentModal = () => {
  const intl = useIntl();

  const closeModal = () => setAutocutStore('ui.openedModalName', '');

  const sendStat = async (accepted: boolean) => {
    await sendStats({
      type: StatType.CONSENT_ASKED,
      value: `${accepted}`,
    });
  };

  const accept = async () => {
    await autocutApi.post('statistics/consent', {});
    await sendStat(true);
    setAutocutStore('user.consentAsked', true);
    closeModal();
  };

  const reject = async () => {
    setAutocutStore('user.consentAsked', true);
    await sendStat(false);
    closeModal();
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'consent_modal_title',
        defaultMessage: 'Enhance AutoCut with your AutoCut Insights',
      })}
      icon={<TbLicense size={36} />}
      footer={
        <FlexContainer flexDirection="column" gap={8}>
          <Button onClickFunction={reject} buttonType="secondary">
            <TranslatedMessage
              id={'consent_modal_cta_reject'}
              defaultMessage="No, keep my AutoCut usage anonymous"
            />
          </Button>
          <Button onClickFunction={accept}>
            <TranslatedMessage
              id={'consent_modal_cta_accept'}
              defaultMessage="Yes, I'm in — Let's improve AutoCut !"
            />
          </Button>
        </FlexContainer>
      }
    >
      <p>
        <TranslatedMessage
          id="consent_modal_message"
          defaultMessage="We're dedicated to enhancing AutoCut based exclusively on how you use our features, like the frequency of AutoCut Podcast edits.{br}No other data is collected — only AutoCut usage — your broader activity remains your own.{br}Are you open to allowing us to use this limited data and potentially contact you to get your feedbacks and so build a better AutoCut together?{br}{br}More information <a>here</a>."
          valuePamareters={{
            href: 'https://www.freeprivacypolicy.com/live/0b281d9b-90f0-49be-baff-3d275e07432c',
          }}
        />
      </p>
    </Modal>
  );
};

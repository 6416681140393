import * as React from 'react';
import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import LogoUrl from '@autocut/assets/images/logo.svg';
const Logo = new URL(LogoUrl, import.meta.url).href;
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import {
  getSeenTrialPromo,
  getTrialPromoEnd,
} from '@autocut/utils/localStorage.utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import { KebabMenu } from '../../atoms/KebabMenu';
import { LicenceInfos } from '../../atoms/LicenceInfos/LicenceInfos';
import { PromoBanner } from '../../atoms/PromoBanner/PromoBanner';
import { SelectLang } from '../../atoms/SelectLang/SelectLang';
import css from './header.module.scss';
import { CURRENT_ENV, Env } from '@autocut/utils/currentEnv.utils';
import { Button } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import * as json from '../../../../../package.json';

export const Header = () => {
  const { isLogged, license } = useAutoCutStore(state => ({
    isLogged: state.user.isLogged,
    license: state.user.license,
  }));
  const [showTrialPromo, setShowTrialPromo] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { logMessage } = useLogger('Header');

  const showDevButton = CURRENT_ENV && CURRENT_ENV !== Env.Production;

  useEffect(() => {
    if (!isLogged || license === undefined) return;

    logMessage(logLevel.info, 'Header mounted.');

    if (license.type === 'trial') {
      logMessage(logLevel.notice, 'Licence is trial');
      const seenTrialPromo = getSeenTrialPromo();
      if (seenTrialPromo) {
        logMessage(logLevel.notice, 'Promo has been started');
        const trialPromoEnd = getTrialPromoEnd();
        if (trialPromoEnd && new Date().getTime() < trialPromoEnd) {
          logMessage(logLevel.notice, 'Promo is still valid');
          setShowTrialPromo(true);
        }
      }
    }
  }, [isLogged, license]);

  return (
    <>
      {isLogged && showTrialPromo ? (
        <PromoBanner setShowTrialPromo={setShowTrialPromo} />
      ) : (
        ''
      )}
      <FlexContainer
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        gap={8}
        className={css.container}
      >
        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          onClick={() => {
            setAutocutStore('ui.process.isPaused', false);
            setAutocutStore('ui.process.isProcessing', false);
            navigate('/homepage');
          }}
          className={css.applicationNameContainer}
          gap={8}
        >
          <img src={Logo} className={css.autocutLogo} alt="autocut_logo" />
          <p className={css.applicationName}>Autocut</p>
          <p className={css.applicationVersion}>v{json.version}</p>
        </FlexContainer>

        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          gap={8}
        >
          {showDevButton && (
            <Button
              size="sm"
              compact
              buttonType={
                location.pathname === '/dev' ? 'primary' : 'secondary'
              }
              color="#e65019"
              onClickFunction={() => {
                if (location.pathname === '/dev') {
                  navigate(-1);
                } else {
                  navigate('/dev');
                }
              }}
            >
              {'</>'}
            </Button>
          )}
          {isLogged && <LicenceInfos />}
          <SelectLang />
          <KebabMenu />
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

import * as React from 'react';

import { PodcastFooterButton } from '@autocut/components/atoms/PodcastFooterButton';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { CameraTimeStep } from '@autocut/components/steps/CamTimeStep';
import { CamerasStep } from '@autocut/components/steps/CamerasStep/CamerasStep';
import { PodcastSilenceStep } from '@autocut/components/steps/PodcastSilenceStep/PodcastSilenceStep';
import { SpeakersStep } from '@autocut/components/steps/SpeakersStep';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { handlePodcastProcess } from '@autocut/utils/podcast/handlePodcastProcess';
import { sequenceHasEnoughClipsForPodcast } from '@autocut/utils/podcast/hasEnoughClips';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useEffect, useMemo } from 'react';
import { BiMicrophone } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import { ModeWrapper } from '../ModeWrapper';

import podcastSelectionImageUrl from '@autocut/assets/images/selectionScreen/podcast.png';
const podcastSelectionImage = new URL(podcastSelectionImageUrl, import.meta.url)
  .href;

export const PodcastMode = () => {
  const intl = useIntl();
  const sequenceInfos = useAutoCutStore(state => state.sequence.infos);
  useLogger('PodcastMode');

  const hasEnoughClips = useMemo(sequenceHasEnoughClipsForPodcast, [
    sequenceInfos,
  ]);

  // We don't want to process unused tracks if the user change sequences
  useEffect(() => {
    if (sequenceInfos && hasEnoughClips) {
      const availableAudioTracks = sequenceInfos.audioTracks?.filter(
        track => track.nbClips > 0
      );
      const defaultSpeakers = AutocutModes.Podcast.defaultParameters.speakers;
      defaultSpeakers[0].audio.value = `${availableAudioTracks[0].index}`;
      defaultSpeakers[0].audio.label = `${intl.formatMessage({
        id: 'text_podcast_track',
        defaultMessage: 'Track',
      })} A${availableAudioTracks[0].index + 1}`;
      defaultSpeakers[1].audio.value = `${availableAudioTracks[1].index}`;
      defaultSpeakers[1].audio.label = `${intl.formatMessage({
        id: 'text_podcast_track',
        defaultMessage: 'Track',
      })} A${availableAudioTracks[1].index + 1}`;

      const availableVideoTracks = sequenceInfos.videoTracks?.filter(
        track => track.nbClips > 0
      );
      const defaultCameras = AutocutModes.Podcast.defaultParameters.cameras;
      defaultCameras[0].value = `${availableVideoTracks[0].index}`;
      defaultCameras[0].label = `${intl.formatMessage({
        id: 'text_podcast_track',
        defaultMessage: 'Track',
      })} V${availableVideoTracks[0].index + 1}`;
      defaultCameras[1].value = `${availableVideoTracks[1].index}`;
      defaultCameras[1].label = `${intl.formatMessage({
        id: 'text_podcast_track',
        defaultMessage: 'Track',
      })} V${availableVideoTracks[1].index + 1}`;

      setAutocutStore('ui.parameters.podcast.cameras', defaultCameras);
      setAutocutStore('ui.parameters.podcast.speakers', defaultSpeakers);
    } else {
      setAutocutStore(
        'ui.parameters.podcast.cameras',
        AutocutModes.Podcast.defaultParameters.cameras
      );
      setAutocutStore(
        'ui.parameters.podcast.speakers',
        AutocutModes.Podcast.defaultParameters.speakers
      );
    }
  }, [hasEnoughClips, sequenceInfos]);

  return (
    <ModeWrapper
      Icon={BiMicrophone}
      text={'Auto Podcast'}
      displayCondition={hasEnoughClips}
      invalidSelectionScreen={{
        titleId: 'podcast_not_available_title',
        titleDefaultMessage:
          'Select a sequence with at least 2 video and audio tracks',
        texts: [
          {
            id: 'podcast_not_available',
            defaultMessage:
              'AutoCut Podcast is not available for your sequence',
          },
        ],
        linkId: 'podcast_link_tuto',
        selectionImage: podcastSelectionImage,
      }}
    >
      <div id="podcast-step1">
        <SpeakersStep />
      </div>
      <CamerasStep />
      <CameraTimeStep />
      <PodcastSilenceStep />
      <ModeFooter
        renderButton={(isLoading, isDisabled) => (
          <PodcastFooterButton
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClickFunction={async () => {
              await handlePodcastProcess(intl);
              await handleTrialTask('podcast');
            }}
          />
        )}
      />
    </ModeWrapper>
  );
};

import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import styleConfig from '@autocut/components/styleconfig';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';

export const NoFFMPEG = () => {
  const [hover, setHover] = React.useState<boolean>(false);

  const style = {
    container: {
      width: '100vw',
      height: '100vh',
    },
    div: {
      textAlign: 'center' as const,
    },
    p: {
      fontSize: '16px',
      marginTop: '100px',
      marginBottom: '10px',
    },
    a: {
      fontSize: '16px',
      color: styleConfig.primaryColor,
      textDecorationLine: 'none',
      cursor: hover ? 'pointer' : '',
    },
  };

  return (
    <div>
      <div style={style.div}>
        <p style={style.p}>
          <TranslatedMessage
            id="text_NoFFMPEG"
            defaultMessage="There hase been an issue with FFMPEG, please contact the support"
          />
        </p>
        <a
          style={style.a}
          onClick={() => openLinkInBrowser('mailto:contact@autocut.fr')}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          contact@autocut.fr
        </a>
      </div>
    </div>
  );
};

import { autocutStoreVanilla } from '../zustand';

export const sequenceHasEnoughClipsForPodcast = (): boolean => {
  const nbAudioTrackFilled =
    autocutStoreVanilla().sequence.infos?.audioTracks.filter(
      track => track.nbClips > 0
    ).length || 0;

  const nbVideoTrackFilled =
    autocutStoreVanilla().sequence.infos?.videoTracks.filter(
      track => track.nbClips > 0
    ).length || 0;

  return nbAudioTrackFilled >= 2 && nbVideoTrackFilled >= 2;
};

import {
  AvailableProviders,
  NeedingTranscriptionModes,
} from '@autocut/types/transcription';
import { autocutStoreVanilla } from '../../zustand';
import { LANGUAGE_TO_MODEL } from '../languageToModel';
import { isRepeatProviderAvailable } from './repeat';
import { isBrollProviderAvailable } from './broll';
import { isSwearWordsProviderAvailable } from './swearWords';

const checkAvailableProvider: AvailableProviders = {
  repeat: isRepeatProviderAvailable,
  broll: isBrollProviderAvailable,
  swear_word: isSwearWordsProviderAvailable,
};

export const isProviderAvailable = (
  mode: NeedingTranscriptionModes,
  usedModel = 0
) => {
  const languageOfTranscription =
    autocutStoreVanilla().ui.parameters[mode].languageOfTranscription;
  const provider =
    LANGUAGE_TO_MODEL[languageOfTranscription.value][usedModel].provider;

  const checkAvailableProviderFunction = checkAvailableProvider[mode];

  if (!checkAvailableProviderFunction) {
    return {
      isAvailable: true,
    };
  }

  return checkAvailableProviderFunction(provider);
};

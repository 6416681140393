/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-this-alias */

import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { uuid4 } from '@sentry/utils';

//@ts-ignore
Function.prototype.clone = function () {
  const that = this;
  const temp = function temporary() {
    //@ts-ignore
    return that.apply(this, arguments);
  };
  for (const key in this) {
    if (this.hasOwnProperty(key)) {
      //@ts-ignore
      temp[key] = this[key];
    }
  }
  return temp;
};

const isPromise = <T>(value: any): value is Promise<T> => 'then' in value;

export type CEPProcess = {
  (this: CEPProcess, ...args: any): any;
  id: string;
  type?: string;
  startTime?: Date;
};

/**
 * Manages the execution of CEP (Common Extensibility Platform) processes.
 */
export class CEPProcessManager {
  private static instance: CEPProcessManager;
  isRunning = false;
  processQueue: [CEPProcess, Array<any>][] = [];
  finishedProcessQueue: [CEPProcess['id'], any][] = [];

  /**
   * Returns the singleton instance of CEPProcessManager.
   * @returns The singleton instance of CEPProcessManager.
   */
  public static getInstance(): CEPProcessManager {
    if (!CEPProcessManager.instance) {
      CEPProcessManager.instance = new CEPProcessManager();
    }
    return CEPProcessManager.instance;
  }

  /**
   * Resets the process manager by clearing the process queue and finished process queue.
   */
  public static reset() {
    const instance = CEPProcessManager.getInstance();
    instance.processQueue = [];
    instance.finishedProcessQueue = [];
    instance.isRunning = false;
  }

  /**
   * Adds a new process to the process queue and runs it if no process is running.
   * @param handler - The process handler function.
   * @param args - The arguments to be passed to the process handler function.
   */
  public addProcess<Args extends Array<any>>(
    handler: {
      (this: CEPProcess, ...args: Args): any | Promise<any>;
      type?: string;
    },
    ...args: Args
  ) {
    const id = uuid4();

    //@ts-ignore
    const process = handler.clone() as CEPProcess;
    process.id = id;

    this.processQueue.push([process, args]);
    setTimeout(() => this.runProcess(), 0);

    return process.id;
  }

  /**
   * Adds a process and waits for it to complete.
   *
   * @param {...Parameters<CEPProcessManager['addProcess']>} args - The arguments to pass to the 'addProcess' method.
   * @returns {Promise<ReturnType<(typeof args)[0]>>} - A promise that resolves to the return value of the 'addProcess' method.
   */
  public async addProcessWithCallback(
    ...args: Parameters<CEPProcessManager['addProcess']>
  ) {
    return (
      callback: (res: ReturnType<(typeof args)[0]>) => void | Promise<void>,
      options?: { callOnErrors?: boolean }
    ) => {
      const id = this.addProcess(...args);

      setTimeout(async () => {
        const res = (
          (await this.waitForProcess(id)) as ReturnType<(typeof args)[0]>
        ).catch(async (e: any) => {
          if (options?.callOnErrors) {
            await callback(e);
          }
        });
        await callback(res);
      }, 0);
    };
  }

  /**
   * Adds a process and waits for it to complete.
   *
   * @param {...Parameters<CEPProcessManager['addProcess']>} args - The arguments to pass to the 'addProcess' method.
   * @returns {Promise<ReturnType<(typeof args)[0]>>} - A promise that resolves to the return value of the 'addProcess' method.
   */
  public async addProcessAndWait(
    ...args: Parameters<CEPProcessManager['addProcess']>
  ) {
    const id = this.addProcess(...args);
    return (await this.waitForProcess(id)) as ReturnType<(typeof args)[0]>;
  }

  /**
   * Runs the next process at the top of the process queue.
   */
  public runProcess() {
    if (this.isRunning || this.processQueue[0]?.[0].startTime) return;
    this.isRunning = this.processQueue.length > 0; //Fix for hot reload

    const processInfo = this.processQueue[0];
    if (processInfo) {
      const [process, args] = processInfo;
      process.startTime = new Date();

      const result = process.call(process, ...args);

      if (isPromise(result)) {
        void result
          .then(result => {
            this.finishedProcessQueue.push([process.id, result]);
            this.endProcess(process);
          })
          .catch((e: any) => {
            this.finishedProcessQueue.push([process.id, e]);
            this.endProcess(process);
          });
      } else {
        this.finishedProcessQueue.push([process.id, result]);
        this.endProcess(process);
      }
    }
  }

  /**
   * Ends the specified process and runs the next process in the queue.
   * @param process - The process to end.
   */
  private endProcess(process: CEPProcess) {
    this.isRunning = false;
    const processInfo = this.processQueue[0];
    if (processInfo && processInfo[0].id === process.id)
      this.processQueue.shift();

    setTimeout(() => this.runProcess(), 0);
  }

  public isProcessExist(processId: CEPProcess['id']) {
    const onGoindProcess = this.processQueue.find(
      process => process?.[0].id === processId
    );
    if (onGoindProcess) return true;
    const finishedProcess = this.finishedProcessQueue.find(
      process => process[0] === processId
    );
    if (finishedProcess) return true;
    return false;
  }

  /**
   * Waits for the specified process to complete.
   * @param processId - The ID of the process to wait for.
   * @returns A promise that resolves when the process is no longer in the queue.
   */
  public async waitForProcess(processId: CEPProcess['id']) {
    if (!this.isProcessExist(processId)) {
      console.error({
        processId,
        processQueue: this.processQueue,
        finishedProcessQueue: this.finishedProcessQueue,
      });
      throw new IncrementalError(
        'Process not found',
        'CEPProcessManager.waitForProcess'
      );
    }

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (!this.isProcessExist(processId)) {
          clearInterval(interval);
          reject(
            new IncrementalError(
              'Process canceled',
              'CEPProcessManager.waitForProcess'
            )
          );
        }

        if (!this.processQueue.find(process => process?.[0].id === processId)) {
          clearInterval(interval);
          const result = this.finishedProcessQueue.find(
            processResult => processResult[0] === processId
          )?.[1];
          if (result instanceof Error || result instanceof IncrementalError) {
            reject(result);
          } else {
            resolve(result);
          }
        }
      }, 300);
    });
  }

  /**
   * Removes the specified process from the process queue.
   * @param processId - The ID of the process to remove.
   */
  public removeProcess(processId: CEPProcess['id']) {
    this.processQueue = this.processQueue.filter(
      process => process?.[0].id !== processId
    );

    setTimeout(() => this.runProcess(), 0);
  }

  /**
   * Removes all processes of the specified type from the process queue.
   * @param type - The type of processes to remove.
   */
  public removeProcesses(type: CEPProcess['type']) {
    if (this.processQueue[0]?.[0].type === type) {
      this.isRunning = false;
    }
    this.processQueue = this.processQueue.filter(
      process => process && process[0].type !== type
    );

    setTimeout(() => this.runProcess(), 0);
  }

  /**
   * Checks if the specified process is the currently active process.
   * @param process - The process to check.
   * @throws Error if the process is not the active process.
   */

  public static check(process: CEPProcess) {
    if (
      process.id !== CEPProcessManager.getInstance().processQueue[0]?.[0].id
    ) {
      throw new IncrementalError('Process canceled', 'CEPProcessManager.check');
    }
  }
}

//Example
/*
export const getSequenceInfos = async function (
  this: CEPProcess,
  ...args: any
) {
  console.log('Big task', args);
  CEPProcessManager.check(this); //Will stop if the process has been canceled
  console.log('Another big task');
  CEPProcessManager.check(this);

  await new Promise(resolve =>
    setTimeout(() => {
      console.log('await');
      resolve(true);
    }, 1000)
  );

  console.log('End');
  this.manager.endProcess(this);
};
getSequenceInfos.type = 'selection';

CEPProcessManager.getInstance().addProcess(getSequenceInfos);
CEPProcessManager.getInstance().removeProcess(this.id);
CEPProcessManager.getInstance().removeProcess('selection');
*/

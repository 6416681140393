import { CustomSelectText } from '@autocut/components/atoms/CustomSelectText/CustomSelectText';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { resourcesConfigs } from '@autocut/enums/resources.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { getRessourceSignedUrl } from '@autocut/utils/downloadableRessources.utils';
import {
  BleepFileEnum,
  bleepSounds,
} from '@autocut/utils/swearWords/bleepFile.enum';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect, useState } from 'react';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { useIntl } from 'react-intl';

const BleepSoundChoiceStep = () => {
  const intl = useIntl();

  useEffect(() => {
    bleepSounds[0].label = intl.formatMessage({
      id: 'swear_words_mute',
      defaultMessage: 'Mute',
    });
  }, [intl]);

  const { bleepFile } = useAutoCutStore(
    state => state.ui.parameters.swear_word
  );

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  const playBleep = async () => {
    if (bleepFile === BleepFileEnum.Muted) return;
    if (audio) {
      audio.pause();
      setAudio(null);
    } else {
      const soundUrl = await getRessourceSignedUrl(
        resourcesConfigs[bleepFile].fileName
      );

      setAudio(() => {
        const tempAudio = new Audio(soundUrl);
        tempAudio.play().catch(() => setAudio(null));
        tempAudio.addEventListener('ended', () => setAudio(null));
        return tempAudio;
      });
    }
  };

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'bleep_selection_step_title_helper',
        defaultMessage: 'Select bleep sound effect',
      })}
      alwaysOpen
    >
      <FlexContainer gap="16px" alignItems="center" justifyContent="center">
        {bleepFile ? (
          <FaRegCirclePlay
            color={audio ? '#e64e19' : 'inherit'}
            style={{ cursor: 'pointer' }}
            size={24}
            onClick={playBleep}
          />
        ) : (
          <FaRegCirclePlay color={'grey'} size={24} />
        )}

        <CustomSelectText<BleepFileEnum>
          isSearchable
          options={bleepSounds}
          handleSelectChange={option => {
            if (option) {
              setAutocutStore(
                'ui.parameters.swear_word.bleepFile',
                option.value
              );
            }
          }}
          initialValue={
            bleepSounds.find(bleep => bleep.value === bleepFile) ||
            bleepSounds[0]
          }
        />
      </FlexContainer>
    </StepLayout>
  );
};

export default BleepSoundChoiceStep;

import { ModalType } from '@autocut/enums/modals.enum';
import { autocutApi } from '@autocut/utils/http.utils';
import {
  getLastSeenModalDate,
  setLastSeenModalDate,
} from '@autocut/utils/localStorage.utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import { AxiosResponse } from 'axios';

export const loadStartUpModals = async () => {
  const lastSeenModalDate = getLastSeenModalDate();

  const result = await autocutApi.get(
    `/launch-modals/launch-modals-since-date`,
    {
      params: {
        last: lastSeenModalDate,
      },
    }
  );

  if (!result) {
    return;
  }

  const { data: modals } = result as AxiosResponse;

  if (modals.length > 0) {
    setLastSeenModalDate(modals?.[0]?.updatedAt);
    setAutocutStore('ui.startUpModals', modals);
    setAutocutStore('ui.openedModalName', ModalType.StartUp);
  }
};

import { ffmpegSupportedFormats } from '@autocut/enums/ffmpeg.enum';
import { child_process, fs } from '@autocut/lib/cep/node';
import { ExportedAudioElement } from '@autocut/types/ExportedAudio';
import { FFmpegCommandBuilder } from '@autocut/types/FFmpegCommandBuilder';
import { SilenceParams } from '@autocut/types/SilenceParams';
import logLevel from '@autocut/types/logLevel.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { addBreadcrumb } from '@sentry/react';
import { logger } from '../logger';
import { getParametersForMode } from '../parameters.utils';

/***
 * Calcule les silences d'un fichier vidéo ou audio
 * @example
 * // return "C:/Chemin/Vers/Temp/AutoCut/e42e5e44ac1e64ad4db8bd54a44a624b.png"
 * getVideoSilencesIntervals("C:/Chemin/Vers/Le/Fichier.mp4", 0, 150)
 * @param {string} filepath - Chemin vers le fichier
 * @returns {Promise<{ tabStart: number[]; tabEnd: number[] }>} - Liste des débuts et fins des silences
 */
const getVideoSilencesIntervals = async (
  exportedAudio: ExportedAudioElement
) => {
  try {
    const parameters = getParametersForMode() as SilenceParams;
    const { end, start, path } = exportedAudio;

    const duration = end - start;
    const { noiseLevel } = parameters;

    const tParam = duration ? duration : null;

    const tabStart: number[] = [];
    const tabEnd: number[] = [];

    let data = '';
    try {
      const FFMPEG_SILENCE_DETECTION_ARGS = new FFmpegCommandBuilder('spawn')
        .start(0)
        .duration(tParam)
        .input(path)
        .ignoreVideo()
        .getSilenceDetectionFilter(false, false, {
          noiseLevel: String(noiseLevel),
          numStream: 1,
          channel: 'FL',
          channel_layout: 'stereo',
        })
        .noOutput()
        .splitCommand();

      const FFMPEG_PATH = FFMPEG_SILENCE_DETECTION_ARGS.shift()!;

      logger(
        'FFmpegUtils',
        logLevel.info,
        `Launching ${[FFMPEG_PATH, ...FFMPEG_SILENCE_DETECTION_ARGS].join(' ')}`
      );

      const FFMPEG_PROCESS = child_process.spawnSync(
        FFMPEG_PATH,
        FFMPEG_SILENCE_DETECTION_ARGS,
        { shell: false }
      );
      logger(
        'FFmpegUtils',
        logLevel.info,
        `Command ${[FFMPEG_PATH, ...FFMPEG_SILENCE_DETECTION_ARGS].join(
          ' '
        )} ended successfully`
      );

      addBreadcrumb({
        category: 'FFmpegUtils',
        level: 'debug',
        data: FFMPEG_PROCESS,
      });
      data = FFMPEG_PROCESS.stderr.toString();

      const isMissMatchError = data
        .toLowerCase()
        .includes('matches no streams'); //multi audio matching error
      const isCommandError = data.toLowerCase().includes('error'); // command error

      if (isMissMatchError || isCommandError) {
        const lastline = data.split('\n')[data.split('\n').length - 1];
        throw new IncrementalError(
          `Error during silences retrieval, FFMPEG_PATH ${FFMPEG_PATH}, FFMPEG_SILENCE_DETECTION_ARGS ${FFMPEG_SILENCE_DETECTION_ARGS}, data ${data}, lastline ${lastline}`,
          'getVideoSilencesIntervals'
        );
      }
    } catch (err: any) {
      throw new IncrementalError(err, 'getVideoSilencesIntervals');
    }

    //Parsing de la sortie de ffmpeg
    const tabStartStr = data.match(/silence_start: -?[.0-9]*/g); //Tableau des occurences de "silence_start
    const tabEndStr = data.match(/silence_end: -?[.0-9]*/g); //Tableau des occurences de "silence_end"

    if (tabEndStr && tabStartStr) {
      const tabLength = Math.max(tabEndStr.length, tabStartStr.length);
      for (let i = 0; i < tabLength; i++) {
        let start, end: number | undefined;

        if (i < tabStartStr.length) {
          const tempTabStart = tabStartStr[i].split(' ');
          start = parseFloat(tempTabStart[tempTabStart.length - 1]);
          // Quand tout le clip est silencieux, FFMPEG peut détecter un silence dont le début est négatif
          if (start < 0) start = 0;
        }

        if (i < tabEndStr.length) {
          const tempTabEnd = tabEndStr[i].split(' ');
          end = parseFloat(tempTabEnd[tempTabEnd.length - 1]);
        }
        if (
          end !== undefined &&
          start !== undefined &&
          !tabEnd.includes(end) &&
          !tabStart.includes(start)
        ) {
          tabStart.push(start);
          tabEnd.push(end);
        }
      }
      tabEnd.push();
    }
    tabStart.sort((a, b) => a - b);
    tabEnd.sort((a, b) => a - b);

    return { tabStart, tabEnd };
  } catch (err: any) {
    throw new IncrementalError(err, 'getVideoSilencesIntervals');
  }
};

const isFFMPEGUsable = () => {
  let FFMPEGPath = '';
  try {
    FFMPEGPath = FFmpegCommandBuilder.getFfmpegPath();

    //Checking if file exists
    if (!fs.existsSync(FFMPEGPath)) {
      logger('ffmpegUtils', logLevel.crit, 'FFMPEG is not on the computer', {
        FFMPEGPath,
      });
      return false;
    }

    //Checking if file has the right access rights
    //Only works for macOS "Note: The fs.constants.X_OK flag doesn't work for Windows operating system and will only check for the existence of the file in the current directly."
    fs.accessSync(FFMPEGPath, fs.constants.X_OK);
    return true;
  } catch (error: any) {
    manageError({ error: new IncrementalError(error, 'isFFMPEGUsable') });
  }
};

export const isFFMPEGSupported = (clip_source: string) => {
  const isSupportedFormat = ffmpegSupportedFormats.some(format =>
    clip_source.endsWith(format)
  );

  return isSupportedFormat;
};

export { getVideoSilencesIntervals, isFFMPEGUsable };

import React, { useCallback, useEffect, useState } from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useIntl } from 'react-intl';

import loudSoundUrl from '@autocut/assets/images/loudSound.svg';
import softSoundUrl from '@autocut/assets/images/softSound.svg';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { setAutocutStore } from '@autocut/utils/zustand';
import { debounce } from 'lodash';
import { NoiseRange } from '../../atoms/NoiseRange/NoiseRange';
import { TextWithHelper } from '../../atoms/TextWithHelper';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import css from './noiseLevelStep.module.css';
const loudSound = new URL(loudSoundUrl, import.meta.url).href;
const softSound = new URL(softSoundUrl, import.meta.url).href;

export type NoiseLevelStepProps = {
  modeId?: 'silence';
};

export const NoiseLevelStep = ({ modeId = 'silence' }: NoiseLevelStepProps) => {
  const intl = useIntl();
  const { noiseLevel: noiseLevelParam } = useAutoCutStore(
    state => state.ui.parameters[modeId]
  );
  const [noiseLevel, setNoiseLevel] = useState(noiseLevelParam);

  useEffect(() => {
    setNoiseLevel(noiseLevelParam);
  }, [noiseLevelParam]);

  // const { setIsOpen: setIsTourOpen } = useTour();
  // useEffect(() => {
  //   if (
  //     getLocalStorage(onboardingLocalStorageKeys.ModeSelection) &&
  //     !getLocalStorage(onboardingLocalStorageKeys.Legacy)
  //   ) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  const debouncedSetNoiseLimit = useCallback(
    debounce(v => {
      setAutocutStore(`ui.parameters.${modeId}.noiseLevel`, Number(v));
    }, 500),
    []
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_Step3_Title',
        defaultMessage: 'Adjust noise level',
      })}
      defaultOpen={true}
    >
      <div id="legacy-step12" className={css.container}>
        <div className={'input_title_left'}>
          <TextWithHelper
            helperId="helper_Step3_1"
            helperDefault="Sound level considered as silence. You can see the effects on the visualization below."
          >
            <TranslatedMessage
              id="text_Step3_Noise"
              defaultMessage="Noise level"
            />
          </TextWithHelper>
        </div>
        <FlexContainer
          justifyContent="space-between"
          className={css.range_noise_level}
        >
          <img
            src={softSound}
            id="soft-sound-logo"
            alt="soft_sound_logo"
            className={css.sound_logo}
          />
          <NoiseRange
            noiseLimit={noiseLevel}
            setNoiseLimit={function (e: any) {
              setNoiseLevel(e.target.value);
              debouncedSetNoiseLimit(e.target.value);
            }}
          />
          <img
            src={loudSound}
            id="loud-sound-logo"
            alt="loud_sound_logo"
            className={css.sound_logo}
          />
        </FlexContainer>
      </div>
    </StepLayout>
  );
};

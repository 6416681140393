import { os, path } from '@autocut/lib/cep/node';
import { evalTS } from '@autocut/lib/utils/bolt';
import {
  ExportDescriptorOptions,
  ExportDescriptorTrackWithClips,
  ExportDescriptorTrackWithIntervals,
} from '@autocut/types/ExportedAudio';
import { v4 as uuidv4 } from 'uuid';
import { IncrementalError } from '../errors/IncrementalError';

const DEFAULT_FORMAT = 'wav';

export const exportAllSequenceAudio = async (
  sequenceEnd: number,
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  const filepath = path.join(
    os.tmpdir(),
    `${uuidv4()}.${options?.format || DEFAULT_FORMAT}`
  );

  try {
    const result = await evalTS(
      'exportSequenceAudioFromTo',
      0,
      sequenceEnd,
      eprFilePath,
      filepath,
      options
    );

    return result;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportAllSequenceAudio');
  }
};

export const exportSequenceIntervalAudio = async (
  intervals: number[][],
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  try {
    const filepath = path.join(
      os.tmpdir(),
      `{filename}.${options?.format || DEFAULT_FORMAT}`
    );
    const audioInfo = await evalTS(
      'exportSequenceAudioIntervals',
      intervals,
      eprFilePath,
      filepath,
      options
    );

    return audioInfo;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportSequenceIntervalAudio');
  }
};

export const exportSelectedSequenceAudio = async (
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  const filepath = path.join(
    os.tmpdir(),
    `{filename}.${options?.format || DEFAULT_FORMAT}`
  );

  try {
    return await evalTS(
      'exportSelectedSequenceAudio',
      eprFilePath,
      filepath,
      options
    );
  } catch (error: any) {
    throw new IncrementalError(error, 'exportSelectedSequenceAudio');
  }
};

export const exportAllTracksAudio = async (
  numTracks: number,
  sequenceEnd: number,
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  try {
    const exportedTrackAudioResult = [];
    for (let trackIndex = 0; trackIndex < numTracks; trackIndex++) {
      const filepath = path.join(
        os.tmpdir(),
        `${uuidv4()}.${options?.format || DEFAULT_FORMAT}`
      );
      const audioInfo = await evalTS(
        'exportTrackAudioFromTo',
        0,
        sequenceEnd,
        trackIndex,
        eprFilePath,
        filepath,
        options
      );

      exportedTrackAudioResult.push({
        trackIndex,
        audioIntervalsResults: [audioInfo],
      });
    }

    return exportedTrackAudioResult;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportAllTracksAudio');
  }
};

export const exportTracksAudioIntervals = async (
  audioTracks: ExportDescriptorTrackWithIntervals[],
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  try {
    const exportedTrackAudioResult = [];
    for (const [trackIteration, trackValue] of audioTracks.entries()) {
      const filepath = path.join(
        os.tmpdir(),
        `${trackIteration}_{filename}.${options?.format || DEFAULT_FORMAT}`
      );
      const audioInfo = await evalTS(
        'exportTrackAudioIntervals',
        trackValue.intervalsToExport,
        trackValue.trackIndex,
        eprFilePath,
        filepath,
        options
      );

      exportedTrackAudioResult.push({
        trackIndex: trackValue.trackIndex,
        audioIntervalsResults: audioInfo,
      });
    }

    return exportedTrackAudioResult;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportTracksAudioIntervals');
  }
};

export const exportAllTrackClipsAudio = async (
  trackIndex: number,
  onlySelected: boolean,
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  const filepath = path.join(
    os.tmpdir(),
    `{filename}.${options?.format || DEFAULT_FORMAT}`
  );
  try {
    const exportedClipsAudioResults = await evalTS(
      'exportAllClipsAudio',
      trackIndex,
      onlySelected,
      eprFilePath,
      filepath,
      options
    );

    return exportedClipsAudioResults;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportAllTrackClipsAudio');
  }
};

export const exportOneTrackClipAudio = async (
  clipIndex: number,
  trackIndex: number,
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  try {
    const filepath = path.join(
      os.tmpdir(),
      `${uuidv4()}.${options?.format || DEFAULT_FORMAT}`
    );
    const audioInfo = await evalTS(
      'exportClipAudio',
      clipIndex,
      trackIndex,
      eprFilePath,
      filepath,
      options
    );

    return audioInfo;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportOneTrackClipAudio');
  }
};

export const exportClipsAudio = async (
  audioTracks: ExportDescriptorTrackWithClips[],
  eprFilePath: string,
  options?: ExportDescriptorOptions
) => {
  try {
    const exportedTrackAudioResult = [];
    for (const audioTrack of audioTracks) {
      if (audioTrack.clips === 'all') {
        const exportedClipsAudioResults = await exportAllTrackClipsAudio(
          audioTrack.trackIndex,
          false,
          eprFilePath,
          options
        );

        exportedTrackAudioResult.push({
          trackIndex: audioTrack.trackIndex,
          clips: exportedClipsAudioResults,
        });
      } else if (audioTrack.clips === 'selected') {
        const exportedClipsAudioResults = await exportAllTrackClipsAudio(
          audioTrack.trackIndex,
          true,
          eprFilePath,
          options
        );

        exportedTrackAudioResult.push({
          trackIndex: audioTrack.trackIndex,
          clips: exportedClipsAudioResults,
        });
      } else {
        const exportedClipsAudioResults = [];
        for (const clip of audioTrack.clips) {
          const audioInfo = await exportOneTrackClipAudio(
            clip.clipIndex,
            audioTrack.trackIndex,
            eprFilePath,
            options
          );

          exportedClipsAudioResults.push(audioInfo);
        }

        exportedTrackAudioResult.push({
          trackIndex: audioTrack.trackIndex,
          clips: exportedClipsAudioResults,
        });
      }
    }

    return exportedTrackAudioResult;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportClipsAudio');
  }
};

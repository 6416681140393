import { evalTS, initBolt } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { logger } from '../logger';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('fetchAndLoadExtendScriptFunctions', level, message, objects);
};

export const fetchAndLoadExtendScriptFunctions = async (nbTry = 0) => {
  try {
    console.log('init : fetchAndLoadExtendScriptFunctions');

    await initBolt();

    await evalTS('initSecret', autocutStoreVanilla().ppro.handshakeSecret);

    await evalTS('enableQE');

    setAutocutStore('ppro.isScriptLoaded', true);
    console.log('init : fetchAndLoadExtendScriptFunctions => done');
  } catch (error) {
    if (nbTry < 5) {
      await new Promise((resolve, reject) =>
        setTimeout(async () => {
          console.error(
            `Error during fetchAndLoadExtendScriptFunctions, retrying in ${
              500 * (nbTry + 1)
            }ms (Try #${nbTry + 1})`
          );
          await fetchAndLoadExtendScriptFunctions(nbTry + 1).catch(reject);
          resolve(true);
        }, 500 * (nbTry + 1))
      );
    } else {
      setAutocutStore('ppro.errors.errorDuringScriptLoading', true);
      logMessage(
        logLevel.error,
        `Error during handleLoadExtendScript : ${
          (error as any).message || (error as any).toString()
        }`,
        {
          error,
        }
      );
      throw new Error(
        `Cannot load ExtendScript functions : ${
          (error as any).message || (error as any).toString()
        }`
      );
    }
  }
};

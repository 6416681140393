import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/atoms/Stepper/StepperRouter/StepperRouter';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModeWrapper } from '../ModeWrapper';
import { ChaptersLanguage } from './Steps/Language/ChapterLanguage';
import { ChaptersEditor } from './Steps/Editor/ChapterEditor';

export const ChaptersMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={HiOutlineVideoCamera}
      text="AutoChapters"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        if (path === 'language') {
          setAutocutStore('ui.process.isPaused', false);
        }
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/cut/chapters"
        steps={[
          {
            label: intl.formatMessage({
              id: 'chapters_step_language',
              defaultMessage: 'Select clips and language',
            }),
            path: 'language',
            element: <ChaptersLanguage />,
          },
          {
            label: intl.formatMessage({
              id: 'chapters_step_editor',
              defaultMessage: 'Chapters editor',
            }),
            path: 'editor',
            element: <ChaptersEditor />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

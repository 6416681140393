import { SilenceParams } from '@autocut/types/SilenceParams';

/***
 * Modifie le tableau d'intervalles de silences donné et supprime les bruits et les silences courts (temps défini par l'utilisateur)
 * @param {Number[][]} silences - Tableau d'intervalles de silence
 * @returns {Number[][]}
 */
export const removeTalksShorterThan = (
  silences: number[][],
  parameters: SilenceParams
) => {
  const { minTimeOfTalk } = parameters;
  for (let i = 0; i < silences.length - 1; i++) {
    // If minTimeOfTalk is too short, it can induce cuts shorter than a frame. PPro will then cut twice at the same place,
    // messing up the count for the deletion so we're putting a limit
    if (silences[i + 1][0] - silences[i][1] < Math.max(minTimeOfTalk, 0.05)) {
      silences[i][1] = silences[i + 1][1];
      silences.splice(i + 1, 1);
      i--;
    }
  }

  return silences;
};

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { IoIosCheckmarkCircle } from 'react-icons/io';

import { Button } from '@autocut/components/atoms';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { useIntl } from 'react-intl';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export const FormattedNumberModal = () => {
  const intl = useIntl();
  const { formattedNumbers } = useAutoCutStore(state => ({
    formattedNumbers:
      state.ui.parameters.caption.postProcessing.formattedNumbers,
  }));

  return (
    <Modal
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      title={intl.formatMessage({
        id: `formatted_numbers_title`,
        defaultMessage: 'Numbers have been formatted',
      })}
      icon={<IoIosCheckmarkCircle size={28} color="#17B26A" />}
      footer={
        <Button
          onClickFunction={() => setAutocutStore('ui.openedModalName', '')}
        >
          <TranslatedMessage
            id={`formatted_numbers_modal_cta`}
            defaultMessage="Close modal"
          />
        </Button>
      }
    >
      <p>
        <TranslatedMessage
          id={`formatted_numbers_modal_text`}
          defaultMessage="<b>{number} words</b> have been replaced by numbers in your transcript."
          values={{
            number: formattedNumbers,
          }}
        />
      </p>
    </Modal>
  );
};

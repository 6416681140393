import { resourcesConfigs } from '@autocut/enums/resources.enum';
import { evalTS } from '@autocut/lib/utils/bolt';
import {
  ExportDescriptor,
  ExportResult,
  ExportSequenceValue,
} from '@autocut/types/ExportedAudio';
import { IntlShape } from 'react-intl';
import { changeMessage } from '../cutButton/changeMessage';
import { IncrementalError } from '../errors/IncrementalError';
import { waitForResource } from '../resourceManager.utils';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import {
  exportAllSequenceAudio,
  exportAllTracksAudio,
  exportClipsAudio,
  exportSelectedSequenceAudio,
  exportSequenceIntervalAudio,
  exportTracksAudioIntervals,
} from './exportFunctions';
import {
  isAllAudioTracksExport,
  isAllSequenceExport,
  isAudioTracksWithIntervalsExport,
  isSelectedSequenceExport,
  isSequenceWithIntervalsExport,
} from './typeguards';

const exportAudio = async <SequenceValue extends ExportSequenceValue>(
  descriptor: ExportDescriptor<SequenceValue>
): Promise<ExportResult<SequenceValue>> => {
  try {
    const eprResourceName =
      descriptor.options?.format === 'mp3' ? 'mp3_epr' : 'wav_epr';
    const eprResource = resourcesConfigs[eprResourceName];

    // Get export template
    await waitForResource(eprResource);
    const eprFilePath =
      autocutStoreVanilla().resources[eprResourceName].filePath;
    if (!eprFilePath)
      throw new IncrementalError('No erp file filepath', 'getTracks');

    const fullSequence = await evalTS('getActiveSequence');
    const NB_TICK_PER_SECONDS = 254016000000;
    const sequenceEnd = fullSequence.end / NB_TICK_PER_SECONDS;
    let exportedAudioResult: ExportResult<SequenceValue>;
    const descriptorSequence = descriptor.sequence;

    if (isAllSequenceExport(descriptorSequence)) {
      const exportedSequenceAudioInfo = await exportAllSequenceAudio(
        sequenceEnd,
        eprFilePath,
        descriptor.options
      );

      exportedAudioResult = {
        sequence: { ...exportedSequenceAudioInfo },
      } as ExportResult<SequenceValue>;
    } else if (isSelectedSequenceExport(descriptorSequence)) {
      const exportedSequenceAudioInfo = await exportSelectedSequenceAudio(
        eprFilePath,
        descriptor.options
      );

      exportedAudioResult = {
        sequence: exportedSequenceAudioInfo,
      } as ExportResult<SequenceValue>;
    } else if (isSequenceWithIntervalsExport(descriptorSequence)) {
      const exportedSequenceAudioInfo = await exportSequenceIntervalAudio(
        descriptorSequence,
        eprFilePath,
        descriptor.options
      );

      exportedAudioResult = {
        sequence: exportedSequenceAudioInfo,
      } as ExportResult<SequenceValue>;
    } else {
      if (isAllAudioTracksExport(descriptorSequence)) {
        const exportedTracksAudioResult = await exportAllTracksAudio(
          fullSequence.audioTracks.numTracks,
          sequenceEnd,
          eprFilePath,
          descriptor.options
        );

        exportedAudioResult = {
          sequence: {
            audioTracks: exportedTracksAudioResult,
          },
        } as ExportResult<SequenceValue>;
      } else if (isAudioTracksWithIntervalsExport(descriptorSequence)) {
        const exportedTrackAudioResult = await exportTracksAudioIntervals(
          descriptorSequence.audioTracks,
          eprFilePath,
          descriptor.options
        );

        exportedAudioResult = {
          sequence: {
            audioTracks: exportedTrackAudioResult,
          },
        } as ExportResult<SequenceValue>;
      } else {
        const exportedTrackAudioResult = await exportClipsAudio(
          descriptorSequence.audioTracks,
          eprFilePath,
          descriptor.options
        );
        exportedAudioResult = {
          sequence: {
            audioTracks: exportedTrackAudioResult,
          },
        } as ExportResult<SequenceValue>;
      }
    }

    return exportedAudioResult;
  } catch (error: any) {
    throw new IncrementalError(error, 'exportAudio');
  }
};

export const getExportedAudioInfos = async <
  SequenceValue extends ExportSequenceValue
>(
  descriptor?: ExportDescriptor<SequenceValue>,
  intl?: IntlShape
): Promise<ExportResult<SequenceValue>> => {
  if (intl) {
    changeMessage(intl, 'button_ExportingAudio', 'Exporting audio...');
  }
  try {
    const currentExportedAudioInfos =
      autocutStoreVanilla().sequence.exportedAudioInfos;
    if (currentExportedAudioInfos) {
      return currentExportedAudioInfos as unknown as ExportResult<SequenceValue>;
    } else if (descriptor) {
      const newExportedAudioInfos = await exportAudio(descriptor);

      setAutocutStore('sequence.exportedAudioInfos', newExportedAudioInfos);

      return newExportedAudioInfos;

      // Si on réutilise du wav on pourra décommenter tout ça
      // if (filteredExportedAudioInfos) {
      //   setAutocutStore('sequence.exportedAudioInfos', newExportedAudioInfos);

      //   return newExportedAudioInfos;
      // } else {
      //   throw new IncrementalError('NO_AUDIBLE_AUDIO', 'getExportedAudioInfos');
      // }
    } else {
      throw new IncrementalError(
        'Cannot get exportedAudioInfos',
        'getExportedAudioInfos'
      );
    }
  } catch (error: any) {
    throw new IncrementalError(error, 'getExportedAudioInfos');
  }
};

// Etant donné qu'on a passé le format d'export en mp3 il est impossible de
// récupérer l'audio en pcm sans passer par une conversion wav avec ffmpeg
// du coup on abandonne pour l'instant la filtrage du muet côté extension

// const filterMutedAudio = (
//   exportedAudioInfos: ExportResult<ExportSequenceValue>
// ): ExportResult<ExportSequenceValue> | undefined => {
//   if (isAllSequenceResult(exportedAudioInfos)) {
//     if (isFileMuted(exportedAudioInfos.sequence.path)) return undefined;
//     else return exportedAudioInfos;
//   } else if (isSelectedOrWithIntervalsSequenceResult(exportedAudioInfos)) {
//     const filteredIntervals = exportedAudioInfos.sequence.filter(
//       interval => !isFileMuted(interval.path)
//     );

//     if (filteredIntervals.length === 0) return undefined;
//     else
//       return {
//         sequence: filteredIntervals,
//       };
//   } else if (isAllOrClipsAudioTrackResult(exportedAudioInfos)) {
//     const filteredAudioTracks = exportedAudioInfos.sequence.audioTracks
//       .map((audioTrack, trackIndex) => {
//         const filteredClips = audioTrack.clips.filter(
//           clip => !isFileMuted(clip.path)
//         );

//         if (filteredClips.length === 0) return undefined;
//         else
//           return {
//             trackIndex,
//             clips: filteredClips,
//           };
//       })
//       .filter(Boolean) as ExportedAudioTrackWithClips[];

//     if (filteredAudioTracks.length === 0) return undefined;
//     else
//       return {
//         sequence: {
//           audioTracks: filteredAudioTracks,
//         },
//       };
//   } else if (isAudioTracksWithIntervalsResult(exportedAudioInfos)) {
//     const filteredAudioTracks = exportedAudioInfos.sequence.audioTracks
//       .map((audioTrack, trackIndex) => {
//         const filteredIntervals = audioTrack.audioIntervalsResults.filter(
//           interval => !isFileMuted(interval.path)
//         );

//         if (filteredIntervals.length === 0) return undefined;
//         else
//           return {
//             trackIndex,
//             audioIntervalsResults: filteredIntervals,
//           };
//       })
//       .filter(Boolean) as ExportedAudioTrackWithIntervals[];

//     if (filteredAudioTracks.length === 0) return undefined;
//     else
//       return {
//         sequence: {
//           audioTracks: filteredAudioTracks,
//         },
//       };
//   } else {
//     throw new IncrementalError('No typeguards matched', 'filterMutedAudio');
//   }
// };

// const isFileMuted = (path: string) => {
//   const [, PCM] = openWaveFile(path) as [number, number[]];
//   for (const sample of PCM) {
//     if (sample > 0) return false;
//   }

//   return true;
// };

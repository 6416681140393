import { InfoText } from '@autocut/components/atoms/InfoText';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/atoms/Stepper/StepperRouter/StepperRouter';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModeWrapper } from '../ModeWrapper';
import { BRollLanguage } from './Steps/Language/BRollLanguage';
import { BRollParameters } from './Steps/Parameters/BRollParameters';
import { BRollTranscript } from './Steps/Transcript/BRollTranscript';

export const BRollMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={HiOutlineVideoCamera}
      text="Auto B-Roll"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        if (path === 'language') {
          setAutocutStore('ui.process.isPaused', false);
        }
        navigate(path);
      }}
    >
      <div style={{ marginBottom: 32 }}>
        <InfoText
          style="info"
          onClose={() => {
            return;
          }}
        >
          <TranslatedMessage
            id="broll_text_info"
            defaultMessage="Auto B-Roll, thanks to AI, analyzes your audio and automatically adds {br} @Pexels B-Rolls related to what your video is about."
          />
        </InfoText>
      </div>
      <StepperRouter
        basePath="/cut/broll"
        steps={[
          {
            label: intl.formatMessage({
              id: 'broll_step_parameters',
              defaultMessage: 'B-Roll Parameters',
            }),
            path: 'parameters',
            element: <BRollParameters />,
          },
          {
            label: intl.formatMessage({
              id: 'broll_step_language',
              defaultMessage: 'Select clips and language',
            }),
            path: 'language',
            element: <BRollLanguage />,
          },
          {
            label: intl.formatMessage({
              id: 'broll_step_brolls',
              defaultMessage: 'Edit B-Roll selection',
            }),
            path: 'brolls',
            element: <BRollTranscript />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

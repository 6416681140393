import {
  MAC_FFMPEG_RELATIVE_PATH,
  OS_MAC,
  OS_WINDOWS,
  WINDOWS_FFMPEG_RELATIVE_PATH,
} from '@autocut/constants/constants';
import { fs, path } from '@autocut/lib/cep/node';
import { getExtensionFolder } from '@autocut/utils';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { ChannelLayout, ChannelLayoutType } from './ChannelLayout';

export enum FFmpegLogLevel {
  QUIET = 'quiet',
  PANIC = 'panic',
  FATAL = 'fatal',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  VERBOSE = 'verbose',
  DEBUG = 'debug',
  TRACE = 'trace',
}

export type FFmpegCommandBuilderMode = 'spawn' | 'exec';

export class FFmpegCommandBuilder {
  private commandParts: string[] = [];
  private mode: FFmpegCommandBuilderMode = 'exec';

  constructor(mode: FFmpegCommandBuilderMode = 'exec') {
    this.mode = mode;
    this.commandParts.push(
      `"${FFmpegCommandBuilder.getFfmpegPath()}"`,
      '-hide_banner'
    );
  }

  static getFfmpegPath() {
    const rootFolder = getExtensionFolder();
    const os = getOS();
    switch (os) {
      case OS_MAC:
        return path.normalize(rootFolder + MAC_FFMPEG_RELATIVE_PATH);
      case OS_WINDOWS:
        return path.normalize(rootFolder + WINDOWS_FFMPEG_RELATIVE_PATH);
    }
    throw new Error('FFmpeg Path not found');
  }

  private checkFilePresence(filepath: string) {
    if (!filepath || !fs.existsSync(filepath)) {
      throw new Error('File not found → ' + filepath);
    }
  }

  public build() {
    return this.commandParts.join(' ');
  }

  public splitCommand() {
    const res = this.commandParts
      .flatMap(part => (part.trim().startsWith('"') ? part : part.split(' ')))
      .filter(Boolean)
      .map(part => (this.isSpawnMode() ? part.replace(/"/g, '') : part));
    return res;
  }

  /* 
        Print capabilities:
  */
  public formats() {
    this.commandParts.push('-formats'); // display all formats available in FFmpeg
    return this;
  }

  public codecs() {
    this.commandParts.push('-codecs'); // display all codecs available in FFmpeg
    return this;
  }

  public filters() {
    this.commandParts.push('-filters'); // display all filters available in FFmpeg
    return this;
  }

  public layouts() {
    this.commandParts.push('-layouts'); // display channel layouts available in FFmpeg
    return this;
  }

  /* 
        Global options:
  */
  public logLevel(level: FFmpegLogLevel) {
    this.commandParts.push(`-v`, `${level}`); // set logging level
    return this;
  }

  public overwriteOutput() {
    this.commandParts.push('-y'); // overwrite output files
    return this;
  }

  public exitOnError() {
    this.commandParts.push('-xerror'); // exit on error
    return this;
  }

  public ignoreVideo() {
    this.commandParts.push('-vn'); // ignore video from file
    return this;
  }

  public ignoreAudio() {
    this.commandParts.push('-an'); // ignore audio from file
    return this;
  }

  public ignoreSubtitle() {
    this.commandParts.push('-sn'); // ignore subtitle from file
    return this;
  }

  public ignoreData() {
    this.commandParts.push('-dn'); // ignore data from file
    return this;
  }

  public onlyAudio() {
    return this.ignoreVideo().ignoreSubtitle().ignoreData();
  }

  public copy() {
    this.commandParts.push('-c`, `copy'); // copy stream from input to output without re-encoding
    return this;
  }

  /*
        Files options:
   */

  public input(filepath: string) {
    this.checkFilePresence(filepath);

    const escapedFilePath = this.isEscapeModeNeeded()
      ? filepath.replace(/\$/g, '\\$')
      : filepath;

    this.commandParts.push(`-i`, `"${escapedFilePath}"`); // path to input file
    return this;
  }

  public start(start: number) {
    start = start < 0 ? 0 : start;

    this.commandParts.push(`-ss`, `${start}`); // start time in second in the file to process from
    return this;
  }

  public duration(duration: number | null) {
    if (!duration) return this;

    if (duration < 0) {
      throw new IncrementalError(
        'Duration must be positive',
        'FFmpegCommandBuilder.duration'
      );
    }

    let durationStr = duration.toString().toLowerCase();

    const isNumberInNegativeExpoFormat = durationStr.includes('e-'); // check if number is in negative exponent format i.e. 0 < duration < 1 && duration has exponent format
    if (isNumberInNegativeExpoFormat) {
      const index = parseInt(durationStr.split('e-')[1]);
      durationStr = duration.toFixed(index);
    }

    this.commandParts.push(`-t`, `${durationStr}`); // duration in second of the file to process
    return this;
  }

  private frame(frame: number) {
    this.commandParts.push(`-frames:v`, `${frame}`); // set number of frame to process
    return this;
  }

  public forceFormat(format: string) {
    this.commandParts.push(`-f`, `${format}`); // force output format
    return this;
  }

  public noOutput() {
    this.commandParts.push('-f', 'null -'); // no output file
    return this;
  }

  public output(filepath: string) {
    this.commandParts.push(
      `"${
        this.isEscapeModeNeeded() ? filepath.replace(/\$/g, '\\$') : filepath
      }"`
    ); // path to output file
    return this;
  }

  /*
        Audio options:
  */

  public samplingRate(rate: number) {
    this.commandParts.push(`-ar`, `${rate}`); // set audio sampling rate in output file
    return this;
  }

  public audioChannels(nbChannels: number) {
    nbChannels = nbChannels < 0 ? 0 : nbChannels;

    this.commandParts.push(`-ac`, `${nbChannels}`); // set audio channels in output file
    return this;
  }

  public getStream(numStream: number) {
    numStream = this.validateNumStream(numStream);

    return this.map(`a:${numStream}`);
  }

  /*
        Filters options:
  */

  private filterComplex(filter: string) {
    this.commandParts.push(`-filter_complex`, `${filter}`); // set filter complex
    return this;
  }

  private map(data: string) {
    this.commandParts.push(`-map`, `${data}`); // set data to map
    return this;
  }

  private audioFilter(filter: string) {
    this.commandParts.push(`-af`, `${filter}`); // set audio filter
    return this;
  }

  /*
        Filters template/option:
  */
  private getGeneratePNGFilter() {
    return 'aformat=channel_layouts=mono,compand,showwavespic=colors=#ffffff';
  }

  private getSilenceDectionFilter(noiseLevel: string) {
    return `silencedetect=n=${noiseLevel}dB:d=0.1`; // get silence from file
  }

  private getStreamSelector(numStream: number | 'a') {
    return `[0:${numStream}]`;
  }

  private getChannelSplitFilter(channel_layout: ChannelLayoutType) {
    return `channelsplit=channel_layout=${channel_layout}`;
  }

  /*
        Filters generator:
  */

  public generatePNGFilter() {
    return this.filterComplex(this.getGeneratePNGFilter()).frame(1);
  }

  public generateSilenceOption() {
    return this.forceFormat('lavfi -i anullsrc=r=48000:cl=mono');
  }

  /// SILENCE DETECTION FILTERS

  public generateFileSilenceDetectionFilter(noiseLevel: string) {
    return this.filterComplex(this.getSilenceDectionFilter(noiseLevel));
  }

  public generateStreamSilenceDetectionFilter(
    noiseLevel: string,
    numStream: number
  ) {
    if (numStream < 0) {
      numStream = 0;
    }

    return this.filterComplex(
      this.getStreamSelector(numStream) +
        this.getSilenceDectionFilter(noiseLevel)
    );
  }

  public generateChannelSilenceDectionFilter(
    noiseLevel: string,
    numStream: number,
    channel: ChannelType,
    channel_layout: ChannelLayoutType
  ) {
    numStream = this.validateNumStream(numStream);

    channel = this.validateChannel(channel, channel_layout);

    return this.filterComplex(
      this.getStreamSelector(numStream) +
        this.getChannelSplitFilter(channel_layout) +
        `[${channel}];[${channel}]` +
        this.getSilenceDectionFilter(noiseLevel)
    );
  }

  public getSilenceDetectionFilter(
    isMultiStream: boolean,
    isChannelExploded: boolean,
    {
      noiseLevel,
      numStream,
      channel,
      channel_layout,
    }: {
      noiseLevel: string;
      numStream: number;
      channel: ChannelType;
      channel_layout: ChannelLayoutType;
    }
  ) {
    if (isMultiStream) {
      if (isChannelExploded) {
        return this.generateChannelSilenceDectionFilter(
          noiseLevel,
          numStream,
          channel,
          channel_layout
        );
      } else {
        return this.generateStreamSilenceDetectionFilter(noiseLevel, numStream);
      }
    } else {
      return this.generateFileSilenceDetectionFilter(noiseLevel);
    }
  }

  public generateConcatFilter() {
    return this.filterComplex('[0:0][1:0]concat=n=2:v=0:a=1[out]').map(
      '"[out]"'
    );
  }

  public generateVolumeDetectFilter() {
    return this.audioFilter('volumedetect');
  }

  /*
        Validator:
  */

  private validateNumStream(numStream: number) {
    return numStream < 0 ? 0 : numStream;
  }

  private validateChannel(
    channel: ChannelType,
    channel_layout: ChannelLayoutType
  ) {
    return !ChannelLayout[channel_layout].includes(channel as string)
      ? (ChannelLayout[channel_layout][0] as ChannelType)
      : channel;
  }

  /*
        Utils:
  */
  private isEscapeModeNeeded() {
    return this.mode === 'exec' && getOS() === OS_MAC;
  }
  private isSpawnMode() {
    return this.mode === 'spawn';
  }
  private isExecMode() {
    return this.mode === 'exec';
  }
}

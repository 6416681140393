import { IntlShape } from 'react-intl';
import { setAutocutStore } from '../zustand';

export const changeMessage = (
  intl: IntlShape,
  messageId: string,
  defaultMessage: string,
  values?: { [key: string]: string | number }
) => {
  if (messageId === '' && defaultMessage === '') {
    setAutocutStore('ui.cutButtonMessage', '');
    return;
  }
  setAutocutStore(
    'ui.cutButtonMessage',
    intl.formatMessage(
      { id: messageId, defaultMessage: defaultMessage },
      values
    )
  );
};

export const RMS_INTERVAL_DURATION = 0.02; // In seconds

export const computeRMS = (
  signal: number[],
  sampleRate: number,
  customRmsIntervalDuration?: number
) => {
  const intervalSampleCount = Math.floor(
    sampleRate * (customRmsIntervalDuration || RMS_INTERVAL_DURATION)
  );
  const rmsValues = [];

  for (let i = 0; i < signal.length; i += intervalSampleCount) {
    // Take a chunk of the signal for the current interval
    const chunk = signal.slice(i, i + intervalSampleCount);

    // Calculate the sum of the squares of the signal samples
    const sumOfSquares = chunk.reduce((acc, val) => acc + val * val, 0);

    // Calculate the mean of the squared samples
    const meanOfSquares = sumOfSquares / chunk.length;

    // Calculate the square root of the mean of squares (RMS)
    const currentRMS = Math.sqrt(meanOfSquares);

    // Store the RMS value for this interval
    rmsValues.push(currentRMS);
  }

  return rmsValues;
};

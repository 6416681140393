import { ModalType } from '@autocut/enums/modals.enum';
import {
  AutocutModeIds,
  getDefaultParametersByMode,
  getParametersByMode,
  getModeStatTrait,
} from '@autocut/enums/modes.enum';
import { evalTS } from '@autocut/lib/utils/bolt';
import { StatType } from '@autocut/types/StatType.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { isEqual } from 'lodash';
import { IntlShape } from 'react-intl';
import { handleRemoveCache } from '../cut/handleRemoveCache';
import { handleFinalMessage } from '../cutButton/handleFinalMessage';
import { calculateXpGained } from '../game.utils';
import { addXp } from '../game/addXp';
import { removeOldFileFromTempFolder } from '../general.utils';
import { sendStats } from '../stats.util';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import { StatMode } from '@autocut/types/StatCategory.enum';

export const END_MODAL: Record<AutocutModeIds, ModalType> = {
  ai: ModalType.EndProcess,
  broll: ModalType.EndProcess,
  caption: ModalType.EndCaptionsProcess,
  chapters: ModalType.EndProcess,
  podcast: ModalType.EndProcess,
  repeat: ModalType.EndProcess,
  resize: ModalType.EndProcess,
  silence: ModalType.EndProcess,
  zoom: ModalType.EndProcess,
  swear_word: ModalType.EndProcess,
  viral_clips: ModalType.EndProcess,
};

export const handleProcessEnd = async (intl: IntlShape) => {
  try {
    const autocutStates = autocutStoreVanilla();
    const startProcessDate =
      autocutStates.onGoingProcess.startDate || new Date();
    const mode = autocutStates.ui.process.mode;
    const parameters = getParametersByMode(mode);
    const statCategory = getModeStatTrait(mode);
    const selectionInfos = autocutStates.sequence.infos;

    // Extension slow down when cache gets too big, we clean it before cutting
    await handleRemoveCache();
    void evalTS('clearSubSequenceCache'); //No need to wait to finish process

    handleFinalMessage(
      intl,
      'button_Success',
      'Done ! Process ended successfully'
    );

    setAutocutStore('ui.openedModalName', END_MODAL[mode.id as AutocutModeIds]);
    setAutocutStore('sequence.infos', undefined);

    const end = new Date();

    const timeTakenMS = end.getTime() - startProcessDate.getTime();
    const minutesTaken = Math.floor(timeTakenMS / 60000);
    const secondsTaken = Math.floor((timeTakenMS % 60000) / 1000);

    setAutocutStore('ui.process.durationProcessMinutes', minutesTaken);
    setAutocutStore('ui.process.durationProcessSeconds', secondsTaken);

    const xpGainedTemp = calculateXpGained(
      timeTakenMS,
      mode.id,
      (selectionInfos as Sequence).nbClipsAudio
    );

    if (xpGainedTemp !== 0) {
      await addXp(xpGainedTemp);
      setAutocutStore('game.level.xpGained', xpGainedTemp);
    }
    await removeOldFileFromTempFolder();

    await sendStats({
      type: StatType.PROCESS_ENDED,
      value: mode.getStatValue() || 1,
      traits: {
        mode: statCategory,
        ...(!isEqual(parameters, getDefaultParametersByMode(mode))
          ? {
              modifiedParameters: 1,
            }
          : {}),
        ...(!!mode.getStatsTraits ? mode.getStatsTraits() : {}),
      },
    });
  } catch (err: any) {
    throw new IncrementalError(err, 'handleProcessEnd');
  }
};

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import './TimeRuler.css';

export type TimeRulerProps = {
  numberValues: number;
};

const TimeRuler = ({ numberValues }: TimeRulerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasWidth, setCanvasWidth] = useState(0);

  const drawTimeline = () => {
    if (canvasRef == null || canvasRef.current == null) {
      return;
    }

    const canvas = canvasRef.current as any;
    if (canvas == null) {
      return;
    }
    const dpr = window.devicePixelRatio || 1;
    const padding = 0;

    canvas.width = canvas.offsetWidth * dpr;
    setCanvasWidth(canvas.offsetWidth * dpr);
    canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
    const ctx = canvas.getContext('2d');
    ctx.scale(dpr, dpr);
    ctx.translate(0, canvas.offsetHeight + padding);

    // Display seconds
    for (let i = 0; i <= numberValues + 1; i++) {
      const currentXValue = i * (canvas.width / (numberValues + 1));
      ctx.lineWidth = 1; // how thick the line is
      ctx.strokeStyle = '#fff'; // what color our line is
      ctx.beginPath();
      ctx.moveTo(currentXValue, -canvas.height);
      ctx.lineTo(currentXValue, 0);
      ctx.stroke();
    }

    // Display half seconds
    const secondOffset = canvas.width / (numberValues + 1) / 2;
    for (let i = 0; i < numberValues + 1; i++) {
      const currentXValue = i * (canvas.width / (numberValues + 1));
      ctx.lineWidth = 1; // how thick the line is
      ctx.strokeStyle = '#fff'; // what color our line is
      ctx.beginPath();
      ctx.moveTo(currentXValue + secondOffset, -canvas.height / 2);
      ctx.lineTo(currentXValue + secondOffset, 0);
      ctx.stroke();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', drawTimeline);
  }, []);

  useEffect(() => {
    drawTimeline();
  }, [canvasRef, canvasWidth]);

  return (
    <div className="timeruler-container">
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default TimeRuler;

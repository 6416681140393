import { ExportedAudioWithSilences } from '@autocut/types/ExportedAudioWithSilences';
import { getTrackByIndex } from '../../timeline/selectedInfos.utils';

export const convertNotUsedAudioToSilence = (
  exportedAudioInfos: ExportedAudioWithSilences[],
  audioTracks: Track[],
  startTimeline: number,
  endTimeline: number
) => {
  return exportedAudioInfos.map(exportedAudio => {
    const currentTrack = getTrackByIndex(exportedAudio.trackIndex, audioTracks);

    // If the current track is used, return the ExportedAudioWithSilences as is
    if (currentTrack?.isUsed) {
      return exportedAudio;
    }

    // If the current track is not used, set timelineBasedSilences and return the updated object
    return {
      ...exportedAudio,
      timelineBasedSilences: [[startTimeline, endTimeline]],
    };
  });
};

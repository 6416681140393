import { AnimatedCanvasType } from '../captions/canvas/classes/animatedCanvas.class.utils';
import { DraggableCanvas } from '../captions/canvas/classes/draggableCanvas.class.utils';
import { uploadFile } from '../files.utils';
import { getCaptionsPresetUploadUrl } from './getCaptionsPresetUploadUrl';

export const uploadMedia = async (
  canvas: AnimatedCanvasType<DraggableCanvas>,
  presetTitle: string
) => {
  const { videoUploadUrl, thumbnailUploadUrl } =
    await getCaptionsPresetUploadUrl(presetTitle);

  const video = await canvas.createVideo();
  const thumbnail = await canvas.createThumbnail();

  try {
    await uploadFile(
      video,
      videoUploadUrl,
      (percentage: number) => {
        console.log('Uploading file', percentage);
      },
      true,
      'video'
    );
  } catch (e) {
    console.log('Error during captions presets video upload', e);
  }

  try {
    await uploadFile(
      thumbnail,
      thumbnailUploadUrl,
      (percentage: number) => {
        console.log('Uploading thumbnail', percentage);
      },
      true,
      'image'
    );
  } catch (e) {
    console.log('Error during captions presets thumbnail upload', e);
  }

  return;
};

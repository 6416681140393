import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { autocutApi } from '../http.utils';
import { setAutocutStore } from '../zustand';
import { getScoreFromApi } from './getScoreFromApi';
import { AutoCutApiError } from '../errors/AutoCutApiError';

export const addXp = async (xp: number) => {
  const fromProgress = await getScoreFromApi();

  const res = await autocutApi
    .post(`/score/add`, {
      xp,
    })
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'addXp'),
      });

      return;
    });

  if (res?.status === 201) {
    const newProgress = res.data;

    setAutocutStore('game.level.levelupAnimationQueued', {
      to: newProgress,
      from: fromProgress,
    });
  }
};

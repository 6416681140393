import { useDrawBackground } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/Background/useDrawBackground';
import { CaptionCanvasObjectHook } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import { useCanvasObject } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionDrawHooks.utils';

export const useBackgroundCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawBackground(args);

  return useCanvasObject({
    name: 'background',
    paths: {
      x: 'ui.parameters.caption.position.xPercentage',
      y: 'ui.parameters.caption.position.yPercentage',
      enabled: 'ui.parameters.caption.box.enabled',
    },
    ...drawReturn,
    ...args,
  });
};

import { RMS_INTERVAL_DURATION } from '../zoom/computeRMS';

// Utils functions to extract the subarrays corresponding to the current time interval of the preview
export const getRMSArrayInterval = (
  fullRmsArray: number[],
  previewStart: number,
  previewEnd: number,
  minClipsStart: number
) => {
  return fullRmsArray.slice(
    (previewStart - minClipsStart) / RMS_INTERVAL_DURATION,
    (previewEnd - minClipsStart) / RMS_INTERVAL_DURATION
  );
};

export const getSilencesArrayInterval = (
  silencesInterval: number[][],
  previewStart: number,
  previewEnd: number
) => {
  return silencesInterval.reduce<number[][]>((result, [start, end]) => {
    if (start < previewEnd && end > previewStart) {
      const newStart = start >= previewStart ? start : previewStart;
      const newEnd = end <= previewEnd ? end : previewEnd;

      result.push([newStart, newEnd]);
    }
    return result;
  }, []);
};

import { OS_MAC } from '@autocut/constants/constants';
import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import { exec } from './exec.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';

const macosPaste = async (): Promise<string | false> => {
  try {
    const { stdout } = await exec({
      command: 'pbpaste',
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.PASTE,
        context: { shellCommand: `pbpaste` },
      },
    });
    return stdout;
  } catch (error) {
    return false;
  }
};

const macosCopy = async (text: string): Promise<boolean> => {
  if (typeof text !== 'string') throw new Error('Text must be a string');

  try {
    await exec({
      command: `echo "${text}" | pbcopy`,
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.COPY,
        context: { shellCommand: `echo "${text}" | pbcopy` },
      },
    });

    return true;
  } catch (error) {
    return false;
  }
};

const windowsPaste = async (): Promise<string | false> => {
  try {
    const { stdout } = await exec({
      command: 'Get-Clipboard',
      options: { shell: 'powershell.exe' },
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.PASTE,
        context: { shellCommand: `Get-Clipboard` },
      },
    });
    return stdout;
  } catch (error) {
    return false;
  }
};

const windowsCopy = async (text: string): Promise<boolean> => {
  if (typeof text !== 'string') throw new Error('Text must be a string');

  try {
    await exec({
      command: `echo "${text}" | clip`,
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.COPY,
        context: { shellCommand: `echo "${text}" | clip` },
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const os = getOS();

export const clipboard = {
  paste: os === OS_MAC ? macosPaste : windowsPaste,
  copy: os === OS_MAC ? macosCopy : windowsCopy,
};

import { hexToRgb, rgbToHex } from '@autocut/utils/color.utils';
import React, { useMemo, CSSProperties, useRef } from 'react';
import { HexColorInput, RgbColor, RgbColorPicker } from 'react-colorful';

import css from './ColorPicker.module.css';
import PopupPortal, { PopupPortalProps } from '../PopupPortal/PopupPortal';

export type ColorPickerProps = {
  value: RgbColor;
  onChange: (color: RgbColor) => void;

  width?: CSSProperties['width'];
  height?: CSSProperties['height'];

  position?: PopupPortalProps['position'];
};

const ColorPicker = ({
  value: valueProp,
  onChange,
  width,
  height,
  position = 'bottom-left',
}: ColorPickerProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const value = useMemo(
    () => valueProp ?? { r: 230, g: 80, b: 25 },
    [valueProp]
  );

  const [showPicker, setShowPicker] = React.useState(false);

  const hexCode = useMemo(() => rgbToHex(value), [value]);

  return (
    <div
      id="portal"
      ref={rootRef}
      className={css.colorPickerContainer}
      style={
        {
          '--color-preview-width': width,
          '--color-preview-height': height,
          '--color-preview': hexCode,
        } as CSSProperties
      }
      onMouseOver={() => setShowPicker(true)}
      onMouseLeave={() => setShowPicker(false)}
    >
      {showPicker && (
        <PopupPortal target={rootRef} position={position}>
          <div ref={popoverRef} className={css.popoverRoot}>
            <HexColorInput
              color={hexCode}
              onBlur={e => onChange(hexToRgb(e.target.value))}
              onChange={e => {
                if (e.length === 7) {
                  onChange(hexToRgb(e));
                }
              }}
            />
            <RgbColorPicker color={value} onChange={color => onChange(color)} />
          </div>
        </PopupPortal>
      )}
    </div>
  );
};

export default ColorPicker;

import { AutoCutApiError } from '../errors/AutoCutApiError';
import { IncrementalError } from '../errors/IncrementalError';
import { autocutApi } from '../http.utils';
import { CaptionChunk } from '@autocut/types/Captions';
import { manageError } from '../manageError';
import { ANIMATED_EMOJI_SIZE } from '@autocut/modes/captions/Steps/Transcript/EmojiSelector/EmojiPicker/EmojiPickerVariant';

export const generateAnimatedEmojisFromTranscription = async ({
  transcription,
}: {
  transcription: CaptionChunk[];
}) => {
  const newTranscription = [...transcription];
  const textArray = transcription.flatMap(chunk => chunk.text);

  const { data = [] } = await autocutApi
    .post('openAI/animatedEmojis', {
      text: textArray,
    })
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'generateEmojisFromTranscription'),
      });

      return {} as never;
    });

  data.forEach((chunk: { text: string; emoji: string; emojiUrl: string }) => {
    if (!chunk.emoji || !chunk.emojiUrl) return;

    const textIndex = textArray.findIndex(text => text.includes(chunk.text));
    if (textIndex === -1) return;

    newTranscription[textIndex].emoji = chunk.emoji;
    newTranscription[textIndex].emojiUrl = chunk.emojiUrl;
    newTranscription[textIndex].emojiSize = ANIMATED_EMOJI_SIZE;
  });

  return newTranscription;
};

import React from 'react';

import { Button } from '@autocut/components/atoms';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { FiAlertTriangle } from 'react-icons/fi';

export const UsageModal = () => {
  const license = useAutoCutStore(state => state.user.license);
  const isTrial = license?.type === 'trial';

  return (
    <Modal
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <TranslatedMessage
            id={`${license?.type}_usage_exceeded_title`}
            defaultMessage="Free trial limitation reach"
          />
        </p>
      }
      icon={<FiAlertTriangle size="1.5rem" />}
      footer={
        isTrial ? (
          <Button
            onClickFunction={() =>
              openLinkInBrowser(
                `${AUTOCUT_CONSTANTS[CURRENT_ENV].WEBSITE_ADDRESS}/app/payment?plan=ai&formula=month`,
                false
              )
            }
            buttonType="primary"
          >
            <TranslatedMessage
              id={`free_trial_usage_exceeded_cta`}
              defaultMessage="Subscribe"
            />
          </Button>
        ) : (
          <></>
        )
      }
    >
      <p
        style={{
          color: 'white',
          padding: '16px',
          fontSize: '13px',
          lineHeight: '23px',
          fontWeight: 'bold',
        }}
      >
        <TranslatedMessage
          id={`${license?.type}_usage_exceeded_text`}
          defaultMessage="You have used more than 1000 minutes of AI feature. <br /> Subscribe to the AI plan to continue using AutoCut AI features."
        />
      </p>
    </Modal>
  );
};

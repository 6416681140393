import React, { useCallback, useState } from 'react';

import { EmojiPicker } from './EmojiPicker';
import { getEmojisCategories } from '@autocut/utils/emojis/getEmojisCategories';
import { getAnimatedEmojis } from '@autocut/utils/emojis/getAnimatedEmojis';
import { getStaticEmojis } from '@autocut/utils/emojis/getStaticEmojis';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { SegmentedControl } from '@autocut/components/atoms/SegmentedControl/SegmentedControl';
import css from './EmojiPickerVariant.module.scss';
import { FormattedMessage } from 'react-intl';

type EmojiPickerVariantOptions = 'animated' | 'static';

export const STATIC_EMOJI_SIZE = 64;
export const ANIMATED_EMOJI_SIZE = 512;

export const EmojiPickerVariant = ({
  onEmojiClick,
  width,
  maxHeight,
}: {
  onEmojiClick: (emoji: { name: string; url: string; size: number }) => void;
  width?: string;
  maxHeight?: string;
}) => {
  const [variant, setVariant] = useState<EmojiPickerVariantOptions>('animated');

  const getCategories = useCallback(
    () => getEmojisCategories(variant === 'animated'),
    [variant]
  );

  const getEmojis =
    variant === 'animated' ? getAnimatedEmojis : getStaticEmojis;

  return (
    <FlexContainer
      className={css.container}
      flexDirection="column"
      style={{
        width,
        ...{
          '--container-max-height': maxHeight ?? '100%',
        },
      }}
    >
      <SegmentedControl
        options={[
          {
            label: (
              <FormattedMessage
                id="emoji_picker_animated_option"
                defaultMessage="Animated"
              />
            ),
            value: 'animated',
          },
          {
            label: (
              <FormattedMessage
                id="emoji_picker_static_option"
                defaultMessage="Static"
              />
            ),
            value: 'static',
          },
        ]}
        onChange={option => setVariant(option)}
        selectedOption={variant}
      />
      <EmojiPicker
        getEmojis={getEmojis}
        getCategories={getCategories}
        onEmojiClick={emoji =>
          onEmojiClick({
            ...emoji,
            size:
              variant === 'animated' ? ANIMATED_EMOJI_SIZE : STATIC_EMOJI_SIZE,
          })
        }
      />
    </FlexContainer>
  );
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { CEPAction } from '@autocut/utils/cep/actions.cep.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla } from '../zustand';

export const getResetSequenceSettingsAction = ():
  | CEPAction<'setSequenceVideoDisplayFormat'>
  | undefined => {
  try {
    const userSequenceVideoDisplayFormat =
      autocutStoreVanilla().onGoingProcess.sequenceParams
        ?.userSequenceVideoDisplayFormat;

    if (!userSequenceVideoDisplayFormat) return;

    return {
      action: 'setSequenceVideoDisplayFormat',
      param: [userSequenceVideoDisplayFormat],
      thenFunctions: [],
    };
  } catch (err: any) {
    throw new IncrementalError(err, 'handleResetSequenceSettings');
  }
};

export const handleResetSequenceSettings = async () => {
  try {
    const action = getResetSequenceSettingsAction();

    if (!action) return;

    await evalTS(action?.action, ...action?.param);
  } catch (err: any) {
    throw new IncrementalError(err, 'handleResetSequenceSettings');
  }
};

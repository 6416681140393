import { handleProcessBase } from '../process/handleProcessBase';
import { autocutStoreVanilla } from '../zustand';
import { changeMessage } from '../cutButton/changeMessage';
import { IntlShape } from 'react-intl';
import { IncrementalError } from '../errors/IncrementalError';
import { saveAndPrepareSequenceSettings } from '../cut/saveAndPrepareSequenceSettings';
import { handleResetSequenceSettings } from '../cut/handleResetSequenceSettings';
import { evalTS } from '@autocut/lib/utils/bolt';
import { addXp } from '../game/addXp';
import { sendStats } from '../stats.util';
import { StatType } from '@autocut/types/StatType.enum';
import { StatMode } from '@autocut/types/StatCategory.enum';

const handleViralClips = async (intl: IntlShape) => {
  const {
    sequence: { infos: sequence },
    onGoingProcess: { viralClips },
  } = autocutStoreVanilla();
  if (!viralClips || !sequence) return;

  changeMessage(intl, 'viral_clips_adding_creating_clips', 'Creating clips...');
  try {
    const filteredClips = viralClips.filter(clip => clip.selected);

    const videoDisplayFormat = await saveAndPrepareSequenceSettings();

    await evalTS('createViralClips', filteredClips, videoDisplayFormat);

    await handleResetSequenceSettings();

    const xpGained = filteredClips.length * 0.55;
    await addXp(xpGained);
  } catch (error: any) {
    console.error(error);
    throw new IncrementalError(error, 'handleViralClips');
  }
};

export const handleViralClipsProcess = handleProcessBase({
  executeProcess: handleViralClips,
});

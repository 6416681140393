import { HTMLAttributes } from 'react';
import FlexContainer, {
  FlexContainerProps,
} from '../../designSystem/components/molecules/FlexContainer';
import React from 'react';
import css from './TranscriptChunkContainer.module.css';

export const TranscriptChunkContainer = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & FlexContainerProps) => {
  return (
    <FlexContainer className={`${css.container} ${className}`} {...props}>
      {children}
    </FlexContainer>
  );
};

import logLevel from '@autocut/types/logLevel.enum';
import { CEPAction, runCEPActions } from '@autocut/utils/cep/actions.cep.utils';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { IntlShape } from 'react-intl';
import { getPproVersion } from '../general.utils';
import { getHostName } from '@autocut/utils/system/hostname.system.utils';
import { getUUID } from '@autocut/utils/system/uuid.system.utils';
import { getKey } from '../localStorage.utils';
import { logger, safeJSONStringify } from '../logger';
import { handleProcessBase } from '../process/handleProcessBase';
import { handleTransitions } from '../transitions/handleTransitions/handleTransitions';
import { computeClipsToCut } from './getClipsToCutAndNumberOfSteps';
import { getAiSilences, getLegacySilences } from './getSilences';
import { handleCut } from './handleCut';
import { handleDelete } from './handleDelete';
import { handleNoSilenceEnd } from './handleNoSilenceEnd';
import {
  getResetSequenceSettingsAction,
  handleResetSequenceSettings,
} from './handleResetSequenceSettings';
import { saveAndPrepareSequenceSettings } from './saveAndPrepareSequenceSettings';
import { getParametersForMode } from '../parameters.utils';
import { SilenceParams } from '@autocut/types/SilenceParams';

type GetSilencesResult = {
  cutTimecodes: number[] | undefined;
  timelineBasedSilencesTotal: number[][] | undefined;
};

export const handleCutBase =
  (
    getModeSilences: (
      intl: IntlShape
    ) => Promise<GetSilencesResult> | GetSilencesResult
  ) =>
  async (intl: IntlShape) => {
    try {
      const parameters = getParametersForMode() as SilenceParams;

      await saveAndPrepareSequenceSettings();

      const { cutTimecodes, timelineBasedSilencesTotal } =
        await getModeSilences(intl);

      if (
        !cutTimecodes ||
        !timelineBasedSilencesTotal ||
        cutTimecodes.length === 0 ||
        timelineBasedSilencesTotal.length === 0
      ) {
        await handleNoSilenceEnd();
        await handleResetSequenceSettings();
        return;
      }

      await computeClipsToCut();

      setAutocutStore(
        'onGoingProcess.timelineBasedSilencesTotal',
        timelineBasedSilencesTotal
      );

      const actions: CEPAction<any>[] = [];

      actions.push(...(await handleCut(timelineBasedSilencesTotal, intl)));

      //DELETE
      if (
        ['delete', 'mute', 'delete_keep_spaces'].includes(parameters.silences)
      ) {
        actions.push(
          ...(await handleDelete(timelineBasedSilencesTotal, actions, intl))
        );
      }

      //TRANSITIONS
      if (parameters.transistions !== 'none') {
        actions.push(
          ...(await handleTransitions(timelineBasedSilencesTotal, intl))
        );
      }

      const resetSequenceSettingsAction = getResetSequenceSettingsAction();
      if (resetSequenceSettingsAction)
        actions.push(resetSequenceSettingsAction);

      // WORKER INSTANTIATION
      try {
        if (window.Worker) {
          const workerTimeoutReport = new Worker(
            new URL('../workerTimeoutReport.ts', import.meta.url),
            {
              type: 'module',
            }
          );
          setAutocutStore(
            'onGoingProcess.workerTimeoutReport',
            workerTimeoutReport
          );
          workerTimeoutReport.postMessage({
            type: 'onInit',
            payload: {
              key: getKey(),
              UUID: await getUUID(),
              Host: await getHostName(),
              'PPro.Version': getPproVersion(),
              state: safeJSONStringify(autocutStoreVanilla()),
            },
          });
        }
      } catch {
        logger('handleCut', logLevel.info, `Worker not supported, skipping`);
      }

      // EXECUTING PROCESS
      await runCEPActions(actions);

      await new Promise(resolve => setTimeout(resolve, 50));
      changeMessage(intl, `button_finalizing`, `Finalizing...`);

      setAutocutStore('ui.process.numberOfCutsDone', cutTimecodes.length);
    } catch (err: any) {
      throw new IncrementalError(err, 'handleCutBase');
    } finally {
      const autocutState = autocutStoreVanilla();
      if (autocutState.onGoingProcess.workerTimeoutReport) {
        autocutState.onGoingProcess.workerTimeoutReport.terminate();
        setAutocutStore('onGoingProcess.workerTimeoutReport', undefined);
      }
    }
  };

export const handleCutAiProcess = handleProcessBase({
  executeProcess: handleCutBase(getAiSilences),
  handleProcessErrorAdditional: async () => {
    try {
      await handleResetSequenceSettings();
    } catch {
      //Nothing, if fail we do not want to override the original error
    }
  },
});

export const handleCutLegacyProcess = handleProcessBase({
  executeProcess: handleCutBase(getLegacySilences),
  handleProcessErrorAdditional: async () => {
    try {
      await handleResetSequenceSettings();
    } catch {
      //Nothing, if fail we do not want to override the original error
    }
  },
});

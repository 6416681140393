import { WaveFile } from 'wavefile';

import { fs } from '@autocut/lib/cep/node';
import VADBuilder, { FRAME_SIZE } from '@autocut/lib/webrtc/';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getParametersForMode } from './parameters.utils';

/***
 * Reads a .wav file
 * @param {string} path - Path to the .wav file
 * @returns {number, number, Int8Array[]} - nom du fichier à enregister
 */
const openWaveFile = (
  path: string
): [sampleRate: number, byteRate: number, samples: Uint8Array] => {
  const wav = new WaveFile(fs.readFileSync(path));

  // @ts-ignore
  const sampleRate = wav.fmt.sampleRate;
  // @ts-ignore
  const numberChannels = wav.fmt.numChannels;
  // @ts-ignore
  const byteRate = wav.fmt.byteRate;

  if (
    ![8000, 16000, 32000, 48000].includes(sampleRate) ||
    numberChannels !== 1
  ) {
    throw new IncrementalError(
      'Your audio file is not compatible',
      'openWaveFile'
    );
  }

  return [sampleRate, byteRate, wav.toBuffer()];
};

/***
 * Calculates the Root Mean Square of an array of samples
 * This function is used with the output of WAVDecoder so the `samples` array is often HUGE.
 * That's why we cannot convert it to regular `number` and use array methods.
 * @param {Int8Array} samples - Array of all samples
 * @returns {number} - RMS of the samples
 */
const calculateRMS = (samples: Int8Array): number => {
  let sum = 0;
  for (let i = 0; i < samples.length; i++) {
    sum += samples[i] * samples[i];
  }
  return Math.sqrt(sum / samples.length);
};

/***
 * Converts RMS to dB
 * @param {number} rms - RMS value
 * @returns {number} - dB value
 */
const convertRMSToDB = (rms: number): number => {
  const dbValue = 20 * Math.log10(rms);

  if (!rms || dbValue < -60) {
    return -60;
  }
  return dbValue;
};

/***
 * Process a .wav file to extract an array of dB values
 * @param {string} pathToAudio - Path to the .wav file
 * @param {number} precision - Duration of a pack of samples in seconds
 * @returns {number[]} - Arrays of dB values, each value is the average dB of a pack of samples
 */
const getDBArrayFromWavFile = (path: string, precision: number): number[] => {
  const [sampleRate, _, samples] = openWaveFile(path);
  const samplePerPack = sampleRate * precision;
  const samplesPack = packSamples(samplePerPack, samples);

  const dbArray = samplesPack.map(sample => {
    const rms = calculateRMS(sample as Int8Array);
    return convertRMSToDB(rms);
  });

  return dbArray;
};

/***
 * Packs samples into packs of the same length to process
 * This function is used with the output of WAVDecoder so the `samples` array is often HUGE.
 * That's why we cannot convert it to regular `number` and use array methods.
 * @param {number} samplePerPack - Number of samples per pack to create
 * @param {Int8Array} samples - Array of all samples
 * @returns {Int8Array[]} - Array of packs of samples
 */
const packSamples = (
  samplePerPack: number,
  samples: Uint8Array // ! HUGE array
): Int8Array[] | number[][] => {
  let currentStartPosition = 0;
  const samplesPack = [];
  while (currentStartPosition + samplePerPack < samples.length) {
    samplesPack.push(
      samples.slice(currentStartPosition, currentStartPosition + samplePerPack)
    );
    currentStartPosition += samplePerPack;
  }
  //Padding last array to also have exactly samplePerPack elements
  const sample = new Int8Array(samplePerPack);
  samples.slice(currentStartPosition).forEach((value, index) => {
    sample[index] = value;
  });
  samplesPack.push(sample);
  return samplesPack as Int8Array[];
};

/***
 * Counts number of voiced samples (true) in an array
 * @param {boolean[]} voicedArray - Array of VAD results
 * @returns {number} - Number of voiced samples
 */
const countVoiced = (voicedArray: boolean[]): number => {
  return voicedArray.filter(voiced => voiced === true).length;
};

/***
 * Processs an array of sample to dertermine if it is a voiced portion
 * @param {Int8Array[]} samples - Array of samples
 * @param {any} vad - vad object
 * @returns {boolean} - Is the portion voiced
 */
const processSamples = (
  samples: (Int8Array | number[])[],
  vad: any
): boolean => {
  const results = [];

  for (let i = 0; i < samples.length; i++) {
    results.push(vad.processFrame(samples[i]));
  }
  const voicesCount = countVoiced(results);
  if (voicesCount >= 0.8 * samples.length) {
    return true;
  }
  return false;
};

/***
 * Processs an array of samples to dertermine silence portions
 * @param {Int8Array[]} samplesPack - Array of pack of samples
 * @param {number} byteRate - Bytes/seconds of the file
 * @param {number} padding - Duration of the padding in MS
 * @param {number} packDurationFrames - Number of frames in one pack
 * @param {any} vad - vad object
 * @returns { tabStart: number[], tabEnd: number[] } - Arrays of silence start and end
 */
const findSilences = (
  samplesPack: (Int8Array | number[])[],
  byteRate: number,
  padding: number,
  packDurationFrames: number,
  vad: any
) => {
  const packDurationMs = (packDurationFrames * 1000) / byteRate;
  const numberSamplePadding = Math.floor(padding / packDurationMs);

  const endSilences = [];
  const startSilences = [];
  // Deciding if audio starts with silence of voice
  // After testing we found out that it works better by starting with silence that will be ended after next sample
  let isSilence = true;

  if (isSilence) startSilences.push(0);

  // Checking each pack of sample after the first padding from voice or silence
  for (
    let index = 1;
    index + numberSamplePadding < samplesPack.length;
    index += numberSamplePadding
  ) {
    const currentResult = processSamples(
      samplesPack.slice(index, index + numberSamplePadding),
      vad
    );

    // If voice is found while in a silence portion: it is the end of a silence
    if (currentResult && isSilence) {
      endSilences.push(((index - numberSamplePadding) * packDurationMs) / 1000);
      isSilence = false;
      // If silence is found while in a voiced portion: it is the start of a silence
    } else if (!currentResult && !isSilence) {
      startSilences.push(
        ((index + numberSamplePadding) * packDurationMs) / 1000
      );
      isSilence = true;
    }
  }

  // If checking ends with silence, we close the last silence part at the very end of the file
  if (isSilence) {
    endSilences.push((samplesPack.length * packDurationMs) / 1000);
  }

  return {
    tabStart: startSilences,
    tabEnd: endSilences,
  };
};

/***
 * Process a .wav file to detect silences
 * @param {string} pathToAudio - Path to the .wav file
 * @returns { tabStart: number[], tabEnd: number[] } - Arrays of silence start and end
 */
const getAiVideoSilencesIntervals = async (pathToAudio: string) => {
  try {
    const parameters = getParametersForMode<'ai'>();

    const VAD = await VADBuilder();
    const [sampleRate, byteRate, samples] = openWaveFile(pathToAudio);
    const vad = new VAD(parameters.aggressivenessLevel, sampleRate);
    const samplesPack = packSamples(FRAME_SIZE[sampleRate], samples);
    const { tabStart, tabEnd } = findSilences(
      samplesPack,
      byteRate,
      30,
      FRAME_SIZE[sampleRate],
      vad
    );

    if (tabEnd.length == tabStart.length) return { tabStart, tabEnd };

    return { tabStart: [], tabEnd: [] };
  } catch (err: any) {
    throw new IncrementalError(err, 'getAiVideoSilencesIntervals');
  }
};

export {
  getAiVideoSilencesIntervals,
  packSamples,
  getDBArrayFromWavFile,
  convertRMSToDB,
};

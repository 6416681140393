import { fs, os } from '@autocut/lib/cep/node';
import { evalTS } from '@autocut/lib/utils/bolt';
import { debounce } from '@autocut/utils/debounce';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import path from 'path';
//@ts-ignore
import { PNG } from 'pngjs/browser';
import { retryTimedOutFunction } from './general.utils';

export const NO_ACTIVE_SEQUENCE_ERROR_MESSAGE = 'NO_ACTIVE_SEQUENCE';

export const exportCurrentFrameAsPNGSync = async (
  src: string,
  time?: number,
  fileName = 'previewFrame.png'
) => {
  try {
    if (src) {
      URL.revokeObjectURL(src);
    }
    const tempFolderpath = os.tmpdir();
    const outpath = path.join(tempFolderpath, fileName);

    const isExported = time
      ? await evalTS('exportCurrentFrameAsPNGAtTime', outpath, time)
      : await evalTS('exportCurrentFrameAsPNG', outpath);
    // When CEP is done with the exportCurrent... function the file is not available right await,
    // so we delay the read until that.

    if (!isExported) {
      throw new IncrementalError(
        NO_ACTIVE_SEQUENCE_ERROR_MESSAGE,
        'exportCurrentFrameAsPNG'
      );
    }

    const blobUrl = await retryTimedOutFunction(() =>
      getCurrentFramePNGBlobUrl(outpath)
    );

    try {
      await evalTS('cleanPreviewFrame', outpath);
    } catch {
      //NOTHING
    }

    return blobUrl;
  } catch (err: any) {
    if (err.message.includes(NO_ACTIVE_SEQUENCE_ERROR_MESSAGE)) {
      throw err;
    } else {
      const finalErr = new IncrementalError(err, 'exportCurrentFrameAsPNG');
      finalErr.reportToSentry();
      return '';
    }
  }
};

export const exportCurrentFrameAsPNG = debounce(
  exportCurrentFrameAsPNGSync,
  250
);

export const getCurrentFramePNGBlobUrl = (outpath: string) => {
  try {
    const data = fs.readFileSync(outpath);
    // fs can access the png file before it's done being writen,
    // causing a half black image to be displayed.
    // If we try to decode the buffer in this case, the following line should crash.
    // hacky fix but haven't found a better way
    PNG.sync.read(data);
    const blob = new Blob([data]);
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  } catch (err) {
    throw new Error('PNG file still being writed');
  }
};

import * as React from 'react';

import './clockCard.css';

type Props = {
  value: string;
  unit: string;
};

const ClockCard = ({ value, unit }: Props) => {
  return (
    <div className="clock-container">
      <div>{value}</div>
      <div>{unit}</div>
    </div>
  );
};

export { ClockCard };

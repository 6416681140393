import { IncrementalError } from '../errors/IncrementalError';
import { getParametersForMode } from '../parameters.utils';
import { convertRMSToDB } from '../vad.utils';
import { openWaveFile } from '../waveForm.utils';
import { computeRMS } from '../zoom/computeRMS';
import { oneDKMean } from './oneDKMean';

export const getTalkingTimelineViaClusters = (
  wavFilePath: string,
  clips: Clip[]
) => {
  try {
    const { precision: precisionParameter } = getParametersForMode('podcast');

    const [sampleRate, PCM] = openWaveFile(wavFilePath) as [number, number[]];
    const rmsValues = computeRMS(PCM, sampleRate, precisionParameter);
    const dbArray = rmsValues.map(rms => convertRMSToDB(rms));

    const clusters = oneDKMean(dbArray);
    const maxDbSilence = Math.max(...clusters[0]);

    const startTimeOfTrack = clips.reduce((acc, clip) => {
      return Math.min(acc, clip.start);
    }, Infinity);
    const timeline = Array(
      Math.floor(startTimeOfTrack / precisionParameter)
    ).fill(0);

    for (const dbValue of dbArray) {
      timeline.push(dbValue <= maxDbSilence ? 0 : 1);
    }

    return timeline;
  } catch (err: any) {
    throw new IncrementalError(err, 'getTalkingTimelineViaClusters');
  }
};

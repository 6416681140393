import React from 'react';
import css from './PaddedSection.module.css';

export type PaddedSectionType = {
  children: React.ReactNode;
};

export const PaddedSection = ({ children }: PaddedSectionType) => {
  return <div className={css.root}>{children}</div>;
};

import { ProcessTranscriptFunction } from '@autocut/types/transcription';
import { handleBackup } from '@autocut/utils/backup/handleBackup';
import { getChapters } from '@autocut/utils/chapters/getChapters';
import { processChapters } from '@autocut/utils/chapters/processChapters';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { handleFinalMessage } from '@autocut/utils/cutButton/handleFinalMessage';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { fetchTranscriptFromExportedAudio } from '@autocut/utils/transcript/fetchTranscriptFromExportedAudio.utils';
import { uploadAudioFileFromExportedAudio } from '@autocut/utils/transcript/uploadWAVFileFromExportedAudio.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';

export const handleChaptersProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    const {
      sequence: { infos: sequence },
    } = autocutStoreVanilla();

    if (!sequence) {
      throw new IncrementalError(
        'Missing sequence',
        'Chapters handleTranscript'
      );
    }

    changeMessage(intl, 'chapters_backup', 'Backing up your sequence...');
    await handleBackup(intl);

    const exportedAudioSequenceInfos = await getExportedAudioInfos(
      {
        sequence: 'selected',
        options: {
          format: 'mp3',
        },
      },
      intl
    );
    const exportedAudioInfos = [...exportedAudioSequenceInfos.sequence];
    const exportedAudioInfosWithUrl =
      await uploadAudioFileFromExportedAudio<'sentences'>(
        exportedAudioInfos,
        p => {
          setAutocutStore('onGoingProcess.progress', p);
        },
        intl
      );
    setAutocutStore('onGoingProcess.progress', undefined);

    changeMessage(intl, 'chapters_analyse', 'Analysing audio...');

    const exportedAudioInfosWithTranscript =
      await fetchTranscriptFromExportedAudio(
        exportedAudioInfosWithUrl,
        'sentences',
        usedModel
      );
    if (!exportedAudioInfosWithTranscript[0].transcript) {
      throw new IncrementalError('No transcript', 'Chapters handleTranscript');
    }

    changeMessage(intl, 'chapters_getting_chapters', 'Generating Chapters...');

    const transcript = exportedAudioInfosWithTranscript[0].transcript.map(
      (sentence, index) => ({ sentence: sentence.text, id: index })
    );

    const response = await getChapters({
      transcript,
    });

    const selectionStart = sequence.audioTracks.reduce(
      (acc, track) =>
        Math.min(
          acc,
          track.selectedClips.reduce(
            (acc, clip) => Math.min(acc, clip.start),
            Number.MAX_SAFE_INTEGER
          )
        ),
      Number.MAX_SAFE_INTEGER
    );

    const chapters = processChapters(
      response,
      exportedAudioInfosWithTranscript[0].transcript,
      selectionStart
    );

    setAutocutStore('onGoingProcess.chapters', chapters);

    handleFinalMessage(
      intl,
      'chapters_transcript_finished',
      'Chapters generated'
    );
  };

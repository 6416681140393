import { decodeCaptionParams } from '@autocut/modes/captions/utils/presets.captions.utils';
import { autocutApi } from '../http.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import { AutoCutApiError } from '../errors/AutoCutApiError';

export const getPublicCaptionsPresets = async ({
  search,
  page,
  limit,
}: {
  search?: string;
  page?: number;
  limit?: number;
}) => {
  const res = await autocutApi
    .get<{
      presets: ({ params: string } & Omit<CaptionsPreset, 'params'>)[];
      hasNextPage: boolean;
    }>(`/captions-presets/public`, {
      params: {
        search,
        page,
        limit,
      },
    })
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'getPublicCaptionsPresets'),
      });

      return null;
    });

  if (res?.status === 200) {
    const captionsPresets: CaptionsPreset[] = res.data.presets.map(
      ({ id, title, params, usageCount, videoUrl, thumbnailUrl }) => {
        const decodedParams = decodeCaptionParams(params);
        return {
          id,
          title,
          params: decodedParams,
          usageCount,
          videoUrl,
          thumbnailUrl,
        };
      }
    );

    return { captionsPresets, hasNextPage: res.data.hasNextPage };
  }

  return null;
};

import chroma from 'chroma-js';
import React, { ComponentPropsWithoutRef, CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

export type TranslatedMessageAdditionalProps = {
  href?: string;
  color?: CSSProperties['color'];
  backgroundColor?: CSSProperties['backgroundColor'];
};

export type TranslatedMessageProps = ComponentPropsWithoutRef<
  typeof FormattedMessage
> & { valuePamareters?: TranslatedMessageAdditionalProps; noAnchor?: boolean };

export const getDefaultTranslatedMessageValues = (
  valuePamareters: TranslatedMessageAdditionalProps
): Exclude<TranslatedMessageProps['values'], undefined | null> => {
  const { href, color, backgroundColor } = valuePamareters;
  return {
    embedTranslation: chunks => {
      console.log('embedTranslation', chunks, color);
      return typeof chunks[0] === 'string' ? (
        <TranslatedMessage
          id={chunks[0]}
          defaultMessage={chunks[0]}
          valuePamareters={valuePamareters}
          noAnchor
        />
      ) : (
        chunks
      );
    },
    br: <br />,
    b: chunks => <b>{chunks}</b>,
    li: chunks => <li>{chunks}</li>,
    i: chunks => <i>{chunks}</i>,
    a: chunks =>
      href ? (
        <a
          href={href}
          style={{
            color: 'inherit',
            cursor: 'pointer',
          }}
        >
          {chunks}
        </a>
      ) : (
        chunks
      ),
    color: chunks => (color ? <span style={{ color }}>{chunks}</span> : chunks),
    backgroundColor: chunks =>
      backgroundColor ? (
        <span
          style={{
            backgroundColor,
            borderRadius: 2,
            padding: '2px 4px',
          }}
        >
          {chunks}
        </span>
      ) : (
        chunks
      ),
    border: chunks =>
      color ? (
        <span
          style={{
            border: `1px solid ${color}`,
            color: chroma(color).luminance() > 0.8 ? 'black' : 'white',
            borderRadius: 3,
            padding: '2px 4px',
          }}
        >
          {chunks}
        </span>
      ) : (
        chunks
      ),
    opacity50: chunks => <span style={{ opacity: '50%' }}>{chunks}</span>,
  };
};

export const TranslatedMessage = ({
  id,
  values,
  valuePamareters,
  ...props
}: TranslatedMessageProps) => {
  return (
    <FormattedMessage
      id={id}
      values={{
        ...getDefaultTranslatedMessageValues(valuePamareters || {}),
        ...values,
      }}
      {...props}
    >
      {chunks => (
        <>
          <span
            id={id}
            style={{
              display: 'block',
              position: 'relative',
              top: -150,
              visibility: 'hidden',
            }}
          />
          {chunks}
        </>
      )}
    </FormattedMessage>
  );
};

import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { ButtonVariant } from './variants';

import css from './Button.module.scss';
import { ColorKeys } from '@autocut/designSystem/colors';
import { Text } from '../Text/Text';

export type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  variant?: ButtonVariant;
  size?: 'lg' | 'md' | 'sm';
  className?: string;
  style?: CSSProperties;
  color?: CSSProperties['color'] | ColorKeys;
  fullWidth?: boolean;
} & Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children' | 'onClick' | 'disabled' | 'className' | 'style'
>;

export const Button = ({
  children,
  onClick,
  disabled = false,
  variant = 'primary',
  size = 'md',
  className,
  style,
  color = 'white',
  fullWidth = true,
  ...props
}: ButtonProps) => {
  return (
    <button
      style={
        {
          '--color': color,
          '--width': fullWidth ? '100%' : 'fit-content',
          ...style,
        } as CSSProperties
      }
      className={`${css.root} ${className}`}
      data-variant={variant}
      data-size={size}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      <Text variant="textSm" color="white">
        {children}
      </Text>
    </button>
  );
};

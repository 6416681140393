type UnsupportedScenariosTranslation = {
  id: string;
  defaultMessage: string;
};

export type UnsupportedScenario = {
  id: string;
  title: UnsupportedScenariosTranslation;
  body: UnsupportedScenariosTranslation[];
  link?: string;
  isFixable?: boolean;
};

// No unsupportedScenarios as of today, i'm still leaving it as it may be useful in the future
export const UnsupportedScenarios = {
  unknown: {
    id: 'unknown',
    title: {
      id: 'title_not_supported_modal_unknown',
      defaultMessage: 'An error occured',
    },
    body: [
      {
        id: 'text_not_supported_modal_unknown',
        defaultMessage:
          'We are sorry, but an error occured while trying to process your sequence...',
      },
      {
        id: 'text_end_not_supported_modal_unknown',
        defaultMessage: 'Please contact us.',
      },
    ] as UnsupportedScenariosTranslation[],
  },
} as const satisfies { [id: string]: UnsupportedScenario };

export type UnsupportedScenarioKeys = keyof typeof UnsupportedScenarios;
export type UnsupportedScenarioIds =
  (typeof UnsupportedScenarios)[UnsupportedScenarioKeys]['id'];

import { InfoText } from '@autocut/components/atoms/InfoText';
import React, { useState, CSSProperties, useEffect } from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import './Helper.css';
import PopupPortal, { PopupPortalProps } from '../PopupPortal/PopupPortal';
import { GoInfo } from 'react-icons/go';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export type HelperProps = {
  size?: CSSProperties['width'];
  textStyle?: CSSProperties;
  strokeWidth?: number | string;
  color?: string;
} & Pick<PopupPortalProps, 'position'> &
  (
    | {
        id_message: string;
        default_message?: string;
      }
    | {
        children: React.ReactNode;
      }
  );

export const Helper = ({
  size = '16px',
  strokeWidth = '1px',
  color = 'white',
  textStyle,
  position,
  ...props
}: HelperProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [iconRef, setIconRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    return () => {
      setIsHovered(false);
    };
  }, []);

  const content =
    'children' in props ? (
      props.children
    ) : 'id_message' in props && 'default_message' in props ? (
      <p style={textStyle}>
        <TranslatedMessage
          id={props.id_message}
          defaultMessage={props.default_message}
        />
      </p>
    ) : null;

  if (!content) return null;

  return (
    <FlexContainer ref={ref => setIconRef(ref)} alignItems="center">
      {iconRef && (
        <GoInfo
          fill={color}
          size={size}
          strokeWidth={strokeWidth}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ cursor: 'pointer' }}
        />
      )}

      {isHovered && iconRef && (
        <PopupPortal target={iconRef} position={position}>
          <InfoText
            hideHelper
            style="info"
            blob={{
              size: '40%',
              blurRadius: 40,
              position: {
                top: position === 'left' ? '-15%' : '75%',
                right: position === 'left' ? '-15%' : '75%',
              },
            }}
          >
            {content}
          </InfoText>
        </PopupPortal>
      )}
    </FlexContainer>
  );
};

import { getAudioClips } from '../timeline/selectedInfos.utils';
import { evalTS } from '@autocut/lib/utils/bolt';

export const cutSwearWords = async (cutTimecodes: number[]) => {
  const selectedAudioClips = getAudioClips();
  const tracksIndexes = [
    ...new Set(selectedAudioClips.map(clip => clip.numTrack)),
  ];

  for (const trackIndex of tracksIndexes) {
    await evalTS('cutClips', cutTimecodes, trackIndex, 2);
  }
};

import { RegionsParam } from '@autocut/components/atoms/WaveForm/WaveForm';
import { AutocutModes, AutocutModeState } from '@autocut/enums/modes.enum';
import { fs } from '@autocut/lib/cep/node';
import { decodeSync } from '@autocut/lib/utils/wav-encoder';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { getParametersForMode } from './parameters.utils';

export { getDbBuffer, getMaxCPMFromWAVFile, normalizedToDB, openWaveFile };

export { calculateRegions, drawDbLimit, drawLineSegment, drawNotUsed };

export { BLACK_COLOR, GRAY_COLOR, GREEN_COLOR, RED_COLOR };

const GREEN_COLOR = 'rgba(0, 255, 0, 0.25)';
const BLACK_COLOR = 'rgba(0, 0, 0, 1)';
const GRAY_COLOR = 'rgba(0, 0, 0, 0.5)';
const RED_COLOR = 'rgba(255, 0, 0, 0.75)';

const openWaveFile = (path: string): [number, any] => {
  try {
    const wav = decodeSync(fs.readFileSync(path));

    const sampleRate = wav.sampleRate;
    const numberChannels = wav.numberOfChannels;

    if (
      ![8000, 16000, 32000, 48000].includes(sampleRate) ||
      numberChannels !== 1
    ) {
      throw 'Your audio file is not compatible';
    }

    return [sampleRate, wav.channelData[0]];
  } catch (error) {
    return [0, new Float32Array()];
  }
};

const _reduceSamples = (
  samples: Float32Array,
  numberPack: number
): [number[], number] => {
  const samplePerPack = Math.floor(samples.length / numberPack);
  let currentStartPosition = 0;
  const samplesPack = [];
  let max = 0;
  while (currentStartPosition + samplePerPack < samples.length) {
    let sum = 0;
    let localMax = 0;
    for (const sampleValue of samples.slice(
      currentStartPosition,
      currentStartPosition + samplePerPack
    )) {
      sum += sampleValue;
      if (localMax > sampleValue) localMax = sampleValue;
    }
    const mean = Math.abs(sum / samplePerPack);
    samplesPack.push(localMax);
    if (mean > max) max = mean;
    currentStartPosition += samplePerPack;
  }
  return [samplesPack, max];
};

const _normalizeSamples = (
  samples: number[],
  maxValue: number
): Float32Array => {
  const toReturn = new Float32Array(samples.length);
  maxValue = maxValue === 0 ? 1 : maxValue;

  for (let i = 0; i < samples.length; i++) {
    toReturn[i] = Math.abs(samples[i] / maxValue);
  }

  return toReturn;
};

const _bufferToDb = (buffer: Float32Array, maxValue: number): number[] => {
  const toReturn = Array.from(buffer, bufferValue => {
    let valueDb = 20 * Math.log10(bufferValue / maxValue);
    if (!isFinite(valueDb) || valueDb < -60) {
      valueDb = -60;
    }
    return valueDb;
  });

  return toReturn;
};

const _normalizeDb = (buffer: number[]): number[] => {
  return buffer.map(n => (n + 60) / 60);
};

const _getMaxValue = (buffer: number[]): number => {
  const max = buffer.reduce(
    (max, currentValue) => Math.max(max, currentValue),
    0
  );

  return max;
};

const normalizedToDB = (normalized: number) => {
  const dbValue = 20 * Math.log10(normalized);

  if (!normalized || dbValue < -60) {
    return -60;
  }
  return dbValue;
};

const getMaxCPMFromWAVFile = (path: string) => {
  const [_, samples] = openWaveFile(path);
  console.log('filePath Sample size', samples.length);
  const maxCPM = _getMaxValue(samples);

  return maxCPM;
};

const getDbBuffer = (path: string, precision: number, maxCPM = 0) => {
  const [_, samples] = openWaveFile(path);
  console.log('filePath Sample size', samples.length, maxCPM);

  const [reduced, maxValue] = _reduceSamples(samples, precision);
  const normalizedSamples = _normalizeSamples(reduced, 1);

  //return _normalizeDb(dbValues);
  return normalizedSamples;
};

const drawNotUsed = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement
) => {
  ctx.beginPath();
  ctx.rect(0, -canvas.height, canvas.width, canvas.height);
  ctx.fillStyle = GRAY_COLOR;
  ctx.fill();
};

const drawLineSegment = (
  ctx: CanvasRenderingContext2D,
  x: number,
  height: number,
  width: number,
  isEven: number
) => {
  // Drawing waveform lines
  ctx.lineWidth = 1; // how thick the line is
  ctx.strokeStyle = '#fff'; // what color our line is
  ctx.beginPath();
  height = -height * 2;
  ctx.moveTo(x, 0);
  ctx.lineTo(x, height);
  ctx.arc(
    x + width / 2,
    height,
    width / 2,
    Math.PI,
    0,
    isEven as unknown as boolean
  );
  ctx.lineTo(x + width, 0);
  ctx.stroke();
};

const drawDbLimit = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  noiseLevel: number
) => {
  // Drawing line where current dB limit is
  const lineHeight: number = Math.pow(10, noiseLevel / 20) * canvas.height;

  ctx.lineWidth = 1; // how thick the line is
  ctx.strokeStyle = 'red'; // what color our line is
  ctx.beginPath();

  ctx.moveTo(0, -lineHeight);
  ctx.lineTo(canvas.width, -lineHeight);
  ctx.stroke();
};

const calculateRegions = (
  canvas: HTMLCanvasElement,
  silencesInterval: number[][],
  duration: number,
  previewStart: number
): RegionsParam[] => {
  const autocutState = autocutStoreVanilla();
  const isAi =
    autocutState.ui.process.mode ===
    (AutocutModes.Ai.id as unknown as AutocutModeState);
  const { marginBefore, marginAfter } = getParametersForMode<'silence'>();
  const regions = [];
  // Adding green zone on the full timeline
  regions.push({
    startX: 0,
    width: canvas.width,
    color: GREEN_COLOR,
  });

  // Adding red zones
  for (let index = 0; index < silencesInterval.length; index++) {
    const startPointSec = silencesInterval[index][0] - previewStart;
    const endPointSec = silencesInterval[index][1] - previewStart;

    const startPoint = (startPointSec / duration) * canvas.width;
    const endPoint = (endPointSec / duration) * canvas.width;

    const rectWidth = endPoint - startPoint;

    const redRegion = {
      startX: startPoint,
      width: rectWidth,
      color: RED_COLOR,
    };
    regions.push(redRegion);

    if (isAi) continue;

    // Adding margins
    if (startPoint !== 0) {
      const beforeWidth = (marginBefore / duration) * canvas.width;
      regions.push({
        startX: startPoint - beforeWidth,
        width: beforeWidth,
        color: GREEN_COLOR,
      });
    }

    if (endPoint !== canvas.width) {
      const afterWidth = (marginAfter / duration) * canvas.width;
      regions.push({
        startX: endPoint,
        width: afterWidth,
        color: GREEN_COLOR,
      });
    }
  }

  return regions;
};

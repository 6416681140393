import * as React from 'react';

import { LocaleContext } from '../context/LocaleContext';

export const useLocale = () => {
  const context = React.useContext(LocaleContext);
  if (context === undefined) {
    throw new Error(
      'Please make sure you use this hook in the LocaleProvider component'
    );
  }
  return context;
};

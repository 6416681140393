import {
  ExportDescriptor,
  ExportDescriptorTrackWithIntervals,
} from '@autocut/types/ExportedAudio';
import { IncrementalError } from '../errors/IncrementalError';
import { getVideoClips } from '../timeline/selectedInfos.utils';
import { autocutStoreVanilla } from '../zustand';

export const getAllClipsDescriptor = () => {
  const sequenceInfos = autocutStoreVanilla().sequence.infos;
  if (!sequenceInfos)
    throw new IncrementalError('No sequenceInfos', 'getAllClipsDescriptor');

  const descriptor = {
    sequence: {
      audioTracks: sequenceInfos.audioTracks.map(audioTrack => ({
        trackIndex: audioTrack.index,
        clips: 'selected' as const,
      })),
    },
  };

  return descriptor;
};

/**
 * For what I call an effective video interval, see : https://excalidraw.com/#room=6a3b2c1905f2164f1a58,ToCx7eANkSg6zy_Ib5oFWQ 'v2' part
 */
export const getAllEffectiveVideoIntervalsDescriptor = (): {
  sequence: number[][];
} => {
  type Point = {
    time: number;
    type: 'start' | 'end';
  };

  const selectedVideoClips = getVideoClips();

  const points: Point[] = selectedVideoClips.flatMap(clip => [
    { time: clip.start, type: 'start' },
    { time: clip.end, type: 'end' },
  ]);
  points.sort(
    (a, b) =>
      a.time - b.time || (a.type === 'end' && b.type === 'start' ? -1 : 1)
  );

  // Iterate through points to find and create intervals without spaces between clips
  const intervals: number[][] = [];
  let activeClipCount = 0; // Used to handle space between clips
  let lastTime = points[0]?.time;

  points.forEach(point => {
    if (activeClipCount > 0) {
      // If there are active clips, we create/use an interval
      // only create a new interval if time has progressed to avoid [time, time] intervals
      if (lastTime !== point.time) {
        intervals.push([lastTime, point.time]);
      }
    }

    if (point.type === 'start') {
      activeClipCount += 1;
    } else {
      activeClipCount -= 1;
    }

    lastTime = point.time;
  });

  const descriptor = {
    sequence: intervals,
  };

  return descriptor;
};

export const getAllSuccessiveClipsDescriptor = () => {
  const sequenceInfos = autocutStoreVanilla().sequence.infos;
  if (!sequenceInfos)
    throw new IncrementalError(
      'No sequenceInfos',
      'getAllSuccessiveClipsDescriptor'
    );

  type Cluster = [number, number]; // Tuple type for a cluster [start, end]
  const getSuccessiveClipsByTrack = (audioTracks: Track[]): Cluster[][] => {
    const tracksClusters: Cluster[][] = [];

    audioTracks.forEach(track => {
      const trackClusters: Cluster[] = [];
      let currentCluster: Cluster | null = null;

      track.selectedClips.forEach(clip => {
        if (currentCluster === null) {
          currentCluster = [clip.start, clip.end];
        } else {
          if (currentCluster[1] === clip.start) {
            currentCluster[1] = clip.end;
          } else {
            trackClusters.push(currentCluster);
            currentCluster = [clip.start, clip.end];
          }
        }
      });

      if (currentCluster !== null) {
        trackClusters.push(currentCluster);
      }

      tracksClusters.push(trackClusters);
    });

    return tracksClusters;
  };

  const clustersByTracks = getSuccessiveClipsByTrack(sequenceInfos.audioTracks);

  const descriptor = {
    sequence: {
      audioTracks: clustersByTracks.map((trackClusters, trackIndex) => ({
        trackIndex,
        intervalsToExport: [...trackClusters],
      })),
    },
  };

  return descriptor;
};

export const getTracksAtIntervalsDescriptor = (
  trackIds: number[],
  intervalStart: number,
  intervalEnd: number
): ExportDescriptor<{
  audioTracks: ExportDescriptorTrackWithIntervals[];
}> => {
  const descriptor = {
    sequence: {
      audioTracks: trackIds.map(trackId => ({
        trackIndex: trackId,
        intervalsToExport: [[intervalStart, intervalEnd]] as Array<
          [number, number]
        >,
      })),
    },
  };

  return descriptor;
};

import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import React, {
  createContext,
  CSSProperties,
  useMemo,
  useRef,
  useState,
} from 'react';

import css from './ParametersGroupLayout.module.css';
import {
  Switch,
  SwitchRefElement,
} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export type ParametersGroupLayoutProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
  helperContent?: React.ReactNode;
  status?: boolean;
  onStatusChange?: (status: boolean) => void;
  hiddenContent?: React.ReactNode;
  headerAdditionalContent?: React.ReactNode;
  leftBorderWidth?: number;
  contentContainerStyle?: CSSProperties;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>;

export const ParametersGroupLayout = ({
  children,
  title,
  helperContent,
  status = true,
  onStatusChange,
  hiddenContent,
  leftBorderWidth,
  contentContainerStyle,
}: ParametersGroupLayoutProps) => {
  const [opened, setOpened] = useState(true);

  const { isInGroup } = React.useContext(ParameterGroupContext);

  const hasHiddenContent = hiddenContent !== undefined;
  const isOpened = status && (!!onStatusChange ? status : opened);
  const handleClick = !!onStatusChange
    ? onStatusChange
    : (checked: boolean) => {
        setOpened(checked);
      };

  const childrenNodes = useMemo(() => {
    //Filter children to remove nodes that are not ParameterLayout component
    const childrenArray = React.Children.toArray(children);
    //Extract from parameterNodes the title and the children
    const childrenNodes = childrenArray.map((child: any, index: number) => {
      return child?.type?.name === ParameterLayout.name ? (
        <React.Fragment key={index}>
          <ParameterLayout.Content {...child.props} />
        </React.Fragment>
      ) : (
        child
      );
    });
    return childrenNodes;
  }, [children]);

  const switchRef = useRef<SwitchRefElement>(null);

  const isUnavailable = !isOpened && !hasHiddenContent;

  return (
    <ConditionalWrap
      wrap={children => (
        <FormSection
          title={
            <FlexContainer flexDirection="row" gap={8} alignItems="center">
              {title}
              {onStatusChange || hasHiddenContent ? (
                <Switch
                  ref={switchRef}
                  checked={isOpened}
                  onChange={handleClick}
                  size={16}
                />
              ) : null}
            </FlexContainer>
          }
          description={helperContent}
        >
          {children}
        </FormSection>
      )}
      condition={!!title}
    >
      <ConditionalWrap
        wrap={children => <PaddedSection>{children}</PaddedSection>}
        condition={!isInGroup}
      >
        <div
          className={`${css.contentContainer} ${
            isUnavailable ? css.disabledContainer : ''
          }`}
          style={
            {
              '--border-width': leftBorderWidth,
              ...contentContainerStyle,
            } as CSSProperties
          }
          onClick={() => {
            if (isUnavailable) switchRef.current?.blink();
          }}
        >
          <ParameterGroupContext.Provider value={{ isInGroup: true }}>
            {isOpened
              ? childrenNodes
              : hasHiddenContent
              ? hiddenContent
              : childrenNodes}
          </ParameterGroupContext.Provider>
        </div>
      </ConditionalWrap>
    </ConditionalWrap>
  );
};

interface ParameterGroupContext {
  isInGroup: boolean;
}

export const ParameterGroupContext = createContext<ParameterGroupContext>({
  isInGroup: false,
});

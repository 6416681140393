import { CEPAction } from '@autocut/utils/cep/actions.cep.utils';

export const insertAudioTracksForTransition = async (
  audioTracks: Track[]
): Promise<[number[], CEPAction<'insertTrackAt'>[]]> => {
  const insertedIndexes = [];
  const insertTrackActions: CEPAction<'insertTrackAt'>[] = [];

  let offset = 0;

  for (const audioTrack of audioTracks) {
    const newTrackIndex = audioTrack.index + offset + 1;
    insertTrackActions.push({
      action: 'insertTrackAt',
      param: ['audio', 1, newTrackIndex],
      thenFunctions: [],
    });

    insertedIndexes.push(newTrackIndex);
    offset++;
  }

  return [insertedIndexes, insertTrackActions];
};

import { z as zod } from 'zod';
import { rgbValidationSchema } from './rgb.validationSchema';

export const textBoxValidationSchema = zod.object({
  enabled: zod.boolean(),
  xPadding: zod.number().int().min(0, 'validation_error_textBox_xPadding'),
  yPadding: zod.number().int().min(0, 'validation_error_textBox_yPadding'),
  opacity: zod
    .number()
    .min(0, 'validation_error_textBox_opacity_min')
    .max(100, 'validation_error_textBox_opacity_max'),
  color: rgbValidationSchema,
  radius: zod.number().int().min(0),
});

/***
 * Applique une union sur un ensemble d'intervalles
 * @example
 * // return [2, 10]
 * stichIntervals([[2, 5], [4, 9], [7, 10]])
 * // return [[2, 5], [6, 10]]
 * stichIntervals([[2, 5], [6, 9], [7, 10]])
 * @param {number[][]} intervals
 * @returns {number[]}
 */
export const stichIntervals = (intervals: number[][], margin = 0) => {
  const copyIntervals: number[][] = JSON.parse(JSON.stringify(intervals));
  const sortedIntervals = copyIntervals.sort((a, b) => a[0] - b[0]);
  const newIntervals: number[][] = [];

  for (let i = 0; i < intervals.length; i++) {
    // Si newIntervals est vide ou si la borne supérieure du dernier élément de newIntervals est inférieure à la borne inférieure de l'intervalle courant moins la marge,
    // alors on ajoute l'intervalle courant à newIntervals
    if (
      !newIntervals.length ||
      newIntervals[newIntervals.length - 1][1] < sortedIntervals[i][0] - margin
    ) {
      newIntervals.push(sortedIntervals[i]);
    }
    // Sinon on modifie la borne supérieure du dernier élément de newIntervals pour la plus grande borne supérieure entre celle du dernier intervalle de newIntervals et celle
    // de l'intervalle courant
    else {
      newIntervals[newIntervals.length - 1][1] = Math.max(
        newIntervals[newIntervals.length - 1][1],
        sortedIntervals[i][1]
      );
    }
  }
  return newIntervals;
};

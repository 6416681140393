import { ExportedAudioElementWithTranscript } from '@autocut/types/Captions';
import { autocutApi } from '../http.utils';
import { AxiosResponse } from 'axios';

export type DeepgramTranscriptResponse = {
  deepgramRequestId: string;
};
export type GoogleTranscriptResponse = {
  googleOperationName: string;
  location?: GoogleLocation;
};
export type TranscriptionProviders = 'deepgram' | 'google';
type GoogleLocation = 'us-central1' | 'europe-west4' | 'asia-southeast1';

type UserOptions = {
  value: string;
  model: string;
  provider: TranscriptionProviders;
  location?: GoogleLocation;
};

export const launchTranscript = async <T extends 'utterances' | 'sentences'>({
  userOptions,
  exportedAudioInfo,
}: {
  userOptions: UserOptions;
  exportedAudioInfo: ExportedAudioElementWithTranscript<T>;
}) => {
  return (
    await autocutApi.post<
      DeepgramTranscriptResponse | GoogleTranscriptResponse,
      AxiosResponse<DeepgramTranscriptResponse | GoogleTranscriptResponse>,
      {
        userOptions: UserOptions;
        signedUrl: string;
      }
    >(`/transcription/launchTranscription`, {
      userOptions,
      signedUrl: exportedAudioInfo.signedUrl ?? '',
    })
  ).data;
};

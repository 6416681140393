import { node_exec_async as node_exec } from '@autocut/lib/cep/node';
import logLevel from '@autocut/types/logLevel.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { logger } from './logger';

export const exec = async ({
  command,
  options = {},
}: {
  command: string;
  options?: { [key: string]: any };
  sentryData?: {
    fingerPrint: string;
    context?: { [key: string]: any };
  };
  disableSentryReport?: boolean;
}) => {
  try {
    logger('execUtils', logLevel.info, `Launching ${command}`);
    const { stdout, stderr } = await node_exec(command, options).catch(
      error => {
        throw new IncrementalError(error, 'node_exec');
      }
    );
    logger('execUtils', logLevel.info, `Command ${command} ended successfully`);

    return { stdout, stderr };
  } catch (error: any) {
    throw new IncrementalError(error, 'exec');
  }
};

import { ModalType } from '@autocut/enums/modals.enum';
import { csi, evalTS } from '@autocut/lib/utils/bolt';
import { createOrUpdateUser } from '../stats.util';
import { setAutocutStore } from '../zustand';

export const BREAKING_VERSION: string[] = [];

const UNSUPPORTED_RULES = [
  // (version: string) => BREAKING_VERSION.includes(version),
  (version: string) => {
    const [major, minor, sub] = version.split('.').map(Number);
    return major < 22;
  },
];

export const checkPproVersion = async () => {
  const host = csi.getHostEnvironment();
  const version = host.appVersion;
  setAutocutStore('ppro.version', version);

  if (UNSUPPORTED_RULES.some(rule => rule(version))) {
    setAutocutStore('ui.openedModalName', ModalType.UnsupportedVersion);
  }

  const userData: any = {
    pproVersion: version,
  };

  try {
    const isBeta = await evalTS('isPremiereBeta');
    setAutocutStore('ppro.isBeta', isBeta);
    userData.pproIsBeta = isBeta ? 1 : 0;
  } catch {}

  try {
    const renderingEngine = (await evalTS('getRenderingEngine')) as string;
    setAutocutStore('ppro.renderingEngine', renderingEngine);
    userData.renderingEngine = renderingEngine;
    if (!renderingEngine.includes('GPU')) {
      setAutocutStore(
        'ui.openedModalName',
        ModalType.UnsupportedRenderingEngine
      );
    }
  } catch {}

  await createOrUpdateUser(userData);
};

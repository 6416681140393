import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import css from './licenceInfos.module.scss';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';

const LicenceInfos = () => {
  const [isLogged, license] = useAutoCutStore(state => [
    state.user.isLogged,
    state.user.license,
  ]);
  const { logMessage } = useLogger('LicenceInfos');

  if (!isLogged || !license) return null;

  const isTrial = license?.type === 'trial';
  const licenceText = isTrial ? (
    <TranslatedMessage
      id="licence_time_left"
      defaultMessage="Unkown timeleft"
      values={{ daysLeft: license.left }}
    />
  ) : (
    <TranslatedMessage
      id="licence_info"
      defaultMessage="Unknown licence"
      values={{ licenceType: license.key_type }}
    />
  );

  return (
    <FlexContainer
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      gap={12}
      className={css.container}
    >
      <p className={css.text}>{licenceText}</p>
      <button
        className={css.buyButton}
        onClick={() => {
          if (isTrial) {
            setAutocutStore('ui.openedModalName', ModalType.GamingTrial);
            return;
          } else {
            logMessage(logLevel.info, 'User went to AutoCut website.');
            openLinkInBrowser(AUTOCUT_CONSTANTS[CURRENT_ENV].WEBAPP_ADDRESS);
          }
        }}
      >
        <p className={css.text}>
          {isTrial && (
            <TranslatedMessage
              id="header_trial_button"
              defaultMessage="Get more"
            />
          )}
          {!isTrial && (
            <TranslatedMessage
              id="header_paid_button"
              defaultMessage="Manage"
            />
          )}
        </p>
        <div className={css.border} />
        <div className={css.blob} />
      </button>
    </FlexContainer>
  );
};

export { LicenceInfos };

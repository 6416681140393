import React, { useEffect, useMemo, useRef, useState } from 'react';

import WaveForm from '@autocut/components/atoms/WaveForm/WaveForm';
import { WaveFormSkeleton } from '@autocut/components/atoms/WaveFormSkeleton';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import {
  getRMSArrayInterval,
  getSilencesArrayInterval,
} from '@autocut/utils/preview/audioCurve.utils';
import { openWaveFile } from '@autocut/utils/waveForm.utils';
import {
  RMS_INTERVAL_DURATION,
  computeRMS,
} from '@autocut/utils/zoom/computeRMS';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ExportedAudioClip } from '@autocut/types/ExportedAudio';

export type AudioCurveProps = {
  track: Track;
  previewStart: number;
  previewEnd: number;
  minClipsStart: number;
  maxClipsEnd: number;
  silencesInterval: number[][];
  clips: ExportedAudioClip[];
  isLoading?: boolean;
  processSilences: () => void;
};

export const AudioCurve = ({
  track,
  previewEnd,
  previewStart,
  minClipsStart,
  maxClipsEnd,
  silencesInterval,
  clips,
  isLoading: isLoadingSilences,
  processSilences,
}: AudioCurveProps) => {
  const [isLoadingCurve, setIsLoading] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);
  const [fullRmsArray, setFullFullRmsArray] = useState<number[]>([]);
  const isProcessing = useAutoCutStore(state => state.ui.process.isProcessing);

  const computeFullRMSArray = async () => {
    const fillRmsArray = (gapDuration: number, rmsArray: number[]) => {
      const samplesToFill = Math.floor(gapDuration / RMS_INTERVAL_DURATION); // Not exact but good enough for an approximation
      const fillerArray = new Array(samplesToFill).fill(0);
      const newArray = [...rmsArray, ...fillerArray];

      return newArray;
    };

    if (!parentRef.current) return;

    let totalFullRmsArray: number[] = [];

    // Here we're computing the rms array of the whole track (bound by the start and end points of the selection)
    // We're filling the gap between clips, at the start or end if needed with 0s.
    if (clips[0].start > minClipsStart) {
      const gapDuration = clips[0].start - minClipsStart;
      totalFullRmsArray = fillRmsArray(gapDuration, totalFullRmsArray);
    }

    for (const [clipIndex, clip] of clips.entries()) {
      const [sampleRate, PCM] = openWaveFile(clip.path) as [number, number[]];
      const rmsValues = computeRMS(PCM, sampleRate);

      if (clipIndex > 0) {
        const previousClip = clips[clipIndex - 1];
        if (previousClip.end !== clip.start) {
          const gapDuration = clip.start - previousClip.end;
          totalFullRmsArray = fillRmsArray(gapDuration, totalFullRmsArray);
        }
      }

      totalFullRmsArray = [...totalFullRmsArray, ...rmsValues];
    }

    const lastClip = clips[clips.length - 1];
    if (lastClip.end < maxClipsEnd) {
      const gapDuration = maxClipsEnd - lastClip.end;
      totalFullRmsArray = fillRmsArray(gapDuration, totalFullRmsArray);
    }

    setFullFullRmsArray(totalFullRmsArray);
  };

  const currentIntervalRmsArray = useMemo(
    () =>
      getRMSArrayInterval(
        fullRmsArray,
        previewStart,
        previewEnd,
        minClipsStart
      ),
    [fullRmsArray, previewEnd, previewStart]
  );
  const currentIntervalSilencesArray = useMemo(
    () => getSilencesArrayInterval(silencesInterval, previewStart, previewEnd),
    [previewEnd, previewStart, silencesInterval]
  );

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      void computeFullRMSArray();
      setIsLoading(false);
    }, 50);
  }, []);

  const handleSelectedAudioChange = async (
    trackIndex: number,
    isUsedTrack: boolean
  ) => {
    if (isProcessing) return;

    setAutocutStore('sequence.infos', state => {
      return {
        ...(state.sequence.infos || {}),
        audioTracks: state.sequence.infos?.audioTracks.map(track =>
          track.index === trackIndex ? { ...track, isUsed: isUsedTrack } : track
        ),
      } as Sequence;
    });

    processSilences();
  };

  return (
    <div ref={parentRef}>
      {isLoadingSilences || isLoadingCurve || !parentRef.current ? (
        <WaveFormSkeleton />
      ) : (
        <WaveForm
          trackIndex={track.index}
          duration={previewEnd - previewStart}
          previewStart={previewStart}
          rmsArray={currentIntervalRmsArray}
          silencesInterval={currentIntervalSilencesArray}
          handleToggleIsUsedTrack={handleSelectedAudioChange}
        />
      )}
    </div>
  );
};

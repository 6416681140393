import { useEffect } from 'react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  AutocutModes,
  AutocutModesKeys,
  getModeById,
} from '@autocut/enums/modes.enum';
import { setAutocutStore } from '@autocut/utils/zustand';

export const ModeProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  useEffect(() => {
    const modeId = (location.pathname.split('/').pop() ||
      'legacy') as (typeof AutocutModes)[AutocutModesKeys]['id'];
    const mode = getModeById(modeId);
    if (mode) {
      setAutocutStore('ui.process.mode', mode);
    }
  }, [location]);

  return <>{children}</>;
};

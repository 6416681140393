import { z as zod } from 'zod';
import { rgbValidationSchema } from './rgb.validationSchema';
import { fontValidationSchema } from './font.validationSchema';
import { textBoxValidationSchema } from './textBox.validationSchema';
import { languageOfTranscriptionValidationSchema } from './languageOfTranscription.validationSchema';
import { CaptionsParameters } from '@autocut/types/CaptionsParameters';

export type CaptionsPreset = {
  id: string;
  title: string;
  customCSS?: string;
  params: CaptionsParameters;
  usageCount: number;
  thumbnailUrl?: string;
  videoUrl?: string;
  isPublic?: boolean;
};

export const captionValidationSchema = zod.object({
  userPresets: zod.array(
    zod.object({
      id: zod.string(),
      title: zod.string(),
      customCSS: zod.string().optional(),
      params: zod.any(),
      usageCount: zod.number().int().min(0).optional(),
      thumbnailUrl: zod.string().optional(),
      videoUrl: zod.string().optional(),
      isPublic: zod.boolean().optional(),
    })
  ),
  enableSoundPlay: zod.boolean().optional(),
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  utils: zod.object({
    hasClosedInfoText: zod.boolean().optional(),
  }),
  position: zod.object({
    xPercentage: zod
      .number()
      .min(0, 'validation_error_caption_position')
      .max(1, 'validation_error_caption_position'),
    yPercentage: zod
      .number()
      .min(0, 'validation_error_caption_position')
      .max(1, 'validation_error_caption_position'),
    emojiXPercentage: zod
      .number()
      .min(0, 'validation_error_caption_emoji_position')
      .max(1, 'validation_error_caption_emoji_position'),
    emojiYPercentage: zod
      .number()
      .min(0, 'validation_error_caption_emoji_position')
      .max(1, 'validation_error_caption_emoji_position'),
    rotation: zod.number().int().min(0).max(360),
    offset: zod.array(zod.number().int().min(0)).length(2),
    scale: zod.array(zod.number().min(0)).length(2),
  }),
  text: zod.object({
    color: rgbValidationSchema,
    fontSize: zod
      .number()
      .int()
      .min(0, 'validation_error_caption_text_fontSizea'),
    font: fontValidationSchema,
  }),
  animations: zod.object({
    enabled: zod.boolean(),
    highlight: zod.object({
      enabled: zod.boolean(),
      color: rgbValidationSchema,
      revealText: zod.object({
        enabled: zod.boolean(),
        color: rgbValidationSchema,
      }),
    }),
    wordBox: textBoxValidationSchema,
    boldWithText: zod.boolean(),
    floatingText: zod.boolean(),
  }),
  outline: zod.object({
    enabled: zod.boolean(),
    color: rgbValidationSchema,
    width: zod.number().int().min(0, 'validation_error_caption_outline_width'),
  }),
  box: textBoxValidationSchema,
  transition: zod.object({
    enabled: zod.boolean(),
    effects: zod.array(
      zod.enum(['zoomIn', 'blurIn'], {
        invalid_type_error: 'validation_error_caption_transition_effects',
      })
    ),
  }),
  emojis: zod.object({
    enabled: zod.boolean(),
    size: zod.number().int().min(0, 'validation_error_caption_emojis_size'),
  }),
  formating: zod.object({
    uppercase: zod.boolean(),
    italic: zod.boolean(),
    nbLines: zod
      .number()
      .int()
      .min(1, 'validation_error_caption_formating_nbLines'),
    maxWidth: zod.number().int().min(0),
    glow: zod.object({
      enabled: zod.boolean(),
      intensity: zod.number().min(0),
      color: rgbValidationSchema,
    }),
    removePunctuation: zod.boolean(),
  }),
  postProcessing: zod.object({
    formattedNumbers: zod.number().optional(),
  }),
});

export const captionPresetTitleSchema = zod
  .string()
  .min(5, 'validation_error_caption_title_too_short')
  .max(25, 'validation_error_caption_title_too_long')
  .refine(value => value.includes(' '), {
    message: 'validation_error_caption_title_no_space',
  });

import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useIntl } from 'react-intl';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Button } from '../Buttons/Button';
import css from './silencesFooterButton.module.css';

export type FooterButtonProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  modeId?: 'silence' | 'ai' | 'repeat';
  onClickFunction: (...args: any) => void;
};

export const SilencesFooterButton = ({
  isLoading = false,
  isDisabled = false,
  modeId = 'silence',
  onClickFunction: handleCutEvent,
}: FooterButtonProps) => {
  const intl = useIntl();

  const { silences, transition } = useAutoCutStore(state => ({
    silences: state.ui.parameters[modeId].silences,
    transition: state.ui.parameters[modeId].transistions,
  }));

  const buttonTextId = `silence_button_${
    silences ? silences : 'delete'
  }_${transition}`;

  return (
    <div id="legacy-step8" className={css.container}>
      <Button
        isLoading={isLoading}
        disabled={isDisabled}
        onClickFunction={async () => await handleCutEvent(intl)}
      >
        <TranslatedMessage
          id={buttonTextId}
          defaultMessage="Cut and delete silences"
          values={{
            mode: intl.formatMessage({
              id: `text_${modeId === 'repeat' ? 'repetitions' : 'silences'}`,
              defaultMessage: 'silences',
            }),
          }}
        />
      </Button>
    </div>
  );
};

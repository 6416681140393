import { quantile, range } from '../math.utils';
import { AudioWavData } from './getAudioClipsWavData';
import { ZoomInterval, getZoomIntervals } from './getZoomIntervals';
import { getZoomCoverage } from './zoomIntervals.utils';

type DelimitZoomIntervalsArgs = {
  clipsAudioData: AudioWavData[];
  totalRMSValue: number[];
  startingAggressivityFactor: number;
  minZoomTime: number;
  maxZoomTime: number;
  goalCoverage: number;
  isSmooth: boolean;
};

export const delimitZoomIntervals = async ({
  clipsAudioData,
  totalRMSValue,
  startingAggressivityFactor,
  minZoomTime,
  maxZoomTime,
  goalCoverage,
  isSmooth,
}: DelimitZoomIntervalsArgs) => {
  let zoomIntervals: ZoomInterval[] = [];
  let bestZoomIntervals: ZoomInterval[] = [];
  let bestDelta = Infinity;
  let aggressivity = startingAggressivityFactor;
  for (let iterationCycle = 9999; iterationCycle > 0; iterationCycle--) {
    zoomIntervals = await getZoomIntervals(
      clipsAudioData,
      aggressivity,
      isSmooth,
      maxZoomTime,
      minZoomTime
    );

    const zoomCoverage = await getZoomCoverage(zoomIntervals);
    const deltaTargetCurrent = Math.abs(goalCoverage - zoomCoverage);

    if (deltaTargetCurrent < 0.02) {
      bestZoomIntervals = zoomIntervals;
      break;
    } else {
      if (deltaTargetCurrent < bestDelta) {
        bestZoomIntervals = zoomIntervals;
        bestDelta = deltaTargetCurrent;
      }

      aggressivity = quantile(
        totalRMSValue,
        range(9999, 0, 1, 0, iterationCycle)
      );
    }
  }

  return bestZoomIntervals;
};

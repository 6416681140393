import * as React from 'react';
import {
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { range } from '@autocut/utils';
import {
  calculateRegions,
  drawDbLimit,
  drawLineSegment,
  drawNotUsed,
} from '@autocut/utils/waveForm.utils';
import { CheckPill } from '../CheckPill/CheckPill';
import VolumeCard from '../VolumeCard/VolumeCard';
import './WaveForm.css';
import { isExportedAudioTrackWithClips } from '../PreviewTimeline/PreviewTimeline';

export type PreviewClip = {
  id: string;
  track: Track;
  startPreview: number;
  endPreview: number;
  silencesInterval: number[][];
};

export type WaveFormProps = {
  trackIndex: number;
  duration: number;
  previewStart: number;
  rmsArray: number[];
  silencesInterval: number[][];
  handleToggleIsUsedTrack: (numTrack: number, isSelected: boolean) => void;
};

export type RegionsParam = {
  startX: number;
  width: number;
  color: string;
};

const WaveForm = ({
  trackIndex,
  previewStart,
  duration,
  rmsArray,
  silencesInterval,
  handleToggleIsUsedTrack,
}: WaveFormProps) => {
  const { isTrackUsed, showPills, noiseLevel, mode } = useAutoCutStore(
    state => ({
      isTrackUsed: state.sequence.infos?.audioTracks.find(
        t => t.index === trackIndex
      )?.isUsed,
      showPills: () => {
        const exportedAudioInfos = state.sequence.exportedAudioInfos;

        if (!isExportedAudioTrackWithClips(exportedAudioInfos))
          throw new Error(
            "Cannot display preview for a sequence that doesn't have audio tracks with clips"
          );

        return (
          exportedAudioInfos?.sequence.audioTracks.reduce(
            (acc, cur) => (cur.clips.length > 0 ? acc + 1 : acc),
            0
          ) > 1
        );
      },
      noiseLevel: state.ui.parameters.silence.noiseLevel,
      mode: state.ui.process.mode,
    })
  );

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
  const [canvasWidth, setCanvasWidth] = useState(0);

  const [cursorPosition, setCursorPosition] = useState(0);

  const hoveredRMS = (() => {
    if (!canvasRef.current) return 0;

    const index = Math.floor(
      range(
        0,
        canvasRef.current.getBoundingClientRect().width,
        0,
        rmsArray.length,
        cursorPosition
      )
    );
    return rmsArray[index];
  })();
  const [displayVolume, setDiplayVolume] = useState(false);

  //DRAWING FUNCTIONS
  const handleMouseMove = (
    event: SyntheticEvent<HTMLCanvasElement, MouseEvent>
  ) => {
    if (canvasRef.current === null || ctx === null) return;

    const canvas = canvasRef.current as HTMLCanvasElement;

    // Clearing current canvas
    ctx.clearRect(0, -canvas.height, canvas.width, canvas.height);

    // Redrawing it
    drawCanvas(ctx);

    // Drawing the vertical line
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'black';
    ctx.beginPath();
    ctx.moveTo(event.nativeEvent.offsetX, -canvas.height);
    ctx.lineTo(event.nativeEvent.offsetX, canvas.height);
    ctx.stroke();

    setCursorPosition(event.nativeEvent.offsetX);
    setDiplayVolume(true);
  };

  const handleResize = () => {
    if (canvasRef == null) {
      return;
    }

    const canvas = canvasRef.current as unknown as HTMLCanvasElement;
    if (canvas == null) {
      return;
    }

    // Creating canvas
    const dpr = 1; // window.devicePixelRatio || 1;
    const padding = 0;

    canvas.width = canvas.offsetWidth * dpr;
    setCanvasWidth(canvas.offsetWidth * dpr);
    canvas.height = (canvas.offsetHeight + padding * 2) * dpr;
    const currentCtx = canvas.getContext('2d');
    if (currentCtx == null) return;

    currentCtx.scale(dpr, dpr);
    currentCtx.translate(0, canvas.offsetHeight + padding);
    setCtx(currentCtx);

    drawCanvas(currentCtx);
  };

  useLayoutEffect(() => {
    handleResize();
  }, []);

  // Drawing silences and voice zones
  const drawZones = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    isUsed: boolean
  ) => {
    const regions = calculateRegions(
      canvas,
      silencesInterval,
      duration,
      previewStart
    );

    for (let index = 0; index < regions.length; index++) {
      ctx.beginPath();
      ctx.rect(
        regions[index].startX,
        -canvas.height,
        regions[index].width,
        canvas.height
      );
      ctx.fillStyle = regions[index].color;
      ctx.fill();
    }

    if (!isUsed) {
      drawNotUsed(ctx, canvas);
    }
  };

  const drawCanvas = (ctx: CanvasRenderingContext2D) => {
    if (canvasRef.current === null || ctx === null) return;

    const canvas = canvasRef.current as HTMLCanvasElement;
    const padding = 0;

    drawZones(ctx, canvas, isTrackUsed ?? true);

    // draw the line segments
    const width = canvas.offsetWidth / rmsArray.length;

    for (let i = 0; i < rmsArray.length; i++) {
      const x = width * i;
      let height = rmsArray[i] * canvas.offsetHeight - padding;
      if (height < 0) {
        height = 0;
      } else if (height > canvas.offsetHeight / 2) {
        height = height > canvas.offsetHeight / 2 ? 1 : 0;
      }
      drawLineSegment(ctx, x, height, width, (i + 1) % 2);
    }

    if (mode.id === 'silence') drawDbLimit(ctx, canvas, noiseLevel);
  };

  useEffect(() => {
    if (canvasRef.current === null || ctx === null) return;

    const canvas = canvasRef.current as HTMLCanvasElement;
    ctx.clearRect(0, -canvas.height, canvas.width, canvas.height);
    drawCanvas(ctx);
  });

  return (
    <div className="waveform-container" style={{ width: '100%' }}>
      {displayVolume && (
        <VolumeCard
          cursorPosition={cursorPosition}
          maxWidth={canvasWidth}
          value={hoveredRMS}
        />
      )}
      <div className={'num-track'}>A{trackIndex + 1}</div>
      <div
        id="waveform-timeline"
        className="timeline"
        style={{ width: '100%' }}
      >
        <canvas
          onMouseMove={handleMouseMove}
          onMouseOut={() => {
            if (canvasRef.current === null || ctx === null) return;

            const canvas = canvasRef.current as HTMLCanvasElement;
            ctx.clearRect(0, -canvas.height, canvas.width, canvas.height);
            drawCanvas(ctx);
            setCursorPosition(0);
            setDiplayVolume(false);
          }}
          style={{ width: '100%', height: '128px' }}
          ref={canvasRef}
        ></canvas>
      </div>
      <div className="use-audio-pill">
        {showPills() && (
          <CheckPill
            id={`waveform-pill-${trackIndex}`}
            isSelected={isTrackUsed !== undefined ? isTrackUsed : true}
            setIsSelected={(checked: boolean) => {
              handleToggleIsUsedTrack(trackIndex, checked);
            }}
            hideLabel
            style={{ marginBottom: 36 }}
          />
        )}
      </div>
      <div id={'waveform-container-' + trackIndex}></div>
    </div>
  );
};

export default WaveForm;

import { AxiosResponse } from 'axios';
import { createIntl, createIntlCache } from 'react-intl';

import { GET_MAC_ID_COMMAND, OS_MAC } from '@autocut/constants/constants';
import { crypto } from '@autocut/lib/cep/node';
import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import IsKeyValidResponse from '@autocut/types/isKeyValidResponse';
import logLevel from '@autocut/types/logLevel.enum';
import { getHostName } from '@autocut/utils/system/hostname.system.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { getUUID } from '@autocut/utils/system/uuid.system.utils';
import { AutoCutApiError } from './errors/AutoCutApiError';
import { IncrementalError } from './errors/IncrementalError';
import { exec } from './exec.utils';
import { getPproVersion } from './general.utils';
import { autocutApi } from './http.utils';
import {
  getKey,
  getLanguage,
  isMacUserUsingSerialNumber,
  setMacUserUsingSerialNumber,
} from './localStorage.utils';
import { logger } from './logger';
import md5 from './md5.utils';
import { logSentryUser } from './sentry.utils';

const cache = createIntlCache();

const messages = {
  EN: {
    "can't_read_uuid_error": "Can't read the computer's UUID.",
    "can't_read_host_error": "Can't read the computer's name.",
    base_error_message: 'An error has occurred ',
    invalid_response_error: "This Key doesn't exist.",
  },
  FR: {
    "can't_read_uuid_error": "Impossible de lire l'UUID de l'ordinateur",
    "can't_read_host_error": "Impossible de lire le nom de l'ordinateur",
    base_error_message: "Une erreur s'est produite",
    invalid_response_error: "Cette clé n'existe pas.",
  },
};

const verifySignature = (
  data: { body: string; timeStamp: string; requestId: string },
  publicKey: string,
  signature: string
): boolean => {
  const bodyHash: string = md5(data.body);
  const stringToSign: string =
    bodyHash + '|' + data.timeStamp + '|' + data.requestId;

  const bufferedString: Buffer = Buffer.from(stringToSign);
  const verifier = crypto.createVerify('SHA256');
  verifier.update(bufferedString);

  if (verifier.verify(publicKey, signature, 'base64')) {
    return true;
  }

  return false;
};

const retreivePublicKey = async (publicKeyId: string): Promise<string> => {
  const { data } = await autocutApi.get(`/keys/pubkey/${publicKeyId}`);
  return data;
};

const isResponseValid = async (
  response: AxiosResponse<any, any>
): Promise<boolean> => {
  const responseId: string = response.headers['autocut-response-id'];
  const timeStamp: string = response.headers['autocut-response-timestamp'];
  const publicKeyId: string = response.headers['autocut-response-pubkey'];
  const responseSign: string = response.headers['autocut-response-sign'];

  if (
    !responseId ||
    !timeStamp ||
    !publicKeyId ||
    !responseSign ||
    new Date(timeStamp).getTime() + 24 * 60 * 60 * 1000 < new Date().getTime()
  ) {
    return false;
  }

  const publicKey = await retreivePublicKey(publicKeyId);

  if (!publicKey) {
    return false;
  }

  const isValid = verifySignature(
    {
      body: JSON.stringify(response.data),
      timeStamp: timeStamp,
      requestId: responseId,
    },
    publicKey,
    responseSign
  );

  return isValid;
};

const isKeyFromLocalStorageValid = () => {
  const keyStorage = getKey(false);
  logger('authUtil', logLevel.info, 'Verifying if local storage key is valid', {
    keyStorage,
  });
  return isKeyValid(keyStorage as string);
};

//TODO : Split
const isKeyValid = async (
  key: string,
  isManual = false
): Promise<IsKeyValidResponse> => {
  const trimmedKey = key?.trim();

  if (trimmedKey === 'none' || trimmedKey === undefined || trimmedKey === '') {
    logger('authUtil', logLevel.notice, 'Key empty or undefined');
    return { result: false, message: 'No key provided' };
  }

  const locale = getLanguage();
  const intl = createIntl(
    {
      locale: locale,
      messages: messages[locale],
    },
    cache
  );
  logger('authUtil', logLevel.notice, 'Checking if key is valid...');

  const version = getPproVersion() || 'PProVersion';

  const uuid = await getUUID(); //TODO : Move error in getUUID function
  if (uuid === undefined) {
    logger('authUtil', logLevel.error, "Can't read the computer's UUID");
    throw new Error(
      intl.formatMessage({
        id: "can't_read_uuid_error",
        defaultMessage: "Can't read the computer's UUID",
      })
    );
  }

  let pcName = await getHostName();

  const platform = getOS();
  if (platform === OS_MAC) {
    const { stdout } = await exec({
      command: GET_MAC_ID_COMMAND,
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.GET_MAC_ID,
        context: { shellCommand: GET_MAC_ID_COMMAND },
      },
    });
    const serialNumber = stdout.trim();

    pcName = serialNumber;
  }

  if (pcName === undefined) {
    logger('authUtil', logLevel.error, "Can't read the computer's name");
    throw new Error(
      intl.formatMessage({
        id: "can't_read_host_error",
        defaultMessage: "Can't read the computer's name",
      })
    );
  }

  const postData = {
    pc_uuid: uuid,
    pc_name: pcName,
    key: trimmedKey,
    ppro_version: version,
    os: platform,
    isManual,
  };

  const response = await autocutApi
    .post(`/keys/is-key-valid`, postData)
    .catch((error: AutoCutApiError | IncrementalError) => {
      logger('authUtil', logLevel.error, `An error has occured.`, {
        error,
        postData,
      });

      throw error;
    });

  let email = 'Unable to get email';
  if (
    response &&
    response.data &&
    response.data.licence &&
    response.data.licence.associatedEmail
  ) {
    email = response.data.licence.associatedEmail;
  }

  logSentryUser({
    id: trimmedKey,
    pcName: pcName,
    uuid: uuid,
    email,
  });

  if (!(await isResponseValid(response))) {
    logger('authUtil', logLevel.crit, "Response doesn't come from server");
    throw new Error(
      intl.formatMessage({
        id: 'invalid_response',
        defaultMessage: "This Key doesn't exist.",
      })
    );
  }

  logger(
    'authUtil',
    logLevel.notice,
    `Key is${response.data.result ? '' : ' not'} valid`,
    { response: response.data }
  );

  return response.data;
};

export { isKeyFromLocalStorageValid, isKeyValid };

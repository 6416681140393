import React from 'react';
import css from './Carousel.module.scss';

type CarouselProps = {
  items: React.ReactNode[];
};

export const Carousel = ({ items }: CarouselProps) => {
  const [currentItem, setCurrentItem] = React.useState(0);

  const isFirstPage = currentItem === 0;
  const isLastPage = currentItem === items.length - 1;

  return (
    <div className={css.carousel}>
      <div
        className={css.container}
        style={{
          transform: `translateX(-${currentItem * 100}%)`,
          transition: 'transform 0.5s ease-out',
        }}
      >
        {items.map((item, index) => (
          <div key={index} className={css.item}>
            {item}
          </div>
        ))}
      </div>

      <div
        className={[css.button, css.left].join(' ')}
        onClick={() => {
          if (isFirstPage) return;

          setCurrentItem(currentItem - 1);
        }}
        style={{
          left: 0,
          opacity: isFirstPage ? 0 : 1,
          cursor: isFirstPage ? 'default' : 'pointer',
        }}
      >
        {'<'}
      </div>
      <div
        className={[css.button, css.right].join(' ')}
        onClick={() => {
          if (isLastPage) return;

          setCurrentItem(currentItem + 1);
        }}
        style={{
          right: 0,
          opacity: isLastPage ? 0 : 1,
          cursor: isLastPage ? 'default' : 'pointer',
        }}
      >
        {'>'}
      </div>
    </div>
  );
};

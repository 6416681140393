import { z as zod } from 'zod';

export const fontValidationSchema = zod.object({
  fontFamily: zod
    .string({
      required_error: 'validation_error_font_fontFamily',
    })
    .min(1, 'validation_error_font_fontFamily'),
  postscriptName: zod.string(),
  lineGap: zod.number().int(),
  ascent: zod.number().int(),
  descent: zod.number().int(),
  unitsPerEm: zod.number().int(),
  bbox: zod.object({
    minX: zod.number().int(),
    minY: zod.number().int(),
    maxX: zod.number().int(),
    maxY: zod.number().int(),
  }),
  label: zod
    .string({ required_error: 'validation_error_font_label' })
    .min(1, 'validation_error_font_label'),
  supportsArabic: zod.boolean().optional(),
  supportsHebrew: zod.boolean().optional(),
});

import { evalTS } from '@autocut/lib/utils/bolt';
import { IntlShape } from 'react-intl';
import { changeMessage } from '../cutButton/changeMessage';
import { IncrementalError } from '../errors/IncrementalError';
import { handleProcessBase } from '../process/handleProcessBase';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import { downloadPexelVideos } from './downloadPexelVideos';
import { getVideosInTimelineFromUtterances } from './getVideosInTimeline';

const handleBRoll = async (intl: IntlShape) => {
  const {
    onGoingProcess: { bRolls },
  } = autocutStoreVanilla();

  if (!bRolls) {
    throw new IncrementalError('Missing parameters', 'handleBRoll');
  }

  try {
    const videosToImport = getVideosInTimelineFromUtterances(bRolls);

    const videosWithPath = await downloadPexelVideos(videosToImport);

    changeMessage(intl, 'broll_adding_broll', 'Adding B-Rolls...');

    setAutocutStore('onGoingProcess.nbStepTotal', videosWithPath.length);
    await evalTS('importBrolls', videosWithPath);

    const userToCredit = videosWithPath
      .map(video => video.user)
      .filter(
        (value, index, self) => self.findIndex(v => v.id === value.id) === index
      );

    setAutocutStore('ui.broll.usersToCredit', userToCredit);
  } catch (e: any) {
    throw new IncrementalError(e, 'handleBRoll');
  } finally {
    setAutocutStore('onGoingProcess.bRolls', undefined);
  }
};

export const handleBRollProcess = handleProcessBase({
  executeProcess: handleBRoll,
});

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { getCaptionsPresetsFromKey } from '@autocut/utils/captionsPresets/getCaptionsPresetsFromKey';
import { CaptionsPresetCard } from './components/CaptionsPresetCard/CaptionsPresetCard';

import css from './PresetCustomization.module.scss';
import { removeUnwantedKeysFromCaptionsParameters } from '@autocut/utils/captions/utils';
import {
  CaptionsParameters,
  CaptionsPreset,
} from '@autocut/types/CaptionsParameters';
import { PresetButtons } from './components/PresetButtons/PresetButtons';
import {
  ExampleCaptionText,
  TEXT_PREVIEW_HEIGHT,
  TEXT_PREVIEW_WIDTH,
} from '../ExampleCaption/ExampleCaptionText';
import { AnimatedCanvasType } from '@autocut/utils/captions/canvas/classes/animatedCanvas.class.utils';
import { DraggableCanvas } from '@autocut/utils/captions/canvas/classes/draggableCanvas.class.utils';
import { completePresetPreviews } from '@autocut/utils/captionsPresets/completePresetPreviews';
import { ModalType } from '@autocut/enums/modals.enum';

const handleApplyPreset = (preset: CaptionsPreset) => {
  //Deep clone with readonly modifier removed
  const userParameters = JSON.parse(
    JSON.stringify(autocutStoreVanilla().ui.parameters.caption)
  );
  const defaultParameters = JSON.parse(
    JSON.stringify(AutocutModes.Captions.defaultParameters)
  );

  const formattedDefaultParams =
    removeUnwantedKeysFromCaptionsParameters(defaultParameters);
  const formattedPresetParams = removeUnwantedKeysFromCaptionsParameters(
    preset.params as CaptionsParameters
  );

  const finalParameters = merge(
    userParameters,
    formattedDefaultParams,
    formattedPresetParams
  );

  setAutocutStore('ui.parameters.caption', finalParameters);
};

export const PRESETS_PER_LINE = 3;
export const PRESETS_ID = 'presets';

const PresetCustomization = () => {
  const [captionsPresets, setCaptionsPresets] = useState<
    CaptionsPreset[] | undefined
  >(undefined);
  const [error, setError] = useState<React.ReactNode | undefined>(undefined);
  const [canvas, setCanvas] = useState<AnimatedCanvasType<DraggableCanvas>>();
  const [preset, setPreset] = useState<CaptionsPreset | undefined>(undefined);

  useEffect(() => {
    void getCaptionsPresetsFromKey().then(value => {
      if (value === null) {
        setError(
          <TranslatedMessage
            id="captions_customization_get_presets_error"
            defaultMessage="Error while getting the presets, please contact us on discord or at contac@autocut.fr"
          />
        );
        return;
      }

      setCaptionsPresets(value);
    });
  }, []);

  useEffect(() => {
    if (preset?.id && !preset?.thumbnailUrl && !preset?.videoUrl && canvas) {
      setAutocutStore('ui.cutButtonMessage', 'completing_preset_preview');
      setAutocutStore('ui.openedModalName', ModalType.Processing);
      void completePresetPreviews({
        id: preset.id,
        title: preset.title,
        canvas,
      }).then(value => {
        setPreset(undefined);
        setAutocutStore('ui.openedModalName', '');
        setAutocutStore('ui.cutButtonMessage', '');
        if (value === null) return;

        setCaptionsPresets(prevState => {
          if (!prevState) return prevState;

          return prevState.map(p => {
            if (p.id === value.id) {
              return value;
            }

            return p;
          });
        });
      });
    }
  }, [canvas, preset]);

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      gap={12}
      id={PRESETS_ID}
    >
      {!!preset?.id && (
        <div style={{ display: 'none' }}>
          <ExampleCaptionText
            key={`captions-text-preview`}
            boundingBox={{
              height: TEXT_PREVIEW_HEIGHT,
              width: TEXT_PREVIEW_WIDTH,
            }}
            height={TEXT_PREVIEW_HEIGHT}
            setCanvas={setCanvas}
            displayedText={preset?.title}
          />
        </div>
      )}

      <TranslatedMessage
        id="captions_customization_presets_title"
        defaultMessage="Presets"
      />

      <PresetButtons
        onCreatePreset={newCaptionsPresets =>
          setCaptionsPresets(newCaptionsPresets)
        }
        onChoosePreset={(chosenPreset, newPresets) => {
          handleApplyPreset(chosenPreset);
          setCaptionsPresets(newPresets);
        }}
      />

      {error && (
        <p
          style={{
            color: 'red',
          }}
        >
          {error}
        </p>
      )}

      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 'fit-content',
        }}
      >
        <div
          className={css.presetsListContainer}
          style={{
            padding: `12px 16px`,

            display: 'grid',
            gridTemplateColumns: `repeat(${PRESETS_PER_LINE}, 1fr)`,
            gap: 8,
          }}
        >
          {captionsPresets?.map((preset, index) => (
            <CaptionsPresetCard
              key={index}
              preset={preset}
              onClick={() => {
                handleApplyPreset(preset);
                setPreset(preset);
              }}
              onRemoveError={setError}
            />
          ))}
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            transform: 'translateY(60%)',
            backgroundColor: 'var(--extension-bg-color)',
            filter: 'blur(2px)',
          }}
        />
      </div>
    </FlexContainer>
  );
};

export default PresetCustomization;

import { getModeStatTrait } from '@autocut/enums/modes.enum';
import { evalTS } from '@autocut/lib/utils/bolt';
import { StatType } from '@autocut/types/StatType.enum';
import logLevel from '@autocut/types/logLevel.enum';
import { logger } from '../logger';
import { sendStats } from '../stats.util';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleRestore', level, message, objects);
};

export const handleRestore = async (showError = true) => {
  try {
    const autocutStates = autocutStoreVanilla();
    const lastBackupId = autocutStates.backup.lastId;
    const isRestorable = autocutStates.backup.isRestorable;

    if (lastBackupId !== '' && isRestorable) {
      const res: boolean = await evalTS('loadBackup', lastBackupId);

      logMessage(logLevel.info, 'Timeline restored.', { res });

      setAutocutStore('backup.isRestorable', false);
    }
  } catch (e: any) {
    console.log('showError', showError);
    manageError({
      error: new IncrementalError(e, 'handleRestore'),
      disableModal: !showError,
    });
  }
};

import { XMLDocument } from '../xml/XMLDocument';
import {
  CAPTIONS_XML_PROJECT_LOCALE,
  CaptionsXmlProjectTrackIndex,
  secondToTickV2,
} from './utils';
import {
  localMotionEffectName,
  localPositionEffectName,
  localScaleEffectName,
} from '../../../jsx/ppro/enums';
import { CaptionChunk } from '@autocut/types/Captions';
import { getResourcePath, getResourcesFolder } from '../resourceManager.utils';
import { downloadFile } from '../files.utils';
import path from 'path';
import { XMLTrackItem } from '../xml/XMLTrackItem';
import { logger } from '../logger';
import logLevel from '@autocut/types/logLevel.enum';

export const BASE_EMOJI_SIZE = 64;
export const EMOJI_SIZE_FACTOR = 1.5;

export const addEmojis = async (
  {
    xmlDocument,
  }: {
    xmlDocument: XMLDocument;
  },
  newName: string
) => {
  const emojiTrack = xmlDocument.getSequences()[0].getVideoTracks()[
    CaptionsXmlProjectTrackIndex.EMOJI
  ];
  const emojiClip = emojiTrack.getClips()[0];

  let index = 0;

  const emojisBinProjectItem = xmlDocument
    .getBinProjectItems()
    .find(binProjectItem => binProjectItem.name === 'emojis');
  const emojiMediaName = 'emoji.png';
  const emojiMediaSource = xmlDocument.medias.find(
    media => media.title === emojiMediaName
  );
  const emojiMediaRelativePath =
    emojiMediaSource?.relativePath.textContent ?? './';

  const addEmojiChunk = async (
    chunk: CaptionChunk,
    {
      emojiProperties,
    }: {
      emojiProperties: EmojiParameters;
    }
  ) => {
    if (!chunk || !chunk.emojiUrl || !chunk.emoji) return;

    try {
      const newEmojiMediaName = chunk.emoji;
      const newEmojiMediaRelativePath = (
        emojiMediaRelativePath?.replace(emojiMediaName, newEmojiMediaName) ??
        `./emojis/` + newEmojiMediaName
      ).replace('/emojis', `/${newName}/emojis`);
      const filePath = getResourcePath({
        fileName: newName + '/emojis/' + newEmojiMediaName,
      });

      await downloadFile(chunk.emojiUrl, filePath, true);

      const newEmojiMediaSource = emojiMediaSource?.clone({
        newName: newEmojiMediaName,
        newPath: path.join(getResourcesFolder(), newEmojiMediaRelativePath),
        newRelativePath: newEmojiMediaRelativePath,
      });
      const newEmojiItem = emojisBinProjectItem?.cloneItem({
        newName: newEmojiMediaName,
        newSource: newEmojiMediaSource,
      });

      const newEmojiClip = emojiClip.clone({
        startTick: secondToTickV2(chunk.start),
        endTick: secondToTickV2(chunk.end),
        index,
        copiedVideoFilterComponents: [
          localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
        ],
      });
      newEmojiClip.changeSource(newEmojiItem);
      // Start of the webp clip must be 0 because the animation start at 0.
      // If not set to 0, the webp emoji will not be displayed correctly.
      newEmojiClip.updateVideoClipTimes({
        startTick: '0',
      });
      await updateEmojiScaleAndPosition(newEmojiClip, emojiProperties);

      index++;
    } catch (e) {
      logger(
        'captions',
        logLevel.error,
        'ERROR WHILE ADDING PNG ' +
          chunk.emoji +
          ' : ' +
          chunk.emojiUrl +
          ' TO CHUNK',
        { chunk }
      );
    }
  };

  const removeOriginalClip = () => {
    emojiClip.remove();
  };

  return {
    addEmojiChunk,
    removeOriginalClip,
  };
};

export const updateEmojiScaleAndPosition = async (
  emojiClip: XMLTrackItem,
  emojiProperties: EmojiParameters
) => {
  if (emojiClip) {
    const emojiScale = getEmojiScale(
      emojiProperties.fontSize,
      emojiProperties.size
    );
    emojiClip.updateMotionParam(
      localScaleEffectName[CAPTIONS_XML_PROJECT_LOCALE],
      emojiScale
    );

    const emojiPosition = emojiProperties.position;
    emojiClip.updateMotionParam(
      localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
      [emojiPosition.x, emojiPosition.y]
    );
  }
};

type EmojiParameters = {
  position: { x: number; y: number };
  fontSize: number;
  size?: number;
};

export const getEmojiScale = (fontSize = 104, emojiSize = BASE_EMOJI_SIZE) => {
  return fontSize * EMOJI_SIZE_FACTOR * (BASE_EMOJI_SIZE / emojiSize);
};

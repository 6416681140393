import { decodeCaptionParams } from '@autocut/modes/captions/utils/presets.captions.utils';
import { autocutApi } from '../http.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import { AutoCutApiError } from '../errors/AutoCutApiError';

export const getCaptionsPresetsFromKey = async () => {
  const res = await autocutApi
    .get(`/captions-presets/from-key`)
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'getCaptionsPresetsFromKey'),
      });
      return null;
    });

  if (res?.status === 200) {
    const captionsPresets: CaptionsPreset[] = res.data.map(
      ({
        id,
        title,
        params,
        usageCount,
        videoUrl,
        thumbnailUrl,
        isPublic,
      }: Omit<CaptionsPreset, 'params'> & { params: string }) => {
        const decodedParams = decodeCaptionParams(params);
        return {
          id,
          title,
          params: decodedParams,
          usageCount,
          videoUrl,
          thumbnailUrl,
          isPublic,
        };
      }
    );

    return captionsPresets;
  }

  return null;
};

import React from 'react';
import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { InvalidSelectionScreen } from '@autocut/components/partials/InvalidSelectionScreen/InvalidSelectionScreen';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { TranscriptLanguageStep } from '@autocut/components/steps/TranscriptLanguageStep/TranscriptLanguageStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useHandleProcessTranscript } from '@autocut/hooks/useHandleProcessTranscript';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const BRollLanguage = () => {
  const { selectionInfos, isProviderAvailable } = useAutoCutStore(state => ({
    selectionInfos: state.sequence.infos,
    isProviderAvailable: state.ui.currentTranscription.isProviderAvailable,
  }));

  const { handleProcessTranscript, error, loading } =
    useHandleProcessTranscript('broll');

  if (!selectionInfos || selectionInfos.nbClipsAudio === 0) {
    return (
      <InvalidSelectionScreen
        titleId="broll_invalid_selection_screen_title"
        titleDefaultMessage="Select clips on your timeline on which you want base B-Roll"
        texts={[
          {
            id: 'broll_invalid_selection_screen_text1',
            defaultMessage:
              'To use Auto B-Roll, you need to select at least one clip with audio in your timeline.',
          },
        ]}
        selectionImage={silenceSelectionImage}
      />
    );
  }

  return (
    <ModeLayout
      footer={
        <ModeFooter
          errorId={error ?? isProviderAvailable.message}
          renderButton={(isLoading, isDisabled) => (
            <Button
              disabled={isDisabled || !isProviderAvailable.isAvailable}
              isLoading={loading || isLoading}
              onClickFunction={async () => {
                await handleProcessTranscript();
              }}
            >
              <TranslatedMessage
                id="broll_process_transcript_button"
                defaultMessage="Go to broll selection"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId="broll" />
      <SelectedClipsStep />
    </ModeLayout>
  );
};

import { ExportedAudioWithSilences } from '@autocut/types/ExportedAudioWithSilences';

/***
 * Pour tout une sélection de clips, ajoute du silences au début et à la fin des clips plus court que toute la longueur de la sélection.
 * @param {Object} clipInfoTotal - Un tableau de dictionnaire comportant "timelineBasedSilences", "start" et "end"
 */
export const fillWithSilences = (
  exportedAudioInfos: ExportedAudioWithSilences[],
  start_min: number,
  end_max: number
) => {
  // On ajoute les silences aux clips en ayant besoin
  return exportedAudioInfos.map(exportedAudio => {
    if (exportedAudio.start > start_min) {
      (exportedAudio.timelineBasedSilences as number[][]).unshift([
        start_min,
        exportedAudio.start,
      ]);
    }
    if (exportedAudio.end < end_max) {
      (exportedAudio.timelineBasedSilences as number[][]).push([
        exportedAudio.end,
        end_max,
      ]);
    }
    return exportedAudio; // Return the modified exportedAudio
  });
};

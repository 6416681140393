import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { debounce } from '@autocut/utils/debounce';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { NumberInput } from '../../atoms/NumberInput';
import css from './silenceSizeStep.module.css';

export type SilenceSizeStepProps = {
  modeId?: 'silence';
};

export const SilenceSizeStep = ({
  modeId = 'silence',
}: SilenceSizeStepProps) => {
  const intl = useIntl();
  const {
    minTimeOfSilence: minTimeOfSilenceParam,
    minTimeOfTalk: minTimeOfTalkParam,
  } = useAutoCutStore(state => state.ui.parameters[modeId]);
  const [minTimeOfSilence, setMinTimeOfSilence] = useState(minTimeOfTalkParam);
  const [minTimeOfTalk, setMinTimeOfTalk] = useState(minTimeOfSilenceParam);

  useEffect(() => {
    setMinTimeOfSilence(minTimeOfSilenceParam);
  }, [minTimeOfSilenceParam]);
  useEffect(() => {
    setMinTimeOfTalk(minTimeOfTalkParam);
  }, [minTimeOfTalkParam]);

  const debouncedSetMinTimeOfSilence = useCallback(
    debounce((value: number) => {
      if (
        value !== autocutStoreVanilla().ui.parameters[modeId].minTimeOfSilence
      )
        setAutocutStore(`ui.parameters.${modeId}.minTimeOfSilence`, value);
    }, 1000),
    []
  );

  const debouncedSetMinTimeOfTalk = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].minTimeOfTalk)
        setAutocutStore(`ui.parameters.${modeId}.minTimeOfTalk`, value);
    }, 1000),
    []
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_Step1_Title',
        defaultMessage: 'Silence durations',
      })}
      arrowOnboardingId="legacy-step3"
    >
      <div className={css.container}>
        <NumberInput
          titleId="text_Step1_Silences"
          unitId="millisecondUnit"
          value={`${minTimeOfSilence * 1000}`}
          targetedParameter={'minTimeOfSilence'}
          onChange={async (value: string) => {
            setMinTimeOfSilence(Number(value));
            await debouncedSetMinTimeOfSilence(Number(value));
          }}
          helperDefault="Minimum duration (in milliseconds) a silence must have to be removed."
          helperId="helper_Step1_1"
        />
        <NumberInput
          titleId="text_Step1_Talks"
          unitId="millisecondUnit"
          value={`${minTimeOfTalk * 1000}`}
          targetedParameter={'minTimeOfTalk'}
          onChange={async (value: string) => {
            setMinTimeOfTalk(Number(value));
            await debouncedSetMinTimeOfTalk(Number(value));
          }}
          helperDefault="Minimum duration (in milliseconds) a talk segment must have to be kept."
          helperId="helper_Step1_2"
          left={true}
        />
      </div>
    </StepLayout>
  );
};

import { ExportedAudioElementWithTranscript } from '@autocut/types/Captions';
import { Utterance } from '@autocut/types/Deepgram';
import { IncrementalError } from '../errors/IncrementalError';
import { getParametersForMode } from '../parameters.utils';
import { launchTranscript } from './launchTranscript';
import { awaitTranscript } from './awaitTranscript';
import { NeedingTranscriptionModes } from '@autocut/types/transcription';
import { LANGUAGE_TO_MODEL } from './languageToModel';
import { setAutocutStore } from '../zustand';

export const fetchTranscriptFromExportedAudio = async <
  T extends 'utterances' | 'sentences'
>(
  exportedAudioInfosWithUrl: ExportedAudioElementWithTranscript<T>[],
  url: 'utterances' | 'delimited-repetitions' | 'sentences',
  usedModel = 0,
  skipEmbedding = true,
  progressCallback?: (p: number) => void
) => {
  const parameters = getParametersForMode<NeedingTranscriptionModes>();
  const languageOfTranscription = parameters.languageOfTranscription;

  setAutocutStore('ui.currentTranscription.usedModel', usedModel);

  const correspondingLanguage =
    LANGUAGE_TO_MODEL[languageOfTranscription.value];
  const userOptions = correspondingLanguage[usedModel];

  if (!userOptions) {
    throw new IncrementalError(
      'LANGUAGE_NOT_SUPPORTED',
      'fetchTranscriptFromExportedAudio'
    );
  }

  let progress = 0;

  const filteredExportedAudioInfos = [];
  const exportedAudioInfosWithTranscript = [...exportedAudioInfosWithUrl];
  for (const exportedAudioInfo of exportedAudioInfosWithTranscript) {
    if (!exportedAudioInfo.signedUrl) continue;

    const launchedTranscript = await launchTranscript<T>({
      userOptions: { value: languageOfTranscription.value, ...userOptions },
      exportedAudioInfo,
    });

    const transcript = await awaitTranscript<T>({
      resultType: url,
      launchedTranscript,
      skipEmbedding,
    });

    if (!transcript.length) continue;

    if (url !== 'sentences') {
      const utteranceTranscript = transcript as Utterance[];
      for (const utterance of utteranceTranscript) {
        utterance.start += exportedAudioInfo.start;
        utterance.end += exportedAudioInfo.start;
        for (const word of utterance.words) {
          word.start += exportedAudioInfo.start;
          word.end += exportedAudioInfo.start;
        }
      }
    }

    exportedAudioInfo.transcript =
      transcript as ExportedAudioElementWithTranscript<T>['transcript'];
    filteredExportedAudioInfos.push(exportedAudioInfo);

    progressCallback?.(++progress / exportedAudioInfosWithTranscript.length);
  }

  if (filteredExportedAudioInfos.length === 0) {
    throw new IncrementalError(
      'NO_SOUND_DETECTED',
      'fetchTranscriptFromExportedAudio'
    );
  }

  return filteredExportedAudioInfos;
};

import config from '../../../cep.config';
const id = config.id;

import {
  MAC_LOG_FOLDER,
  OS_MAC,
  OS_WINDOWS,
} from '@autocut/constants/constants';
import { SystemPath } from '@autocut/lib/cep/csinterface';
import { fs, os, path } from '@autocut/lib/cep/node';
import { csi, inCEPEnvironment } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { logger } from './logger';

const MAX_TEMP_FOLDER_SIZE = 1000 * 1e6; // 1 Go
const MAX_TEMP_FILE_AGE = 1000 * 60 * 60 * 2; // 2 hours

const timeout = (ms: number): Promise<any> => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

let FFMPEG_PATH = '';
const getExtensionFolder = (): string => {
  if (FFMPEG_PATH) {
    return FFMPEG_PATH;
  }

  logger('generalUtils', logLevel.info, 'Getting extension folder path...');
  let extensionFolderPath = csi.getSystemPath(SystemPath.EXTENSION);
  const result = extensionFolderPath;
  if (!extensionFolderPath || extensionFolderPath === 'invalidParam') {
    logger(
      'generalUtils',
      logLevel.warn,
      'Failed to get extension folder path, retry...',
      { extensionFolderPath }
    );
    extensionFolderPath = csi.getSystemPath('extension');
  }

  if (
    (!extensionFolderPath || extensionFolderPath === 'invalidParam') &&
    !FFMPEG_PATH
  ) {
    throw new IncrementalError(
      'Unable to get extension folder path',
      'getExtensionFolder'
    );
  }

  logger(
    'generalUtils',
    logLevel.info,
    'Extension folder path get successfully.',
    { extensionFolderPath }
  );
  FFMPEG_PATH = extensionFolderPath;

  return extensionFolderPath;
};

export const getPproVersion = (): string | undefined => {
  if (!inCEPEnvironment || !csi) return undefined;

  let host: any = 'default value';

  try {
    host = csi.hostEnvironment;
    return host.appVersion;
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'getPproVersion'),
      additionalData: { id, host },
    });
  }

  return undefined;
};

export const comparePproVersions = (version1?: string, version2?: string) => {
  if (!version1 || !version2) return 0;

  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = i < v1.length ? v1[i] : 0;
    const num2 = i < v2.length ? v2[i] : 0;

    if (num1 < num2) {
      return -1;
    } else if (num1 > num2) {
      return 1;
    }
  }

  return 0; // versions are equal
};

const getLogFolder = (): string => {
  const rootFolder = getExtensionFolder();
  const os = getOS();

  switch (os) {
    case OS_MAC:
      return MAC_LOG_FOLDER;
    case OS_WINDOWS:
      return rootFolder + '\\..\\';
    default:
      return rootFolder + '\\..\\';
  }
};

const removeOldFileFromTempFolder = async () => {
  const tempFolderpath = os.tmpdir();
  const outFolderpath = path.join(tempFolderpath, 'AutoCut');

  if (!fs.existsSync(outFolderpath)) {
    fs.mkdirSync(outFolderpath);
    return;
  }

  const tempFolderSize = getDirSize(outFolderpath);
  logger('generalUtils', logLevel.info, `Checking temp folder size...`, {
    size: tempFolderSize + ' bytes',
  });
  const files = fs.readdirSync(outFolderpath);

  for (const file of files) {
    const tempFilePath = path.join(outFolderpath, file);
    const isTempFolderSizeOverOneGiga = tempFolderSize > MAX_TEMP_FOLDER_SIZE;
    const wasntReadedWithinLast2Hours =
      fs.statSync(tempFilePath).atimeMs < Date.now() - MAX_TEMP_FILE_AGE;

    const needToBeRemoved =
      isTempFolderSizeOverOneGiga || wasntReadedWithinLast2Hours;
    if (needToBeRemoved) {
      fs.unlinkSync(tempFilePath);
      logger(
        'generalUtils',
        logLevel.info,
        `File ${tempFilePath} removed from temp folder`
      );
    }
  }
};

export const getDirSize = (dirPath: string): number => {
  if (!fs.existsSync(dirPath)) return 0;

  const files = fs.readdirSync(dirPath);
  const stats = files.map((file: string) => {
    const stat = fs.statSync(path.join(dirPath, file));
    if (stat.isDirectory()) {
      return { size: getDirSize(path.join(dirPath, file)) };
    }
    return stat;
  });

  return stats.reduce(
    (accumulator: number, stat: any) => accumulator + stat.size ?? 0,
    0
  );
};

export const retryTimedOutFunction = async <T>(
  functionToRetry: () => T,
  maxNumberOfRetry = 10,
  delayToWait = 0,
  currentNumberOfRetry = 0
): Promise<T> => {
  if (currentNumberOfRetry >= maxNumberOfRetry) {
    throw new IncrementalError(
      'Could not execute function',
      'retryTimedOutFunction'
    );
  }

  try {
    const res = functionToRetry();
    return res;
  } catch (error) {
    await new Promise(res => setTimeout(res, delayToWait + 50));
    return retryTimedOutFunction(
      functionToRetry,
      maxNumberOfRetry,
      (delayToWait + 100) * 2,
      currentNumberOfRetry + 1
    );
  }
};

export {
  getExtensionFolder,
  getLogFolder,
  removeOldFileFromTempFolder,
  timeout,
};

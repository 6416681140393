import * as React from 'react';

import { WordBase } from '@autocut/types/Deepgram';
import sentenceCss from '../TranscriptSentence/TranscriptSentence.module.css';
import css from './TranscriptWord.module.css';
import { getValue } from '@autocut/utils/captions/utils';

type TranscriptWordProps = {
  word: WordBase;
  groupIndex: number;
  utteranceIndex: number;
  wordIndex: number;
};

export const TranscriptWord = ({
  word,
  groupIndex,
  utteranceIndex,
  wordIndex,
}: TranscriptWordProps) => {
  return (
    <span
      className={`${css.word} ${word.isCut ? sentenceCss.marked : ''} ${
        sentenceCss.overrideSelectionStyle
      }`}
      data-group-index={groupIndex}
      data-utterance-index={utteranceIndex}
      data-word-index={wordIndex}
    >
      {getValue(word)}
    </span>
  );
};

import { OS_MAC } from '@autocut/constants/constants';
import { exec } from '../exec.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';

export const xmlToPrProjFile = async (
  initialFilePath: string,
  newFilePath: string
) => {
  const tempGzipFile = `${initialFilePath}.gz`;

  const os = getOS();

  const command =
    os === OS_MAC
      ? `gzip -c "${initialFilePath}" > "${tempGzipFile}" && mv "${tempGzipFile}" "${newFilePath}"`
      : `tar -czf "${newFilePath}" "${initialFilePath}"`;
  try {
    await exec({
      command,
    });
  } catch (e) {
    return false;
  }

  return true;
};

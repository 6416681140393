import { IncrementalError } from '../errors/IncrementalError';
import { getParametersForMode } from '../parameters.utils';

export const getTalkingTimelineFromSilences = (
  clips: Clip[],
  timelineBasedSilences: number[][]
): number[] => {
  try {
    const startTimeOfTrack = clips.reduce((acc, clip) => {
      return Math.min(acc, clip.start);
    }, Infinity);
    const endTimeOfTrack = clips.reduce((acc, clip) => {
      return Math.max(acc, clip.end);
    }, 0);

    // All timelines starts at 0 seconds

    const { precision: precisionParameter } = getParametersForMode('podcast');

    const timeline: number[] = [
      ...Array(Math.floor(startTimeOfTrack / precisionParameter)).fill(0),
      ...Array(
        Math.ceil((endTimeOfTrack - startTimeOfTrack) / precisionParameter)
      ).fill(1),
    ];

    for (const silence of timelineBasedSilences) {
      const start = Math.floor(silence[0] / precisionParameter);
      const end = Math.ceil(silence[1] / precisionParameter);

      for (let i = start; i < end; i++) {
        timeline[i] = 0;
      }
    }

    return timeline;
  } catch (err: any) {
    throw new IncrementalError(err, 'getTalkingTimelineFromSilences');
  }
};

import { CAPTIONS_XML_PROJECT_LOCALE } from '@autocut/utils/captions/utils';
import { XMLTrack } from '@autocut/utils/xml/XMLTrack';
import { localScaleEffectName } from '../../../../jsx/ppro/enums';

export const resizeTemplateClip = (
  track: XMLTrack,
  sequenceWidth: number,
  sequenceHeight: number,
  params?: { clipIndex?: number; withMasterClip?: boolean }
) => {
  const clip = track.getClips()[params?.clipIndex || 0];
  clip.resize([sequenceWidth, sequenceHeight]);
  if (params?.withMasterClip) {
    clip.masterClip?.resize([sequenceWidth, sequenceHeight]);
  }

  return clip;
};

export const resizeTemplates = (
  tracks: [XMLTrack, { withMasterClip: boolean; withLocalScale: boolean }][],
  sequenceWidth: number,
  sequenceHeight: number,
  optimalScale: number
) => {
  return tracks.map(([track, params]) => {
    const clip = resizeTemplateClip(track, sequenceWidth, sequenceHeight, {
      withMasterClip: params.withMasterClip,
    });

    if (params.withLocalScale) {
      clip.updateMotionParam(
        localScaleEffectName[CAPTIONS_XML_PROJECT_LOCALE],
        optimalScale
      );
    }

    return clip;
  });
};

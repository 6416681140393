import * as React from 'react';
import { RadioButton } from '../RadioButton/RadioButton';
import { RadioButtonWithInput } from '../RadioButtonWithInput/RadioButtonWithInput';
import css from './RadioButtonsForm.module.css';

export type RadioButtonsFormProps = {
  radioButtonDescriptions: {
    label: string;
    value: number | string | boolean;
    default?: boolean;
    withInput?: boolean;
  }[];
  onChange: (value: number | string | boolean) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
};

export const RadioButtonsForm = ({
  radioButtonDescriptions,
  onChange,
  disabled = false,
  style,
}: RadioButtonsFormProps) => {
  const [indexFocus, setIndexFocus] = React.useState(0);
  const formName = React.useMemo(() => `form${Math.random()}`, []);

  return (
    <div className={css.formContainer} style={style}>
      {radioButtonDescriptions.map((description, index) => {
        if (description.withInput) {
          return (
            <RadioButtonWithInput
              key={`radioWithInput${index}`}
              name={formName}
              disabled={disabled || indexFocus !== index}
              setIndexFocus={() => setIndexFocus(index)}
              onChange={onChange}
            />
          );
        } else {
          return (
            <RadioButton
              key={`radio${index}`}
              name={`${formName}-${index}-${description.default}`}
              label={description.label}
              value={description.value}
              setIndexFocus={() => setIndexFocus(index)}
              onClick={onChange}
              default={description.default}
              disabled={disabled}
            />
          );
        }
      })}
    </div>
  );
};

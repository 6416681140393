import { AutoCutApiError } from '../errors/AutoCutApiError';
import { IncrementalError } from '../errors/IncrementalError';
import { autocutApi } from '../http.utils';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';

export const defaultTrialTasks = [
  {
    id: 'onboarding',
    amount: 1,
    textId: 'trial_task_onboarding',
    completed: false,
  },
  {
    id: 'captions',
    amount: 1,
    textId: 'trial_task_captions',
    completed: false,
    link: 'https://www.autocut.fr/autocaptions/',
  },
  {
    id: 'podcast',
    amount: 1,
    textId: 'trial_task_podcast',
    completed: false,
    link: 'https://www.autocut.fr/autocutpodcast/',
  },
  {
    id: 'zoom',
    amount: 1,
    textId: 'trial_task_zoom',
    completed: false,
    link: 'https://www.autocut.fr/autozoom/',
  },
  {
    id: 'repeat',
    amount: 1,
    textId: 'trial_task_repeat',
    completed: false,
    link: 'https://www.autocut.fr/autocutrepeat/',
  },
  {
    id: 'broll',
    amount: 1,
    textId: 'trial_task_broll',
    completed: false,
    link: 'https://www.autocut.fr/autobroll/',
  },
  {
    id: 'swear_word',
    amount: 1,
    textId: 'trial_task_swear_word',
    completed: false,
    link: 'https://www.autocut.fr/autoprofanityfilter/',
  },
  {
    id: 'preset',
    amount: 1,
    textId: 'trial_task_preset',
    completed: false,
  },
  {
    id: 'discord',
    amount: 1,
    textId: 'trial_task_discord',
    completed: false,
    link: 'https://discord.com/invite/autocut',
  },
  {
    id: 'testimonial',
    amount: 1,
    textId: 'trial_task_testimonial',
    completed: false,
    link: 'https://senja.io/p/autocut/r/ikmgDE',
  },
] as const;

export type TrialTaskId = (typeof defaultTrialTasks)[number]['id'];

export const handleTrialTask = async (
  taskId: TrialTaskId,
  sendToApi = true
) => {
  const license = autocutStoreVanilla().user.license;
  if (!license || license.type !== 'trial') return;

  const currentTasks = autocutStoreVanilla().game.trial.tasks;
  const task =
    currentTasks.find(task => task.id === taskId) ||
    defaultTrialTasks.find(task => task.id === taskId);

  if (!task || task.completed) return;

  const newTasks = currentTasks
    .map(task => {
      if (task.id === taskId) {
        return { ...task, completed: true, isAnimated: true };
      }

      return task;
    })
    .sort((a, b) => Number(b.completed) - Number(a.completed));

  if (sendToApi) {
    await autocutApi
      .post('/keys/tasks/complete')
      .catch((error: AutoCutApiError | IncrementalError) => {
        console.error('Error while completing trial task', error);
      });

    setAutocutStore('user.license', {
      ...license,
      left: Number(license?.left || 0) + 1,
    });
  }

  setAutocutStore('game.trial.tasks', newTasks);
  setAutocutStore('game.trial.displayModal', true);

  return true;
};

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { VscDebugBreakpointUnsupported } from 'react-icons/vsc';

import { Button } from '@autocut/components/atoms';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { useIntl } from 'react-intl';

export const UnsupportedRenderingEngineModal = () => {
  const intl = useIntl();

  return (
    <Modal
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      title={intl.formatMessage({
        id: `unsupported_rendering_engine_title`,
        defaultMessage: 'Unsupported rendering engine',
      })}
      icon={<VscDebugBreakpointUnsupported size={36} />}
      footer={
        <Button
          onClickFunction={() => setAutocutStore('ui.openedModalName', '')}
        >
          <TranslatedMessage
            id={`unsupported_version_modal_cta`}
            defaultMessage="I understand"
          />
        </Button>
      }
    >
      <p>
        <TranslatedMessage
          id={`unsupported_rendering_engine_modal_text`}
          defaultMessage="You are currently using an unsupported rendereing engine. You <b>will</b> not be able to use Auto Captions nor AutoZoom.{br}{br}Please select a GPU accelerated rendering engine in your project settings."
        />
      </p>
    </Modal>
  );
};

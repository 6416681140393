import {
  MAC_HOSTNAME_COMMAND,
  OS_MAC,
  WINDOWS_HOSTNAME_COMMAND,
} from '@autocut/constants/constants';
import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import { exec } from '@autocut/utils/exec.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';

export const fetchHostName = async (): Promise<any> => {
  // const debug = new Debuger("Auth", "GetHostname");
  const os = getOS();
  const cmdHostname =
    os === OS_MAC ? MAC_HOSTNAME_COMMAND : WINDOWS_HOSTNAME_COMMAND;

  const { stdout } = await exec({
    command: cmdHostname,
    sentryData: {
      fingerPrint: SentryFingerPrintEnum.EXEC.GET_UUID,
      context: { shellCommand: cmdHostname },
    },
  });
  // debug.Log(`Fin ${stdout}`);
  return stdout;
};

export const refreshHostName = async (): Promise<any> => {
  const hostname = await fetchHostName();
  setAutocutStore('user.clientInfos.hostname', hostname);
  return hostname;
};

/*
    @return the name of the computer
*/
export const getHostName = async () => {
  //As it's called sooner than the store init we need to catch the error
  try {
    const res = autocutStoreVanilla().user.clientInfos.hostname;
    if (res) {
      return res;
    }
  } catch {
    return await fetchHostName();
  }

  //If do not exist we refresh it
  return await refreshHostName();
};

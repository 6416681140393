import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { handleCaptionsProcess } from '@autocut/utils/captions/handleCaptionProcess.utils';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Navigate, useNavigate } from 'react-router-dom';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { CaptionsTranscriptStepContainer } from './CaptionsTranscriptStepContainer';
import { CaptionsStyleStep } from './CaptionsStyleStep/CaptionsStyleStep';
import { CaptionsTranscriptEditor } from './CaptionsTranscriptEditorStep/CaptionsTranscriptEditorStep';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { ModalType } from '@autocut/enums/modals.enum';
import { CheckTranscriptModal } from './CheckTranscriptModal/CheckTranscriptModal';
import { getLocalStorage } from '@autocut/utils/localStorage.utils';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { Divider } from '@autocut/designSystem/components/atoms/Divider/Divider';

const CaptionsTranscriptStep = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { captionsChunks } = useAutoCutStore(state => ({
    captionsChunks: state.onGoingProcess.captionChunks,
  }));
  const [isCheckTranscriptModalOpen, setIsCheckTranscriptModalOpen] =
    useState(false);
  const [hasCheckedTranscript, setHasCheckedTranscript] = useState(
    getLocalStorage('dontShowCheckTranscriptModal')
  );

  if (!captionsChunks) {
    return null;
  }

  const hasChunks = (captionsChunks ?? []).length > 0;

  if (!hasChunks) {
    navigate('../script');
    throw new IncrementalError('SEQUENCE_MISMATCH', 'Captions transcript step');
  }

  const openTranscriptEditorModal = () => {
    addModalToQueue(ModalType.EditTranscript);
    setHasCheckedTranscript(true);
  };

  const processCaptions = async () => {
    await handleCaptionsProcess(intl);
    await handleTrialTask('captions');
  };

  return captionsChunks.length > 0 ? (
    <>
      <CheckTranscriptModal
        isOpen={isCheckTranscriptModalOpen}
        onClose={() => setIsCheckTranscriptModalOpen(false)}
        onValidateTranscript={async () => {
          await processCaptions();
        }}
        onOpenModal={() => {
          setHasCheckedTranscript(true);
        }}
      />
      <ModeLayout
        footer={
          <ModeFooter
            renderButton={(isLoading, isDisabled) => (
              <Button
                disabled={isDisabled || !hasChunks}
                onClick={async () => {
                  if (hasCheckedTranscript) {
                    await processCaptions();
                  } else {
                    setIsCheckTranscriptModalOpen(true);
                  }
                }}
                color={colors.primary600}
              >
                <TranslatedMessage
                  id="captions_transcript_action"
                  defaultMessage="Add captions"
                />
              </Button>
            )}
          />
        }
      >
        <FlexContainer flexDirection="column" gap={8}>
          <CaptionsTranscriptStepContainer
            title={
              <TranslatedMessage
                id="captions_customization_modal_title"
                defaultMessage="Captions style"
              />
            }
          >
            <CaptionsStyleStep />
          </CaptionsTranscriptStepContainer>

          <Divider />

          <CaptionsTranscriptStepContainer
            title={
              <TranslatedMessage
                id="captions_presetCustomization_editTranscriptionModal_title"
                defaultMessage="Transcription"
              />
            }
            onClick={openTranscriptEditorModal}
          >
            <CaptionsTranscriptEditor />
          </CaptionsTranscriptStepContainer>
        </FlexContainer>
      </ModeLayout>
    </>
  ) : (
    <Navigate to="../customization" replace={true} />
  );
};

export default CaptionsTranscriptStep;

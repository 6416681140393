import { OS_MAC, OS_WINDOWS } from '@autocut/constants/constants';
import { csi } from '@autocut/lib/utils/bolt';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';

export const fetchOS = (): typeof OS_MAC | typeof OS_WINDOWS | undefined => {
  let os = csi.getOSInformation();
  if (os) {
    os = os.indexOf('Mac') === 0 ? OS_MAC : OS_WINDOWS;
    return os;
  }
  return undefined;
};

export const refreshOS = () => {
  const os = fetchOS();
  if (os) {
    setAutocutStore('user.clientInfos.os', os);
  }
  return os;
};

export const getOS = () => {
  //As it's called sooner than the store init we need to catch the error
  try {
    const res = autocutStoreVanilla().user.clientInfos.os;
    if (res) {
      return res;
    }
  } catch (e) {
    return fetchOS();
  }

  //If do not exist we refresh it
  return refreshOS();
};

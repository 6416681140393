import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { Camera } from '@autocut/types/Camera';
import { Speaker } from '@autocut/types/Speaker';
import logLevel from '@autocut/types/logLevel.enum';
import { updateCameraReducer } from '@autocut/utils/cameras/updateCameraReducer';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useMemo } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsCameraVideoFill } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import css from './Camera.module.css';

import selectCustomStyle from '@autocut/components/atoms/CustomSelectText/SelectStyle';
import { removeCameraReducer } from '@autocut/utils/cameras/removeCameraReducer';
import { updateSpeakerReducer } from '@autocut/utils/speakers/updateSpeakerReducer';
import Select from 'react-select';

import './camera.css';

export type CameraProps = {
  camera: Camera;
};

export const CameraComponent = ({ camera }: CameraProps) => {
  const intl = useIntl();
  const { logMessage } = useLogger('CameraComponent');

  const { videoTracks, speakers, cameras } = useAutoCutStore(state => ({
    videoTracks: state.sequence.infos?.videoTracks,
    speakers: state.ui.parameters.podcast.speakers,
    cameras: state.ui.parameters.podcast.cameras,
  }));

  const availableVideoTracks = useMemo(() => {
    const availableTracks = videoTracks
      ?.filter(track => track.nbClips > 0)
      .map((videoTrack: any) => {
        const name = `${intl.formatMessage({
          id: 'text_podcast_track',
          defaultMessage: 'Track',
        })} V${videoTrack.index + 1}`;
        return {
          label: name,
          value: String(videoTrack.index),
        };
      });

    const usedTracks = cameras.map((camera: Camera) => ({
      value: camera.value,
      label: camera.label,
    }));

    const availableVideoTracks = availableTracks?.filter(
      (track: any) =>
        !usedTracks.find(
          (usedTrack: any) => String(usedTrack.value) === String(track.value)
        )
    );

    return availableVideoTracks;
  }, [cameras]);

  const availableSpeakers = useMemo(
    () =>
      speakers.map((speaker: Speaker, index: number) => {
        const name =
          speaker.name === ''
            ? `${intl.formatMessage({
                id: 'text_podcast_speaker',
                defaultMessage: 'Speaker',
              })} ${speaker.audio.label}`
            : speaker.name;

        return {
          label: name,
          value: speaker.id,
          index: index,
        };
      }),
    [speakers]
  );

  const allSpeakers = {
    label: intl.formatMessage({
      id: 'text_podcast_all_speakers',
      defaultMessage: 'All speakers',
    }),
    value: 'all',
    index: -1,
  };

  const speakerSelectValues = useMemo(() => {
    return speakers
      .map(speaker => {
        return speaker.cameras.map((currentCamera: Camera) => {
          if (currentCamera.id === camera.id) {
            const name =
              speaker.name === ''
                ? `${intl.formatMessage({
                    id: 'text_podcast_speaker',
                    defaultMessage: 'Speaker',
                  })} ${speaker.audio.label}`
                : speaker.name;

            return {
              label: name,
              value: speaker.id,
              index: availableSpeakers.findIndex(
                availableSpeaker => availableSpeaker.value === speaker.id
              ),
            };
          }
        });
      })
      .flat(1)
      .filter(Boolean);
  }, [speakers]);

  return (
    <div id="podcast-step3" className={css.container}>
      <div className={css.left}>
        <AiOutlineCloseCircle
          className={`${css.remove} ${css.icon}`}
          onClick={() => {
            logMessage(logLevel.notice, 'Removing camera', {
              [camera.id]: camera,
            });
            setAutocutStore(
              'ui.parameters.podcast.cameras',
              removeCameraReducer(camera.id)
            );
          }}
          size={16}
          color="white"
        />
        <BsCameraVideoFill className={css.icon} size={16} />
        <Select
          classNamePrefix="videoTrack"
          className={camera.label === '' ? 'default' : ''}
          styles={selectCustomStyle('trackSelection')}
          options={availableVideoTracks || []}
          defaultValue={
            camera.label === ''
              ? {
                  label: `${intl.formatMessage({
                    id: 'text_podcast_track',
                    defaultMessage: 'Track',
                  })} VX`,
                  value: '',
                }
              : camera
          }
          onChange={value => {
            logMessage(logLevel.notice, 'Setting camera video track.', {
              [camera.id]: value,
            });
            if (!value) return;

            setAutocutStore(
              'ui.parameters.podcast.cameras',
              updateCameraReducer(camera.id, {
                label: value.label,
                value: value.value,
              })
            );
          }}
        />
      </div>
      <div className={css.right}>
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={[allSpeakers, ...availableSpeakers]}
          value={speakerSelectValues}
          styles={selectCustomStyle('speakerSelection')}
          onChange={(value: any) => {
            logMessage(logLevel.notice, 'Setting camera speakers.', {
              [camera.id]: value,
            });

            const actualValue = value.find(
              (speaker: any) => speaker.value === 'all'
            )
              ? availableSpeakers
              : value;

            if (camera.speakerIds.length > actualValue.length) {
              const removedSpeakers = camera.speakerIds
                .map((speakerId: any) => {
                  if (
                    !actualValue.find(
                      (speaker: any) => speaker.value === speakerId
                    )
                  ) {
                    return speakers.find(
                      (speaker: Speaker) => speaker.id === speakerId
                    );
                  }
                })
                .filter(Boolean) as Speaker[];

              for (const speaker of removedSpeakers) {
                setAutocutStore(
                  'ui.parameters.podcast.speakers',
                  updateSpeakerReducer(speaker.id, {
                    cameras: [
                      ...speaker.cameras.filter(
                        (currentCamera: Camera) =>
                          currentCamera.id !== camera.id
                      ),
                    ],
                  })
                );
              }
            }

            setAutocutStore(
              'ui.parameters.podcast.cameras',
              updateCameraReducer(camera.id, {
                speakerIds: actualValue.map((speaker: any) => speaker.value),
              })
            );
          }}
        />
      </div>
    </div>
  );
};

import { getSubclipIndexes } from '@autocut/utils/cut/handleDelete/getSubclipIndexes.utils';

/**
 * Returns the indexes of the clips to delete after cut process.
 * /!\ The indexes are relative to the timeline.
 **/
export const getClipIndexesToDelete = (
  track: Track,
  silenceIntervals: number[][]
) => {
  const res = track.selectedClips
    .map(clip => {
      const { nbCutsOnClip, subclipIndexesToDelete } = getSubclipIndexes(
        clip,
        silenceIntervals
      );

      return {
        ...clip,
        nbCutsOnClip,
        subclipsIndexes: subclipIndexesToDelete,
      };
    })
    .sort((a, b) => a.start - b.start)
    .map((clip, index, array) => {
      const clipsBefore = array.slice(0, index);
      const newIndex = clipsBefore.reduce(
        (acc, c) => acc + c.nbCutsOnClip,
        clip.indexInTrack
      );

      return clip.subclipsIndexes.map(subclipIndex => newIndex + subclipIndex);
    });

  return res.flat();
};

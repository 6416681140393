import { ClipTypeEnum } from '@autocut/types/ClipType.enum';
import { CEPAction } from '@autocut/utils/cep/actions.cep.utils';
import { getClipIndexesToDelete } from '@autocut/utils/cut/handleDelete/getClipIndexesToDelete.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla, ProgressState } from '@autocut/utils/zustand';
import { IntlShape } from 'react-intl';
import { changeMessage } from '../cutButton/changeMessage';
import { getParametersForMode } from '../parameters.utils';
import { setAutocutStore } from '../zustand';

export const handleDelete = async (
  timelineBasedSilencesTotal: number[][],
  actions: CEPAction<any>[],
  intl: IntlShape
) => {
  try {
    changeMessage(
      intl,
      'button_PreDeletingStep',
      'Searching silences clip to delete…'
    );

    const autocutState = autocutStoreVanilla();
    if (
      !autocutState.sequence.infos ||
      (!autocutState.sequence.infos.audioTracks.length &&
        !autocutState.sequence.infos.videoTracks.length)
    ) {
      throw new Error('No clip selected');
    }

    const parameters = getParametersForMode();
    const silenceAction = (parameters as any).silences;

    const tracksWithClipsToDelete = [
      ...autocutState.sequence.infos.audioTracks,
      ...(silenceAction === 'delete'
        ? autocutState.sequence.infos.videoTracks
        : []),
    ]
      .map(track => ({
        index: track.index,
        type: track.type,
        clipIndexesToDelete: getClipIndexesToDelete(
          track,
          timelineBasedSilencesTotal
        ),
      }))
      .filter(track => track.clipIndexesToDelete.length);

    //We update the last action "then" callback to update the message template just before executing our first delete action
    actions[actions.length - 1]?.thenFunctions.push(() => {
      setAutocutStore(
        'onGoingProcess.CEPProgressCallback',
        () => () =>
          handleDeleteMessage(
            intl,
            tracksWithClipsToDelete[0].type === ClipTypeEnum.Audio
              ? 'Audio'
              : 'Video',
            tracksWithClipsToDelete[0],
            silenceAction as 'delete' | 'mute'
          )
      );
    });

    const deleteActions: CEPAction<'deleteClips'>[] = [];

    for (
      let trackIndex = 0;
      trackIndex < tracksWithClipsToDelete.length;
      trackIndex++
    ) {
      const track = tracksWithClipsToDelete[trackIndex];
      const nextTrack = tracksWithClipsToDelete[trackIndex + 1];

      if (!track.clipIndexesToDelete.length) continue;

      deleteActions.push({
        action: 'deleteClips',
        param: [
          track.clipIndexesToDelete.reverse(),
          track.index,
          track.type,
          silenceAction as 'delete' | 'mute' | 'delete_keep_spaces',
        ],
        thenFunctions: [
          () => {
            if (nextTrack) {
              setAutocutStore(
                'onGoingProcess.CEPProgressCallback',
                () => () =>
                  handleDeleteMessage(
                    intl,
                    nextTrack.type === ClipTypeEnum.Audio ? 'Audio' : 'Video',
                    nextTrack,
                    silenceAction as 'delete' | 'mute'
                  )
              );
            } else {
              changeMessage(
                intl,
                `button_PostDeletingStep`,
                `Silences removed...`
              );
            }
          },
        ],
      });
    }

    return deleteActions;
  } catch (err: any) {
    throw new IncrementalError(err, 'handleDelete');
  }
};

export const handleDeleteMessage =
  (
    intl: IntlShape,
    trackType: 'Audio' | 'Video',
    track: {
      index: number;
      type: 1 | 2;
      clipIndexesToDelete: number[];
    },
    type: 'delete' | 'mute'
  ) =>
  (progress: ProgressState) => {
    changeMessage(
      intl,
      `button_DeletingStep_${type}_${trackType}`,
      `Delete silence on ${trackType.toLowerCase()} track {numTrack} : {current} out of {max}`,
      {
        numTrack: track.index + 1,
        current: progress.current,
        max: progress.max,
      }
    );
  };

import * as React from 'react';

import css from './menuItem.module.css';

export type MenuItemProps = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

export const MenuItem = ({ icon, label, onClick }: MenuItemProps) => {
  return (
    <div className={css.container} onClick={onClick}>
      <div className={css.icon}>{icon}</div>
      <div>{label}</div>
    </div>
  );
};

import React from 'react';

import loaderUrl from '@autocut/assets/images/LoaderAutoCut.gif';
const loader = new URL(loaderUrl, import.meta.url).href;

interface LoaderProps {
  isLoading?: boolean;
  height: number;
}

export const LoaderAutocut = ({ isLoading = true, height }: LoaderProps) => {
  return (
    <div>
      {isLoading ? <img height={height} src={loader} alt="loader" /> : null}
    </div>
  );
};

import { AutoCutState } from '@autocut/utils/zustand';
import { Speaker } from '@autocut/types/Speaker';

export const updateSpeakerReducer =
  (speakerId: string, updatedFields: Partial<Speaker>) =>
  (state: AutoCutState) => {
    return state.ui.parameters.podcast.speakers.map((speaker: Speaker) => {
      if (speaker.id === speakerId) {
        return {
          ...speaker,
          ...updatedFields,
        };
      }
      return speaker;
    });
  };

import React from 'react';

import css from './PresetCard.module.css';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { CheckBox } from '@autocut/components/atoms/CheckBox/CheckBox';
import { ResizePreset } from '@autocut/types/Resize';

import { FaRegTrashAlt } from 'react-icons/fa';

export type PresetCardProps = {
  preset: ResizePreset;
  checked: boolean;
  onClick: () => void;
  onDelete?: () => void | undefined;
};

export const PresetCard = ({
  preset,
  checked,
  onClick,
  onDelete = undefined,
}: PresetCardProps) => {
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className={`${css.container} ${checked ? css.selected : ''}`}
    >
      <FlexContainer
        className={css.subContainer}
        flexDirection="column"
        justifyContent="space-between"
        gap={4}
      >
        <FlexContainer gap={4} flexDirection="column">
          <FlexContainer justifyContent="space-between" alignItems="center">
            <FlexContainer gap={2}>
              {preset.icons?.map(icon => icon)}
            </FlexContainer>

            <CheckBox
              size={16}
              checked={checked}
              onChange={() => {
                onClick();
              }}
            />
          </FlexContainer>
          <div>{preset.name}</div>
        </FlexContainer>
        <FlexContainer justifyContent="space-between" alignItems="center">
          <div>
            {preset.width}x{preset.height}
          </div>
          {onDelete && (
            <FaRegTrashAlt
              className={css.trashIcon}
              onClick={event => {
                event.stopPropagation();
                onDelete();
              }}
            />
          )}
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

import React, { CSSProperties } from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { FaRegTrashAlt } from 'react-icons/fa';

import css from './ChaptersTranscriptItem.module.scss';
import { TranscriptChunkContainer } from '@autocut/components/containers/TranscriptChunkContainer';
import { EditableTimeBadge } from '../TimeBadge/TimeBadge';
import { ImageContainer } from './ImageContainer/ImageContainer';
import { NOT_FOUND_CHAPTER_VALUE } from '@autocut/utils/chapters/processChapters';
import { EditableText } from '../EditableText/EditableText';

export type ChaptersTranscriptItemProps = {
  chapter: Chapter;
  onChapterUpdate: (chapter: Chapter) => void;
  onChapterDelete: () => void;
};

export const ChaptersTranscriptItem = ({
  chapter,
  onChapterUpdate,
  onChapterDelete,
}: ChaptersTranscriptItemProps) => {
  return (
    <TranscriptChunkContainer
      className={css.chaptersItemContainer}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <FlexContainer alignItems="center" gap={12}>
        <ImageContainer chapter={chapter} />
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          gap={8}
          style={{
            ...({
              '--time-badge-from-color':
                chapter.start === NOT_FOUND_CHAPTER_VALUE
                  ? 'rgba(191, 0, 0, 0.90)'
                  : 'white',
              '--time-badge-to-color':
                chapter.end === NOT_FOUND_CHAPTER_VALUE
                  ? 'rgba(191, 0, 0, 0.90)'
                  : 'white',
            } as CSSProperties),
          }}
        >
          <EditableTimeBadge
            onChange={(from, to) =>
              onChapterUpdate({ ...chapter, start: from, end: to })
            }
            from={chapter.start}
            to={chapter.end}
          />
          <EditableText
            className={css.titleInput}
            initialValue={chapter.title}
            onChange={title => onChapterUpdate({ ...chapter, title })}
          />
        </FlexContainer>
      </FlexContainer>

      <FaRegTrashAlt
        className={css.trashIcon}
        onClick={onChapterDelete}
        size={16}
        color="#98A2B3"
      />
    </TranscriptChunkContainer>
  );
};

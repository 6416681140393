import { setAutocutStore } from '@autocut/utils/zustand';
import { TourProvider } from '@reactour/tour';
import React, { ComponentProps } from 'react';
import {
  OnboardingModal,
  popoverStyles,
} from '../modals/OnboardingModals/OnboardingWelcomeModal';

export type TourContainerProps = { children: React.ReactNode } & Omit<
  ComponentProps<typeof TourProvider>,
  'children'
>;

export const TourContainer = ({
  children,
  beforeClose,
  afterOpen,
  ...props
}: TourContainerProps) => {
  return (
    <TourProvider
      ContentComponent={OnboardingModal}
      scrollSmooth
      disableInteraction
      disableDotsNavigation
      disableKeyboardNavigation
      styles={{
        popover: popoverStyles,
      }}
      beforeClose={e => {
        setAutocutStore('ui.isTourOpened', false);
        beforeClose?.(e);
      }}
      afterOpen={async e => {
        setAutocutStore('ui.isTourOpened', true);
        afterOpen?.(e);
      }}
      {...props}
    >
      {children}
    </TourProvider>
  );
};

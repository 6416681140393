import { Button } from '@autocut/components/atoms';
import { downloadYoutubeFile } from '@autocut/utils/chapters/downloadYoutubeTextFile';
import React, { useState } from 'react';
import { GoDownload } from 'react-icons/go';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoIosCheckmarkCircle } from 'react-icons/io';

type DownloadButtonProps = {
  chapters: Chapter[];
};

const OPTIONS = {
  download: {
    icon: GoDownload,
    label: (
      <TranslatedMessage
        id="chapters_editor_download_youtube_chapters_file_download"
        defaultMessage="Download YouTube chapters file"
      />
    ),
    color: 'white',
  },
  success: {
    icon: IoIosCheckmarkCircle,
    label: (
      <TranslatedMessage
        id="chapters_editor_download_youtube_chapters_file_success"
        defaultMessage="chapter.txt has been added to your PPRO project"
      />
    ),
    color: '#17B26A',
  },
};

export const DownloadButton = ({ chapters }: DownloadButtonProps) => {
  const [icon, setIcon] = useState<keyof typeof OPTIONS>('download');

  const option = OPTIONS[icon];
  return (
    <Button
      onClickFunction={async () => {
        await downloadYoutubeFile(chapters).then(() => {
          setIcon('success');

          setTimeout(() => {
            setIcon('download');
          }, 5000);
        });
      }}
      buttonType="secondary"
      color="white"
      compact
      style={{
        padding: '8px 12px',
      }}
    >
      <FlexContainer flexDirection="row" alignItems="center" gap={8}>
        {React.createElement(option.icon, {
          color: option.color,
          size: '1.5rem',
        })}
        {option.label}
      </FlexContainer>
    </Button>
  );
};

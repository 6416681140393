import React from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import { CheckBox } from '@autocut/components/atoms/CheckBox/CheckBox';
import { TranscriptChunkContainer } from '@autocut/components/containers/TranscriptChunkContainer';
import { TimeBadge } from '../../../../../../components/atoms/TimeBadge/TimeBadge';
import { ImageContainer } from '../ImageContainer/ImageContainer';
import { ViralityScore } from '../ViralityScore/ViralityScore';
import css from './ViralClipsTranscriptItem.module.scss';

type ViralClipsTranscriptItemProps = {
  viralClip: ViralClip;
  onViralClipSelect: () => void;
  onViralClipPlay: () => void;
};

export const ViralClipsTranscriptItem = ({
  viralClip,
  onViralClipSelect,
  onViralClipPlay,
}: ViralClipsTranscriptItemProps) => {
  return (
    <TranscriptChunkContainer
      className={`${css.chaptersItemContainer} ${
        viralClip.selected ? '' : css.unselected
      }`}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <FlexContainer alignItems="flex-start" gap={12}>
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          className={css.leftColumn}
          gap={8}
        >
          <ImageContainer viralClip={viralClip} onClick={onViralClipPlay} />
          <ViralityScore score={viralClip.score} reason={viralClip.reason} />
        </FlexContainer>
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          gap={8}
          className={css.rightColumn}
          onClick={onViralClipSelect}
        >
          <FlexContainer
            flexDirection="row"
            flexWrap="nowrap"
            justifyContent="space-between"
            alignItems="center"
            className={css.header}
            gap={8}
          >
            <TimeBadge from={viralClip.start} to={viralClip.end} />
            <div className={css.checkboxContainer}>
              <CheckBox
                size={20}
                checked={viralClip.selected ?? false}
                onChange={() => null} // Suppress warning
              />
            </div>
          </FlexContainer>
          <p className={`${css.viralClipTitle} ${css.unselectable}`}>
            {viralClip.title}
          </p>
          <p className={css.unselectable}>{viralClip.text}</p>
        </FlexContainer>
      </FlexContainer>
    </TranscriptChunkContainer>
  );
};

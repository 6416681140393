export const getPositionWithAnchors = (
  anchorPosition: [number, number],
  currentPosition: [number, number],
  thresholds: [number, number]
) => {
  const [currentX, currentY] = currentPosition;
  const [anchorX, anchorY] = anchorPosition;
  const [thresholdX, thresholdY] = thresholds;

  const xIsAdjusted = Math.abs(currentX - anchorX) < thresholdX;
  const yIsAdjusted = Math.abs(currentY - anchorY) < thresholdY;

  const newX = xIsAdjusted ? anchorX : currentX;
  const newY = yIsAdjusted ? anchorY : currentY;

  return {
    newPosition: [newX, newY],
    adjusted: [xIsAdjusted ? anchorX : null, yIsAdjusted ? anchorY : null],
  };
};

import { StepType } from '@reactour/tour';

export const modeSelectionSteps: StepType[] = [
  {
    selector: '#modeSelection-step1',
    content: {
      title: 'onboarding_modeSelection_step1_title',
      body: 'onboarding_modeSelection_step1_body',
    } as any,
  },
  {
    selector: '#modeSelection-step1',
    content: {
      title: 'onboarding_modeSelection_step2_title',
      body: 'onboarding_modeSelection_step2_body',
    } as any,
  },
];

import { CanvasObject } from '@autocut/utils/captions/canvas/classes/canvas.class.utils';

export type CanvasObjectModifier = (
  canvasOject: Omit<CanvasObject, 'id'>
) => Omit<CanvasObject, 'id'>;

export type CanvasObjectModifierProps = {
  enabled: boolean;
  modifier: CanvasObjectModifier;
};

export type CanvasObjectModifierOutput = {
  enabled: boolean;
  modifier: CanvasObjectModifier;
};

export type CanvasObjectModifierHook = (
  props: CanvasObjectModifierProps
) => CanvasObjectModifierOutput;

export const useCanvasObjectModifier: CanvasObjectModifierHook = props => {
  return {
    modifier: props.enabled ? props.modifier : canvasObject => canvasObject,
    enabled: props.enabled,
  };
};

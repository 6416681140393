import React from 'react';

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Button } from '@autocut/components/atoms';
import { FiAlertTriangle } from 'react-icons/fi';
import { setAutocutStore } from '@autocut/utils/zustand';

export const RELOAD_MODAL_TIMEOUT = 30 * 60 * 1000; // 30 minutes

const RestartAutoCutModal = () => {
  return (
    <Modal
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <TranslatedMessage
            id="reload_autocut_modal_title"
            defaultMessage="Reload AutoCut"
          />
        </p>
      }
      icon={<FiAlertTriangle size="1.5rem" />}
      footer={
        <Button
          onClickFunction={() =>
            (window.location.href = window.location.origin)
          }
          buttonType="primary"
        >
          <TranslatedMessage
            id="reload_autocut_modal_cta"
            defaultMessage="Reload AutoCut"
          />
        </Button>
      }
      closeModalFunction={() => {
        setAutocutStore('utils.openedTimestamp', Date.now());
        setAutocutStore('ui.openedModalName', '');
      }}
    >
      <p
        style={{
          color: 'white',
          padding: '16px',
          fontSize: '13px',
          lineHeight: '23px',
          fontWeight: 'bold',
        }}
      >
        <TranslatedMessage
          id="reload_autocut_modal_text"
          defaultMessage="It's been over 30 minutes since AutoCut was last opened. {br} For optimal performances, we recommend to reload AutoCut now.{br}Simply click the button bellow to reload AutoCut"
        />
      </p>
    </Modal>
  );
};

export default RestartAutoCutModal;

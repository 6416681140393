import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import css from './Select.module.scss';
import { IconChevronDown } from '../Icon/arrows/IconChevronDown';
import { Text } from '../Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { IconXClose } from '../Icon/general/IconXClose';
import FlexContainer from '../../molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { isArray } from 'lodash';
import { Item } from './Item/Item';
import { FormattedMessage } from 'react-intl';
import PopupPortal from '@autocut/components/atoms/PopupPortal/PopupPortal';

export type SelectOption = {
  value: string;
  valueInDropdown?: ReactNode;
};

type SelectSelected<T> = T extends true
  ? SelectOption[] | undefined
  : SelectOption | undefined;

type SelectOnChange<T> = T extends true
  ? (value: SelectOption[]) => void
  : (value: SelectOption) => void;

type SelectAllowSelectAll<T> = T extends true ? boolean : undefined;

export type SelectProps<T> = {
  placeholder?: ReactNode;
  allowMultiple?: T;
  fullWidth?: boolean;
  clearable?: boolean;
  options: SelectOption[];
  selected: SelectSelected<T>;
  onChange: SelectOnChange<T>;
  allowSelectAll?: SelectAllowSelectAll<T>;
  maxHeightDropdown?: number;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>;

export const Select = <T extends boolean | undefined = false>({
  allowSelectAll = false,
  allowMultiple = false,
  fullWidth = false,
  clearable = false,
  placeholder,
  onChange,
  selected,
  options,
  style,
  maxHeightDropdown = 180,
}: SelectProps<T>) => {
  const [id, _] = useState(Math.random().toString(36).substring(7));
  const [isOpen, setIsOpen] = useState(false);
  const [selectRef, setSelectRef] = useState<HTMLElement | null>(null);
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');

  const selectedArray = useMemo(() => {
    if (selected === undefined) return [];

    if (allowMultiple === true || isArray(selected)) {
      return selected as SelectOption[];
    }

    return [selected as SelectOption];
  }, [selected, allowMultiple]);

  const filteredOptions = useMemo(() => {
    if (!selectedArray || !allowMultiple) return options;

    return options.filter(option => !selectedArray.includes(option));
  }, [options, selectedArray, allowMultiple]);

  const handleSelect = (option: SelectOption) => {
    if (allowMultiple === true) {
      if (selected === undefined) {
        onChange([option] as SelectOption[] & SelectOption);
        return;
      }

      if (selectedArray.includes(option)) {
        onChange(
          selectedArray.filter(
            selectedOption => selectedOption !== option
          ) as SelectOption[] & SelectOption
        );
        return;
      }

      onChange([...selectedArray, option] as SelectOption[] & SelectOption);

      return;
    }

    onChange(option as SelectOption[] & SelectOption);
    setIsOpen(false);
  };

  const handleSelectAll = () => {
    onChange(options as SelectOption[] & SelectOption);
  };

  const innerContainerStyle = isOpen
    ? position === 'bottom'
      ? { borderRadius: `${Spacing.s1} ${Spacing.s1} 0 0` }
      : { borderRadius: `0 0 ${Spacing.s1} ${Spacing.s1}` }
    : { borderRadius: Spacing.s1 };

  const optionsStyle = {
    ...(position === 'bottom'
      ? {
          borderTop: 'none',
          borderRadius: `0 0 ${Spacing.s1} ${Spacing.s1}`,
        }
      : {
          borderBottom: 'none',
          borderRadius: `${Spacing.s1} ${Spacing.s1} 0 0`,
        }),
    maxHeight: maxHeightDropdown,
  };

  useEffect(() => {
    const getPosition = () => {
      const windowHeight = window.innerHeight;
      const selectRect = selectRef?.getBoundingClientRect();
      const selectBottom = selectRect ? selectRect.bottom : 0;

      if (maxHeightDropdown + selectBottom <= windowHeight) {
        setPosition('bottom');
      } else {
        setPosition('top');
      }
    };

    window.addEventListener('scroll', getPosition);

    return () => {
      window.removeEventListener('scroll', getPosition);
    };
  }, [maxHeightDropdown, selectRef]);

  return (
    <>
      <div
        id={`designSelect.${id}`}
        style={{
          width: fullWidth ? '100%' : 'fit-content',
          zIndex: isOpen ? 1 : 0,
          ...style,
        }}
        className={`${css.container} ${isOpen ? css.open : ''}`}
        ref={setSelectRef}
      >
        <FlexContainer
          gap={8}
          className={css.innerContainer}
          onClick={() => setIsOpen(prev => !prev)}
          alignItems="center"
          justifyContent="space-between"
          style={innerContainerStyle}
        >
          <FlexContainer gap={Spacing.s1} alignItems="center" flexWrap="wrap">
            {selectedArray.length > 0 ? (
              selectedArray.map((option, index) => (
                <Item
                  key={index}
                  isBadge={allowMultiple}
                  onDelete={
                    allowMultiple ? () => handleSelect(option) : undefined
                  }
                >
                  {option.valueInDropdown ?? option.value}
                </Item>
              ))
            ) : (
              <Text variant="textSm" color={colors.gray400}>
                <Item isPlaceholder isBadge={false}>
                  {placeholder || (
                    <FormattedMessage
                      id="designSystem_atoms_select_placeholder"
                      defaultMessage="Select an option"
                    />
                  )}
                </Item>
              </Text>
            )}
          </FlexContainer>

          <FlexContainer gap={Spacing.s1} flexShrink={false}>
            <IconChevronDown
              className={css.chevron}
              size={16}
              color={isOpen ? 'white' : colors.gray400}
            />
            {clearable && (
              <IconXClose
                className={css.close}
                size={16}
                color={colors.gray400}
                onClick={event => {
                  event.stopPropagation();
                  onChange(undefined as any);
                }}
              />
            )}
          </FlexContainer>
        </FlexContainer>
        {isOpen && selectRef && (
          <PopupPortal target={selectRef} position={position}>
            <div
              className={css.options}
              style={{
                ...optionsStyle,
                width: selectRef.getBoundingClientRect().width,
              }}
            >
              {filteredOptions.length === 0 ? (
                <div className={css.option}>
                  <Item isBadge={false} isPlaceholder>
                    <FormattedMessage
                      id="designSystem_atoms_select_no_more"
                      defaultMessage="No more options available"
                    />
                  </Item>
                </div>
              ) : (
                filteredOptions.map((option, index) => (
                  <div
                    className={css.option}
                    key={index}
                    onClick={() => handleSelect(option)}
                  >
                    <Item isBadge={false}>
                      {option.valueInDropdown ?? option.value}
                    </Item>
                  </div>
                ))
              )}
              {allowSelectAll &&
                allowMultiple &&
                filteredOptions.length !== 0 && (
                  <div className={css.option} onClick={handleSelectAll}>
                    <Item isBadge={false}>
                      <FormattedMessage
                        id="designSystem_atoms_select_select_all"
                        defaultMessage="Select all"
                      />
                    </Item>
                  </div>
                )}
            </div>
          </PopupPortal>
        )}
      </div>
      <div
        className={`${css.overlay} ${isOpen ? css.open : ''}`}
        onClick={() => setIsOpen(false)}
      />
    </>
  );
};

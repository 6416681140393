import React, { useEffect, useState } from 'react';

import css from './ImageContainer.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {
  NO_ACTIVE_SEQUENCE_ERROR_MESSAGE,
  exportCurrentFrameAsPNGSync,
} from '@autocut/utils/previewFrame';
import { LoadingRing } from '../../LoadingRing/LoadingRing';
import { IoWarningOutline } from 'react-icons/io5';
import PopupPortal from '../../PopupPortal/PopupPortal';
import { InfoText } from '../../InfoText';
import { TranslatedMessage } from '../../TranslatedMessage/TranslatedMessage';
import { NOT_FOUND_CHAPTER_VALUE } from '@autocut/utils/chapters/processChapters';

export type ImageContainerProps = {
  chapter: Chapter;
};

export const ImageContainer = ({ chapter }: ImageContainerProps) => {
  const [src, setSrc] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isMissingSequence, setIsMissingSequence] = useState(false);

  const errorIconRef = React.useRef<HTMLDivElement>(null);
  const [errorIsShown, setErrorIsShown] = useState(false);

  useEffect(() => {
    if (
      chapter.start === NOT_FOUND_CHAPTER_VALUE ||
      chapter.end === NOT_FOUND_CHAPTER_VALUE
    ) {
      setIsError(true);
      return;
    }
    setIsLoading(true);
    void exportCurrentFrameAsPNGSync(src, chapter.start, `${chapter.title}.png`)
      .then(blobUrl => {
        setSrc(blobUrl);
        setIsError(false);
        setIsMissingSequence(false);
      })
      .catch((e: Error) => {
        console.log('ERROR', e);
        if (e.message.includes(NO_ACTIVE_SEQUENCE_ERROR_MESSAGE)) {
          setIsError(false);
          setIsLoading(false);
          setIsMissingSequence(true);
        } else {
          setIsError(true);
          setIsLoading(false);
          setIsMissingSequence(false);
        }
      });
  }, [chapter]);

  if (isError || isMissingSequence) {
    return (
      <div className={css.container}>
        {errorIsShown && (
          <PopupPortal target={errorIconRef} position="top-right">
            <InfoText style="error">
              <div
                style={{
                  maxWidth: '200px',
                }}
              >
                <TranslatedMessage
                  id="chapters_preview_error"
                  defaultMessage="An error has occured generating the preview. Try to update the timestamps or to restart AutoCut if the problems persists."
                />
              </div>
            </InfoText>
          </PopupPortal>
        )}
        <FlexContainer
          ref={errorIconRef}
          className={css.errorContainer}
          alignItems="center"
          justifyContent="center"
          onMouseEnter={() => setErrorIsShown(true)}
          onMouseLeave={() => setErrorIsShown(false)}
        >
          <IoWarningOutline />
        </FlexContainer>
      </div>
    );
  }

  return (
    <div className={css.container}>
      <div key={chapter.title} className={css.imageContainer}>
        {isLoading && (
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <LoadingRing />
          </FlexContainer>
        )}
        <img
          src={src}
          alt={chapter.title}
          onLoad={() => {
            setIsLoading(false);
          }}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>
    </div>
  );
};

import * as React from 'react';

import PreviewTimeline from '@autocut/components/atoms/PreviewTimeline/PreviewTimeline';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { getAllClipsDescriptor } from '@autocut/utils/export/descriptors';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { manageError } from '@autocut/utils/manageError';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import css from './previewStep.module.css';

export type PreviewStepProps = {
  modeId?: 'silence' | 'ai';
};

export const PreviewStep = ({ modeId = 'silence' }: PreviewStepProps) => {
  const intl = useIntl();
  const { exportedAudioInfos, isSelectionParsing } = useAutoCutStore(state => ({
    exportedAudioInfos: state.sequence.exportedAudioInfos,
    isSelectionParsing: state.sequence.parsingProcess.isLoading,
  }));
  const [isPreviewLoaded, setIsPreviewLoaded] = useState(false);

  const handleOnClick = async () => {
    try {
      await getExportedAudioInfos(getAllClipsDescriptor(), intl);
      setIsPreviewLoaded(true);
    } catch (error: any) {
      manageError({ error });
    }
  };

  // Because you don't want the preview loading right after exporting an audio (ie: during process execution if it was not previously loaded)
  useEffect(() => {
    if (!exportedAudioInfos) setIsPreviewLoaded(false);
  }, [exportedAudioInfos]);

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_Step5_Title',
        defaultMessage: 'Preview',
      })}
      alwaysOpen={true}
      id="legacy-step4"
    >
      {!isPreviewLoaded || !exportedAudioInfos ? (
        <>
          <p className={css.softButton} onClick={handleOnClick}>
            <TranslatedMessage
              id={'generate_preview'}
              defaultMessage={'Generate the preview'}
            />
          </p>
          {isSelectionParsing && <div className={css.backdropShadow} />}
        </>
      ) : (
        <FlexContainer alignItems="center" style={{ position: 'relative' }}>
          <PreviewTimeline modeId={modeId} />
          {isSelectionParsing && <div className={css.backdropShadow} />}
        </FlexContainer>
      )}
    </StepLayout>
  );
};

import { ModalType } from '@autocut/components/modals';
import { CSEvent } from '@autocut/lib/cep/csinterface';
import { evalExtendscript, evalTS } from '@autocut/lib/utils/bolt';
import { StatCategory } from '@autocut/types/StatCategory.enum';
import { StatType } from '@autocut/types/StatType.enum';
import { sendStats } from '../stats.util';
import { setAutocutStore } from '../zustand';

export let fixTimeoutId: any;
const HEARTBEAT_INTERVAL = 2000;
const HEARTBEAT_TIMEOUT = 3000;

export const fixBrokenCEP = async () => {
  let isAlive = (await evalExtendscript('eval(1 + 1)')) !== 'EvalScript error.';

  if (isAlive) {
    await initHeartbeat();
    return;
  }

  let evalIndex = 0;

  while (!isAlive && evalIndex < 5) {
    isAlive = (await evalExtendscript('eval(1 + 1)')) !== 'EvalScript error.';
    evalIndex++;
  }

  await sendStats({
    type: StatType.ERROR,
    value: 1,
    traits: {
      type: 'LOST_CEP',
      fatal: isAlive ? 0 : 1,
    },
  });

  if (isAlive) {
    await initHeartbeat();
    return;
  }

  setAutocutStore('ui.openedModalName', ModalType.LostCepConnection);
};

export const initHeartbeat = async () => {
  setTimeout(requestHeartbeat, HEARTBEAT_INTERVAL);
  fixTimeoutId = setTimeout(fixBrokenCEP, HEARTBEAT_TIMEOUT);
};

export const requestHeartbeat = async () => {
  await evalTS('sendHeartbeat');
};

export const onHeartbeatCallback = async (event: CSEvent) => {
  const isAlive = event.data;

  if (isAlive) {
    setTimeout(requestHeartbeat, HEARTBEAT_INTERVAL);

    clearTimeout(fixTimeoutId);
    fixTimeoutId = setTimeout(fixBrokenCEP, HEARTBEAT_TIMEOUT);
  }
};

import * as React from 'react';

import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { StepLayout } from '@autocut/layouts/StepLayout';
import logLevel from '@autocut/types/logLevel.enum';
import { addSpeakerReducer } from '@autocut/utils/speakers/addSpeakerReducer';
import { setAutocutStore } from '@autocut/utils/zustand';
import { SpeakerComponent } from './Speaker';
import css from './speakersStep.module.css';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Helper } from '@autocut/components/atoms/Helper/Helper';
import { Speaker } from '@autocut/types/Speaker';

export const SpeakersStep = () => {
  const intl = useIntl();
  const { logMessage } = useLogger('SpeakersStep');
  const speakers = useAutoCutStore(
    state => state.ui.parameters.podcast.speakers
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_Step1Podcast_Title',
        defaultMessage: 'Speakers',
      })}
      alwaysOpen={true}
      helperId="helper_speakers_podcast"
      helperDefault="Add your speakers, give them a name and select the corresponding audio track."
    >
      <FlexContainer className={css.header} justifyContent="space-between">
        <div>
          <TranslatedMessage
            id="header_speakers_name"
            defaultMessage="Speaker's name"
          />
        </div>
        <FlexContainer gap={4} alignItems="center">
          <div>
            <TranslatedMessage
              id="header_speakers_track"
              defaultMessage="Audio track of the speaker"
            />
          </div>
          <Helper
            id_message="helper_speakers_podcast_audio_tracks"
            default_message="Select the audio track where we can hear the speaker, you can only select one track per speaker."
            position="left"
            textStyle={{
              width: '200px',
            }}
          />
        </FlexContainer>
      </FlexContainer>
      <div className={css.container}>
        {speakers.map((speaker: Speaker, index: number) => (
          <SpeakerComponent speaker={speaker} index={index} key={speaker.id} />
        ))}
        <div
          id="podcast-step2"
          className={css.addSpeaker}
          onClick={() => {
            logMessage(logLevel.notice, 'Adding speaker.');
            setAutocutStore(
              'ui.parameters.podcast.speakers',
              addSpeakerReducer
            );
          }}
        >
          <AiOutlinePlusCircle size={16} color="white" />
          <div>
            <TranslatedMessage
              id="text_podcast_add_speaker"
              defaultMessage="Add a speaker"
            />
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

import { isArrayContainsDuplicates } from '@autocut/utils/array.utils';
import { z as zod } from 'zod';

export const camerasValidationSchema = zod.object({
  id: zod.string(),
  speakerIds: zod
    .array(zod.string())
    .min(1, 'validation_error_cameras_speakerIds'),
  label: zod.string().min(1, 'validation_error_cameras_label'),
  value: zod.string(),
});

export const podcastValidationSchema = zod
  .object({
    minimumCamTime: zod
      .number()
      .min(0, 'validation_error_podcast_minimum_cam_time'),
    maximumCamTime: zod
      .number()
      .min(0, 'validation_error_podcast_maximum_cam_time'),
    deleteUnusedClips: zod.boolean({
      required_error: 'validation_error_podcast_deleteUnusedClips',
    }),
    speakers: zod.array(
      zod.object({
        id: zod.string(),
        name: zod.string(),
        cameras: zod
          .array(camerasValidationSchema)
          .min(1, 'validation_error_podcast_speakers_cameras'),
        audio: zod.object({
          label: zod.string(),
          value: zod.string().min(1, 'validation_error_podcast_speakers_audio'),
        }),
      })
    ),
    cameras: zod
      .array(camerasValidationSchema)
      .min(2, 'validation_error_podcast_cameras_length')
      .refine(
        cameras => !isArrayContainsDuplicates(cameras, camera => camera.value),
        'validation_error_podcast_cameras'
      ),

    // These values are not changed by the user, so no need to validate them
    toleranceTime: zod.number(),
    precision: zod.number(),
  })
  .refine(
    schema => schema.minimumCamTime < schema.maximumCamTime,
    'validation_error_podcast'
  );

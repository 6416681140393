import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { VscDebugBreakpointUnsupported } from 'react-icons/vsc';

import React from 'react';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Button } from '@autocut/components/atoms';
import { setAutocutStore } from '@autocut/utils/zustand';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ModalType } from '..';
import { BREAKING_VERSION } from '@autocut/utils/init/checkPproVersion';
import { killAllExtensions } from '@autocut/utils/system/system.utils';

const HandshakeRestartModal = () => {
  const { version } = useAutoCutStore(state => ({
    version: state.ppro.version,
  }));

  const isBreaking = BREAKING_VERSION.includes(version);

  return (
    <Modal
      closeModalFunction={() =>
        setAutocutStore(
          'ui.openedModalName',
          isBreaking ? ModalType.UnsupportedVersion : ''
        )
      }
      title={
        <TranslatedMessage
          id="handshake_restart_modal_title"
          defaultMessage="Hard reset needed"
        />
      }
      icon={<VscDebugBreakpointUnsupported size={36} />}
      footer={
        !isBreaking && (
          <Button onClickFunction={killAllExtensions}>
            <TranslatedMessage
              id={`handshake_restart_modal_cta`}
              defaultMessage="Close all my extensions"
            />
          </Button>
        )
      }
    >
      <p>
        <TranslatedMessage
          id={`handshake_restart_modal_text`}
          defaultMessage="We detected multiple AutoCut instances running. Click on the button bellow to close them all and avoid any problem."
        />
      </p>
    </Modal>
  );
};

export default HandshakeRestartModal;

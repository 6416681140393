import {
  MAC_UUID_COMMAND,
  OS_MAC,
  OS_WINDOWS,
  WINDOWS_UUID_COMMAND,
} from '@autocut/constants/constants';
import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import logLevel from '@autocut/types/logLevel.enum';
import { exec } from '@autocut/utils/exec.utils';
import { logger } from '@autocut/utils/logger';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { getOS } from '@autocut/utils/system/os.system.utils';

export const DEFAULT_UUID = 'bf79b79f-c77a-4d91-b2bc-d31235712a27';

export const fetchUUID = async (): Promise<any> => {
  const os = getOS();
  const cmdUUID = os === OS_MAC ? MAC_UUID_COMMAND : WINDOWS_UUID_COMMAND;

  const sentryData = {
    fingerPrint: SentryFingerPrintEnum.EXEC.GET_UUID,
    context: { shellCommand: cmdUUID },
  };

  let res = DEFAULT_UUID;

  try {
    if (os === OS_WINDOWS) {
      // eslint-disable-next-line no-var
      var { stdout } = await exec({
        command: cmdUUID,
        options: { shell: 'powershell.exe' },
        sentryData,
      });
    } else {
      // eslint-disable-next-line no-var
      var { stdout } = await exec({
        command: cmdUUID,
        sentryData,
      });
    }

    const result = stdout.match(
      /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/
    );

    if (!result || result.length === 0) {
      logger('generalUtils', logLevel.error, `UUID not found.`, {
        cmdUUID,
        stdout,
      });
    } else {
      const uuid = result.length > 0 ? result[0] : null;
      if (uuid) {
        res = uuid;
      }
    }
  } catch (error) {
    logger('generalUtils', logLevel.error, `Error while getting UUID.`, {
      error,
    });
  } finally {
    return res;
  }
};

export const refreshUUID = async (): Promise<any> => {
  const uuid = await fetchUUID();
  setAutocutStore('user.clientInfos.uuid', uuid);
  return uuid;
};

export const getUUID = async () => {
  //As it's called sooner than the store init we need to catch the error
  try {
    const res = autocutStoreVanilla().user.clientInfos.uuid;

    if (res && res !== DEFAULT_UUID) {
      return res;
    }
  } catch {
    return await fetchUUID();
  }

  //If do not exist we refresh it
  return await refreshUUID();
};

enum Env {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
  Test = 'test',
}

const ENV_MAP: Record<string, Env> = {
  production: Env.Production,
  preview: Env.Staging,
  development: Env.Development,
  test: Env.Test,
};

//VITE_ENV is defined in vite.config.ts at build time
const VITE_ENV_VAR = 'VITE_ENV';
const CURRENT_ENV =
  ENV_MAP[
    process.env.NODE_ENV === 'development' ? 'development' : VITE_ENV_VAR
  ];

export { CURRENT_ENV, Env };

import React from 'react';

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import { getHostName } from '@autocut/utils/system/hostname.system.utils';
import { getPproVersion } from '@autocut/utils';
import { getKey } from '@autocut/utils/localStorage.utils';
import { BrowserRouter } from 'react-router-dom';
import * as json from '../../package.json';
import App from './App';
import { AUTOCUT_CONSTANTS } from './constants/config';
import { SENTRY_DSN } from './constants/constants';
import { GameProvider } from './context/GameProvider';
import { LocaleProvider } from './context/LocaleContext';
import './index.css';
import logLevel from './types/logLevel.enum';
import { CURRENT_ENV } from './utils/currentEnv.utils';
import { logger } from './utils/logger';
import { getUUID } from '@autocut/utils/system/uuid.system.utils';
import { WindowSizeProvider } from './context/WindowSizeContext';

// This function allows to stringify an Error object
// It will be used for Sentry and the logger
// https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
if (!('toJSON' in Error.prototype))
  Object.defineProperty(Error.prototype, 'toJSON', {
    value: function () {
      const alt: any = {};

      Object.getOwnPropertyNames(this).forEach(function (key: any) {
        // @ts-ignore
        alt[key] = this[key];
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

logger(
  'BASE',
  logLevel.info,
  `AutoCut extension started in env : ${CURRENT_ENV}.`
);

const tagManagerArgs = {
  gtmId: AUTOCUT_CONSTANTS[CURRENT_ENV].GTM_ID,
};

const SENTRY_ENV = process.env.REACT_SENTRY_ENV || (CURRENT_ENV as string);

if (SENTRY_ENV === 'staging') {
  //@ts-ignore
  console.log(json.version);
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  enabled: ['production', 'staging', 'qa-staging', 'development'].includes(
    SENTRY_ENV
  ),
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: ['staging', 'qa-staging', 'development'].includes(
    SENTRY_ENV
  )
    ? 1.0
    : 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: ['staging', 'qa-staging', 'development'].includes(
    SENTRY_ENV
  )
    ? 1.0
    : 0.1,
  release: `autocut-extension-v3@${json.version}`,
  async beforeSend(event, hint) {
    // Setting default tags
    event.tags = {
      ...event.tags,
      'AutoCut Version': json.version,
      key: getKey(),
      UUID: await getUUID(),
      Host: await getHostName(),
      'PPro.Version': getPproVersion(),
      FingerPrint:
        event.fingerprint && event.fingerprint?.length > 0
          ? event.fingerprint[0]
          : 'No FingerPrint',
    };

    const containInvalidDataError =
      hint.attachments &&
      hint.attachments.length > 0 &&
      hint.attachments[0].data
        .toString()
        .match(/Invalid data found when processing input/gim) !== null;
    if (containInvalidDataError) {
      return null;
    }

    const containNoSuchFileOrDirectoryError =
      hint.attachments &&
      hint.attachments.length > 0 &&
      hint.attachments[0].data
        .toString()
        .match(/no such file or directory/gim) !== null;
    if (containNoSuchFileOrDirectoryError) {
      return null;
    }

    const isFingerPrintMatch =
      event.fingerprint &&
      event.fingerprint.includes('Error while sending statistics');
    const isAxiosBadRequestError =
      hint.attachments &&
      hint.attachments.length > 0 &&
      hint.attachments[0].data
        .toString()
        .match(/Request failed with status code (403|400)/gim) !== null;

    // Do not report expired token error
    const isExpiredTokenError = isFingerPrintMatch && isAxiosBadRequestError;
    if (isExpiredTokenError) {
      return null;
    }

    return event;
  },
  ignoreErrors: [
    /.*Reference QT Chapter track not found.*/,
    /.*co located POCs unavailable.*/,
  ],

  integrations: [new Sentry.Replay()],
});

TagManager.initialize(tagManagerArgs);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <LocaleProvider>
      <GameProvider>
        <WindowSizeProvider>
          <App />
        </WindowSizeProvider>
      </GameProvider>
    </LocaleProvider>
  </BrowserRouter>
);

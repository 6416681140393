import React from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { CiCirclePlus } from 'react-icons/ci';

import css from './PresetCard.module.css';

export type AddPresetCardProps = {
  onClick: () => void;
};

export const AddPresetCard = ({ onClick }: AddPresetCardProps) => {
  return (
    <div
      onClick={() => {
        onClick();
      }}
      className={`${css.container}`}
    >
      <FlexContainer
        className={css.subContainer}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={4}
      >
        <CiCirclePlus className={css.plusIcon} size={48} />
      </FlexContainer>
    </div>
  );
};

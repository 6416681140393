import { fs } from '@autocut/lib/cep/node';
import { ExportedAudioWithSilences } from '@autocut/types/ExportedAudioWithSilences';
import { getIntersections } from '@autocut/utils/cut/getTimelineBasedSilencesIntersections/getIntersections';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { convertNotUsedAudioToSilence } from './cut/convertNotUsedAudioToSilence/convertNotUsedAudioToSilence';
import {
  applyMargins,
  fillWithSilences,
  flattenIntervals,
  removeSilencesShorterThan,
  removeTalksShorterThan,
} from './cut/cutUtils';
import { getVideoSilencesIntervals } from './ffmpeg/ffmpeg.utils';
import { getAiVideoSilencesIntervals } from './vad.utils';
import { SilenceParams } from '@autocut/types/SilenceParams';
import { ExportedAudioElement } from '@autocut/types/ExportedAudio';

const getBlobFromPath = async (path: string) => {
  const contents = fs.readFileSync(path, {
    encoding: 'base64',
  });
  return `data:image/png;base64, ${contents}`;
};

const processRawTimelineBasedSilences = (
  exportedAudioInfos: ExportedAudioWithSilences[],
  audioTracks: Track[],
  cuttingParameters: SilenceParams,
  forPreview = false
) => {
  try {
    let timelineBasedSilences: number[][] = [];

    if (!exportedAudioInfos)
      throw new IncrementalError(
        'Empty exportedAudioInfos',
        'processRawTimelineBasedSilences'
      );

    if (exportedAudioInfos.length > 1) {
      const sequenceInfo = autocutStoreVanilla().sequence.infos;
      if (!sequenceInfo)
        throw new IncrementalError(
          'Empty sequenceInfo',
          'processRawTimelineBasedSilences'
        );

      const startTimeline = sequenceInfo.start;
      const endTimeline = sequenceInfo.end;

      exportedAudioInfos = convertNotUsedAudioToSilence(
        exportedAudioInfos,
        audioTracks,
        startTimeline,
        endTimeline
      );

      // On ajoute du silences aux clips avant leur début et après leur fin pour les "combler" sur toute la longueur de la sélection
      // afin de pouvoir réaliser l'intersection
      exportedAudioInfos = fillWithSilences(
        exportedAudioInfos,
        startTimeline,
        endTimeline
      );

      // On récupère tout les silences
      const timelineBasedSilencesByExportedAudio = exportedAudioInfos.map(
        exportedAudio => exportedAudio.timelineBasedSilences as number[][]
      );

      // Puis on réalise sur ces derniers l'intersection des intervalles pour obtenir les silences de la séquence en elle même
      timelineBasedSilences = timelineBasedSilencesByExportedAudio.reduce(
        (acc, current) => getIntersections(acc, current),
        [[0, Infinity]]
      );
    }
    // Sinon, ils correspondent simplement aux silences de l'unique piste
    else {
      timelineBasedSilences =
        exportedAudioInfos[0]?.timelineBasedSilences || [];
    }

    timelineBasedSilences.sort((a, b) => a[0] - b[0]);

    timelineBasedSilences = removeSilencesShorterThan(
      timelineBasedSilences,
      cuttingParameters
    );

    timelineBasedSilences = removeTalksShorterThan(
      timelineBasedSilences,
      cuttingParameters
    );

    // On applique les préférences utilisateurs aux silences de la timeline
    timelineBasedSilences = applyMargins(
      timelineBasedSilences,
      exportedAudioInfos,
      cuttingParameters,
      forPreview
    );

    timelineBasedSilences = removeSilencesShorterThan(
      timelineBasedSilences,
      cuttingParameters
    );

    return timelineBasedSilences;
  } catch (err: any) {
    throw new IncrementalError(err, 'processRawTimelineBasedSilences');
  }
};

// Renvoie les silences de la video donnée,
// ex : sourceBasedSilence :  {"silences":[[1.77,1.93],[3.44,4.46],[8.88,10.03],[21.23,21.54],[26.09,26.30],[29.20,29.32],[33.55,34.40]]}
// |!| We skipped stringify of the silences
const getSilencesOfVideoBase = async (
  getModeVideoSilenceIntervals: () => Promise<{
    tabStart: number[];
    tabEnd: number[];
  }>
) => {
  try {
    const { tabEnd, tabStart } = await getModeVideoSilenceIntervals();

    const silences: { silences: number[][] } = {
      silences: [] as number[][],
    };
    for (let i = 0; i < tabStart.length; i++) {
      const silenceElement: number[] = [tabStart[i], tabEnd[i]];

      silences.silences.push(silenceElement);
    }
    return silences;
  } catch (err: any) {
    throw new IncrementalError(err, 'getSilencesOfVideoBase');
  }
};

const getAiSilencesOfVideo = async (exportedAudio: ExportedAudioElement) => {
  return getSilencesOfVideoBase(() =>
    getAiVideoSilencesIntervals(exportedAudio.path)
  );
};

const getLegacySilencesOfVideo = async (
  exportedAudio: ExportedAudioElement
) => {
  return getSilencesOfVideoBase(() => getVideoSilencesIntervals(exportedAudio));
};

/***
 * Renvoie un couple d'intervals de silences
 * @param exportedAudio - Les informations d'export sur lequel récupérer les silences
 * @returns {{timelineBasedSilences : Number[][], sourceBasedSilences : Number[][]}}
 * timelineBasedSilences : Le tableau des intervals de silences relatif à la timeline
 * sourceBasedSilences : Le tableau des intervals de silences relatif à la source
 * Les deux sont de la forme [[x1, y1], ...]
 */
const getTimelineBasedSilencesBase = async (
  exportedAudio: ExportedAudioElement,
  getModeVideoSilenceIntervals: (
    exportedAudio: ExportedAudioElement
  ) => Promise<{ silences: number[][] }>
) => {
  try {
    const sourceBasedSilences: number[][] = (
      await getModeVideoSilenceIntervals(exportedAudio)
    ).silences;

    const silences = [...sourceBasedSilences];

    for (const interval of sourceBasedSilences) {
      //On calcule les timecodes auxquels couper PAR RAPPORT AU DEBUT DE LA TIMELINE
      interval[0] += exportedAudio.start;
      interval[1] += exportedAudio.start;
    }

    return {
      timelineBasedSilences: silences,
    };
  } catch (err: any) {
    throw new IncrementalError(err, 'getTimelineBasedSilencesBase');
  }
};

const getAiTimelineBasedSilences = async (
  exportedAudio: ExportedAudioElement
) => {
  try {
    return getTimelineBasedSilencesBase(
      exportedAudio,
      async (exportedAudio: ExportedAudioElement) => {
        try {
          return await getAiSilencesOfVideo(exportedAudio);
        } catch (err: any) {
          throw new IncrementalError(err, 'getAiTimelineBasedSilences');
        }
      }
    );
  } catch (err: any) {
    throw new IncrementalError(err, 'getAiTimelineBasedSilences');
  }
};

const getLegacyTimelineBasedSilences = async (
  exportedAudio: ExportedAudioWithSilences
) => {
  return getTimelineBasedSilencesBase(
    exportedAudio,
    (exportedAudio: ExportedAudioElement) =>
      getLegacySilencesOfVideo(exportedAudio)
  );
};

export {
  flattenIntervals,
  getAiSilencesOfVideo,
  getAiTimelineBasedSilences,
  getBlobFromPath,
  getLegacySilencesOfVideo,
  getLegacyTimelineBasedSilences,
  processRawTimelineBasedSilences,
};

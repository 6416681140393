import { z as zod } from 'zod';
import { languageOfTranscriptionValidationSchema } from './languageOfTranscription.validationSchema';

export const brollValidationSchema = zod
  .object({
    languageOfTranscription: languageOfTranscriptionValidationSchema,
    minimumBRollTime: zod
      .number()
      .min(0, 'validation_error_broll_minimumBRollTime'),
    maximumBRollTime: zod
      .number()
      .min(0, 'validation_error_broll_maximumBRollTime'),
  })
  .refine(
    schema => schema.minimumBRollTime < schema.maximumBRollTime,
    'validation_error_broll_min_max_duration'
  );

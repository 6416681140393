import { NumberInput } from '@autocut/components/atoms/NumberInput';
import { StepLayout } from '@autocut/layouts/StepLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import css from './bRollSizeStep.module.css';
import { debounce } from '@autocut/utils/debounce';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';

export const BRollSizeStep = () => {
  const intl = useIntl();
  const {
    minimumBRollTime: minimumBRollTimeParam,
    maximumBRollTime: maximumBRollTimeParam,
  } = useAutoCutStore(state => state.ui.parameters.broll);

  const [minTimeOfBRoll, setMinTimeOfBRoll] = useState(minimumBRollTimeParam);
  const [maxTimeOfBRoll, setMaxTimeOfBRoll] = useState(maximumBRollTimeParam);

  useEffect(() => {
    setMinTimeOfBRoll(minimumBRollTimeParam);
  }, [minimumBRollTimeParam]);

  useEffect(() => {
    setMaxTimeOfBRoll(maximumBRollTimeParam);
  }, [maximumBRollTimeParam]);

  const debouncedSetMinTimeOfBRoll = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters.broll.minimumBRollTime)
        setAutocutStore('ui.parameters.broll.minimumBRollTime', value);
    }, 1000),
    []
  );

  const debouncedSetMaxTimeOfBRoll = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters.broll.maximumBRollTime)
        setAutocutStore('ui.parameters.broll.maximumBRollTime', value);
    }, 1000),
    []
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'broll_duration_step_title',
        defaultMessage: 'B-Roll durations',
      })}
      alwaysOpen
    >
      <div className={css.container}>
        <NumberInput
          titleId="broll_duration_step_minimum_title"
          unitId="secondUnit"
          value={`${minTimeOfBRoll}`}
          targetedParameter={'minimumBRollTime'}
          onChange={async (value: string) => {
            setMinTimeOfBRoll(Number(value));
            await debouncedSetMinTimeOfBRoll(Number(value));
          }}
          helperDefault="Each B-Roll will be at least this long."
          helperId="broll_duration_step_minimum_helper"
        />
        <NumberInput
          titleId="broll_duration_step_maximum_title"
          unitId="secondUnit"
          value={`${maxTimeOfBRoll}`}
          targetedParameter={'maximumBRollTime'}
          onChange={async (value: string) => {
            setMaxTimeOfBRoll(Number(value));
            await debouncedSetMaxTimeOfBRoll(Number(value));
          }}
          helperDefault="Each B-Roll will be at most this long."
          helperId="broll_duration_step_maximum_helper"
          left={true}
        />
      </div>
    </StepLayout>
  );
};

import { RgbColor } from 'react-colorful';
import { XMLTrackItem } from '../xml/XMLTrackItem';
import { CAPTIONS_XML_PROJECT_LOCALE, secondToTickV2 } from './utils';
import {
  localGraphicParametersName,
  localMotionEffectName,
  localTransformEffectName,
} from '../../../jsx/ppro/enums';
import { rgbToHex } from '../color.utils';

export const CAPTION_MOGRT_WIDTH = 1920;
export const CAPTION_MOGRT_HEIGHT = 1080;

export const copyBackgroundClip = async ({
  originalClip,
  startTime,
  endTime,
  index,
  radius,
  opacity,
  color,
  size,
  padding,
}: {
  originalClip: XMLTrackItem;
  startTime: number;
  endTime: number;
  index: number;
  radius: number;
  opacity: number;
  color: RgbColor;
  size: [number, number];
  padding: [number, number];
}) => {
  if (!opacity) return { newClip: null };

  const copiedVideoFilterComponents = [
    localGraphicParametersName[CAPTIONS_XML_PROJECT_LOCALE],
    localTransformEffectName[CAPTIONS_XML_PROJECT_LOCALE],
    localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
  ];

  const newClip = originalClip.clone({
    startTick: secondToTickV2(startTime),
    endTick: secondToTickV2(endTime),
    index,
    copiedVideoFilterComponents,
  });

  const totalSize = [size[0] + padding[0] * 2, size[1] + padding[1] * 2];

  newClip.updateMogrtParams({
    color: rgbToHex(color, false),
    opacity,
    roundness: radius,
    size: totalSize,
  });

  index++;

  return { newClip };
};

export const calculateBackgroundPosition = (
  [sequenceWidth, sequenceHeight]: [number, number],
  xPercentage: number,
  yPercentage: number,
  mogrtScaleFactor: number
) => {
  const scaledMogrtWidth = CAPTION_MOGRT_WIDTH * mogrtScaleFactor;
  const scaledMogrtHeight = CAPTION_MOGRT_HEIGHT * mogrtScaleFactor;
  const widthDifference = scaledMogrtWidth - sequenceWidth;
  const heightDifference = scaledMogrtHeight - sequenceHeight;

  const newXPosition = xPercentage * sequenceWidth + widthDifference / 2;
  const newYPosition = yPercentage * sequenceHeight + heightDifference / 2;

  return {
    x: newXPosition,
    xPercentage: newXPosition / scaledMogrtWidth,
    y: newYPosition,
    yPercentage: newYPosition / scaledMogrtHeight,
  };
};

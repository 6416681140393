import { z as zod } from 'zod';

export const resizePresetValidationSchema = zod.object(
  {
    name: zod.string().min(1, 'validation_error_resize_preset_name'),
    width: zod.number().min(1, 'validation_error_resize_preset_width'),
    height: zod.number().min(1, 'validation_error_resize_preset_height'),
    icons: zod.array(zod.any()).optional(),
  },
  { required_error: 'validation_error_resize_preset' }
);

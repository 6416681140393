export type ClipType = 1 | 2;

export enum ClipTypeEnum {
  Video = 1,
  Audio = 2,
}

export interface CEPClip {
  id: string;
  path: string;
  type: ClipType;
  numTrack: number;
  indexInTrack: number;
  audioChannelMapping: {
    type: number;
    number: number;
  };
  in: number;
  out: number;
  start: number;
  end: number;
  frameRate: number;
  speed: number;
  linkedClipsId: string[];
  isSelected: boolean;
}

export interface ClipCollection {
  length: number;
  numItems: number;
  [index: number]: CEPClip;
}

export interface CEPSequence {
  id: string;
  timebase: string;
  start: number;
  end: number;
  inPoint: number | undefined;
  outPoint: number | undefined;
  videoTracks: CEPTrack[];
  audioTracks: CEPTrack[];
  nbClipsVideo: number;
  nbClipsAudio: number;
  settings: CEPSequenceSettings;
}
export interface CEPSequenceSettings {
  frameRate: number;
  videoDisplayFormat: number;
  timebase: number;
  audioChannelCount: number;
  audioTrackType: number;
  width: number;
  height: number;
}
export interface CEPTrack {
  id: string;
  index: number;
  type: ClipType;
  start: number;
  end: number;
  clips: CEPClip[];
  isUsed: boolean;
}

export interface TrackItemWPath {
  // Don't know why but build crash on this line with a ts error
  //@ts-ignore
  trackItem: TrackItem;
  path: string;
}

// Mirror of 'src/js/types/ExportedAudio.d.ts/ExportDescriptorOptions'
export type ExportDescriptorOptions = {
  muteUnselected?: boolean;
};

export type ExportedAudioElement = {
  start: number;
  end: number;
  path: string;
};

import { range } from '../math.utils';
import { getParametersForMode } from '../parameters.utils';
import { delimitZoomIntervals } from './delimitZoomIntervals';
import { AudioWavData } from './getAudioClipsWavData';
import {
  LOWER_LIMIT_OBJECTIVE_NL_SHARE,
  LOWER_LIMIT_OBJECTIVE_SPEED,
  LOWER_LIMIT_SPEED,
  PSO,
  UPPER_LIMIT_OBJECTIVE_NL_SHARE,
  UPPER_LIMIT_OBJECTIVE_SPEED,
  UPPER_LIMIT_SPEED,
} from './pso';
import { addAnchorAndZoomCoef } from './zoomIntervals.utils';

type ComputeDynamicZoomIntervalsArgs = {
  clipsAudioData: AudioWavData[];
  totalRMSValue: number[];
  startingAggressivityFactor: number;
  effectiveDynamicZoomCoverage: number;
};

type ComputeSmoothZoomIntervalsArgs = {
  clipsAudioData: AudioWavData[];
  totalRMSValue: number[];
  startingAggressivityFactor: number;
  effectiveSmoothZoomCoverage: number;
};

export const computeDynamicZoomIntervals = async ({
  clipsAudioData,
  effectiveDynamicZoomCoverage,
  startingAggressivityFactor,
  totalRMSValue,
}: ComputeDynamicZoomIntervalsArgs) => {
  const {
    dynamicMinZoomTime,
    dynamicMaxZoomTime,
    dynamicMinZoomIntensity,
    autoZoomMaxCoef,
  } = getParametersForMode('zoom');
  const autoZoomMinCoef = 1 + dynamicMinZoomIntensity * (autoZoomMaxCoef - 1);

  const bestDynamicZoomIntervals = await delimitZoomIntervals({
    clipsAudioData,
    totalRMSValue,
    startingAggressivityFactor,
    minZoomTime: dynamicMinZoomTime,
    maxZoomTime: dynamicMaxZoomTime,
    goalCoverage: effectiveDynamicZoomCoverage,
    isSmooth: false,
  });

  addAnchorAndZoomCoef(
    bestDynamicZoomIntervals,
    autoZoomMinCoef,
    autoZoomMaxCoef
  );

  return bestDynamicZoomIntervals;
};

export const computeSmoothZoomIntervals = async ({
  clipsAudioData,
  effectiveSmoothZoomCoverage,
  startingAggressivityFactor,
  totalRMSValue,
}: ComputeSmoothZoomIntervalsArgs) => {
  const { smoothMinZoomTime, smoothMaxZoomTime, nervousness, autoZoomMaxCoef } =
    getParametersForMode('zoom');

  //Calcul intervals zoom smooth
  const bestSmoothZoomIntervals = await delimitZoomIntervals({
    clipsAudioData,
    totalRMSValue,
    startingAggressivityFactor,
    minZoomTime: smoothMinZoomTime,
    maxZoomTime: smoothMaxZoomTime,
    goalCoverage: effectiveSmoothZoomCoverage,
    isSmooth: true,
  });

  // calcul et attribution coefs zoom smooth
  const objectiveNonLimitedShare = range(
    0,
    1,
    LOWER_LIMIT_OBJECTIVE_NL_SHARE,
    UPPER_LIMIT_OBJECTIVE_NL_SHARE,
    nervousness
  );
  const objectiveSpeed = range(
    0,
    1,
    LOWER_LIMIT_OBJECTIVE_SPEED,
    UPPER_LIMIT_OBJECTIVE_SPEED,
    nervousness
  );
  const { globalBestPosition } = PSO({
    coefMax: autoZoomMaxCoef,
    coefMin: 1,
    numIterations: 500,
    numParticles: 500,
    objectiveNonLimitedShare,
    objectiveSpeed,
    smoothZoomIntervals: bestSmoothZoomIntervals,
    speedMax: UPPER_LIMIT_SPEED,
    speedMin: LOWER_LIMIT_SPEED,
  });
  const [smoothZoomCoefMin, smoothZoomCoefMax, smoothZoomSpeed] =
    globalBestPosition;
  addAnchorAndZoomCoef(
    bestSmoothZoomIntervals,
    smoothZoomCoefMin,
    smoothZoomCoefMax,
    smoothZoomSpeed
  );

  return bestSmoothZoomIntervals;
};

import * as React from 'react';
import { CSSProperties, useState, useEffect } from 'react';

import './Progress.css';

type ProgressProps = {
  progress?: number;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  color?: CSSProperties['color'];
  textColor?: CSSProperties['color'];
  innerText?: string;
  innerTextSize?: CSSProperties['fontSize'];
  style?: CSSProperties;
};

const Progress = ({
  progress = 0,
  width,
  height,
  color,
  textColor,
  innerText,
  innerTextSize,
  style,
}: ProgressProps) => {
  const [boundedProgress, setBoundedProgress] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(1000);

  useEffect(() => {
    const newValue = Math.max(0, Math.min(100, progress));
    if (boundedProgress !== newValue) {
      setBoundedProgress(newValue);
      if (boundedProgress > newValue) {
        setAnimationDuration(0);
        setTimeout(() => {
          setAnimationDuration(1000);
        }, 50);
      }
    }
  }, [progress]);

  return (
    <div
      className="progress"
      style={
        {
          ...style,
          '--progress-height': height,
          '--progress-width': width,
          '--progress-value': boundedProgress,
          '--progress-color': color,
          '--progress-animation-duration': `${animationDuration}ms`,
          '--progress-text-color': textColor,
          '--progress-font-size': innerTextSize,
        } as CSSProperties
      }
    >
      <p>{innerText}</p>
    </div>
  );
};

export default Progress;

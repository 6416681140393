import * as React from 'react';
import { useEffect, useState } from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const PreviewTimelineLegend = () => {
  return (
    <div style={{ width: '100%', marginTop: '16px' }}>
      <FlexContainer justifyContent="space-around">
        <PreviewTimelineLegendElement color="green" />
        <PreviewTimelineLegendElement color="greenLight" />
        <PreviewTimelineLegendElement color="red" />
      </FlexContainer>
    </div>
  );
};

type Props = {
  color: 'red' | 'green' | 'greenLight';
};

const PreviewTimelineLegendElement = ({ color }: Props) => {
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    if (color == 'green') {
      setBackgroundColor('rgba(0, 255, 0, 0.25)');
    } else if (color == 'greenLight') {
      setBackgroundColor('rgba(0, 255, 0, 0.42)');
    } else if (color == 'red') {
      setBackgroundColor('rgba(255, 0, 0, 0.75)');
    } else {
      setBackgroundColor('rgba(0,0,0,1)');
    }
  }, []);
  return (
    <div style={{ fontSize: '12px' }}>
      <FlexContainer alignItems="center">
        <div
          style={{
            width: '20px',
            height: '20px',
            backgroundColor: backgroundColor,
            marginRight: '3px',
          }}
        />
        {color === 'green' && (
          <TranslatedMessage id="text_legend_kept" defaultMessage="Kept" />
        )}
        {color === 'greenLight' && (
          <TranslatedMessage id="text_legend_margin" defaultMessage="Margins" />
        )}
        {color === 'red' && (
          <TranslatedMessage
            id="text_legend_deleted"
            defaultMessage="Deleted"
          />
        )}
      </FlexContainer>
    </div>
  );
};

import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/atoms/Stepper/StepperRouter/StepperRouter';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModeWrapper } from '../ModeWrapper';
import { ViralClipsSelector } from './Steps/Selector/ViralClipsSelector';
import { ViralClipsLanguage } from './Steps/Language/ViralClipsLanguage';

export const ViralClipsMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={HiOutlineVideoCamera}
      text="Auto Viral Clips detector"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        if (path === 'language') {
          setAutocutStore('ui.process.isPaused', false);
        }
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/cut/viral_clips"
        steps={[
          {
            label: intl.formatMessage({
              id: 'viral_clips_step_language',
              defaultMessage: 'Select clips and language',
            }),
            path: 'language',
            element: <ViralClipsLanguage />,
          },
          {
            label: intl.formatMessage({
              id: 'viral_clips_step_selector',
              defaultMessage: 'Select viral clips',
            }),
            path: 'selector',
            element: <ViralClipsSelector />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

import { DiscordCta } from '@autocut/components/atoms/DiscordCta/DiscordCta';
import {
  HomepageCardAutoZoom,
  HomepageCardBRoll,
  HomepageCardCaptions,
  HomepageCardChapter,
  HomepageCardLegacy,
  HomepageCardPodcast,
  HomepageCardRepeat,
  HomepageCardResize,
  HomepageCardSwearWords,
  HomepageCardViralClips,
} from '@autocut/components/atoms/HomepageCard/HomepageCards';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { ModeTitle } from '@autocut/components/atoms/ModeTitle/ModeTitle';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { TourContainer } from '@autocut/components/containers/TourContainer';
import { RELOAD_MODAL_TIMEOUT } from '@autocut/components/modals/RestartAutoCutModal/RestartAutoCutModal';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { onboardingLocalStorageKeys } from '@autocut/onboarding/onboardingLocalStorageKeys.enum';
import { modeSelectionSteps } from '@autocut/onboarding/steps/modeSelection.steps';
import { StatType } from '@autocut/types/StatType.enum';
import { sendStats } from '@autocut/utils';
import { setLocalStorage } from '@autocut/utils/localStorage.utils';
import { manuallyRefreshSequence } from '@autocut/utils/sequence.utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import { enableBodyScroll } from 'body-scroll-lock';
import * as React from 'react';
import { useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import css from './Homepage.module.css';
import {
  CARD_SIZE,
  GRID_GAP,
  calculateColumnNumbers,
  computeAndApplyBackgroundMask,
} from './utils';
import { useOnboarding } from '@autocut/context/OnboardingProvider/OnboardingProvider';
import { EOnboardingSteps } from '@autocut/context/OnboardingProvider/utils';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { HomepageTrialButton } from '@autocut/components/atoms/HomepageTrialButton/HomepageTrialButton';

export const Homepage = () => {
  useOnboarding(EOnboardingSteps.WELCOME);

  const { openedTimestamp } = useAutoCutStore(state => ({
    openedTimestamp: state.utils.openedTimestamp,
  }));

  useEffect(() => {
    setAutocutStore('ui.process.isPaused', true);
    setAutocutStore('sequence.infos', undefined);

    return () => {
      setAutocutStore('ui.process.isPaused', false);
      void manuallyRefreshSequence();
    };
  }, []);

  useEffect(() => {
    const openReloadAutoCutModal = () => {
      setAutocutStore('ui.openedModalName', ModalType.RestartAutoCut);
    };
    const timeout = setTimeout(
      openReloadAutoCutModal,
      Math.max(0, openedTimestamp + RELOAD_MODAL_TIMEOUT - Date.now())
    );

    return () => clearTimeout(timeout);
  }, [openedTimestamp]);

  return (
    <TourContainer
      steps={modeSelectionSteps}
      beforeClose={() => {
        setLocalStorage(onboardingLocalStorageKeys.ModeSelection, true);
        enableBodyScroll(document as unknown as HTMLElement);
      }}
      afterOpen={async () => {
        await sendStats({
          type: StatType.ON_BOARDING_STARTED,
          value: 1,
          traits: {
            page: 'homepage',
          },
        });
      }}
    >
      <HomepageContent />
    </TourContainer>
  );
};

// in pixel

const HomepageCards = [
  HomepageCardLegacy,
  HomepageCardPodcast,
  HomepageCardCaptions,
  HomepageCardAutoZoom,
  HomepageCardRepeat,
  HomepageCardSwearWords,
  HomepageCardResize,
  HomepageCardBRoll,
  HomepageCardChapter,
  HomepageCardViralClips,
];

const HomepageContent = () => {
  const intl = useIntl();
  const { isBeta } = useAutoCutStore(state => ({
    isBeta: state.ppro.isBeta,
  }));
  const cardsContainerRef = React.useRef<HTMLDivElement>(null);
  const backgroundRef = React.useRef<HTMLDivElement>(null);

  // const { setIsOpen: setIsTourOpen } = useTour();
  // React.useEffect(() => {
  //   if (!getLocalStorage(onboardingLocalStorageKeys.ModeSelection)) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  React.useEffect(() => {
    const cardsContainerElement = cardsContainerRef.current;
    const backgroundElement = backgroundRef.current;

    const columnNumbers = calculateColumnNumbers({
      cardsContainerElement: cardsContainerElement,
      cardsNumber: HomepageCards.length,
    });
    cardsContainerElement?.style.setProperty(
      '--column-numbers',
      columnNumbers.toString()
    );

    computeAndApplyBackgroundMask({
      cardsContainerElement: cardsContainerElement,
      backgroundElement: backgroundElement,
    });

    window.addEventListener('resize', () => {
      const columnNumbers = calculateColumnNumbers({
        cardsContainerElement: cardsContainerElement,
        cardsNumber: HomepageCards.length,
      });
      cardsContainerElement?.style.setProperty(
        '--column-numbers',
        columnNumbers.toString()
      );

      computeAndApplyBackgroundMask({
        cardsContainerElement: cardsContainerElement,
        backgroundElement: backgroundElement,
      });
    });

    return () =>
      window.removeEventListener('resize', () => {
        const columnNumbers = calculateColumnNumbers({
          cardsContainerElement: cardsContainerElement,
          cardsNumber: HomepageCards.length,
        });
        cardsContainerElement?.style.setProperty(
          '--column-numbers',
          columnNumbers.toString()
        );

        computeAndApplyBackgroundMask({
          cardsContainerElement: cardsContainerElement,
          backgroundElement: backgroundElement,
        });
      });
  }, []);

  return (
    <>
      <DiscordCta />

      <FlexContainer
        style={{
          position: 'relative',
          width: '100%',
          height: 56,
        }}
        alignItems="center"
        justifyContent="center"
      >
        <ModeTitle
          Icon={AiOutlineHome}
          text={intl.formatMessage({
            id: 'homepage_title',
            defaultMessage: 'Homepage',
          })}
          withHomeButton={false}
        />

        <HomepageTrialButton
          style={{
            position: 'absolute',
            right: 64,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </FlexContainer>
      <div className={`dashboardContainer ${css.mainContainer}`}>
        {isBeta && (
          <div style={{ marginBottom: 16 }}>
            <InfoText style="warning">
              <TranslatedMessage
                id="warning_beta_version"
                defaultMessage="It seems you are running a beta version of Premiere Pro. You might encounter some issues with AutoCut. Please test it out on the latest stable version of Premiere Pro."
              />
            </InfoText>
          </div>
        )}
        <div
          ref={cardsContainerRef}
          id="modeSelection-step1"
          className={css.cardsContainer}
          style={{
            gridTemplateColumns: `repeat(var(--column-numbers), ${CARD_SIZE}px)`,
            gridAutoRows: CARD_SIZE,
            gap: GRID_GAP,
          }}
        >
          {HomepageCards.map((Card, index) => {
            return <Card key={index} />;
          })}
          <div ref={backgroundRef} className={css.background} />
          {/* If you are adding new cards, you can modify the background (add blobs) to make it suit the changes */}
          <div className={css.blobContainer}>
            <div className={css.blob} />
            <div className={css.blob} />
            <div className={css.blob} />
            <div className={css.blob} />
          </div>
        </div>
      </div>
    </>
  );
};

export const Channel: { [key: string]: string } = {
  FL: 'FL',
  FR: 'FR',
  FC: 'FC',
  LFE: 'LFE',
  BL: 'BL',
  BR: 'BR',
  FLC: 'FLC',
  FRC: 'FRC',
  BC: 'BC',
  SL: 'SL',
  SR: 'SR',
  TC: 'TC',
  TFL: 'TFL',
  TFC: 'TFC',
  TFR: 'TFR',
  TBL: 'TBL',
  TBC: 'TBC',
  TBR: 'TBR',
  DL: 'DL',
  DR: 'DR',
  WL: 'WL',
  WR: 'WR',
  SDL: 'SDL',
  SDR: 'SDR',
  LFE2: 'LFE2',
  TSL: 'TSL',
  TSR: 'TSR',
  BFC: 'BFC',
  BFL: 'BFL',
  BFR: 'BFR',
};

declare global {
  type ChannelType = keyof typeof Channel;
}

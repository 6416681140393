import { CheckPill } from '@autocut/components/atoms/CheckPill/CheckPill';
import { TextWithHelper } from '@autocut/components/atoms/TextWithHelper';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import css from './ZoomConstraintStep.module.css';

export const ZoomConstraintStep = () => {
  const zoomParameters = useAutoCutStore(state => state.ui.parameters.zoom);

  return (
    <FlexContainer
      justifyContent="flex-start"
      alignItems="center"
      gap={24}
      className={css.container}
    >
      <div className={css.title}>
        <TextWithHelper
          helperId="autozoom_constraint_zoom_helper"
          helperDefault="If yes, no zoom will span over more than one clip. If no, your whole selection will be processed as one single audio file and zooms will ignore individuals clips in the timeline."
        >
          <TranslatedMessage
            id="autozoom_constraint_zoom_title"
            defaultMessage="Constraint zooms to clip"
          />
        </TextWithHelper>
      </div>
      <div className={css.input}>
        <CheckPill
          id="constraintZoomsParameter"
          isSelected={zoomParameters.constraintZoom}
          setIsSelected={(value: boolean) =>
            setAutocutStore('ui.parameters.zoom.constraintZoom', value)
          }
          size={16}
        />
      </div>
    </FlexContainer>
  );
};

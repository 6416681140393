import React, { Suspense } from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';

import css from './BRollCreditsModal.module.css';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoCheckmarkSharp, IoCopyOutline } from 'react-icons/io5';
import { Button } from '@autocut/components/atoms';
import logLevel from '@autocut/types/logLevel.enum';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useLogger } from '@autocut/hooks/useLogger';
import ConfettiBackground from '@autocut/components/atoms/ConfettiBackground/ConfettiBackground';
import { closeModal } from '@autocut/utils/modal/closeModal';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { clipboard } from '@autocut/utils/clipboard.util';

export const BRollCreditsModal = () => {
  const intl = useIntl();
  const { logMessage } = useLogger('BRollCreditsModal');

  const usersToCredit = useAutoCutStore(
    state => state.ui.broll.usersToCredit
  ) as {
    id: number;
    name: string;
    url: string;
  }[];

  return (
    <Modal
      title={intl.formatMessage({
        id: 'endprocess_modal_title',
        defaultMessage: 'Process completed!',
      })}
      icon={
        <div className={css.iconContainer}>
          <IoCheckmarkSharp size={24} color="white" />
        </div>
      }
      closeModalFunction={closeModal}
      extraChildren={
        <Suspense fallback={<div />}>
          <ConfettiBackground />
        </Suspense>
      }
      footer={
        <Button
          buttonType="tertiary"
          compact
          onClickFunction={() => {
            logMessage(logLevel.info, 'Open Review modal.');
            setAutocutStore('ui.openedModalName', ModalType.Review);
          }}
        >
          <TranslatedMessage
            id="star_review_title"
            defaultMessage="Grade AutoCut !"
          />
        </Button>
      }
    >
      <p>
        <TranslatedMessage
          id="endprocess_modal_text_1"
          defaultMessage="Process finished successfully!"
        />
      </p>
      <FlexContainer justifyContent="space-between" alignItems="center">
        <p className={css.creditContainer}>
          <TranslatedMessage
            id={'broll_credit_modal_title'}
            defaultMessage={
              'Here are the credits for the B-Rolls used in your video : '
            }
          />
          {usersToCredit.map((user, index) => (
            <p key={user.id}>
              {index + 1} @{user.name} -{' '}
              {intl.formatMessage({
                id: 'broll_credit_modal_provided_by',
                defaultMessage: 'provided by Pexels',
              })}
              (
              <span
                className={css.link}
                onClick={() => {
                  openLinkInBrowser(user.url);
                }}
              >
                <TranslatedMessage
                  id={'broll_credit_modal_link'}
                  defaultMessage={'link'}
                />
              </span>
              )
            </p>
          ))}
        </p>
        <Button
          className={css.copyButton}
          onClickFunction={async () => {
            await clipboard.copy(
              usersToCredit
                .map(
                  (user, index) =>
                    `${index + 1} @${user.name} - ${intl.formatMessage({
                      id: 'broll_credit_modal_provided_by',
                      defaultMessage: 'provided by Pexels',
                    })} (${user.url})`
                )
                .join('\n')
            );
          }}
        >
          <FlexContainer alignItems="center" gap="8px">
            <IoCopyOutline />
            <TranslatedMessage
              id={'broll_credit_modal_button'}
              defaultMessage={'Copy to clipboard'}
            />
          </FlexContainer>
        </Button>
      </FlexContainer>

      <p>
        <TranslatedMessage
          id="endprocess_modal_text_2"
          defaultMessage="Feel free to give us feedback on the quality of the result"
        />
      </p>
    </Modal>
  );
};

import React, { useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { debounce } from '@autocut/utils/debounce';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { NumberInput } from '../../atoms/NumberInput';
import css from './paddingStep.module.css';

export type PaddingStepProps = {
  modeId?: 'silence';
};

export const PaddingStep = ({ modeId = 'silence' }: PaddingStepProps) => {
  const intl = useIntl();
  const { marginBefore: marginBeforeParam, marginAfter: marginAfterParam } =
    useAutoCutStore(state => state.ui.parameters[modeId]);

  const [marginBefore, setMarginBefore] = useState(marginBeforeParam);
  const [marginAfter, setMarginAfter] = useState(marginAfterParam);

  useEffect(() => {
    setMarginBefore(marginBeforeParam);
  }, [marginBeforeParam]);

  useEffect(() => {
    setMarginAfter(marginAfterParam);
  }, [marginAfterParam]);

  const debouncedSetMarginBefore = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].marginBefore)
        setAutocutStore(`ui.parameters.${modeId}.marginBefore`, value);
    }, 1000),
    []
  );

  const debouncedSetMarginAfter = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].marginAfter)
        setAutocutStore(`ui.parameters.${modeId}.marginAfter`, value);
    }, 1000),
    []
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_Step2_Title',
        defaultMessage: 'Padding',
      })}
    >
      <div className={css.container}>
        <NumberInput
          titleId="text_Step2_Before"
          unitId="millisecondUnit"
          value={`${marginBefore * 1000}`}
          targetedParameter={'marginBefore'}
          onChange={async (value: string) => {
            setMarginBefore(Number(value));
            await debouncedSetMarginBefore(Number(value));
          }}
          helperDefault="Silence duration before the talk segment."
          helperId="helper_Step2_1"
        />
        <NumberInput
          titleId="text_Step2_After"
          unitId="millisecondUnit"
          value={`${marginAfter * 1000}`}
          targetedParameter={'marginAfter'}
          onChange={async (value: string) => {
            setMarginAfter(Number(value));
            await debouncedSetMarginAfter(Number(value));
          }}
          helperDefault="Silence duration after the talk segment."
          helperId="helper_Step2_2"
          left={true}
        />
      </div>
    </StepLayout>
  );
};

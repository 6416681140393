import React from 'react';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Helper } from '../Helper/Helper';
import { Input } from '../Inputs/Input';
import { BiPaste } from 'react-icons/bi';

import css from './LinkToUserFileInput.module.css';
import { clipboard } from '@autocut/utils/clipboard.util';

export const LinkToUserFileInput = ({
  linkToUserFile,
  setLinkToUserFile,
}: {
  linkToUserFile: string;
  setLinkToUserFile: (linkToUserFile: string) => void;
}) => {
  return (
    <FlexContainer flexDirection="column" className={css.link_to_user_file}>
      <span className={css.link_to_user_file_title}>
        <TranslatedMessage
          id="send_log_link_to_user_file"
          defaultMessage="Please provide a link to your video & audio file."
        />
      </span>

      <p>
        <FlexContainer alignItems="center" gap={4}>
          <TranslatedMessage
            id="send_log_link_to_user_file_platform"
            defaultMessage="(WeTransfer, Google Drive or any other file sharing service)."
          />
          <Helper
            position="top"
            textStyle={{
              minWidth: '160px',
            }}
            id_message="send_log_link_to_user_file_platform_helper"
            default_message="This will assist us in quickly resolving your issue."
          />
        </FlexContainer>
      </p>
      <FlexContainer alignItems="center">
        <Input
          value={linkToUserFile}
          onChange={e => setLinkToUserFile(e.target.value)}
        />
        <BiPaste
          className={css.paste_icon}
          color="white"
          size={24}
          title="Paste from clipboard"
          onClick={async () => {
            const text = (await clipboard.paste()) as string;

            setLinkToUserFile(text);
          }}
        />
      </FlexContainer>
      <p>
        <TranslatedMessage
          id="send_log_link_to_user_file_end"
          defaultMessage="This will assist us in quickly resolving your issue."
        />
      </p>
    </FlexContainer>
  );
};

import { REPEAT_ALGORITHM } from '@autocut/modes/repeat/Steps/Language/Parts/AlgorithmSelectStep';
import { z as zod } from 'zod';
import { languageOfTranscriptionValidationSchema } from './languageOfTranscription.validationSchema';

export const repeatValidationSchema = zod.object({
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  transistions: zod.enum(['none', 'j_cut', 'l_cut', 'both', 'constant_power'], {
    invalid_type_error: 'validation_error_silence_transitions',
  }),
  silences: zod.enum(['delete', 'keep', 'mute', 'delete_keep_spaces'], {
    invalid_type_error: 'validation_error_silence_silences',
  }),
  selectedAlgorithm: zod.enum(
    [REPEAT_ALGORITHM.FAST, REPEAT_ALGORITHM.PRECISE],
    {
      invalid_type_error: 'validation_error_repeat_algorithm',
    }
  ),
});

import { z as zod } from 'zod';
import { fontValidationSchema } from './font.validationSchema';

const featureObject = zod.object({
  disabled: zod.boolean().optional(),
  showWarning: zod.boolean().optional(),
});

const unsupportedFeaturesObject = zod.object({
  uppercase: featureObject,
  wordBox: featureObject,
  chapters: featureObject,
  viralClips: featureObject,
});

export const languageOfTranscriptionValidationSchema = zod.object({
  value: zod.string().min(1, 'validation_error_languageOfTranscription'),
  label: zod.string().min(1),
  textDirection: zod.enum(['ltr', 'rtl']).optional(),
  unsupportedFeatures: zod
    .function()
    .args(fontValidationSchema.optional())
    .returns(unsupportedFeaturesObject)
    .optional(),
  maxWordsPerChunk: zod.number().optional(),
});

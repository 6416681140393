import React from 'react';

import { Button } from '@autocut/components/atoms';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { InOutStep } from '@autocut/components/steps/InOutStep/InOutStep';
import { ReframeStep } from '@autocut/components/steps/ReframeStep/ReframeStep';
import { ResizePresetStep } from '@autocut/components/steps/ResizePresetStep/ResizePresetStep';
import { WatermarkStep } from '@autocut/components/steps/WatermarkStep/WatermarkStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { evalTS } from '@autocut/lib/utils/bolt';
import { CiMobile1 } from 'react-icons/ci';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ResizeFooterButton } from '../../components/atoms/ResizeFooterButton/ResizeFooterButton';
import { ModeWrapper } from '../ModeWrapper';
import { handleResizeProcess } from '@autocut/utils/resize/handleResizeProcess';

import resizeSelectionImageUrl from '@autocut/assets/images/selectionScreen/resize.gif';
const resizeSelectionImage = new URL(resizeSelectionImageUrl, import.meta.url)
  .href;

export const ResizeMode = () => {
  const intl = useIntl();
  const selectionInfos = useAutoCutStore(state => state.sequence.infos);

  return (
    <ModeWrapper
      Icon={CiMobile1}
      text="AutoResize"
      displayCondition={
        !!(
          selectionInfos &&
          selectionInfos.inPoint !== null &&
          selectionInfos.outPoint !== null
        )
      }
      invalidSelectionScreen={{
        titleId: 'resize_invalid_selection_screen_title',
        titleDefaultMessage: 'Add In & Out points in your timeline',
        texts: [
          {
            id: 'resize_invalid_selection_screen_text1',
            defaultMessage: 'To use Resize, your need to add in & out points.',
          },
        ],
        customCta: (
          <Button
            buttonType="tertiary"
            size={'sm'}
            compact
            onClickFunction={async () => {
              await evalTS('setInOutPointsFullSequence');
            }}
          >
            <TranslatedMessage
              id="resize_invalid_selection_screen_cta"
              defaultMessage="Set In & Out points on full sequence"
            />
          </Button>
        ),
        selectionImage: resizeSelectionImage,
      }}
    >
      <InfoText
        style="info"
        onClose={() => {
          return;
        }}
      >
        <TranslatedMessage
          id="resize_text_info"
          defaultMessage="Social media generator resize and reframe automatically your sequence for all Social media best dimensions."
        />
      </InfoText>

      <InOutStep />
      <ResizePresetStep />
      <ReframeStep />
      <WatermarkStep />
      <ModeFooter
        renderButton={(isLoading, isDisabled) => (
          <ResizeFooterButton
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClickFunction={async () => await handleResizeProcess(intl)}
          />
        )}
      />
    </ModeWrapper>
  );
};

import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import styleConfig from '@autocut/components/styleconfig';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { Button } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const NoScript = () => {
  const [hover, setHover] = React.useState<boolean>(false);

  const style = {
    container: {
      width: '100vw',
      height: '100vh',
    },
    div: {
      textAlign: 'center' as const,
    },
    h2: {
      fontSize: '16px',
      marginTop: '100px',
      marginBottom: '10px',
    },
    p: {
      fontSize: '16px',
      marginBottom: '10px',
    },
    a: {
      fontSize: '16px',
      marginBottom: '16px',
      color: styleConfig.primaryColor,
      textDecorationLine: 'none',
      cursor: hover ? 'pointer' : '',
    },
  };

  return (
    <div>
      <div style={style.div}>
        <h2 style={style.h2}>
          <TranslatedMessage
            id="error_modal_title_scriptNotLoaded"
            defaultMessage="An error occurred while loading AutoCut"
          />
        </h2>
        <p style={style.p}>
          <TranslatedMessage
            id="error_modal_text_scriptNotLoaded"
            defaultMessage="An error occurred while loading AutoCut"
          />
        </p>
        <p style={style.p}>
          <TranslatedMessage
            id="error_modal_tips_scriptNotLoaded"
            defaultMessage="An error occurred while loading AutoCut"
          />
        </p>
        <FlexContainer alignItems="center" flexDirection="column">
          <a
            style={style.a}
            onClick={() => openLinkInBrowser('mailto:contact@autocut.fr')}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            contact@autocut.fr
          </a>
          <Button
            onClickFunction={() =>
              (window.location.href = window.location.origin)
            }
            size="md"
          >
            Reload AutoCut
          </Button>
        </FlexContainer>
      </div>
    </div>
  );
};

import logLevel from '@autocut/types/logLevel.enum';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { getHostName } from '@autocut/utils/system/hostname.system.utils';
import { getUUID } from '@autocut/utils/system/uuid.system.utils';
import { logger } from '../logger';
import { os } from '@autocut/lib/cep/node';
import { exec } from '../exec.utils';
import { GET_MAC_ID_COMMAND } from '@autocut/constants/constants';
import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import { autocutApi } from '../http.utils';

export const askForTrialKey = async (email: string, locale: string) => {
  const uuid = await getUUID();
  if (uuid === undefined) {
    logger('askForTrialKey', logLevel.error, "Can't read the computer's UUID");

    throw new Error("can't_read_uuid_error");
  }

  let pcName = await getHostName();

  const platform = os.platform();
  if (platform === 'darwin') {
    const { stdout } = await exec({
      command: GET_MAC_ID_COMMAND,
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.GET_MAC_ID,
        context: { shellCommand: GET_MAC_ID_COMMAND },
      },
    });
    pcName = stdout.trim();
  }

  if (pcName === undefined) {
    logger('askForTrialKey', logLevel.error, "Can't read the computer's name.");
    throw new Error("can't_read_host_error");
  }

  const params = {
    email: email,
    language: locale,
    pc_uuid: uuid,
    pc_name: pcName,
    os: getOS(),
  };

  logger(
    'askForTrialKey',
    logLevel.notice,
    'Asking server for new Trial key.',
    {
      params,
    }
  );
  const { data } = await autocutApi.get(`/keys/generate-trial-key`, {
    params,
  });

  if (data === undefined) {
    logger('askForTrialKey', logLevel.error, "Can't reach the server.");
    throw new Error("can't_reach_server_error");
  }

  if (data.message === 'OK' || data.message === 'OK2') {
    logger('askForTrialKey', logLevel.notice, 'Server has generated a key.');

    return true;
  } else {
    logger(
      'askForTrialKey',
      logLevel.warn,
      'Server has not generated the key',
      { data }
    );

    throw new Error(data.message);
  }
};

import { onSequenceSelectionChangedCallbackDebounced } from '@autocut/utils/init/registerCEPListeners';
import { autocutStoreVanilla } from './zustand';
import { evalTS } from '@autocut/lib/utils/bolt';

export const getAvailableVideoTrackIndex = (minIndex?: number) => {
  const sequenceInfos = autocutStoreVanilla().sequence.infos;
  if (!sequenceInfos) throw new Error('No sequence infos available');

  const availableVideoTracks = sequenceInfos.videoTracks
    .sort((a, b) => a.index - b.index)
    .map(track => {
      return track.nbClips === 0
        ? !minIndex || track.index > minIndex
          ? track.index
          : false
        : false;
    })
    .filter(r => r !== false) as number[];

  if (availableVideoTracks?.length === 0) {
    return undefined;
  }

  return availableVideoTracks;
};

export const getAvailableAudioTrackIndex = (minIndex?: number) => {
  const sequenceInfos = autocutStoreVanilla().sequence.infos;
  if (!sequenceInfos) throw new Error('No sequence infos available');

  const availableAudioTracks = sequenceInfos.audioTracks
    .sort((a, b) => a.index - b.index)
    .map(track => {
      return track.nbClips === 0
        ? !minIndex || track.index > minIndex
          ? track.index
          : false
        : false;
    })
    .filter(r => r !== false) as number[];

  if (availableAudioTracks?.length === 0) {
    return undefined;
  }

  return availableAudioTracks;
};

export const manuallyRefreshSequence = async () => {
  await evalTS('setActiveSequenceAsDefault');
  await onSequenceSelectionChangedCallbackDebounced();
};

export const getProjectName = () =>
  autocutStoreVanilla().sequence?.lastSettings?.projectItem?.treePath?.match(
    /(?<=(\/|\\)).*(?=\.prproj(\/|\\))/g
  )?.[0];

const encodeProjectName = (projectName?: string) =>
  projectName
    ? Buffer.from(projectName, 'utf-8').toString('base64')
    : undefined;

export const getEncodedProjectName = () => {
  const projectName = getProjectName();
  return `project_${encodeProjectName(projectName)}`;
};

import {
  getGlobalResourceConfig,
  getSimpleResourceConfig,
  requestResourceOnInit,
} from '@autocut/utils/downloadableRessources.utils';

export const resourcesConfigs = {
  //CAPTIONS
  captions_xml_project: getSimpleResourceConfig({
    resourceName: 'captions_xml_project',
    fileName: 'captions_xml_project_2.73.xml',
  }),
  caption_resources: getSimpleResourceConfig({
    resourceName: 'captions_resources',
    fileName: 'captions_resources_2.71.1.zip',
  }),
  //ZOOMS
  adjustment_layer_prproj: getSimpleResourceConfig({
    resourceName: 'adjustment_layer_prproj',
    fileName: 'AutoCutBin.prproj',
  }),
  //GLOBALS
  wav_epr: requestResourceOnInit(
    getGlobalResourceConfig({
      resourceName: 'wav_epr',
      fileName: 'WAV_48_kHz_16_bits.epr',
    })
  ),
  mp3_epr: requestResourceOnInit(
    getGlobalResourceConfig({
      resourceName: 'mp3_epr',
      fileName: 'MP3_256Kb.epr',
    })
  ),
  //PROFANITY
  bleep_sound_1_wav: getSimpleResourceConfig({
    resourceName: 'bleep_sound_1_wav',
    fileName: 'bleep_sound_1.wav',
  }),
  bleep_sound_2_wav: getSimpleResourceConfig({
    resourceName: 'bleep_sound_2_wav',
    fileName: 'bleep_sound_2.wav',
  }),
} as const;

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import {
  CaptionDrawHookProps,
  CaptionCanvasObjectHookProps,
  CaptionsDrawHookOutput,
} from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import { CaptionsParameters } from '@autocut/types/CaptionsParameters';
import {
  generateCanvasFontParam,
  measureTextOnCanvas,
  splitTextIntoLines,
} from '@autocut/utils/captions/canvas/canvas.utils';
import { DeepPartial } from '@autocut/utils/type.utils';
import {
  AutoCutStateKeys,
  deepGet,
  setAutocutStore,
} from '@autocut/utils/zustand';
import { cloneDeep, merge } from 'lodash';
import { useIntl } from 'react-intl';

export const useCaptionsTextMetrics = ({
  xFactor,
  yFactor,
  displayedText,
  providedWidth,
  ...fixedParams
}: Omit<CaptionDrawHookProps, 'metrics'> &
  DeepPartial<CaptionsParameters> & {
    displayedText?: string;
    providedWidth?: number;
  }) => {
  const intl = useIntl();
  const baseText =
    displayedText ??
    intl.formatMessage({
      id: 'captions_customization_example_text',
    });

  const { params, lastSequenceSettings } = useAutoCutStore(state => ({
    params: {
      text: state.ui.parameters.caption.text,
      formating: state.ui.parameters.caption.formating,
    },
    lastSequenceSettings: state.sequence.lastSettings,
  }));

  const mergedParams = merge(cloneDeep(params), fixedParams);

  const text = mergedParams.formating.uppercase
    ? baseText.toUpperCase()
    : baseText;

  const fontSize = mergedParams.text.fontSize / yFactor;
  const fontFamily = mergedParams.text.font.fontFamily;
  const italic = mergedParams.formating.italic;
  const fontParamObject = {
    italic,
    fontSize,
    fontFamily,
  };
  const fontParam = generateCanvasFontParam(fontParamObject);
  const {
    metrics: { height: maxLineHeight },
  } = measureTextOnCanvas('Éj', fontParamObject, false);
  const maxAllowedWidth =
    providedWidth ??
    ((mergedParams.formating.maxWidth / 100) * lastSequenceSettings.width) /
      xFactor;

  const lines = splitTextIntoLines(text, {
    font: fontParamObject,
    maxWidth: maxAllowedWidth,
    uppercase: mergedParams.formating.uppercase,
  }).slice(0, mergedParams.formating.nbLines);
  const maxLineWidth = lines.reduce(
    (max, current) => Math.max(max, current.width),
    0
  );
  const lineHeight = (fontSize * 120) / 100;
  const textMetrics = {
    width: maxLineWidth,
    height: lineHeight * lines.length,
  };

  const accentSize =
    maxLineHeight -
    measureTextOnCanvas('Ej', fontParamObject, false).metrics.height;

  return {
    fontParamObject,
    fontParam,
    textMetrics,
    lineHeight,
    lines,
    maxLineHeight,
    maxLineWidth,
    accentSize,
  };
};

export const useCanvasObject = ({
  name,
  paths,
  boundingBox,
  forcedX,
  forcedY,
  zIndex,
  draw,
  objectMetrics,
  ...rest
}: CaptionCanvasObjectHookProps &
  CaptionsDrawHookOutput & {
    name: string;
    paths: {
      x: AutoCutStateKeys;
      y: AutoCutStateKeys;
      enabled?: AutoCutStateKeys;
    };
  }) => {
  const { x, y, enabled } = useAutoCutStore(state => ({
    x: deepGet(state, paths.x.split('.')),
    y: deepGet(state, paths.y.split('.')),
    enabled: paths.enabled ? deepGet(state, paths.enabled.split('.')) : true,
  }));

  return {
    name,
    enabled,
    zIndex: zIndex || 0,
    x: forcedX ?? x * boundingBox.width,
    y: forcedY ?? y * boundingBox.height,
    forcedX,
    forcedY,
    width: objectMetrics.width,
    height: objectMetrics.height,
    draw,
    objectMetrics,
    onMove: (newX: number, newY: number) => {
      if (!forcedX) setAutocutStore(paths.x, newX / boundingBox.width);
      if (!forcedY) setAutocutStore(paths.y, newY / boundingBox.height);
    },
    ...rest,
  };
};

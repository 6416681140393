import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { ZoomTypes } from '@autocut/types/ZoomType';
import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  PresetCard,
  Preset,
} from '../../../../components/atoms/PresetCard/PresetCard';
import css from './ZoomPresetStep.module.css';

const zoomPresets: Preset<'zoom'>[] = [
  {
    id: 'calm',
    parameters: {
      totalZoomPercentage: 0.5,
      dynamicZoomPercentage: 0.05,
      smoothZoomPercentage: 0.95,
      nervousness: 0,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
  {
    id: 'paced',
    parameters: {
      totalZoomPercentage: 0.6,
      dynamicZoomPercentage: 0.1,
      smoothZoomPercentage: 0.9,
      nervousness: 0.3,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
  {
    id: 'energetic',
    parameters: {
      totalZoomPercentage: 0.72,
      dynamicZoomPercentage: 0.15,
      smoothZoomPercentage: 0.85,
      nervousness: 0.6,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
  {
    id: 'hyperactive',
    parameters: {
      totalZoomPercentage: 0.85,
      dynamicZoomPercentage: 0.2,
      smoothZoomPercentage: 0.8,
      nervousness: 1,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
  },
];

export const ZoomPresetStep = () => {
  const intl = useIntl();

  return (
    <ParametersGroupLayout
      title={intl.formatMessage({
        id: 'zoom_preset_title',
        defaultMessage: 'Default presets',
      })}
    >
      <div className={css.presetsContainer}>
        {zoomPresets.map(preset => (
          <PresetCard mode="zoom" preset={preset} key={preset.id} />
        ))}
      </div>
    </ParametersGroupLayout>
  );
};

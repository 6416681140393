import styled from '@emotion/styled';

import styleConfig from '../../styleconfig';

interface InputProps {
  inputSize?: 'sm' | 'md' | 'lg';
}

export const Input = styled.input((props: InputProps) => ({
  padding: props.inputSize
    ? `${styleConfig.inputSizes[`padding_${props.inputSize}`]}`
    : '0.5rem 0.25rem',
  minWidth: props.inputSize
    ? `${styleConfig.inputSizes[`minWidth_${props.inputSize}`]}`
    : '250px',
  width: '100%',
  border: '1px solid #94a3b8',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 'bold',
  fontSize: '12px',
  color: 'white',
  '&:focus': {
    outline: '#e33d00',
    border: '1px #E64E19 solid',
  },
  ':hover': {
    border: '1px #E64E19 solid',
  },
}));

import { Button, Input } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { createCaptionsPreset } from '@autocut/utils/captionsPresets/createCaptionsPreset';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoCreateOutline } from 'react-icons/io5';
import css from './CreatePresetModal.module.scss';
import { CheckPill } from '@autocut/components/atoms/CheckPill/CheckPill';
import { AxiosError } from 'axios';
import { manageError } from '@autocut/utils/manageError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { AnimatedCanvasType } from '@autocut/utils/captions/canvas/classes/animatedCanvas.class.utils';
import { DraggableCanvas } from '@autocut/utils/captions/canvas/classes/draggableCanvas.class.utils';
import {
  ExampleCaptionText,
  TEXT_PREVIEW_HEIGHT,
  TEXT_PREVIEW_WIDTH,
} from '../../../ExampleCaption/ExampleCaptionText';
import { captionPresetTitleSchema } from '@autocut/validationSchemas/caption.validationSchema';
import { ZodError } from 'zod';

export const CreatePresetModal = () => {
  const intl = useIntl();

  const { captionsParameters } = useAutoCutStore(state => ({
    captionsParameters: state.ui.parameters.caption,
  }));
  const [newPresetTitle, setNewPresetTitle] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [error, setError] = useState<React.ReactNode | undefined>(undefined);
  const [textPreviewCanvas, setTextPreviewCanvas] = useState<
    AnimatedCanvasType<DraggableCanvas> | undefined
  >(undefined);

  const handleCreate = async (presetTitle: string, isPublic: boolean) => {
    try {
      captionPresetTitleSchema.parse(presetTitle);
    } catch (e: any) {
      const firstIssue =
        (e as ZodError).issues[0].message ??
        'validation_error_caption_title_unknown';
      setError(
        <TranslatedMessage
          id={firstIssue}
          defaultMessage="An unknown error occurred while validating the title."
        />
      );

      return;
    }

    try {
      const newPreset = await createCaptionsPreset({
        captionsParameters,
        title: presetTitle,
        isPublic,
        canvas: textPreviewCanvas,
      });

      if (newPreset === null) throw new Error('Could not create preset');

      if (await handleTrialTask('preset'))
        setAutocutStore('ui.openedModalName', ModalType.GamingTrial);

      setAutocutStore('ui.parameters.caption.userPresets', newPreset);

      setAutocutStore('ui.openedModalName', '');
    } catch (e: any) {
      if (e.message === 'preset-already-exists') {
        setError(
          <TranslatedMessage
            id="captions_presetCustomization_error_preset_already_exists"
            defaultMessage="This preset already exists. Please choose another name."
          />
        );
        return;
      }

      manageError({
        error: new IncrementalError(
          e as AxiosError<
            {
              message: string;
              statusCode: number;
            },
            any
          >,
          'createCaptionsPreset'
        ),
      });

      textPreviewCanvas?.destroy();
      setAutocutStore('ui.openedModalName', '');
    }
  };

  return (
    <>
      <Modal
        icon={<IoCreateOutline color="#eb5b26" size="1.5rem" />}
        title={
          <TranslatedMessage
            id="captions_presetCustomization_createPresetModal_title"
            defaultMessage="Create new preset"
          />
        }
        closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      >
        {isPublic && !!newPresetTitle.length && (
          <div style={{ display: 'none' }}>
            <ExampleCaptionText
              key={`captions-text-preview`}
              boundingBox={{
                height: TEXT_PREVIEW_HEIGHT,
                width: TEXT_PREVIEW_WIDTH,
              }}
              height={TEXT_PREVIEW_HEIGHT}
              setCanvas={setTextPreviewCanvas}
              displayedText={newPresetTitle}
            />
          </div>
        )}
        <FlexContainer
          flexDirection="column"
          gap={24}
          style={{
            minWidth: '300px',
          }}
        >
          <FlexContainer
            flexDirection="column"
            gap={8}
            style={{
              width: '300px',
            }}
          >
            <FlexContainer flexDirection="column" gap={2}>
              <Input
                itemType="text"
                value={newPresetTitle}
                onChange={e => setNewPresetTitle(e.target.value)}
                placeholder={intl.formatMessage({
                  id: 'captions_presetCustomization_createPresetModal_placeholder',
                  defaultMessage: 'Preset name (at least two words)',
                })}
              />

              {error && (
                <p
                  style={{
                    color: 'red',
                  }}
                >
                  {error}
                </p>
              )}
            </FlexContainer>

            <FlexContainer
              flexDirection="row"
              flexWrap="nowrap"
              gap={24}
              alignItems="center"
            >
              <p>
                <TranslatedMessage
                  id="captions_presetCustomization_createPresetModal_isPublic"
                  defaultMessage="Share to community - public"
                />
              </p>

              <CheckPill
                id="captions_preset_customization_is_public"
                hideLabel
                isSelected={isPublic}
                setIsSelected={setIsPublic}
                size={'10px'}
              />
            </FlexContainer>
          </FlexContainer>

          <div className={css.buttonsContainer}>
            <Button
              onClickFunction={() => {
                setAutocutStore('ui.openedModalName', '');
              }}
              className={css.button}
              buttonType="secondary"
            >
              <TranslatedMessage
                id="confirm_modal_cancel_label"
                defaultMessage="Cancel"
              />
            </Button>

            <Button
              onClickFunction={() => handleCreate(newPresetTitle, isPublic)}
              className={css.button}
            >
              <TranslatedMessage
                id="captions_presetCustomization_createPresetModal_create"
                defaultMessage="Create preset"
              />
            </Button>
          </div>
        </FlexContainer>
      </Modal>
    </>
  );
};

import React from 'react';
import css from './SegmentedControl.module.scss';

export function SegmentedControl<T extends string>({
  options,
  selectedOption = options[0].value,
  onChange,
}: {
  options: { value: T; label: React.ReactNode }[];
  selectedOption?: T;
  onChange?: (option: T) => void;
}) {
  return (
    <div className={css.segmentedControl}>
      {options.map((option, index) => (
        <div
          key={index}
          className={`${css.option} ${
            option.value === selectedOption ? css.selected : ''
          }`}
          onClick={() => onChange?.(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

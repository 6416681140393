export const DEFAULT_COLUMNS_NUMBER = 3;
export const CARD_SIZE = 144;
export const GRID_GAP = 16;
export const BORDER_RADIUS = 8;

// Function to calculate the number of columns based on the container width
// As auto-fit does not work nicely in this chromium version
export const calculateColumnNumbers = ({
  cardsContainerElement,
  cardsNumber,
}: {
  cardsContainerElement: HTMLDivElement | null;
  cardsNumber: number;
}) => {
  if (!cardsContainerElement) return DEFAULT_COLUMNS_NUMBER;

  const cardWidth = CARD_SIZE + GRID_GAP;
  const containerWidth = cardsContainerElement.clientWidth;
  let columnNumbers = Math.floor(containerWidth / cardWidth);

  const columnWidthWithoutLastGap =
    (columnNumbers + 1) * CARD_SIZE + columnNumbers * GRID_GAP;

  if (columnWidthWithoutLastGap < containerWidth) columnNumbers += 1;

  return Math.min(columnNumbers, cardsNumber);
};

// The window effect of the homepage background relies on 3 layers :
// The first one, at bottom level : contains only the orange circles (blob)
// The second one : empty but mimick the background of the extension
// The third one, at top level : contains the cards
// Since all the layers are of the same dimensions and positioned absolutely, they stack perfectly on each other.
// Knowing that we just have to compute a polygon per card that will serve of mask for the background to achieve the "see through" effect.
// This way the background (2nd layer) will let the circles go trough the cards but hide anything else
// All the complicated calculation are here to account for the borders of the cards
export const computeAndApplyBackgroundMask = ({
  cardsContainerElement,
  backgroundElement,
}: {
  cardsContainerElement: HTMLDivElement | null;
  backgroundElement: HTMLDivElement | null;
}) => {
  if (!cardsContainerElement || !backgroundElement) return;

  const cardElements = Array.from(cardsContainerElement.children).filter(
    htmlElement =>
      Array.from(htmlElement.classList).some(className =>
        className.includes('card')
      )
  );

  const paths: string[] = [];
  const cardsContainerBoundingRect =
    cardsContainerElement.getBoundingClientRect();
  for (const card of cardElements) {
    const cardBoundingRect = card.getBoundingClientRect();
    // Mask position is relative to background container but cards bouding client rect is relative to top left corner
    // We substract the cardsContainer position to get the cards position relative to the containers
    const maskCoordinates = {
      x: cardBoundingRect.x - cardsContainerBoundingRect.x,
      y: cardBoundingRect.y - cardsContainerBoundingRect.y,
    };
    const topBorder = `h${CARD_SIZE - 2 * BORDER_RADIUS}`;
    const rightBorder = `v${CARD_SIZE - 2 * BORDER_RADIUS}`;
    const bottomBorder = `h-${CARD_SIZE - 2 * BORDER_RADIUS}`;
    const leftBorder = `v-${CARD_SIZE - 2 * BORDER_RADIUS}`;

    const topRightCorner = `a${BORDER_RADIUS},${BORDER_RADIUS} 0 0 1 ${BORDER_RADIUS},${BORDER_RADIUS}`;
    const bottomRightCorner = `a${BORDER_RADIUS},${BORDER_RADIUS} 0 0 1 -${BORDER_RADIUS},${BORDER_RADIUS}`;
    const bottomLeftCorner = `a${BORDER_RADIUS},${BORDER_RADIUS} 0 0 1 -${BORDER_RADIUS},-${BORDER_RADIUS}`;
    const topLeftCorner = `a${BORDER_RADIUS},${BORDER_RADIUS} 0 0 1 ${BORDER_RADIUS},-${BORDER_RADIUS}`;
    const path = `<path d="M${maskCoordinates.x + BORDER_RADIUS},${
      maskCoordinates.y
    } ${topBorder} ${topRightCorner} ${rightBorder} ${bottomRightCorner} ${bottomBorder} ${bottomLeftCorner} ${leftBorder} ${topLeftCorner}" fill="black"/>`;
    paths.push(path);
  }
  const mask = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${
    cardsContainerBoundingRect.width
  } ${
    cardsContainerBoundingRect.height
  }" preserveAspectRatio="none">${paths.join(' ')}</svg>') 100%,
    linear-gradient(#fff,#fff);`;

  const backgroundStyle = `-webkit-mask:${mask};-webkit-mask-composite:destination-out;`;
  backgroundElement.setAttribute('style', backgroundStyle);
};

import adrianThumbnail from '@autocut/assets/images/authors/adrian.png?url';
import alexandreThumbnail from '@autocut/assets/images/authors/alexandre.png?url';
// import olivierThumbnail from '@autocut/assets/images/authors/olivier.png?url';
import maximeThumbnail from '@autocut/assets/images/authors/maxime.png?url';
import amelieThumbnail from '@autocut/assets/images/authors/amelie.png?url';
import myriamThumbnail from '@autocut/assets/images/authors/myriam.png?url';
import arthurThumbnail from '@autocut/assets/images/authors/arthur.png?url';

export enum EAuthors {
  ADRIAN = 'adrian',
  ALEXANDRE = 'alexandre',
  OLIVIER = 'olivier',
  MAXIME = 'maxime',
  AMELIE = 'amelie',
  MYRIAM = 'myriam',
  ARTHUR = 'arthur',
}

export const Authors: Record<EAuthors, { name: string; picture: string }> = {
  [EAuthors.ADRIAN]: {
    name: 'Adrian',
    picture: adrianThumbnail,
  },
  [EAuthors.ALEXANDRE]: {
    name: 'Alexandre',
    picture: alexandreThumbnail,
  },
  [EAuthors.OLIVIER]: {
    name: 'Olivier',
    picture: 'olivierThumbnail',
  },
  [EAuthors.MAXIME]: {
    name: 'Maxime',
    picture: maximeThumbnail,
  },
  [EAuthors.AMELIE]: {
    name: 'Amelie',
    picture: amelieThumbnail,
  },
  [EAuthors.MYRIAM]: {
    name: 'Myriam',
    picture: myriamThumbnail,
  },
  [EAuthors.ARTHUR]: {
    name: 'Arthur',
    picture: arthurThumbnail,
  },
};

export const LANGUAGE_TO_MODEL: {
  [key: string]: {
    provider: 'deepgram' | 'google';
    model: string;
    value?: string;
    location?: 'us-central1' | 'europe-west4' | 'asia-southeast1';
  }[];
} = {
  //NOVA-2
  en: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'en-GB',
      location: 'europe-west4',
    },
  ],
  'en-US': [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      location: 'us-central1',
    },
  ],
  fr: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'fr-FR',
      location: 'europe-west4',
    },
  ],
  de: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'de-DE',
      location: 'europe-west4',
    },
  ],
  hi: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'hi-IN',
      location: 'asia-southeast1',
    },
  ],
  pt: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'pt-PT',
      location: 'europe-west4',
    },
  ],
  'pt-BR': [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'pt-BR',
      location: 'us-central1',
    },
  ],
  es: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'es-ES',
      location: 'europe-west4',
    },
  ],
  'es-419': [{ model: 'nova-2', provider: 'deepgram', value: 'es-419' }],
  da: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'da-DK',
      location: 'europe-west4',
    },
  ],
  nl: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'nl-NL',
      location: 'europe-west4',
    },
  ],
  it: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'it-IT',
      location: 'europe-west4',
    },
  ],
  ja: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ja-JP',
      location: 'asia-southeast1',
    },
  ],
  ko: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ko-KR',
      location: 'asia-southeast1',
    },
  ],
  no: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'no-NO',
      location: 'europe-west4',
    },
  ],
  pl: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'pl-PL',
      location: 'europe-west4',
    },
  ],
  sv: [
    { model: 'nova-2', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'sv-SE',
      location: 'europe-west4',
    },
  ],
  bg: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'bg-BG',
      location: 'europe-west4',
    },
  ],
  ca: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ca-ES',
      location: 'europe-west4',
    },
  ],
  cs: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'cs-CZ',
      location: 'europe-west4',
    },
  ],
  el: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'el-GR',
      location: 'europe-west4',
    },
  ],
  et: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'et-EE',
      location: 'europe-west4',
    },
  ],
  fi: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'fi-FI',
      location: 'europe-west4',
    },
  ],
  hu: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'hu-HU',
      location: 'europe-west4',
    },
  ],
  id: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'id-ID',
      location: 'asia-southeast1',
    },
  ],
  lt: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'lt-LT',
      location: 'europe-west4',
    },
  ],
  lv: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'lv-LV',
      location: 'europe-west4',
    },
  ],
  ms: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ms-MY',
      location: 'asia-southeast1',
    },
  ],
  ro: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ro-RO',
      location: 'europe-west4',
    },
  ],
  ru: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ru-RU',
      location: 'europe-west4',
    },
  ],
  sk: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'sk-SK',
      location: 'europe-west4',
    },
  ],
  th: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'th-TH',
      location: 'asia-southeast1',
    },
  ],
  tr: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'tr-TR',
      location: 'europe-west4',
    },
  ],
  uk: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'uk-UA',
      location: 'europe-west4',
    },
  ],
  vi: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'vi-VN',
      location: 'asia-southeast1',
    },
  ],
  zh: [
    { provider: 'deepgram', model: 'nova-2' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'cmn-Hans-CN',
      location: 'asia-southeast1',
    },
  ],

  //ENHANCED
  ta: [
    { model: 'enhanced', provider: 'deepgram' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ta-IN',
      location: 'asia-southeast1',
    },
  ],

  //WHISPER
  af: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'af-ZA',
      location: 'europe-west4',
    },
  ],
  am: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'am-ET',
      location: 'europe-west4',
    },
  ],
  as: [{ provider: 'deepgram', model: 'whisper' }],
  az: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'az-AZ',
      location: 'europe-west4',
    },
  ],
  ba: [{ provider: 'deepgram', model: 'whisper' }],
  be: [{ provider: 'deepgram', model: 'whisper' }],
  bn: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'bn-IN',
      location: 'asia-southeast1',
    },
  ],
  bo: [{ provider: 'deepgram', model: 'whisper' }],
  br: [{ provider: 'deepgram', model: 'whisper' }],
  bs: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'bs-BA',
      location: 'europe-west4',
    },
  ],
  cy: [{ provider: 'deepgram', model: 'whisper' }],
  eu: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'eu-ES',
      location: 'europe-west4',
    },
  ],
  fa: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'fa-IR',
      location: 'europe-west4',
    },
  ],
  fo: [{ provider: 'deepgram', model: 'whisper' }],
  gl: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'gl-ES',
      location: 'europe-west4',
    },
  ],
  gu: [
    {
      model: 'chirp',
      provider: 'google',
      value: 'gu-IN',
      location: 'asia-southeast1',
    },
  ],
  ha: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ha-NG',
      location: 'europe-west4',
    },
  ],
  haw: [{ provider: 'deepgram', model: 'whisper' }],
  he: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'iw-IL',
      location: 'europe-west4',
    },
  ],
  hr: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'hr-HR',
      location: 'europe-west4',
    },
  ],
  ht: [{ provider: 'deepgram', model: 'whisper' }],
  hy: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'hy-AM',
      location: 'europe-west4',
    },
  ],
  is: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'is-IS',
      location: 'europe-west4',
    },
  ],
  jw: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'jv-ID',
      location: 'asia-southeast1',
    },
  ],
  ka: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ka-GE',
      location: 'europe-west4',
    },
  ],
  kk: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'kk-KZ',
      location: 'europe-west4',
    },
  ],
  km: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'km-KH',
      location: 'asia-southeast1',
    },
  ],
  kn: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'kn-IN',
      location: 'asia-southeast1',
    },
  ],
  lb: [{ provider: 'deepgram', model: 'whisper' }],
  ln: [{ provider: 'deepgram', model: 'whisper' }],
  lo: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'lo-LA',
      location: 'asia-southeast1',
    },
  ],
  mg: [{ provider: 'deepgram', model: 'whisper' }],
  mi: [{ provider: 'deepgram', model: 'whisper' }],
  mk: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'mk-MK',
      location: 'europe-west4',
    },
  ],
  ml: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ml-IN',
      location: 'asia-southeast1',
    },
  ],
  mn: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'mn-MN',
      location: 'asia-southeast1',
    },
  ],
  mr: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'mr-IN',
      location: 'asia-southeast1',
    },
  ],
  mt: [{ provider: 'deepgram', model: 'whisper' }],
  my: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'my-MM',
      location: 'asia-southeast1',
    },
  ],
  ne: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ne-NP',
      location: 'asia-southeast1',
    },
  ],
  nn: [{ provider: 'deepgram', model: 'whisper' }],
  oc: [{ provider: 'deepgram', model: 'whisper' }],
  pa: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'pa-Guru-IN',
      location: 'asia-southeast1',
    },
  ],
  ps: [{ provider: 'deepgram', model: 'whisper' }],
  sa: [{ provider: 'deepgram', model: 'whisper' }],
  sd: [{ provider: 'deepgram', model: 'whisper' }],
  si: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'si-LK',
      location: 'asia-southeast1',
    },
  ],
  sl: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'sl-SI',
      location: 'europe-west4',
    },
  ],
  sn: [{ provider: 'deepgram', model: 'whisper' }],
  so: [{ provider: 'deepgram', model: 'whisper' }],
  sq: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'sq-AL',
      location: 'europe-west4',
    },
  ],
  sr: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'sr-RS',
      location: 'europe-west4',
    },
  ],
  su: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'su-ID',
      location: 'asia-southeast1',
    },
  ],
  sw: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'sw-TZ',
      location: 'europe-west4',
    },
  ],
  te: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'te-IN',
      location: 'asia-southeast1',
    },
  ],
  tg: [{ provider: 'deepgram', model: 'whisper' }],
  tk: [{ provider: 'deepgram', model: 'whisper' }],
  tl: [{ provider: 'deepgram', model: 'whisper' }],
  tt: [{ provider: 'deepgram', model: 'whisper' }],
  ur: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'ur-PK',
      location: 'europe-west4',
    },
  ],
  uz: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'uz-UZ',
      location: 'europe-west4',
    },
  ],
  yo: [
    { provider: 'deepgram', model: 'whisper' },
    {
      model: 'chirp',
      provider: 'google',
      value: 'yo-NG',
      location: 'europe-west4',
    },
  ],
  yi: [{ provider: 'deepgram', model: 'whisper' }],

  // GOOGLE
  'ar-DZ': [{ provider: 'google', model: 'long' }],
  'ar-BH': [{ provider: 'google', model: 'long' }],
  'ar-EG': [{ provider: 'google', model: 'long' }],
  'ar-IQ': [{ provider: 'google', model: 'long' }],
  'ar-IL': [{ provider: 'google', model: 'long' }],
  'ar-JO': [{ provider: 'google', model: 'long' }],
  'ar-KW': [{ provider: 'google', model: 'long' }],
  'ar-LB': [{ provider: 'google', model: 'long' }],
  'ar-MR': [{ provider: 'google', model: 'long' }],
  'ar-MA': [{ provider: 'google', model: 'long' }],
  'ar-OM': [{ provider: 'google', model: 'long' }],
  'ar-QA': [{ provider: 'google', model: 'long' }],
  'ar-SA': [{ provider: 'google', model: 'long' }],
  'ar-PS': [{ provider: 'google', model: 'long' }],
  'ar-TN': [{ provider: 'google', model: 'long' }],
  'ar-AE': [{ provider: 'google', model: 'long' }],
  'ar-YE': [{ provider: 'google', model: 'long' }],
};

import logLevel from '@autocut/types/logLevel.enum';
import { CURRENT_ENV, Env } from '@autocut/utils/currentEnv.utils';
import { logger } from '@autocut/utils/logger';
import { ns } from '../../../shared/shared';
import CSInterface from '../cep/csinterface';
import { fs } from '../cep/node';
import Vulcan, { VulcanMessage } from '../cep/vulcan';

export const csi = new CSInterface();
export const vulcan = new Vulcan();

// jsx utils

/**
 * @function EvalES
 * Evaluates a string in ExtendScript scoped to the project's namespace
 * Optionally, pass true to the isGlobal param to avoid scoping
 *
 * @param script    The script as a string to be evaluated
 * @param isGlobal  Optional. Defaults to false,
 *
 * @return String Result.
 */

export const evalES = (script: string, isGlobal = false): Promise<string> => {
  return new Promise(function (resolve, reject) {
    const pre = isGlobal
      ? ''
      : `var host = typeof $ !== 'undefined' ? $ : window; host["${ns}"].`;
    const fullString = pre + script;
    csi.evalScript(
      'try{' + fullString + '}catch(e){alert(e);}',
      (res: string) => {
        resolve(res);
      }
    );
  });
};

import type { Scripts } from '@esTypes/index';

import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import axios from 'axios';

export type ArgTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never;
export type ReturnType<F extends Function> = F extends (
  ...args: infer A
) => infer B
  ? B
  : never;

/**
 * @description End-to-end type-safe ExtendScript evaluation with error handling
 * Call ExtendScript functions from CEP with type-safe parameters and return types.
 * Any ExtendScript errors are captured and logged to the CEP console for tracing
 *
 * @param functionName The name of the function to be evaluated.
 * @param args the list of arguments taken by the function.
 *
 * @return Promise resolving to function native return type.
 *
 * @example
 * // CEP
 * evalTS("myFunc", 60, 'test').then((res) => {
 *    console.log(res.word);
 * });
 *
 * // ExtendScript
 * export const myFunc = (num: number, word: string) => {
 *    return { num, word };
 * }
 *
 */

export const evalTS = async <
  Key extends string & keyof Scripts,
  Func extends Function & Scripts[Key]
>(
  functionName: Key,
  ...args: ArgTypes<Func> | []
): Promise<ReturnType<Func>> => {
  let isError: boolean = false;
  const startDate = new Date();
  try {
    if (
      !autocutStoreVanilla().ppro.isScriptLoaded &&
      functionName !== 'initSecret' &&
      functionName !== 'enableQE'
    ) {
      console.warn(functionName, 'called before script initialization');
    }

    if (
      functionName !== 'handshake' &&
      functionName !== 'initSecret' &&
      functionName !== 'enableQE'
    ) {
      const secret = autocutStoreVanilla().ppro.handshakeSecret;
      const res = await evalTS('handshake', secret);
      if (res !== 'true' && res !== 'undefined') {
        manageError({
          error: new IncrementalError(
            new Error(
              `HANDSHAKE_ERROR : needed secret "${res}" != "${secret}"`
            ),
            'handshakeFailed'
          ),
        });
      } else if (res === 'undefined') {
        try {
          await evalTS(
            'initSecret',
            autocutStoreVanilla().ppro.handshakeSecret
          );
        } catch (e: any) {
          if (!e?.message?.includes('Secret already initialized')) {
            throw e; //If the error is "Secret already initialized", we don't want to throw it. (We should not get in here anyway)
          }
        }
      }
    }

    if (functionName !== 'sendHeartbeat' && functionName !== 'handshake') {
      logger('evalTS', logLevel.debug, 'Evaluating ExtendScript function...', {
        functionName,
        args,
        argsStr: JSON.stringify(args),
      });
    }

    const result = await new Promise(function (resolve, reject) {
      const formattedArgs = args
        .map(arg => {
          return `${JSON.stringify(arg)}`;
        })
        .join(',');
      csi.evalScript(
        `try{
            var host = typeof $ !== 'undefined' ? $ : window;
            if(!host["${ns}"]) throw new Error("Namespace not found");
            var res = host["${ns}"].${functionName}(${formattedArgs});
            JSON.stringify(res);
          }catch(e){
            try{
              e.fileName = new File(e.fileName).fsName;
            }catch(e){
              // do nothing
            }
            e.error = true;
            JSON.stringify(e);
          }`,
        (res: string) => {
          try {
            if (res === 'undefined') {
              // @ts-ignore
              return resolve();
            }
            if (res.includes('EvalScript error')) {
              if (!autocutStoreVanilla().ppro.isScriptLoaded) {
                reject(
                  `Try to launch function ${functionName}(${formattedArgs}) before script is loaded.`
                );
              } else {
                reject(
                  `EvalScript error in function ${functionName}(${formattedArgs}) : ${res}`
                );
              }
            }
            const parsed = JSON.parse(res || '{}');

            if (
              parsed !== null &&
              typeof parsed === 'object' &&
              'error' in parsed
            ) {
              reject(
                `Handled error in function ${functionName}(${formattedArgs}) => ${
                  parsed.message || ''
                }`
              );
            }
            if (parsed?.name === 'ReferenceError') {
              reject(
                `ReferenceError in function ${functionName}(${formattedArgs}) : ${res}`
              );
            } else if (parsed?.name === 'Error') {
              reject(
                `Handled error in function ${functionName}(${formattedArgs}) => ${
                  parsed.message || ''
                }`
              );
            } else {
              const debugParsed = { ...parsed, source: '' };
              delete debugParsed.source;

              if (
                functionName !== 'sendHeartbeat' &&
                functionName !== 'handshake'
              ) {
                logger(
                  'evalTS',
                  logLevel.debug,
                  'ExtendScript function ended',
                  {
                    functionName,
                    parsed: debugParsed,
                    parsedStr: JSON.stringify(debugParsed),
                  }
                );
              }

              resolve(parsed);
            }
          } catch (error) {
            const errorMessage =
              'message' in (error as { message?: string })
                ? (error as { message?: string }).message
                : `"error"`;
            logger(
              'evalTS',
              logLevel.error,
              'ExtendScript function ended with error',
              {
                functionName,
                error,
                errorMessage,
                errorStr: JSON.stringify(error),
              }
            );
            reject(
              `Error in function ${functionName}(${formattedArgs}) => ${errorMessage}\nOutput : ${res}`
            );
          }
        }
      );
    });
    return result as Promise<ReturnType<Func>>;
  } catch (e: any) {
    isError = true;

    if (e?.message?.includes('Namespace')) {
      manageError({
        error: new IncrementalError(
          new Error(`NAMESPACE_NOT_FOUND`),
          'namespaceNotFound'
        ),
      });
    }

    throw new IncrementalError(e, `evalTS(${functionName})`);
  } finally {
    const endDate = new Date();
    setAutocutStore('dev.cepCallHistory', state => [
      ...state.dev.cepCallHistory.slice(-49),
      {
        functionName,
        args,
        endsWithError: isError,
        start: {
          timestamp: startDate.getTime(),
          pretty: startDate.toTimeString(),
        },
        end: { timestamp: endDate.getTime(), pretty: endDate.toTimeString() },
      },
    ]);
  }
};

export const evalFile = async (file: string) => {
  return await evalES(
    "typeof $ !== 'undefined' ? $.evalFile(\"" +
      file +
      '") : fl.runScript(FLfile.platformPathToURI("' +
      file +
      '"));',
    true
  );
};

// js utils

export const getScriptLocally = async () => {
  if (window.cep) {
    const extRoot = csi.getSystemPath('extension');
    const jsxSrc = `${extRoot}/jsx/index.js`;

    if (fs.existsSync(jsxSrc)) {
      const script = fs.readFileSync(jsxSrc, { encoding: 'utf-8' });
      return script.toString();
    }
  }

  throw new Error('jsx file not found');
};

export const getScriptFromServer = async () => {
  try {
    const result = await axios.get(
      `${window.location.origin}/jsx/index.js?timestamp=${new Date().getTime()}`
    );

    const eval2 = eval; //RollUp hack to make eval work

    const decoded = eval2(`${result.data}; getCode()`);

    return decoded;
  } catch (e) {
    console.error(
      'Error while getting script from server',
      (e as any).message || e
    );
    throw e;
  }
};

export const initBolt = async () => {
  // We get host environment from the CSInterface object once, the value is then stored in csi.hostEnvironment
  csi.getHostEnvironment();

  let script = '';

  try {
    script =
      CURRENT_ENV === Env.Development
        ? await getScriptLocally()
        : await getScriptFromServer();

    const res = await evalExtendscript(script);
    if (typeof res === 'string' && res.includes('EvalScript error'))
      throw new Error(res);
  } catch (error: any) {
    manageError({
      error: new IncrementalError('SCRIPT_NOT_LOADED', 'initBolt'),
    });
    setAutocutStore('ppro.isScriptLoaded', false);

    throw error;
  }
};

export const posix = (str: string) => str.replace(/\\/g, '/');

export const openLinkInBrowser = (url: string, addTimestamp = false) => {
  if (window.cep) {
    const includesParams = url.includes('?');
    const addedTimestamp = includesParams
      ? `&timestamp=${new Date().getTime()}`
      : `?timestamp=${new Date().getTime()}`;

    const includesHash = url.includes('#');

    if (includesHash) {
      url = url.replace('#', addedTimestamp + '#');
    } else {
      url += addedTimestamp;
    }
    csi.openURLInDefaultBrowser(url);
  } else {
    location.href = url;
  }
};

export const getAppBackgroundColor = () => {
  const { green, blue, red } = JSON.parse(
    window.__adobe_cep__.getHostEnvironment() as string
  ).appSkinInfo.panelBackgroundColor.color;
  return {
    rgb: {
      r: red,
      g: green,
      b: blue,
    },
    hex: `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`,
  };
};

export const subscribeBackgroundColor = (callback: (color: string) => void) => {
  const getColor = () => {
    const newColor = getAppBackgroundColor();
    const { r, g, b } = newColor.rgb;

    return `rgb(${r}, ${g}, ${b})`;
  };
  // get current color
  callback(getColor());
  // listen for changes
  csi.addEventListener(
    'com.adobe.csxs.events.ThemeColorChanged',
    () => callback(getColor()),
    {}
  );
};

// vulcan

declare type IVulcanMessageObject = {
  event: string;
  callbackID?: string;
  data?: string | null;
  payload?: object;
};

export const vulcanSend = (id: string, msgObj: IVulcanMessageObject) => {
  const msg = new VulcanMessage(VulcanMessage.TYPE_PREFIX + id, null, null);
  const msgStr = JSON.stringify(msgObj);
  msg.setPayload(msgStr);
  vulcan.dispatchMessage(msg);
};

export const vulcanListen = (id: string, callback: Function) => {
  vulcan.addMessageListener(
    VulcanMessage.TYPE_PREFIX + id,
    (res: any) => {
      const msgStr = vulcan.getPayload(res);
      const msgObj = JSON.parse(msgStr);
      callback(msgObj);
    },
    null
  );
};

export const isAppRunning = (targetSpecifier: string) => {
  const { major, minor, micro } = csi.getCurrentApiVersion();
  const version = parseFloat(`${major}.${minor}`);
  if (version >= 11.2) {
    return vulcan.isAppRunningEx(targetSpecifier.toUpperCase());
  } else {
    return vulcan.isAppRunning(targetSpecifier);
  }
};

interface IOpenDialogResult {
  data: string[];
}
export const selectFolder = (
  dir: string,
  msg: string,
  callback: (res: string) => void
) => {
  const result = window.cep.fs.showOpenDialog(
    false,
    true,
    msg,
    dir
  ) as IOpenDialogResult;
  if (result.data?.length > 0) {
    const folder = decodeURIComponent(result.data[0].replace('file://', ''));
    callback(folder);
  }
};

export const selectFile = (
  dir: string,
  msg: string,
  callback: (res: string) => void
) => {
  const result = window.cep.fs.showOpenDialog(
    false,
    false,
    msg,
    dir
  ) as IOpenDialogResult;
  if (result.data?.length > 0) {
    const folder = decodeURIComponent(result.data[0].replace('file://', ''));
    callback(folder);
  }
};

export const inCEPEnvironment = (): boolean => {
  return csi.inCEPEnvironment();
};

export const evalExtendscript = (script: string) => {
  if (!csi.inCEPEnvironment()) console.warn('Not in CEP environment.');
  return new Promise<any>(function (resolve, reject) {
    const doEvalScript = function () {
      csi.evalScript(script, function (executionResult: any) {
        if (!executionResult || executionResult === 'undefined')
          return resolve(0);
        try {
          executionResult = JSON.parse(executionResult);
        } catch (err) {}
        if (executionResult.error != undefined) {
          reject(
            new Error(
              'ExtendScript ' +
                executionResult.error +
                ': ' +
                executionResult.message +
                '\n' +
                executionResult.stack
            )
          );
        }
        resolve(executionResult);
      });
    };
    setTimeout(doEvalScript, 0);
  });
};

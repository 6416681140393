import { StepLayout } from '@autocut/layouts/StepLayout';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { PresetCard } from './PresetCard/PresetCard';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { ResizePreset } from '@autocut/types/Resize';
import { RiTiktokLine } from 'react-icons/ri';
import { AiOutlineYoutube } from 'react-icons/ai';
import { FaInstagram } from 'react-icons/fa6';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import { FiTwitter } from 'react-icons/fi';
import { AddPresetCard } from './PresetCard/AddPresetCard';
import { ModalType } from '@autocut/enums/modals.enum';
import { TiPencil } from 'react-icons/ti';

const defaultPresets: ResizePreset[] = [
  {
    name: 'TikTok, Shorts & Reels',
    width: 1080,
    height: 1920,
    icons: [
      <RiTiktokLine key={'tiktok'} size={18} color="white" />,
      <AiOutlineYoutube key={'youtube'} size={18} color="white" />,
      <FaInstagram key={'reels'} size={18} color="white" />,
    ],
  },
  {
    name: 'Youtube FHD',
    width: 1920,
    height: 1080,
    icons: [<AiOutlineYoutube key={'youtube'} size={18} color="white" />],
  },
  {
    name: 'Twitter Square',
    width: 1920,
    height: 1920,
    icons: [<FiTwitter key={'twitter'} size={18} color="white" />],
  },
];

export const ResizePresetStep = () => {
  const intl = useIntl();

  const { preset, customPresets } = useAutoCutStore(state => ({
    preset: state.ui.parameters.resize.preset,
    customPresets: state.ui.resize.customPresets,
  }));

  const handleDeleteCustomPreset = (presetName: string) => {
    setAutocutStore(
      'ui.resize.customPresets',
      customPresets.filter(preset => preset.name !== presetName)
    );

    if (preset && presetName === preset.name) {
      setAutocutStore(
        'ui.parameters.resize.preset',
        undefined as unknown as ResizePreset
      );
    }
  };

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'resize_preset_step_title',
        defaultMessage: 'Presets',
      })}
      alwaysOpen={true}
      helperId="resize_preset_step_helper"
      helperDefault="Select a preset to resize your sequence. You can also create your own preset. The dimensions of your new sequence are displayed below."
    >
      <FlexContainer gap={12} flexWrap="wrap">
        {defaultPresets.map(currentPreset => (
          <PresetCard
            preset={currentPreset}
            key={currentPreset.name}
            checked={
              !!(preset && preset.name && currentPreset.name === preset.name)
            }
            onClick={() => {
              setAutocutStore('ui.parameters.resize.preset', currentPreset);
            }}
          />
        ))}
        {customPresets.map(currentPresetTemp => {
          const currentPreset = {
            ...currentPresetTemp,
            icons: [
              <TiPencil
                key={`${currentPresetTemp.name}-icon`}
                size={18}
                color="white"
              />,
            ],
          };

          return (
            <PresetCard
              preset={currentPreset}
              key={currentPreset.name}
              checked={
                !!(preset && preset.name && currentPreset.name === preset.name)
              }
              onClick={() => {
                setAutocutStore('ui.parameters.resize.preset', currentPreset);
              }}
              onDelete={() => {
                handleDeleteCustomPreset(currentPreset.name);
              }}
            />
          );
        })}
        <AddPresetCard
          onClick={() =>
            setAutocutStore('ui.openedModalName', ModalType.CreateResizeFormat)
          }
        />
      </FlexContainer>
    </StepLayout>
  );
};

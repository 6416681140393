import { autocutApi } from '../http.utils';
import { Sentence, Utterance } from '@autocut/types/Deepgram';
import { IncrementalError } from '../errors/IncrementalError';
import {
  DeepgramTranscriptResponse,
  GoogleTranscriptResponse,
} from './launchTranscript';

type TranscriptType<T> = T extends 'utterances' ? Utterance[] : Sentence[];

export const awaitTranscript = async <T extends 'utterances' | 'sentences'>({
  resultType,
  launchedTranscript,
  skipEmbedding,
}: {
  resultType: 'utterances' | 'delimited-repetitions' | 'sentences';
  launchedTranscript: DeepgramTranscriptResponse | GoogleTranscriptResponse;
  skipEmbedding: boolean;
}): Promise<TranscriptType<T>> => {
  let tryCount = 0;
  let transcript: TranscriptType<T> = [] as any;

  // Limit of 10 minutes
  const maxTryCount = 60;
  const interval = 10000;

  while (tryCount <= maxTryCount) {
    if (tryCount === maxTryCount)
      throw new IncrementalError(
        'TRANSCRIPTION_FAILED',
        'fetchTranscriptFromExportedAudio'
      );

    const tempTranscript = (
      await autocutApi
        .post<TranscriptType<T> | false>(`/transcription/${resultType}`, {
          options: {
            skipEmbedding,
          },
          ...launchedTranscript,
        })
        .catch(e => {
          throw e;
        })
    ).data;

    if (tempTranscript === false) {
      tryCount++;

      await new Promise(resolve => setTimeout(resolve, interval));
    } else {
      transcript = tempTranscript;
      break;
    }
  }

  return transcript;
};

import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Sorter } from '@autocut/components/atoms/Sorter/Sorter';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { playChunk } from '@autocut/utils/captions/utils';
import { handleViralClipsProcess } from '@autocut/utils/viralClips/handleViralClipsProcess';
import { setAutocutStore } from '@autocut/utils/zustand';
import css from './ViralClipsSelector.module.scss';
import { ViralClipsTranscriptItem } from './components/ViralClipsTranscriptItem/ViralClipsTranscriptItem';

export const ViralClipsSelector = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [currentTimeout, setCurrentTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  const { viralClips } = useAutoCutStore(state => ({
    viralClips: state.onGoingProcess.viralClips,
  }));

  useEffect(() => {
    if (!viralClips?.length) {
      navigate('../language');

      setAutocutStore('ui.currentErrorId', 'NO_SOUND_DETECTED');
      setAutocutStore('ui.openedModalName', ModalType.Error);
    }
  }, [viralClips]);

  if (!viralClips) return null;

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={(isLoading, isDisabled) => (
            <Button
              disabled={isDisabled}
              onClickFunction={async () => {
                await handleViralClipsProcess(intl);
              }}
            >
              <TranslatedMessage
                id="viral_clips_button_process"
                defaultMessage="Create clips"
              />
            </Button>
          )}
        />
      }
    >
      <FlexContainer flexDirection="column" gap={8}>
        <FlexContainer
          flex="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <p className={css.title} style={{ color: 'white' }}>
            <TranslatedMessage
              id="viral_clips_selector_title"
              defaultMessage="Detected {number} potential viral clips"
              values={{
                number: viralClips.length,
              }}
            />
          </p>
          <Sorter
            list={viralClips}
            criterias={[
              {
                label: intl.formatMessage({
                  id: 'viral_score',
                  defaultMessage: 'Viral score',
                }),
                sortFunction: (a: ViralClip, b: ViralClip) => b.score - a.score,
              },
              {
                label: intl.formatMessage({
                  id: 'start',
                  defaultMessage: 'Start',
                }),
                sortFunction: (a: ViralClip, b: ViralClip) => a.start - b.start,
              },
            ]}
            onUpdate={(sortedViralClips: ViralClip[]) =>
              setAutocutStore('onGoingProcess.viralClips', sortedViralClips)
            }
          />
        </FlexContainer>
        <FlexContainer flexDirection="column" gap={4}>
          {viralClips.map((viralClip, index) => (
            <ViralClipsTranscriptItem
              key={'viral_clip ' + viralClip.title}
              viralClip={viralClip}
              onViralClipSelect={() => {
                const clips = [...viralClips];
                clips[index].selected = !clips[index].selected;
                setAutocutStore('onGoingProcess.viralClips', clips);
              }}
              onViralClipPlay={async () =>
                setCurrentTimeout(
                  await playChunk(
                    viralClip.start,
                    viralClip.end,
                    currentTimeout
                  )
                )
              }
            />
          ))}
        </FlexContainer>
      </FlexContainer>
    </ModeLayout>
  );
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { Scripts } from '@esTypes/*';

export type CEPAction<
  Type extends
    | 'cutClips'
    | 'deleteClips'
    | 'moveAudioClipsToTrack'
    | 'changeStartAndEndOfAudioTrack'
    | 'setSequenceVideoDisplayFormat'
    | 'handlePodcastCameraEdits'
    | 'applyConstantPowerToAllAudioOnTrackBetween'
    | 'insertTrackAt'
> = {
  action: Type;
  param: Parameters<Scripts[Type]>;
  thenFunctions: ((res: ReturnType<Scripts[Type]>) => void)[];
};

const setWorkerStep = (name: string) => {
  const autocutState = autocutStoreVanilla();
  autocutState.onGoingProcess.workerTimeoutReport?.postMessage({
    type: 'onStep',
    payload: name,
  });
};

export const runCEPActions = async (actions: CEPAction<any>[]) => {
  try {
    const actionsPromises = [];
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];
      if (i === 0) setWorkerStep(action.action);
      actionsPromises.push(
        evalTS(action.action, ...action.param).then(res => {
          const nextAction = actions[i + 1];
          if (nextAction) setWorkerStep(nextAction.action);
          try {
            for (const thenFunction of action.thenFunctions) {
              thenFunction(res);
            }
          } catch (err: any) {
            throw new IncrementalError(err, `${action.action}(then)}`);
          }
        })
      );
    }

    await Promise.all(actionsPromises);
  } catch (err: any) {
    throw new IncrementalError(err, `runCEPActions`);
  }
};

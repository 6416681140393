import * as React from 'react';
import { useIntl } from 'react-intl';
import { AUTOZOOM_DYNAMISM_LEVEL } from '../../steps/DynamismStep/DynamismStep';
import { NumberInput } from '../NumberInput';
import { RadioButton } from '../RadioButton/RadioButton';
import css from './RadioButtonWithInput.module.css';

export type RadioButtonWithInputProps = {
  name: string;
  disabled: boolean;
  onChange: (value: number) => void;
  setIndexFocus?: () => void;
};

// Radio buttons with input have to be used with RadioButtonForm
export const RadioButtonWithInput = ({
  name,
  disabled = true,
  onChange,
  setIndexFocus,
}: RadioButtonWithInputProps) => {
  const intl = useIntl();

  const handleOnClick = () => {
    if (setIndexFocus) setIndexFocus();
  };

  return (
    <div className={css.radioButtonWithInputContainer}>
      <RadioButton
        name={name}
        label={intl.formatMessage({
          id: 'autozoom_dynamism_custom',
          defaultMessage: 'Custom',
        })}
        value={AUTOZOOM_DYNAMISM_LEVEL.CUSTOM}
        onClick={handleOnClick}
      />
      <NumberInput
        disabled={disabled}
        unitId="percentageUnit"
        targetedParameter="dynamism"
        value={`15`}
        onChange={(newValue: string) => {
          onChange(parseFloat(newValue));
        }}
      />
    </div>
  );
};

import * as React from 'react';

import { useIntl } from 'react-intl';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { setAutocutStore } from '@autocut/utils/zustand';
import { NumberInput } from '../../atoms/NumberInput';
import css from './camTimeStep.module.css';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import {
  Preset,
  PresetCard,
} from '@autocut/components/atoms/PresetCard/PresetCard';

const podcastPresets: Preset<'podcast'>[] = [
  {
    id: 'calm',
    parameters: {
      minimumCamTime: 5,
      maximumCamTime: 60,
    },
  },
  {
    id: 'paced',
    parameters: {
      minimumCamTime: 4,
      maximumCamTime: 30,
    },
  },
  {
    id: 'energetic',
    parameters: {
      minimumCamTime: 3,
      maximumCamTime: 20,
    },
  },
  {
    id: 'hyperactive',
    parameters: {
      minimumCamTime: 2,
      maximumCamTime: 10,
    },
  },
];

export const CameraTimeStep = () => {
  const intl = useIntl();
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_step1_podcast_title',
        defaultMessage: 'Camera time durations',
      })}
      alwaysOpen
    >
      <ParametersGroupLayout
        title={intl.formatMessage({
          id: 'podcast_preset_title',
          defaultMessage: 'Default presets',
        })}
      >
        <div className={css.presetsContainer}>
          {podcastPresets.map(preset => (
            <PresetCard mode="podcast" preset={preset} key={preset.id} />
          ))}
        </div>
      </ParametersGroupLayout>
      <div className={css.container}>
        <NumberInput
          titleId="text_step1_podcast_minimum"
          value={String(podcastParameters.minimumCamTime)}
          targetedParameter="minimumCamTime"
          onChange={(value: string) =>
            setAutocutStore(
              'ui.parameters.podcast.minimumCamTime',
              parseFloat(value)
            )
          }
          helperDefault="Minimum duration (in seconds) a camera will be displayed without changing."
          helperId="helper_Step1_podcast_minium"
        />
        <NumberInput
          titleId="text_step1_podcast_maximum"
          value={String(podcastParameters.maximumCamTime)}
          targetedParameter="maximumCamTime"
          onChange={(value: string) =>
            setAutocutStore(
              'ui.parameters.podcast.maximumCamTime',
              parseFloat(value)
            )
          }
          helperDefault="Maximum duration (in seconds) a camera will be displayed before being forced to change."
          helperId="helper_Step1_podcast_maximum"
          left={true}
        />
      </div>
    </StepLayout>
  );
};

import { ExportedAudioElementWithTranscript } from '@autocut/types/Captions';
import { ExportedAudioElement } from '@autocut/types/ExportedAudio';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { uploadAndGetSignedUrl } from '@autocut/utils/files.utils';
import { autocutStoreVanilla } from '../zustand';
import { IntlShape } from 'react-intl';
import { changeMessage } from '../cutButton/changeMessage';

export const uploadAudioFileFromExportedAudio = async <
  Variant extends 'utterances' | 'sentences' = 'utterances'
>(
  exportedAudioInfos: ExportedAudioElement[],
  progressCallback?: (p: number) => void,
  intl?: IntlShape
) => {
  // Upload WAVs to bucket
  const userKey = autocutStoreVanilla().user.key;
  let progress = 0;
  const exportedAudioInfosWithUrl: ExportedAudioElementWithTranscript<Variant>[] =
    [...exportedAudioInfos];

  if (intl) changeMessage(intl, 'button_UploadingAudio', 'Uploading audio...');

  try {
    for (const exportedAudioInfo of exportedAudioInfosWithUrl) {
      const signedUrl = await uploadAndGetSignedUrl(
        `${userKey?.trim()}_${exportedAudioInfo.path
          .split(/[\/\\]/g)
          .pop()
          ?.trim()}`,
        exportedAudioInfo.path,
        p => {
          return progressCallback?.(
            (p + progress * 100) / exportedAudioInfosWithUrl.length
          );
        },
        { length: exportedAudioInfo.end - exportedAudioInfo.start }
      );
      progress++;
      exportedAudioInfo.signedUrl = signedUrl;

      progressCallback?.((progress * 100) / exportedAudioInfosWithUrl.length);
    }

    return exportedAudioInfosWithUrl;
  } catch (error: any) {
    throw new IncrementalError(error, 'uploadWAVFileFromCaptionIntervals');
  }
};

import { AutocutModes } from '@autocut/enums/modes.enum';
import { IntlShape } from 'react-intl';
import { getAiSilences, getLegacySilences } from '../cut/getSilences';
import { changeMessage } from '../cutButton/changeMessage';
import { IncrementalError } from '../errors/IncrementalError';
import { autocutStoreVanilla } from '../zustand';

export const handleGetSilencesPreviewProcess = async (intl: IntlShape) => {
  try {
    const mode = autocutStoreVanilla().ui.process.mode.id as unknown as string;
    let silences;
    switch (mode) {
      case AutocutModes.Ai.id:
        silences = await getAiSilences(intl, true);
        break;
      case AutocutModes.Legacy.id:
        silences = await getLegacySilences(intl, true);
        break;

      default:
        throw new Error('unknown mode : ' + mode);
    }

    changeMessage(intl, '', '');

    return silences.timelineBasedSilencesTotal;
  } catch (err: any) {
    const error = new IncrementalError(err, 'handleGetSilencesPreviewProcess');
    throw error;
  }
};

export const getMinMaxClipsEnd = () => {
  const selectionInfos = autocutStoreVanilla().sequence.infos;
  const clips = selectionInfos?.audioTracks.flatMap(
    track => track.selectedClips
  );

  if (!clips || !clips.length) return [0, 10];

  return clips.reduce(
    (result, clip) => [
      Math.min(result[0], clip.start),
      Math.max(result[1], clip.end),
    ],
    [Infinity, 0]
  );
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { getParametersForMode } from '../parameters.utils';
import { handleProcessBase } from '../process/handleProcessBase';
import { autocutStoreVanilla } from '../zustand';
import { changeMessage } from '../cutButton/changeMessage';
import { IntlShape } from 'react-intl';

const handleChapters = async (intl: IntlShape) => {
  const params = getParametersForMode('chapters');
  const {
    onGoingProcess: { chapters },
  } = autocutStoreVanilla();
  if (!chapters) return;

  changeMessage(intl, 'chapters_adding_chapters', 'Adding Chapters...');

  await Promise.all(
    chapters.map(async chapter => {
      await evalTS('createMarker', {
        title: chapter.title,
        start: Math.round(chapter.start),
        end: Math.round(chapter.end),
        colorIndex: params.colorIndex,
      });
    })
  );
};

export const handleChaptersProcess = handleProcessBase({
  executeProcess: handleChapters,
});

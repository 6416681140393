import { evalTS } from '@autocut/lib/utils/bolt';
import { ProcessTranscriptFunction } from '@autocut/types/transcription';
import { processCaptionsChunksState } from '@autocut/utils/captions/processCaptionsChunksState.utils';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { fetchTranscriptFromExportedAudio } from '@autocut/utils/transcript/fetchTranscriptFromExportedAudio.utils';
import { uploadAudioFileFromExportedAudio } from '@autocut/utils/transcript/uploadWAVFileFromExportedAudio.utils';
import { setAutocutStore } from '@autocut/utils/zustand';

export const handleCaptionsProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    const exportedAudioSequenceInfos = await getExportedAudioInfos(
      {
        sequence: 'selected',
        options: {
          format: 'mp3',
        },
      },
      intl
    );
    const exportedAudioInfos = [...exportedAudioSequenceInfos.sequence];

    setAutocutStore('onGoingProcess.progress', 0);
    const exportedAudioInfosWithUrl = await uploadAudioFileFromExportedAudio(
      exportedAudioInfos,
      p => {
        setAutocutStore('onGoingProcess.progress', p);
      },
      intl
    );
    setAutocutStore('onGoingProcess.progress', undefined);
    changeMessage(intl, 'broll_analyse', 'Analysing audio...');

    const exportedAudioInfosWithTranscript =
      await fetchTranscriptFromExportedAudio(
        exportedAudioInfosWithUrl,
        'utterances',
        usedModel
      );

    setAutocutStore(
      'ui.currentTranscription.exportedAudioWithTranscript',
      exportedAudioInfosWithTranscript
    );

    setAutocutStore('ui.cutButtonMessage', undefined);

    const startTime = exportedAudioSequenceInfos.sequence[0].start;

    if (startTime) {
      const cursorTime = (await evalTS('getCursorTime')) ?? 0;

      if (cursorTime < startTime) {
        await evalTS('setPlayerPosition', startTime);
      } else {
        const endTime =
          exportedAudioSequenceInfos.sequence[
            exportedAudioSequenceInfos.sequence.length - 1
          ].end;

        if (endTime && cursorTime > endTime) {
          await evalTS('setPlayerPosition', endTime);
        }
      }
    }

    processCaptionsChunksState();
  };

import { CaptionChunk, CaptionChunkHighlight } from '@autocut/types/Captions';
import { CanvasFontParams, splitTextIntoLines } from './canvas/canvas.utils';
import { autocutStoreVanilla } from '../zustand';
import {
  PPRO_TEXT_LINE_BREAK,
  getHighlightFromWord,
} from './processCaptionsChunksState.utils';
import { getValue } from './utils';

export const mergeChunks = (chunks: CaptionChunk[], index: number) => {
  const sequenceWidth =
    autocutStoreVanilla().sequence.infos?.settings.width ||
    autocutStoreVanilla().sequence.lastSettings.width;
  const captionsParams = autocutStoreVanilla().ui.parameters.caption;

  const fontParameters: CanvasFontParams = {
    italic: captionsParams.formating.italic,
    fontSize: captionsParams.text.fontSize,
    fontFamily: captionsParams.text.font.fontFamily,
  };
  const allowedWidth =
    (captionsParams.formating.maxWidth / 100) * sequenceWidth;

  const newChunks = [...chunks];
  const chunk = newChunks[index];
  const nextChunk = newChunks[index + 1];
  if (chunk && nextChunk) {
    const allWords = [...chunk.lines.flat(), ...nextChunk.lines.flat()];

    const chunkLines = splitTextIntoLines(
      chunk.text.split(/\s/g).join(' ') +
        ' ' +
        nextChunk.text.split(/\s/g).join(' '),
      {
        font: fontParameters,
        maxWidth: allowedWidth,
        uppercase: captionsParams.formating.uppercase,
      }
    );
    const updatedLinesPerWords = chunkLines
      .map(line => allWords.slice(line.startIndex, line.endIndex + 1))
      .filter(words => words.length > 0);
    const chunkText = updatedLinesPerWords
      .map(words => words.map(word => getValue(word)).join(' '))
      .join(PPRO_TEXT_LINE_BREAK);

    let lastEnd = chunk?.highlight[0]?.start || 0;
    let lastIndexEnd = -2;

    const newChunk: CaptionChunk = {
      ...chunk,
      emoji: chunk.emoji ?? nextChunk.emoji,
      emojiUrl: chunk.emojiUrl ?? nextChunk.emojiUrl,
      emojiSize: chunk.emojiSize ?? nextChunk.emojiSize,
      start: chunk.start,
      text: chunkText,
      end: nextChunk.end,
      highlight: updatedLinesPerWords.flatMap((line, lineIndex) => {
        let currentLine = '';
        return line
          .map(word => {
            if (!word) return null;
            const res = getHighlightFromWord(
              word,
              lastEnd,
              lastIndexEnd,
              currentLine,
              lineIndex
            );
            currentLine = currentLine + res.word + ' ';
            lastEnd = res.end;
            lastIndexEnd = res.indexEnd;
            return res;
          })
          .filter(Boolean) as CaptionChunkHighlight[];
      }),
      lines: updatedLinesPerWords,
      nbLines: updatedLinesPerWords.length,
    };

    newChunks[index] = newChunk;
    newChunks.splice(index + 1, 1);
  }
  return newChunks;
};

import {
  UnsupportedScenario,
  UnsupportedScenarioIds,
  UnsupportedScenarios,
} from '@autocut/enums/unsupported.enum';

export const getCorrespondingUnsupportedModalContent = (
  title: UnsupportedScenarioIds
): UnsupportedScenario => {
  return UnsupportedScenarios[
    (Object.keys(UnsupportedScenarios).find(
      scenarioId => scenarioId === title
    ) || 'unknown') as UnsupportedScenarioIds
  ];
};

import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import React from 'react';

type CaptionsTranscriptStepContainerProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
};

export const CaptionsTranscriptStepContainer = ({
  title,
  subtitle,
  children,
  onClick,
}: CaptionsTranscriptStepContainerProps) => {
  return (
    <FlexContainer
      flexDirection="column"
      gap={4}
      alignItems="flex-start"
      style={{
        padding: 16,

        cursor: onClick ? 'pointer' : 'default',

        width: '100%',
      }}
      onClick={onClick}
    >
      <FormSection title={title} description={subtitle} variant="secondary">
        <PaddedSection>{children}</PaddedSection>
      </FormSection>
    </FlexContainer>
  );
};

import React, { CSSProperties } from 'react';
import css from './HomepageTrialButton.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { LuGift } from 'react-icons/lu';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';

type HomepageTrialButtonProps = {
  style?: CSSProperties;
};

export const HomepageTrialButton = ({ style }: HomepageTrialButtonProps) => {
  const { tasks, isTrial } = useAutoCutStore(state => ({
    tasks: state.game.trial.tasks,
    isTrial: state.user.license?.type === 'trial',
  }));

  if (!isTrial) return null;

  const displayIndicator = tasks.some(task => !task.completed);

  return (
    <FlexContainer
      className={css.container}
      style={style}
      alignItems="center"
      justifyContent="center"
      onClick={() =>
        setAutocutStore('ui.openedModalName', ModalType.GamingTrial)
      }
    >
      <LuGift color="white" size={24} strokeWidth={1.5} />

      {displayIndicator && <div className={css.indicator} />}
    </FlexContainer>
  );
};

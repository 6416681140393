import { exec } from './exec.utils';

export const createDirectory = async (directory: string) => {
  console.log('createDirectory', directory);
  const command = `mkdir "${directory}"`;

  await exec({
    command,
  });
};

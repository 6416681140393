import React, { useEffect } from 'react';

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { FiAlertTriangle } from 'react-icons/fi';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { RetryTranscriptionButton } from '@autocut/components/atoms/RetryTranscriptionButton/RetryTranscriptionButton';
import { LANGUAGE_TO_MODEL } from '@autocut/utils/transcript/languageToModel';
import { getParametersForMode } from '@autocut/utils/parameters.utils';
import { NeedingTranscriptionModes } from '@autocut/types/transcription';
import { sendStats } from '@autocut/utils';
import { StatType } from '@autocut/types/StatType.enum';

export const DeepgramErrorModal = () => {
  const currentTranscription = useAutoCutStore(
    state => state.ui.currentTranscription
  );

  const parameters = getParametersForMode<NeedingTranscriptionModes>();
  const hasAlternativeProvider = Boolean(
    currentTranscription.modeId &&
      LANGUAGE_TO_MODEL[parameters?.languageOfTranscription.value][
        currentTranscription.usedModel + 1
      ]
  );
  useEffect(() => {
    void sendStats({
      type: StatType.ERROR,
      value: 1,
      traits: {
        mode: currentTranscription?.modeId,
        type: 'DEEPGRAM_ERROR_MODAL',
      },
    });
  }, []);

  return (
    <Modal
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <TranslatedMessage
            id="deepgram_error_modal_title"
            defaultMessage="A translation error has occurred"
          />
        </p>
      }
      icon={<FiAlertTriangle size="1.5rem" color="red" />}
    >
      <p
        style={{
          color: 'white',
          padding: '16px',
          fontSize: '13px',
          lineHeight: '23px',
          fontWeight: 'bold',
        }}
      >
        <TranslatedMessage
          id="deepgram_error_modal_text"
          defaultMessage="An error occurred while translating the audio. It may be related to a temporary issue with our AI model, the quality of the audio or the language spoken but we have no further information for now. {br}. Please wait a few minutes and try again. If the problem persists, please contact us on discord or by email at contact@autocut.fr."
        />
      </p>

      {hasAlternativeProvider && currentTranscription.modeId && (
        <RetryTranscriptionButton
          modeId={currentTranscription.modeId}
          usedModel={currentTranscription.usedModel + 1}
        />
      )}
    </Modal>
  );
};

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { CaptionDrawHook } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import {
  drawDebugRectangle,
  drawRoundedRectOnCanvas,
} from '@autocut/utils/captions/canvas/canvas.utils';
import { rgbToHex } from '@autocut/utils/color.utils';
import chroma from 'chroma-js';
import { cloneDeep, isEqual } from 'lodash';
import { useMemo, useRef } from 'react';

export const useDrawBackground: CaptionDrawHook = ({ metrics, debug }) => {
  const { params, font, unsupportedFeatures } = useAutoCutStore(state => ({
    params: { box: state.ui.parameters.caption.box },
    font: state.ui.parameters.caption.text.font,
    unsupportedFeatures:
      state.ui.parameters.caption.languageOfTranscription.unsupportedFeatures,
  }));
  //Cache management
  const lastDrawnParams = useRef(params);

  const isNotSupported = unsupportedFeatures?.(font).wordBox.disabled;

  const {
    textMetrics,
    fontParamObject: { fontSize },
    maxLineWidth,
    lineHeight,
    lines,
    accentSize,
  } = metrics;

  const paddings = useMemo(
    () => ({
      x: params.box.enabled ? (params.box.xPadding * fontSize) / 100 : 0,
      y: params.box.enabled ? (params.box.yPadding * fontSize) / 100 : 0,
    }),
    [fontSize, params.box.enabled, params.box.xPadding, params.box.yPadding]
  );

  const backgroundMetrics = {
    width: textMetrics.width + paddings.x * 2,
    height: lineHeight * lines.length + paddings.y * 2,
  };

  const drawBackground = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number
  ) => {
    if (isNotSupported) return;

    const leftX = x - textMetrics.width / 2;
    const topY = y - textMetrics.height / 2;

    if (params.box.enabled) {
      const radius = (params.box.radius / 100) * (fontSize + paddings.y);

      const textBackgroundConfig = {
        color: params.box.color,
        opacity: params.box.opacity,
        position: {
          x: leftX - paddings.x,
          y: topY - paddings.y - accentSize,
        },
        size: {
          width: maxLineWidth + 2 * paddings.x,
          height: lineHeight * lines.length + 2 * paddings.y,
        },
        radius,
      };
      if (debug) {
        drawDebugRectangle(
          ctx,
          textBackgroundConfig.position.x,
          textBackgroundConfig.position.y,
          textBackgroundConfig.size.width,
          textBackgroundConfig.size.height,
          chroma(rgbToHex(params.box.color))
            .set('hsl.h', '+180')
            .luminance(0.4)
            .hex()
        );
      }
      drawRoundedRectOnCanvas(ctx, textBackgroundConfig);
    }
    lastDrawnParams.current = cloneDeep(params);
  };

  const backgroundCustomCacheCheck = () => {
    return isEqual(lastDrawnParams.current, params);
  };

  return {
    draw: drawBackground,
    customCacheCheck: backgroundCustomCacheCheck,
    drawDebug: () => void 0,
    objectMetrics: backgroundMetrics,
  };
};

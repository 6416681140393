import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';

import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export type LostCepModalProps = {};

export const LostCepModal = () => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'error_modal_lost_cep_title',
        defaultMessage: 'An error has occured : Lost connection',
      })}
    >
      <TranslatedMessage
        id="error_modal_lost_cep_text_1"
        defaultMessage="You have lost the connection to Adobe Premiere Pro. Please restart the extension and try again."
      />
      <br />
      <br />
      <TranslatedMessage
        id="error_modal_lost_cep_text_2"
        defaultMessage="If restarting the extension doesn't fix the issue, try restarting Premiere Pro."
      />
    </Modal>
  );
};

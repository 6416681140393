import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { BRollTranscriptStep } from '@autocut/components/steps/BRollTranscriptStep/BRollTranscriptStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { handleBRollProcess } from '@autocut/utils/bRolls/handleBRollProcess';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useNavigate } from 'react-router-dom';

export const BRollTranscript = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { bRolls } = useAutoCutStore(state => ({
    bRolls: state.onGoingProcess.bRolls,
  }));

  useEffect(() => {
    if (!bRolls) {
      navigate('../parameters');
    }
  }, [bRolls]);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={(isLoading, isDisabled) => (
            <Button
              disabled={isDisabled}
              onClickFunction={async () => {
                await handleBRollProcess(intl);
                await handleTrialTask('broll');
              }}
            >
              <TranslatedMessage id="broll_button_next" defaultMessage="Next" />
            </Button>
          )}
        />
      }
    >
      <BRollTranscriptStep />
    </ModeLayout>
  );
};

import React from 'react';
import { LuTrash2 } from 'react-icons/lu';
import { MdSettingsBackupRestore } from 'react-icons/md';
import css from './DeleteChunkButton.module.scss';

export const DeleteChunkButton = ({
  onConfirm,
  variant = 'delete',
}: {
  onConfirm: () => void;
  variant: 'restore' | 'delete';
}) => {
  const isDeleting = variant === 'delete';
  const isRestoring = variant === 'restore';
  const iconProps = {
    size: '1.5rem',
    className: css.deleteChunkIcon,
    style: {
      cursor: 'pointer',
    },
    onClick: () => onConfirm(),
  };
  return (
    <div className={css.deleteChunk}>
      {isDeleting && <LuTrash2 {...iconProps} color="#912018" />}
      {isRestoring && <MdSettingsBackupRestore {...iconProps} />}
    </div>
  );
};

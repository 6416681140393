import { debounce } from '@autocut/utils/debounce';
import { autocutApi } from '@autocut/utils/http.utils';

export const fetchUsage = debounce(
  async () => {
    const res = await autocutApi.get('/deepgram/usage');
    return res.data;
  },
  100,
  true
);

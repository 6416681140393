import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React from 'react';
import css from './ViralityScore.module.scss';
import { Helper } from '@autocut/components/atoms/Helper/Helper';

export const ViralityScore = ({
  score,
  reason,
}: {
  score: number;
  reason: string;
}) => {
  return (
    <FlexContainer flexDirection="column" alignItems="center">
      <p className={css.title}>
        <TranslatedMessage
          id="viral_clips_virality_score"
          defaultMessage="Viral potential score"
        />
      </p>
      <FlexContainer justifyContent="center" flexWrap="nowrap" gap={8}>
        <p className={css.score}>{score}</p>
        <Helper
          id_message={reason}
          default_message={reason}
          size={12}
          position="top-right"
          textStyle={{ maxWidth: '280px' }}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

import { ModalType } from '@autocut/enums/modals.enum';
import { addModalToQueue } from '@autocut/utils/modal/modals.utils';
import { ProviderProps } from '@reactour/tour';

export enum EOnboardingSteps {
  WELCOME = 'welcome',
  CAPTIONS = 'captions',
}

export type OnboardingTourProps = Partial<ProviderProps> & {
  openTour?: () => void;
};

export const ONBOARDING_TOURS: Record<EOnboardingSteps, OnboardingTourProps> = {
  [EOnboardingSteps.WELCOME]: {
    openTour: () => addModalToQueue(ModalType.OnboardingWelcome),
  },
  [EOnboardingSteps.CAPTIONS]: {},
};

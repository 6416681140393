import {
  CaptionChunk,
  ExportedAudioElementWithTranscript,
} from '@autocut/types/Captions';
import { WordBase } from '@autocut/types/Deepgram';
import {
  CanvasFontParams,
  splitTextIntoLines,
} from '@autocut/utils/captions/canvas/canvas.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { getParametersForMode } from '../parameters.utils';
import { getValue } from './utils';

export const PPRO_TEXT_LINE_BREAK = '\r';

export const processCaptionsChunksState = () => {
  const transcripts: ExportedAudioElementWithTranscript['transcript'][] =
    autocutStoreVanilla().ui.currentTranscription.exportedAudioWithTranscript.map(
      audio => audio.transcript
    );
  if (!transcripts.length) return;
  try {
    const sequenceWidth = autocutStoreVanilla().sequence.infos?.settings.width;
    const sequenceHeight =
      autocutStoreVanilla().sequence.infos?.settings.height;
    if (!sequenceWidth || !sequenceHeight) throw new Error();

    const params = getParametersForMode('caption');
    const fontParameters: CanvasFontParams = {
      italic: params.formating.italic,
      fontSize: params.text.fontSize,
      fontFamily: params.text.font.fontFamily,
    };
    const allowedWidth = (params.formating.maxWidth / 100) * sequenceWidth;

    //Split transcript by chunk that fit the screen
    const chunks: CaptionChunk[] = [];
    for (const transcript of transcripts) {
      if (!transcript)
        throw new IncrementalError(
          'No transcript found',
          'processCaptionsChunksState'
        );

      chunks.push(
        ...transcript.flatMap(transcript => {
          const chunks: CaptionChunk[] = [];

          // timeChunks = transcript words split when there is a pause of 0.2s. Shape : [[word, word, word], [word, word]]
          // A timeChunk is a subgroup of a transcript returned by the API
          const timeChunks = transcript.words.reduce((groups, word) => {
            const lastGroup = groups[groups.length - 1];
            if (!lastGroup) return [[word]];
            const lastWord = lastGroup?.[lastGroup.length - 1];
            if (!lastWord || lastWord.end + 0.2 < word.start) {
              //NEW CHUNK
              groups.push([word]);
            } else {
              //ADD TO LAST CHUNK
              lastGroup.push(word);
            }
            return groups;
          }, [] as (typeof transcript.words)[]);

          // lines are the timeChunks split into lines accorded to the maxWidth. Every line has been mesured into a canvas to fit the maxWidth.
          const lines = timeChunks
            .map(timeChunk =>
              splitTextIntoLines(
                timeChunk.map(word => getValue(word)).join(' '),
                {
                  font: fontParameters,
                  maxWidth: allowedWidth,
                  uppercase: params.formating.uppercase,
                }
              )
            )
            .reduce((result, current) => {
              //The reduce is used to correct the startIndex and endIndex of each line to have a transcript related word index and not a timeChunk related one.
              const lastLine = result[result.length - 1] || {
                endIndex: -1,
              };
              return [
                ...result,
                ...current.map(line => ({
                  ...line,
                  startIndex: line.startIndex + lastLine.endIndex + 1,
                  endIndex: line.endIndex + lastLine.endIndex + 1,
                })),
              ];
            }, []);

          // wordsByLine are the line but with the originals word objects instead
          const wordsByLine = lines.map(line => {
            const words = transcript.words.slice(
              line.startIndex,
              line.endIndex + 1
            );
            return words;
          });

          // chunks are the final chunks that will be displayed. A chunk is a group of [params.formating.nbLines] lines.
          let currentChunkLineNumber = 0;
          let lastEnd = 0;
          let lastIndexEnd = -2;
          for (const line of wordsByLine) {
            let chunkIndex = Math.max(chunks.length - 1, 0);
            if (
              currentChunkLineNumber >= params.formating.nbLines ||
              !chunks.length
            ) {
              //NEW CHUNK
              currentChunkLineNumber = 0;
              lastEnd = line[0]?.start ?? 0;
              lastIndexEnd = -2;
              chunkIndex++;
            }
            let currentLine = '';
            const currentChunk: CaptionChunk | undefined = chunks[chunkIndex];

            const newChunkText =
              (currentChunk?.text
                ? currentChunk.text + PPRO_TEXT_LINE_BREAK
                : '') + //Previous lines + new line break
              line.map(word => getValue(word)).join(' '); //New line

            chunks[chunkIndex] = {
              ...{ start: line[0]?.start, ...getLineEmoji(line) }, //To be overriden by the previous chunk values
              ...(currentChunk || {}), //Previous chunk values override
              text: newChunkText,
              end: line[line.length - 1].end, //Last word of the line end is the new chunk end
              highlight: [
                ...(currentChunk?.highlight || []),
                ...line.map(word => {
                  const res = getHighlightFromWord(
                    word,
                    lastEnd,
                    lastIndexEnd,
                    currentLine,
                    currentChunkLineNumber
                  );
                  currentLine = currentLine + res.word + ' ';
                  lastEnd = res.end;
                  lastIndexEnd = res.indexEnd;
                  return res;
                }),
              ],
              nbLines: currentChunkLineNumber + 1,
              lines: [...(currentChunk?.lines || []), line],
            };

            currentChunkLineNumber++;
          }

          return chunks;
        })
      );
    }

    setAutocutStore('onGoingProcess.captionChunks', chunks);

    return chunks;
  } catch (e: any) {
    throw new IncrementalError(e, 'processCaptionsChunksState');
  }
};

export const getHighlightFromWord = (
  word: WordBase,
  lastEnd: number,
  lastIndexEnd: number,
  currentLine: string,
  indexLine: number
) => {
  const wordString = getValue(word);
  const res = {
    start: lastEnd,
    end: word.end,
    indexStart: lastIndexEnd + 2, // +2 because of the space
    indexEnd: lastIndexEnd + 2 + wordString.length - 1,
    word: wordString,
    lineBeforeWord: currentLine,
    confidence: word.confidence,
    indexLine,
  };
  return res;
};

const getLineEmoji = (line: WordBase[]) => {
  const firstEmojiWord = line.find(word => word.emojiUrl && word.emoji);
  return {
    emoji: firstEmojiWord?.emoji,
    emojiUrl: firstEmojiWord?.emojiUrl,
    emojiSize: firstEmojiWord?.emojiSize,
  };
};

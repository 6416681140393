import { StepType } from '@reactour/tour';

export const onboardingSteps: StepType[] = [
  {
    selector: '#onboarding-step1',
    content: {
      title: 'onboarding_onboarding_step1_title',
      body: 'onboarding_onboarding_step1_body',
    } as any,
    position: 'center',
    padding: { wrapper: [200] },
  },
  {
    selector: '#onboarding-step2',
    content: {
      title: 'onboarding_onboarding_step2_title',
      body: 'onboarding_onboarding_step2_body',
    } as any,
  },
  {
    selector: '#onboarding-step3',
    content: {
      title: 'onboarding_onboarding_step3_title',
      body: 'onboarding_onboarding_step3_body',
    } as any,
  },
  {
    selector: '#onboarding-step4',
    content: {
      title: 'onboarding_onboarding_step4_title',
      body: 'onboarding_onboarding_step4_body',
    } as any,
  },
];

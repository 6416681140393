import { useState } from 'react';
import { getErrorInfos } from '../utils/errors/errors.util';
import { IncrementalError } from '../utils/errors/IncrementalError';
import { manageError } from '../utils/manageError';
import { setAutocutStore } from '../utils/zustand';
import {
  NeedingTranscriptionModes,
  ProcessTranscriptFunctions,
} from '@autocut/types/transcription';
import { useIntl } from 'react-intl';
import { ModalType } from '@autocut/enums/modals.enum';
import { handleBrollProcessTranscript } from '@autocut/modes/broll/Steps/Language/handleBrollProcessTranscript';
import { handleCaptionsProcessTranscript } from '@autocut/modes/captions/Steps/Language/handleCaptionsProcessTranscript';
import { handleChaptersProcessTranscript } from '@autocut/modes/chapter/Steps/Language/handleChaptersProcessTranscript';
import { handleRepeatProcessTranscript } from '@autocut/modes/repeat/Steps/Language/handleRepeatProcessTranscript';
import { handleViralClipsProcessTranscript } from '@autocut/modes/viralClips/Steps/Language/handleViralClipsProcessTranscript';
import { useNavigate } from 'react-router-dom';
import { handleSwearWordsProcessTranscript } from '@autocut/utils/swearWords/handleSwearWordsProcessTranscript';

export const processTranscriptFunctions: ProcessTranscriptFunctions = {
  caption: {
    process: handleCaptionsProcessTranscript,
    navigateTo: '/cut/caption/transcript',
  },
  broll: {
    process: handleBrollProcessTranscript,
    navigateTo: '/cut/broll/brolls',
  },
  chapters: {
    process: handleChaptersProcessTranscript,
    navigateTo: '/cut/chapters/editor',
  },
  repeat: {
    process: handleRepeatProcessTranscript,
    navigateTo: '/cut/repeat/transcript',
  },
  swear_word: {
    process: handleSwearWordsProcessTranscript,
    navigateTo: '/homepage',
  },
  viral_clips: {
    process: handleViralClipsProcessTranscript,
    navigateTo: '/cut/viral_clips/selector',
  },
};

export const useHandleProcessTranscript = <T extends NeedingTranscriptionModes>(
  mode: T,
  onSuccess?: () => void
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const intl = useIntl();
  const navigate = useNavigate();

  const handleProcessTranscript = async (usedModel = 0) => {
    setAutocutStore('ui.openedModalName', ModalType.Processing);
    setAutocutStore('ui.process.isPaused', true);
    setAutocutStore('ui.currentTranscription.modeId', mode);
    setLoading(true);
    setError(null);

    try {
      const { process, navigateTo } = processTranscriptFunctions[mode];
      await process(intl, usedModel);

      if (navigateTo) {
        navigate(navigateTo);
      }

      setAutocutStore('ui.openedModalName', '');
      onSuccess?.();
    } catch (e: any) {
      setAutocutStore('ui.process.isPaused', false);
      const errorInfos = getErrorInfos(e);

      const formattedError = new IncrementalError(
        e,
        `handleTranscript ${mode}`
      );

      if (errorInfos.id === 'EXPORT_CANCELLED') {
        //Recognized error
        setError('error_modal_text_exportCancelled');
      } else {
        //API returned error
        setError(formattedError.message || JSON.stringify(e));
      }

      manageError({
        error: formattedError,
      });
    } finally {
      setLoading(false);
    }
  };

  return { handleProcessTranscript, loading, error };
};

import { useDrawEmoji } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/Emoji/useDrawEmoji';
import { CaptionCanvasObjectHook } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import { useCanvasObject } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionDrawHooks.utils';

export const useEmojiCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawEmoji(args);

  return useCanvasObject({
    name: 'emoji',
    paths: {
      x: 'ui.parameters.caption.position.emojiXPercentage',
      y: 'ui.parameters.caption.position.emojiYPercentage',
    },
    ...drawReturn,
    ...args,
  });
};

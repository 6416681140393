import { NumberInput } from '@autocut/components/atoms/NumberInput';
import { InputRangeParameter } from '@autocut/components/atoms/Parameters/InputRangeParamater/InputRangeParameter';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { updateCoverage } from '@autocut/utils/zoom/updateCoverage';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const DynamicZoomStep = () => {
  const { zoomTypes, dynamicZoomPercentage, dynamicMaxZoomTime } =
    useAutoCutStore(state => state.ui.parameters.zoom);
  const isOnlyZoomType =
    Object.values(zoomTypes).filter(Boolean).length === 1 ? true : false;
  const intl = useIntl();

  return (
    <ParametersGroupLayout
      title={intl.formatMessage({
        id: 'autozoom_dynamic_zoom',
        defaultMessage: 'Dynamic zooms',
      })}
      status={zoomTypes.DYNAMIC}
      onStatusChange={status => {
        setAutocutStore('ui.parameters.zoom.zoomTypes.DYNAMIC', status);
        if (status)
          updateCoverage(
            'dynamicZoomPercentage',
            AutocutModes.Zoom.defaultParameters.dynamicZoomPercentage
          );
        else updateCoverage('dynamicZoomPercentage', 0);
      }}
      hiddenContent={null}
    >
      <ParameterLayout
        title={
          <FormattedMessage
            id="autozoom_dynamic_zoom_coverage"
            defaultMessage="Dynamic zooms coverage"
          />
        }
        grow
      >
        <InputRangeParameter
          value={dynamicZoomPercentage}
          step={0.01}
          min={0}
          max={1}
          disabled={isOnlyZoomType}
          unit="%"
          unitFormatting={value => `${(value * 100).toFixed()}`}
          onChange={value =>
            updateCoverage('dynamicZoomPercentage', parseFloat(value))
          }
        />
      </ParameterLayout>
      <ParameterLayout
        title={
          <FormattedMessage
            id="autozoom_max_zoom_title"
            defaultMessage="Maximum zoom duration"
          />
        }
        grow
        height={32}
      >
        <NumberInput
          unitId="secondUnit"
          value={`${dynamicMaxZoomTime}`}
          targetedParameter={'dynamicMaxZoomTime'}
          onChange={(value: string) =>
            setAutocutStore(
              'ui.parameters.zoom.dynamicMaxZoomTime',
              parseFloat(value)
            )
          }
          inputContainerStyle={{ paddingTop: 6, paddingBottom: 6 }}
        />
      </ParameterLayout>
    </ParametersGroupLayout>
  );
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import * as React from 'react';
import { useState } from 'react';
import { LiaSortAmountDownSolid } from 'react-icons/lia';
import { CustomSelectText } from '../CustomSelectText/CustomSelectText';
import css from './Sorter.module.scss';

type SorterProps<T> = {
  list: T[];
  criterias: {
    label: string;
    sortFunction: (a: any, b: any) => number;
  }[];
  onUpdate?: (sortedList: T[]) => void;
};

export const Sorter = ({ list, criterias, onUpdate }: SorterProps<any>) => {
  const [selectedValue, setSelectedValue] = useState({
    label: criterias[0].label,
    value: '0',
  });

  return (
    <FlexContainer
      className={`${css.container}`}
      flexDirection="row"
      gap={8}
      alignItems="center"
    >
      <LiaSortAmountDownSolid size={48} />
      <CustomSelectText
        options={criterias.map((criteria, index) => ({
          label: criteria.label,
          value: `${index}`,
        }))}
        handleSelectChange={option => {
          if (!option) return null;

          const index = parseInt(option?.value as string);
          const criteria = criterias[index];
          const sortedList = [...list.sort(criteria.sortFunction)];
          setSelectedValue({ label: option.label, value: option.value });

          if (onUpdate) {
            console.log('list updated');
            onUpdate(sortedList);
          }
        }}
        initialValue={selectedValue}
      />
    </FlexContainer>
  );
};

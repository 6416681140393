import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ColorPicker from '@autocut/components/atoms/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { Slider } from '@autocut/designSystem/components/atoms/Slider/Slider';

const OutlineCustomization = () => {
  const { enabled, color, width } = useAutoCutStore(
    state => state.ui.parameters.caption.outline
  );

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked =>
        setAutocutStore('ui.parameters.caption.outline.enabled', checked)
      }
      title={
        <TranslatedMessage
          id="captions_customization_outline"
          defaultMessage="Outline"
        />
      }
    >
      <ParameterLayout
        title={
          <TranslatedMessage
            id="captions_customization_outline_color"
            defaultMessage="Color"
          />
        }
      >
        <ColorPicker
          value={color}
          onChange={color =>
            setAutocutStore('ui.parameters.caption.outline.color', color)
          }
        />
      </ParameterLayout>
      <ParameterLayout
        title={
          <TranslatedMessage
            id="captions_customization_outline_width"
            defaultMessage="Width"
          />
        }
        wrap={true}
      >
        <Slider
          variant="valueOnLeft"
          value={`${width}`}
          min={0}
          max={100}
          unit="px"
          onChange={value =>
            setAutocutStore('ui.parameters.caption.outline.width', value)
          }
        />
      </ParameterLayout>
    </ParametersGroupLayout>
  );
};

export default OutlineCustomization;

import { importXMLProjectResources } from '@autocut/utils/captions/xml/importResources.xml.captions.utils.ts';

import { path } from '@autocut/lib/cep/node';
import { XMLDocument } from '../../xml/XMLDocument';
import { getResourcesFolder } from '@autocut/utils/resourceManager.utils';

export const initCaptionXMLTemplate = async (folderName: string) => {
  const { xmlProjectPath } = await importXMLProjectResources(folderName);

  // ===== PARSING TEMPLATE PROJECT =====
  const xmlDoc = new XMLDocument(xmlProjectPath);

  // ===== UPDATE RELATIVE PATHS =====
  xmlDoc.medias.forEach(media => {
    if (media.relativePath)
      media.changeFilePath(
        path.join(
          getResourcesFolder(),
          folderName,
          media.relativePath?.textContent ?? ''
        )
      );
  });

  return xmlDoc;
};

import { createContext, useEffect } from 'react';

import React from 'react';
import {
  EOnboardingSteps,
  ONBOARDING_TOURS,
  OnboardingTourProps,
} from './utils';
import { ProviderProps, TourProvider, useTour } from '@reactour/tour';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import {
  getLocalStorage,
  setLocalStorage,
} from '@autocut/utils/localStorage.utils';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';

type OnboardingContext = {
  onboardingCompleted: string[];
  setComplete: (id: EOnboardingSteps) => void;
  selectTour: (onboardingTour: OnboardingTourProps) => void;
};
export const OnboardingContext = createContext<OnboardingContext>({
  onboardingCompleted: [],
  setComplete: () => {
    return;
  },
  selectTour: () => {
    return;
  },
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [onboardingCompleted, setOnboardingCompleted] = React.useState<
    EOnboardingSteps[]
  >(getLocalStorage('onboardings') || []);
  const [selectedTour, setSelectedTour] =
    React.useState<OnboardingTourProps | null>(null);

  const setComplete = (id: EOnboardingSteps) => {
    const onBoardingCompleted = [...new Set([...onboardingCompleted, id])];
    setOnboardingCompleted(onBoardingCompleted);
    setLocalStorage('onboardings', onBoardingCompleted);
    setSelectedTour(null);
  };

  return (
    <OnboardingContext.Provider
      value={{
        onboardingCompleted,
        setComplete,
        selectTour: setSelectedTour,
      }}
    >
      <ConditionalWrap
        condition={!!selectedTour && !!selectedTour.steps}
        wrap={children => (
          <TourProvider
            {...(selectedTour as OnboardingTourProps &
              Pick<ProviderProps, 'steps'>)}
          >
            {children}
          </TourProvider>
        )}
      >
        {children as React.ReactElement}
      </ConditionalWrap>
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (id: EOnboardingSteps) => {
  const { onboardingCompleted, setComplete, selectTour } =
    React.useContext(OnboardingContext);
  const isOnboardingCompleted = onboardingCompleted.includes(id);
  const { openedModalName } = useAutoCutStore(state => ({
    openedModalName: state.ui.openedModalName,
  }));
  const { setIsOpen } = useTour();

  const tour = ONBOARDING_TOURS[id];

  useEffect(() => {
    /** Quand on ouvre la modale, le useEffect se recharge et on met la modale une deuxième fois dans la queue.
    Il faudrait pouvoir checker le nom de la modale ouverte pour ne pas la remettre dans la queue */

    const isOnboardingJustCompleted =
      getLocalStorage('onboardings')?.includes(id);

    if (!isOnboardingCompleted && !isOnboardingJustCompleted) {
      selectTour(tour);

      if (tour.openTour && openedModalName !== 'OnboardingWelcome') {
        tour.openTour();
      } else {
        setIsOpen(true);
      }
    }
  }, [id, isOnboardingCompleted, selectTour, setIsOpen, tour, openedModalName]);

  return {
    isOnboardingCompleted,
    tour,
    setComplete,
  };
};

export const useCompleteOnboarding = (id: EOnboardingSteps) => {
  const { setComplete } = React.useContext(OnboardingContext);

  return () => setComplete(id);
};

import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect } from 'react';

import { InvalidSelectionScreen } from '@autocut/components/partials/InvalidSelectionScreen/InvalidSelectionScreen';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { TranscriptLanguageStep } from '@autocut/components/steps/TranscriptLanguageStep/TranscriptLanguageStep';
import { useHandleProcessTranscript } from '@autocut/hooks/useHandleProcessTranscript';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

const CaptionsLanguage = () => {
  const { handleProcessTranscript, error, loading } =
    useHandleProcessTranscript('caption');

  const { selectionInfos, isProviderAvailable } = useAutoCutStore(state => ({
    selectionInfos: state.sequence.infos,
    isProviderAvailable: state.ui.currentTranscription.isProviderAvailable,
  }));

  useEffect(() => {
    // On mounting, we enable selection detection.
    // As we disable it when processing the transcript, we have to re-enable it here in case of the user goes back to the previous step.
    setAutocutStore('ui.process.isPaused', false);
    window.scrollTo(0, 0);
  }, []);

  const nbFiles = React.useMemo(
    () =>
      selectionInfos
        ? Array.from(
            new Set(
              selectionInfos.audioTracks.flatMap(track =>
                track.selectedClips.map(clip => clip.path)
              )
            )
          ).length
        : 0,
    [selectionInfos]
  );

  return !!selectionInfos && selectionInfos.nbClipsAudio > 0 ? (
    <ModeLayout
      footer={
        <ModeFooter
          errorId={error ?? isProviderAvailable.message}
          renderButton={(isLoading, isDisabled) => (
            <Button
              onClick={() => handleProcessTranscript()}
              disabled={
                loading ||
                isLoading ||
                isDisabled ||
                nbFiles <= 0 ||
                !isProviderAvailable.isAvailable
              }
              color={colors.primary600}
            >
              <TranslatedMessage
                id="captions_process_transcript_button"
                defaultMessage="Go to captions editor"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId="caption" />
      <SelectedClipsStep />
    </ModeLayout>
  ) : (
    <InvalidSelectionScreen
      titleId="captions_text_noClip_title"
      titleDefaultMessage="Select clip(s) to cut ddin your timeline"
      texts={[
        { id: 'captions_text_noClip', defaultMessage: 'Nothing selected' },
        {
          id: 'captions_text_noClip_no_selection',
          defaultMessage:
            'If the selected clips do not appear, try restarting AutoCut.',
        },
      ]}
      selectionImage={silenceSelectionImage}
    />
  );
};

export default CaptionsLanguage;

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { MARKER_COLORS } from '@autocut/utils/chapters/colors';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { FaCheck } from 'react-icons/fa';

import css from './ChaptersParameters.module.scss';

export const ChaptersParameters = () => {
  const { colorIndex } = useAutoCutStore(state => state.ui.parameters.chapters);

  return (
    <ParameterLayout
      title={
        <p className={css.title}>
          <TranslatedMessage
            id="chapters_parameters_color"
            defaultMessage="Marker color"
          />
        </p>
      }
    >
      <FlexContainer
        gap={8}
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        {Object.entries(MARKER_COLORS).map(([index, color]) => {
          const isSelected = colorIndex === parseInt(index);
          return (
            <div
              key={color}
              onClick={() => {
                setAutocutStore(
                  'ui.parameters.chapters.colorIndex',
                  parseInt(index)
                );
              }}
              className={`${css.markerColor} ${isSelected ? css.selected : ''}`}
              style={{
                backgroundColor: color,
              }}
            >
              {isSelected && (
                <div className={css.badge}>
                  <FaCheck size={10} color="white" />
                </div>
              )}
            </div>
          );
        })}
      </FlexContainer>
    </ParameterLayout>
  );
};

import { AutocutMode, AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { comparePproVersions, getPproVersion } from '@autocut/utils';
import { isKeyTypeEqualsOrGreaterThan } from '@autocut/utils/keys/keyType.utils';
import { fetchUsage } from '@autocut/utils/transcript/fetchUsage.utils';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { HomepageCard } from './HomepageCard';

export const HomepageCardBase = ({ mode }: { mode: AutocutMode }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const license = useAutoCutStore(state => state.user.license);
  const [usage, setUsage] = React.useState<{ percentage: number }>();

  const isTrial = !isKeyTypeEqualsOrGreaterThan(
    license?.type === 'trial' ? 'ai' : license?.key_type || 'basic',
    mode.minKeyType
  );

  const PProVersion = getPproVersion();
  const pproVersionIsSupported =
    comparePproVersions(PProVersion, mode.minPProVersion) >= 0;

  const disabled = useMemo(
    () =>
      !license?.features?.includes(mode.id) ||
      (license?.type !== 'trial' &&
        (usage?.percentage || 0) >= 0.99 &&
        isTrial) ||
      !pproVersionIsSupported ||
      !mode.available,
    [license?.features, mode.id, usage]
  );

  const additionalText = useMemo(
    () =>
      mode.available
        ? !pproVersionIsSupported
          ? intl.formatMessage({
              id: 'version_not_supported',
              defaultMessage: 'PPro version not supported',
            })
          : disabled || isTrial
          ? intl.formatMessage(
              { id: 'upgrade_to', defaultMessage: 'Upgrade to {plan}' },
              {
                plan: mode.minKeyType.toLocaleUpperCase(),
              }
            )
          : mode.status || undefined
        : intl.formatMessage({
            id: 'homepage_comming_soon',
            defaultMessage: 'Coming soon',
          }),
    [disabled, intl]
  );

  const needUpgrade = mode.available && disabled;

  useEffect(() => {
    const refreshUsage = async () => setUsage(await fetchUsage());

    if (!usage && mode.minKeyType === 'ai' && mode.available)
      void refreshUsage();
  }, [usage]);

  return (
    <HomepageCard
      disabled={disabled}
      upgrade={needUpgrade}
      showTrialbanner={!disabled && isTrial}
      additionalText={additionalText}
      Icon={mode.icon}
      text={mode.name}
      description={mode.description}
      thumbnail={mode.thumbnail}
      onClick={() => navigate(mode.path)}
    />
  );
};

export const HomepageCardLegacy = () => (
  <HomepageCardBase mode={AutocutModes.Legacy} />
);
export const HomepageCardPodcast = () => (
  <HomepageCardBase mode={AutocutModes.Podcast} />
);
export const HomepageCardAutoZoom = () => (
  <HomepageCardBase mode={AutocutModes.Zoom} />
);
export const HomepageCardRepeat = () => (
  <HomepageCardBase mode={AutocutModes.Repeat} />
);
export const HomepageCardCaptions = () => (
  <HomepageCardBase mode={AutocutModes.Captions} />
);
export const HomepageCardChapter = () => (
  <HomepageCardBase mode={AutocutModes.Chapters} />
);
export const HomepageCardResize = () => (
  <HomepageCardBase mode={AutocutModes.Resize} />
);
export const HomepageCardBRoll = () => (
  <HomepageCardBase mode={AutocutModes.BRoll} />
);
export const HomepageCardSwearWords = () => (
  <HomepageCardBase mode={AutocutModes.SwearWords} />
);
export const HomepageCardViralClips = () => (
  <HomepageCardBase mode={AutocutModes.ViralClips} />
);

import { Sentence } from '@autocut/types/Deepgram';

export const NOT_FOUND_CHAPTER_VALUE = -1;

export const processChapters = (
  openAiResponse: ChaptersOpenAiResponse,
  sentences: Sentence[],
  offset = 0
): Chapter[] => {
  const chapters = openAiResponse
    .map(chapter => {
      const startSentenceIndex = parseInt(chapter.first_sentence.id);
      const endSentenceIndex = parseInt(chapter.last_sentence.id);

      const startSentence = sentences[startSentenceIndex];
      const endSentence = sentences[endSentenceIndex];

      if (!startSentence || !endSentence) return null;

      return {
        title: chapter.title,
        start: startSentence.start + offset,
        end: endSentence.end + offset,
      };
    })
    .filter(Boolean) as Chapter[];

  return chapters;
};

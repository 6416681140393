import { getSubclipIndexes } from '@autocut/utils/cut/handleDelete/getSubclipIndexes.utils';

/**
 * Returns the indexes of the clips to move in order to make J and L cuts.
 * /!\ The indexes are relative to the timeline.
 **/
export const getClipIndexesToMove = (
  track: Track,
  soundIntervals: number[][]
) => {
  const numberClipsAfterCuts = track.selectedClips.reduce((acc, clip) => {
    const { nbCutsOnClip } = getSubclipIndexes(clip, soundIntervals);
    return acc + nbCutsOnClip + 1;
  }, 0);

  const clipIndexToMove = [];
  let currentIndex = -1;

  for (
    let clipIndexAfterCuts = 0;
    clipIndexAfterCuts < numberClipsAfterCuts;
    clipIndexAfterCuts++
  ) {
    if (clipIndexAfterCuts % 2 === 0) {
      clipIndexToMove.push(currentIndex++);
    }
  }

  return clipIndexToMove;
};

import { random } from 'lodash';
import { IncrementalError } from '../errors/IncrementalError';
import { getParametersForMode } from '../parameters.utils';
import { getNextCamera, GetNextCameraReturnType } from './getNextCamera';

export const getCamerasToDisplay = (
  cameras: {
    weight: number;
    label: string;
    value: string;
  }[],
  normalizedSpeakers: {
    talkingTimeline: any[];
    id: string;
    name: string;
    cameras: ({
      label: string;
      value: string;
    } & any)[];
    audio: {
      label: string;
      value: string;
    };
    clips: Clip[];
  }[],
  audioOffsetStart = 0,
  audioOffsetEnd = 9999999
) => {
  try {
    const podcastParameters = getParametersForMode('podcast');

    const camerasToDisplay: {
      from: number;
      to: number;
      camera: {
        weight: number;
        label: string;
        value: string;
      };
    }[] = [];

    let nextCamera: GetNextCameraReturnType = undefined;
    let startSecond = audioOffsetStart;

    let hasReachedEnd = false;

    do {
      nextCamera = getNextCamera(
        startSecond,
        nextCamera?.camera,
        cameras,
        normalizedSpeakers,
        //Adding some randomness to the maximum cam time (20%)
        random(
          podcastParameters.maximumCamTime -
            0.2 * podcastParameters.maximumCamTime,
          podcastParameters.maximumCamTime,
          true
        ),
        podcastParameters.minimumCamTime
      );

      if (!nextCamera?.camera || nextCamera?.to >= audioOffsetEnd) {
        hasReachedEnd = true;
      }

      if (nextCamera?.camera) {
        if (hasReachedEnd) {
          nextCamera.to = audioOffsetEnd;
        }
        camerasToDisplay.push(nextCamera);

        startSecond = nextCamera?.to;
      }
    } while (!hasReachedEnd);

    return camerasToDisplay;
  } catch (err: any) {
    throw new IncrementalError(err, 'getCamerasToDisplay');
  }
};

import { RadioButtonsForm } from '@autocut/components/atoms/RadioButtonsForm/RadioButtonsForm';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { useIntl } from 'react-intl';

export const PodcastSilenceStep = () => {
  const intl = useIntl();
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'title_podcast_silences_step',
        defaultMessage: 'Remove or disable',
      })}
    >
      <FlexContainer
        justifyContent="space-between"
        alignItems="center"
        gap={24}
      >
        <RadioButtonsForm
          radioButtonDescriptions={[
            {
              label: intl.formatMessage(
                {
                  id: 'text_silences_step_delete',
                  defaultMessage: 'Remove',
                },
                {
                  mode: '',
                }
              ),
              value: true,
              default: podcastParameters.deleteUnusedClips === true,
            },
            {
              label: intl.formatMessage(
                {
                  id: 'text_silences_step_disable',
                  defaultMessage: 'Disable',
                },
                {
                  mode: '',
                }
              ),
              value: false,
              default: podcastParameters.deleteUnusedClips === false,
            },
          ]}
          onChange={newValue => {
            setAutocutStore(
              'ui.parameters.podcast.deleteUnusedClips',
              newValue as typeof podcastParameters.deleteUnusedClips
            );
          }}
        />
      </FlexContainer>
    </StepLayout>
  );
};

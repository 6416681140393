import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import { Helper } from '../Helper/Helper';
import css from './SilenceDetectionTypeChoice.module.css';
import LicenceTypeTag from '@autocut/components/atoms/LicenceTypeTag/LicenceTypeTag';
import { setAutocutStore } from '@autocut/utils/zustand';

const SilenceDetectionTypeChoice = ({}) => {
  const { logMessage } = useLogger('SilenceDetectionTypeChoice');
  const { mode, license } = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    license: state.user.license,
  }));
  const navigate = useNavigate();

  const isAiAvailable =
    license?.features?.includes(AutocutModes.Ai.id) || false;

  // const { setIsOpen: setIsTourOpen } = useTour();
  // React.useEffect(() => {
  //   if (!getLocalStorage(onboardingLocalStorageKeys.ModeSelection)) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  return (
    <div id="modeSelection-step1" className={css.silenceSelectorContainer}>
      <div
        className={mode.id === AutocutModes.Legacy.id ? css.active : ''}
        onClick={() => {
          logMessage(logLevel.notice, 'Changing mode', {
            mode: AutocutModes.Legacy.id,
          });
          setAutocutStore('ui.process.mode', AutocutModes.Legacy);
          navigate('/cut/legacy');
        }}
      >
        <div className={css.silenceSelectorTitle}>
          AutoCut v2
          <div id="modeSelection-step2">
            <Helper
              id_message="text_autocutv2"
              default_message="AutoCut v2 is the legacy version, there are a lot of options to configure for the perfect silence detection"
              size="14px"
              textStyle={{
                width: '160px',
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={mode.id === AutocutModes.Ai.id ? css.active : ''}
        style={{ cursor: isAiAvailable ? 'pointer' : 'not-allowed' }}
        onClick={
          isAiAvailable
            ? () => {
                logMessage(logLevel.notice, 'Changing mode', {
                  mode: AutocutModes.Ai,
                });
                setAutocutStore('ui.process.mode', AutocutModes.Ai);
                navigate('/cut/ai');
              }
            : undefined
        }
      >
        <div className={css.silenceSelectorTitle}>
          AutoCut AI
          <Helper
            id_message="text_autocutAi"
            default_message="AutoCut AI allow you to automatically detect silences with only one parameter, let the AI do everything else"
            size="14px"
            textStyle={{
              width: '160px',
            }}
          />
          {!isAiAvailable && <LicenceTypeTag licenceType="pro" />}
        </div>
      </div>
    </div>
  );
};

export default SilenceDetectionTypeChoice;

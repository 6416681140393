import * as React from 'react';

import { useLocale } from '@autocut/hooks/useLocale';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import { Locale } from '@autocut/context/LocaleContext';
import Select, { SingleValue } from 'react-select';
import selectCustomStyle from '../CustomSelectText/SelectStyle';

export const SelectLang = () => {
  const { locale, setAndSaveLocale, availableLocales } = useLocale();
  const { logMessage } = useLogger('SelectLang');

  const handleChange = (
    value: SingleValue<{
      value: (typeof availableLocales)[number];
      label: (typeof availableLocales)[number];
    }>
  ) => {
    logMessage(logLevel.info, 'Changing locale', { value });
    setAndSaveLocale(value?.value as Locale);
  };

  return (
    <Select
      isSearchable={false}
      styles={selectCustomStyle('trackSelection')}
      options={availableLocales.map(
        (currentLocale: (typeof availableLocales)[number]) => ({
          value: currentLocale,
          label: currentLocale,
        })
      )}
      defaultValue={{ value: locale, label: locale }}
      onChange={handleChange}
    />
  );
};

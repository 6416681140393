import * as React from 'react';
import { useEffect, useState } from 'react';

import { PopoverContentProps } from '@reactour/tour';
import { disableBodyScroll } from 'body-scroll-lock';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { StatCategory } from '@autocut/types/StatCategory.enum';
import { StatType } from '@autocut/types/StatType.enum';
import css from './onboardingModal.module.css';
import { sendStats } from '@autocut/utils/stats.util';

export const popoverStyles = (base: any) => ({
  ...base,
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: '#24212C',
  minWidth: '375px',
});

export const OnboardingModal = ({
  currentStep: currentStepIndex,
  steps,
  setIsOpen,
  setCurrentStep,
}: PopoverContentProps) => {
  const [isLastStep, setIsLastStep] = useState(
    currentStepIndex === steps.length - 1
  );
  const [currentStep, setCurrentStepObj] = useState(
    steps[Math.min(Math.max(0, currentStepIndex), steps.length - 1)]
  );

  useEffect(() => {
    setIsLastStep(currentStepIndex === steps.length - 1);
    setCurrentStepObj(steps[currentStepIndex]);
  }, [currentStepIndex]);

  useEffect(() => {
    // @ts-ignore
    disableBodyScroll(document);
  }, []);

  const handleNext = async () => {
    if (currentStepIndex === steps.length - 1) {
      await sendStats({
        type: StatType.ON_BOARDING_COMPLETED,
        value: 1,
      });
      setIsOpen(false);
    } else {
      setCurrentStep(currentStepIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStepIndex === 0) {
      setIsOpen(false);
    } else {
      setCurrentStep(currentStepIndex - 1);
    }
  };

  const handleSkip = async () => {
    await sendStats({
      type: StatType.ON_BOARDING_SKIPPED,
      value: 1,
      traits: {
        step: currentStepIndex + 1,
        totalSteps: steps.length,
      },
    });
    setIsOpen(false);
  };

  return (
    <div className={css.container}>
      <div className={css.title}>
        <TranslatedMessage
          id={(currentStep.content as any).title}
          defaultMessage={(currentStep.content as any).title}
        />
      </div>
      <div>
        <TranslatedMessage
          id={(currentStep.content as any).body}
          defaultMessage={(currentStep.content as any).body}
        />
      </div>
      <div className={css.buttons}>
        <div onClick={handleSkip} className={css.skipButton}>
          <TranslatedMessage
            id="onboarding_skip"
            defaultMessage="Skip onboarding"
          />
        </div>
        <div className={css.navigationButton}>
          {currentStepIndex !== 0 && (
            <button onClick={handlePrevious} className={css.previousButton}>
              <TranslatedMessage
                id="onboarding_previous"
                defaultMessage="Previous"
              />
            </button>
          )}
          <button onClick={handleNext} className={css.nextButton}>
            {isLastStep ? (
              <TranslatedMessage id="onboarding_close" defaultMessage="Close" />
            ) : (
              <>
                <TranslatedMessage
                  id="onboarding_next"
                  defaultMessage="Next ({current}/{max})"
                  values={{
                    current: currentStepIndex + 1,
                    max: steps.length,
                  }}
                />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

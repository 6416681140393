import React from 'react';

import css from './TimeBadge.module.css';
import PopupPortal from '../PopupPortal/PopupPortal';
import { TranslatedMessage } from '../TranslatedMessage/TranslatedMessage';
import { InfoText } from '../InfoText';
import { EditableText } from '../EditableText/EditableText';

export type TimeBadgeProps = {
  from: number;
  to: number;
};

export const TimeBadge = ({ from, to }: TimeBadgeProps) => {
  return (
    <div className={`${css.container} ${css.unselectable}`}>
      {new Date(from * 1000).toISOString().substring(11, 19)} -{' '}
      {new Date(to * 1000).toISOString().substring(11, 19)}
    </div>
  );
};

const TIME_REGEXP = /^\d{1,2}(?::\d{1,2}){0,2}$/;

export const EditableTimeBadge = ({
  from,
  to,
  onChange,
}: TimeBadgeProps & {
  onChange: (from: number, to: number) => void;
}) => {
  const [errorIsShown, setErrorIsShown] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const showError = () => {
    setErrorIsShown(true);
    setTimeout(() => {
      setErrorIsShown(false);
    }, 3000);
  };

  return (
    <>
      {errorIsShown && (
        <PopupPortal target={containerRef} position="top-right">
          <InfoText style="error">
            <TranslatedMessage
              id="time_badge_error_wrong_format"
              defaultMessage="Wrong time format. Please use HH:MM:SS format."
            />
          </InfoText>
        </PopupPortal>
      )}
      <div ref={containerRef} className={css.container}>
        <EditableText
          className={'from'}
          initialValue={new Date(from * 1000).toISOString().substring(11, 19)}
          onChange={value => {
            if (!TIME_REGEXP.test(value)) {
              showError();
              onChange(from, to);
              return;
            }

            const [seconds = 0, minutes = 0, hours = 0] = value
              .split(':')
              .map(Number)
              .reverse();
            const newFrom = hours * 3600 + minutes * 60 + seconds;
            onChange(newFrom, to);
          }}
        />
        {' - '}
        <EditableText
          className={'to'}
          initialValue={new Date(to * 1000).toISOString().substring(11, 19)}
          onChange={value => {
            if (!TIME_REGEXP.test(value)) {
              showError();
              onChange(from, to);
              return;
            }

            const [seconds = 0, minutes = 0, hours = 0] = value
              .split(':')
              .map(Number)
              .reverse();
            const newTo = hours * 3600 + minutes * 60 + seconds;
            onChange(from, newTo);
          }}
        />
      </div>
    </>
  );
};

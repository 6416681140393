import React, { forwardRef, useImperativeHandle, useState } from 'react';

import css from './PexelPreview.module.css';
import { LoadingRing } from '../LoadingRing/LoadingRing';

export type PexelPreviewProps = {
  videoSrc: string;
  picture: string;
};

export type PexelVideoRef = HTMLVideoElement & {
  zoom: () => void;
  unzoom: () => void;
};

export const PexelPreview = forwardRef<PexelVideoRef, PexelPreviewProps>(
  ({ picture, videoSrc }, ref) => {
    const videoRef = React.createRef<HTMLVideoElement>();
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isZoomed, setIsZoomed] = useState(false);

    useImperativeHandle(ref, () => ({
      ...(videoRef.current as HTMLVideoElement),
      zoom: () => setIsZoomed(true),
      unzoom: () => setIsZoomed(false),
      play: async () => {
        try {
          await videoRef.current?.play();
        } catch (e) {
          return;
        }
      },
      pause: () => {
        try {
          videoRef.current?.pause();
        } catch (e) {
          setTimeout(() => {
            videoRef.current?.pause();
          }, 500);
        }
      },
    }));

    return (
      <div className={css.container}>
        <video
          title="video"
          className={`${css.video} ${isZoomed ? css.displayed : ''}`}
          ref={videoRef}
          onCanPlay={() => setIsVideoLoaded(true)}
          autoPlay={false}
          muted
          loop
          poster={picture}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        {!isVideoLoaded && (
          <div
            style={{
              position: 'absolute',
              zIndex: '5',
              inset: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingRing size={40} />
          </div>
        )}
      </div>
    );
  }
);

import { Speaker } from '@autocut/types/Speaker';
import { getTalkingTimelineViaClusters } from '../clusters/getTalkingTimelineViaClusters';
import { getAiTimelineBasedSilences } from '../cut';
import { IncrementalError } from '../errors/IncrementalError';
import { getTracksAtIntervalsDescriptor } from '../export/descriptors';
import { getExportedAudioInfos } from '../export/getExportedAudioInfos';
import { getTalkingTimelineFromSilences } from './getTalkingTimelineFromSilences';
import { getCleanTimeline } from './timelinePostProcessing';

export const getSpeakersTalkingTimeline = async (
  speakers: (Speaker & {
    clips: Clip[];
  })[],
  sequenceStart: number,
  sequenceEnd: number
) => {
  try {
    const speakersWithTalkingTimeline = [];
    const tracksToExport = speakers.map(speaker => Number(speaker.audio.value));

    const exportDescriptor = getTracksAtIntervalsDescriptor(
      tracksToExport,
      sequenceStart,
      sequenceEnd
    );

    const exported = await getExportedAudioInfos(exportDescriptor);

    for (const track of exported.sequence.audioTracks) {
      const interval = track.audioIntervalsResults[0];
      const trackIndex = track.trackIndex;

      const speaker = speakers.find(
        t => Number(t.audio.value) === trackIndex
      ) as Speaker & { clips: Clip[] };
      const { timelineBasedSilences } = await getAiTimelineBasedSilences(
        interval
      );

      const vadTalkingTimeline = getTalkingTimelineFromSilences(
        speaker.clips,
        timelineBasedSilences
      );

      const clustersTalkingTimeline = getTalkingTimelineViaClusters(
        interval.path,
        speaker.clips
      );

      if (vadTalkingTimeline.length !== clustersTalkingTimeline.length) {
        // vadTalkingTimeline is one frame bigger than clustersTalkingTimeline for some reason
        // TODO: investigate why
      }

      const talkingTimeline: number[] = [];

      for (let i = 0; i < vadTalkingTimeline.length; i++) {
        talkingTimeline.push(
          vadTalkingTimeline[i] && clustersTalkingTimeline[i]
        );
      }

      const cleanTalkingTimeline = getCleanTimeline(talkingTimeline);

      speakersWithTalkingTimeline.push({
        ...speaker,
        talkingTimeline: cleanTalkingTimeline,
      } as Speaker & {
        clips: Clip[];
        talkingTimeline: number[];
      });
    }

    return speakersWithTalkingTimeline;
  } catch (err: any) {
    throw new IncrementalError(err, 'getSpeakersTalkingTimeline');
  }
};

export const DISCOVER_OPTIONS = [
  'facebook',
  'youtube-video',
  'youtube-comment',
  'reddit',
  'google',
  'friend',
  'school',
  'other',
];

export const TYPEOFCREATION_OPTIONS = [
  'interview',
  'podcast',
  'short',
  'youtube',
  'internal',
  'other',
];

export const JOBSTATUS_OPTIONS = [
  'content_creator',
  'video_editor_professional',
  'video_editor_semi_professional',
  'student',
  'video_editor_beginner',
  'other',
];

export const isDiscoverFormValid = ({
  discover,
  jobStatus,
  typeOfCreation,
}: {
  discover: string | null;
  jobStatus: string | null;
  typeOfCreation: string[] | null;
}): boolean => {
  return (
    discover !== null &&
    jobStatus !== null &&
    typeOfCreation !== null &&
    typeOfCreation.length > 0
  );
};

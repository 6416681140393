import { Utterance } from '@autocut/types/Utterance';
import { autocutApi } from '../http.utils';
import { BRoll } from '@autocut/types/BRolls';

export const getPexelVideos = async (parameters: {
  transcript: Utterance[];
  minDuration: number;
  maxDuration: number;
  maxWordsPerChunk?: number;
  resolution: {
    width: number;
    height: number;
  };
}) => {
  const { data } = await autocutApi.post('deepgram/b-rolls', {
    transcript: parameters.transcript,
    minDuration: parameters.minDuration,
    maxDuration: parameters.maxDuration,
    resolution: parameters.resolution,
    maxWordsPerChunk: parameters.maxWordsPerChunk,
  });

  return data as BRoll[];
};

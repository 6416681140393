import { evalTS } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { logger } from '../logger';
import { setAutocutStore } from '../zustand';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('checkForSavedBackup', level, message, objects);
};

export const checkForSavedBackup = async () => {
  const res = await evalTS('isThereBackup');
  if (res) {
    logMessage(logLevel.debug, 'Old backup found.', { res });
    setAutocutStore('backup.lastId', res);
    setAutocutStore('backup.isRestorable', true);
  } else {
    logMessage(logLevel.debug, 'No backup found.');
    setAutocutStore('backup.isRestorable', false);
  }
};

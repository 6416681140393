import * as React from 'react';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import LogoUrl from '@autocut/assets/images/logo.svg';
const Logo = new URL(LogoUrl, import.meta.url).href;
import { Button, LoaderInfinity } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLocale } from '@autocut/hooks/useLocale';
import { useLogger } from '@autocut/hooks/useLogger';
import { onboardingLocalStorageKeys } from '@autocut/onboarding/onboardingLocalStorageKeys.enum';
import logLevel from '@autocut/types/logLevel.enum';
import { isFFMPEGUsable } from '@autocut/utils/ffmpeg/ffmpeg.utils';
import {
  getSeenTrialPromo,
  setLocalStorage,
  setSeenTrialPromo,
  startTrialPromo,
} from '@autocut/utils/localStorage.utils';
import { TourContainer } from '@autocut/components/containers/TourContainer';
import { onboardingSteps } from '@autocut/onboarding/steps/onboarding.steps';
import { sendStats } from '@autocut/utils';
import { StatType } from '@autocut/types/StatType.enum';
import { StatCategory } from '@autocut/types/StatCategory.enum';
import { enableBodyScroll } from 'body-scroll-lock';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { loginFromLocalStorage } from '@autocut/utils/license/login';

import css from './Onboarding.module.css';
import { IoCartOutline } from 'react-icons/io5';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export const Onboarding = () => {
  return (
    <TourContainer
      steps={onboardingSteps}
      afterOpen={async () => {
        await sendStats({
          type: StatType.ON_BOARDING_STARTED,
          value: 1,
          traits: {
            page: 'onboarding',
          },
        });
      }}
      beforeClose={() => {
        console.log('Onboarding tour closed');
        setLocalStorage(onboardingLocalStorageKeys.Onboarding, true);
        enableBodyScroll(document as unknown as HTMLElement);
      }}
    >
      <OnboardingContent />
    </TourContainer>
  );
};

export const OnboardingContent = () => {
  const navigate = useNavigate();
  const locale = useLocale();

  const [isLogged, license, key] = useAutoCutStore(state => [
    state.user.isLogged,
    state.user.license,
    state.user.key,
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { logMessage } = useLogger('Onboarding');

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!autocutStoreVanilla().user.isLogged) await loginFromLocalStorage();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // const { setIsOpen: setIsTourOpen } = useTour();
  // useEffect(() => {
  //   if (!isLoading && !getLocalStorage(onboardingLocalStorageKeys.Onboarding)) {
  //     setTimeout(() => {
  //       setIsTourOpen(true);
  //     }, 1000);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    const startupCheck = async () => {
      logMessage(logLevel.notice, 'Check for FFMPEG.');
      if (redirectIfFFMPEGUnusable()) {
        logMessage(logLevel.notice, 'FFMPEG available, check if key valid.');

        if (isLogged) {
          if (license && license.type === 'trial') {
            if (
              !getSeenTrialPromo() &&
              license.left &&
              license.left >= 3 &&
              license.left <= 12
            ) {
              setSeenTrialPromo(true);
              startTrialPromo();
            }
          }

          navigate('/homepage');
        }
      }
      if (!isLogged && !key) {
        setIsLoading(false);
      }
    };
    if (isLogged === undefined) return;

    void startupCheck();
  }, [isLogged, license, key]);

  const redirectIfFFMPEGUnusable = (): boolean => {
    if (!isFFMPEGUsable()) {
      logMessage(logLevel.error, 'FFMPEG is not available.');
      navigate('/no-ffmpeg');
      return false;
    }
    return true;
  };

  setTimeout(async () => {
    if (isLoading) {
      setIsLoading(false);
      if (!autocutStoreVanilla().user.isLogged) await loginFromLocalStorage();
    }
  }, 10000);

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      id="onboarding-step1"
      flex="1"
      className={css.container}
      gap={48}
    >
      {isLoading ? (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{ width: '100%' }}
        >
          <LoaderInfinity height={300} />
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            className={css.header}
            flexDirection="column"
            alignItems="center"
          >
            <h1 className={css.title}>
              <TranslatedMessage
                id="onboarding_title"
                defaultMessage="Welcome on AutoCut"
              />
            </h1>
            <p className={css.subtitle}>
              <TranslatedMessage
                id="onboarding_subtitle"
                defaultMessage="Your ultimate Premiere Pro extension"
              />
            </p>
            <img className={css.logo} src={Logo} alt="" />
          </FlexContainer>
          <FlexContainer
            className={css.buttonContainer}
            flexDirection="column"
            gap={16}
          >
            <Button
              id="onboarding-step2"
              onClickFunction={() => navigate('trial')}
              size={'100%'}
              buttonType="primary"
              weight="bold"
            >
              <TranslatedMessage
                id="text_Welcome_freeTrial"
                defaultMessage="Get a free trial"
              />
            </Button>
            <div className={css.glassButtonContainer}>
              <Button
                id="onboarding-step3"
                onClickFunction={() => navigate('login')}
                size={'100%'}
                buttonType="glass"
                weight="bold"
              >
                <TranslatedMessage
                  id="text_Welcome_UseLicence"
                  defaultMessage="Use a licence key"
                />
              </Button>
              <div className={css.blobContainer}>
                <div className={css.blob} />
              </div>
            </div>

            <div className={css.glassButtonContainer}>
              <Button
                id="onboarding-step4"
                onClickFunction={() => {
                  logMessage(
                    logLevel.info,
                    "Open AutoCut's prices website page."
                  );
                  openLinkInBrowser(
                    locale.locale === 'FR'
                      ? `${AUTOCUT_CONSTANTS[CURRENT_ENV].WEBSITE_ADDRESS}/fr/#pricing`
                      : `${AUTOCUT_CONSTANTS[CURRENT_ENV].WEBSITE_ADDRESS}/#pricing`,
                    true
                  );
                }}
                size={'100%'}
                buttonType="glass"
                weight="bold"
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="center"
                  gap={8}
                  className={css.buyButton}
                >
                  <IoCartOutline size={18} />
                  <TranslatedMessage
                    id="text_Welcome_BuyLicence"
                    defaultMessage="Buy a license key"
                  />
                </FlexContainer>
              </Button>
              <div className={css.blobContainer}>
                <div className={css.blob} />
              </div>
            </div>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

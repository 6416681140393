import {
  Select,
  SelectOption,
} from '@autocut/designSystem/components/atoms/Select/Select';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, { useState } from 'react';
import {
  DISCOVER_OPTIONS,
  JOBSTATUS_OPTIONS,
  TYPEOFCREATION_OPTIONS,
} from './utils';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { BadgeSelector } from '@autocut/designSystem/components/molecules/BadgeSelector/BadgeSelector';

type OnboardingDiscoverFormProps = {
  onChange: (
    discover: string | null,
    jobStatus: string | null,
    typeOfCreation: string[] | null
  ) => void;
};

export const OnboardingDiscoverForm = ({
  onChange,
}: OnboardingDiscoverFormProps) => {
  const [discover, setDiscover] = useState<SelectOption | undefined>(undefined);
  const [jobStatus, setJobStatus] = useState<SelectOption | undefined>(
    undefined
  );
  const [typeOfCreation, setTypeOfCreation] = useState<string[]>([]);
  return (
    <FlexContainer flexDirection="column" gap={8} alignItems="flex-start">
      <Select
        options={DISCOVER_OPTIONS.map(option => ({
          value: option,
          valueInDropdown: (
            <TranslatedMessage
              id={`discover-option-${option}`}
              defaultMessage={option}
            />
          ),
        }))}
        fullWidth
        onChange={value => {
          setDiscover(value);
          onChange(
            value.value || null,
            jobStatus?.value || null,
            typeOfCreation
          );
        }}
        selected={discover}
      />
      <Select
        options={JOBSTATUS_OPTIONS.map(option => ({
          value: option,
          valueInDropdown: (
            <TranslatedMessage
              id={`jobstatus_option_${option}`}
              defaultMessage={option}
            />
          ),
        }))}
        fullWidth
        onChange={value => {
          setJobStatus(value);
          onChange(
            discover?.value || null,
            value.value || null,
            typeOfCreation
          );
        }}
        selected={jobStatus}
      />

      <BadgeSelector
        allowMultiple={true}
        selected={typeOfCreation}
        onChange={value => {
          setTypeOfCreation(value);
          onChange(
            discover?.value || null,
            jobStatus?.value || null,
            value || null
          );
        }}
        options={TYPEOFCREATION_OPTIONS.map(option => ({
          value: option,
          label: (
            <TranslatedMessage
              id={`type-of-creation-option-${option}`}
              defaultMessage={option}
            />
          ),
        }))}
      />
    </FlexContainer>
  );
};

import React, { forwardRef } from 'react';

import css from './VideoContainer.module.css';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { BRoll } from '@autocut/types/BRolls';
import { FaRegTrashAlt } from 'react-icons/fa';
import { LuPencil } from 'react-icons/lu';
import { PexelPreview, PexelVideoRef } from '../../PexelPreview/PexelPreview';
import { modifyVideoSize } from '@autocut/utils/bRolls/modifyVideoSize';

export type VideoContainerProps = {
  video: BRoll['video'];
  onDelete: () => void;
  onClick: () => void;
};

export const VideoContainer = forwardRef<PexelVideoRef, VideoContainerProps>(
  ({ video, onDelete, onClick }, videoRef) => {
    return (
      <div className={css.container}>
        {video ? (
          <div key={video.id} className={css.videoContainer}>
            <PexelPreview
              ref={videoRef}
              videoSrc={modifyVideoSize(video?.downloadUrl as string, 100, 57)}
              picture={video.previewPictures[0].picture}
            />
            <FlexContainer
              className={css.videoOverlay}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <LuPencil onClick={onClick} size={16} color="white" />
              <FaRegTrashAlt onClick={onDelete} size={16} color="white" />
            </FlexContainer>
          </div>
        ) : (
          <FlexContainer
            className={css.noVideoInnerContainer}
            justifyContent="center"
            alignItems="center"
            onClick={onClick}
          >
            +
          </FlexContainer>
        )}
      </div>
    );
  }
);

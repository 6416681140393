import { evalTS } from '@autocut/lib/utils/bolt';

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getCursorTime = async (): Promise<
  | {
      secs: number;
      timecode: string;
      ticks: string;
      frames: number;
    }
  | undefined
> => {
  const qeActiveSequence = await evalTS('getQEActiveSequence');

  return qeActiveSequence?.CTI;
};

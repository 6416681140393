import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { CaptionDrawHook } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import {
  generateCanvasFontParam,
  measureTextOnCanvas,
} from '@autocut/utils/captions/canvas/canvas.utils';

export const useDrawEmoji: CaptionDrawHook = ({ yFactor }) => {
  const params = useAutoCutStore(state => state.ui.parameters.caption);
  const text = '😊';
  const fontSize = params.text.fontSize / yFactor;
  const fontFamily = params.text.font.fontFamily;
  const italic = params.formating.italic;

  const { metrics: emojiMetrics } = measureTextOnCanvas(
    text,
    {
      fontSize,
      fontFamily,
      italic,
    },
    false
  );

  const fontParam = generateCanvasFontParam({
    italic,
    fontSize,
    fontFamily,
  });

  const draw = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
    ctx.font = fontParam;
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';
    ctx.shadowColor = 'transparent';
    ctx.fillText(text, x, y + fontSize / 10 - emojiMetrics.width / 2);
  };

  return {
    draw,
    objectMetrics: {
      width: emojiMetrics.width,
      height: emojiMetrics.width,
    },
  };
};

import { decodeCaptionParams } from '@autocut/modes/captions/utils/presets.captions.utils';
import { autocutApi } from '../http.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import { AutoCutApiError } from '../errors/AutoCutApiError';

export const copyPresetFromPresetId = async (presetId: string) => {
  const res = await autocutApi
    .post(`captions-presets/copy-from-preset-id/${presetId}`)
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'createCaptionsPreset'),
      });

      return null;
    });

  if (res?.status === 201) {
    const captionsPreset: CaptionsPreset[] = res.data.map(
      ({
        id,
        title,
        params,
        videoUrl,
        thumbnailUrl,
      }: {
        id: string;
        title: string;
        params: string;
        videoUrl: string;
        thumbnailUrl: string;
      }) => {
        const decodedParams = decodeCaptionParams(params);
        return { id, title, params: decodedParams, videoUrl, thumbnailUrl };
      }
    );

    return captionsPreset;
  }

  return null;
};

import { ExportedAudioWithSilences } from '@autocut/types/ExportedAudioWithSilences';
import { SilenceParams } from '@autocut/types/SilenceParams';
import { StatType } from '@autocut/types/StatType.enum';
import logLevel from '@autocut/types/logLevel.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { IntlShape } from 'react-intl';
import {
  getAiTimelineBasedSilences,
  getLegacyTimelineBasedSilences,
  processRawTimelineBasedSilences,
} from '../cut';
import { changeMessage } from '../cutButton/changeMessage';
import { handleFinalMessage } from '../cutButton/handleFinalMessage';
import { getAllClipsDescriptor } from '../export/descriptors';
import { getExportedAudioInfos } from '../export/getExportedAudioInfos';
import { logger } from '../logger';
import { getParametersForMode } from '../parameters.utils';
import { sendStats } from '../stats.util';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('getSilences', level, message, objects);
};

const getSilencesBase = async (
  intl: IntlShape,
  getModeTimelineBasedSilences: (
    exportedAudio: ExportedAudioWithSilences
  ) => Promise<{
    timelineBasedSilences: number[][];
  }>,
  forPreview = false
) => {
  try {
    const autocutStates = autocutStoreVanilla();
    const selectionInfos = autocutStates.sequence.infos;

    logMessage(logLevel.notice, 'Exporting audio...');

    const exportedAudioInfos = await getExportedAudioInfos(
      getAllClipsDescriptor(),
      intl
    );

    if (!exportedAudioInfos || !selectionInfos)
      return {
        cutTimecodes: undefined,
        timelineBasedSilencesTotal: undefined,
      };

    const clips = exportedAudioInfos.sequence.audioTracks.flatMap(
      (track: any) => track.clips
    );

    changeMessage(intl, 'button_SearchingSilences', 'Searching for silences…');
    logMessage(logLevel.notice, 'Searching for silences...');

    for (const clip of clips) {
      logMessage(
        logLevel.notice,
        `Getting timeline based silences for track: ${clip.trackIndex}, clip: ${clip.indexInTrack}`
      );

      try {
        const { timelineBasedSilences } = await getModeTimelineBasedSilences(
          clip
        );
        clip.timelineBasedSilences = timelineBasedSilences;
      } catch (error: any) {
        throw new IncrementalError(error, 'getSilencesBase');
      }
    }

    const parameters = getParametersForMode();

    const timelineBasedSilencesTotal = processRawTimelineBasedSilences(
      clips || [],
      autocutStates.sequence.infos?.audioTracks || [],
      parameters as SilenceParams,
      forPreview
    );

    logMessage(
      logLevel.notice,
      'Getting timeline based silences for whole timeline (mutliple clips)',
      { timelineBasedSilencesTotal }
    );

    if (timelineBasedSilencesTotal.length === 0) {
      const error = 'No silences found';
      logMessage(logLevel.warn, error);
      handleFinalMessage(intl, 'button_NoSilences', error);
      await sendStats({
        type: StatType.ERROR,
        value: 1,
        traits: {
          type: 'NO_SILENCES',
          mode: autocutStates.ui.process.mode.statCategory,
          fatal: 0,
        },
      });

      return {
        cutTimecodes: [],
        timelineBasedSilencesTotal,
      };
    }

    logMessage(logLevel.notice, 'Flatting intervals');
    const cutTimecodes = timelineBasedSilencesTotal.flat();

    return { cutTimecodes, timelineBasedSilencesTotal };
  } catch (err: any) {
    throw new IncrementalError(err, 'getSilencesBase');
  }
};

export const getAiSilences = (intl: IntlShape, forPreview = false) => {
  return getSilencesBase(
    intl,
    (exportedAudio: ExportedAudioWithSilences) =>
      getAiTimelineBasedSilences(exportedAudio),
    forPreview
  );
};
export const getLegacySilences = (intl: IntlShape, forPreview = false) => {
  return getSilencesBase(
    intl,
    (exportedAudio: ExportedAudioWithSilences) =>
      getLegacyTimelineBasedSilences(exportedAudio),
    forPreview
  );
};

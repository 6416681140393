import React, { Suspense, useEffect } from 'react';

import Modal, { ModalProps } from '@autocut/layouts/ModalLayout/BaseModal';

import css from './EndProcessModal.module.css';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { Button } from '@autocut/components/atoms';
import logLevel from '@autocut/types/logLevel.enum';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useLogger } from '@autocut/hooks/useLogger';
import ConfettiBackground from '@autocut/components/atoms/ConfettiBackground/ConfettiBackground';
import { closeModal } from '@autocut/utils/modal/closeModal';
import { ModalType } from '@autocut/enums/modals.enum';
import { useNavigate } from 'react-router-dom';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { MdSettingsBackupRestore } from 'react-icons/md';
import { handleRestore } from '@autocut/utils/backup/handleRestore';
import { sendStats } from '@autocut/utils';
import { StatType } from '@autocut/types/StatType.enum';
import { AutocutModeIds, getModeById } from '@autocut/enums/modes.enum';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';

const defaultEndProcessConfig = {
  goToNextMode: 'silence',
  restoreSequence: true,
  rateAutoCut: true,
  timeTaken: true,
  timeSaved: true,
};

export const EndProcessModal = ({
  children,
  ...props
}: Partial<ModalProps>) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { logMessage } = useLogger('EndprocessModal');

  const {
    mode,
    durationProcessMinutes: minutesTaken,
    durationProcessSeconds: secondsTaken,
    xpGained,
    currentProgress,
    license,
    displayTrialTaskModal,
  } = useAutoCutStore(state => ({
    mode: state.ui.process.mode,
    durationProcessMinutes: state.ui.process.durationProcessMinutes,
    durationProcessSeconds: state.ui.process.durationProcessSeconds,
    xpGained: state.game.level.xpGained,
    currentProgress: state.game.level.currentProgress,
    license: state.user.license,
    displayTrialTaskModal: state.game.trial.displayModal,
  }));

  const minutesSaved = Math.floor((xpGained * 60) / 100);
  const secondsSaved = Math.floor(((xpGained * 60) / 100 - minutesSaved) * 60);

  const displayNextMode =
    mode.endProcessConfig?.goToNextMode === false ||
    (typeof mode.endProcessConfig?.goToNextMode === 'string' &&
      !license?.features?.includes(
        mode.endProcessConfig.goToNextMode as AutocutModeIds
      ))
      ? false
      : mode.endProcessConfig.goToNextMode;

  const displayRestoreSequence =
    typeof mode.endProcessConfig?.restoreSequence === 'boolean'
      ? mode.endProcessConfig.restoreSequence
      : defaultEndProcessConfig.restoreSequence;

  const displayRateAutoCut =
    typeof mode.endProcessConfig?.rateAutoCut === 'boolean'
      ? mode.endProcessConfig.rateAutoCut
      : defaultEndProcessConfig.rateAutoCut;

  const displayTimeTaken =
    typeof mode.endProcessConfig?.timeTaken === 'boolean'
      ? mode.endProcessConfig.timeTaken
      : defaultEndProcessConfig.timeTaken;

  const displayTimeSaved =
    typeof mode.endProcessConfig?.timeSaved === 'boolean'
      ? mode.endProcessConfig.timeSaved
      : defaultEndProcessConfig.timeSaved;

  useEffect(() => {
    logMessage(logLevel.info, 'Process ended. Redirecting to homepage.');
    navigate('/homepage');
  }, []);

  const handleUndo = async () => {
    await handleRestore();

    await sendStats({
      type: StatType.UNDO_PROCESS,
      value: 1,
      traits: { mode: mode.statCategory },
    });

    setAutocutStore('ui.process.isPaused', false);
    closeModal();
  };

  const handleNextMode = () => {
    navigate(
      getModeById(displayNextMode as AutocutModeIds)?.path || '/homepage'
    );

    setAutocutStore('ui.process.isPaused', false);
    closeModal();
  };

  return (
    <Modal
      fullWidth
      title={intl.formatMessage({
        id: 'endprocess_modal_title',
        defaultMessage: 'Process completed!',
      })}
      icon={
        <div className={css.iconContainer}>
          <IoCheckmarkSharp color="white" strokeWidth={2} />
        </div>
      }
      closeModalFunction={
        displayTrialTaskModal
          ? () => setAutocutStore('ui.openedModalName', ModalType.GamingTrial)
          : closeModal
      }
      extraChildren={
        <Suspense fallback={<div />}>
          <ConfettiBackground />
        </Suspense>
      }
      footer={
        <FlexContainer
          className={css.footerContainer}
          justifyContent="space-between"
          gap="8px"
          style={{ width: '100%' }}
        >
          {displayRestoreSequence && (
            <Button
              buttonType="secondary"
              size="md"
              onClickFunction={async () => {
                await handleUndo();
              }}
            >
              <FlexContainer alignItems="center" justifyContent="center">
                <MdSettingsBackupRestore size={25} color="#fff" />
                <span className="cancel-modal-button-text">
                  <TranslatedMessage
                    id="button_undo_modal"
                    defaultMessage="Undo Cuts"
                  />
                </span>
              </FlexContainer>
            </Button>
          )}
          {displayNextMode && (
            <Button
              onClickFunction={handleNextMode}
              buttonType="primary"
              size="md"
            >
              <TranslatedMessage
                id={`endprocess_modal_next_mode_${displayNextMode}_button`}
                defaultMessage="endprocess_modal_next_mode_silence_button"
              />
            </Button>
          )}
        </FlexContainer>
      }
      {...props}
    >
      <FlexContainer flexDirection="column" alignItems="flex-start" gap="12px">
        {(displayTimeSaved || displayTimeTaken) && (
          <div className={css.timeInfos}>
            {displayTimeTaken && (
              <p>
                <TranslatedMessage
                  id="stat_undo_modal"
                  defaultMessage="Process completed in {minutes} minutes and {seconds} seconds !"
                  values={{
                    minutes: minutesTaken,
                    seconds: secondsTaken,
                  }}
                />
              </p>
            )}
            {displayTimeSaved && (
              <>
                <p>
                  <TranslatedMessage
                    id="cancelCutModal_timesaved_this_cut"
                    defaultMessage="You saved: <b>{minutesSaved}</b> minute(s) and <b>{secondsSaved}</b> second(s)"
                    values={{
                      minutesSaved,
                      secondsSaved,
                    }}
                  />
                </p>
                <p>
                  <TranslatedMessage
                    id="cancelCutModal_timesaved_all_cuts"
                    defaultMessage="Since using AutoCut, you saved : {hourSaved} hour(s) and {minutesSaved} minute(s) ! 🎉"
                    values={{
                      hourSaved: currentProgress?.level,
                      minutesSaved: ~~((currentProgress.xp * 60) / 100),
                    }}
                  />
                </p>
              </>
            )}
          </div>
        )}
        {children}
        {displayRateAutoCut && (
          <FlexContainer flexDirection="column">
            <p>
              <TranslatedMessage
                id="endprocess_modal_text_2"
                defaultMessage="Feel free to give us feedback on the quality of the result:"
              />
            </p>
            <Button
              buttonType="tertiary"
              compact
              onClickFunction={() => {
                logMessage(logLevel.info, 'Open Senja in browser');
                openLinkInBrowser('https://senja.io/p/autocut/r/ikmgDE');
              }}
            >
              <TranslatedMessage
                id="star_review_title"
                defaultMessage="Grade AutoCut !"
              />
            </Button>
          </FlexContainer>
        )}

        {displayNextMode && (
          <p>
            <TranslatedMessage
              id={`endprocess_modal_next_mode_${displayNextMode}_text`}
              defaultMessage="If you want, you can now use AutoCut Silences for automatically removing silences."
            />
          </p>
        )}
      </FlexContainer>
    </Modal>
  );
};

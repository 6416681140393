import * as React from 'react';
import { useMemo, useState } from 'react';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';

import selectCustomStyle, {
  SELECT_COLORS,
} from '@autocut/components/atoms/CustomSelectText/SelectStyle';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { Speaker } from '@autocut/types/Speaker';
import logLevel from '@autocut/types/logLevel.enum';
import { debounce } from '@autocut/utils/debounce';
import { removeSpeakerReducer } from '@autocut/utils/speakers/removeSpeakerReducer';
import { updateSpeakerReducer } from '@autocut/utils/speakers/updateSpeakerReducer';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import css from './speaker.module.css';

import './speaker.css';

export type SpeakerProps = {
  speaker: Speaker;
  index: number;
};

export const SpeakerComponent = ({
  speaker: currentSpeaker,
  index: indexSpeaker,
}: SpeakerProps) => {
  const intl = useIntl();
  const { logMessage } = useLogger('SpeakerComponent');
  const { audioTracks } = useAutoCutStore(state => ({
    audioTracks: state.sequence.infos?.audioTracks,
  }));

  const availableAudioTracks = useMemo(() => {
    const availableTracks = audioTracks
      ?.filter(audioTrack => audioTrack.nbClips > 0)
      .map((audioTrack: any) => {
        const name = `${intl.formatMessage({
          id: 'text_podcast_track',
          defaultMessage: 'Track',
        })} A${audioTrack.index + 1}`;
        return {
          label: name,
          value: String(audioTrack.index),
        };
      });

    return availableTracks;
  }, [audioTracks]);

  const [name, setName] = useState(currentSpeaker.name);
  const audio = currentSpeaker.audio;

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);

    void debounce(() => {
      logMessage(logLevel.notice, 'Changing speaker name', {
        [currentSpeaker.id]: value,
      });
      setAutocutStore(
        'ui.parameters.podcast.speakers',
        updateSpeakerReducer(currentSpeaker.id, { name: value })
      );
    }, 500)();
  };

  return (
    <div
      id="podcast-step3"
      className={css.container}
      style={{
        borderColor:
          SELECT_COLORS[
            indexSpeaker >= SELECT_COLORS.length
              ? SELECT_COLORS.length - 1
              : indexSpeaker
          ],
      }}
    >
      <div className={css.left}>
        <AiOutlineCloseCircle
          className={css.remove}
          onClick={() => {
            logMessage(logLevel.notice, 'Removing speaker', {
              [currentSpeaker.id]: currentSpeaker,
            });
            setAutocutStore(
              'ui.parameters.podcast.speakers',
              removeSpeakerReducer(currentSpeaker.id)
            );
          }}
          size={16}
          color="white"
        />
        <BsFillPersonFill size={16} />
        <input
          id="podcast-step4"
          type="text"
          className={css.name}
          value={name}
          placeholder={intl.formatMessage({
            id: 'input_speaker_name_placeholder',
            defaultMessage: "Enter speaker's name…",
          })}
          onChange={handleNameChange}
        />
      </div>
      <div className={css.tracks}>
        <Select
          classNamePrefix="audioTrack"
          className={audio.label === '' ? 'default' : ''}
          styles={selectCustomStyle('trackSelection')}
          options={availableAudioTracks || []}
          defaultValue={
            audio.label === ''
              ? {
                  label: `${intl.formatMessage({
                    id: 'text_podcast_track',
                    defaultMessage: 'Track',
                  })} AX`,
                  value: '',
                }
              : (audio as unknown as { label: string; value: string })
          }
          onChange={value => {
            logMessage(logLevel.notice, 'Setting speaker audio track.', {
              [currentSpeaker.id]: value,
            });

            if (!value) return;

            setAutocutStore(
              'ui.parameters.podcast.speakers',
              updateSpeakerReducer(currentSpeaker.id, {
                audio: value,
              })
            );
          }}
        />
      </div>
    </div>
  );
};

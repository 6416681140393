/**
 * Returns the indexes of the subclips to delete after cut process.
 * /!\ The indexes are relative to the clip itself, not the timeline.
 **/
export const getSubclipIndexes = (clip: Clip, silenceIntervals: number[][]) => {
  const framerate = clip.frameRate;

  const clipStart = clip.start;
  const clipEnd = clip.end;

  const clipStartFrame = Math.floor(clipStart * framerate);
  const clipEndFrame = Math.floor(clipEnd * framerate);

  let isSilenceFirst = false;
  let isSilenceLast = false;
  const validTimecodes = silenceIntervals.flatMap(interval => {
    const intervalInFrame = interval.map(timecode =>
      Math.floor(timecode * framerate)
    );

    if (
      intervalInFrame[1] > clipStartFrame &&
      intervalInFrame[0] <= clipStartFrame
    ) {
      isSilenceFirst = true;
    }
    if (
      intervalInFrame[1] >= clipEndFrame &&
      intervalInFrame[0] < clipEndFrame
    ) {
      isSilenceLast = true;
    }
    return interval.filter(
      timecode =>
        Math.floor(timecode * framerate) > clipStartFrame &&
        Math.floor(timecode * framerate) < clipEndFrame // We remove from intervals all timecodes outside the clip
    );
  });
  const nbCutsOnClip = validTimecodes.length;
  const nbClipAfterCuts = nbCutsOnClip + 1;

  const subclipIndexesToDelete = Array.from(
    {
      length:
        Math.floor(nbClipAfterCuts / 2) +
        (isSilenceLast && isSilenceFirst ? 1 : 0),
    },
    (_, i) => 2 * i + (isSilenceFirst ? 0 : 1)
  );
  const subclipIndexes = Array.from({ length: nbClipAfterCuts }, (_, i) => i);

  return { nbCutsOnClip, subclipIndexesToDelete, subclipIndexes };
};

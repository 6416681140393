import { IntlShape } from 'react-intl';
import { evalTS } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { changeMessage } from '../cutButton/changeMessage';
import { logger } from '../logger';
import { setAutocutStore } from '../zustand';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleBackup', level, message, objects);
};

export const handleBackup = async (intl: IntlShape) => {
  try {
    logMessage(logLevel.notice, 'Backing up project before cutting');
    changeMessage(intl, 'button_SavingBackup', 'Backing up your project…');

    setAutocutStore('backup.isRestorable', false);
    const res: string = await evalTS('saveBackup');
    setAutocutStore('backup.lastId', res);
    setAutocutStore('backup.isRestorable', true);
  } catch (err: any) {
    throw new IncrementalError(err, 'handleBackup');
  }
};

import { OS_MAC } from '@autocut/constants/constants';
import { exec } from './exec.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';

export const unzip = async (fileName: string, outputDir: string) => {
  const os = getOS();

  const command =
    os === OS_MAC
      ? `unzip -o "${fileName}" -d "${outputDir}"`
      : `tar -xf "${fileName}" -C "${outputDir}"`;

  await exec({
    command,
  });
};

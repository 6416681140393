import { SuccessLoader } from '@autocut/components/atoms/SuccessLoader/SuccessLoader';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, { useEffect, useState } from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useNavigate } from 'react-router-dom';

import css from './SendingEmail.module.css';

export type SendingEmailProps = {
  email: string;
};

export const SendingEmail = ({ email }: SendingEmailProps) => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(true);
    }, 2000);
  }, []);

  const onSuccess = () => {
    setTimeout(() => {
      navigate('../login');
    }, 3000);
  };

  return (
    <FlexContainer
      className={css.container}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={16}
    >
      <SuccessLoader isSuccess={success} onSuccess={onSuccess} />
      {success && (
        <TranslatedMessage
          id="trial_sending_email_success"
          defaultMessage="Email sent successfully to {email}"
          values={{
            email: email,
          }}
        />
      )}
    </FlexContainer>
  );
};

import { decodeCaptionParams } from '@autocut/modes/captions/utils/presets.captions.utils';
import { autocutApi } from '../http.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import { AutoCutApiError } from '../errors/AutoCutApiError';

export const removeCaptionsPresetFromKey = async (
  captionsPresetId: CaptionsPreset['id']
) => {
  const res = await autocutApi
    .delete(`/captions-presets/from-key`, {
      data: {
        captionsPresetId,
      },
    })
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'createCaptionsPreset'),
      });

      return null;
    });

  if (res?.status === 200) {
    const captionsPresets: CaptionsPreset[] = res.data.map(
      ({
        id,
        title,
        params,
        videoUrl,
        thumbnailUrl,
      }: Omit<CaptionsPreset, 'params'> & { params: string }) => {
        const decodedParams = decodeCaptionParams(params);

        return { id, title, params: decodedParams, videoUrl, thumbnailUrl };
      }
    );

    return captionsPresets;
  }

  return null;
};

import { CustomSelectText } from '@autocut/components/atoms/CustomSelectText/CustomSelectText';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const REPEAT_ALGORITHM = {
  FAST: 'FAST',
  PRECISE: 'PRECISE',
};

export const AlgorithmSelectStep = () => {
  const intl = useIntl();
  const algorithms = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'repeat_precise_algorithm',
          defaultMessage: 'Precise',
        }),
        value: REPEAT_ALGORITHM.PRECISE,
      },
      {
        label: intl.formatMessage({
          id: 'repeat_fast_algorithm',
          defaultMessage: 'Fast',
        }),
        value: REPEAT_ALGORITHM.FAST,
      },
    ],
    [intl]
  );
  const { selectedAlgorithm } = useAutoCutStore(
    state => state.ui.parameters.repeat
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'repeat_select_algorithm',
        defaultMessage: 'Select algorithm',
      })}
      alwaysOpen
    >
      <CustomSelectText
        options={algorithms}
        handleSelectChange={option => {
          setAutocutStore(
            'ui.parameters.repeat.selectedAlgorithm',
            option?.value as string
          );
        }}
        initialValue={algorithms.find(
          algorithm => algorithm.value === selectedAlgorithm
        )}
      />
      <div style={{ marginTop: 16 }}>
        <InfoText style="info">
          <TranslatedMessage
            id="repeat_precise_algorithm_info"
            defaultMessage={
              'The precise algorithm is our most recent and efficient one but require approximately 1 minute of processing per 6 minutes of video.'
            }
          />
        </InfoText>
      </div>
    </StepLayout>
  );
};

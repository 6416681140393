import { inCEPEnvironment } from '@autocut/lib/utils/bolt';
import { setAutocutStore } from '../zustand';

export const updateIsInCEPEnv = async () => {
  if (!inCEPEnvironment()) {
    setTimeout(async () => {
      await updateIsInCEPEnv();
    }, 100);
  }

  setAutocutStore('ppro.isInCEPEnv', true);
};

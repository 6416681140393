import { Author } from '@autocut/components/atoms/Author/Author';
import { EAuthors } from '@autocut/components/atoms/Author/utils';
import Stepper, {
  StepperStep,
} from '@autocut/components/atoms/Stepper/Stepper';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { colors } from '@autocut/designSystem/colors';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal, { ModalProps } from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';

export type StepperModalProps<T> = {
  title: React.ReactNode;
  icon?: React.ReactNode;
  steps: (StepperStep<T> & {
    author: EAuthors;
    description: React.ReactNode;
    body?: React.ReactNode;
    cta?: (nextStep: () => void) => React.ReactNode;
  })[];
  lastStepCta?: React.ReactNode;
  opened: boolean;
  onClose: () => void;
} & Partial<ModalProps>;

export const StepperModal = <T,>({
  title,
  icon,
  steps,
  opened,
  onClose,
  ...props
}: StepperModalProps<T>) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  if (!opened) return null;

  const isLastStep = activeIndex === steps.length - 1;
  const activeStep = steps[activeIndex];

  const nextStep = isLastStep ? onClose : () => setActiveIndex(activeIndex + 1);
  return (
    <Modal
      title={title}
      icon={icon}
      footer={
        activeStep.cta?.(nextStep) || (
          <Button onClick={nextStep} color={colors.primary600}>
            {isLastStep ? (
              <TranslatedMessage id="onboarding_close" defaultMessage="Close" />
            ) : (
              <TranslatedMessage id="onboarding_next" defaultMessage="Next" />
            )}
          </Button>
        )
      }
      closeModalFunction={() => {
        setActiveIndex(0);
        onClose?.();
      }}
      {...props}
    >
      <FlexContainer flexDirection="column" gap={12}>
        {steps.length > 1 && (
          <Stepper steps={steps} activeIndex={activeIndex - 1} />
        )}
        <FormSection title={<Author author={activeStep.author} />}>
          <PaddedSection>{activeStep.description}</PaddedSection>
        </FormSection>

        {activeStep.body}
      </FlexContainer>
    </Modal>
  );
};

import { XMLTrackItem } from '../xml/XMLTrackItem';
import {
  FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION,
  FLOATING_TEXT_EFFECT_CLIP_VIDEO_FILTER_COMPONENTS,
  secondToTickV2,
} from './utils';

export const addFloatingTextEffect = ({
  floatingTextEffectClip,
  captionsSequenceSecondsEnd,
}: {
  floatingTextEffectClip?: XMLTrackItem;
  captionsSequenceSecondsEnd: number;
}) => {
  if (!floatingTextEffectClip) {
    console.error(
      'The floating text effect could not be added as the floatingTextEffectClip was undefined.'
    );
    return false;
  }

  let floatingTextEffectClipSecondsStart =
    FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION;
  let index = 1;
  while (floatingTextEffectClipSecondsStart < captionsSequenceSecondsEnd) {
    let endSeconds =
      floatingTextEffectClipSecondsStart +
      FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION;
    if (endSeconds > captionsSequenceSecondsEnd) {
      endSeconds = captionsSequenceSecondsEnd;
    }

    floatingTextEffectClip.clone({
      startTick: secondToTickV2(floatingTextEffectClipSecondsStart),
      endTick: secondToTickV2(endSeconds),
      copiedVideoFilterComponents:
        FLOATING_TEXT_EFFECT_CLIP_VIDEO_FILTER_COMPONENTS,
      index,
    });

    index++;
    floatingTextEffectClipSecondsStart = endSeconds;
  }

  return true;
};

import { PREVIEW_DURATION } from '@autocut/constants/constants';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { evalTS } from '@autocut/lib/utils/bolt';
import { convertSecondsToHMSString } from '@autocut/utils/date.utils';
import {
  getMinMaxClipsEnd,
  handleGetSilencesPreviewProcess,
} from '@autocut/utils/preview/previewTimeline.utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import ArrowTip from '../ArrowTip';
import { AudioCurve } from '../AudioCurve/AudioCurve';
import { PreviewTimelineLegend } from '../PreviewTimelineElement/PreviewTimelineElement';
import { TextWithHelper } from '../TextWithHelper';
import TimeRuler from '../TimeRuler/TimeRuler';
import css from './PreviewTimeline.module.css';
import {
  ExportResult,
  ExportSequenceValue,
  ExportDescriptorTrackWithClips,
} from '@autocut/types/ExportedAudio';

export type PreviewTimelineProps = {
  modeId?: 'silence' | 'ai';
};

export const isExportedAudioTrackWithClips = (
  exportedAudio?: ExportResult<ExportSequenceValue>
): exportedAudio is ExportResult<{
  audioTracks: ExportDescriptorTrackWithClips[];
}> => {
  if (
    exportedAudio?.sequence &&
    'audioTracks' in exportedAudio.sequence &&
    'clips' in exportedAudio.sequence.audioTracks[0]
  ) {
    return true;
  }

  return false;
};

const PreviewTimeline = ({ modeId = 'silence' }: PreviewTimelineProps) => {
  const intl = useIntl();
  const { selectionInfos, cuttingParameters, exportedAudioInfos } =
    useAutoCutStore(state => ({
      selectionInfos: state.sequence.infos,
      cuttingParameters: state.ui.parameters[modeId],
      exportedAudioInfos: state.sequence.exportedAudioInfos,
    }));

  //We get the mininum start time and maximum end time of the selected clips
  const [minClipsStart, maxClipsEnd] = useMemo(getMinMaxClipsEnd, []);

  const [silences, setSilences] = useState<any[]>([]);

  //Nagivation
  const [previewStart, setPreviewStart] = useState<number>(minClipsStart);
  const previewEnd = Math.min(previewStart + PREVIEW_DURATION, maxClipsEnd);

  //Affichage
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const previewStartDisplay = convertSecondsToHMSString(previewStart, true);
  const previewEndDisplay = convertSecondsToHMSString(previewEnd, true);

  const processSilencesWithLoading = () => {
    // Use of setTimeout to allow for a first render with skeletons
    // (otherwise the screen freeze until processSilences is finished despite the async)
    // see more : https://www.qovery.com/blog/how-to-fix-freeze-between-react-pages-swap
    setIsLoading(true);
    setTimeout(async () => {
      const silences = (await handleGetSilencesPreviewProcess(intl)) || [];

      setSilences(silences);
      setIsLoading(false);
    }, 50);
  };

  useEffect(() => {
    processSilencesWithLoading();
  }, [cuttingParameters, selectionInfos]);

  //Preview navigation functions
  const setPreviewStartAtTime = (offset: number) => {
    const newOffset = Math.max(
      Math.min(offset, maxClipsEnd - PREVIEW_DURATION),
      minClipsStart
    );

    setPreviewStart(newOffset);

    return newOffset;
  };

  const goBackward = () => setPreviewStartAtTime(previewStart - 5);

  const goForward = () => setPreviewStartAtTime(previewStart + 5);

  const centerOnTime = async () => {
    const cursorTime = await evalTS('getCursorTime');

    setPreviewStartAtTime(cursorTime - PREVIEW_DURATION / 2);
  };

  if (!isExportedAudioTrackWithClips(exportedAudioInfos)) {
    throw new Error(
      "Cannot display preview for a sequence that doesn't have audio tracks with clips"
    );
  }

  const clips = exportedAudioInfos.sequence.audioTracks.flatMap(
    track => track.clips
  );

  return (
    <div className={css.container}>
      <div className={css.preview_title}>
        <TextWithHelper
          helperId="helper_Step3_2"
          helperDefault="Preview of saved and deleted pieces. You can move forward or backward with the arrows or center the preview in relation to the position of the time cursor."
        >
          <TranslatedMessage
            id="text_Step3_Previsualisation"
            defaultMessage="Preview"
          />
        </TextWithHelper>
      </div>
      <div className={css.preview_times}>
        <p className={css.startTime}>{previewStartDisplay}</p>
        <p className={css.endTime}>{previewEndDisplay}</p>
      </div>
      <TimeRuler numberValues={10} />
      {selectionInfos?.audioTracks
        .filter(track => track.nbClipsSelected)
        .map(track => (
          <AudioCurve
            key={`${track.index}`}
            track={track}
            previewStart={previewStart}
            previewEnd={previewEnd}
            maxClipsEnd={maxClipsEnd}
            minClipsStart={minClipsStart}
            silencesInterval={silences}
            isLoading={isLoading}
            clips={
              clips
                ? clips
                    .filter(clip => clip.trackIndex === track.index)
                    .sort((a, b) => a.start - b.start)
                : []
            }
            processSilences={processSilencesWithLoading}
          />
        ))}
      <div className={css.inputs}>
        {previewStart > minClipsStart && (
          <div onClick={() => goBackward()}>
            <ArrowTip direction="left" height={14} variant="primary" />
          </div>
        )}
        <div
          className={css.center}
          onClick={
            !isLoading
              ? async () => {
                  await centerOnTime();
                }
              : undefined
          }
        >
          <TranslatedMessage
            id="text_Step3_CenterButton"
            defaultMessage="Center on indicator"
          />
        </div>

        {previewStart + PREVIEW_DURATION < maxClipsEnd && (
          <div onClick={() => goForward()}>
            <ArrowTip direction="right" height={14} variant="primary" />
          </div>
        )}
      </div>
      <PreviewTimelineLegend />
    </div>
  );
};

export default PreviewTimeline;

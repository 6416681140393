import { useEffect } from 'react';

import React from 'react';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';

export const GameProvider = ({ children }: { children: any }) => {
  const { levelupAnimation, levelupAnimationQueued } = useAutoCutStore(
    state => ({
      levelupAnimation: state.game.level.levelupAnimation,
      levelupAnimationQueued: state.game.level.levelupAnimationQueued,
    })
  );

  useEffect(() => {
    if (
      levelupAnimation === undefined &&
      levelupAnimationQueued !== undefined
    ) {
      setAutocutStore('game.level.levelupAnimation', levelupAnimationQueued);
      setAutocutStore('game.level.levelupAnimationQueued', undefined);
    }
  }, [levelupAnimation, levelupAnimationQueued]);

  return <>{children}</>;
};

import React, { ChangeEvent, useState } from 'react';
import { InputVariant } from './variants';

import css from './Input.module.scss';
import { Text } from '../Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';

export type InputProps = {
  variant?: InputVariant;
  className?: string;
  style?: React.CSSProperties;
  unit?: string;
  onChange: (value: string) => void;
} & Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'className' | 'style' | 'onChange'
>;

export const Input = ({
  variant = 'primary',
  className,
  style,
  onChange = (value: string) => console.log(value),
  unit,
  ...props
}: InputProps) => {
  const [value, setValue] = useState(props.value);

  const valueWidth = String(value).length;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={css.container}>
      <input
        data-variant={variant}
        type="text"
        className={`${css.root} ${className}`}
        onChange={handleChange}
        style={{
          ...style,
        }}
        {...props}
      />
      {unit && (
        <Text
          variant="textXs"
          color={colors.gray400}
          className={css.unit}
          style={{ left: `calc(${valueWidth}ch + ${Spacing.s4})` }}
        >
          {unit}
        </Text>
      )}
    </div>
  );
};

import React from 'react';

import css from './EndCaptionsProcessModal.module.css';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { EndProcessModal } from '../EndProcessModal/EndProcessModal';
import { Button } from '@autocut/components/atoms';
import { useNavigate } from 'react-router-dom';
import { processTranscriptFunctions } from '@autocut/hooks/useHandleProcessTranscript';
import { setAutocutStore } from '@autocut/utils/zustand';
import { FormattedMessage, useIntl } from 'react-intl';
import { BiUndo } from 'react-icons/bi';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const EndCaptionsProcessModal = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <EndProcessModal
      title={
        <TranslatedMessage
          id="endprocess_captions_modal_title"
          defaultMessage="Captions added!"
        />
      }
      icon={'🎉'}
      footer={
        <FlexContainer flexDirection="column" gap={4}>
          <p>
            <TranslatedMessage
              id="endprocess_captions_modal_footer"
              defaultMessage="If your subtitle style or transcription is not ok, you can go back to the captions editor."
            />
          </p>
          <Button
            onClickFunction={() => {
              navigate(processTranscriptFunctions.caption.navigateTo);
              setAutocutStore('ui.openedModalName', '');
            }}
            buttonType="secondary"
          >
            <FlexContainer
              flexDirection="row"
              gap={12}
              justifyContent="center"
              alignItems="center"
            >
              <BiUndo size={18} />
              <FormattedMessage
                id="endprocess_captions_modal_button"
                defaultMessage="Back to the transcript editor"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      }
    >
      <div className={css.modal}>
        <FlexContainer flexDirection="column" gap={0}>
          <p>
            <TranslatedMessage
              id="endprocess_captions_modal_text_1"
              defaultMessage="Your animated captions have been added! 🎉"
            />
          </p>
          <p>
            <TranslatedMessage
              id="endprocess_captions_modal_text_2"
              defaultMessage="If you need to modify captions:"
            />
          </p>
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          gap={6}
          style={{
            paddingBlock: 6,
          }}
        >
          <li>
            {intl.formatMessage({
              id: 'endprocess_captions_modal_bullet_point_1',
              defaultMessage:
                'Double click on the subsequence: "Double click to edit"',
            })}
          </li>

          <li>
            {intl.formatMessage({
              id: 'endprocess_captions_modal_bullet_point_2',
              defaultMessage: 'Select the caption you want to edit',
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: 'endprocess_captions_modal_bullet_point_3',
              defaultMessage:
                'Open the essential graphics and click on "edit" tab',
            })}
          </li>
        </FlexContainer>

        <p>
          <TranslatedMessage
            id="endprocess_captions_modal_text_3"
            defaultMessage="There is a <a>detailed tutorial if needed.</a>"
            //VALUE LINK
            values={{
              a: text => (
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    openLinkInBrowser(`https://youtu.be/F8kKRmqqDNw`)
                  }
                >
                  {text}
                </a>
              ),
            }}
          />
        </p>
      </div>
    </EndProcessModal>
  );
};

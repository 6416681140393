import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
export const NoInternetConnexion = () => {
  return (
    <FlexContainer>
      <p>No connexion image</p>
      <p>
        <TranslatedMessage
          id="text_NoInternet"
          defaultMessage="An Internet Connexion is required for Autocut to work"
        />
      </p>
    </FlexContainer>
  );
};

import { autocutStoreVanilla } from '../zustand';

export const getAudioClips = () => {
  const infos = autocutStoreVanilla().sequence.infos;

  if (!infos) return [];

  const audioClips = infos.audioTracks.flatMap(track => track.selectedClips);

  return audioClips.sort((a, b) => a.numTrack - b.numTrack);
};

export const getVideoClips = () => {
  const infos = autocutStoreVanilla().sequence.infos;

  if (!infos) return [];

  const videoClips = infos.videoTracks.flatMap(track => track.selectedClips);

  return videoClips.sort((a, b) => a.numTrack - b.numTrack);
};

export const getAllClips = () => {
  return [...getVideoClips(), ...getAudioClips()];
};

export const getTrackByIndex = (indexTrack: number, tracks: Track[]) => {
  return tracks.find(track => track.index === indexTrack);
};

import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { BRollSizeStep } from '@autocut/components/steps/BRollSizeStep/BRollSizeStep';
import React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useNavigate } from 'react-router-dom';

export const BRollParameters = () => {
  const navigate = useNavigate();

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={(isLoading, isDisabled) => (
            <Button
              disabled={isDisabled}
              onClickFunction={() => navigate('../language')}
            >
              <TranslatedMessage id="broll_button_next" defaultMessage="Next" />
            </Button>
          )}
        />
      }
    >
      <BRollSizeStep />
    </ModeLayout>
  );
};

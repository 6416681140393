import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import React from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useNavigate } from 'react-router-dom';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { ChaptersTranscriptItem } from '@autocut/components/atoms/ChaptersTranscriptItem/ChaptersTranscriptItem';
import { ChaptersParameters } from './components/ChaptersParameters';
import { DownloadButton } from './components/DownloadButton';
import { handleChaptersProcess } from '@autocut/utils/chapters/handleChaptersProcess';

import css from './ChapterEditor.module.scss';
import { setAutocutStore } from '@autocut/utils/zustand';

export const ChaptersEditor = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { chapters } = useAutoCutStore(state => ({
    chapters: state.onGoingProcess.chapters,
  }));

  if (!chapters) {
    navigate('../language');
    return null;
  }

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={(isLoading, isDisabled) => (
            <Button
              disabled={isDisabled}
              onClickFunction={async () => {
                await handleChaptersProcess(intl);
              }}
            >
              <TranslatedMessage id="broll_button_next" defaultMessage="Next" />
            </Button>
          )}
        />
      }
    >
      <FlexContainer flexDirection="column" gap={24} className={css.container}>
        <FlexContainer flexDirection="column" gap={8}>
          <p className={css.title}>
            <TranslatedMessage
              id="chapters_editor_chapters_title"
              defaultMessage="Detected chapters"
            />
          </p>
          <FlexContainer flexDirection="column" gap={4}>
            {chapters.map((chapter, index) => (
              <ChaptersTranscriptItem
                key={'chapter ' + index}
                chapter={chapter}
                onChapterUpdate={chapter => {
                  setAutocutStore(
                    'onGoingProcess.chapters',
                    chapters.map((c, i) => (i === index ? chapter : c))
                  );
                }}
                onChapterDelete={() => {
                  setAutocutStore(
                    'onGoingProcess.chapters',
                    chapters.filter((_, i) => i !== index)
                  );
                }}
              />
            ))}
          </FlexContainer>

          <DownloadButton chapters={chapters} />
        </FlexContainer>

        <FlexContainer flexDirection="column" gap={8}>
          <p className={css.title}>
            <TranslatedMessage
              id="chapters_editor_parameters_title"
              defaultMessage="Chapters parameters"
            />
          </p>

          <ChaptersParameters />
        </FlexContainer>
      </FlexContainer>
    </ModeLayout>
  );
};

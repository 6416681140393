import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React, { useState } from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { CaptionsPresetCard } from '../CaptionsPresetCard/CaptionsPresetCard';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import { PRESETS_PER_LINE } from '../../PresetCustomization';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { removeCaptionsPresetFromKey } from '@autocut/utils/captionsPresets/removeCaptionsPresetFromKey';
import { FaRegTrashCan } from 'react-icons/fa6';
import { PresetPreview } from '../PresetPreview';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';

export const DeletePresetsModal = () => {
  const userPresets = useAutoCutStore(
    state => state.ui.parameters.caption.userPresets
  ) as CaptionsPreset[];

  const [selectedPreset, setSelectedPreset] = useState<CaptionsPreset | null>(
    null
  );

  const handleRemove = async (preset: CaptionsPreset) => {
    const newCaptionsPresets = await removeCaptionsPresetFromKey(preset.id);
    if (newCaptionsPresets === null) {
      throw new Error('Error while removing the preset');
    }
    setAutocutStore('ui.parameters.caption.userPresets', newCaptionsPresets);
  };

  return (
    <Modal
      icon={<FaRegTrashCan size="1.5rem" />}
      title={
        <TranslatedMessage
          id="captions_presetCustomization_deletePresetsModal_title"
          defaultMessage="Delete presets"
        />
      }
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      fullWidth
      footer={
        <FlexContainer
          flexDirection="column"
          justifyContent="center"
          style={{
            width: '100%',
          }}
          gap={16}
        >
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            style={{
              height: '40px',
              fontSize: '20px',
            }}
          >
            <PresetPreview selectedPreset={selectedPreset} />
          </FlexContainer>

          <FlexContainer
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="nowrap"
            gap={128}
            style={{
              padding: '0 32px',
            }}
          >
            <Button
              onClick={() => setAutocutStore('ui.openedModalName', '')}
              variant="secondary"
            >
              <TranslatedMessage
                id="captions_presetCustomization_searchPresetModal_cancel"
                defaultMessage="Cancel"
              />
            </Button>

            <Button
              onClick={async () => {
                if (selectedPreset === null) return;
                await handleRemove(selectedPreset);

                setSelectedPreset(null);
              }}
              disabled={selectedPreset === null}
              color={colors.error700}
            >
              <FlexContainer
                gap={4}
                alignItems="center"
                justifyContent="center"
              >
                <FaRegTrashCan size="20px" />
                <TranslatedMessage
                  id="captions_presetCustomization_deletePresetsModal_delete"
                  defaultMessage="Delete preset"
                />
              </FlexContainer>
            </Button>
          </FlexContainer>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={16}
        style={{
          width: '100%',
        }}
      >
        <FlexContainer
          flexDirection="column"
          gap={8}
          justifyContent="space-between"
          style={{
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${PRESETS_PER_LINE}, 1fr)`,
              gap: 8,
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              padding: '16px',
            }}
          >
            {userPresets?.map((preset, index) => (
              <CaptionsPresetCard
                key={index}
                preset={preset}
                onClick={async () => {
                  if (selectedPreset?.id === preset.id) {
                    setSelectedPreset(null);
                    return;
                  }
                  setSelectedPreset(preset);
                }}
                isSelected={preset.id === selectedPreset?.id}
              />
            ))}
          </div>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

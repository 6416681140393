import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import ArrowTip from '@autocut/components/atoms/ArrowTip';
import { Helper } from '@autocut/components/atoms/Helper/Helper';
import css from './stepLayout.module.css';

export type StepLayoutProps = {
  title: string;
  alwaysOpen?: boolean;
  children: React.ReactNode;
  maxHeight?: number;
  defaultOpen?: boolean;
  helperId?: string | undefined;
  helperDefault?: string | undefined;
  arrowOnboardingId?: string | undefined;
  isRelative?: boolean;
  disabled?: boolean;
  id?: string;
};

export const StepLayout = ({
  title,
  children,
  alwaysOpen = false,
  defaultOpen = false,
  helperId = undefined,
  helperDefault = undefined,
  arrowOnboardingId = undefined,
  isRelative = true,
  disabled = false,
  id,
}: StepLayoutProps) => {
  const childrenContainerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [childrenHeight, setChildrenHeight] = useState<number>(0);

  const refreshChildrenHeight = () => {
    //As children container is in a div with overflow hidden, we can check the height needed to display all children even if it's closed
    setChildrenHeight(childrenContainerRef.current?.offsetHeight || 0);
  };

  const handleSwitchOpen = () => {
    if (alwaysOpen) return;
    setIsOpen(!isOpen);
    refreshChildrenHeight();
  };

  useEffect(() => {
    refreshChildrenHeight();
  }, [children]);

  useLayoutEffect(() => {
    if (childrenContainerRef.current) {
      const observer = new MutationObserver(refreshChildrenHeight);
      observer.observe(childrenContainerRef.current, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    if (defaultOpen) {
      setTimeout(() => setIsOpen(true), 100);
    }
  }, [defaultOpen]);

  return (
    <div
      className={`${css.container} ${alwaysOpen || isOpen ? css.open : ''}`}
      style={{
        position: isRelative ? 'relative' : 'initial',
        opacity: disabled ? '50%' : undefined,
      }}
      id={id}
    >
      <div
        id={arrowOnboardingId}
        className={css.header}
        onClick={disabled ? undefined : handleSwitchOpen}
      >
        <div className={css.title}>
          <div>{title}</div>
          {helperId && helperDefault && (
            <Helper
              id_message={helperId}
              default_message={helperDefault}
              textStyle={{
                width: '240px',
              }}
              position="right"
            />
          )}
        </div>

        {!alwaysOpen && (
          <div>
            <ArrowTip
              height={16}
              direction={isOpen ? 'top' : 'bottom'}
              variant={'primary'}
            />
          </div>
        )}
      </div>
      <div
        style={{
          height: isOpen || alwaysOpen ? childrenHeight : 0,
        }}
        className={`${css.body} ${alwaysOpen ? css.fullMaxHeight : ''}`}
      >
        <div ref={childrenContainerRef}>{children}</div>
      </div>
    </div>
  );
};

import * as React from 'react';
import css from './RadioButton.module.css';

export type RadioButtonProps = {
  label: string | React.ReactNode;
  value: any;
  name: string;
  onClick: (value: number | string) => void;
  setIndexFocus?: () => void;
  default?: boolean;
  checked?: boolean;
  disabled?: boolean;
};

export const RadioButton = ({
  label,
  value,
  name,
  onClick,
  setIndexFocus,
  default: isDefault = false,
  disabled = false,
}: RadioButtonProps) => {
  const handleOnClick = () => {
    if (setIndexFocus) setIndexFocus();
    onClick(value);
  };

  return (
    <div className={`${css.radioButtonContainer}`} key={name}>
      <input
        type="radio"
        id={`${name}`}
        name={name}
        value={`${value}`}
        style={{ width: 'auto', marginRight: '16px' }}
        onClick={handleOnClick}
        disabled={disabled}
        checked={isDefault}
        readOnly
      />
      <label htmlFor={`${name}`}>{label}</label>
    </div>
  );
};

import React, { useState } from 'react';

import { StepLayout } from '@autocut/layouts/StepLayout';
import { useIntl } from 'react-intl';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { BRoll } from '@autocut/types/BRolls';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { BRollTranscriptItem } from '@autocut/components/atoms/BRollTranscriptItem/BRollTranscriptItem';
import { BRollChoiceModal } from '@autocut/components/modals/BRollChoiceModal/BRollChoiceModal';
import { InvalidSelectionScreen } from '@autocut/components/partials/InvalidSelectionScreen/InvalidSelectionScreen';
import { useNavigate } from 'react-router-dom';

export const BRollTranscriptStep = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const bRolls = useAutoCutStore(
    state => state.onGoingProcess.bRolls
  ) as BRoll[];

  const [currentBRoll, setCurrentBroll] = useState<BRoll | undefined>(
    undefined
  );

  if (!bRolls) {
    navigate('../parameters');
  }

  if (bRolls.length === 0) {
    return (
      <InvalidSelectionScreen
        titleId="broll_no_broll_screen_title"
        titleDefaultMessage="Transcript processing is over, but nothing was found."
        texts={[
          {
            id: 'broll_no_broll_screen_text1',
            defaultMessage:
              'In order to use Auto B-Roll, you need to select at least one audio clip with speech in your timeline.',
          },
          {
            id: 'broll_no_broll_screen_text2',
            defaultMessage:
              'If you did that already, verify the language of your transcript and check that you audio tracks are not muted.',
          },
        ]}
      />
    );
  }

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'broll_choice_step_title',
        defaultMessage: 'B-Roll selection',
      })}
      alwaysOpen
    >
      {currentBRoll && (
        <BRollChoiceModal
          bRoll={currentBRoll}
          closeModalFunction={() => {
            setCurrentBroll(undefined);
          }}
        />
      )}
      <FlexContainer flexDirection="column" gap={8}>
        {bRolls &&
          bRolls.map(bRoll => (
            <BRollTranscriptItem
              openChoiceModal={(bRoll: BRoll) => {
                setCurrentBroll(bRoll);
              }}
              key={bRoll.id}
              bRoll={bRoll}
            />
          ))}
      </FlexContainer>
    </StepLayout>
  );
};

// In Sentry a fingerPrint is a string that is used to group errors.
export const SentryFingerPrintEnum = {
  API: {
    ADOBE_EXCHANGE_GRADE: 'Error while creating doc in extra-trial collection',
    ASKING_KEY_ERROR: 'Error while asking trial key',
    STATISTICS: {
      DEFAULT: 'Error while sending statistics',
      ERROR: 'Error while sending error statistics',
      REVIEW: 'Error while sending review.',
    },
    IS_KEY_VALID: 'Error while checking if key is valid.',
    IS_KEY_VALID_ACTIVATION: 'Error while activate the key.',
    REQUESTING_FEATURE_ERROR: 'Error while requesting feature.',
    RESEND_TRIAL_KEY: 'Error while resending trial key.',
    REPORT_DAILY_LOG: 'Error while sending daily log.',
    SCORE: {
      GET: 'Error while getting user score',
      POST: 'Error while updating user score',
    },
  },
  CEP: {
    CACHE: 'Error while removing cache folder',
    INIT: 'Error while initializing script',
    LOAD_SCRIPT: 'Error while eval script',
    GET_PPRO_VERSION: 'Error while getting PPro version from CS Interface.',
  },
  FFMPEG: {
    ACCESS_RIGHTS: 'There is an issue with FFMPEG access rights',
    BRAW_EXPORT: 'Failed command for exporting BRAW',
    AUDIO_EXTRACTION: 'Failed command for full audio extraction',
    CHANNEL_EXTRACTION: 'Failed command for channel extraction',
    CONCATENATION: 'Failed command for concatenation',
    ERROR_RETURNED_ON_READING: 'FFmpeg returned an error when reading file',
    WARNING_RETURNED_ON_READING: 'FFmpeg returned warnings when reading file',
    ERROR_THROWED_ON_READING: 'error_throwed_on_reading',
    GET_CLIP_DURATION: 'Failed to get clip duration',
    GET_CHANNEL_LAYOUT: 'Failed command to get channel layout',
    GET_VIDEO_STREAM: 'Failed command to get video stream',
    MAX_VOLUME_DETECTION: 'Failed command to get max volume',
    NOT_SUPPORTED_CHANNEL_LAYOUT: 'Channel layout not supported',
    NOT_SUPPORTED_FORMAT: 'This format is not supported by FFmpeg',
    SILENCE_DETECTION: 'Failed command for silence detection',
    SILENCE_GENERATION: 'Failed command for silence generation',
    STREAM_EXTRACTION: 'Failed command for stream extraction',
    STREAM_NUMBER_DETECTION: 'Failed command to get number of streams',
    PNG_GENERATION: 'Failed command for png file generation',
  },
  PREVIEW: {
    FINAL_PREVIEW_BASED_SILENCE: 'Failed to get final preview based silence.',
    WAVEFORM: 'Error while reading wav file for drawing waveform.',
  },
  PROCESS: (mode?: string) => `Unexpected error while in ${mode} mode.`,
  EXEC: {
    default: 'exec',
    GET_UUID: 'Failed command for getUUID',
    GET_HOST: 'Failed command for getHostname',
    GET_MAC_ID: 'Failed command for getMacID',
    GET_TEMP_FOLDER: 'Failed command for getTempFolder',
    COPY: 'Failed command for copy',
    PASTE: 'Failed command for paste',
  },
  STORAGE: {
    GET: 'Error while getting persistence storage.',
    SET: 'Error while setting local storage.',
  },
  DEVTOOL: 'Test ERROR',
};

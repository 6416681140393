import { ContextualMenu } from '@autocut/components/atoms/ContextualMenu';
import { ContextualMenuItem } from '@autocut/components/atoms/ContextualMenuItem';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { SilencesFooterButton } from '@autocut/components/atoms/SilencesFooterButton';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SilenceStep } from '@autocut/components/steps/SilenceStep/SilenceStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useSelection } from '@autocut/hooks/useSelection';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { TranscriptSentence } from '@autocut/modes/repeat/Steps/Transcript/Parts/TranscriptSentence';
import { Utterance, WordBase } from '@autocut/types/Deepgram';
import { playChunk } from '@autocut/utils/captions/utils';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { handleRepeatProcess } from '@autocut/utils/repeat/handleRepeatProcess';
import {
  SelectionCoordinatesType,
  SelectionDirection,
  WordCoordinatesType,
  getSelectionDirection,
} from '@autocut/utils/selection';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import { HiScissors } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import css from './RepeatTranscriptStep.module.css';

type ToggleIsCutArgs = {
  transcript: Array<Utterance[]>;
  selectionCoordinates: SelectionCoordinatesType;
};

export const RepeatTranscriptStep = () => {
  const intl = useIntl();

  const [isHoveringButton, setIsHoveringButton] = React.useState(false);
  const {
    selectionCoordinates,
    setSelectionCoordinates,
    contextualMenuPosition,
    contextualMenuVisibility,
    setContextualMenuVisibility,
  } = useSelection(isHoveringButton);
  const { transcript } = useAutoCutStore(state => ({
    transcript: state.onGoingProcess.delimitedTranscript as Utterance[][],
  }));
  const [currentTimeout, setCurrentTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  const toggleIsCut = (args: ToggleIsCutArgs): void => {
    const { transcript, selectionCoordinates } = args;
    const { startWordCoordinates, endWordCoordinates } = selectionCoordinates;
    const direction = getSelectionDirection(selectionCoordinates);
    const startIndex =
      direction === SelectionDirection.FORWARD
        ? startWordCoordinates
        : endWordCoordinates;
    const endIndex =
      direction === SelectionDirection.FORWARD
        ? endWordCoordinates
        : startWordCoordinates;

    for (let g = startIndex.groupIndex; g <= endIndex.groupIndex; g++) {
      const startU =
        g === startIndex.groupIndex ? startIndex.utteranceIndex : 0;
      const endU =
        g === endIndex.groupIndex
          ? endIndex.utteranceIndex
          : transcript[g].length - 1;

      for (let u = startU; u <= endU; u++) {
        const startW =
          g === startIndex.groupIndex && u === startU
            ? startIndex.wordIndex
            : 0;
        const endW =
          g === endIndex.groupIndex && u === endU
            ? endIndex.wordIndex
            : transcript[g][u].words.length - 1;

        for (let w = startW; w <= endW; w++) {
          transcript[g][u].words[w].isCut = !transcript[g][u].words[w].isCut;
        }
      }
    }
  };

  const onCutToggleFromMenu = (event: Event) => {
    event.stopPropagation();
    const newTranscript = [...transcript];

    if (!selectionCoordinates) return;
    const { startWordCoordinates, endWordCoordinates } = selectionCoordinates;

    const selectionDirection = getSelectionDirection(selectionCoordinates);

    if (selectionDirection === SelectionDirection.FORWARD) {
      toggleIsCut({ transcript: newTranscript, selectionCoordinates });
    } else {
      const coordoninates: SelectionCoordinatesType = {
        startWordCoordinates: endWordCoordinates,
        endWordCoordinates: startWordCoordinates,
      };
      toggleIsCut({
        transcript: newTranscript,
        selectionCoordinates: coordoninates,
      });
    }

    setAutocutStore('onGoingProcess.delimitedTranscript', newTranscript);
    setSelectionCoordinates(undefined);
    setContextualMenuVisibility(false);
  };

  const onCutToggle = ({
    transcript,
    selectionCoordinates,
  }: ToggleIsCutArgs) => {
    const newTranscript = [...transcript];
    toggleIsCut({ transcript: newTranscript, selectionCoordinates });
    setAutocutStore('onGoingProcess.delimitedTranscript', newTranscript);
  };

  const onPlayClickFromMenu = async (event: Event) => {
    event.stopPropagation();

    if (!selectionCoordinates) return;
    const { startWordCoordinates, endWordCoordinates } = selectionCoordinates;

    const selectionDirection = getSelectionDirection(selectionCoordinates);

    let startWord: WordBase;
    let endWord: WordBase;
    if (selectionDirection === SelectionDirection.FORWARD) {
      startWord = getWordByCoordinate(startWordCoordinates);
      endWord = getWordByCoordinate(endWordCoordinates);
    } else {
      endWord = getWordByCoordinate(startWordCoordinates);
      startWord = getWordByCoordinate(endWordCoordinates);
    }

    setSelectionCoordinates(undefined);
    setContextualMenuVisibility(false);
    setCurrentTimeout(
      await playChunk(startWord.start, endWord.end, currentTimeout)
    );
  };

  const onPlayClick = async (
    selectionCoordinates: SelectionCoordinatesType
  ) => {
    const { startWordCoordinates, endWordCoordinates } = selectionCoordinates;
    const startWord = getWordByCoordinate(startWordCoordinates);
    const endWord = getWordByCoordinate(endWordCoordinates);
    setCurrentTimeout(
      await playChunk(startWord.start, endWord.end, currentTimeout)
    );
  };

  const getWordByCoordinate = ({
    groupIndex,
    utteranceIndex,
    wordIndex,
  }: WordCoordinatesType): WordBase => {
    return transcript[groupIndex][utteranceIndex].words[wordIndex];
  };

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={(isLoading, isDisabled) => (
            <SilencesFooterButton
              modeId="repeat"
              isLoading={isLoading}
              isDisabled={isDisabled}
              onClickFunction={async () => {
                await handleRepeatProcess({ intl, transcript });
                await handleTrialTask('repeat');
              }}
            />
          )}
        />
      }
    >
      <InfoText style="info">
        <TranslatedMessage
          id="repeat_info_banner"
          defaultMessage="Welcome to the caption editor - you will of course also be able to edit your captions in the Premiere Pro timeline after adding them."
        />
      </InfoText>

      <SilenceStep modeId="repeat" />

      <ParametersGroupLayout
        leftBorderWidth={0}
        contentContainerStyle={{ paddingLeft: 0, gap: 0 }}
        title={intl.formatMessage({
          id: 'repeat_transcript_editor_title',
          defaultMessage: 'Fix transcription',
        })}
      >
        <div className={css.mainContainer}>
          <ContextualMenu
            isVisible={contextualMenuVisibility}
            left={contextualMenuPosition.left}
            top={contextualMenuPosition.top}
            setIsHovering={setIsHoveringButton}
          >
            <ContextualMenuItem
              label={intl.formatMessage({
                id: 'repeat_toggle_cut_button_text',
                defaultMessage: 'Cut/Uncut',
              })}
              onClick={onCutToggleFromMenu}
            />
            <ContextualMenuItem
              label={intl.formatMessage({
                id: 'repeat_play_button_text',
                defaultMessage: 'Play',
              })}
              onClick={onPlayClickFromMenu}
            />
          </ContextualMenu>
          {transcript.map((utterances, groupIndex) => (
            <React.Fragment key={groupIndex}>
              <div className={css.group}>
                {utterances.map((utterance, utteranceIndex) => {
                  return (
                    <div
                      className={css.sentenceAndScissors}
                      key={utteranceIndex}
                    >
                      <div
                        onClick={() =>
                          onCutToggle({
                            transcript,
                            selectionCoordinates: {
                              startWordCoordinates: {
                                groupIndex,
                                utteranceIndex,
                                wordIndex: 0,
                              },
                              endWordCoordinates: {
                                groupIndex,
                                utteranceIndex,
                                wordIndex: utterance.words.length - 1,
                              },
                            },
                          })
                        }
                        className={css.scissors}
                      >
                        <HiScissors size={12} />
                      </div>
                      <div
                        onClick={() =>
                          onPlayClick({
                            startWordCoordinates: {
                              groupIndex,
                              utteranceIndex,
                              wordIndex: 0,
                            },
                            endWordCoordinates: {
                              groupIndex,
                              utteranceIndex,
                              wordIndex: utterance.words.length - 1,
                            },
                          })
                        }
                        className={css.scissors}
                      >
                        <FaPlay
                          size={10}
                          style={{ transform: 'translate(1px)' }}
                        />
                      </div>
                      <span className={css.sentence}>
                        <TranscriptSentence
                          words={utterance.words}
                          groupIndex={groupIndex}
                          utteranceIndex={utteranceIndex}
                          selectionCoordinates={selectionCoordinates}
                        />
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className={css.separator}></div>
            </React.Fragment>
          ))}
        </div>
      </ParametersGroupLayout>
    </ModeLayout>
  );
};

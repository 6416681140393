import { getModeStatTrait } from '@autocut/enums/modes.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { IntlShape } from 'react-intl';
import { handleRestore } from '../backup/handleRestore';
import { handleFinalMessage } from '../cutButton/handleFinalMessage';
import { autocutStoreVanilla } from '../zustand';
import { sendStats } from '../stats.util';
import { StatType } from '@autocut/types/StatType.enum';
import { HttpStatusCode } from 'axios';

export const handleProcessError = async (err: unknown, intl: IntlShape) => {
  const autocutState = autocutStoreVanilla();
  const mode = autocutState.ui.process.mode;
  const statCategory = getModeStatTrait(mode);
  const autocutStates = autocutStoreVanilla();
  const isRestorable = autocutStates.backup.isRestorable;

  const formattedError = new IncrementalError(
    err instanceof IncrementalError
      ? err
      : `An error has occured during process (${mode.id}): ` + err,
    `handleProcessError(${mode.id})`
  );

  // Trial key usage exceeded
  if (formattedError.status === HttpStatusCode.TooManyRequests) {
    if (isRestorable) await handleRestore(false);
    return;
  }

  manageError({
    error: formattedError,
  });

  await sendStats({
    type: StatType.ERROR,
    value: 1,
    traits: {
      type: 'PROCESS_ERROR',
      mode: statCategory,
      fatal: 1,
    },
  });

  handleFinalMessage(intl, 'button_Failure', 'Failure... Something went wrong');

  if (isRestorable) await handleRestore(false);
};

import { OS_MAC, OS_WINDOWS } from '@autocut/constants/constants';
import { csi } from '@autocut/lib/utils/bolt';
import { exec } from '@autocut/utils/exec.utils';
import { sendStats } from '@autocut/utils/stats.util';
import { refreshHostName } from '@autocut/utils/system/hostname.system.utils';
import { getOS, refreshOS } from '@autocut/utils/system/os.system.utils';
import { refreshUUID } from '@autocut/utils/system/uuid.system.utils';

export const refreshClientInfos = async () => {
  await refreshUUID();
  await refreshHostName();
  await refreshOS();
};

/**
 * Kill all CEP extensions. The function first close this extension and after 1 seconds kill all CEP extensions processes.
 * Suprisingly, the process killer command does not seems to need any permissions.
 */
export const killAllExtensions = async () => {
  const platform = getOS();
  let command;

  if (platform === OS_WINDOWS) {
    command = 'timeout /t 1 & taskkill /F /IM CEPHtmlEngine.exe';
  } else if (platform === OS_MAC) {
    command = 'sleep 1 && pkill -SIGKILL -f CEPHtmlEngine';
  }

  if (command === undefined) throw new Error('Platform not supported');

  await sendStats({
    type: 'HARD_RESET',
    value: 'true',
    traits: {
      os: platform,
    },
  });

  void exec({ command, options: { windowsHide: true } });
  csi.closeExtension();
};

import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import logLevel from '@autocut/types/logLevel.enum';
import {
  MAX_CACHE_FOLDER_SIZE,
  getCacheFolderSize,
  removeCache,
} from '../cache.utils';
import { logger } from '../logger';
import { manageError } from '@autocut/utils/manageError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleRemoveCache', level, message, objects);
};

export const handleRemoveCache = async () => {
  try {
    const cacheFolderSize = getCacheFolderSize();
    if (cacheFolderSize > MAX_CACHE_FOLDER_SIZE) {
      await removeCache();
    }
  } catch (error: any) {
    //Non-blocking error, cache is just not deleted
    logMessage(
      logLevel.error,
      'An error has occured when deleting cache: ' + error
    );

    manageError({
      error: new IncrementalError(error, 'handleRemoveCache'),
      disableModal: true,
    });
  }
};

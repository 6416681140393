import { XMLDocument } from './XMLDocument';
import { XMLSequence } from './XMLSequence';
import './prototypes';
import { XMLTrackItem } from './XMLTrackItem';

export class XMLTrack {
  private objectUId: string;
  public parentDocument: XMLDocument;
  private parentSequence: XMLSequence;
  private track: Element;
  private tagName: 'VideoClipTrack' | 'AudioClipTrack';

  constructor(track: Element, sequence: XMLSequence) {
    this.track = track;
    this.objectUId = track.getAttribute('ObjectUID') ?? '';
    this.parentDocument = sequence.parentDocument;
    this.parentSequence = sequence;

    const tagName = track.tagName;
    if (!(tagName === 'VideoClipTrack' || tagName === 'AudioClipTrack')) {
      throw new Error(
        'Error when creating XMLTrack : the track selected is not a video or audio track'
      );
    }
    this.tagName = tagName;
  }

  public exists() {
    return !!this.parentDocument.findElementWithObjectId(
      [this.tagName],
      this.objectUId
    );
  }

  public getClips() {
    const trackItems = this.track.querySelectorAll('TrackItem');

    const clips = Array.from(trackItems).map(trackItem => {
      return new XMLTrackItem({ trackItem, track: this });
    });

    return clips;
  }

  public getClip(objectID?: string) {
    const trackItem = this.track.querySelector(
      `TrackItem[ObjectRef="${objectID}"]`
    );

    if (!trackItem)
      throw new Error(
        `Error when getting clip : no clip found with objectID ${objectID}`
      );

    return new XMLTrackItem({ trackItem, track: this });
  }

  public getLastTrackItem() {
    const lastTrackItem = this.track.querySelector(
      'ClipTrack ClipItems TrackItems TrackItem:last-child'
    );

    const objectRef = lastTrackItem?.getAttribute('ObjectRef');

    return { lastTrackItem, objectRef };
  }
}

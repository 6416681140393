import * as React from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { Button } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useLocale } from '@autocut/hooks/useLocale';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { closeModal } from '@autocut/utils/modal/closeModal';

const StartUpModals = () => {
  const startUpModals = useAutoCutStore(state => state.ui.startUpModals);

  const { locale } = useLocale();
  const upperCaseLocale = locale.toUpperCase() === 'FR' ? 'FR' : 'EN';

  return (
    <Modal
      title={
        <TranslatedMessage
          id="text_since_your_last_visit"
          defaultMessage="Since your last visit"
        />
      }
      footer={
        <Button onClickFunction={closeModal}>
          <TranslatedMessage
            id="back_to_autocut"
            defaultMessage="Go Back to AutoCut"
          />
        </Button>
      }
    >
      <FlexContainer justifyContent="center" flexDirection="column">
        {startUpModals != null ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              width: '100%',
              padding: '0.5rem',
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
          >
            {startUpModals.map((modal: any) => (
              <StepLayout
                key={modal.id}
                title={modal[`title_${upperCaseLocale}`]}
                alwaysOpen={true}
              >
                <div className="inputPanelContainer" key={modal.id}>
                  <p style={{ textAlign: 'justify' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal[`body_message_${upperCaseLocale}`],
                      }}
                    />
                  </p>
                </div>
                <br />
                {modal[`link_${upperCaseLocale}`] &&
                modal[`linkText_${upperCaseLocale}`] ? (
                  <Button
                    onClickFunction={() =>
                      openLinkInBrowser(modal[`link_${upperCaseLocale}`])
                    }
                  >
                    {modal[`linkText_${upperCaseLocale}`]}
                  </Button>
                ) : null}
              </StepLayout>
            ))}
          </div>
        ) : (
          ''
        )}
        <FlexContainer
          style={{
            marginBottom: '15px',
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
          justifyContent="center"
          alignItems="center"
        ></FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export { StartUpModals };

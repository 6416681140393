import * as React from 'react';

import { SentryFingerPrintEnum } from '@autocut/types/SentryFingerPrint.enum';
import {
  reportSentryError,
  reportSentryMessage,
} from '@autocut/utils/sentry.utils';
import { Button } from '../../atoms';
import css from './SentryTest.module.css';

const SentryTest = ({}) => {
  const [message, setMessage] = React.useState('');
  const [returnedMessage, setReturnedMessage] = React.useState('');

  return (
    <div className={css.container}>
      Please enter the name of the CEP script you want to launch
      <div className={css.inputContainer}>
        <input
          className={css.input}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <Button
          size="sm"
          onClickFunction={() => {
            reportSentryMessage(message);
            setReturnedMessage('Message sent to Sentry');
            setTimeout(() => {
              setReturnedMessage('');
            }, 5000);
          }}
        >
          Sentry Message
        </Button>
        <Button
          size="sm"
          onClickFunction={() => {
            reportSentryError(
              SentryFingerPrintEnum.DEVTOOL,
              new Error(message)
            );
            setReturnedMessage('Error sent to Sentry');
            setTimeout(() => {
              setReturnedMessage('');
            }, 5000);
          }}
        >
          Sentry Error
        </Button>
      </div>
      {returnedMessage && <div className={css.message}>{returnedMessage}</div>}
    </div>
  );
};

export default SentryTest;

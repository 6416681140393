import React from 'react';

import css from './DiscordCta.module.css';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { DiscordIcon } from './DiscordIcon/DiscordIcon';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';

export const DISCORD_LINK = 'https://discord.gg/autocut';

export const DiscordCta = () => {
  return (
    <div
      className={css.container}
      onClick={() => {
        openLinkInBrowser(DISCORD_LINK);
      }}
    >
      <div className={css.text}>
        <TranslatedMessage id="discordCta_title" defaultMessage="Discord" />
      </div>
      <DiscordIcon
        color="white"
        size={16}
        style={{ transform: 'rotate(90deg)' }}
      />
    </div>
  );
};

import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { VscDebugBreakpointUnsupported } from 'react-icons/vsc';

import React from 'react';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Button } from '@autocut/components/atoms';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ModalType } from '..';
import { BREAKING_VERSION } from '@autocut/utils/init/checkPproVersion';

export const UnsupportedVersionModal = () => {
  const intl = useIntl();

  const { version } = useAutoCutStore(state => ({
    version: state.ppro.version,
  }));

  const isBreaking = BREAKING_VERSION.includes(version);

  return (
    <Modal
      closeModalFunction={() =>
        setAutocutStore(
          'ui.openedModalName',
          isBreaking ? ModalType.UnsupportedVersion : ''
        )
      }
      title={intl.formatMessage({
        id: `unsupported_version_modal_title${isBreaking ? `_${version}` : ''}`,
        defaultMessage: 'Unsupported Premiere Pro version',
      })}
      icon={<VscDebugBreakpointUnsupported size={36} />}
      footer={
        !isBreaking && (
          <Button
            onClickFunction={() => setAutocutStore('ui.openedModalName', '')}
          >
            <TranslatedMessage
              id={`unsupported_version_modal_cta${
                isBreaking ? `_${version}` : ''
              }`}
              defaultMessage="I understand"
            />
          </Button>
        )
      }
    >
      <p>
        <TranslatedMessage
          id={`unsupported_version_modal_text${
            isBreaking ? `_${version}` : ''
          }`}
          defaultMessage="You are currently using an unsupported version of Premiere Pro ({version}). You <b>will</b> not be able to use AutoCut at its fullest with this version.{br}{br}Please use the <b>latest stable release</b> to have the best experience with AutoCut."
          values={{ version: version }}
        />
      </p>
    </Modal>
  );
};

import { Button, Input } from '@autocut/components/atoms';
import { NumberInput } from '@autocut/components/atoms/NumberInput';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { closeModal } from '@autocut/utils/modal/closeModal';
import React, { useState } from 'react';

import css from './CreateResizeFormatModal.module.css';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';

import { resizePresetValidationSchema } from '@autocut/validationSchemas/resizePreset.validationSchema';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import { handleTrialTask } from '@autocut/utils/game/trialGamfication.util';
import { ModalType } from '..';

export const CreateResizeFormatModal = () => {
  const intl = useIntl();
  const { logMessage } = useLogger('CreateResizeFormatModal');

  const customPresets = useAutoCutStore(state => state.ui.resize.customPresets);

  const [name, setName] = useState('');
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [validationSchemaErrors, setValidationSchemaErrors] = useState<
    string[]
  >([]);

  const handleCreateFormat = async () => {
    setIsLoading(true);

    const newFormat = {
      name,
      width,
      height,
    };

    try {
      const format = resizePresetValidationSchema.parse(newFormat);
      setAutocutStore('ui.resize.customPresets', [...customPresets, format]);
      setValidationSchemaErrors([]);
      closeModal();
      if (await handleTrialTask('preset'))
        setAutocutStore('ui.openedModalName', ModalType.GamingTrial);
    } catch (error: any) {
      setValidationSchemaErrors(
        error.issues.map((issue: any) => issue.message)
      );
      logMessage(logLevel.error, `Error in new format : ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      fullWidth
      title={intl.formatMessage({
        id: 'create_format_modal_title',
        defaultMessage: 'Create a new format',
      })}
      footer={
        <FlexContainer flexDirection="column" gap="8px">
          {validationSchemaErrors.length > 0 && (
            <div>
              {validationSchemaErrors.map((error, index) => (
                <p key={index} className={css.additionalMessage}>
                  {intl.formatMessage({
                    id: error,
                  })}
                </p>
              ))}
            </div>
          )}
          <Button
            buttonType="primary"
            onClickFunction={handleCreateFormat}
            isLoading={isLoading}
            disabled={customPresets.some((preset: any) => preset.name === name)}
          >
            {customPresets.some((preset: any) => preset.name === name) ? (
              <TranslatedMessage
                id="create_format_modal_button_disabled"
                defaultMessage="Name already taken"
              />
            ) : (
              <TranslatedMessage
                id="create_format_modal_button"
                defaultMessage="Create"
              />
            )}
          </Button>
        </FlexContainer>
      }
      shouldScroll={false}
    >
      <FlexContainer flexDirection="column" gap={24}>
        <FlexContainer flexDirection="column" gap={8}>
          <TranslatedMessage
            id="create_format_modal_name"
            defaultMessage="Name"
          />
          <Input
            placeholder={'Format name'}
            type="text"
            value={name ?? ''}
            onChange={e => setName(e.target.value)}
            inputSize="lg"
          />
        </FlexContainer>

        <FlexContainer gap={16} alignItems="flex-end">
          <NumberInput
            titleId="create_format_modal_width"
            unitId="px"
            value={width.toString()}
            targetedParameter={'createFormatWidth'}
            onChange={async (value: string) => {
              setWidth(Number(value));
            }}
          />
          <div className={css.x}>X</div>
          <NumberInput
            titleId="create_format_modal_height"
            unitId="px"
            value={height.toString()}
            targetedParameter={'createFormatHeight'}
            onChange={async (value: string) => {
              setHeight(Number(value));
            }}
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

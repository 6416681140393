import { z as zod } from 'zod';

export const silenceValidationSchema = zod.object({
  noiseLevel: zod
    .number({ invalid_type_error: 'validation_error_silence_noiseLevel_type' })
    .nonpositive({ message: 'validation_error_silence_noiseLevel_nonpositive' })
    .int({ message: 'validation_error_silence_noiseLevel_integer' }),
  minTimeOfSilence: zod
    .number({
      invalid_type_error: 'validation_error_silence_minTimeOfSilence_type',
    })
    .min(0, 'validation_error_silence_minTimeOfSilence'),
  minTimeOfTalk: zod
    .number({
      required_error: 'validation_error_silence_maxTimeOfSilence_type',
    })
    .min(0, 'validation_error_silence_maxTimeOfSilence'),
  marginBefore: zod
    .number({
      invalid_type_error: 'validation_error_silence_marginBefore_type',
    })
    .min(0, 'validation_error_silence_marginBefore'),
  marginAfter: zod
    .number({ invalid_type_error: 'validation_error_silence_marginAfter_type' })
    .min(0, 'validation_error_silence_marginAfter'),
  transistions: zod.enum(['none', 'j_cut', 'l_cut', 'both', 'constant_power'], {
    invalid_type_error: 'validation_error_silence_transitions',
  }),
  silences: zod.enum(['delete', 'keep', 'mute', 'delete_keep_spaces'], {
    invalid_type_error: 'validation_error_silence_silences',
  }),
});

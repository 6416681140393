import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { IntlShape } from 'react-intl';
import { handleProcessEnd } from './handleProcessEnd';
import { handleProcessError } from './handleProcessError';
import { handleProcessFinally } from './handleProcessFinally';
import { handleProcessStart } from './handleProcessStart';

type HandleProcessBaseType = {
  executeProcess: (...params: any) => any;
  handleProcessErrorAdditional?: (err: unknown, intl: IntlShape) => void;
};

export const handleProcessBase =
  ({ executeProcess, handleProcessErrorAdditional }: HandleProcessBaseType) =>
  async (intl: IntlShape, additionalParams: any[] = []) => {
    try {
      if (autocutStoreVanilla().ui.process.isProcessing) {
        throw new IncrementalError(
          new Error('Process already running'),
          'handleProcessBase'
        );
      }
      setAutocutStore('ui.process.isProcessing', true);

      await handleProcessStart(intl);

      try {
        await executeProcess(intl, ...additionalParams);
      } catch (err: any) {
        throw new IncrementalError(err, 'executeProcess');
      }

      await handleProcessEnd(intl);
    } catch (err) {
      await handleProcessError(err, intl);
      if (handleProcessErrorAdditional) handleProcessErrorAdditional(err, intl);
    } finally {
      handleProcessFinally();
    }
  };

export enum BleepFileEnum {
  Muted = 'muted',
  BleepSound1 = 'bleep_sound_1_wav',
  BleepSound2 = 'bleep_sound_2_wav',
}

export const bleepSounds: Array<{
  label: string;
  value: BleepFileEnum;
  modelOrder: number;
}> = [
  {
    label: 'Mute',
    value: BleepFileEnum.Muted,
    modelOrder: 0,
  },
  {
    label: 'Bleep 1',
    value: BleepFileEnum.BleepSound1,
    modelOrder: 0,
  },
  {
    label: 'Bleep 2',
    value: BleepFileEnum.BleepSound2,
    modelOrder: 0,
  },
];

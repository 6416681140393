import { Utterance } from '@autocut/types/Deepgram';
import { IntlShape } from 'react-intl';
import { stichIntervals } from '../cut/cutUtils';
import { handleCutBase } from '../cut/handleCutProcess';
import { handleResetSequenceSettings } from '../cut/handleResetSequenceSettings';
import { handleProcessBase } from '../process/handleProcessBase';

type HandleRepeatProcessArgs = {
  transcript: Array<Utterance[]>;
  intl: IntlShape;
};

// Méthode de calcul des intervalles à découper.
// Nous ne pouvons pas utiliser les cutTimecodes fournis par l'API de speech to text car ils manquent de précision
// nous affinons donc les bornes des intervalles grâce aux silences de la vidéo.
const getRepeatIntervals = async (transcript: Array<Utterance[]>) => {
  const flattenedTranscript = transcript.flat();

  const cuttedWordsIntervals = flattenedTranscript
    .flatMap(utterance =>
      utterance.words.map(word => {
        if (word.isCut) {
          return [word.start, word.end];
        }
      })
    )
    .filter(element => element != undefined) as number[][];
  const stitchedCuttedIntervals = stichIntervals(cuttedWordsIntervals, 0.5);
  const cutTimecodes = stitchedCuttedIntervals.flat();

  return {
    timelineBasedSilencesTotal: stitchedCuttedIntervals,
    cutTimecodes,
  };
};

export const handleRepeatProcess = ({
  transcript,
  intl,
}: HandleRepeatProcessArgs) => {
  return handleProcessBase({
    executeProcess: handleCutBase(() => getRepeatIntervals(transcript)),
    handleProcessErrorAdditional: async () =>
      await handleResetSequenceSettings(),
  })(intl);
};

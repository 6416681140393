import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import type { Scripts } from '@esTypes/index';
import { evalTS } from '../../../lib/utils/bolt';
import { CaptionChunk } from '../../../types/Captions';
import {
  getAvailableAudioTrackIndex,
  getAvailableVideoTrackIndex,
} from '../../sequence.utils';

import { cloneDeep, merge } from 'lodash';
import { IntlShape } from 'react-intl';
import {
  localBlurEffectName,
  localMotionEffectName,
  localOpacityEffectName,
  localPositionEffectName,
  localTextEffectName,
  localTransformEffectName,
} from '../../../../jsx/ppro/enums';
import { rgbToInteger } from '../../color.utils';
import { addFloatingTextEffect } from '../addFloatingTextEffect.utils';
import { PproSourceTextParam } from '../formatSourceTextData';
import {
  CAPTIONS_XML_PROJECT_LOCALE,
  getValue,
  secondToTickV2,
} from '../utils';

import { OS_MAC } from '@autocut/constants/constants';
import { loadFontForCanvas } from '@autocut/modes/captions/Steps/Customization/Parts/Fonts/fonts.utils';
import { getLineYOffset } from '@autocut/utils/captions/xml/chunk.xml.captions.utils';
import { initCaptionXMLTemplate } from '@autocut/utils/captions/xml/init.xml.captions.utils';
import { getXMLTextParameters } from '@autocut/utils/captions/xml/parameters.xml.captions.utils';
import { resizeTemplates } from '@autocut/utils/captions/xml/templates.xml.captions.utils';
import { getCaptionsTemplateTracks } from '@autocut/utils/captions/xml/tracks.xml.captions.utils';
import { getParametersForMode } from '@autocut/utils/parameters.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';
import { addBreadcrumb } from '@sentry/react';
import {
  calculateBackgroundPosition,
  CAPTION_MOGRT_HEIGHT,
  CAPTION_MOGRT_WIDTH,
  copyBackgroundClip,
} from '../backgrounds.utils';
import { CanvasFontParams, measureTextOnCanvas } from '../canvas/canvas.utils';
import { addEmojis } from '../emojis.utils';
import { getHighlightColor, getHighlightPosition } from '../highlights.utils';
import { retryTimedOutFunction } from '@autocut/utils/general.utils';

// Windows and macOS appear to have different rendering methods for text, differing by approximately 5%.
const WINDOWS_TEXT_RENDERING_FACTOR = 100 / 100;

export type AutoCutCaptionsKeyframes = Parameters<
  Scripts['createAndModifyMoGRT']
>[5];

export const INITIAL_COPIED_VIDEO_FILTER_COMPONENTS = [
  localTextEffectName[CAPTIONS_XML_PROJECT_LOCALE],
  localOpacityEffectName[CAPTIONS_XML_PROJECT_LOCALE],
];

const progress = (step: string, percent?: number) => {
  console.log('todo:progress', step, percent);
};

const importCaptionsThroughXml = async (
  chunks: CaptionChunk[],
  timeOffset: number,
  intl: IntlShape,
  onProgress?: (progress: number) => void
) => {
  try {
    // ===== INITIALIZING =====
    progress('Initializing');
    const startTimeOrderedCaptions = chunks.sort((a, b) => a.start - b.start);
    const firstChunk = startTimeOrderedCaptions[0];
    if (!firstChunk) throw new Error('No chunk');

    const newName = 'captions_' + Date.now();

    const xmlDoc = await initCaptionXMLTemplate(newName);

    //Get sequence size
    const activeSequence = await evalTS('getActiveSequence');
    if (!activeSequence)
      throw new IncrementalError(
        'SEQUENCE_MISMATCH',
        'importCaptionsThroughXml'
      );

    const sequenceWidth = activeSequence?.frameSizeHorizontal;
    const sequenceHeight = activeSequence?.frameSizeVertical;

    let optimalScale = Math.max(
      await evalTS('getMogrtOptimalScale', [
        CAPTION_MOGRT_WIDTH,
        CAPTION_MOGRT_HEIGHT,
      ]),
      100
    );
    // PPro 24.1 seems to have a problem when scale is 100
    if (optimalScale === 100) optimalScale = 100.1;

    const optimalFactor = optimalScale / 100;
    const finalXFactor = 1 / sequenceWidth;
    const finalYFactor = 1 / sequenceHeight;
    const finalMogrtXFactor = 1 / (optimalFactor * CAPTION_MOGRT_WIDTH);
    const finalMogrtYFactor = 1 / (optimalFactor * CAPTION_MOGRT_HEIGHT);

    const {
      textBackgroundTrack,
      wordBackgroundTrack,
      captionsTrack,
      effectsTrack,
      floatingTextEffectTrack,
    } = getCaptionsTemplateTracks(xmlDoc);

    // ===== UPDATE TEMPLATE TEXT CLIP =====
    progress('Update template text clip');
    const [captionsClip, floatingTextEffectClip] = resizeTemplates(
      [
        [captionsTrack, { withMasterClip: true, withLocalScale: false }],
        [
          floatingTextEffectTrack,
          {
            withMasterClip: true,
            withLocalScale: true,
          },
        ],
      ],
      sequenceWidth,
      sequenceHeight,
      optimalScale
    );
    const [effectsClip] = resizeTemplates(
      [[effectsTrack, { withMasterClip: true, withLocalScale: true }]],
      sequenceWidth,
      sequenceHeight,
      300
    );

    const adjustedOptimalScale =
      getOS() === OS_MAC
        ? optimalScale
        : optimalScale * WINDOWS_TEXT_RENDERING_FACTOR;
    const [textBackgroundClip, wordBackgroundClip] = resizeTemplates(
      [
        [textBackgroundTrack, { withMasterClip: false, withLocalScale: true }],
        [wordBackgroundTrack, { withMasterClip: false, withLocalScale: true }],
      ],
      sequenceWidth,
      sequenceHeight,
      adjustedOptimalScale
    );

    // ===== GET PARAMETERS =====
    progress('Get parameters');
    const autocutState = autocutStoreVanilla();
    const params = getParametersForMode('caption');

    // Booleans
    const animationIsEnabled = params.animations.enabled;
    const transitionIsEnabled = params.transition.enabled ?? true;

    const unsupportedFeatures =
      autocutState.ui.parameters.caption.languageOfTranscription.unsupportedFeatures?.(
        params.text.font
      );

    // Text
    const isUppercase = unsupportedFeatures?.uppercase?.disabled
      ? false
      : params.formating.uppercase;
    const captionsProperties = getXMLTextParameters({
      ...params,
      formating: {
        ...params.formating,
        uppercase: isUppercase,
      },
    });

    // Fonts
    const fontParameters: CanvasFontParams = {
      italic: params.formating.italic,
      fontSize: params.text.fontSize,
      fontFamily: params.text.font.fontFamily,
    };
    await loadFontForCanvas(params.text.font);

    const lineHeight = (120 * params.text.fontSize) / 100;

    const {
      metrics: {
        height: maxLineHeight,
        actualBoundingBoxDescent: maxLineDescentHeight,
      },
    } = measureTextOnCanvas('Éj', fontParameters, false);

    // ===== UPDATE TEMPLATE TEXT CLIP AGAIN =====
    progress('Update template text clip again');

    // We process the position sequence based to re-use the canvas functions
    // It's the position of the center top of the block.
    // The y center depends of the number of line and will be calculated chunk by chunk
    const xPosition = params.position.xPercentage * sequenceWidth;
    const yPosition =
      params.position.yPercentage * sequenceHeight -
      maxLineDescentHeight +
      maxLineHeight;

    // ===== APPLYING TRANSITIONS =====
    progress('Applying transitions');

    const transitionsVideoFilterComponents: string[] = [];
    if (transitionIsEnabled) {
      transitionsVideoFilterComponents.push(
        localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE]
      );
      const transitions = params.transition.effects ?? [];
      if (transitions.includes('zoomIn'))
        transitionsVideoFilterComponents.push(
          localTransformEffectName[CAPTIONS_XML_PROJECT_LOCALE]
        );
      if (transitions.includes('blurIn'))
        transitionsVideoFilterComponents.push(
          localBlurEffectName[CAPTIONS_XML_PROJECT_LOCALE]
        );
    }

    // ===== DETERMINATING TRACKS TO USE =====
    progress('Determinating tracks to use');
    const availablesVideoTracks = getAvailableVideoTrackIndex(
      autocutState.sequence.infos?.videoTracks.find(t => t.nbClipsSelected > 0)
        ?.index
    );
    const availablesAudioTracks = getAvailableAudioTrackIndex(
      autocutState.sequence.infos?.audioTracks.find(t => t.nbClipsSelected > 0)
        ?.index
    );

    let videoTrackIndex = availablesVideoTracks?.find(index =>
      availablesAudioTracks?.includes(index)
    );

    if (!videoTrackIndex) {
      // ===== ADD TRACKS =====
      progress('Create a new track');

      const maxAudioTrackIndex =
        autocutState.sequence.infos?.audioTracks?.length || 0;

      const i = 0;
      do {
        videoTrackIndex = await evalTS('addTrack', 'video');
      } while (
        (videoTrackIndex || -1) < maxAudioTrackIndex &&
        !availablesAudioTracks?.includes(videoTrackIndex ?? -1) &&
        i < 100
      );
    }

    // ===== ADDING EMOJIS =====
    progress('Adding emojis');
    const { addEmojiChunk, removeOriginalClip } = await addEmojis(
      {
        xmlDocument: xmlDoc,
      },
      newName
    );

    if (params.animations.floatingText) {
      // ===== ADDING FLOATING TEXT =====
      progress('Adding floating text');

      const lastChunk =
        startTimeOrderedCaptions[startTimeOrderedCaptions.length - 1];
      addFloatingTextEffect({
        floatingTextEffectClip,
        captionsSequenceSecondsEnd: lastChunk.end,
      });
    } else {
      // ===== REMOVING FLOATING TEXT =====
      progress('Removing floating text');

      floatingTextEffectClip.remove();
    }

    let highLightIndex = 0;
    // ===== ADDING CHUNKS =====
    progress('Adding chunks');
    const done = 0;
    const total = chunks.length;

    for (const [index, chunk] of chunks.entries()) {
      if (chunk.deleted) continue;

      const blockXOffset = 0;
      const blockYOffset =
        -getLineYOffset({
          fontSize: params.text.fontSize,
          nbLines: chunk.nbLines,
          indexLine: chunk.nbLines - 1,
          maxLineHeight,
        }) / 2;

      const blockPositionX = xPosition + blockXOffset;
      const blockPositionY = yPosition + blockYOffset;
      const blockPositionXPercentage = blockPositionX * finalXFactor;
      const blockPositionYPercentage = blockPositionY * finalYFactor;

      const startTime = chunk.start - timeOffset;
      const endTime = chunk.end - timeOffset;

      const chunkLines = chunk.lines.map((line, index) => ({
        words: line,
        fullText: line.map(word => getValue(word)).join(' '),
        metrics: measureTextOnCanvas(
          line.map(word => getValue(word)).join(' '),
          fontParameters,
          isUppercase
        ).metrics,
        position: {
          x: blockPositionX,
          y:
            yPosition -
            getLineYOffset({
              fontSize: params.text.fontSize,
              nbLines: chunk.nbLines,
              indexLine: index,
              maxLineHeight,
            }),
        },
      }));
      const maxLineWidth = Math.max(
        ...chunkLines.map(line => line.metrics.width)
      );

      // ===== TEXT POSITION =====
      captionsClip.updateTextParam(
        localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
        [blockPositionXPercentage, blockPositionYPercentage]
      );

      // ===== TEXT TRANSITION =====
      if (transitionsVideoFilterComponents.length) {
        effectsClip.clone({
          startTick: secondToTickV2(startTime),
          endTick: secondToTickV2(endTime),
          index,
          copiedVideoFilterComponents: transitionsVideoFilterComponents,
        });
      }

      // ===== Background =====
      if (params.box.enabled) {
        const textBoxXPadding =
          ((params.box?.xPadding ?? 10) / 100) * params.text.fontSize;
        const textBoxYPadding =
          ((params.box?.yPadding ?? 10) / 100) * params.text.fontSize;

        const textBoxRadius =
          (params.box.radius / 100) *
          (params.text.fontSize +
            (params.box.yPadding / 100) * params.text.fontSize);

        console.log(
          textBoxRadius,
          optimalFactor,
          textBoxRadius / optimalFactor
        );
        const { newClip: newTextBackgroundClip } = await copyBackgroundClip({
          originalClip: textBackgroundClip,
          startTime: startTime,
          endTime: endTime,
          color: params.box.color,
          index,
          opacity: Math.min(params.box.enabled ? params.box.opacity : 0, 99.9),
          size: [
            maxLineWidth * finalMogrtXFactor,
            lineHeight * chunk.nbLines * finalMogrtYFactor,
          ],
          radius: textBoxRadius / optimalFactor,
          padding: [
            textBoxXPadding * finalMogrtXFactor,
            textBoxYPadding * finalMogrtYFactor,
          ],
        });

        const { xPercentage, yPercentage } = calculateBackgroundPosition(
          [sequenceWidth, sequenceHeight],
          params.position.xPercentage,
          params.position.yPercentage,
          optimalFactor
        );

        newTextBackgroundClip?.updateTransformParam(
          localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
          [xPercentage, yPercentage]
        );
      }

      for (const [index, highlight] of chunk.highlight.entries()) {
        const highlightStart = highlight.start - timeOffset;
        const highlightEnd =
          (chunk.highlight[index + 1]?.start ?? highlight.end) - timeOffset;

        const highlightColor =
          animationIsEnabled && params.animations.highlight.enabled
            ? getHighlightColor({
                textColor: params.text.color,
                highlightColor: params.animations.highlight.color,
                unrevealedTextColor: params.animations.highlight.revealText
                  .enabled
                  ? params.animations.highlight.revealText.color
                  : undefined,
                indexStart: highlight.indexStart,
                indexEnd: highlight.indexEnd,
              })
            : ([[0, rgbToInteger(params.text.color)]] as [number, number][]);

        const textProperties: PproSourceTextParam = {
          mTextParam: {
            mStyleSheet: {
              mText: chunk.text,
              mFillColor: { mParamValues: highlightColor },
            },
          },
        };

        // Highlight Text
        const newClip = captionsClip.clone({
          startTick: secondToTickV2(highlightStart),
          endTick: secondToTickV2(highlightEnd),
          index: highLightIndex,
          copiedVideoFilterComponents: INITIAL_COPIED_VIDEO_FILTER_COMPONENTS,
        });

        const clipProperties: PproSourceTextParam = merge(
          cloneDeep(captionsProperties),
          textProperties
        );
        newClip.updateSourceTextParam(clipProperties);

        // Wordbox
        if (
          animationIsEnabled &&
          params.animations.wordBox?.enabled &&
          !unsupportedFeatures?.wordBox.disabled
        ) {
          const wordBoxXPadding =
            ((params.animations.wordBox?.xPadding ?? 10) / 100) *
            params.text.fontSize;
          const wordBoxYPadding =
            ((params.animations.wordBox?.yPadding ?? 10) / 100) *
            params.text.fontSize;
          const wordBoxRadius =
            (params.animations.wordBox?.radius / 100) *
            (params.text.fontSize +
              (params.animations.wordBox.yPadding / 100) *
                params.text.fontSize);

          const {
            metrics: { width: wordWidth },
          } = measureTextOnCanvas(highlight.word, fontParameters, isUppercase);

          console.log(
            wordBoxRadius,
            optimalFactor,
            wordBoxRadius / optimalFactor
          );
          const { newClip: newWordBoxClip } = await copyBackgroundClip({
            originalClip: wordBackgroundClip,
            startTime: highlightStart,
            endTime: highlightEnd,
            color: params.animations.wordBox?.color,
            index: highLightIndex,
            opacity: params.animations.wordBox?.enabled
              ? params.animations.wordBox?.opacity
              : 0,
            padding: [
              wordBoxXPadding * finalMogrtXFactor,
              wordBoxYPadding * finalMogrtYFactor,
            ],
            size: [
              wordWidth * finalMogrtXFactor,
              lineHeight * finalMogrtYFactor,
            ],
            radius: wordBoxRadius / optimalFactor,
          });

          const { xPosition: wordXPosition, yPosition: wordYPosition } =
            await getHighlightPosition(
              chunkLines[highlight.indexLine].fullText,
              highlight.word,
              highlight.lineBeforeWord,
              highlight.indexLine,
              chunk.nbLines,
              {
                fontParameters,
                uppercase: isUppercase,
              }
            );

          const textBlockHeight = lineHeight * chunk.nbLines;

          const { xPercentage, yPercentage } = calculateBackgroundPosition(
            [sequenceWidth, sequenceHeight],
            params.position.xPercentage,
            params.position.yPercentage,
            optimalFactor
          );

          newWordBoxClip?.updateTransformParam(
            localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
            [
              xPercentage - wordXPosition * finalMogrtXFactor,
              yPercentage -
                (wordYPosition + textBlockHeight / 2) * finalMogrtYFactor,
            ]
          );

          // ===== ADDING ONE CHUNK =====
          progress('Adding chunks', (done / total) * 100);
        }

        highLightIndex++;
      }

      // ===== ADDING EMOJIS =====
      progress('Adding emoji');
      await addEmojiChunk(chunk, {
        emojiProperties: {
          position: {
            x: params.position.emojiXPercentage,
            y: params.position.emojiYPercentage,
          },
          fontSize: params.text.fontSize,
          size: chunk.emojiSize,
        },
      });

      onProgress?.(Math.round((index / chunks.length) * 100));
    }
    // ===== REMOVING TEMPLATE CLIPS =====
    progress('Removing template clips');
    captionsClip.remove();
    textBackgroundClip.remove();
    effectsClip.remove();
    wordBackgroundClip.remove();
    removeOriginalClip();

    // ===== RESIZING =====
    progress('Resizing');
    const captionsSequences = xmlDoc.getSequences()[0];
    captionsSequences.resize([sequenceWidth, sequenceHeight]);

    // ===== RENAMING =====
    progress('Renaming');
    const rootItem = xmlDoc.rootItems.find(item => item.name === 'CAPTIONS');
    if (rootItem) rootItem.rename(newName);
    else
      addBreadcrumb({
        category: 'importCaptionsThroughXml',
        level: 'info',
        message: 'Root item not found',
      });

    captionsSequences.rename(newName);

    addBreadcrumb({
      category: 'importCaptionsThroughXml',
      data: {
        rootItem,
      },
      level: 'info',
      message: 'Renamed',
    });
    // ===== EXPORTING =====
    progress('Exporting');
    const prprojFilePath = await xmlDoc.exportToPrproj(newName);
    addBreadcrumb({
      category: 'importCaptionsThroughXml',
      data: {
        prprojFilePath,
      },
      level: 'info',
      message: 'Exported',
    });

    // ===== IMPORTING IN BIN =====
    progress('Importing in bin');
    const result = await retryTimedOutFunction(
      async () => await evalTS('importFileInBin', prprojFilePath, 'CAPTIONS')
    );
    addBreadcrumb({
      category: 'importCaptionsThroughXml',
      data: {
        result,
      },
      level: 'info',
      message: 'Imported',
    });

    // ===== IMPORTING IN SEQUENCE =====
    progress('Importing in sequence');
    await evalTS(
      'importSubSequenceToTrack',
      videoTrackIndex as number,
      newName,
      timeOffset
    );
    await retryTimedOutFunction(
      async () =>
        await evalTS(
          'renameClip',
          videoTrackIndex as number,
          0,
          intl.formatMessage({
            id: 'double_click_to_edit',
            defaultMessage: 'Double click to edit',
          })
        )
    );
    await evalTS('reloadSubsequence', newName);
  } catch (err: any) {
    throw new IncrementalError(err, 'importCaptionsThroughXml');
  }
};

export default importCaptionsThroughXml;

import { csi } from '@autocut/lib/utils/bolt';
import { SilenceParams } from '@autocut/types/SilenceParams';
import { CEPAction } from '@autocut/utils/cep/actions.cep.utils';
import { getClipIndexesToMove } from '@autocut/utils/cut/handleTransition/getClipIndexesToMove.utils';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getParametersForMode } from '@autocut/utils/parameters.utils';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { IntlShape } from 'react-intl';
import { Locale } from '../../../../jsx/ppro/const';
import { changeStartsAndEnds } from '../changeStartsAndEnds/changeStartsAndEnds';
import { insertAudioTracksForTransition } from '../insertAudioTracksForTransition/insertAudioTracksForTransition';

export const handleTransitions = async (
  timelineBasedSilencesTotal: number[][],
  intl: IntlShape
) => {
  try {
    const parameters = getParametersForMode<'silence' | 'ai'>();
    if (['j_cut', 'l_cut', 'both'].includes(parameters.transistions)) {
      return await handleJLCut(
        parameters as SilenceParams,
        timelineBasedSilencesTotal,
        intl
      );
    }

    if (parameters.transistions === 'constant_power') {
      return handleConstantPower();
    }

    return [];
  } catch (err: any) {
    throw new IncrementalError(err, 'handleTransitions');
  }
};

const handleConstantPower = () => {
  const hostEnv = csi.hostEnvironment;
  const locale = hostEnv.appUILocale as Locale;
  const audioTracks = autocutStoreVanilla().sequence.infos?.audioTracks;

  const transitionsActions: CEPAction<'applyConstantPowerToAllAudioOnTrackBetween'>[] =
    [];

  try {
    for (const audioTrack of audioTracks ?? []) {
      if (audioTrack.nbClipsSelected > 0) {
        const action: CEPAction<'applyConstantPowerToAllAudioOnTrackBetween'> =
          {
            action: 'applyConstantPowerToAllAudioOnTrackBetween',
            param: [locale, audioTrack.index, audioTrack.start, audioTrack.end],
            thenFunctions: [],
          };

        transitionsActions.push(action);
      }
    }

    return transitionsActions;
  } catch (error: any) {
    throw new IncrementalError(error, 'handleConstantPower');
  }
};

const handleJLCut = async (
  parameters: SilenceParams,
  timelineBasedSilencesTotal: number[][],
  intl: IntlShape
) => {
  const audioTracks = autocutStoreVanilla().sequence.infos?.audioTracks;

  try {
    changeMessage(
      intl,
      'text_transition_progress_insert',
      'Inserting new audio tracks…'
    );

    const autocutState = autocutStoreVanilla();
    if (
      !autocutState.sequence.infos ||
      (!autocutState.sequence.infos.audioTracks.length &&
        !autocutState.sequence.infos.videoTracks.length)
    ) {
      throw new Error('No clip selected');
    }

    const tracksToTransition: Track[] = audioTracks?.filter(
      audioTrack => audioTrack.nbClipsSelected > 0
    ) as Track[];

    const transitionsActions: CEPAction<
      | 'moveAudioClipsToTrack'
      | 'changeStartAndEndOfAudioTrack'
      | 'insertTrackAt'
    >[] = [];

    const [insertedIndexes, insertTrackActions] =
      await insertAudioTracksForTransition(tracksToTransition ?? []);

    transitionsActions.push(...insertTrackActions);

    const tracksWithClipsToMove = [...tracksToTransition].map(track => ({
      index: track.index,
      type: track.type,
      clipIndexesToMove: getClipIndexesToMove(
        track,
        timelineBasedSilencesTotal
      ),
    }));

    let trackIndex = 0;

    for (const audioTrack of tracksWithClipsToMove ?? []) {
      if (transitionsActions[transitionsActions.length - 1]) {
        transitionsActions[transitionsActions.length - 1].thenFunctions.push(
          (r: any) => {
            changeMessage(
              intl,
              'text_transition_progress_move',
              'Moving audio to new tracks for transitions…'
            );
          }
        );
      }

      transitionsActions.push({
        action: 'moveAudioClipsToTrack',
        param: [
          audioTrack.clipIndexesToMove,
          audioTrack.index + trackIndex,
          insertedIndexes[trackIndex],
        ],
        thenFunctions: [
          () => {
            changeMessage(
              intl,
              'text_transition_progress_handling',
              'Adding transitions: {transition}',
              {
                transition:
                  parameters.transistions === 'j_cut'
                    ? 'J-Cuts'
                    : parameters.transistions === 'l_cut'
                    ? 'L-Cuts'
                    : 'J&L-Cuts',
              }
            );
          },
        ],
      });
      trackIndex++;
    }

    transitionsActions.push(
      ...(await changeStartsAndEnds(
        audioTracks
          ? // @ts-ignore
            audioTracks.filter(audioTrack => audioTrack.nbClipsSelected > 0)
          : [],
        insertedIndexes,
        parameters as SilenceParams
      ))
    );

    if (transitionsActions[transitionsActions.length - 1]) {
      transitionsActions[transitionsActions.length - 1].thenFunctions.push(
        (r: any) => {
          changeMessage(
            intl,
            'text_transition_progress_move',
            'Moving audio to new tracks…'
          );
        }
      );
    }

    return transitionsActions;
  } catch (error: any) {
    throw new IncrementalError(error, 'handleJLCut');
  }
};

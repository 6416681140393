import { range } from '../math.utils';
import { ZoomInterval } from './getZoomIntervals';
import { addZoomCoef } from './zoomIntervals.utils';

// TODO : l'implémentation de ce PSO est très couplé avec le zoom (intégration direct des contraintes, constantes et fitness function)
// Il y aurait moyen de généraliser l'utilisation du PSO si besoin.

// Ranges of solution possible values
export const UPPER_LIMIT_SPEED = 0.11;
export const LOWER_LIMIT_SPEED = 0;
export const UPPER_LIMIT_NL_SHARE = 1;
export const LOWER_LIMIT_NL_SHARE = 0;
// Ranges of solution objectives
export const UPPER_LIMIT_OBJECTIVE_SPEED = UPPER_LIMIT_SPEED;
export const LOWER_LIMIT_OBJECTIVE_SPEED = 0.03;
export const UPPER_LIMIT_OBJECTIVE_NL_SHARE = 0.5;
export const LOWER_LIMIT_OBJECTIVE_NL_SHARE = 0;

const WEIGHT_SHARE = 0.55;
const WEIGHT_SPEED = 0.45;

type ParticleConstructorArgs = {
  coefMin: number;
  coefMax: number;
  speedMin: number;
  speedMax: number;
};

type PSOArgs = {
  smoothZoomIntervals: ZoomInterval[];
  numParticles: number;
  numIterations: number;
  coefMin: number;
  coefMax: number;
  speedMin: number;
  speedMax: number;
  objectiveNonLimitedShare: number;
  objectiveSpeed: number;
  c1?: number;
  c2?: number;
  w?: number;
};

class Particle {
  coefMin: number;
  coefMax: number;
  speedMin: number;
  speedMax: number;
  position: number[];
  velocity: number[];
  bestPosition: number[];
  bestScore: number;

  constructor({
    coefMin,
    coefMax,
    speedMin,
    speedMax,
  }: ParticleConstructorArgs) {
    this.coefMin = coefMin;
    this.coefMax = coefMax;
    this.speedMin = speedMin;
    this.speedMax = speedMax;

    // Initialize position with constraints
    this.position = [
      Math.random() * (coefMax - coefMin) + coefMin, // Coef min
      Math.random() * (coefMax - coefMin) + coefMin, // Coef max
      Math.random() * (speedMax - speedMin) + speedMin, // Zoom speed
    ];

    if (this.position[0] > this.position[1]) {
      [this.position[0], this.position[1]] = [
        this.position[1],
        this.position[0],
      ];
    }

    this.velocity = new Array(3).fill(0).map(() => Math.random() - 0.5);
    this.bestPosition = [...this.position];
    this.bestScore = Number.POSITIVE_INFINITY;
  }

  enforceConstraints() {
    // Enforce position[0] <= position[1] and [coefMin, coefMax] constraint
    this.position[0] = Math.min(
      Math.max(this.position[0], this.coefMin),
      this.coefMax
    );
    this.position[1] = Math.min(
      Math.max(this.position[1], this.coefMin),
      this.coefMax
    );
    if (this.position[0] > this.position[1]) {
      [this.position[0], this.position[1]] = [
        this.position[1],
        this.position[0],
      ];
    }

    // Enforce [speedMin, speedMax] constraint for position[2]
    this.position[2] = Math.min(
      Math.max(this.position[2], this.speedMin),
      this.speedMax
    );
  }

  // Parameters here are the PSO parameters, they can be fine tuned to the PSO needs
  updateVelocity(globalBestPosition: number[], c1 = 0.75, c2 = 0.75, w = 0.2) {
    for (let i = 0; i < this.velocity.length; i++) {
      const r1 = Math.random();
      const r2 = Math.random();
      const cognitiveComponent =
        c1 * r1 * (this.bestPosition[i] - this.position[i]);
      const socialComponent =
        c2 * r2 * (globalBestPosition[i] - this.position[i]);
      const velocity =
        w * this.velocity[i] + cognitiveComponent + socialComponent;

      this.velocity[i] = velocity;
    }
  }

  updatePosition() {
    for (let i = 0; i < this.position.length; i++) {
      this.position[i] += this.velocity[i];
    }
    this.enforceConstraints();
  }
}

const fitnessFunction = (
  smoothZoomIntervals: ZoomInterval[],
  position: number[],
  objectiveNonLimitedShare: number,
  objectiveNonLimitedSpeed: number
) => {
  addZoomCoef(smoothZoomIntervals, position[0], position[1], position[2]);
  const { meanSpeed, nonLimitedShare } =
    computeMeanSpeedAndNonLimitedShare(smoothZoomIntervals);

  const normalizedNonLimitedShare = nonLimitedShare / UPPER_LIMIT_NL_SHARE;
  const normalizedObjectiveNonLimitedShare =
    objectiveNonLimitedShare / UPPER_LIMIT_NL_SHARE;
  const normalizedSpeed = meanSpeed / UPPER_LIMIT_SPEED;
  const normalizedObjectiveSpeed = objectiveNonLimitedSpeed / UPPER_LIMIT_SPEED;

  const score =
    Math.abs(normalizedNonLimitedShare - normalizedObjectiveNonLimitedShare) *
      WEIGHT_SHARE +
    Math.abs(normalizedSpeed - normalizedObjectiveSpeed) * WEIGHT_SPEED;

  return score;
};

const computeMeanSpeedAndNonLimitedShare = (
  smoothZoomIntervals: ZoomInterval[]
) => {
  let totalSpeed = 0;
  let nonLimitedNumber = 0;
  let limitedNumber = 0;

  for (const smoothZoomInterval of smoothZoomIntervals) {
    const intervalSpeed =
      (smoothZoomInterval.toZoomCoef - smoothZoomInterval.fromZoomCoef) /
      (smoothZoomInterval.end - smoothZoomInterval.start);

    if (smoothZoomInterval.fromZoomCoef > 1) nonLimitedNumber++;
    else limitedNumber++;

    totalSpeed += intervalSpeed;
  }

  const nonLimitedShare = nonLimitedNumber / (nonLimitedNumber + limitedNumber);
  const meanSpeed = totalSpeed / (nonLimitedNumber + limitedNumber);

  return { nonLimitedShare, meanSpeed };
};

export const PSO = ({
  smoothZoomIntervals,
  coefMax,
  coefMin,
  numIterations,
  numParticles,
  speedMax,
  speedMin,
  objectiveNonLimitedShare,
  objectiveSpeed,
  c1,
  c2,
  w,
}: PSOArgs) => {
  let globalBestScore = Number.POSITIVE_INFINITY;
  let globalBestPosition = new Array(3);

  // Initialize particles
  const particles = new Array(numParticles)
    .fill(0)
    .map(() => new Particle({ coefMin, coefMax, speedMin, speedMax }));

  for (let iter = 0; iter < numIterations; iter++) {
    particles.forEach(particle => {
      const score = fitnessFunction(
        smoothZoomIntervals,
        particle.position,
        objectiveNonLimitedShare,
        objectiveSpeed
      );

      // Update personal best
      if (score < particle.bestScore) {
        particle.bestScore = score;
        particle.bestPosition = [...particle.position];
      }

      // Update global best
      if (score < globalBestScore) {
        globalBestScore = score;
        globalBestPosition = [...particle.position];
      }
    });

    // Update velocity and position
    particles.forEach(particle => {
      particle.updateVelocity(globalBestPosition, c1, c2, w);
      particle.updatePosition();
    });
  }

  return { globalBestScore, globalBestPosition };
};

export const optimizer = (bestSmoothZoomIntervals: ZoomInterval[]) => {
  const globalScores = [];
  for (let iterOptimizer = 0; iterOptimizer < 1000; iterOptimizer++) {
    iterOptimizer % 10 === 0
      ? console.log('iterOptimizer : ', iterOptimizer)
      : null;
    const c1 = Math.random() * (1 - 0) + 0;
    const c2 = Math.random() * (1 - 0) + 0;
    const w = Math.random() * (1 - 0) + 0;

    const solutionScore = [];
    for (let iterSolution = 0; iterSolution < 10; iterSolution++) {
      const nervousness = Math.random();
      const objectiveNonLimitedShare = range(
        0,
        1,
        LOWER_LIMIT_OBJECTIVE_NL_SHARE,
        UPPER_LIMIT_OBJECTIVE_NL_SHARE,
        nervousness
      );
      const objectiveSpeed = range(
        0,
        1,
        LOWER_LIMIT_OBJECTIVE_SPEED,
        UPPER_LIMIT_OBJECTIVE_SPEED,
        nervousness
      );

      const { globalBestScore } = PSO({
        coefMax: 1.5,
        coefMin: 1,
        numIterations: 100,
        numParticles: 100,
        objectiveNonLimitedShare,
        objectiveSpeed,
        smoothZoomIntervals: bestSmoothZoomIntervals,
        speedMax: UPPER_LIMIT_SPEED,
        speedMin: LOWER_LIMIT_SPEED,
      });
      solutionScore.push(globalBestScore);
    }

    const meanSolutionScore =
      solutionScore.reduce(
        (previousScore, currentScore) => previousScore + currentScore,
        0
      ) / solutionScore.length;
    globalScores.push([[c1, c2, w], meanSolutionScore]);
  }

  const bestSolution = globalScores.reduce(
    (bestScore, currentScore) =>
      currentScore[1] < bestScore[1] ? currentScore : bestScore,
    globalScores[0]
  );

  return bestSolution;
};

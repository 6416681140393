import React from 'react';

import { RadioButtonsForm } from '@autocut/components/atoms/RadioButtonsForm/RadioButtonsForm';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { useIntl } from 'react-intl';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';

export const ReframeStep = () => {
  const intl = useIntl();

  const resizeParameters = useAutoCutStore(state => state.ui.parameters.resize);

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'resize_reframe_step_title',
        defaultMessage: 'Follow subject',
      })}
      alwaysOpen={true}
      helperId="resize_reframe_step_helper"
      helperDefault="You can choose the speed at which the camera will follow the subject."
    >
      <RadioButtonsForm
        radioButtonDescriptions={[
          {
            label: intl.formatMessage({
              id: 'resize_reframe_step_slower',
              defaultMessage: 'Slow',
            }),
            value: 'slower',
            default: resizeParameters.autoReframePreset === 'slower',
          },
          {
            label: intl.formatMessage({
              id: 'resize_reframe_step_default',
              defaultMessage: 'Normal',
            }),
            value: 'default',
            default: resizeParameters.autoReframePreset === 'default',
          },
          {
            label: intl.formatMessage({
              id: 'resize_reframe_step_faster',
              defaultMessage: 'Fast',
            }),
            value: 'faster',
            default: resizeParameters.autoReframePreset === 'faster',
          },
        ]}
        onChange={newValue =>
          setAutocutStore(
            'ui.parameters.resize.autoReframePreset',
            newValue as typeof resizeParameters.autoReframePreset
          )
        }
      />
    </StepLayout>
  );
};

import { InfoText } from '@autocut/components/atoms/InfoText';
import { RadioButtonsForm } from '@autocut/components/atoms/RadioButtonsForm/RadioButtonsForm';
import { TextWithHelper } from '@autocut/components/atoms/TextWithHelper';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { AutoCutStateKeys, setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export type TransitionStepProps = {
  modeId?: 'silence' | 'ai';
};

export const TransitionStep = ({ modeId = 'silence' }: TransitionStepProps) => {
  const intl = useIntl();
  const cuttingParameters = useAutoCutStore(
    state => state.ui.parameters[modeId]
  );

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_transition_step_title',
        defaultMessage: 'Transitions',
      })}
    >
      <FlexContainer flexDirection="column">
        {cuttingParameters.silences !== 'delete' && (
          <InfoText style="warning">
            <TranslatedMessage
              id="warning_transition_step"
              defaultMessage="You cannot use transitions without deleting silences."
            />
          </InfoText>
        )}

        <ParametersGroupLayout>
          <ParameterLayout
            title={
              <TranslatedMessage
                id="text_transition_step_default"
                defaultMessage="Default"
              />
            }
          >
            <RadioButtonsForm
              style={{ justifyContent: 'flex-end', gap: '8px' }}
              disabled={cuttingParameters.silences !== 'delete'}
              radioButtonDescriptions={[
                {
                  label: intl.formatMessage({
                    id: 'text_transition_step_none',
                    defaultMessage: 'None',
                  }),
                  value: 'none',
                  default: cuttingParameters.transistions === 'none',
                },
              ]}
              onChange={newValue =>
                setAutocutStore(
                  `ui.parameters.${modeId}.transistions` as AutoCutStateKeys,
                  newValue as typeof cuttingParameters.transistions
                )
              }
            />
          </ParameterLayout>
          <ParameterLayout
            height={'fit-content'}
            title={
              <TextWithHelper
                helperId="help_transition_step"
                helperDefault="This will add the padding after and/or before each cut. Creating a smoother transition between the cuts. If selected, it will create a new track for the transitions."
              >
                <TranslatedMessage
                  id="text_transition_step_j_l_cut"
                  defaultMessage="J-Cut & L-Cut"
                />
              </TextWithHelper>
            }
          >
            <RadioButtonsForm
              style={{
                justifyContent: 'flex-end',
                gap: '8px',
              }}
              disabled={cuttingParameters.silences !== 'delete'}
              radioButtonDescriptions={[
                {
                  label: intl.formatMessage({
                    id: 'text_transition_step_j_cut',
                    defaultMessage: 'J-Cut',
                  }),
                  value: 'j_cut',
                  default: cuttingParameters.transistions === 'j_cut',
                },
                {
                  label: intl.formatMessage({
                    id: 'text_transition_step_l_cut',
                    defaultMessage: 'L-Cut',
                  }),
                  value: 'l_cut',
                  default: cuttingParameters.transistions === 'l_cut',
                },
                {
                  label: intl.formatMessage({
                    id: 'text_transition_step_both',
                    defaultMessage: 'Both',
                  }),
                  value: 'both',
                  default: cuttingParameters.transistions === 'both',
                },
              ]}
              onChange={newValue =>
                setAutocutStore(
                  `ui.parameters.${modeId}.transistions` as AutoCutStateKeys,
                  newValue as typeof cuttingParameters.transistions
                )
              }
            />
          </ParameterLayout>

          <ParameterLayout
            title={
              <TranslatedMessage
                id="text_transition_step_premiere_pro"
                defaultMessage="Premiere Pro"
              />
            }
          >
            <RadioButtonsForm
              style={{ justifyContent: 'flex-end', gap: '8px' }}
              disabled={cuttingParameters.silences !== 'delete'}
              radioButtonDescriptions={[
                {
                  label: intl.formatMessage({
                    id: 'text_transition_constant_power',
                    defaultMessage: 'Constant Power',
                  }),
                  value: 'constant_power',
                  default: cuttingParameters.transistions === 'constant_power',
                },
              ]}
              onChange={newValue =>
                setAutocutStore(
                  `ui.parameters.${modeId}.transistions` as AutoCutStateKeys,
                  newValue as typeof cuttingParameters.transistions
                )
              }
            />
          </ParameterLayout>
        </ParametersGroupLayout>
      </FlexContainer>
    </StepLayout>
  );
};

import { REPEAT_ALGORITHM } from '@autocut/modes/repeat/Steps/Language/Parts/AlgorithmSelectStep';
import { autocutStoreVanilla } from '@autocut/utils/zustand';
import { TranscriptionProviders } from '../launchTranscript';

export const isRepeatProviderAvailable = (
  provider: TranscriptionProviders
): {
  isAvailable: boolean;
  message?: string;
} => {
  const selectedAlgorithm =
    autocutStoreVanilla().ui.parameters.repeat.selectedAlgorithm;

  if (selectedAlgorithm === REPEAT_ALGORITHM.PRECISE) {
    return {
      isAvailable: true,
    };
  }

  if (provider === 'google') {
    return {
      isAvailable: false,
      message: 'repeat_google_not_supported',
    };
  }

  return {
    isAvailable: true,
  };
};

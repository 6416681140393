import { CEPAction } from '@autocut/utils/cep/actions.cep.utils';
import { SilenceParams } from '@autocut/types/SilenceParams';

export const changeStartsAndEnds = async (
  audioTracks: Track[],
  insertIndexes: number[],
  parameters: SilenceParams
) => {
  let currentIndex = 0;

  const transistionsChangesActions: CEPAction<'changeStartAndEndOfAudioTrack'>[] =
    [];

  for (const audioTrack of audioTracks) {
    if (parameters.transistions === 'j_cut') {
      transistionsChangesActions.push({
        action: 'changeStartAndEndOfAudioTrack',
        param: [insertIndexes[currentIndex] - 1, parameters.marginBefore, 0],
        thenFunctions: [],
      });
      transistionsChangesActions.push({
        action: 'changeStartAndEndOfAudioTrack',
        param: [insertIndexes[currentIndex], parameters.marginBefore, 0],
        thenFunctions: [],
      });
    }
    if (parameters.transistions === 'l_cut') {
      transistionsChangesActions.push({
        action: 'changeStartAndEndOfAudioTrack',
        param: [insertIndexes[currentIndex] - 1, 0, parameters.marginAfter],
        thenFunctions: [],
      });
      transistionsChangesActions.push({
        action: 'changeStartAndEndOfAudioTrack',
        param: [insertIndexes[currentIndex], 0, parameters.marginAfter],
        thenFunctions: [],
      });
    }
    if (parameters.transistions === 'both') {
      transistionsChangesActions.push({
        action: 'changeStartAndEndOfAudioTrack',
        param: [
          insertIndexes[currentIndex] - 1,
          parameters.marginBefore,
          parameters.marginAfter,
        ],
        thenFunctions: [],
      });
      transistionsChangesActions.push({
        action: 'changeStartAndEndOfAudioTrack',
        param: [
          insertIndexes[currentIndex],
          parameters.marginBefore,
          parameters.marginAfter,
        ],
        thenFunctions: [],
      });
    }
    currentIndex++;
  }

  return transistionsChangesActions;
};

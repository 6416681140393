import { Button } from '@autocut/components/atoms';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { useLocale } from '@autocut/hooks/useLocale';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { closeModal } from '@autocut/utils/modal/closeModal';
import React from 'react';
import { TbTag } from 'react-icons/tb';

import css from './TrialExpiredModal.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export type TrialExpiredModalProps = {
  closeModal?: () => void;
};

export const TrialExpiredModal = ({
  closeModal: closeModalProps,
}: TrialExpiredModalProps) => {
  const { locale } = useLocale();

  return (
    <Modal
      icon={<TbTag strokeWidth={2} size="1.5rem" />}
      title={
        <p className={css.modalTitle}>
          <TranslatedMessage
            id="trial_expired_modal_title"
            defaultMessage="Your trial key has expired."
          />
        </p>
      }
      footer={
        <Button
          buttonType="primary"
          onClickFunction={() => {
            openLinkInBrowser(
              `${AUTOCUT_CONSTANTS[CURRENT_ENV].WEBSITE_ADDRESS}/${
                locale === 'EN' ? 'en/' : ''
              }?coupon=a3Z5Uumo72nw5cW#pricing`,
              true
            );
          }}
        >
          <TranslatedMessage
            id="trial_expired_modal_button"
            defaultMessage="Get a subscription (-20%)"
          />
        </Button>
      }
      closeModalFunction={closeModalProps || closeModal}
    >
      <FlexContainer flexDirection="column">
        <p>
          <TranslatedMessage
            id="trial_expired_modal_text1"
            defaultMessage="Your free trial has expired. Subscribe to AutoCut to keep using the extension."
          />
        </p>
        <br />
        <ol className={css.list}>
          <li>
            <p>
              <TranslatedMessage
                id="trial_expired_modal_text2"
                defaultMessage="Visit your account on your AutoCut dashboard at"
              />
              <span>
                <Button
                  buttonType="tertiary"
                  compact
                  color="#94a3b8"
                  className={css.link}
                  onClickFunction={() => {
                    openLinkInBrowser(
                      `${AUTOCUT_CONSTANTS[CURRENT_ENV].WEBSITE_ADDRESS}/${
                        locale === 'EN' ? 'en/' : ''
                      }?coupon=a3Z5Uumo72nw5cW#pricing`,
                      true
                    );
                  }}
                >
                  AutoCut.fr
                </Button>
              </span>
              .
            </p>
          </li>
          <li>
            <p>
              <TranslatedMessage
                id="trial_expired_modal_text3"
                defaultMessage="Subscribe to a license key."
              />
            </p>
          </li>
        </ol>
        <br />
        <p>
          <TranslatedMessage
            id="trial_expired_modal_text4"
            defaultMessage="Reach out to our support team if you need help: {supportEmail}"
            values={{
              supportEmail: 'contact@autocut.fr',
            }}
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};

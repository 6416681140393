import React, { useState } from 'react';
import FlexContainer from '../FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import {
  RadioButton,
  RadioButtonProps,
} from '../../atoms/RadioButton/RadioButton';

export type RadioFormProps = {
  options: RadioButtonProps[];
  onChange: (value: string) => void;
};

export const RadioForm = ({ options }: RadioFormProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  return (
    <FlexContainer flexDirection="column" gap={Spacing.s1}>
      {options.map((option, index) => (
        <RadioButton
          key={index}
          checked={
            selectedIndex === index ||
            (selectedIndex === undefined && option.defaultChecked)
          }
          onClick={() => setSelectedIndex(index)}
          {...option}
        />
      ))}
    </FlexContainer>
  );
};

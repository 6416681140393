import { useEffect } from 'react';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import {
  getResourcePath,
  ResourceManagerKeys,
} from '@autocut/utils/resourceManager.utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { getEncodedProjectName } from '@autocut/utils/sequence.utils';

export const ResourceManager = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { resources, treePath, isLogged } = useAutoCutStore(state => ({
    resources: state.resources,
    treePath: state.sequence.lastSettings.projectItem.treePath,
    isLogged: state.user.isLogged,
  }));
  const [projectName, setProjectName] = React.useState<string>('');
  const { logMessage } = useLogger('ResourceManager');

  useEffect(() => {
    const refreshResources = async () => {
      await Promise.all(
        Object.keys(resources).map(async key => {
          const resource = resources[key as ResourceManagerKeys];
          if (resource.required && !resource.loading && !resource.filePath) {
            logMessage(
              logLevel.info,
              `Downloading resource "${resource.fileName}"`,
              resource
            );
            setAutocutStore(
              `resources.${key as ResourceManagerKeys}.loading`,
              true
            );
            try {
              const outputPath = getResourcePath(
                resource,
                resource.scope === 'global' ? 'global' : projectName
              );
              await resource.downloadMethod(outputPath);
              setAutocutStore(
                `resources.${key as ResourceManagerKeys}.filePath`,
                outputPath
              );
            } catch (e) {
              setAutocutStore(
                `resources.${key as ResourceManagerKeys}.error`,
                (e as any).message || (e as any).toString()
              );
            } finally {
              setAutocutStore(
                `resources.${key as ResourceManagerKeys}.loading`,
                false
              );
              setAutocutStore(
                `resources.${key as ResourceManagerKeys}.required`,
                false
              );
            }
          }
        })
      );
    };

    if (isLogged) void refreshResources();
  }, [resources, isLogged]);

  useEffect(() => {
    const newProject = getEncodedProjectName();
    if (newProject && newProject !== projectName) {
      setProjectName(newProject);
      Object.keys(resources).map(async key => {
        const resource = resources[key as ResourceManagerKeys];
        if (resource.filePath) {
          setAutocutStore(
            `resources.${key as ResourceManagerKeys}.filePath`,
            undefined
          );
        }
        if (resource.loading) {
          setAutocutStore(
            `resources.${key as ResourceManagerKeys}.loading`,
            false
          );
        }
        if (resource.error) {
          setAutocutStore(
            `resources.${key as ResourceManagerKeys}.error`,
            undefined
          );
        }
      });
    }
  }, [treePath]);

  return <>{children}</>;
};

import { ProcessTranscriptFunction } from '@autocut/types/transcription';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { fetchTranscriptFromExportedAudio } from '@autocut/utils/transcript/fetchTranscriptFromExportedAudio.utils';
import { uploadAudioFileFromExportedAudio } from '@autocut/utils/transcript/uploadWAVFileFromExportedAudio.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { handleBackup } from '@autocut/utils/backup/handleBackup';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { Utterance } from '@autocut/types/Utterance';
import { IntlShape } from 'react-intl';
import { stichIntervals } from '../cut/cutUtils';
import { handleResetSequenceSettings } from '../cut/handleResetSequenceSettings';
import { saveAndPrepareSequenceSettings } from '../cut/saveAndPrepareSequenceSettings';
import { getParametersForMode } from '../parameters.utils';
import { applyVolumeFilter } from './applyVolumeFilter';
import { cutSwearWords } from './cutSwearWords';
import { getSwearWordsTimeCodes } from './getSwearWordsTimeCodes';
import { insertBleepClips } from './insertBleepClips';
import { BleepFileEnum } from './bleepFile.enum';

const beepSwearWords = async (intl: IntlShape, transcript: Utterance[]) => {
  try {
    const { bleepFile } = getParametersForMode('swear_word');
    await handleBackup(intl);
    await saveAndPrepareSequenceSettings();

    changeMessage(intl, 'swear_words_audio_analyse', 'Analysing audio...');

    const swearWords = await getSwearWordsTimeCodes(transcript);
    const swearWordsTimecodes = swearWords.map(swearWord => [
      swearWord.start,
      swearWord.end,
    ]);
    const swearWordsTimecodesStitched = stichIntervals(swearWordsTimecodes);

    const cutTimecodes = swearWordsTimecodesStitched.flat(1);

    changeMessage(intl, 'swear_words_audio_insert', 'Inserting bleeps...');

    if (bleepFile !== BleepFileEnum.Muted)
      await insertBleepClips(swearWordsTimecodesStitched, bleepFile);

    changeMessage(
      intl,
      'swear_words_audio_filter',
      'Applying volume filters...'
    );

    await cutSwearWords(cutTimecodes);
    await applyVolumeFilter(swearWordsTimecodesStitched);

    await handleResetSequenceSettings();
  } catch (e: any) {
    throw new IncrementalError(e, 'beepSwearWords');
  }
};

export const handleSwearWordsProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    const {
      sequence: { infos: sequence },
    } = autocutStoreVanilla();

    if (!sequence) {
      throw new IncrementalError(
        'Missing sequence',
        'Viral clips handleTranscript'
      );
    }

    changeMessage(intl, 'swear_words_audio_export', 'Exporting audio...');

    const exportedAudioSequenceInfos = await getExportedAudioInfos(
      {
        sequence: 'selected',
        options: {
          format: 'mp3',
        },
      },
      intl
    );
    const exportedAudioInfos = [...exportedAudioSequenceInfos.sequence];
    const exportedAudioInfosWithUrl = await uploadAudioFileFromExportedAudio(
      exportedAudioInfos,
      p => {
        setAutocutStore('onGoingProcess.progress', p);
      },
      intl
    );
    const exportedAudioInfosWithTranscript =
      await fetchTranscriptFromExportedAudio(
        exportedAudioInfosWithUrl,
        'utterances',
        usedModel
      );
    const transcript = exportedAudioInfosWithTranscript.reduce<Utterance[]>(
      (accumulatedUtterances, exportedAudioInfos) => {
        return [
          ...accumulatedUtterances,
          ...(exportedAudioInfos.transcript as Utterance[]),
        ];
      },
      []
    );

    changeMessage(
      intl,
      'viral_clips_getting_clips',
      'Generating viral clips...'
    );

    await beepSwearWords(intl, transcript);
  };

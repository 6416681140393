import {
  ModeTitle,
  ModeTitleProps,
} from '@autocut/components/atoms/ModeTitle/ModeTitle';
import {
  InvalidSelectionScreen,
  InvalidSelectionScreenProps,
} from '@autocut/components/partials/InvalidSelectionScreen/InvalidSelectionScreen';
import * as React from 'react';
import { IconType } from 'react-icons';

export type ModeWrapperProps = {
  Icon?: IconType;
  text: string;
  displayCondition?: boolean;
  invalidSelectionScreen?: Omit<InvalidSelectionScreenProps, 'Icon'>;
  children: React.ReactNode;
  customHomeButtonHandler?: ModeTitleProps['customHomeButtonHandler'];
};

export const ModeWrapper = ({
  Icon,
  text,
  displayCondition,
  invalidSelectionScreen,
  children,
  customHomeButtonHandler,
}: ModeWrapperProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingTop: '8px',
      }}
    >
      <ModeTitle
        Icon={Icon}
        text={text}
        customHomeButtonHandler={customHomeButtonHandler}
      />
      <div className="dashboardContainer">
        {displayCondition || displayCondition === undefined ? (
          children
        ) : invalidSelectionScreen ? (
          <InvalidSelectionScreen {...invalidSelectionScreen} />
        ) : (
          <p>Missing invalidSelectionScreen</p>
        )}
      </div>
    </div>
  );
};

import { useDrawWordBoxes } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/HighlightBoxes/useDrawWordBoxes';
import { CaptionCanvasObjectHook } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import { useCanvasObject } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionDrawHooks.utils';

export const useWordBoxesCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawWordBoxes(args);

  return useCanvasObject({
    name: 'highlightBoxes',
    paths: {
      x: 'ui.parameters.caption.position.xPercentage',
      y: 'ui.parameters.caption.position.yPercentage',
      enabled: 'ui.parameters.caption.animations.wordBox.enabled',
    },
    ...drawReturn,
    ...args,
  });
};

import { Canvas } from '@autocut/utils/captions/canvas/classes/canvas.class.utils';

type CanvasConstructor<C extends Canvas> = {
  new (...args: any[]): C;
  prototype: C;
};

export type AnimatedCanvasType<T extends Canvas> = T & {
  start(): void;
  stop(): void;
  createVideo(): Promise<Blob>;
  createThumbnail(): Promise<Blob>;
  loop: boolean;
  fps: number;
  duration: number;
  fpsCap: number;
  video: Blob | null;
  thumbnail: Blob | null;
};

/**
 * AnimatedCanvas adds the ability to refresh the canvas at a specific framerate to allow fluid animations.
 *
 * Typescript mixin : https://www.typescriptlang.org/docs/handbook/mixins.html
 * Allow AnimatedCanvas to extend any Canvas extended class
 * @template T - The type of the Canvas constructor.
 * @param {T} CanvasClass - The Canvas constructor.
 * @param {number} [fpsParam=24] - The frames per second for the animation.
 * @returns {Class} - The AnimatedCanvas class.
 */
export const AnimatedCanvas = <T extends CanvasConstructor<Canvas>>(
  CanvasClass: T,
  options: {
    animation: {
      loop?: boolean;
      fps?: number;
      duration?: number;
    };
    fpsCap?: number;
    showFps?: boolean;
  }
) => {
  return class AnimatedCanvas extends CanvasClass {
    public loop: boolean;
    public fps: number;
    public duration: number;
    public fpsCap: number; //Limit render per seconds
    public video: Blob | null;
    public thumbnail: Blob | null;

    //FPS management
    private paused = true;
    private startTime = 0;
    private lastDrawTime = 0;

    /**
     * Constructs a new instance of the AnimatedCanvas class.
     * @param {...any[]} args - Arguments to pass to the base canvas constructor.
     */
    constructor(...args: any[]) {
      super(...args);

      this.loop = options.animation?.loop || false;
      this.fps = options.animation?.fps || 24;
      this.duration = options.animation?.duration || 240;
      this.fpsCap = options.fpsCap || 120;
      this.video = null;
      this.thumbnail = null;

      this.ctx.imageSmoothingEnabled = true;
      this.ctx.imageSmoothingQuality = 'high';

      return this;
    }

    destroy(): void {
      this.stop();
      super.destroy();
    }

    /**
     * Starts the animation loop.
     */
    start() {
      this.paused = false;
      this.startTime = (document.timeline.currentTime as number) || 0;
      window.requestAnimationFrame(() => this.drawFrame(this.startTime));
    }

    /**
     * Stops the animation loop.
     */
    stop() {
      this.paused = true;
    }

    private drawFrame(timestamp: number) {
      if (this.paused) return;

      const timeSinceStart = timestamp - this.startTime;
      const timeSinceLastDraw = timestamp - this.lastDrawTime;
      const progress = timeSinceStart / (this.duration * 1000);

      const frame = Math.round((timeSinceStart * this.fps) / 1000);

      if (progress < 1) {
        // + 0.1 to avoid 0.3332 < 0.3333 that cause 1 frame out of 2 skiped
        if (timeSinceLastDraw + 0.1 >= 1000 / this.fps) {
          this.lastDrawTime = timestamp;
          super.draw(
            { frame, progress },
            {
              additionnalDraw(canvas: AnimatedCanvas) {
                if (canvas.debug || options.showFps) {
                  const canvasBBox = canvas.canvasRef.getBoundingClientRect();

                  const framerate = 1000 / timeSinceLastDraw;

                  canvas.ctx.fillStyle = 'white';
                  canvas.ctx.textAlign = 'right';
                  canvas.ctx.font = '12px Arial';
                  canvas.ctx.fillText(
                    `f-${frame > 9 ? frame : `0${frame}`}`,
                    canvasBBox.width - 10,
                    10
                  );
                  if (framerate >= canvas.fps * 0.9) {
                    canvas.ctx.fillStyle = 'green';
                  } else if (framerate >= canvas.fps * 0.6) {
                    canvas.ctx.fillStyle = 'orange';
                  } else {
                    canvas.ctx.fillStyle = 'red';
                  }
                  canvas.ctx.fillText(
                    `${framerate.toFixed(0)}fps`,
                    canvasBBox.width - 10,
                    30
                  );
                }
              },
            }
          );
        }
        window.requestAnimationFrame(t => this.drawFrame(t));
      } else {
        if (this.loop) {
          this.start();
        } else {
          this.stop();
        }
      }
    }

    /**
     * Draws the canvas with the current frame.
     * @param {any} [arg] - Additional arguments to pass to the base draw method.
     */
    draw() {
      //Draw do not do anything anymore
      return;
    }

    public async createVideo() {
      const stream = this.canvasRef.captureStream(this.fps);
      const recorder = new MediaRecorder(stream, {
        mimeType: 'video/webm',
      });

      const chunks: Blob[] = [];
      recorder.ondataavailable = e => {
        if (e.data.size) {
          chunks.push(e.data);
        }
      };

      super.draw({ frame: 0, progress: 0 });
      recorder.start();
      this.start();

      setTimeout(() => {
        recorder.stop();
        this.stop();
      }, this.duration * 1000);

      return new Promise<Blob>(resolve => {
        recorder.onstop = () => {
          this.video = new Blob(chunks, { type: 'video/webm' });
          resolve(this.video);
        };
      });
    }

    public createThumbnail = async (thumbnailSecond = 1) => {
      super.draw({
        frame: (thumbnailSecond % this.duration) * this.fps,
        progress: (thumbnailSecond % this.duration) / this.duration,
      });
      this.stop();

      this.thumbnail = await new Promise(resolve => {
        this.canvasRef.toBlob(blob => {
          resolve(blob);
        });
      });

      this.start();
      return this.thumbnail as Blob;
    };
  };
};

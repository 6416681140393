import React, { CSSProperties } from 'react';

import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import css from './LicenceTypeTag.module.css';
import { LicenceKeyType } from '@autocut/types/isKeyValidResponse';

export type LicenceTypeTagProps = {
  licenceType: LicenceKeyType;
  size?: CSSProperties['fontSize'];
};

const LicenceTypeTag = ({ licenceType, size }: LicenceTypeTagProps) => {
  return (
    <button
      style={
        {
          '--licence-tag-size': size,
        } as CSSProperties
      }
      className={css.licenceTag}
      onClick={() => {
        openLinkInBrowser(AUTOCUT_CONSTANTS[CURRENT_ENV].WEBAPP_ADDRESS);
      }}
    >
      {licenceType}
    </button>
  );
};

export default LicenceTypeTag;

import { BRoll } from '@autocut/types/BRolls';
import { ProcessTranscriptFunction } from '@autocut/types/transcription';
import { Utterance } from '@autocut/types/Utterance';
import { handleBackup } from '@autocut/utils/backup/handleBackup';
import { getPexelVideos } from '@autocut/utils/bRolls/getPexelVideos';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { handleFinalMessage } from '@autocut/utils/cutButton/handleFinalMessage';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { getParametersForMode } from '@autocut/utils/parameters.utils';
import { fetchTranscriptFromExportedAudio } from '@autocut/utils/transcript/fetchTranscriptFromExportedAudio.utils';
import { uploadAudioFileFromExportedAudio } from '@autocut/utils/transcript/uploadWAVFileFromExportedAudio.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';

export const handleBrollProcessTranscript: ProcessTranscriptFunction = async (
  intl,
  usedModel = 0
) => {
  const { minimumBRollTime, maximumBRollTime } = getParametersForMode('broll');

  const {
    sequence: { infos: sequence },
    ui: {
      parameters: {
        broll: { languageOfTranscription },
      },
    },
  } = autocutStoreVanilla();

  if (!sequence || !maximumBRollTime || !minimumBRollTime) {
    throw new IncrementalError('Missing parameters', 'B-Roll handleTranscript');
  }

  changeMessage(intl, 'broll_backup', 'Backing up your sequence...');
  await handleBackup(intl);

  const exportedAudioSequenceInfos = await getExportedAudioInfos(
    {
      sequence: 'selected',
      options: {
        format: 'mp3',
      },
    },
    intl
  );
  const exportedAudioInfos = [...exportedAudioSequenceInfos.sequence];
  const exportedAudioInfosWithUrl = await uploadAudioFileFromExportedAudio(
    exportedAudioInfos,
    p => {
      setAutocutStore('onGoingProcess.progress', p);
    },
    intl
  );
  setAutocutStore('onGoingProcess.progress', undefined);

  changeMessage(intl, 'broll_analyse', 'Analysing audio...');

  const exportedAudioInfosWithTranscript =
    await fetchTranscriptFromExportedAudio(
      exportedAudioInfosWithUrl,
      'utterances',
      usedModel
    );

  changeMessage(intl, 'broll_getting_broll', 'Getting B-Rolls...');

  const bRolls: BRoll[] = [];
  for (const exportedAudioInfo of exportedAudioInfosWithTranscript) {
    bRolls.push(
      ...(await getPexelVideos({
        transcript: exportedAudioInfo.transcript as Utterance[],
        minDuration: minimumBRollTime,
        maxDuration: maximumBRollTime,
        resolution: {
          width: sequence.settings.width,
          height: sequence.settings.height,
        },
        maxWordsPerChunk: languageOfTranscription.maxWordsPerChunk,
      }))
    );
  }

  setAutocutStore('onGoingProcess.bRolls', bRolls);

  handleFinalMessage(intl, 'broll_transcript_finished', 'Transcript generated');
};

import { RgbColor } from 'react-colorful';

export const hexToRgb = (hex: string): RgbColor => {
  // Remove the hash symbol if it exists
  hex = hex.replace('#', '');

  // Convert the hex string to a number
  const hexNum = parseInt(hex, 16);

  // Extract the red, green, and blue components from the hex number
  const r = (hexNum >> 16) & 255;
  const g = (hexNum >> 8) & 255;
  const b = hexNum & 255;

  // Return the RGB object
  return { r, g, b };
};

export const rgbToHex = (rgb: RgbColor, withPrefix = true): string => {
  // Convert the RGB components to hex strings
  const rHex = rgb.r.toString(16).padStart(2, '0');
  const gHex = rgb.g.toString(16).padStart(2, '0');
  const bHex = rgb.b.toString(16).padStart(2, '0');

  const prefix = withPrefix ? '#' : '';
  return `${prefix}${rHex}${gHex}${bHex}`;
};

export const rgbToHsl = (rgb: RgbColor) => {
  // Normalize the RGB values
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;

  // Find the maximum and minimum values of the RGB components
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the lightness
  const lightness = (max + min) / 2;

  // Calculate the saturation
  let saturation = 0;
  if (max !== min) {
    const delta = max - min;
    saturation = delta / (1 - Math.abs(2 * lightness - 1));
  }

  // Calculate the hue
  let hue = 0;
  if (max === r) {
    hue = ((g - b) / (max - min)) % 6;
  } else if (max === g) {
    hue = (b - r) / (max - min) + 2;
  } else {
    hue = (r - g) / (max - min) + 4;
  }
  // max===min
  isNaN(hue) && (hue = 0);

  hue *= 60;
  if (hue < 0) {
    hue += 360;
  }

  // Return the HSL object
  return { h: hue, s: saturation, l: lightness };
};

export const HSLToRGB = (hsl: {
  h: number;
  s: number;
  l: number;
}): RgbColor => {
  const { h, s, l } = hsl;

  // Convert the hue to a value between 0 and 1
  const hue = h / 360;

  // Calculate the chroma
  const chroma = (1 - Math.abs(2 * l - 1)) * s;

  // Calculate the intermediate values
  const x = chroma * (1 - Math.abs(((hue * 6) % 2) - 1));
  const m = l - chroma / 2;

  let r = 0;
  let g = 0;
  let b = 0;

  // Determine the RGB values based on the hue
  if (hue >= 0 && hue < 1 / 6) {
    r = chroma;
    g = x;
  } else if (hue >= 1 / 6 && hue < 2 / 6) {
    r = x;
    g = chroma;
  } else if (hue >= 2 / 6 && hue < 3 / 6) {
    g = chroma;
    b = x;
  } else if (hue >= 3 / 6 && hue < 4 / 6) {
    g = x;
    b = chroma;
  } else if (hue >= 4 / 6 && hue < 5 / 6) {
    r = x;
    b = chroma;
  } else {
    r = chroma;
    b = x;
  }

  // Add the intermediate values and convert to the RGB range
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  // Return the RGB object
  return { r, g, b };
};

export const HSLtoHex = (hsl: { h: number; s: number; l: number }): string => {
  const rgb = HSLToRGB(hsl);
  return rgbToHex(rgb);
};

export const hexToHexOpacity = (hex: string, opacity: number): string => {
  const opacityHex = Math.round((opacity / 100) * 255)
    .toString(16)
    .padStart(2, '0');
  return `${hex}${opacityHex}`;
};

export const rgbToInteger = (rgb: RgbColor) => {
  let { r, g, b } = rgb;

  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  const colorInteger = (r << 16) + (g << 8) + b;

  return colorInteger;
};

export const randomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  return rgbToHex({ r, g, b });
};

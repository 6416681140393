import { useRelativeMousePosition } from '@autocut/components/atoms/PreviewFrame/useMousePosition';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { evalTS } from '@autocut/lib/utils/bolt';
import { ExampleCaption } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/ExampleCaption';
import {
  exportCurrentFrameAsPNG,
  NO_ACTIVE_SEQUENCE_ERROR_MESSAGE,
} from '@autocut/utils/previewFrame';
import { getCursorTime } from '@autocut/utils/time.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect, useMemo } from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { LoaderInfinity } from '../LoaderInfinity/LoaderInfinity';
import { ZoomAnchor, ZoomAnchorProps } from '../ZoomAnchor/ZoomAnchor';
import css from './PreviewFrame.module.css';
import { getPositionWithAnchors } from './usePreviewAnchors';
import { Button } from '../Buttons/Button';

type InterfaceZoomAnchorProps = Omit<ZoomAnchorProps, 'sequenceDimension'>;

export type AnchorPreviewFrameInterface = {
  type: 'anchor';
  additionalProps: Omit<InterfaceZoomAnchorProps, 'x' | 'y'>;
};

export type CaptionPreviewFrameInterface = {
  type: 'captions';
};

export type PreviewFrameInterfaces =
  | AnchorPreviewFrameInterface
  | CaptionPreviewFrameInterface;

export type PreviewFrameProps = {
  interfaces?: PreviewFrameInterfaces[];
  initialPosition?: { x: number; y: number };
  anchors?: boolean | number | { x?: boolean | number; y?: boolean | number };
  anchorsDisplay?: boolean | { x?: boolean; y?: boolean };
  cursorBounds?: [number, number];
  debug?: boolean;
  maxHeight?: `${number}px` | '100%';
  withPreviewButton?: boolean;
  onClick?: () => void;
};

const DEFAULT_ANCHOR_THRESHOLD = { x: 10, y: 10 };

export const PreviewFrame = ({
  interfaces,
  initialPosition,
  anchors,
  anchorsDisplay,
  cursorBounds,
  debug,
  maxHeight = '400px',
  withPreviewButton = true,
  onClick,
}: PreviewFrameProps) => {
  const [isError, setIsError] = React.useState(false);
  const [isMissingSequence, setIsMissingSequence] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const lastSequenceSettings = useAutoCutStore(
    state => state.sequence.lastSettings
  );

  const [src, setSrc] = React.useState('');

  const [placeholderContainerStyle, setPlaceholderContainerStyle] =
    React.useState<React.CSSProperties>({});
  const [imageInterfaceStyle, setImageInterfaceStyle] = React.useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  const imgContainerRef = React.useRef<HTMLDivElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  const threshold = !anchors
    ? { x: 0, y: 0 }
    : anchors === true
    ? DEFAULT_ANCHOR_THRESHOLD
    : typeof anchors === 'number'
    ? { x: anchors, y: anchors }
    : {
        x:
          typeof anchors.x === 'number'
            ? anchors.x
            : DEFAULT_ANCHOR_THRESHOLD.x,
        y:
          typeof anchors.y === 'number'
            ? anchors.y
            : DEFAULT_ANCHOR_THRESHOLD.y,
      };

  const thresholdX = threshold.x;
  const thresholdY = threshold.y;

  const {
    x,
    xClicked,
    y,
    yClicked,
    boundingBox,
    mouseIn,
    isClicked,
    reset: resetMousePos,
  } = useRelativeMousePosition(imgRef, {
    clickRef: imgContainerRef,
    defaultValue: initialPosition,
  });

  const [anchoredX, showXAnchor, anchoredY, showYAnchor] = useMemo(() => {
    if (!anchors || !boundingBox) return [x, y];

    const xMiddle = boundingBox?.width / 2;
    const yMiddle = boundingBox?.height / 2;

    const newX = Math.abs(x - xMiddle) < thresholdX ? xMiddle : x;
    const newY = Math.abs(y - yMiddle) < thresholdY ? yMiddle : y;

    const showXAnchor =
      anchorsDisplay === undefined
        ? true
        : typeof anchorsDisplay === 'boolean'
        ? anchorsDisplay
        : anchorsDisplay.x ?? true;
    const showYAnchor =
      anchorsDisplay === undefined
        ? true
        : typeof anchorsDisplay === 'boolean'
        ? anchorsDisplay
        : anchorsDisplay.y ?? true;

    return [
      newX,
      newX === xMiddle && showXAnchor,
      newY,
      newY === yMiddle && showYAnchor,
    ];
  }, [anchors, boundingBox, x, y]);

  const {
    newPosition: [anchoredClickedX, anchoredClickedY],
  } = getPositionWithAnchors(
    [boundingBox?.width / 2, boundingBox?.height / 2],
    [xClicked, yClicked],
    [thresholdX, thresholdY]
  );

  const onImageLoaded = () => {
    checkIfRatioChanged();
    updatePlaceholderWidth();
    updateImageInterfaceDimension();
  };

  // Ratio of image can change if switching between 2 differents sequence
  const checkIfRatioChanged = () => {
    if (!imgRef.current) return;

    const lastBBox = imageInterfaceStyle;
    const lastRatio = lastBBox.width / lastBBox.height;
    const bbImg = imgRef.current.getBoundingClientRect();
    const currentRatio = bbImg.width / bbImg.height;

    if (lastRatio != currentRatio) resetMousePos();
  };

  const updateImageInterfaceDimension = () => {
    if (!imgRef.current) return;

    const bbImg = imgRef.current.getBoundingClientRect();
    setImageInterfaceStyle({
      height: bbImg.height,
      width: bbImg.width,
    });
  };

  const updatePlaceholderWidth = () => {
    const placeholderStyle = {
      background: '#ffffff20',
      borderRadius: 8,
    };

    if (!imgContainerRef.current) {
      setPlaceholderContainerStyle(placeholderStyle);
      return;
    }

    const bboxImgContainer = imgContainerRef.current.getBoundingClientRect();
    if (bboxImgContainer.height <= 0) {
      setPlaceholderContainerStyle(placeholderStyle);
      return;
    }

    setPlaceholderContainerStyle({
      ...placeholderStyle,
      height: bboxImgContainer.height,
    });
  };

  const updatePreview = async () => {
    setIsLoading(true);

    const cursorTime = (await getCursorTime())?.secs || -1;
    const isPlayerOutOfBounds =
      cursorBounds?.length === 2 &&
      !(cursorTime >= cursorBounds[0] && cursorTime <= cursorBounds[1]);

    exportCurrentFrameAsPNG(
      src,
      isPlayerOutOfBounds ? cursorBounds[0] + 0.1 : undefined
    )
      .then(blobUrl => {
        setSrc(blobUrl);
        setIsLoading(false);
        setIsError(false);
        setIsMissingSequence(false);
        updatePlaceholderWidth();
      })
      .catch((e: Error) => {
        console.log('ERROR', e);
        if (e.message.includes(NO_ACTIVE_SEQUENCE_ERROR_MESSAGE)) {
          setIsError(false);
          setIsLoading(false);
          setIsMissingSequence(true);
        } else {
          setIsError(true);
          setIsLoading(false);
          setIsMissingSequence(false);
        }
        updatePlaceholderWidth();
      });
  };

  useEffect(() => {
    void updatePreview();
  }, [
    lastSequenceSettings.width,
    lastSequenceSettings.height,
    lastSequenceSettings.projectItem.nodeId,
  ]);

  // Manage window resize
  useEffect(() => {
    window.addEventListener('resize', updateImageInterfaceDimension);

    return () => {
      window.removeEventListener('resize', updateImageInterfaceDimension);
    };
  }, []);

  return (
    <div className={css.previewContainer}>
      {isError || isMissingSequence ? (
        <div
          className={css.placeholderContainer}
          style={placeholderContainerStyle}
        >
          <TranslatedMessage
            id={
              isMissingSequence
                ? 'preview_frame_no_sequence'
                : 'autozoom_preview_error_message'
            }
            defaultMessage={'An error occurred while loading the preview'}
          />
          <div
            className={css.center}
            style={{ width: '50%' }}
            onClick={updatePreview}
          >
            <TranslatedMessage
              id="autozoom_preview_error_button"
              defaultMessage="Retry"
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={css.placeholderContainer}
            style={{
              ...placeholderContainerStyle,
              visibility: isLoading ? 'visible' : 'hidden', // Use of visibility to keep the img loaded and prevent flashing
            }}
          >
            <LoaderInfinity height={300} />
          </div>
          <div
            className={css.imgContainer}
            style={{
              visibility: isLoading ? 'hidden' : 'visible',
            }}
            ref={imgContainerRef}
          >
            <div
              className={css.interfacesContainer}
              style={imageInterfaceStyle}
              onClick={onClick}
            >
              {/* If we want to push this system further we can add a HOC that handle all the mouseEvent/coordinates computation
                  and nest all the interfaces.
                  For now each interfaces will have it's own logic.
              */}
              {!!imageInterfaceStyle.width &&
                !!imageInterfaceStyle.height &&
                interfaces &&
                interfaces.map((interfaceComponent, index) => {
                  switch (interfaceComponent.type) {
                    case 'anchor':
                      return (
                        <ZoomAnchor
                          key={`anchor${index}`}
                          {...interfaceComponent.additionalProps}
                          x={anchoredClickedX}
                          y={anchoredClickedY}
                        />
                      );
                    case 'captions':
                      return (
                        <ExampleCaption
                          key={`captions${index}`}
                          boundingBox={imageInterfaceStyle}
                          debug={debug}
                        />
                      );
                  }
                })}
            </div>
            {anchors ? (
              <div className={css.anchorContainer} style={imageInterfaceStyle}>
                {showXAnchor && isClicked ? (
                  <div className={css.anchorVertical} />
                ) : null}
                {showYAnchor && isClicked ? (
                  <div className={css.anchorHorizontal} />
                ) : null}
              </div>
            ) : null}
            <img
              ref={imgRef}
              onLoad={onImageLoaded}
              src={src}
              style={{
                maxHeight,
                height: '100%',
              }}
            />
            {debug ? (
              <div className={css.debuger}>
                <span>
                  x:{x} ({anchoredX}) | y:{y} ({anchoredY})
                </span>
                <br />
                <span>
                  xClicked:{xClicked} ({anchoredClickedX}) | yClicked:
                  {yClicked} ({anchoredClickedY})
                </span>
                <br />
                <span>
                  Box: x: {boundingBox?.left} | y: {boundingBox?.top} (
                  {boundingBox?.width}x{boundingBox?.height})
                </span>
                <br />
                <span>{mouseIn ? 'Inside' : 'Outside'}</span>
              </div>
            ) : null}
          </div>
          {isLoading || isError || isMissingSequence
            ? null
            : withPreviewButton && (
                <Button
                  buttonType="tertiary"
                  compact
                  style={{
                    width: '100%',
                    height: '28px',
                    minWidth: '240px',
                    color: 'white',
                  }}
                  onClickFunction={async () => {
                    try {
                      const rawSequenceSettings = await evalTS(
                        'getSequenceSettings'
                      );
                      const sequenceSettings =
                        autocutStoreVanilla().sequence.lastSettings;

                      setAutocutStore('sequence.lastSettings', {
                        ...sequenceSettings,
                        width: rawSequenceSettings.videoFrameWidth,
                        height: rawSequenceSettings.videoFrameHeight,
                      });

                      await updatePreview();
                    } catch {
                      setIsError(true);
                      setIsLoading(false);
                    }
                  }}
                >
                  <TranslatedMessage
                    id="autozoom_preview_on_indicator"
                    defaultMessage="Preview on indicator"
                  />
                </Button>
              )}
        </>
      )}
    </div>
  );
};

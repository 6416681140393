import React from 'react';

import css from './Tabs.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

type TabProps = {
  onClick: () => void;
  isActive: boolean;
  children: React.ReactNode;
};

type TabsProps = {
  direction?: 'row' | 'column';
  content: {
    TabContent: React.ReactNode;
    Panel: React.ReactNode;
    value: string;
  }[];
};

export const Tabs = ({ direction = 'column', content }: TabsProps) => {
  const [activeTab, setActiveTab] = React.useState(content[0].value);
  return (
    <FlexContainer flexDirection={direction} className={css.container} gap={8}>
      <FlexContainer
        flexDirection={direction === 'row' ? 'column' : 'row'}
        gap={0}
        className={css.header}
      >
        {content.map(({ TabContent, value }) => (
          <Tab
            key={value}
            isActive={activeTab === value}
            onClick={() => setActiveTab(value)}
          >
            {TabContent}
          </Tab>
        ))}
      </FlexContainer>

      <FlexContainer flexDirection="column" className={css.panelContainer}>
        {content.find(({ value }) => value === activeTab)?.Panel}
      </FlexContainer>
    </FlexContainer>
  );
};

const Tab = ({ children, onClick, isActive }: TabProps) => {
  const classNames = [css.tab, isActive ? css.active : ''].join(' ');
  return (
    <div onClick={onClick} className={classNames}>
      {children}
    </div>
  );
};

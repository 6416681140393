import { resourcesConfigs } from '@autocut/enums/resources.enum';
import {
  getResourcePath,
  getResourcesFolder,
  waitForResource,
} from '../../resourceManager.utils';
import { unzip } from '../../unzip.utils';
import path from 'path';
import { createDirectory } from '@autocut/utils/createDirectory.utils';

export const importXMLProjectResources = async (folderName: string) => {
  const mogrtResources = resourcesConfigs['caption_resources'];
  await waitForResource(mogrtResources);
  const resourcesPath = getResourcePath(mogrtResources);

  const directoryPath = path.join(getResourcesFolder(), folderName);
  await createDirectory(directoryPath);
  await unzip(resourcesPath, directoryPath);

  const xmlProject = resourcesConfigs['captions_xml_project'];
  await waitForResource(xmlProject);
  const xmlProjectPath = getResourcePath(xmlProject);

  return {
    xmlProjectPath,
  };
};

import * as React from 'react';

import './noiseRange.css';

type Props = {
  noiseLimit: number;
  setNoiseLimit: any;
};

export const NoiseRange = ({ noiseLimit, setNoiseLimit }: Props) => {
  return (
    <div className={'range_container'}>
      <output
        className={'displayValue'}
        style={{
          left: `calc(${100 * ((-50 - noiseLimit) / -50)}%`,
        }}
      >
        {noiseLimit} dB
      </output>
      <input
        type="range"
        value={noiseLimit}
        step={1}
        min={-50}
        max={0}
        className={'range_input'}
        onChange={(e: any) => setNoiseLimit(e)}
      />
    </div>
  );
};

import { PreviewFrame } from '@autocut/components/atoms/PreviewFrame/PreviewFrame';
import { TextWithHelper } from '@autocut/components/atoms/TextWithHelper';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useMemo } from 'react';
import css from './PreviewZoomStep.module.css';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { InputRangeParameter } from '@autocut/components/atoms/Parameters/InputRangeParamater/InputRangeParameter';

export const PreviewZoomStep = () => {
  const { dynamicMinZoomIntensity, autoZoomMaxCoef } = useAutoCutStore(
    state => state.ui.parameters.zoom
  );
  const autoZoomMinCoef = useMemo(
    () => 1 + dynamicMinZoomIntensity * (autoZoomMaxCoef - 1),
    [autoZoomMaxCoef, dynamicMinZoomIntensity]
  );

  return (
    <div className={css.previewZoomStep}>
      <div className={css.stepTitle}>
        <TextWithHelper
          helperId="autozoom_anchor_helper"
          helperDefault="The anchor point is where the zoom will be applied. You can visualize the effect of maximum and minimum zoom using the colored boxes."
        >
          <TranslatedMessage
            id="autozoom_anchor_title"
            defaultMessage="Define the anchor point"
          />
        </TextWithHelper>
      </div>
      <div className={css.previewContainer}>
        <PreviewFrame
          interfaces={[
            {
              type: 'anchor',
              additionalProps: {
                coeffZoomMin: autoZoomMinCoef,
                coeffZoomMax: autoZoomMaxCoef,
                onChange: (anchorPosMinCoef, anchorPosMaxCoef) => {
                  setAutocutStore(
                    'ui.parameters.zoom.anchorPosMinCoef',
                    anchorPosMinCoef
                  );
                  setAutocutStore(
                    'ui.parameters.zoom.anchorPosMaxCoef',
                    anchorPosMaxCoef
                  );
                },
              },
            },
          ]}
        />
      </div>

      <ParameterLayout
        title={
          <p style={{ color: 'white' }}>
            <TranslatedMessage
              id="autozoom_max_zoom"
              defaultMessage="Maximum zoom"
            />
          </p>
        }
      >
        <InputRangeParameter
          value={autoZoomMaxCoef}
          step={0.05}
          min={1}
          max={2}
          unit="%"
          unitFormatting={value => `${(value * 100).toFixed()}`}
          onChange={value =>
            setAutocutStore(
              'ui.parameters.zoom.autoZoomMaxCoef',
              parseFloat(value)
            )
          }
        />
      </ParameterLayout>
    </div>
  );
};

import { Utterance } from '@autocut/types/Deepgram';
import { ProcessTranscriptFunction } from '@autocut/types/transcription';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { delimitTakes } from '@autocut/utils/repeat/delimitTakes';
import { fetchTranscriptFromExportedAudio } from '@autocut/utils/transcript/fetchTranscriptFromExportedAudio.utils';
import { uploadAudioFileFromExportedAudio } from '@autocut/utils/transcript/uploadWAVFileFromExportedAudio.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { REPEAT_ALGORITHM } from './Parts/AlgorithmSelectStep';

export const handleRepeatProcessTranscript: ProcessTranscriptFunction = async (
  intl,
  usedModel = 0
) => {
  const exportedAudioSequenceInfos = await getExportedAudioInfos(
    {
      sequence: 'selected',
      options: {
        format: 'mp3',
      },
    },
    intl
  );
  const exportedAudioInfos = [...exportedAudioSequenceInfos.sequence];

  setAutocutStore('onGoingProcess.progress', 0);
  const exportedAudioInfosWithUrl = await uploadAudioFileFromExportedAudio(
    exportedAudioInfos,
    p => {
      setAutocutStore('onGoingProcess.progress', p);
    },
    intl
  );
  setAutocutStore('onGoingProcess.progress', undefined);
  changeMessage(intl, 'broll_analyse', 'Analysing audio...');

  const selectedAlgorithm =
    autocutStoreVanilla().ui.parameters.repeat.selectedAlgorithm;

  const url =
    selectedAlgorithm === REPEAT_ALGORITHM.PRECISE
      ? 'delimited-repetitions'
      : 'utterances';
  const skipEmbedding = selectedAlgorithm === REPEAT_ALGORITHM.PRECISE;
  const exportedAudioInfosWithTranscript =
    await fetchTranscriptFromExportedAudio(
      exportedAudioInfosWithUrl,
      url,
      usedModel,
      skipEmbedding
    );

  const delimitedUtterances = delimitTakes(
    exportedAudioInfosWithTranscript.flatMap(
      audio => audio.transcript
    ) as Utterance[]
  );

  setAutocutStore('onGoingProcess.delimitedTranscript', delimitedUtterances);

  setAutocutStore('ui.cutButtonMessage', undefined);
};

import { crypto, fs } from '@autocut/lib/cep/node';
import { XMLSequence } from './XMLSequence';
import { getResourcePath } from '../resourceManager.utils';
import { XMLProjectItem } from './XMLProjectItem';
import { XMLVideoMediaSource } from './XMLMediaSource';
import { xmlToPrProjFile } from './xmlToPrProjFile';
import { XMLBinProjectItem } from './XMLBinProjectItem';

export class XMLDocument extends Document {
  public lastObjectId: number;
  public rootItems: XMLProjectItem[];
  public medias: XMLVideoMediaSource[];
  public binProjectItems: XMLBinProjectItem[] | undefined = undefined;
  public sequences: XMLSequence[] | undefined = undefined;

  constructor(filePath: string) {
    super();

    const xmlData = fs
      .readFileSync(filePath, 'utf8')
      .replace(/<\??xml.*>/, '')
      .replace(/\\n/gm, '\n')
      .replace(/\\t/gm, '\t')
      .replace(/\\"/gm, '"');
    const domParser = new DOMParser();
    const parsedDocument = domParser.parseFromString(xmlData, 'text/xml');

    const newRootElement = this.createElement('rootElement');
    this.appendChild(newRootElement);

    for (const node of parsedDocument.childNodes) {
      newRootElement.appendChild(this.importNode(node, true));
    }

    const objectIds = this.querySelectorAll('[ObjectID]');

    this.lastObjectId = parseInt(
      objectIds[objectIds.length - 1].getAttribute('ObjectID') ?? '0'
    );

    const rootProjectItem = this.querySelector('RootProjectItem[ObjectUID]');
    const rootProjectItemChildrenItems =
      rootProjectItem?.querySelectorAll('Item');

    this.rootItems = Array.from(rootProjectItemChildrenItems ?? [])
      .map(rootProjectItem => {
        const objectUID = rootProjectItem.getAttribute('ObjectURef') ?? '';
        const clipProjectItem = this.findElementWithObjectId(
          ['ClipProjectItem'],
          objectUID
        ) as Element;

        //clipProjectItem is undefined if it is a folder as well
        if (!clipProjectItem) return undefined;

        const masterClipRef = clipProjectItem?.querySelector(
          'MasterClip'
        ) as Element;
        const masterClipId = masterClipRef?.getAttribute('ObjectURef') ?? '';

        const masterClip = this.findElementWithObjectId(
          ['MasterClip'],
          masterClipId
        ) as Element;

        return new XMLProjectItem({
          refElement: rootProjectItem,
          document: this,
          clipProjectItem,
          masterClip,
        });
      })
      .filter(Boolean) as XMLProjectItem[];
    this.rootItems = [];

    const medias = this.getMedias();
    this.medias = medias.map(
      media => new XMLVideoMediaSource({ element: media, document: this })
    );

    this.initBinaryHashesValues();

    return this;
  }

  public getSequences() {
    if (this.sequences) return this.sequences;

    const sequences = this.querySelectorAll('Sequence[ObjectUID]');

    const xmlSequences = Array.from(sequences).map(sequence => {
      return new XMLSequence(sequence, this);
    });

    this.sequences = xmlSequences;

    return xmlSequences;
  }

  public getBinProjectItems() {
    if (this.binProjectItems) return this.binProjectItems;

    const binProjectItems = this.querySelectorAll('BinProjectItem[ObjectUID]');

    this.binProjectItems = Array.from(binProjectItems).map(
      binProjectItem => new XMLBinProjectItem(binProjectItem, this)
    );

    return this.binProjectItems;
  }

  public async exportToPrproj(newFileName: string) {
    const xmlData = this.documentElement.innerHTML;
    const xmlFileName = getResourcePath({ fileName: newFileName + '.xml' });
    fs.writeFileSync(xmlFileName, xmlData);

    const prprojFileName = getResourcePath({
      fileName: newFileName + '.prproj',
    });
    await xmlToPrProjFile(xmlFileName, prprojFileName);

    return prprojFileName;
  }

  public exportToXml(filePath: string) {
    const xmlData = this.documentElement.innerHTML;

    fs.writeFileSync(filePath, xmlData);
  }

  private getMedias() {
    const mediasElement = this.querySelectorAll('VideoMediaSource[ObjectID]');

    return Array.from(mediasElement);
  }

  private initBinaryHashesValues() {
    const elementWithbinaryHashesAttributes =
      this.querySelectorAll('[BinaryHash]');

    const treatedBinaryHash: string[] = [];

    for (const element of elementWithbinaryHashesAttributes) {
      const binaryHash = element.getAttribute('BinaryHash');
      const text = element.textContent;

      if (
        !binaryHash ||
        treatedBinaryHash.includes(binaryHash) ||
        !text?.length
      )
        continue;

      const elementsWithSameBinaryHash = this.querySelectorAll(
        `[BinaryHash="${binaryHash}"]`
      );

      for (const element of elementsWithSameBinaryHash) {
        element.textContent = text;
      }

      treatedBinaryHash.push(binaryHash);
    }
  }

  public getNewObjectId() {
    const newObjectId = (this.lastObjectId + 1).toString();
    this.lastObjectId++;
    return newObjectId;
  }

  public getNewObjectUID() {
    return crypto.randomUUID();
  }
}

import { z as zod } from 'zod';
import { AutocutModeIds } from '../enums/modes.enum';
import { aiValidationSchema } from './ai.validationSchema';
import { brollValidationSchema } from './broll.validationSchema';
import { captionValidationSchema } from './caption.validationSchema';
import { podcastValidationSchema } from './podcast.validationSchema';
import { repeatValidationSchema } from './repeat.validationSchema';
import { resizeValidationSchema } from './resize.validationSchema';
import { silenceValidationSchema } from './silence.validationSchema';
import { swearWordValidationSchema } from './swearWord.validationSchema';
import { zoomValidationSchema } from './zoom.validationSchema';
import { chapterValidationSchema } from './chapter.validationSchema';
import { viralClipsValidationSchema } from './viralClips.validationSchema';

export const parametersValidationSchema = {
  silence: silenceValidationSchema,
  ai: aiValidationSchema,
  podcast: podcastValidationSchema,
  repeat: repeatValidationSchema,
  zoom: zoomValidationSchema,
  caption: captionValidationSchema,
  chapters: chapterValidationSchema,
  broll: brollValidationSchema,
  swear_word: swearWordValidationSchema,
  viral_clips: viralClipsValidationSchema,
  resize: resizeValidationSchema,
} satisfies { [key in AutocutModeIds]: zod.ZodFirstPartySchemaTypes };

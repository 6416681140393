import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/atoms/Stepper/StepperRouter/StepperRouter';
import { ModeWrapper } from '@autocut/modes/ModeWrapper';
import React, { useState } from 'react';
import { HiOutlineKey } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Email } from './Email/Email';
import { Login } from '../Login/Login';
import { useIntl } from 'react-intl';
import { SendingEmail } from './SendingEmail/SendingEmail';

export const Trial = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const [trialEmail, setTrialEmail] = useState<string>('');

  return (
    <ModeWrapper
      Icon={HiOutlineKey}
      text={intl.formatMessage({
        id: 'text_Welcome_freeTrial',
        defaultMessage: 'Get a free trial',
      })}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/onboarding/trial"
        steps={[
          {
            label: intl.formatMessage({
              id: 'trial_stepper_email',
              defaultMessage: 'Enter your email',
            }),
            path: 'email',
            element: <Email setTrialEmail={setTrialEmail} />,
          },
          {
            label: intl.formatMessage({
              id: 'trial_stepper_sending_email',
              defaultMessage: 'Sending the email',
            }),
            path: 'sending-email',
            element: <SendingEmail email={trialEmail} />,
          },
          {
            label: intl.formatMessage({
              id: 'trial_stepper_login',
              defaultMessage: 'Use your licence key',
            }),
            path: 'login',
            element: <Login trialEmail={trialEmail} />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

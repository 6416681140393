import { ModalType } from '@autocut/enums/modals.enum';
import { AutoCutApiError } from '@autocut/utils/errors/AutoCutApiError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';

export type IdentifiedErrorType = {
  id: string;
  intlId: string;
  testFunction: (error: Error | AutoCutApiError) => boolean;
  link?: string;
  isFixable?: boolean;
  disableSentry: boolean;
  customModal?: ModalType;
};

export const IdentifiedErrors = {
  // EXTENSION ERRORS
  FILE_NOT_FOUND: {
    id: 'FILE_NOT_FOUND',
    intlId: 'fileNotFound',
    disableSentry: false,
    testFunction: ({ message }) =>
      message && message.toLowerCase().startsWith('file not found'),
  },
  HANDSHAKE_ERROR: {
    id: 'HANDSHAKE_ERROR',
    intlId: 'handshakeError',
    disableSentry: true,
    testFunction: ({ message }) =>
      message && message.includes('HANDSHAKE_ERROR'),
    customModal: ModalType.HandshakeRestart,
  },
  NAMESPACE_NOT_FOUND: {
    id: 'NAMESPACE_NOT_FOUND',
    intlId: 'handshakeError',
    disableSentry: false,
    testFunction: ({ message }) =>
      message && message.includes('NAMESPACE_NOT_FOUND'),
    customModal: ModalType.HandshakeRestart,
  },
  SCRIPT_NOT_LOADED: {
    id: 'SCRIPT_NOT_LOADED',
    intlId: 'scriptNotLoaded',
    disableSentry: false,
    testFunction: ({ message }) =>
      message && message.includes('SCRIPT_NOT_LOADED'),
  },
  SEQUENCE_MISMATCH: {
    id: 'SEQUENCE_MISMATCH',
    intlId: 'sequenceMismatch',
    testFunction: ({ message }) =>
      message && message.includes('SEQUENCE_MISMATCH'),
    disableSentry: true,
  },
  EXPORT_CANCELLED: {
    id: 'EXPORT_CANCELLED',
    intlId: 'exportCancelled',
    disableSentry: true,
    testFunction: ({ message }) =>
      message && message.includes('User has cancelled the export'),
  },
  EXPORT_UNKNOWN: {
    id: 'EXPORT_UNKNOWN',
    intlId: 'exportUnknown',
    disableSentry: false,
    testFunction: ({ message }) =>
      message && message.includes('Unknown error during export'),
  },
  NO_SPACE: {
    id: 'NO_SPACE',
    intlId: 'noSpace',
    disableSentry: true,
    testFunction: ({ message }) => message && message.includes('ENOSPC'),
  },
  TIMELINE_MUTED: {
    id: 'TIMELINE_MUTED',
    intlId: 'timelineMuted',
    disableSentry: true,
    testFunction: ({ message }) =>
      message && message.includes('TIMELINE_MUTED'),
  },
  NO_AUDIBLE_AUDIO: {
    id: 'NO_AUDIBLE_AUDIO',
    intlId: 'noAudibleAudio',
    disableSentry: true,
    testFunction: ({ message }) =>
      message && message.includes('NO_AUDIBLE_AUDIO'),
  },
  OFFLINE: {
    id: 'OFFLINE',
    intlId: 'offline',
    disableSentry: true,
    testFunction: e => {
      if (!('status' in e) || !(e instanceof AutoCutApiError)) {
        return false;
      }
      const error = e.error;
      const axiosError: any = 'error' in error ? error.error : error;

      const statusCode = e.status;
      const errorCode = axiosError.code;
      const isOffline = statusCode === undefined || errorCode === 'ERR_NETWORK';
      return isOffline;
    },
  },
  CONNCLOSEDBYCLIENT: {
    id: 'CONNCLOSEDBYCLIENT',
    intlId: 'connectionClosedByClient',
    disableSentry: false,
    testFunction: e => {
      if (!('status' in e)) {
        return false;
      }
      const statusCode = (e as any).status;
      return statusCode === 499;
    },
  },
  RESOURCE_TIMEOUT: {
    id: 'RESOURCE_TIMEOUT',
    intlId: 'resourceTimeout',
    disableSentry: true,
    testFunction: err =>
      err instanceof IncrementalError && err.fingerprint === 'waitForResource',
  },
  NO_SOUND_DETECTED: {
    id: 'NO_SOUND_DETECTED',
    intlId: 'noSoundDetected',
    disableSentry: true,
    testFunction: ({ message }) =>
      message && message.includes('NO_SOUND_DETECTED'),
  },
  BACKUP_NOT_FOUND: {
    id: 'BACKUP_NOT_FOUND',
    intlId: 'backupNotFound',
    disableSentry: true,
    testFunction: ({ message }) =>
      message.includes('Cannot get backup project item'),
  },
  SET_CURSOR_OUT_INTERVAL: {
    id: 'SET_CURSOR_OUT_INTERVAL',
    intlId: 'setCursorOutInterval',
    disableSentry: false,
    testFunction: ({ message }) =>
      message && message.includes('SET_CURSOR_OUT_INTERVAL'),
  },

  // API ERRORS
  DEEPGRAM_ERROR: {
    id: 'DEEPGRAM_ERROR',
    intlId: 'deepgramError',
    disableSentry: false,
    testFunction: ({ name }) => name && name.includes('DEEPGRAM_ERROR'),
    customModal: ModalType.DeepgramError,
  },
  OPENAI_RESPONSE_PARSING_ERROR: {
    id: 'OPENAI_RESPONSE_PARSING_ERROR',
    intlId: 'openAIParsingError',
    disableSentry: false,
    testFunction: ({ name }) =>
      name && name.includes('OPENAI_RESPONSE_PARSING_ERROR'),
  },
  USAGE_EXCEEDED: {
    id: 'USAGE_EXCEEDED',
    intlId: 'usageExceeded',
    disableSentry: true,
    testFunction: ({ name }) => name && name.includes('USAGE_EXCEEDED'),
    customModal: ModalType.UsageLimit,
  },
  //UNKNOWN must be at the end as the list order is used to determine the error priority
  UNKNOWN: {
    id: 'UNKNOWN',
    intlId: 'unknown',
    disableSentry: false,
    testFunction: () => true,
    customModal: ModalType.ReportIssue,
  },
} as { [id: string]: IdentifiedErrorType };

export type IdentifiedErrorKeys = keyof typeof IdentifiedErrors;
export type IdentifiedErrorIds =
  (typeof IdentifiedErrors)[IdentifiedErrorKeys]['id'];

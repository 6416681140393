import * as React from 'react';

import css from './kebabIcon.module.css';

export const KebabIcon = () => {
  return (
    <svg
      className={css.icon}
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="12"
      fill="none"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 10.375v-.0063M1 6v-.0062M1 1.625v-.0063M1 9.75c.2761 0 .5.2798.5.625S1.2761 11 1 11s-.5-.2798-.5-.625.2239-.625.5-.625Zm0-4.375c.2761 0 .5.2798.5.625s-.2239.625-.5.625S.5 6.3452.5 6s.2239-.625.5-.625ZM1 1c.2761 0 .5.2798.5.625s-.2239.625-.5.625-.5-.2798-.5-.625S.7239 1 1 1Z"
      />
    </svg>
  );
};

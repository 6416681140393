import { BRoll, FormattedBRollVideo } from '@autocut/types/BRolls';
import { filterEmptyElements } from '../array.utils';

export const getVideosInTimelineFromUtterances = (
  utterances: BRoll[]
): Omit<FormattedBRollVideo, 'path'>[] => {
  return filterEmptyElements(
    utterances.map(utterance => {
      if (!utterance.video) return undefined;

      return {
        ...utterance.video,
        in: utterance.start,
        out: utterance.end,
        duration: utterance.end - utterance.start,
      };
    })
  );
};

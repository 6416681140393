import { ProcessTranscriptFunction } from '@autocut/types/transcription';
import { changeMessage } from '@autocut/utils/cutButton/changeMessage';
import { getExportedAudioInfos } from '@autocut/utils/export/getExportedAudioInfos';
import { fetchTranscriptFromExportedAudio } from '@autocut/utils/transcript/fetchTranscriptFromExportedAudio.utils';
import { uploadAudioFileFromExportedAudio } from '@autocut/utils/transcript/uploadWAVFileFromExportedAudio.utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { handleBackup } from '@autocut/utils/backup/handleBackup';
import { handleFinalMessage } from '@autocut/utils/cutButton/handleFinalMessage';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getViralClips } from '@autocut/utils/viralClips/getViralClips';
import { processViralClips } from '@autocut/utils/viralClips/processViralClips';

export const handleViralClipsProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    const {
      sequence: { infos: sequence },
    } = autocutStoreVanilla();

    if (!sequence) {
      throw new IncrementalError(
        'Missing sequence',
        'Viral clips handleTranscript'
      );
    }

    changeMessage(intl, 'viral_clips_backup', 'Backing up your sequence...');
    await handleBackup(intl);

    const exportedAudioSequenceInfos = await getExportedAudioInfos(
      {
        sequence: 'selected',
        options: {
          format: 'mp3',
        },
      },
      intl
    );
    const exportedAudioInfos = [...exportedAudioSequenceInfos.sequence];
    const exportedAudioInfosWithUrl =
      await uploadAudioFileFromExportedAudio<'sentences'>(
        exportedAudioInfos,
        p => {
          setAutocutStore('onGoingProcess.progress', p);
        },
        intl
      );
    setAutocutStore('onGoingProcess.progress', undefined);

    changeMessage(intl, 'viral_clips_analyse', 'Analysing audio...');

    const exportedAudioInfosWithTranscript =
      await fetchTranscriptFromExportedAudio(
        exportedAudioInfosWithUrl,
        'sentences',
        usedModel
      );
    if (!exportedAudioInfosWithTranscript[0].transcript) {
      throw new IncrementalError(
        'No transcript',
        'Viral clips handleTranscript'
      );
    }

    changeMessage(
      intl,
      'viral_clips_getting_clips',
      'Generating viral clips...'
    );

    const transcript = exportedAudioInfosWithTranscript[0].transcript.map(
      (sentence, index) => ({ sentence: sentence.text, id: index })
    );

    const response = await getViralClips({
      transcript,
    });

    const selectionStart = sequence.audioTracks.reduce(
      (acc, track) =>
        Math.min(
          acc,
          track.selectedClips.reduce(
            (acc, clip) => Math.min(acc, clip.start),
            Number.MAX_SAFE_INTEGER
          )
        ),
      Number.MAX_SAFE_INTEGER
    );

    const viralClips = processViralClips(
      response,
      exportedAudioInfosWithTranscript[0].transcript,
      selectionStart
    );

    setAutocutStore('onGoingProcess.viralClips', viralClips);

    handleFinalMessage(
      intl,
      'viral_clips_transcript_finished',
      'Viral clips generated'
    );
  };

import { Camera, CameraWithScore } from '@autocut/types/Podcast';
import { Speaker } from '@autocut/types/Speaker';
import { sample } from 'lodash';
import { IncrementalError } from '../errors/IncrementalError';
import { getCamerasScore } from './getCameraScore';

export const getBestCamera = (
  from: number,
  to: number,
  allowedCameras: Camera[],
  speakers: (Speaker & {
    clips: Clip[];
    talkingTimeline: number[];
  })[],
  favoriteCamera?: Camera
): CameraWithScore | undefined => {
  try {
    const camerasWithScore = getCamerasScore(
      from,
      to,
      allowedCameras,
      speakers
    );
    if (camerasWithScore.length === 0) return undefined;

    const bestScore = camerasWithScore[0].score;
    let candidateCameras: CameraWithScore[];

    // If no cameras are talking, we want to select the camera with the most people on it (lowest weight)
    if (bestScore <= 0.25) {
      const worstWeight = Math.min(
        ...camerasWithScore.map(camera => camera.weight)
      );

      const camerasWithWorstWeight = camerasWithScore.filter(
        camera => camera.weight === worstWeight
      );
      const bestScoreWithWorstWeight = Math.max(
        ...camerasWithWorstWeight.map(camera => camera.score)
      );
      candidateCameras = camerasWithWorstWeight.filter(
        camera => camera.score === bestScoreWithWorstWeight
      );
    } else {
      candidateCameras = camerasWithScore.filter(
        camera => camera.score === bestScore
      );
    }

    if (favoriteCamera) {
      const favoriteCameraWithScore = candidateCameras.find(
        camera => camera.value === favoriteCamera.value
      );
      if (favoriteCameraWithScore) return favoriteCameraWithScore;
    }

    return sample(candidateCameras) as CameraWithScore;
  } catch (err: any) {
    throw new IncrementalError(err, 'getBestCamera');
  }
};

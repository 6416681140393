//If you update this function, please hard reload the extension in Premiere Pro

import { CSEvent } from '@autocut/lib/cep/csinterface';
import { csi, evalTS } from '@autocut/lib/utils/bolt';
import { CEPProcessManager } from '@autocut/utils/cepProcessus/CEPProcessManager';
import { getSequenceInfos } from '@autocut/utils/cepProcessus/getSequenceInfos.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { addBreadcrumb } from '@sentry/react';
import { debounce } from '../debounce';
import { manageError } from '../manageError';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import { onHeartbeatCallback } from './heartbeat';

const onProcessCrashCallback = () => {
  setAutocutStore(
    'ui.cutButtonMessage',
    'An error occured. Dumping informations ...'
  );
};

// To be used CEP side with :
// triggerCustomEvent('process-log', JSON.stringify({stringifiedItem : JSON.stringify(item))})); to get the whole depth in Sentry
// or
//triggerCustomEvent('process-log', JSON.stringify(item)); to get 1st level depth in Sentry
const onProcessLoggingCallback = (messageToLogData: CSEvent) => {
  setAutocutStore('dev.errors', state => [
    ...state.dev.errors,
    messageToLogData.data,
  ]);
  addBreadcrumb({
    category: 'onProcessLoggingCallback',
    data: messageToLogData.data,
    level: 'debug',
    message: 'Data from cep',
  });
};

const getSequenceUpdateInformations = (sequenceInfos: Sequence) => {
  return {
    id: sequenceInfos.id,
    inPoint: sequenceInfos.inPoint,
    outPoint: sequenceInfos.outPoint,
    audioTracks: sequenceInfos.audioTracks.map(track => ({
      selectedClips: track.selectedClips,
      nbClips: track.nbClips,
    })),
    videoTracks: sequenceInfos.videoTracks.map(track => ({
      selectedClips: track.selectedClips,
      nbClips: track.nbClips,
    })),
  };
};

const resetLoadingBar = () => {
  setSequenceLoading(false);
  setAutocutStore('sequence.parsingProcess.progress', state => ({
    percentage: 0,
    current: 0,
    max: state.sequence.parsingProcess.progress.max,
  }));
};

let lastSelectionInfoString: string;
export const invalidateLastSelection = () => (lastSelectionInfoString = '');

const setLoadingBarTo = (progress: number) => {
  setAutocutStore('sequence.parsingProcess.progress', state => ({
    percentage: progress,
    current: state.sequence.parsingProcess.progress.max,
    max: state.sequence.parsingProcess.progress.max,
  }));
};

const updateInOutPoints = async () => {
  try {
    const cepProcessManager = CEPProcessManager.getInstance();
    const sequenceInfos = await cepProcessManager.addProcessAndWait(
      getSequenceInfos
    );

    if (!sequenceInfos) return;

    const { inPoint, outPoint } = getSequenceUpdateInformations(sequenceInfos);

    const currentSequenceInfos = autocutStoreVanilla().sequence.infos;

    if (!currentSequenceInfos) return;

    setAutocutStore('sequence.infos', {
      ...currentSequenceInfos,
      inPoint,
      outPoint,
    });
  } catch (e: any) {
    console.error('Error getting sequence infos while updating inPoints ', e);
  }
};

const onSequenceSelectionChangedCallback = async () => {
  let errorCode = 0;

  try {
    if (
      autocutStoreVanilla().ui.process.isProcessing ||
      autocutStoreVanilla().ui.process.isPaused
    ) {
      if (autocutStoreVanilla().ui.process.mode.id === 'caption') {
        await updateInOutPoints();
      }

      lastSelectionInfoString = '';
      return;
    }

    errorCode = 1;

    setSequenceLoading(true);
    let sequenceInfos: Sequence | undefined;

    try {
      errorCode = 3;
      const cepProcessManager = CEPProcessManager.getInstance();
      cepProcessManager.removeProcesses('getSequenceInfos');
      sequenceInfos = await cepProcessManager.addProcessAndWait(
        getSequenceInfos
      );
      errorCode = 4;
    } catch (e: any) {
      if (e?.message?.includes('Process canceled')) {
        return;
      } else {
        setAutocutStore('sequence.infos', undefined);
        resetLoadingBar();
        throw new IncrementalError(e, 'getSequenceInfos');
      }
    }
    errorCode = 5;

    if (!sequenceInfos) throw new Error('SequenceInfos undefined');
    if (Object.keys(sequenceInfos).length === 0) {
      setAutocutStore('sequence.infos', undefined);
      return resetLoadingBar();
    }
    if (
      JSON.stringify(getSequenceUpdateInformations(sequenceInfos)) ===
      lastSelectionInfoString
    ) {
      return resetLoadingBar();
    }

    lastSelectionInfoString = JSON.stringify(
      getSequenceUpdateInformations(sequenceInfos)
    );

    errorCode = 6;

    //Set progress to 100%
    setLoadingBarTo(100);
    setAutocutStore('sequence.exportedAudioInfos', undefined);
    setAutocutStore('sequence.infos', sequenceInfos);
    setAutocutStore('onGoingProcess.sequenceParams', {});
    setSequenceLoading(false);
  } catch (e: any) {
    const finalError = new IncrementalError(
      e,
      `onSequenceSelectionChangedCallback-${errorCode}`
    );
    setAutocutStore('sequence.infos', undefined);
    resetLoadingBar();
    manageError({ error: finalError });
  }
};

export const onSequenceSelectionChangedCallbackDebounced = debounce(
  //@ts-ignore
  onSequenceSelectionChangedCallback,
  500
);

const setSequenceLoading = (isSequenceLoading: boolean) => {
  if (autocutStoreVanilla().ui.process.isProcessing) return;

  setAutocutStore('sequence.parsingProcess.isLoading', isSequenceLoading);

  if (isSequenceLoading) {
    setAutocutStore('sequence.parsingProcess.progress', state => ({
      percentage: 0,
      current: state.sequence.parsingProcess.progress.max,
      max: state.sequence.parsingProcess.progress.max,
    }));
  }
};

const onGetProcessProgressCallback = (event: CSEvent) => {
  const { percentage, current, max } = event.data;
  if (!percentage || !current || !max) return;

  const autocutState = autocutStoreVanilla();
  const progressCallback = autocutState.onGoingProcess.CEPProgressCallback;
  if (!progressCallback) return;

  if (autocutState.onGoingProcess.workerTimeoutReport) {
    autocutState.onGoingProcess.workerTimeoutReport.postMessage({
      type: 'onProgressCallback',
      payload: { percentage, current, max },
    });
  }

  progressCallback({ percentage, current, max });
};

export const registerCEPListeners = async (retry = 0) => {
  // Use of registerInitialized to prevent adding a new listener on hot relead (previous one not unmounted, prevent multiplication of event handling)
  // (This is why you have to hard reload if you modify onSequenceSelectionChangedCallback)
  //@ts-ignore
  if (window.__autocut__?.registerInitialized) return;

  try {
    const res = await evalTS('registerOnSequenceSelectionChanged');
    if (!res) {
      throw new Error(
        'registerOnSequenceSelectionChanged failed because app is undefined'
      );
    }
    csi.addEventListener(
      'selectionChanged',
      onSequenceSelectionChangedCallbackDebounced
    );
    csi.addEventListener('process-progress', onGetProcessProgressCallback);
    csi.addEventListener('heartbeat', onHeartbeatCallback);
    csi.addEventListener('process-crash', onProcessCrashCallback);
    csi.addEventListener('process-log', onProcessLoggingCallback);

    //@ts-ignore
    window.__autocut__ = { ...window.__autocut__, registerInitialized: true };
  } catch (e) {
    console.error(
      `${
        retry > 0 ? `(Retry ${retry}) ` : ''
      }Error during registerCEPListeners : ` + e
    );
    if (retry < 10) {
      setTimeout(() => registerCEPListeners(retry + 1), 100);
    } else {
      setAutocutStore('ppro.isScriptLoaded', false);
      throw new Error("Can't launch registerCEPListeners");
    }
  }
};

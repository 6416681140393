import logLevel from '@autocut/types/logLevel.enum';
import { CEPAction } from '@autocut/utils/cep/actions.cep.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { addBreadcrumb } from '@sentry/react';
import { IntlShape } from 'react-intl';
import { ClipTypeEnum } from '../../../jsx/ppro/types.ppro';
import { changeMessage } from '../cutButton/changeMessage';
import { logger } from '../logger';
import { getSentryLogLevel } from '../sentry.utils';
import {
  ProgressState,
  autocutStoreVanilla,
  setAutocutStore,
} from '../zustand';

export const handleCut = async (
  timelineBasedSilencesTotal: number[][],
  intl: IntlShape
) => {
  try {
    const autocutState = autocutStoreVanilla();
    if (
      !autocutState.sequence.infos ||
      (!autocutState.sequence.infos.audioTracks.length &&
        !autocutState.sequence.infos.videoTracks.length)
    ) {
      throw new Error('No clip selected');
    }

    const clipsToCut = autocutState.onGoingProcess.clipsToCut;
    if (!clipsToCut) return [];

    // Group clips to cut by track and type
    const tracksWithClipsToCut = [
      ...autocutState.sequence.infos.audioTracks,
      ...autocutState.sequence.infos.videoTracks,
    ]
      .map(track => ({
        index: track.index,
        type: track.type,
        clipsToCut: clipsToCut.filter(
          clip => clip.type === track.type && clip.numTrack === track.index
        ),
      }))
      .filter(track => track.clipsToCut.length);

    const timecodes = timelineBasedSilencesTotal.flat();
    // Filter timecode by track based on if there is a clip to cut on this track
    const tracksWithTimecodesToCut = tracksWithClipsToCut.map(track => ({
      ...track,
      timecodes: timecodes.filter(timecode =>
        track.clipsToCut.find(
          clip => timecode > clip.start && timecode < clip.end
        )
      ),
    }));

    const cutActions: CEPAction<'cutClips'>[] = [];

    for (
      let trackIndex = 0;
      trackIndex < tracksWithTimecodesToCut.length;
      trackIndex++
    ) {
      const track = tracksWithTimecodesToCut[trackIndex];
      const nextTrack = tracksWithTimecodesToCut[trackIndex + 1];

      if (!track.timecodes.length) continue;

      if (trackIndex === 0) {
        setAutocutStore(
          'onGoingProcess.CEPProgressCallback',
          () => () =>
            handleCutMessage(
              intl,
              track.type === ClipTypeEnum.Audio ? 'Audio' : 'Video',
              track
            )
        );
      }

      cutActions.push({
        action: 'cutClips',
        param: [
          track.timecodes,
          track.index,
          track.type as number,
        ] as CEPAction<'cutClips'>['param'],
        thenFunctions: [
          (failedTimecodes: number[]) => {
            try {
              if (nextTrack) {
                setAutocutStore(
                  'onGoingProcess.CEPProgressCallback',
                  () => () =>
                    handleCutMessage(
                      intl,
                      nextTrack.type === ClipTypeEnum.Audio ? 'Audio' : 'Video',
                      nextTrack
                    )
                );
              } else {
                changeMessage(intl, `button_PostCuttingStep`, `Cuts done...`);
              }
              if (failedTimecodes.length > 0) {
                logger(
                  'handleCut',
                  logLevel.error,
                  `cutClips failed ${failedTimecodes.length} times`
                );

                addBreadcrumb({
                  message: `cutClips failed ${failedTimecodes.length} times`,
                  category: 'handleCut',
                  data: { failedCut: failedTimecodes },
                  level: getSentryLogLevel(logLevel.error),
                });
              }
            } catch (err: any) {
              new IncrementalError(err, 'cutClips(then)').reportToSentry();
            }
          },
        ],
      });
    }
    return cutActions;
  } catch (err: any) {
    throw new IncrementalError(err, 'handleCut');
  }
};

export const handleCutMessage =
  (
    intl: IntlShape,
    trackType: 'Audio' | 'Video',
    track: {
      index: number;
    }
  ) =>
  (progress: ProgressState) => {
    changeMessage(
      intl,
      `button_CuttingStep_${trackType}`,
      `Performing cuts on ${trackType.toLowerCase()} track {numTrack} : {current} out of {max}`,
      {
        numTrack: track.index + 1,
        current: progress.current,
        max: progress.max,
      }
    );
  };

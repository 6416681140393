import * as React from 'react';
import { createContext, useState } from 'react';

import { IntlProvider } from 'react-intl';

import English from '@autocut/lang/en-US.json';
import French from '@autocut/lang/fr.json';
import Portugueses from '@autocut/lang/pt.json';
import Spanish from '@autocut/lang/es.json';
import Korean from '@autocut/lang/kr.json';
import Japanese from '@autocut/lang/jp.json';
import { getLanguage, setLanguage } from '@autocut/utils/localStorage.utils';
import { CURRENT_ENV, Env } from '@autocut/utils/currentEnv.utils';

const getMessages = (locale: Locale) => {
  switch (locale) {
    case 'FR':
      return French;
    case 'EN':
      return English;
    case 'PT':
      return Portugueses;
    case 'ES':
      return Spanish;
    case 'KR':
      return Korean;
    case 'JP':
      return Japanese;
    default:
      return English;
  }
};

export const availableLocales = ['FR', 'EN', 'PT', 'ES', 'KR', 'JP'] as const;

export type Locale = (typeof availableLocales)[number];

export const pproLocales = [
  'de_DE',
  'en_US',
  'es_ES',
  'fr_FR',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'pt_BR',
  'ru_RU',
  'zh_CN',
] as const;
export type PProLocale = (typeof pproLocales)[number];

interface ILocaleContext {
  locale: Locale;
  setAndSaveLocale: (locale: Locale) => void;
  availableLocales: typeof availableLocales;
}

export const LocaleContext = createContext<ILocaleContext>({
  locale: null as any,
  setAndSaveLocale: null as any,
  availableLocales: null as any,
});

export const LocaleProvider = ({ children }: { children: any }) => {
  const loadedLocale = getLanguage();

  const [locale, setLocale] = useState<Locale>(loadedLocale);

  const setAndSaveLocale = (locale: Locale) => {
    setLocale(locale);
    setLanguage(locale);
  };

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setAndSaveLocale,
        availableLocales,
      }}
    >
      <IntlProvider
        locale={locale}
        messages={getMessages(locale)}
        onError={
          CURRENT_ENV !== Env.Production
            ? e => {
                console.info('Translation error\n', e.message);
              }
            : undefined
        }
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

import { ExportResult } from '@autocut/types/ExportedAudio';
import { getAllEffectiveVideoIntervalsDescriptor } from '../export/descriptors';
import { getExportedAudioInfos } from '../export/getExportedAudioInfos';
import { getParametersForMode } from '../parameters.utils';
import { openWaveFile } from '../waveForm.utils';
import { computeRMS } from './computeRMS';

export type AudioWavData = {
  sampleRate: number;
  rmsValues: number[];
  startTimeline: number;
  endTimeline: number;
};

export const getAudioClipWavData = async () => {
  const { constraintZoom } = getParametersForMode('zoom');

  let exportedAudioSequenceInfos: ExportResult<'selected'>;

  if (constraintZoom) {
    exportedAudioSequenceInfos = await getExportedAudioInfos(
      getAllEffectiveVideoIntervalsDescriptor()
    );
  } else {
    exportedAudioSequenceInfos = await getExportedAudioInfos({
      sequence: 'selected',
    });
  }

  const clipsAudioData: AudioWavData[] = [];

  for (const exportedAudioInfo of exportedAudioSequenceInfos.sequence) {
    const [sampleRate, PCM] = openWaveFile(exportedAudioInfo.path) as [
      number,
      number[]
    ];
    const rmsValues = computeRMS(PCM, sampleRate);

    clipsAudioData.push({
      sampleRate,
      rmsValues,
      startTimeline: exportedAudioInfo.start,
      endTimeline: exportedAudioInfo.end,
    });
  }

  return clipsAudioData;
};

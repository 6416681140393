import React from 'react';

import css from './Badge.module.scss';
import { Text } from '../Text/Text';
import { BadgeVariant } from './variants';

export type BadgeProps = {
  children: React.ReactNode;
  variant?: BadgeVariant;
  hoverable?: boolean;
};

export const Badge = ({ children, variant, hoverable }: BadgeProps) => {
  return (
    <div
      data-variant={variant}
      className={`${css.root} ${hoverable ? css.hoverable : ''}`}
    >
      <Text variant="textXs.bold" color="white">
        {children}
      </Text>
    </div>
  );
};

import React from 'react';

import css from './LoadingRing.module.css';

export type LoadingRingProps = {
  size?: number;
};

export const LoadingRing = ({ size = 18 }: LoadingRingProps) => {
  return (
    <div
      className={css.ldsRing}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const WIDTH_RATIO = 1 / 2;
const HEIGHT_RATIO = 1 / 5;

export const getWatermarkScale = (
  watermark: { path: string; width: number; height: number },
  preset: { name: string; width: number; height: number }
) => {
  const widthScale = ((WIDTH_RATIO * preset.width) / watermark.width) * 100;
  const heightScale = ((HEIGHT_RATIO * preset.height) / watermark.height) * 100;

  return Math.min(widthScale, heightScale);
};

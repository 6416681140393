import { evalTS } from '@autocut/lib/utils/bolt';
import { CEPAction, runCEPActions } from '@autocut/utils/cep/actions.cep.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { IntlShape } from 'react-intl';
import { handleResetSequenceSettings } from '../cut/handleResetSequenceSettings';
import { saveAndPrepareSequenceSettings } from '../cut/saveAndPrepareSequenceSettings';
import { changeMessage } from '../cutButton/changeMessage';
import { addXp } from '../game/addXp';
import { handleProcessBase } from '../process/handleProcessBase';
import { getSpeakersWithClips } from '../speakers/getSpeakers';
import { ProgressState, setAutocutStore } from '../zustand';
import { getAudioOffsets } from './getAudioOffset';
import { getCamerasToDisplay } from './getCamerasToDisplay';
import { getSpeakersTalkingTimeline } from './getSpeakersTalkingTimeline';
import { getUsedVideoTracks } from './getUsedVideoTracks';
import { normalizeTimelines } from './normalizeTimeline';
import { getParametersForMode } from '../parameters.utils';

const editPodcast = async (intl: IntlShape) => {
  try {
    const podcastParameters = getParametersForMode('podcast');

    await saveAndPrepareSequenceSettings();

    changeMessage(intl, 'podcast_getting_tracks', 'Getting tracks...');
    const cameras = getUsedVideoTracks();

    changeMessage(intl, 'podcast_getting_silences', 'Getting silences...');
    const speakers = await getSpeakersWithClips();
    if (!speakers) {
      throw new IncrementalError(
        'Error while retrieving speakers and associated clipInfo',
        'editPodcast'
      );
    }

    const [audioOffsetStart, audioOffsetEnd] = getAudioOffsets(speakers);

    await evalTS('cutAllTracksAt', [audioOffsetStart, audioOffsetEnd]);

    //Speakers has now timelineBasedSilences
    const speakersWithTalkingTimeline = await getSpeakersTalkingTimeline(
      speakers,
      audioOffsetStart,
      audioOffsetEnd
    );

    //Normalize timelines so that all timelines have the same length
    const normalizedSpeakers = normalizeTimelines(speakersWithTalkingTimeline);
    const camerasToDisplay = getCamerasToDisplay(
      cameras,
      normalizedSpeakers,
      audioOffsetStart,
      audioOffsetEnd
    );

    let currentStep = 0;

    const actions: CEPAction<'handlePodcastCameraEdits'>[] = [];

    setPodcastCameraMessage(intl, cameras[0]);

    for (let cameraIndex = 0; cameraIndex < cameras.length; cameraIndex++) {
      const camera = cameras[cameraIndex];
      const nextCamera = cameras[cameraIndex + 1];
      //We format cameraToDisplay to merge disabled clips
      const cameraToDisplayForThisCamera = camerasToDisplay.reduce(
        (res, current) => {
          if (current.camera.value === camera.value) {
            return [...res, current];
          }
          const last = res.pop();
          if (last) {
            if (
              Math.abs(last.to - current.from) < 0.05 &&
              last.camera.value !== camera.value
            ) {
              const newLast = { ...last, to: current.to };
              return [...res, newLast];
            }
            return [...res, last, current];
          }
          return [...res, current];
        },
        [] as typeof camerasToDisplay
      );

      actions.push({
        action: 'handlePodcastCameraEdits',
        param: [
          camera,
          cameraToDisplayForThisCamera,
          podcastParameters.deleteUnusedClips,
        ],
        thenFunctions: [
          ...(nextCamera
            ? [() => setPodcastCameraMessage(intl, nextCamera)]
            : []),
        ],
      });
      currentStep += cameraToDisplayForThisCamera.length;
    }

    //We order actions to run the track with the most cut first, typically the track with the most speakers
    //(it will slow down a little bit the rest of the process but it is faster than making a lot of cut at the end)
    actions.sort(
      (
        a: CEPAction<'handlePodcastCameraEdits'>,
        b: CEPAction<'handlePodcastCameraEdits'>
      ) => a.param[1].length - b.param[1].length
    );

    await runCEPActions(actions);

    await handleResetSequenceSettings();

    setAutocutStore('onGoingProcess.nbStepTotal', currentStep);
    const xpGained = currentStep * 0.55;
    await addXp(xpGained);
    setAutocutStore('game.level.xpGained', xpGained);
  } catch (err: any) {
    throw new IncrementalError(err, 'editPodcast');
  }
};

export const handlePodcastProcess = handleProcessBase({
  executeProcess: editPodcast,
});

export const setPodcastCameraMessage = (
  intl: IntlShape,
  camera: { label: string }
) => {
  setAutocutStore(
    'onGoingProcess.CEPProgressCallback',
    () => (progress: ProgressState) => {
      changeMessage(
        intl,
        'podcast_edit_track',
        'Editing track {indexTrackInfo}: {current} out of {max}',
        {
          indexTrackInfo: camera.label,
          current: progress.current,
          max: progress.max,
        }
      );
    }
  );
};

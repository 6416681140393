import { CaptionsParameters } from '@autocut/types/CaptionsParameters';
import {
  PproSourceTextCapsOptionsEnum,
  PproSourceTextParam,
} from '@autocut/utils/captions/formatSourceTextData';
import { rgbToInteger } from '@autocut/utils/color.utils';

export const getXMLTextParameters = (
  params: CaptionsParameters
): PproSourceTextParam => {
  return {
    mTextParam: {
      mNumStrokes: params.outline.enabled ? 1 : 0,
      mShadowVisible: params.formating.glow.enabled,
      mShadowColor: rgbToInteger(params.formating.glow.color),
      mShadowBlur: params.formating.glow.intensity,
      mShadowOpacity: params.formating.glow.enabled ? 100 : 0,
      mShadowOffset: 0,
      mShadowSize: 0,
      mStyleSheet: {
        mFillColor: { mParamValues: [[0, rgbToInteger(params.text.color)]] },
        mFontName: { mParamValues: [[0, params.text.font.postscriptName]] },
        mFontSize: { mParamValues: [[0.0, params.text.fontSize]] },
        mStrokeVisible: {
          mParamValues: [[0, params.outline.enabled ? true : false]],
        },
        mStrokeWidth: { mParamValues: [[0, params.outline.width]] },
        mStrokeColor: {
          mParamValues: [[0, rgbToInteger(params.outline.color)]],
        },
        mFauxItalic: { mParamValues: [[0, params.formating.italic]] },
        mCapsOption: {
          mParamValues: [
            [
              0,
              params.formating.uppercase
                ? PproSourceTextCapsOptionsEnum.ALL_CAPS
                : PproSourceTextCapsOptionsEnum.NORMAL,
            ],
          ],
        },
      },
    },
  };
};

import {
  LicenceKeyType,
  orderedLicenceKeyTypes,
} from '@autocut/types/isKeyValidResponse';

export const isKeyTypeEqualsOrGreaterThan = (
  keyType: LicenceKeyType,
  comparedTo: LicenceKeyType
) => {
  const indexKeyType = orderedLicenceKeyTypes.indexOf(keyType);
  const indexComparedKeyType = orderedLicenceKeyTypes.indexOf(comparedTo);

  return indexKeyType >= indexComparedKeyType;
};

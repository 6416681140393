import { ExportedAudioWithSilences } from '@autocut/types/ExportedAudioWithSilences';
import { SilenceParams } from '@autocut/types/SilenceParams';
import { isCloseTo } from '@autocut/utils/math.utils';

/***
 * Applique les marges sur chaque intervalle du tableau d'intervalles donné (Ajoute à chaque borne inférieur d'intervalle la valeur de la marge inférieur et soustrait à
 * chaque borne supérieur d'intervalle la valeur de la marge supérieur)
 */
export const applyMargins = (
  silences: number[][],
  exportedAudioInfos: ExportedAudioWithSilences[],
  parameters: SilenceParams,
  forPreview = false
) => {
  const { marginBefore, marginAfter, transistions } = parameters;

  const hasJCut = transistions === 'j_cut' || transistions === 'both';
  const hasLCut = transistions === 'l_cut' || transistions === 'both';

  // J and L cuts needs to be inverted because the margins are applied on the silences, and not on the talking parts.
  const marginBeforeNumber = hasLCut && !forPreview ? 0 : marginBefore;
  const marginAfterNumber = hasJCut && !forPreview ? 0 : marginAfter;

  const [minStart, maxEnd] = exportedAudioInfos.reduce(
    (acc, currentExportedAudio) => [
      Math.min(acc[0], currentExportedAudio.start),
      Math.max(acc[1], currentExportedAudio.end),
    ],
    [Infinity, 0]
  );

  silences.forEach(silence => {
    const isSilenceStart = isCloseTo(minStart, silence[0], 0.005); // On n'applique pas de marge au début si il existe un clip commencant par un silence
    if (!isSilenceStart) {
      silence[0] += marginBeforeNumber;
    }

    const isSilenceEnd = isCloseTo(maxEnd, silence[1], 0.005); // On n'applique pas de marge à la fin si il existe un clip finissant par un silence
    if (!isSilenceEnd) {
      silence[1] -= marginAfterNumber;
      if (silence[1] < 0) {
        silence[1] = 0;
      }
    }
  });

  return silences;
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import { CaptionChunk, CaptionChunkHighlight } from '@autocut/types/Captions';
import React, { CSSProperties } from 'react';
import PopupPortal from '@autocut/components/atoms/PopupPortal/PopupPortal';
import { InfoText } from '@autocut/components/atoms/InfoText';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { TfiSplitVAlt } from 'react-icons/tfi';

import css from './EditableWord.module.scss';
import { EditableText } from '@autocut/components/atoms/EditableText/EditableText';

export const EditableWord = ({
  highlight,
  onTextChange,
  onSplit,
  isLast,
  isRtl,
}: {
  chunk: CaptionChunk;
  highlight: CaptionChunkHighlight;
  onTextChange: (word: string) => void;
  onSplit: () => void;
  isLast: boolean;
  isRtl: boolean;
}) => {
  const iconRef = React.useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = React.useState(false);

  const iconAnimationVariables = isRtl
    ? {
        '--margin-left': '6px',
        '--margin-right': '0',
      }
    : {
        '--margin-left': '0',
        '--margin-right': '6px',
      };

  return (
    <FlexContainer
      flexDirection={isRtl ? 'row-reverse' : 'row'}
      gap={4}
      alignItems="center"
      className={css.word}
    >
      <EditableText
        initialValue={highlight.word}
        onChange={newValue => onTextChange(newValue)}
        filteredCharacters={['\n', '\r']}
        style={
          highlight.word.length
            ? undefined
            : {
                border: '1px solid white',
                opacity: 0.3,
                borderRadius: '4px',
                minWidth: '20px',
              }
        }
      />

      {!isLast && (
        <div
          ref={iconRef}
          className={css.splitIcon}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => {
            setIsHovered(false);
            onSplit();
          }}
          style={iconAnimationVariables as CSSProperties}
        >
          <TfiSplitVAlt strokeWidth="1px" />

          {isHovered && (
            <PopupPortal
              target={iconRef}
              position="bottom"
              containerStyle={{
                cursor: 'pointer',
              }}
            >
              <InfoText style="info" hideHelper>
                <TranslatedMessage
                  id="captions_editor_chunk_split_tooltip"
                  defaultMessage="Split chunks"
                />
              </InfoText>
            </PopupPortal>
          )}
        </div>
      )}
    </FlexContainer>
  );
};

import { Button, Input } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, { useRef, useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import css from './Email.module.css';
import { FooterLayout } from '@autocut/layouts/FooterLayout';
import { validateEmail } from '@autocut/components/modals';
import { askForTrialKey } from '@autocut/utils/keys/askForTrialKey.util';
import { useLocale } from '@autocut/hooks/useLocale';
import { useNavigate } from 'react-router-dom';

export type EmailProps = {
  setTrialEmail: (email: string) => void;
};

export const Email = ({ setTrialEmail }: EmailProps) => {
  const intl = useIntl();
  const { locale } = useLocale();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');

  const buttonRef = useRef();

  const handleAskForTrial = async () => {
    if (validateEmail(email) !== undefined)
      setError(validateEmail(email) as string);

    setError('');

    try {
      const result = await askForTrialKey(email, locale);

      if (result) {
        setTrialEmail(email);
        navigate('../sending-email');
      }
    } catch (error: any) {
      setError(
        intl.formatMessage({ id: error.message, defaultMessage: error.message })
      );
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && buttonRef.current) {
      // @ts-ignore
      buttonRef.current.handleClick();
    }
  };

  return (
    <FlexContainer flexDirection="column" gap={48}>
      <FlexContainer flexDirection="column" gap={12}>
        <FlexContainer alignItems="center" gap={8} className={css.title}>
          <TranslatedMessage
            id="trial_stepper_email"
            defaultMessage="Enter your email"
          />
          <div className={css.emailContainer}>
            <MdOutlineEmail size={20} color="white" />
          </div>
        </FlexContainer>
        <FlexContainer flexDirection="column" gap={8} className={css.subtitle}>
          <TranslatedMessage
            id="text_GetLicence_Text"
            defaultMessage="Enter your email address and receive a free trial license"
          />
          <Input
            placeholder="email@email.com"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            inputSize="md"
          />
        </FlexContainer>
      </FlexContainer>
      <FooterLayout>
        <div className={css.buttonContainer}>
          <div className={css.errorMessage}>{error}</div>

          <Button
            ref={buttonRef}
            onClickFunction={handleAskForTrial}
            weight="bold"
          >
            <TranslatedMessage id="broll_button_next" defaultMessage="Next" />
          </Button>
        </div>
      </FooterLayout>
    </FlexContainer>
  );
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { CaptionsParameters } from '@autocut/types/CaptionsParameters';
import { WordBase } from '@autocut/types/Deepgram';
import { Locale } from '../../../jsx/ppro/const';
import {
  localMotionEffectName,
  localTransformEffectName,
} from '../../../jsx/ppro/enums';
import { autocutStoreVanilla } from '../zustand';

export const CAPTIONS_XML_PROJECT_LOCALE: Locale = 'en_US';

export const FLOATING_TEXT_EFFECT_CLIP_SECONDS_DURATION = 5;
export const FLOATING_TEXT_EFFECT_CLIP_VIDEO_FILTER_COMPONENTS = [
  localTransformEffectName[CAPTIONS_XML_PROJECT_LOCALE],
  localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
];

export enum CaptionsXmlProjectTrackIndex {
  TEXT_BACKGROUND = 0,
  WORD_BACKGROUND = 1,
  CAPTIONS = 2,
  EMOJI = 3,
  EFFECTS = 4,
  FLOATING_TEXT_EFFECT = 5,
}

export const secondToTickV2 = (second: number) => {
  const decimalNumber = second.toString().split('.')[1] ?? '';
  const decimalNumberLength = decimalNumber.length;
  const multiplicator = Math.pow(10, decimalNumberLength);
  const roundedSecond = Math.round(second * multiplicator);
  const bigIntSecond = BigInt(roundedSecond);

  const NB_TICK_PER_SECONDS = BigInt('254016000000');

  const ticks = (bigIntSecond * NB_TICK_PER_SECONDS) / BigInt(multiplicator);

  return ticks.toString();
};
export const isInInterval = (
  start: number,
  end: number,
  interval: [number, number]
) => {
  return start >= interval[0] && end <= interval[1];
};

export const getValue = (word: WordBase) => {
  const removePunctuation =
    autocutStoreVanilla().ui.parameters.caption.formating.removePunctuation;
  return removePunctuation ? word.word : word.punctuated_word ?? word.word;
};

export const playChunk = async (
  start: number,
  end: number,
  currentTimeout?: NodeJS.Timeout
) => {
  if (currentTimeout) clearTimeout(currentTimeout);

  const duration = end - start;

  if (duration < 1) {
    const padding = duration * 0.4;
    await evalTS('playAtTimeAndStop', start - padding, duration + 2 * padding);
  } else {
    void evalTS('playAtTime', start);

    return setTimeout(() => {
      void evalTS('pause');
    }, duration * 1000);
  }
};

export const removeUnwantedKeysFromCaptionsParameters = (
  presetParams: CaptionsParameters
): CaptionsParameters => {
  const presetParamsCopy = JSON.parse(JSON.stringify(presetParams));

  delete presetParamsCopy.language;
  delete presetParamsCopy.position;
  delete presetParamsCopy.text.fontSize;
  delete presetParamsCopy.needBackup;
  delete presetParamsCopy.formating?.nbLines;
  delete presetParamsCopy.formating?.maxWidth;
  delete presetParamsCopy.emojis?.enabled;
  delete presetParamsCopy.userPresets;

  return presetParamsCopy as CaptionsParameters;
};

export const removePunctuation = (text: string) =>
  text.replace(/[^\w\s\']|_/g, '').replace(/\s+/g, ' ');

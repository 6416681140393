import { fs } from '@autocut/lib/cep/node';
import { csi, evalTS } from '@autocut/lib/utils/bolt';
import sizeOf from 'buffer-image-size';
import { IntlShape } from 'react-intl';
import { Locale } from '../../../jsx/ppro/const';
import { handleResetSequenceSettings } from '../cut/handleResetSequenceSettings';
import { saveAndPrepareSequenceSettings } from '../cut/saveAndPrepareSequenceSettings';
import { changeMessage } from '../cutButton/changeMessage';
import { IncrementalError } from '../errors/IncrementalError';
import { addXp } from '../game/addXp';
import { handleProcessBase } from '../process/handleProcessBase';
import { setAutocutStore } from '../zustand';
import { getWatermarkScale } from './watermark.util';
import { getParametersForMode } from '../parameters.utils';

const handleResize = async (intl: IntlShape) => {
  const {
    preset: presetTmp,
    watermark,
    autoReframePreset,
  } = getParametersForMode('resize');

  if (!presetTmp) {
    throw new IncrementalError('No preset selected', 'handleResize');
  }

  if (!autoReframePreset) {
    throw new IncrementalError(
      'No autoReframe preset selected',
      'handleResize'
    );
  }

  const preset = {
    name: presetTmp.name,
    width: presetTmp.width,
    height: presetTmp.height,
  };

  try {
    changeMessage(intl, 'resize_backup', 'Backing up your sequence...');

    await saveAndPrepareSequenceSettings();

    const hostEnv = csi.hostEnvironment;

    changeMessage(
      intl,
      'resize_create_new_sequence',
      'Creating a new sequence...'
    );

    await evalTS('createSequenceForResize', preset);

    changeMessage(
      intl,
      'resize_resizing_sequence',
      'Resizing the new sequence...'
    );
    await evalTS('activateAutoreframe', preset.name, autoReframePreset);
    await evalTS('changeVideoFrameSize', preset.width, preset.height);

    if (watermark) {
      changeMessage(intl, 'resize_adding_watermark', 'Adding watermark...');

      const watermarkPath = (watermark as any).path;

      const buffer = fs.readFileSync(watermarkPath);
      const { width, height } = sizeOf(buffer);
      const watermarkObj = {
        path: watermarkPath,
        width,
        height,
      };

      await evalTS(
        'importWatermark',
        watermarkObj,
        getWatermarkScale(watermarkObj, preset),
        hostEnv.appUILocale as Locale
      );
    }

    changeMessage(intl, 'resize_cleaning_sequence', 'Cleaning the sequence...');
    await evalTS('removeAllUnusedTracks');

    await handleResetSequenceSettings();

    const xpGained = 1.66 + (watermark ? 0.33 : 0);
    await addXp(xpGained);
    setAutocutStore('game.level.xpGained', xpGained);
  } catch (error: any) {
    console.error(error);
    throw new IncrementalError(error, 'editResize');
  }
};

export const handleResizeProcess = handleProcessBase({
  executeProcess: handleResize,
});

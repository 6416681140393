import { XMLDocument } from './XMLDocument';
import { XMLVideoMediaSource } from './XMLMediaSource';
import { XMLVideoClip } from './XMLVideoClip';

export class XMLProjectItem {
  private refElement: Element;
  private document: XMLDocument;

  public objectUID: string;

  private clipProjectItem: Element;
  public masterClip: Element;
  public name: string;
  private loggingInfo: Element;
  public videoClip: XMLVideoClip;
  private audioClipChannelGroups: Element;

  constructor({
    refElement,
    document,
    clipProjectItem,
    masterClip,
    loggingInfo,
    videoClip,
    audioClipChannelGroups,
  }: {
    refElement: Element;
    document: XMLDocument;
    clipProjectItem?: Element;
    masterClip?: Element;
    loggingInfo?: Element;
    videoClip?: XMLVideoClip;
    audioClipChannelGroups?: Element;
  }) {
    this.objectUID = refElement.getAttribute('ObjectURef') ?? '';
    this.refElement = refElement;

    this.document = document;

    this.clipProjectItem = clipProjectItem ?? this.getClipProjectItem()!;
    this.name = this.clipProjectItem.querySelector('Name')?.textContent ?? '';
    this.masterClip = masterClip ?? this.getMasterClip()!;

    this.loggingInfo = loggingInfo ?? this.getLoggingInfo()!;
    this.videoClip = videoClip ?? this.getMasterClipVideoClip()!;
    this.audioClipChannelGroups =
      audioClipChannelGroups ?? this.getAudioClipChannelGroups()!;

    return this;
  }

  public rename(newName: string) {
    this.name = newName;
    this.masterClip.querySelector('Name')!.textContent = newName;
    this.clipProjectItem.querySelector('Name')!.textContent = newName;
    this.loggingInfo.querySelector('ClipName')!.textContent = newName;
  }

  public clone({
    newName,
    newObjectUID,
    newSource,
    index = 0,
  }: {
    newName: string;
    newObjectUID?: string;
    newSource?: XMLVideoMediaSource;
    index?: number;
  }) {
    const newProjectItemUID = newObjectUID ?? this.document.getNewObjectUID();
    const newRefElement = this.refElement.cloneNodeWithNewId(newProjectItemUID);
    newRefElement.setAttribute('Index', index.toString());

    const newClipProjectItem =
      this.clipProjectItem.cloneNodeWithNewId(newProjectItemUID);

    const masterClipRef = newClipProjectItem.querySelector('MasterClip')!;
    const newMasterClipId = this.document.getNewObjectUID();
    masterClipRef.setAttribute('ObjectURef', newMasterClipId);
    const newMasterClip = this.masterClip.cloneNodeWithNewId(newMasterClipId);

    const newLoggingInfoId = this.document.getNewObjectId();
    const newLoggingInfoRef = newMasterClip.querySelector('LoggingInfo')!;
    newLoggingInfoRef.setAttribute('ObjectRef', newLoggingInfoId);
    const newLoggingInfo =
      this.loggingInfo.cloneNodeWithNewId(newLoggingInfoId);

    const newVideoClipId = this.document.getNewObjectId();
    const newVideoClipRef = newMasterClip.querySelector('Clip')!;
    newVideoClipRef.setAttribute('ObjectRef', newVideoClipId);
    const newVideoClip = this.videoClip.clone({
      newObjectID: newVideoClipId,
      newSource,
    });

    const newAudioClipChannelGroupsId = this.document.getNewObjectId();
    const newAudioClipChannelGroupsRef = newMasterClip.querySelector(
      'AudioClipChannelGroups'
    )!;
    newAudioClipChannelGroupsRef.setAttribute(
      'ObjectRef',
      newAudioClipChannelGroupsId
    );
    const newAudioClipChannelGroups =
      this.audioClipChannelGroups.cloneNodeWithNewId(
        newAudioClipChannelGroupsId
      );

    const newXmlProjectItem = new XMLProjectItem({
      refElement: newRefElement,
      clipProjectItem: newClipProjectItem,
      masterClip: newMasterClip,
      document: this.document,
      loggingInfo: newLoggingInfo,
      videoClip: newVideoClip,
      audioClipChannelGroups: newAudioClipChannelGroups,
    });

    newXmlProjectItem.rename(newName);

    return newXmlProjectItem;
  }

  private getClipProjectItem = () => {
    return this.document.findElementWithObjectId(
      ['ClipProjectItem'],
      this.objectUID
    );
  };

  private getMasterClip = () => {
    const masterClipRef = this.clipProjectItem.querySelector('MasterClip')!;
    const masterClipId = masterClipRef?.getAttribute('ObjectURef') ?? '';
    return this.document.findElementWithObjectId(['MasterClip'], masterClipId);
  };

  private getLoggingInfo = () => {
    const loggingInfoRef = this.masterClip.querySelector('LoggingInfo')!;
    const loggingInfoId = loggingInfoRef?.getAttribute('ObjectRef') ?? '';
    return this.document.findElementWithObjectId(
      ['ClipLoggingInfo'],
      loggingInfoId
    );
  };

  private getMasterClipVideoClip = () => {
    // It might have multipe clips but for now, I've only worked with master clip that had one
    const videoClipRef = this.masterClip.querySelector('Clip')!;
    const videoClipId = videoClipRef?.getAttribute('ObjectRef') ?? '';
    const videoClip = this.document.findElementWithObjectId(
      ['VideoClip'],
      videoClipId
    )!;
    if (!videoClip) return;
    return new XMLVideoClip({ element: videoClip, document: this.document });
  };

  private getAudioClipChannelGroups = () => {
    const audioChannelGroupsRef = this.masterClip.querySelector(
      'AudioClipChannelGroups'
    )!;
    const audioChannelGroupsId =
      audioChannelGroupsRef?.getAttribute('ObjectRef') ?? '';
    return this.document.findElementWithObjectId(
      ['ClipChannelGroupVectorSerializer'],
      audioChannelGroupsId
    );
  };
}

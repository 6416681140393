import { LoaderInfinity } from '@autocut/components/atoms';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import React from 'react';
import { useIntl } from 'react-intl';

export const InOutStep = () => {
  const intl = useIntl();

  const selectionInfos = useAutoCutStore(state => state.sequence.infos);

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'resize_in_out_step_title',
        defaultMessage: 'In / Out Points',
      })}
      alwaysOpen={true}
      helperId="resize_in_out_step_helper"
      helperDefault="Please check your in & out points in your timeline. They should be the same as the values below."
    >
      {selectionInfos &&
      selectionInfos.inPoint !== null &&
      selectionInfos.outPoint !== null ? (
        <FlexContainer flexDirection="column" justifyContent="center" gap={4}>
          <div>In: {selectionInfos?.inPoint.toFixed(2)} seconds</div>
          <div>Out: {selectionInfos?.outPoint.toFixed(2)} seconds</div>
          <div>
            Clip duration:{' '}
            {(selectionInfos.outPoint - selectionInfos.inPoint).toFixed(2)}{' '}
            seconds
          </div>
        </FlexContainer>
      ) : (
        <LoaderInfinity height={36} />
      )}
    </StepLayout>
  );
};

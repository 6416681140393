import { hexToHexOpacity, rgbToHex } from '@autocut/utils/color.utils';
import React, { useEffect, useState } from 'react';

import css from './CaptionsPresetCard.module.scss';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaRegCheckCircle } from 'react-icons/fa';
import PopupPortal from '@autocut/components/atoms/PopupPortal/PopupPortal';

const ANIMATION_SPEED = 2;
export const PRESET_CARD_HEIGHT = 40;

export const CaptionsPresetCard = ({
  preset,
  onClick,
  withUsageCount = false,
  isSelected,
}: {
  preset: CaptionsPreset;
  onClick: () => void;
  onRemoveError?: (error: React.ReactNode) => void;
  withUsageCount?: boolean;
  isSelected?: boolean;
}) => {
  const textRef = React.useRef<HTMLParagraphElement>(null);
  const [imageWidthDifference, setImageWidthDifference] = useState(0);

  useEffect(() => {
    const textElement = textRef.current;
    const containerElement = textElement?.parentElement;
    const cardElement = containerElement?.parentElement;

    if (!textElement || !containerElement || !cardElement) return;

    const width = textElement.getBoundingClientRect().width;
    const containerWidth = containerElement.getBoundingClientRect().width;

    const isBigger = width > containerWidth;
    const difference = width - containerWidth;

    if (!isBigger) {
      textElement.style.width = containerWidth + 'px';
      return;
    } else {
      cardElement.addEventListener('mouseenter', () => {
        textElement.style.left = `-${difference}px`;
        textElement.style.transition = `left ${
          (difference / containerWidth) * ANIMATION_SPEED
        }s ease-in-out`;
      });

      cardElement.addEventListener('mouseleave', () => {
        textElement.style.left = `0`;
        textElement.style.transition = 'none';
      });
    }

    return () => {
      cardElement.removeEventListener('mouseenter', () => {
        textElement.style.left = `-${difference}px`;
        textElement.style.transition = `left ${
          (difference / containerWidth) * ANIMATION_SPEED
        }s ease-in-out`;
      });

      cardElement.removeEventListener('mouseleave', () => {
        textElement.style.left = `0`;
        textElement.style.transition = 'none';
      });
    };
  }, []);

  const splittedTitle = preset.title.split(' ');

  return (
    <div
      className={`${css.container} ${isSelected ? css.selected : ''}`}
      style={{
        height: PRESET_CARD_HEIGHT,
      }}
    >
      <div className={css.selectedIcon}>
        <FaRegCheckCircle />
      </div>

      <div className={css.presetCard}>
        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          gap={10}
          onClick={onClick}
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            minHeight: '30px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {preset.thumbnailUrl && (
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',

                overflow: 'hidden',
              }}
            >
              <img
                className={css.thumbnail}
                src={preset.thumbnailUrl}
                style={{
                  maxWidth: 'none',
                  height: '100%',
                  transition: 'transform 1s',
                  willChange: 'transform',
                  ...{
                    '--image-width-difference': `${imageWidthDifference}px`,
                  },
                }}
                onLoad={event => {
                  const difference = event.currentTarget.parentElement
                    ?.clientWidth
                    ? event.currentTarget.parentElement?.clientWidth -
                      event.currentTarget.width
                    : 0;
                  setImageWidthDifference(Math.min(0, difference));
                }}
              />
            </div>
          )}

          {!preset.thumbnailUrl && (
            <p
              ref={textRef}
              style={{
                width: 'fit-content',
                position: 'absolute',
                left: '0',

                display: 'flex',
                alignItems: 'center',
                gap: '2px',

                whiteSpace: 'nowrap',
                color: preset.params.text?.color
                  ? rgbToHex({
                      r: preset.params.text?.color?.r ?? 255,
                      g: preset.params.text?.color?.g ?? 255,
                      b: preset.params.text?.color?.b ?? 255,
                    })
                  : undefined,
                fontFamily: preset.params.text?.font?.fontFamily,
                fontSize: '13px',
                lineHeight: '14px',
              }}
              onClick={onClick}
            >
              {splittedTitle[0] + ' '}
              <span
                style={{
                  fontFamily: preset.params.text?.font?.fontFamily,
                  color:
                    preset.params.animations?.highlight?.enabled &&
                    preset.params.animations.highlight.color
                      ? rgbToHex({
                          r: preset.params.animations.highlight.color.r ?? 255,
                          g: preset.params.animations.highlight.color.g ?? 255,
                          b: preset.params.animations.highlight.color.b ?? 255,
                        })
                      : undefined,
                  background:
                    preset.params.animations?.wordBox?.enabled &&
                    preset.params.animations.wordBox.color
                      ? hexToHexOpacity(
                          rgbToHex({
                            r: preset.params.animations.wordBox.color.r ?? 255,
                            g: preset.params.animations.wordBox.color.g ?? 255,
                            b: preset.params.animations.wordBox.color.b ?? 255,
                          }),
                          preset.params.animations.wordBox.opacity ?? 100
                        )
                      : undefined,
                  padding: preset.params.animations?.wordBox?.enabled
                    ? `${
                        (preset.params.animations.wordBox.yPadding ?? 0) / 100
                      }rem ${
                        (preset.params.animations.wordBox.xPadding ?? 0) / 100
                      }rem`
                    : undefined,
                  display:
                    preset.params.animations?.wordBox?.enabled &&
                    preset.params.animations.wordBox.yPadding
                      ? 'inline-block'
                      : undefined,
                  borderRadius: preset.params.animations?.wordBox?.enabled
                    ? `${
                        (preset.params.animations.wordBox.radius ?? 0) / 100
                      }rem`
                    : undefined,
                  fontWeight: preset.params.animations?.boldWithText
                    ? 900
                    : 'normal',
                }}
              >
                {splittedTitle[1] ?? splittedTitle[0]}
              </span>
              {' ' + splittedTitle.slice(2).join(' ')}
            </p>
          )}
        </FlexContainer>
        {withUsageCount && <UsageCount count={preset.usageCount} />}
      </div>
    </div>
  );
};

const UsageCount = ({ count = 0 }: { count: number }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <FlexContainer
      ref={ref}
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      gap={2}
      className={css.usageCount}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <AiOutlineDownload size="1rem" color="white" strokeWidth={'2rem'} />
      {count}

      {isHovered && (
        <PopupPortal target={ref} position="left">
          <p className={css.tooltip}>
            <TranslatedMessage
              id="captions_presetCustomization_usageCount"
              defaultMessage="Used by {count} users"
              values={{ count }}
            />
          </p>
        </PopupPortal>
      )}
    </FlexContainer>
  );
};

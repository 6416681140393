import { fs, path } from '@autocut/lib/cep/node';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { merge } from 'lodash';
import { getAllLocalStorage, setLocalStorage } from './localStorage.utils';
import { getLogFolder } from './logger';

const getPersistanceStorageFilePath = () => {
  const folder = getLogFolder();
  const filePath = path.join(folder, 'persistenceStorage.log');

  return filePath;
};

const isPersistenceStorageExist = () => {
  const filePath = getPersistanceStorageFilePath();

  return fs.existsSync(filePath);
};

const saveLocalStorage = () => {
  const items = getAllLocalStorage();

  const filePath = getPersistanceStorageFilePath();

  try {
    fs.writeFileSync(filePath, JSON.stringify(items), { flag: 'w+' });
  } catch (err: any) {
    manageError({
      error: new IncrementalError(err, 'saveLocalStorage'),
      disableModal: true,
    });
  }
};

const fillLocalStorageWithPersistanceStorage = () => {
  const filePath = getPersistanceStorageFilePath();

  if (!fs.existsSync(filePath)) return;

  let data: any = {};

  try {
    // READ PERSISTANCE STORAGE FILE
    const fileData = fs.readFileSync(filePath, 'utf8');
    data = JSON.parse(fileData);
    // MERGE PERSISTANCE STORAGE DATA WITH LOCAL STORAGE (LOCAL STORAGE HAS PRIORITY)
    const mergedData = merge(data, getAllLocalStorage());
    for (const property in mergedData) {
      setLocalStorage(property, mergedData[property], false, false);
    }
  } catch (error: any) {
    // Do nothing if can't read persistance storage
  }
};

export {
  fillLocalStorageWithPersistanceStorage,
  isPersistenceStorageExist,
  saveLocalStorage,
};

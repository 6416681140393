import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { CaptionsPreset } from '@autocut/validationSchemas/caption.validationSchema';
import React from 'react';

export const PresetPreview = ({
  selectedPreset,
}: {
  selectedPreset: CaptionsPreset | null;
}) => {
  if (selectedPreset === null)
    return (
      <TranslatedMessage
        id="captions_presetCustomization_no_preset_selected"
        defaultMessage="Select preset to see preview"
      />
    );

  if (!!selectedPreset.videoUrl)
    return <video src={selectedPreset.videoUrl} autoPlay loop />;

  return (
    <TranslatedMessage
      id="captions_presetCustomization_no_preview_available"
      defaultMessage="No preview available for this preset"
    />
  );
};

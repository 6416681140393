import { autocutApi } from '../http.utils';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';

export const deleteUnusedPresets = async (force = false) => {
  try {
    const res = await autocutApi.delete(`/captions-presets/unused`, {
      data: {
        force,
      },
    });

    if (res?.status === 200) {
      return res.data;
    }

    return null;
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'createCaptionsPreset'),
    });
    return null;
  }
};

export const getLineYOffset = ({
  fontSize,
  nbLines,
  indexLine,
  maxLineHeight,
}: {
  fontSize: number;
  nbLines: number;
  indexLine: number;
  maxLineHeight: number;
}) => {
  const isLastLine = indexLine === nbLines - 1;
  return 1.2 * fontSize * indexLine + (isLastLine ? maxLineHeight : 0);
};

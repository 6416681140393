import { AutoCutApiError } from '../errors/AutoCutApiError';
import { IncrementalError } from '../errors/IncrementalError';
import { autocutApi } from '../http.utils';
import { manageError } from '../manageError';

export enum EmojiCategory {
  ACTIVITY = 'activity',
  FLAGS = 'flags',
  ANIMALS_AND_NATURE = 'animals-and-nature',
  FOOD_AND_DRINK = 'food—and—drink',
  OBJECTS = 'objects',
  PEOPLE = 'people',
  SMILEYS = 'smileys',
  SYMBOLS = 'symbols',
  TRAVEL_AND_PLACES = 'travel-and-places',
  SKIN_TONES = 'skin-tones',
}

export const getEmojisCategories = async (
  animated = false
): Promise<EmojiCategory[]> => {
  const endpoint = animated
    ? `emojis/categories?animated=true`
    : `emojis/categories?animated=false`;

  const {
    data = [],
  }: {
    data: string[];
  } = await autocutApi
    .get(endpoint)
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'generateEmojisFromTranscription'),
      });

      return {} as never;
    });

  return data as EmojiCategory[];
};

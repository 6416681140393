import { autocutApi } from '../http.utils';

export const getChapters = async (parameters: {
  transcript: {
    id: number;
    sentence: string;
  }[];
}) => {
  const { data } = await autocutApi.post<ChaptersOpenAiResponse>(
    'openAI/chapters',
    {
      transcript: parameters.transcript,
    }
  );

  return data;
};

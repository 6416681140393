import { clamp } from '@autocut/utils';
import * as React from 'react';
import { RxCross1 } from 'react-icons/rx';
import css from './ZoomAnchor.module.css';

export type ZoomAnchorProps = {
  x: number;
  y: number;
  coeffZoomMin: number;
  coeffZoomMax: number;
  onChange: (
    anchorPosMinCoef: { xPercentage: number; yPercentage: number },
    anchorPosMaxCoef: { xPercentage: number; yPercentage: number }
  ) => void;
};

export const ZoomAnchor = ({
  x,
  y,
  coeffZoomMax,
  coeffZoomMin,
  onChange,
}: ZoomAnchorProps) => {
  const [crossStyle, setCrossStyle] = React.useState({
    left: `50%`,
    top: `50%`,
  });
  const [minZoomBoxStyle, setMinZoomBoxStyle] = React.useState({
    left: `50%`,
    top: `50%`,
  });
  const [maxZoomBoxStyle, setMaxZoomBoxStyle] = React.useState({
    left: `50%`,
    top: `50%`,
  });
  const zoomAnchorContainerRef = React.useRef<HTMLDivElement>(null);
  const minCoeffZoomBoxRef = React.useRef<HTMLDivElement>(null);
  const maxCoeffZoomBoxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    //If x = 0 and crossStyle.left = 50%, we are in the initial state at the first render so we do not update the position as the preview is not yet loaded
    if (x === 0 && crossStyle.left === '50%') return;

    const zoomAnchorContainerBBox =
      zoomAnchorContainerRef.current?.getBoundingClientRect();
    updatePositions(zoomAnchorContainerBBox)(x, y);
  }, [coeffZoomMax, coeffZoomMin, x, y]);

  // When the preview load for the first time we reset the parameters so that they match what is display
  // It would be a hassle to display the previous value considering all the different format
  React.useEffect(() => {
    onChange(
      { xPercentage: 0.5, yPercentage: 0.5 },
      { xPercentage: 0.5, yPercentage: 0.5 }
    );
  }, []);

  const updatePositions =
    (zoomAnchorContainerBBox?: DOMRect) => (x: number, y: number) => {
      if (!zoomAnchorContainerBBox) {
        if (!zoomAnchorContainerRef.current) return;
        zoomAnchorContainerBBox =
          zoomAnchorContainerRef.current?.getBoundingClientRect();
      }

      updateCrossPosition(x, y, zoomAnchorContainerBBox);
      const anchorPosMinCoef = updateMinZoomBoxPosition(
        x,
        y,
        zoomAnchorContainerBBox
      );
      const anchorPosMaxCoef = updateMaxZoomBoxPosition(
        x,
        y,
        zoomAnchorContainerBBox
      );
      const anchorPosPercentageMinCoef = {
        xPercentage: anchorPosMinCoef.x / zoomAnchorContainerBBox.width,
        yPercentage: anchorPosMinCoef.y / zoomAnchorContainerBBox.height,
      };
      const anchorPosPercentageMaxCoef = {
        xPercentage: anchorPosMaxCoef.x / zoomAnchorContainerBBox.width,
        yPercentage: anchorPosMaxCoef.y / zoomAnchorContainerBBox.height,
      };
      onChange(anchorPosPercentageMinCoef, anchorPosPercentageMaxCoef);
    };

  const updateCrossPosition = (
    x: number,
    y: number,
    zoomAnchorContainerBBox: DOMRect
  ) => {
    setCrossStyle({
      left: `${clamp(x, 0, zoomAnchorContainerBBox.width)}px`,
      top: `${clamp(y, 0, zoomAnchorContainerBBox.height)}px`,
    });
  };

  const updateMinZoomBoxPosition = (
    xRelative: number,
    yRelative: number,
    zoomAnchorContainerBBox: DOMRect
  ) => {
    if (!minCoeffZoomBoxRef.current) return { x: 0, y: 0 };

    const minCoeffZoomBoxBBox =
      minCoeffZoomBoxRef.current.getBoundingClientRect();
    const xPos = clamp(
      xRelative,
      minCoeffZoomBoxBBox.width / 2,
      zoomAnchorContainerBBox.width - minCoeffZoomBoxBBox.width / 2
    );
    const yPos = clamp(
      yRelative,
      minCoeffZoomBoxBBox.height / 2,
      zoomAnchorContainerBBox.height - minCoeffZoomBoxBBox.height / 2
    );

    setMinZoomBoxStyle({
      left: `${xPos}px`,
      top: `${yPos}px`,
    });

    return { x: xPos, y: yPos };
  };

  const updateMaxZoomBoxPosition = (
    xRelative: number,
    yRelative: number,
    zoomAnchorContainerBBox: DOMRect
  ) => {
    if (!maxCoeffZoomBoxRef.current) return { x: 0, y: 0 };

    const maxCoeffZoomBoxBBox =
      maxCoeffZoomBoxRef.current.getBoundingClientRect();
    const xPos = clamp(
      xRelative,
      maxCoeffZoomBoxBBox.width / 2,
      zoomAnchorContainerBBox.width - maxCoeffZoomBoxBBox.width / 2
    );
    const yPos = clamp(
      yRelative,
      maxCoeffZoomBoxBBox.height / 2,
      zoomAnchorContainerBBox.height - maxCoeffZoomBoxBBox.height / 2
    );

    setMaxZoomBoxStyle({
      left: `${xPos}px`,
      top: `${yPos}px`,
    });

    return { x: xPos, y: yPos };
  };

  return (
    <div className={css.zoomAnchorContainer} ref={zoomAnchorContainerRef}>
      <RxCross1
        size={32}
        className={css.cross}
        style={crossStyle}
        color="white"
      />
      <div
        className={`${css.zoomBox}`}
        style={{
          width: `calc(100% / ${coeffZoomMin})`,
          height: `calc(100% / ${coeffZoomMin})`,
          border: 'unset',
          ...minZoomBoxStyle,
        }}
        ref={minCoeffZoomBoxRef}
      />
      <div
        className={`${css.zoomBox} ${css.max}`}
        style={{
          width: `calc(100% / ${coeffZoomMax})`,
          height: `calc(100% / ${coeffZoomMax})`,
          ...maxZoomBoxStyle,
        }}
        ref={maxCoeffZoomBoxRef}
      />
    </div>
  );
};

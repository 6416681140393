import { TranscriptionProviders } from '../launchTranscript';

export const isBrollProviderAvailable = (
  provider: TranscriptionProviders
): {
  isAvailable: boolean;
  message?: string;
} => {
  if (provider === 'google') {
    return {
      isAvailable: false,
      message: 'broll_google_not_supported',
    };
  }

  return {
    isAvailable: true,
  };
};

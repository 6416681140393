import React, { HTMLAttributes } from 'react';

import css from './Accordion.module.scss';

export type AccordionProps = {
  children: React.ReactNode;
  open: boolean;
  className?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, 'className'>;

export const Accordion = ({
  children,
  open,
  className,
  ...props
}: AccordionProps) => {
  return (
    <div
      className={`${css.container} ${open ? css.open : ''} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/atoms/Stepper/StepperRouter/StepperRouter';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { ModeWrapper } from '@autocut/modes/ModeWrapper';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import RepeatLanguage from './Steps/Language/RepeatLanguage';
import { RepeatTranscriptStep } from './Steps/Transcript/RepeatTranscriptStep';

export const RepeatMode = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ModeWrapper
      Icon={AutocutModes.Repeat.icon}
      text={AutocutModes.Repeat.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/cut/repeat"
        steps={[
          {
            label: intl.formatMessage({
              id: 'captions_step_script',
              defaultMessage: 'Select clips & language',
            }),
            clickable: true,
            path: 'script',
            element: <RepeatLanguage />,
            index: true,
          },
          {
            label: intl.formatMessage({
              id: 'repeat_step_transcription',
              defaultMessage: 'Check transcription',
            }),
            path: 'transcript',
            element: <RepeatTranscriptStep />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

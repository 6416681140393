export enum Spacing {
  s1 = 'var(--spacing-s1)',
  s2 = 'var(--spacing-s2)',
  s3 = 'var(--spacing-s3)',
  s4 = 'var(--spacing-s4)',
  s5 = 'var(--spacing-s5)',
  s6 = 'var(--spacing-s6)',
  s7 = 'var(--spacing-s7)',
  s8 = 'var(--spacing-s8)',
  s10 = 'var(--spacing-s10)',
  s12 = 'var(--spacing-s12)',
  s16 = 'var(--spacing-s16)',
  s20 = 'var(--spacing-s20)',
  s24 = 'var(--spacing-s24)',
  s32 = 'var(--spacing-s32)',
  s40 = 'var(--spacing-s40)',
  s48 = 'var(--spacing-s48)',
  s56 = 'var(--spacing-s56)',
  s64 = 'var(--spacing-s64)',
}

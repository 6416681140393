import React from 'react';
import { TranslatedMessage } from '../TranslatedMessage/TranslatedMessage';
import { useHandleProcessTranscript } from '@autocut/hooks/useHandleProcessTranscript';
import { NeedingTranscriptionModes } from '@autocut/types/transcription';
import { Button } from '../Buttons/Button';
import { isProviderAvailable } from '@autocut/utils/transcript/isProviderAvailable/isProviderAvailable';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const RetryTranscriptionButton = ({
  modeId,
  usedModel = 0,
}: {
  modeId: NeedingTranscriptionModes;
  usedModel?: number;
}) => {
  const { handleProcessTranscript, error, loading } =
    useHandleProcessTranscript(modeId);

  if (error || !isProviderAvailable(modeId, usedModel).isAvailable) return null;

  return (
    <FlexContainer
      flexDirection="column"
      gap={24}
      alignItems="flex-start"
      style={{
        paddingInline: '16px',
      }}
    >
      <p
        style={{
          color: 'white',
          fontSize: '13px',
          lineHeight: '23px',
          fontWeight: 'bold',
        }}
      >
        <TranslatedMessage
          id="deepgram_error_retry"
          defaultMessage="You can also retry the transcription with one of our other AI."
        />
      </p>

      <Button
        onClickFunction={async () => handleProcessTranscript(usedModel)}
        disabled={loading}
        buttonType="primary"
      >
        <TranslatedMessage
          id="retry_transcript"
          defaultMessage="Retry with an other AI"
        />
      </Button>
    </FlexContainer>
  );
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, LoaderInfinity } from '@autocut/components/atoms';
import { autocutApi } from '@autocut/utils';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { BRoll, BRollVideo } from '@autocut/types/BRolls';
import { debounce } from '@autocut/utils/debounce';
import {
  PexelPreview,
  PexelVideoRef,
} from '@autocut/components/atoms/PexelPreview/PexelPreview';

import css from './BRollChoiceModal.module.css';
import { setAutocutStore } from '@autocut/utils/zustand';
import { modifyVideoSize } from '@autocut/utils/bRolls/modifyVideoSize';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export type BRollChoiceModalProps = {
  bRoll: BRoll;
  closeModalFunction: () => void;
};

export const BRollChoiceModal = ({
  bRoll,
  closeModalFunction,
}: BRollChoiceModalProps) => {
  const intl = useIntl();

  const sequence = useAutoCutStore(state => state.sequence.infos);

  const lastKeywords = useRef<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [currentKeywords, setCurrentKeywords] = useState<string>(
    bRoll.description || ''
  );
  const [videos, setVideos] = useState<BRoll['video'][]>([]);

  const debouncedGetVideo = useCallback(
    debounce(async (description: string) => {
      const videos = await getVideos(description);
      setVideos(videos);

      setAutocutStore('onGoingProcess.bRolls', state =>
        (state.onGoingProcess.bRolls as BRoll[]).map(currentBRoll => {
          if (currentBRoll.id === bRoll.id) {
            return {
              ...currentBRoll,
              description: description,
            };
          }

          return currentBRoll;
        })
      );
    }, 2000),
    []
  );

  useEffect(() => {
    if (currentKeywords === '' || lastKeywords.current === currentKeywords)
      return;

    void debouncedGetVideo(currentKeywords);
  }, [currentKeywords]);

  useEffect(() => {
    const handleFirstVideoFetch = async () => {
      const videos = await getVideos(bRoll.description);

      setVideos(videos);
    };
    if (currentKeywords === '') return;

    void handleFirstVideoFetch();
  }, []);

  const getVideos = async (description: string) => {
    setIsLoading(true);
    lastKeywords.current = description;

    const { data: videos } = await autocutApi.post('/pexel/videos', {
      description: description,
      minDuration: Number(bRoll.end - bRoll.start),
      minWidth: sequence?.settings.width,
      minHeight: sequence?.settings.height,
      count: 20,
    });

    setIsLoading(false);

    return videos;
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'broll_search_modal_title',
        defaultMessage: 'Choose B-Roll',
      })}
      closeModalFunction={closeModalFunction}
      fullScreen
    >
      <FlexContainer flexDirection="column" gap={8}>
        <Input
          type="text"
          placeholder={intl.formatMessage({
            id: 'broll_search_modal_input_placeholder',
            defaultMessage: 'Type to search for B-Rolls',
          })}
          value={currentKeywords}
          onChange={e => setCurrentKeywords(e.target.value)}
          inputSize="sm"
          width={'100%'}
        />

        <FlexContainer
          flexWrap="wrap"
          gap={8}
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <FlexContainer
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <LoaderInfinity height={200} />
              <TranslatedMessage
                id="broll_search_modal_searching"
                defaultMessage="Searching for B-Rolls..."
              />
            </FlexContainer>
          ) : (
            videos.map((video: any) => (
              <VideoContainerDiv
                key={video.id}
                video={video}
                bRoll={bRoll}
                onBrollPick={closeModalFunction}
              />
            ))
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

const VideoContainerDiv = ({
  video,
  bRoll,
  onBrollPick,
}: {
  video: BRollVideo;
  bRoll: BRoll;
  onBrollPick: () => void;
}) => {
  const videoRef = useRef<PexelVideoRef>(null);

  const changeVideo = (video: BRoll['video']) => {
    setAutocutStore('onGoingProcess.bRolls', state =>
      (state.onGoingProcess.bRolls as BRoll[]).map(currentBRoll => {
        if (currentBRoll.id === bRoll.id) {
          return {
            ...currentBRoll,
            video: video,
          };
        }
        return currentBRoll;
      })
    );
  };

  const onMouseEnter = async () => {
    await videoRef.current?.play();
  };

  const onMouseLeave = () => {
    videoRef.current?.pause();
  };

  return (
    <div
      className={css.videoContainer}
      key={video.id}
      onClick={() => {
        changeVideo(video);
        onBrollPick();
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PexelPreview
        ref={videoRef}
        picture={video.previewPictures[0].picture}
        videoSrc={modifyVideoSize(video?.downloadUrl as string, 150, 85)}
      />
    </div>
  );
};

import { IntlShape } from 'react-intl';
import logLevel from '@autocut/types/logLevel.enum';
import { logger } from '../logger';
import { changeMessage } from './changeMessage';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleFinalMessage', level, message, objects);
};

//Le but de cette fontion est d'afficher un message final de le bouton principal et le remplacer par le 'CUT' au bout de 4 secondes
export const handleFinalMessage = (
  intl: IntlShape,
  messageId: string,
  defaultMessage: string
) => {
  logMessage(logLevel.notice, 'Creating timeout with message.', {
    messageId,
  });
  changeMessage(intl, messageId, defaultMessage);

  setTimeout(() => {
    changeMessage(intl, '', '');
  }, 4000);
};

export const splitArrayInChunks = <T extends any[]>(
  array: T,
  chunkSize: number,
  fillLastChunk = false
): T[] => {
  if (!Array.isArray(array)) return [] as unknown[][] as T[];

  const result = array.reduce((acc, value, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!acc[chunkIndex]) {
      acc[chunkIndex] = [] as T[]; // start a new chunk
    }

    acc[chunkIndex].push(value);

    return acc;
  }, [] as unknown[][] as T[]);

  if (fillLastChunk) {
    const lastChunk = result[result.length - 1];
    const lastChunkLength = lastChunk.length;
    if (lastChunkLength < chunkSize) {
      lastChunk[chunkSize - 1] = 0;
      lastChunk.fill(0, lastChunkLength, chunkSize);
    }
  }

  return result;
};

export const isArrayContainsDuplicates = <T>(
  array: T[],
  getterFunction: (element: T) => any = (element: T) => element
): boolean => {
  const seen: any[] = [];
  for (const element of array) {
    const value = getterFunction(element);
    if (seen.includes(value)) {
      return true;
    }
    seen.push(value);
  }
  return false;
};

const emptyElement = [null, undefined, '', 0, false];
type TEmptyElement = (typeof emptyElement)[number];
export const filterEmptyElements = <T>(array: (T | TEmptyElement)[]): T[] => {
  return array.filter(Boolean) as T[];
};

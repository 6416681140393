import { PProLocale } from '@autocut/context/LocaleContext';
import { resourcesConfigs } from '@autocut/enums/resources.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { IntlShape } from 'react-intl';
import { ClipTypeEnum } from '../../../jsx/ppro/types.ppro';
import { csi, evalTS } from '../../lib/utils/bolt';
import { changeMessage } from '../cutButton/changeMessage';
import { addXp } from '../game/addXp';
import { handleProcessBase } from '../process/handleProcessBase';
import { waitForResource } from '../resourceManager.utils';
import { getAllClips } from '../timeline/selectedInfos.utils';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import { computeBestZoomIntervals } from './computeBestZoomIntervals';
import { getAudioClipWavData } from './getAudioClipsWavData';

const addZooms = async (intl: IntlShape) => {
  try {
    changeMessage(
      intl,
      'autozoom_button_message_step1',
      'Autozoom preparation ...'
    );

    await waitForResource(resourcesConfigs.adjustment_layer_prproj);
    const outputFilePath =
      autocutStoreVanilla().resources.adjustment_layer_prproj.filePath;
    if (!outputFilePath) {
      throw new Error('Adjustment layer not downloaded');
    }

    changeMessage(
      intl,
      'autozoom_button_message_step2',
      'Extracting audio data ...'
    );

    const clipsAudioData = await getAudioClipWavData();

    changeMessage(
      intl,
      'autozoom_button_message_step3',
      'Computing zoom intervals ...'
    );

    const zoomIntervals = await computeBestZoomIntervals(clipsAudioData);
    // On récupère la locale de l'utilisateur car les noms des effets dépendants de la langue de PPro
    const hostEnv = csi.hostEnvironment;

    const clips = getAllClips(); //Return clip infos if available ?
    const videoClips = clips.filter(clip => clip.type === ClipTypeEnum.Video);
    const highestTrackIndex = getHighestTrackIndexFromClips(videoClips) + 1;
    await evalTS('importAdjustmentLayer', outputFilePath);
    await evalTS('insertTrackForZoomAdjustmentLayer', highestTrackIndex);

    setAutocutStore(
      'onGoingProcess.CEPProgressCallback',
      () =>
        ({ current, max }: { current: number; max: number }) => {
          console.log('max, current : ', max, ' ', current);
          changeMessage(
            intl,
            'autozoom_button_message_step4',
            'Applying zoom {current} out of {max} ...',
            { current, max }
          );
        }
    );
    await evalTS('addZooms', {
      indexTrackToInsert: highestTrackIndex,
      zoomIntervals,
      local: hostEnv.appUILocale as PProLocale,
    });

    setAutocutStore('onGoingProcess.nbStepTotal', zoomIntervals.length);
    const xpGained = zoomIntervals.length * 0.55;
    await addXp(xpGained);
    setAutocutStore('game.level.xpGained', xpGained);
  } catch (err: any) {
    throw new IncrementalError(err, 'addZooms');
  }
};

const getHighestTrackIndexFromClips = (clips: Clip[]) => {
  return clips.reduce(
    (highestClip, currentClip) =>
      currentClip.numTrack > highestClip.numTrack ? currentClip : highestClip,
    clips[0]
  ).numTrack;
};

export const handleZoomProcess = handleProcessBase({
  executeProcess: addZooms,
});

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React, { useState } from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { IoCreateOutline } from 'react-icons/io5';
import css from './EditTranscriptModal.module.scss';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { CaptionsTranscriptButtons } from '@autocut/modes/captions/Steps/Transcript/CaptionsTranscriptEditorStep/CaptionsTranscriptButtons/CaptionsTranscriptButtons';
import { CaptionChunk } from '@autocut/types/Captions';
import { mergeChunks } from '@autocut/utils/captions/mergeChunks.utils';
import { isInInterval, playChunk } from '@autocut/utils/captions/utils';
import { Chunk } from '@autocut/modes/captions/Steps/Transcript/CaptionsTranscriptEditorStep/Chunk/Chunk';
import { CustomizeChunkCard } from '@autocut/modes/captions/Steps/Transcript/CaptionsTranscriptEditorStep/CustomizeChunkCard/CustomizeChunkCard';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { Switch } from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';

export const EditTranscriptModal = () => {
  const {
    captionsChunks,
    inPoint = null,
    outPoint = null,
    enableSoundPlay = true,
  } = useAutoCutStore(state => ({
    captionsChunks: state.onGoingProcess.captionChunks,
    inPoint: state.sequence.infos?.inPoint,
    outPoint: state.sequence.infos?.outPoint,
    enableSoundPlay: state.ui.parameters.caption.enableSoundPlay,
  }));

  const [localeCaptionChunks, setLocaleCaptionChunks] =
    useState(captionsChunks);
  const [selectedChunkIndex, setSelectedChunkIndex] = React.useState<
    null | number
  >(0);
  const [currentTimeout, setCurrentTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  if (!localeCaptionChunks) {
    return null;
  }

  const isRtl: boolean =
    autocutStoreVanilla().ui.parameters.caption?.languageOfTranscription
      .textDirection === 'rtl';

  return (
    <Modal
      icon={<IoCreateOutline size="1.5rem" />}
      title={
        <TranslatedMessage
          id="captions_presetCustomization_editTranscriptionModal_title"
          defaultMessage="Edit transcription"
        />
      }
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      fullWidth
      footer={
        <Button
          onClick={() => setAutocutStore('ui.openedModalName', '')}
          color={colors.primary600}
        >
          <TranslatedMessage id="button_save" defaultMessage="Save" />
        </Button>
      }
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <FlexContainer flexDirection="column" gap={8}>
        <FlexContainer
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <CaptionsTranscriptButtons
            captionChunks={localeCaptionChunks}
            updateChunks={(captionChunks: CaptionChunk[]) => {
              setLocaleCaptionChunks(captionChunks);
              setAutocutStore('onGoingProcess.captionChunks', captionChunks);
            }}
          />

          <Switch
            checked={enableSoundPlay}
            onChange={value =>
              setAutocutStore('ui.parameters.caption.enableSoundPlay', value)
            }
            size={18}
            leftLabel={
              <TranslatedMessage
                id="captions_editor_sound_play"
                defaultMessage="Enable sound play"
              />
            }
          />
        </FlexContainer>

        <CustomizeChunkCard
          selectedChunkIndex={selectedChunkIndex}
          chunks={localeCaptionChunks ?? []}
          onChange={newChunks => {
            if (selectedChunkIndex === null) return;

            setLocaleCaptionChunks(newChunks);
            setAutocutStore('onGoingProcess.captionChunks', newChunks);
          }}
        />

        <ParametersGroupLayout
          title={
            <div className={css.title}>
              <TranslatedMessage
                id="captions_editor_description_title"
                defaultMessage="Select captions you want to edit"
              />
            </div>
          }
        >
          <FlexContainer
            flexDirection={isRtl ? 'row-reverse' : 'row'}
            flexWrap="wrap"
            gap={2}
          >
            {(localeCaptionChunks || []).map((chunk, index) => {
              if (!chunk) return null;

              return (
                <Chunk
                  key={index}
                  chunk={chunk}
                  onSelect={async () => {
                    setSelectedChunkIndex(index);

                    if (
                      (inPoint !== null || outPoint !== null) &&
                      !isInInterval(chunk.start, chunk.end, [
                        inPoint ?? 0,
                        outPoint ??
                          localeCaptionChunks[localeCaptionChunks.length - 1]
                            .end,
                      ])
                    ) {
                      return;
                    }

                    if (enableSoundPlay)
                      setCurrentTimeout(
                        await playChunk(chunk.start, chunk.end, currentTimeout)
                      );
                  }}
                  onMerge={() => {
                    if (!localeCaptionChunks) return;
                    const newChunks = mergeChunks(localeCaptionChunks, index);
                    if (selectedChunkIndex && selectedChunkIndex > index) {
                      setSelectedChunkIndex(selectedChunkIndex - 1);
                    }

                    setLocaleCaptionChunks(newChunks);
                    setAutocutStore('onGoingProcess.captionChunks', newChunks);
                  }}
                  selected={index === selectedChunkIndex}
                  isRtl={isRtl}
                  isLast={index === localeCaptionChunks.length - 1}
                />
              );
            })}
          </FlexContainer>
        </ParametersGroupLayout>
      </FlexContainer>
    </Modal>
  );
};

import Stepper, {
  StepperProps,
  StepperStep,
} from '@autocut/components/atoms/Stepper/Stepper';
import { StatCategory } from '@autocut/types/StatCategory.enum';
import { StatType } from '@autocut/types/StatType.enum';
import { sendStats } from '@autocut/utils';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { posix } from 'path';
import React, { useEffect, useMemo } from 'react';
import {
  Location,
  matchPath,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router';

export type StepperRouterStep<T> = {
  path: string;
  index?: boolean;
  element: JSX.Element;
  clickable?: boolean;
} & Omit<StepperStep<T>, 'onClick'>;

export type StepperRouterProps<T> = {
  steps: StepperRouterStep<T>[];
  basePath?: string;
} & Omit<StepperProps<T>, 'activeIndex' | 'steps'>;

const StepperRouter = <T,>({ steps, basePath }: StepperRouterProps<T>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const sendFunnelStat = (index: number) => {
    index = index + 1;
    if (!steps[index]) return;
    setTimeout(async () => {
      await sendStats({
        type: StatType.STEPPER,
        value: `${basePath}/${steps[index].path}`,
        traits: {
          totalSteps: steps.length,
          currentStep: index,
        },
      });
    }, 1);
  };

  const activeIndex = useMemo(() => {
    const res =
      steps.findIndex(step =>
        matchPath(posix.join(basePath ?? '', step.path), pathname)
      ) - 1;
    sendFunnelStat(res);
    return res >= -1 ? res : steps.findIndex(step => step.index) - 1;
  }, [basePath, pathname, steps]);

  const indexStep = useMemo(
    () => steps.find(step => step.index) || steps[0],
    [steps]
  );

  useEffect(() => {
    setAutocutStore('ui.currentSteps', steps);

    return () => {
      setAutocutStore('ui.currentSteps', []);
    };
  }, []);

  return (
    <>
      <Stepper
        activeIndex={activeIndex}
        steps={steps.map(step => ({
          ...step,
          onClick: step.clickable ? () => navigate(step.path) : undefined,
        }))}
      />
      <Routes>
        <Route
          path=""
          element={
            <>
              <Navigate to={indexStep.path} />
              {indexStep.element}
            </>
          }
        />
        {steps.map(step => (
          <Route key={step.path} {...step} />
        ))}
        <Route path="*" element={<Navigate to={indexStep.path} />} />
      </Routes>
    </>
  );
};

export default StepperRouter;

export const getPreviousStepPath = (location: Location) => {
  const currentSteps = autocutStoreVanilla().ui.currentSteps;
  if (!currentSteps) return '/homepage';

  const previousIndex =
    currentSteps.findIndex(step =>
      location.pathname.endsWith('/' + step.path)
    ) - 1;

  if (previousIndex < 0) return '/homepage';

  const path = currentSteps[previousIndex]?.path;
  return path ? path : '/homepage';
};

import React from 'react';
import FlexContainer from '../../molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import { Text } from '../../atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';
import { FormSectionVariant } from './variants';
import { FontSize } from '@autocut/designSystem/enums/fontSize.enum';

export type FormSectionType = {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  variant?: FormSectionVariant;
};

export const FormSection = ({
  title,
  description,
  children,
  variant,
}: FormSectionType) => {
  return (
    <FlexContainer
      flexDirection="column"
      gap={Spacing.s2}
      style={{ width: '100%' }}
    >
      <FlexContainer flexDirection="column" gap={Spacing.s1}>
        <Text
          variant={
            variant === 'primary'
              ? 'textMd.bold'
              : variant === 'secondary'
              ? 'textSm.semibold'
              : 'textMd.bold'
          }
          color={colors.gray300}
        >
          {title}
        </Text>
        {description && (
          <Text
            variant="textXs"
            color={colors.gray400}
            style={{ lineHeight: FontSize.textXs }}
          >
            {description}
          </Text>
        )}
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};

import { AutoCutState, setAutocutStore } from '@autocut/utils/zustand';
import { Camera } from '@autocut/types/Camera';
import { updateSpeakerReducer } from '../speakers/updateSpeakerReducer';
import { Speaker } from '@autocut/types/Speaker';

export const updateCameraReducer =
  (cameraId: string, updatedFields: Partial<Camera>) =>
  (state: AutoCutState) => {
    return state.ui.parameters.podcast.cameras.map((camera: Camera) => {
      if (camera.id === cameraId) {
        const updatedCamera = {
          ...camera,
          ...updatedFields,
        };

        const speakerIds = updatedCamera.speakerIds;
        for (const speakerId of speakerIds) {
          const speaker = state.ui.parameters.podcast.speakers.find(
            speaker => speaker.id === speakerId
          ) as Speaker;

          if (!speaker) continue;

          setAutocutStore(
            'ui.parameters.podcast.speakers',
            updateSpeakerReducer(speakerId, {
              cameras: [
                ...speaker.cameras.filter(
                  (camera: Camera) => camera.id !== updatedCamera.id
                ),
                updatedCamera,
              ],
            })
          );
        }

        return updatedCamera;
      }
      return camera;
    });
  };

import * as React from 'react';
import { useEffect } from 'react';

import ModalManager from '@autocut/components/modals/ModalManager';
import { ResourceManager } from '@autocut/context/ResourceManager';
import { NoScript } from '@autocut/pages/NoScript';
import logLevel from '@autocut/types/logLevel.enum';
import { CEPProcessManager } from '@autocut/utils/cepProcessus/CEPProcessManager';
import { initAutocut } from '@autocut/utils/init';
import { fixTimeoutId } from '@autocut/utils/init/heartbeat';
import { manageError } from '@autocut/utils/manageError';
import { Navigate, Route, Routes } from 'react-router-dom';
import { OnboardingModal } from './components/modals/OnboardingModals/OnboardingWelcomeModal';
import { Header } from './components/partials';
import { GameHub } from './components/partials/GameHub';
import { ModeProvider } from './context/ModeProvider';
import { useAutoCutStore } from './hooks/useAutoCutStore';
import { useLogger } from './hooks/useLogger';
import { csi, evalTS } from './lib/utils/bolt';
import { AiSilencesMode } from './modes/ai/AiSilencesMode';
import { AutoZoomMode } from './modes/autozoom/AutoZoomMode';
import { BRollMode } from './modes/broll/BRollMode';
import CaptionsMode from './modes/captions/CaptionsMode';
import { LegacySilencesMode } from './modes/legacy/LegacySilencesMode';
import { PodcastMode } from './modes/podcast/PodcastMode';
import { RepeatMode } from './modes/repeat/RepeatMode';
import { ResizeMode } from './modes/resize/ResizeMode';
import { SwearWordsMode } from './modes/swearWords/SwearWordsMode';
import {
  CuttingDashboard,
  NoFFMPEG,
  NoInternetConnexion,
  Onboarding,
} from './pages';
import DevelopmentDashboard from './pages/DevelopmentDashboard/DevelopmentDashboard';
import { Login } from './pages/Onboarding/Login/Login';
import { Trial } from './pages/Onboarding/Trial/Trial';
import { onSequenceSelectionChangedCallbackDebounced } from './utils/init/registerCEPListeners';
import { autocutStoreVanilla } from './utils/zustand';
import { ChaptersMode } from './modes/chapter/ChapterMode';
import { ViralClipsMode } from './modes/viralClips/ViralClipsMode';
import { Homepage } from './pages/Homepage/Homepage';
import '@autocut/designSystem/styles/index';
import { DesignSystemExample } from './pages/DesignSystemExample/DesignSystemExample';
import { OnboardingProvider } from './context/OnboardingProvider/OnboardingProvider';

// WARNING: Do not remove this line
// If you really want to, test to delete localStorage and persistant storage after removing the line
// If the extension doesn't start again, put it back
OnboardingModal.name;

export default function App() {
  const { isLogged, errorDuringScriptLoading } = useAutoCutStore(state => ({
    isLogged: state.user.isLogged,
    errorDuringScriptLoading: state.ppro.errors.errorDuringScriptLoading,
  }));

  const { logMessage } = useLogger('App');

  useEffect(() => {
    const init = async () => {
      await initAutocut().then(() => {
        logMessage(logLevel.info, 'Autocut initialized.');
      });
      window.addEventListener(
        'beforeunload',
        async () => await evalTS('unregisterOnSequenceSelectionChanged')
      );
      window.onerror = async function (
        messageOrEvent,
        source,
        noligne,
        nocolonne,
        error
      ) {
        if (!error) return;
        await manageError({
          error,
          additionalData: messageOrEvent,
          disableModal: true,
        });
      };
    };

    void init();

    return () => {
      const intervals = autocutStoreVanilla().utils.intervals;
      intervals.map(interval => clearInterval(interval));

      //Stop heartbeat
      clearTimeout(fixTimeoutId);

      csi.removeEventListener(
        'selectionChanged',
        onSequenceSelectionChangedCallbackDebounced,
        null
      );

      CEPProcessManager.reset();
    };
  }, []);

  return (
    <>
      <ResourceManager>
        <OnboardingProvider>
          <ModalManager />
          <ModeProvider>
            <Header />
            <Routes>
              <Route path="/index.html" element={<Navigate to="/" />} />

              <Route path="/dev" element={<DevelopmentDashboard />} />
              <Route path="/ds" element={<DesignSystemExample />} />

              <Route path="/onboarding">
                <Route path="" element={<Onboarding />} />
                <Route path="login" element={<Login />} />
                <Route path="trial/*" element={<Trial />} />
              </Route>

              <Route path="/no-internet" element={<NoInternetConnexion />} />

              <Route path="/no-ffmpeg" element={<NoFFMPEG />} />

              {isLogged && !errorDuringScriptLoading ? (
                <>
                  <Route path="/homepage" element={<Homepage />} />
                  <Route path="/cut" element={<CuttingDashboard />}>
                    <Route path="" element={<Navigate to="legacy" />} />
                    <Route path="legacy" element={<LegacySilencesMode />} />
                    <Route path="ai" element={<AiSilencesMode />} />
                    <Route path="repeat/*" element={<RepeatMode />} />
                    <Route path="podcast" element={<PodcastMode />} />
                    <Route path="zoom" element={<AutoZoomMode />} />
                    <Route path="resize" element={<ResizeMode />} />
                    <Route path="broll/*" element={<BRollMode />} />
                    <Route path="swear_word" element={<SwearWordsMode />} />
                    <Route path="caption/*" element={<CaptionsMode />} />
                    <Route path="chapters/*" element={<ChaptersMode />} />
                    <Route path="viral_clips/*" element={<ViralClipsMode />} />
                  </Route>
                </>
              ) : errorDuringScriptLoading ? (
                <Route path="/cut/*" element={<NoScript />} />
              ) : null}

              <Route path="/" element={<Navigate to="/onboarding" />} />
              <Route path="/*" element={<Navigate to="/onboarding" />} />
            </Routes>
            <GameHub />
          </ModeProvider>
        </OnboardingProvider>
      </ResourceManager>
    </>
  );
}

import * as React from 'react';

import { TiArrowLeft } from 'react-icons/ti';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { autocutApi } from '@autocut/utils';
import { Button } from '../../atoms';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import './ExchangeRateModal.css';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { manageError } from '@autocut/utils/manageError';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';

const ExchangeRateModal = () => {
  const intl = useIntl();
  const license = useAutoCutStore(state => state.user.license);
  const { logMessage } = useLogger('ExchangeRateModal');

  const goBack = () =>
    setAutocutStore('ui.openedModalName', ModalType.EndProcess);

  const handleClick = async () => {
    logMessage(logLevel.notice, "Click on 'Go rate on Adobe Exchange' button", {
      license,
    });
    if (license === undefined) {
      logMessage(logLevel.warn, 'No key found.');
      return;
    }

    const key = license.key;
    if (key === '') {
      logMessage(logLevel.warn, 'No key found.');
      return;
    }

    try {
      const res = await autocutApi.post(`/modals/adobe-exchange-grade`);
      logMessage(
        logLevel.debug,
        'Response from server when adding extra-trial.',
        res
      );
    } catch (error: any) {
      manageError({
        error: new IncrementalError(error, 'ExchangeRateModal'),
        disableModal: true,
      });
    }

    logMessage(logLevel.notice, 'Open Adobe Exchange in default browser');
    openLinkInBrowser(
      'https://exchange.adobe.com/apps/cc/106665/autocut-auto-remove-silence'
    );
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'exchange_title',
        defaultMessage: 'Thanks for those 5 stars!',
      })}
      icon={<TiArrowLeft onClick={goBack} size={40} color="#FFFFFF" />}
      footer={
        <Button buttonType="tertiary" compact onClickFunction={handleClick}>
          <TranslatedMessage
            id="exchange_button"
            defaultMessage="Go to Adobe Exchange"
          />
        </Button>
      }
    >
      <FlexContainer flexDirection="column" alignItems="flex-start">
        <p>
          <TranslatedMessage
            id="exchange_text"
            defaultMessage="Go to Adobe Exchange and rate AutoCut to get an extra 7 days trial!"
          />
        </p>
        <p className="exchange-rate-info">
          <TranslatedMessage
            id="exchange_info"
            defaultMessage="If you have installed AutoCut from our website, you do not need to install the extension again."
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};

export default ExchangeRateModal;

import React, { CSSProperties } from 'react';
import css from './Pagination.module.scss';

import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const Pagination = ({
  page,
  hasNextPage,
  hasPreviousPage,
  setPage,
  iconSize = 20,
  maxWidth = 300,
}: {
  page: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  setPage: (page: number) => void;
  iconSize?: number;
  maxWidth?: number;
}) => {
  const getCurrentIndex = () => {
    if (!hasPreviousPage) return 0;

    if (!hasNextPage) return 2;

    return 1;
  };

  return (
    <div
      className={css.container}
      style={{
        ...({
          '--icon-size': `${iconSize}px`,
          '--container-max-width': `${maxWidth}px`,
        } as CSSProperties),
      }}
    >
      {
        <div
          onClick={() => hasPreviousPage && setPage(page - 1)}
          className={`${css.button} ${!hasPreviousPage ? css.disabled : ''}`}
        >
          <FaArrowLeft size={iconSize} />
        </div>
      }
      <FlexContainer gap={16} justifyContent="center" alignItems="center">
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={index}
            className={`${css.indicator} ${
              getCurrentIndex() === index ? css.current : ''
            }`}
          />
        ))}
      </FlexContainer>
      <div
        onClick={() => hasNextPage && setPage(page + 1)}
        className={`${css.button} ${!hasNextPage ? css.disabled : ''}`}
      >
        <FaArrowRight size={iconSize} />
      </div>
    </div>
  );
};

import * as React from 'react';
import { useEffect, useReducer } from 'react';

import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Button } from '../Buttons/Button';
import css from './podcastFooterButton.module.css';

export type FooterButtonProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  onClickFunction: (...args: any) => void;
};

export const PodcastFooterButton = ({
  isLoading = false,
  isDisabled = false,
  onClickFunction,
}: FooterButtonProps) => {
  const { deleteUnusedClips } = useAutoCutStore(
    state => state.ui.parameters.podcast
  );

  const buttonTextId = `podcast_button_${
    deleteUnusedClips ? 'delete' : 'disable'
  }`;

  return (
    <div id="podcast-step9" className={css.container}>
      <Button
        isLoading={isLoading}
        disabled={isDisabled}
        onClickFunction={onClickFunction}
      >
        <TranslatedMessage id={buttonTextId} defaultMessage="Podcast" />
      </Button>
    </div>
  );
};

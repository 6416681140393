import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React, { useEffect } from 'react';
import { GiResize } from 'react-icons/gi';

import css from './WindowTooSmallModal.module.scss';
import {
  useWindowSize,
  WINDOW_MIN_HEIGHT,
  WINDOW_MIN_WIDTH,
} from '@autocut/context/WindowSizeContext';
import { closeModal } from '@autocut/utils/modal/closeModal';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export const WindowTooSmallModal = () => {
  const { isTooSmall, windowSize } = useWindowSize();

  useEffect(() => {
    if (!isTooSmall) {
      closeModal();
    }
  }, [isTooSmall]);

  return (
    <Modal
      icon={<GiResize strokeWidth={2} size="1.5rem" />}
      title={
        <p className={css.modalTitle}>
          <TranslatedMessage
            id="window_too_small_modal_title"
            defaultMessage="Increase AutoCut window size"
          />
        </p>
      }
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <FlexContainer flexDirection="column">
        <p>
          <TranslatedMessage
            id="window_too_small_modal_text1"
            defaultMessage="AutoCut does not work on windows smaller than {width}px/{height}px."
            values={{
              width: WINDOW_MIN_WIDTH,
              height: WINDOW_MIN_HEIGHT,
            }}
          />
        </p>
        <p>
          <TranslatedMessage
            id="window_too_small_modal_text2"
            defaultMessage="Your window is currently {currentWidth}px/{currentHeight}px."
            values={{
              currentWidth: windowSize.width,
              currentHeight: windowSize.height,
            }}
          />
        </p>
        <br />
        <p>
          <TranslatedMessage
            id="window_too_small_modal_text3"
            defaultMessage="If the problem persists, please contact us on discord or at {supportEmail}."
            values={{
              supportEmail: 'contact@autocut.fr',
            }}
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};

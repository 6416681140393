import { csi, evalTS } from '@autocut/lib/utils/bolt';
import { Locale } from '../../../jsx/ppro/const';
import { getClipIndexesToDelete } from '../cut/handleDelete/getClipIndexesToDelete.utils';
import { autocutStoreVanilla } from '../zustand';

export const applyVolumeFilter = async (clipsTimecodes: number[][]) => {
  const autocutState = autocutStoreVanilla();

  if (
    !autocutState.sequence.infos ||
    !autocutState.sequence.infos.audioTracks.length
  ) {
    throw new Error('No clip selected');
  }

  const hostEnv = csi.hostEnvironment;

  const tracksWithClipsToReduceVolumes =
    autocutState.sequence.infos.audioTracks.map(track => ({
      index: track.index,
      clipIndexesToReduceVolumes: getClipIndexesToDelete(track, clipsTimecodes),
    }));

  for (const track of tracksWithClipsToReduceVolumes) {
    await evalTS(
      'reduceVolumeOfClips',
      track.index,
      track.clipIndexesToReduceVolumes,
      hostEnv.appUILocale as Locale,
      0.005
    );
  }
};

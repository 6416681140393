import logLevel from '@autocut/types/logLevel.enum';
import { logger } from '../logger';
import { setAutocutStore } from '../zustand';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleNoSilenceEnd', level, message, objects);
};

export const handleNoSilenceEnd = async () => {
  logMessage(logLevel.notice, 'End of cutting process without cutting.');
  setAutocutStore('ui.process.isProcessing', false);
};

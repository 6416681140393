import logLevel from '@autocut/types/logLevel.enum';
import { StatType } from '@autocut/types/StatType.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { logger } from './logger';
import { July, Trait, generateEntityName, Value } from '@kezios/july-sdk';
import { CURRENT_ENV } from './currentEnv.utils';
import { autocutStoreVanilla } from './zustand';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';

export type Traits = Record<string, string | number | Date>;

export const getTransitionGroupId = (transition: string) => {
  switch (transition) {
    case 'j_cut':
      return 'transition_j_cut';
    case 'l_cut':
      return 'transition_l_cut';
    case 'both':
      return 'transition_both';
    default:
      return 'transition_none';
  }
};

export interface StatisticDto {
  value: number | string | Date;
  type: StatType;
  traits?: Partial<Trait> | undefined;
}

export const july = new July(
  AUTOCUT_CONSTANTS[CURRENT_ENV].JULY_API_KEY || '',
  AUTOCUT_CONSTANTS[CURRENT_ENV].JULY_ADDRESS
);

export const createOrUpdateUser = async (traits?: Trait) => {
  const store = autocutStoreVanilla();
  const key = store.user.key;
  const scoreRef = store.user.additionalInformations?.scoreRef || undefined;

  if (!key) return;

  const userName = generateEntityName(
    key,
    AUTOCUT_CONSTANTS[CURRENT_ENV].JULY_SECRET_KEY,
    scoreRef
  );

  await july.user(userName, traits);
};

export const sendStats = async (stat: StatisticDto) => {
  if (stat === undefined) {
    return;
  }

  const traitWithoutUndefined: Trait = {};
  for (const key in stat.traits) {
    if (stat.traits[key]) {
      traitWithoutUndefined[key] = stat.traits[key] as Value;
    }
  }

  try {
    logger('statsUtils', logLevel.notice, 'Sending stat.', { stat });
    return await july.event(stat.type, stat.value, traitWithoutUndefined);
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'sendStats'),
      additionalData: stat,
      disableModal: true,
    });
  }

  return undefined;
};

/***
 * Pour deux tableaux d'intervalles de silences, renvoie leur intersection.
 * @example
 * // return [[2, 3], [4, 4], [5, 8]]
 * getTimelineBasedSilencesIntersections([[1, 3], [4, 11]], [[2, 4], [5, 8], [12, 15]])
 * @param {number[][]} silences1 - Le premier tableau d'intervalles de silences.
 * @param {number[][]} silences2 - Le second tableau d'intervalles de silences.
 * @returns {number[] | []}
 */
export function getIntersections(
  arr1: number[][],
  arr2: number[][]
): number[][] {
  const result: number[][] = [];

  // Iterate through each pair in the first array
  for (let i = 0; i < arr1.length; i++) {
    // Iterate through each pair in the second array
    for (let j = 0; j < arr2.length; j++) {
      // Find the start and end of the intersection, if it exists
      const start = Math.max(arr1[i][0], arr2[j][0]);
      const end = Math.min(arr1[i][1], arr2[j][1]);

      // If these form a valid interval, add it to the results
      if (start < end) {
        result.push([start, end]);
      }
    }
  }

  return result;
}

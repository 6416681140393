export enum FontSize {
  displayXs = 'var(--font-size-displayXs)',
  displaySm = 'var(--font-size-displaySm)',
  displayMd = 'var(--font-size-displayMd)',
  displayLg = 'var(--font-size-displayLg)',
  displayXl = 'var(--font-size-displayXl)',
  textXs = 'var(--font-size-textXs)',
  textSm = 'var(--font-size-textSm)',
  textMd = 'var(--font-size-textMd)',
  textLg = 'var(--font-size-textLg)',
  textXl = 'var(--font-size-textXl)',
}

import { XMLDocument } from './XMLDocument';

export class XMLVideoMediaSource {
  public objectID: string;
  private element: Element;
  public relativePath: Element;
  private filePath: Element;
  private actualMediaFilePath: Element;
  public title: string;
  public videoStream: Element;
  public document: XMLDocument;
  public metadataId: string;
  public media: Element;

  constructor({
    element,
    document,
    media,
    videoStream,
  }: {
    element: Element;
    document: XMLDocument;
    media?: Element;
    videoStream?: Element;
  }) {
    this.objectID = element.getAttribute('ObjectID') ?? '';
    this.element = element;
    this.document = document;

    this.media = media ?? this.getMedia()!;

    this.title = this.media.querySelector('Title')?.textContent ?? '';
    this.relativePath = this.media.querySelector('RelativePath')!;
    this.filePath = this.media.querySelector('FilePath')!;
    this.actualMediaFilePath = this.media.querySelector('ActualMediaFilePath')!;
    this.metadataId =
      this.media.querySelector('ContentAndMetadataState')?.textContent ?? '';

    this.videoStream = videoStream ?? this.getVideoStream()!;
  }

  public changeFilePath(filePath: string) {
    if (this.filePath?.textContent) this.filePath.textContent = filePath;
    if (this.actualMediaFilePath?.textContent)
      this.actualMediaFilePath.textContent = filePath;
  }

  public clone({
    newName,
    newPath,
    newRelativePath,
    newObjectUID,
  }: {
    newName: string;
    newPath: string;
    newRelativePath: string;
    newObjectUID?: string;
  }) {
    const newVideoMediaSource = this.element.cloneNodeWithNewId(
      newObjectUID ?? this.document.getNewObjectId()
    );
    const newMediaId = this.document.getNewObjectUID();
    const newMediaRef = newVideoMediaSource.querySelector('Media')!;
    newMediaRef.setAttribute('ObjectURef', newMediaId);
    const newMedia = this.media.cloneNodeWithNewId(newMediaId);

    const newVideoStreamId = this.document.getNewObjectId();
    const newVideoStreamRef = newMedia.querySelector('VideoStream')!;
    newVideoStreamRef.setAttribute('ObjectRef', newVideoStreamId);
    const newVideoStream =
      this.videoStream.cloneNodeWithNewId(newVideoStreamId);

    const newMetadataId = this.document.getNewObjectUID();
    newMedia.querySelector('ContentAndMetadataState')!.textContent =
      newMetadataId;
    const encodedMetadataId = Buffer.from(newMetadataId, 'utf16le').toString(
      'base64'
    );
    newMedia.querySelector('ModificationState')!.textContent =
      encodedMetadataId;

    newMedia.querySelector('FileKey')!.textContent =
      this.document.getNewObjectUID();
    newMedia.querySelector('ImplementationID')!.textContent =
      this.document.getNewObjectUID();

    newMedia.querySelector('Title')!.textContent = newName;
    newMedia.querySelector('RelativePath')!.textContent = newRelativePath;
    newMedia.querySelector('FilePath')!.textContent = newPath;
    newMedia.querySelector('ActualMediaFilePath')!.textContent = newPath;

    return new XMLVideoMediaSource({
      element: newVideoMediaSource,
      document: this.document,
      media: newMedia,
      videoStream: newVideoStream,
    });
  }

  getMedia = () => {
    const mediaRef = this.element.querySelector('Media')!;
    const mediaUID = mediaRef?.getAttribute('ObjectURef') ?? '';
    return this.document.findElementWithObjectId(['Media'], mediaUID);
  };

  getVideoStream() {
    const videoStreamRef = this.media.querySelector('VideoStream')!;
    const videoStreamId = videoStreamRef?.getAttribute('ObjectRef') ?? '';
    return this.document.findElementWithObjectId(
      ['VideoStream'],
      videoStreamId
    );
  }
}

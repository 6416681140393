import { IncrementalError } from '../errors/IncrementalError';

const getClusters = (values: number[], centroids: number[]): number[][] => {
  let distances, minDistance, minDistanceIndex;
  try {
    const clusters: number[][] = Array.from(
      { length: centroids.length },
      () => []
    );

    for (const value of values) {
      distances = [
        Math.abs(value - centroids[0]),
        Math.abs(value - centroids[1]),
      ];
      minDistance = Math.min(...distances);
      minDistanceIndex = distances.indexOf(minDistance);

      clusters[minDistanceIndex].push(value);
    }

    return clusters;
  } catch (err: any) {
    throw new IncrementalError(err, `getClusters`);
  }
};

const getCentroids = (clusters: number[][]): number[] => {
  const centroids: number[] = [];

  for (const cluster of clusters) {
    let sum = 0;
    for (const value of cluster) {
      sum += value;
    }
    centroids.push(sum / cluster.length);
  }

  return centroids;
};

export const oneDKMean = (values: number[], maxIterations = 50): number[][] => {
  try {
    let centroids = [Math.min(...values), Math.max(...values)];
    if (centroids[0] === centroids[1]) return [values, []]; // If min === max then audio is probably all silence and should be treated as such (<=> this case correspond to an user error)

    let clusters: number[][] = [];
    let found = false;
    let iterations = 0;

    while (!found && iterations++ < maxIterations) {
      clusters = getClusters(values, centroids);
      const newCentroids = getCentroids(clusters);
      found = newCentroids.every(
        (centroid, index) =>
          Math.abs((centroids[index] - centroid) / centroids[index]) < 0.0001
      );
      centroids = newCentroids;
    }

    return clusters;
  } catch (err: any) {
    throw new IncrementalError(err, 'oneDKMean');
  }
};

import { Channel } from './Channel';

export const ChannelLayout = {
  mono: [Channel.FC],
  stereo: [Channel.FL, Channel.FR],
  '2.1': [Channel.FL, Channel.FR, Channel.LFE],
  '3.0': [Channel.FL, Channel.FR, Channel.FC],
  '3.0(back)': [Channel.FL, Channel.FR, Channel.BC],
  '4.0': [Channel.FL, Channel.FR, Channel.FC, Channel.BC],
  quad: [Channel.FL, Channel.FR, Channel.BL, Channel.BR],
  'quad(side)': [Channel.FL, Channel.FR, Channel.SL, Channel.SR],
  '3.1': [Channel.FL, Channel.FR, Channel.FC, Channel.LFE],
  '5.0': [Channel.FL, Channel.FR, Channel.FC, Channel.BL, Channel.BR],
  '5.0(side)': [Channel.FL, Channel.FR, Channel.FC, Channel.SL, Channel.SR],
  '4.1': [Channel.FL, Channel.FR, Channel.FC, Channel.LFE, Channel.BC],
  '5.1': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BL,
    Channel.BR,
  ],
  '5.1(side)': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.SL,
    Channel.SR,
  ],
  '6.0': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.BC,
    Channel.SL,
    Channel.SR,
  ],
  '6.0(front)': [
    Channel.FL,
    Channel.FR,
    Channel.FLC,
    Channel.FRC,
    Channel.SL,
    Channel.SR,
  ],
  hexagonal: [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.BL,
    Channel.BR,
    Channel.BC,
  ],
  '6.1': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BC,
    Channel.SL,
    Channel.SR,
  ],
  '6.1(back)': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BL,
    Channel.BR,
    Channel.BC,
  ],
  '6.1(front)': [
    Channel.FL,
    Channel.FR,
    Channel.LFE,
    Channel.FLC,
    Channel.FRC,
    Channel.SL,
    Channel.SR,
  ],
  '7.0': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.BL,
    Channel.BR,
    Channel.SL,
    Channel.SR,
  ],
  '7.0(front)': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.FLC,
    Channel.FRC,
    Channel.SL,
    Channel.SR,
  ],
  '7.1': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BL,
    Channel.BR,
    Channel.SL,
    Channel.SR,
  ],
  '7.1(wide)': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BL,
    Channel.BR,
    Channel.FLC,
    Channel.FRC,
  ],
  '7.1(wide-side)': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.FLC,
    Channel.FRC,
    Channel.SL,
    Channel.SR,
  ],
  '7.1(top)': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BL,
    Channel.BR,
    Channel.TFL,
    Channel.TFR,
  ],
  octagonal: [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.BL,
    Channel.BR,
    Channel.BC,
    Channel.SL,
    Channel.SR,
  ],
  cube: [
    Channel.FL,
    Channel.FR,
    Channel.BL,
    Channel.BR,
    Channel.TFL,
    Channel.TFR,
    Channel.TBL,
    Channel.TBR,
  ],
  hexadecagonal: [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.BL,
    Channel.BR,
    Channel.BC,
    Channel.SL,
    Channel.SR,
    Channel.TFL,
    Channel.TFC,
    Channel.TFR,
    Channel.TBL,
    Channel.TBC,
    Channel.TBR,
    Channel.WL,
    Channel.WR,
  ],
  downmix: [Channel.DL, Channel.DR],
  '22.2': [
    Channel.FL,
    Channel.FR,
    Channel.FC,
    Channel.LFE,
    Channel.BL,
    Channel.BR,
    Channel.FLC,
    Channel.FRC,
    Channel.BC,
    Channel.SL,
    Channel.SR,
    Channel.TC,
    Channel.TFL,
    Channel.TFC,
    Channel.TFR,
    Channel.TBL,
    Channel.TBC,
    Channel.TBR,
    Channel.LFE2,
    Channel.TSL,
    Channel.TSR,
    Channel.BFC,
    Channel.BFL,
    Channel.BFR,
  ],
};

export type ChannelLayoutType = keyof typeof ChannelLayout;

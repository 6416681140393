import * as React from 'react';

import { PiWarningCircleBold } from 'react-icons/pi';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import {
  IdentifiedErrorKeys,
  IdentifiedErrorType,
} from '@autocut/enums/errors.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { path } from '@autocut/lib/cep/node';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { getErrorInfosById } from '@autocut/utils';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import './ErrorModal.css';

const ErrorModal = () => {
  const intl = useIntl();

  const { errorId, unsupportedFileName } = useAutoCutStore(state => ({
    errorId: state.ui.currentErrorId,
    unsupportedFileName: state.sequence.errors.unsupportedFileName,
  }));
  const { intlId, link } = getErrorInfosById(
    errorId as IdentifiedErrorKeys
  ) as IdentifiedErrorType;

  // If a fixable error pop up again, change this
  const isFixable = false;

  const linkTextID = isFixable
    ? 'link_not_supported_modal'
    : 'link_not_supported_modal_no_solution';
  const linkTextDefaultMessage = `To better understand this error${
    isFixable ? 'and how to fix it' : ''
  }, go to `;

  return (
    <Modal
      title={intl.formatMessage({
        id: `error_modal_title_${intlId}`,
        defaultMessage: 'An error has occured.',
      })}
      icon={<PiWarningCircleBold fill="red" size="24" />}
    >
      <FlexContainer justifyContent="space-between">
        <FlexContainer flexDirection="column" alignItems="flex-start">
          <p className="error-modal-text">
            <TranslatedMessage
              id={`error_modal_text_${intlId}`}
              defaultMessage={'An error has occured.'}
              values={
                unsupportedFileName
                  ? {
                      filename: path.basename(unsupportedFileName),
                      filepath: unsupportedFileName,
                    }
                  : {}
              }
            />
            <br />
            <TranslatedMessage
              id={`error_modal_tips_${intlId}`}
              defaultMessage={'Try to change your selection or your settings.'}
            />
          </p>
          {link && (
            <p>
              <TranslatedMessage
                id={linkTextID}
                defaultMessage={linkTextDefaultMessage}
              />
              <a
                className={'tuto_link'}
                onClick={() => {
                  openLinkInBrowser(
                    AUTOCUT_CONSTANTS[CURRENT_ENV].ACADEMY_ADDRESS[
                      intl.locale.toUpperCase() as 'FR' | 'EN'
                    ] + link
                  );
                }}
              >
                AutoCut Academy
              </a>
              <TranslatedMessage
                id={'link_not_supported_modal_end'}
                defaultMessage={' our documentation and tutorials site'}
              />
            </p>
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export default ErrorModal;

import { Speaker } from '@autocut/types/Speaker';
import { IncrementalError } from '../errors/IncrementalError';

export const getAudioOffsets = (
  speakers: (Speaker & {
    clips: Clip[];
  })[]
) => {
  try {
    let audioOffsetStart = 999999;
    let audioOffsetEnd = 0;

    for (const speaker of speakers) {
      if (speaker.clips[0].start < audioOffsetStart) {
        audioOffsetStart = speaker.clips[0].start;
      }
      if (speaker.clips[speaker.clips.length - 1].end > audioOffsetEnd) {
        audioOffsetEnd = speaker.clips[speaker.clips.length - 1].end;
      }
    }

    return [audioOffsetStart, audioOffsetEnd];
  } catch (err: any) {
    throw new IncrementalError(err, 'getAudioOffsets');
  }
};

import { RMS_INTERVAL_DURATION } from './computeRMS';
import { AudioWavData } from './getAudioClipsWavData';

export type ZoomInterval = {
  start: number;
  end: number;
  fromZoomAnchor: { xPercentage: number; yPercentage: number };
  toZoomAnchor: { xPercentage: number; yPercentage: number };
  rmsArray: number[];
  fromZoomCoef: number;
  toZoomCoef: number;
  isSmooth: boolean;
};

export const getZoomIntervals = async (
  audioClipsData: AudioWavData[],
  aggressivity: number,
  isSmooth: boolean,
  maxZoomTime: number,
  minZoomTime: number
) => {
  const zoomIntervals: ZoomInterval[] = [];

  for (const audioClipData of audioClipsData) {
    let startMarker: number | undefined = undefined;
    let endMarker: number | undefined = undefined;
    let refreshCounter = 0;
    let maxZoomTimeReached = false;
    const refreshTreshold = 1 / RMS_INTERVAL_DURATION; // Xs / RMS_INTERVAL_DURATION, avec X la durée en seconde du temps de rafraichissement

    const saveInterval = () => {
      const start = (startMarker as number) + audioClipData.startTimeline;
      const end =
        (endMarker as number) ===
        audioClipData.rmsValues.length * RMS_INTERVAL_DURATION
          ? audioClipData.endTimeline
          : (endMarker as number) + audioClipData.startTimeline;

      zoomIntervals.push({
        start,
        end,
        fromZoomCoef: 0,
        toZoomCoef: 0,
        toZoomAnchor: {
          xPercentage: 0,
          yPercentage: 0,
        },
        fromZoomAnchor: {
          xPercentage: 0,
          yPercentage: 0,
        },
        rmsArray: audioClipData.rmsValues.slice(
          (startMarker as number) / RMS_INTERVAL_DURATION,
          (endMarker as number) / RMS_INTERVAL_DURATION + 1
        ),
        isSmooth,
      });
      startMarker = undefined;
      endMarker = undefined;
    };

    for (const [rmsIndex, rmsValue] of audioClipData.rmsValues.entries()) {
      // Si le dernier intervalle a été fermé car durée max atteinte, alors
      // on attend une nouvelle portion calme avant de rouvrir un intervalle
      if (maxZoomTimeReached) {
        if (rmsValue > aggressivity) {
          refreshCounter = 0;
        } else {
          refreshCounter++;
          if (refreshCounter > refreshTreshold) {
            maxZoomTimeReached = false;
            refreshCounter = 0;
          }
        }
      } else {
        // On est sur une portion bruyante
        if (rmsValue > aggressivity) {
          // On ouvre l'intervalle si il est nouveau
          if (!startMarker) startMarker = rmsIndex * RMS_INTERVAL_DURATION;
          // et on maintient à jour le point de sortie
          endMarker = rmsIndex * RMS_INTERVAL_DURATION;
          refreshCounter = 0;

          // Si l'intervalle courant devient trop grand
          // alors on l'enregistre, on réinitialise les marqueurs
          // et on met en pause le calcul d'intervalle le temps de
          // retrouver une portion calme (maxZoomTimeReached = true;)
          if (
            startMarker &&
            endMarker &&
            endMarker - startMarker > maxZoomTime
          ) {
            saveInterval();
            maxZoomTimeReached = true;
          }
        } else {
          if (startMarker) refreshCounter++;

          // Si nous sommes sur une portion calme depuis trop longtemps
          // et que l'intervalle courant est assez grand, alors on l'enregistre
          // et on réinitialise les marqueurs
          if (refreshCounter > refreshTreshold && startMarker && endMarker) {
            if (endMarker - startMarker > minZoomTime) {
              saveInterval();
            }
            // Sinon, on ignore l'intervalle
            else {
              startMarker = undefined;
              endMarker = undefined;
              refreshCounter = 0;
            }
          }
        }
      }
    }

    if (startMarker && endMarker && endMarker - startMarker > minZoomTime) {
      saveInterval();
    }
  }

  return zoomIntervals;
};

import { resourcesConfigs } from '@autocut/enums/resources.enum';
import { evalTS } from '@autocut/lib/utils/bolt';
import { waitForResource } from '../resourceManager.utils';
import { autocutStoreVanilla } from '../zustand';
import { BleepFileEnum } from './bleepFile.enum';

export const insertBleepClips = async (
  timecodes: number[][],
  bleepFile: Exclude<BleepFileEnum, BleepFileEnum.Muted>
) => {
  await waitForResource(
    resourcesConfigs[bleepFile as 'bleep_sound_1_wav' | 'bleep_sound_2_wav']
  );

  const bleepSoundPath = autocutStoreVanilla().resources[bleepFile]
    .filePath as string;

  await evalTS('insertCensorBleeps', bleepSoundPath, timecodes);
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { splitArrayInChunks } from '@autocut/utils/array.utils';
import {
  CEPProcess,
  CEPProcessManager,
} from '@autocut/utils/cepProcessus/CEPProcessManager';
import { runPromiseWithFallback } from '@autocut/utils/errors/errors.util';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { autocutStoreVanilla, setAutocutStore } from '@autocut/utils/zustand';
import { ClipTypeEnum } from '../../../jsx/ppro/types.ppro';
import { sleep } from '../time.utils';

type SelectionParsingProgress = {
  nbClipTotal: number;
  nbClipParsed: number;
  lastProgressEvent: number;
  lastProgressEventTimestamp: number;
};

const PROGRESS_EVENT_RATE = 500; // 0.5 seconds

const handleClipsParsed = (
  selectionParsingProgress: SelectionParsingProgress
) => {
  const nbClipTotal = selectionParsingProgress.nbClipTotal;
  const nbClipParsed = selectionParsingProgress.nbClipParsed;
  const lastProgressEvent = selectionParsingProgress.lastProgressEvent;

  const percentage = 10 + ~~((80 * nbClipParsed) / nbClipTotal);
  if (
    percentage > lastProgressEvent &&
    Date.now() >
      selectionParsingProgress.lastProgressEventTimestamp + PROGRESS_EVENT_RATE
  ) {
    const result = { percentage, current: nbClipParsed, max: nbClipTotal };
    selectionParsingProgress.lastProgressEvent = percentage;
    selectionParsingProgress.lastProgressEventTimestamp = Date.now();
    setAutocutStore('sequence.parsingProcess.progress', result);
  }
};

const getTracks = async (
  setProgress: (code: string) => void,
  trackType: ClipTypeEnum,
  selectionParsingProgress: SelectionParsingProgress,
  tracks: {
    selectedClipsIndexes: number[];
    nbClip: number;
    nbClipSelected: number;
  }[]
) => {
  const tracksInfo = [];
  let nbClips = 0;

  for (let trackIndex = 0; trackIndex < tracks.length; trackIndex++) {
    setProgress('A1');
    const { id: trackId } = await runPromiseWithFallback<{ id: string }>(
      () => evalTS('getTrack', trackIndex, trackType),
      { id: 'Audio 1' }
    );
    const clipsInfos = [];
    const clipsIndexes = splitArrayInChunks(
      tracks[trackIndex].selectedClipsIndexes,
      10
    );
    setProgress('A2');
    for (const chunk of clipsIndexes) {
      setProgress(`A3 - ${chunk[0]} - ${chunk[chunk.length - 1]}`);
      const clipsInfosChunk = await evalTS(
        'getClipsByTrack',
        trackIndex,
        trackType,
        chunk
      );
      clipsInfos.push(...clipsInfosChunk);
      selectionParsingProgress.nbClipParsed += chunk.length;
      handleClipsParsed(selectionParsingProgress);
    }
    setProgress('A4');
    const start = clipsInfos.length >= 1 ? clipsInfos[0].start : 0;
    setProgress('A5');
    const end =
      clipsInfos.length >= 1 ? clipsInfos[clipsInfos.length - 1].end : 0;
    setProgress('A6');

    nbClips += clipsInfos.length;

    const trackInfo = {
      id: trackId,
      type: trackType,
      index: trackIndex,
      selectedClips: clipsInfos,
      nbClips: tracks[trackIndex].nbClip,
      nbClipsSelected: tracks[trackIndex].nbClipSelected,
      start,
      end,
      isUsed: true,
    };

    tracksInfo.push(trackInfo);
    setProgress('A7');
  }

  setProgress('A8');
  return { tracksInfo, nbClips };
};

export const getSequenceInfos = async function (this: CEPProcess) {
  const selectionParsingProgress: SelectionParsingProgress = {
    lastProgressEvent: 0,
    lastProgressEventTimestamp: 0,
    nbClipParsed: 0,
    nbClipTotal: 0,
  };
  const isProcessing = autocutStoreVanilla().ui.process.isProcessing;
  if (isProcessing) {
    return undefined;
  }

  let errorCodeGetSequenceInfos = '';
  const setProgress = (code: string) => {
    errorCodeGetSequenceInfos = code;
    CEPProcessManager.check(this);
  };

  try {
    setProgress('0');
    await sleep(100); // wait for other event to avoid processing getActiveSequence for nothing
    setProgress('1');
    const activeSequence = await evalTS('getActiveSequence');
    setProgress('2');

    if (!activeSequence) return {};

    ///CEP
    setProgress('3');
    setAutocutStore('sequence.parsingProcess.progress', {
      current: 5,
      max: 100,
      percentage: 5,
    });
    const { nbClipSelected, tracks } = await evalTS('getSequenceNumberOfClips');
    selectionParsingProgress.nbClipTotal = nbClipSelected;

    setProgress('3.5');
    const rawSequenceSettings = await evalTS('getSequenceSettings');

    setProgress('4');
    const timebase = parseFloat(activeSequence.timebase);

    setProgress('4.1');
    const sequenceSettings = {
      name: activeSequence.name,
      projectItem: activeSequence.projectItem,
      frameRate: 1 / rawSequenceSettings.videoFrameRate.seconds,
      videoDisplayFormat: activeSequence.videoDisplayFormat,
      width: activeSequence.frameSizeHorizontal,
      height: activeSequence.frameSizeVertical,
      timebase: timebase,
      audioChannelCount: rawSequenceSettings.audioChannelCount,
      audioTrackType: rawSequenceSettings.audioChannelType,
    };

    setProgress('4.2');
    setAutocutStore('sequence.lastSettings', sequenceSettings);

    //JS
    setProgress('5');
    const start =
      parseFloat(activeSequence.zeroPoint) *
      (1 / timebase) *
      rawSequenceSettings.videoFrameRate.seconds;
    setProgress('6');
    const end =
      parseFloat(activeSequence.end) *
      (1 / timebase) *
      rawSequenceSettings.videoFrameRate.seconds;

    setProgress('6.5');
    const [sequenceInPoint, sequenceOutPoint] = await evalTS(
      'getSequenceInOutPoints'
    );

    setProgress('7');
    const sequenceInfos: Sequence = {
      id: activeSequence.sequenceID,
      videoTracks: [],
      audioTracks: [],
      nbClipsAudio: 0,
      nbClipsVideo: 0,
      timebase: timebase.toString(),
      start: start,
      end: end,
      inPoint: sequenceInPoint,
      outPoint: sequenceOutPoint,
      settings: sequenceSettings,
    };

    setProgress('8');

    const { nbClips: nbClipsVideo, tracksInfo: videoTracksInfo } =
      await getTracks(
        setProgress,
        ClipTypeEnum.Video,
        selectionParsingProgress,
        tracks.videoTracks
      );
    const { nbClips: nbClipsAudio, tracksInfo: audioTracksInfo } =
      await getTracks(
        setProgress,
        ClipTypeEnum.Audio,
        selectionParsingProgress,
        tracks.audioTracks
      );

    setProgress('9');

    setProgress('10');
    sequenceInfos.videoTracks = videoTracksInfo as Track[];
    setProgress('11');
    sequenceInfos.audioTracks = audioTracksInfo as Track[];

    setProgress('12');
    sequenceInfos.nbClipsVideo = nbClipsVideo;
    setProgress('13');
    sequenceInfos.nbClipsAudio = nbClipsAudio;
    setProgress('14');

    return sequenceInfos;
  } catch (error: any) {
    throw new IncrementalError(
      error,
      `getSequenceInfos - ${errorCodeGetSequenceInfos}`
    );
  }
};
getSequenceInfos.type = 'getSequenceInfos';

import { InputRangeParameter } from '@autocut/components/atoms/Parameters/InputRangeParamater/InputRangeParameter';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const ZoomCoverageStep = () => {
  const { totalZoomPercentage } = useAutoCutStore(
    state => state.ui.parameters.zoom
  );

  return (
    <ParameterLayout
      title={
        <p style={{ color: 'white' }}>
          <FormattedMessage
            id="autozoom_zoom_coverage"
            defaultMessage="Total zoom coverage"
          />
        </p>
      }
      grow
    >
      <InputRangeParameter
        value={totalZoomPercentage}
        step={0.01}
        min={0}
        max={1}
        unit="%"
        unitFormatting={value => `${(value * 100).toFixed()}`}
        onChange={value =>
          setAutocutStore(
            'ui.parameters.zoom.totalZoomPercentage',
            parseFloat(value)
          )
        }
      />
    </ParameterLayout>
  );
};

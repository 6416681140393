import { Locale } from '@autocut/context/LocaleContext';
import { Font } from '@autocut/types/font';
import { IntlShape } from 'react-intl';

const ARABIC_MAX_WORDS_PER_CHUNK = 15;

export const getLanguages = (intl: IntlShape) =>
  [
    {
      label: intl.formatMessage({
        id: 'language_selection_french',
        defaultMessage: 'French',
      }),
      value: 'fr',
      modelOrder: 0,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_english',
        defaultMessage: 'English',
      }),
      value: 'en',
      modelOrder: 0,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_english_united_states',
        defaultMessage: 'English - United States',
      }),
      value: 'en-US',
      modelOrder: 0,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_algeria',
        defaultMessage: 'Arabic - Algeria',
      }),
      value: 'ar-DZ',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_bahrain',
        defaultMessage: 'Arabic - Bahrain',
      }),
      value: 'ar-BH',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_egypt',
        defaultMessage: 'Arabic - Egypt',
      }),
      value: 'ar-EG',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_iraq',
        defaultMessage: 'Arabic - Iraq',
      }),
      value: 'ar-IQ',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_israel',
        defaultMessage: 'Arabic - Israel',
      }),
      value: 'ar-IL',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_jordan',
        defaultMessage: 'Arabic - Jordan',
      }),
      value: 'ar-JO',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_kuwait',
        defaultMessage: 'Arabic - Kuwait',
      }),
      value: 'ar-KW',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_lebanon',
        defaultMessage: 'Arabic - Lebanon',
      }),
      value: 'ar-LB',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_mauritania',
        defaultMessage: 'Arabic - Mauritania',
      }),
      value: 'ar-MR',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_morocco',
        defaultMessage: 'Arabic - Morocco',
      }),
      value: 'ar-MA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_oman',
        defaultMessage: 'Arabic - Oman',
      }),
      value: 'ar-OM',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_qatar',
        defaultMessage: 'Arabic - Qatar',
      }),
      value: 'ar-QA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_saudi_arabia',
        defaultMessage: 'Arabic - Saudi Arabia',
      }),
      value: 'ar-SA',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_palestine',
        defaultMessage: 'Arabic - Palestine',
      }),
      value: 'ar-PS',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_tunisia',
        defaultMessage: 'Arabic - Tunisia',
      }),
      value: 'ar-TN',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_united_arab_emirates',
        defaultMessage: 'Arabic - United Arab Emirates',
      }),
      value: 'ar-AE',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_arabic_yemen',
        defaultMessage: 'Arabic - Yemen',
      }),
      value: 'ar-YE',
      textDirection: 'rtl',
      modelOrder: 2,
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsArabic, showWarning: true },
        chapters: { disabled: true, showWarning: false },
        viralClips: { disabled: true, showWarning: false },
      }),
      maxWordsPerChunk: ARABIC_MAX_WORDS_PER_CHUNK,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_german',
        defaultMessage: 'German',
      }),
      value: 'de',
      modelOrder: 1,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_hindi',
        defaultMessage: 'Hindi',
      }),
      value: 'hi',
      modelOrder: 1,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_portuguese',
        defaultMessage: 'language_selection_portuguese',
      }),
      value: 'pt',
      modelOrder: 1,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_portuguese_brazil',
        defaultMessage: 'Portuguese - Brazil',
      }),
      value: 'pt-BR',
      modelOrder: 1,
    },

    {
      label: intl.formatMessage({
        id: 'language_selection_spanish',
        defaultMessage: 'Spanish',
      }),
      value: 'es',
      modelOrder: 1,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_spanish_latin_america',
        defaultMessage: 'Spanish - Latin America',
      }),
      value: 'es-419',
      modelOrder: 1,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_danish',
        defaultMessage: 'Danish',
      }),
      value: 'da',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_dutch',
        defaultMessage: 'Dutch',
      }),
      value: 'nl',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_flemish',
        defaultMessage: 'Flemish',
      }),
      value: 'nl',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_italian',
        defaultMessage: 'Italian',
      }),
      value: 'it',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_japanese',
        defaultMessage: 'Japanese',
      }),
      value: 'ja',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_korean',
        defaultMessage: 'Korean',
      }),
      value: 'ko',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_norwegian',
        defaultMessage: 'Norwegian',
      }),
      value: 'no',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_polish',
        defaultMessage: 'Polish',
      }),
      value: 'pl',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_swedish',
        defaultMessage: 'Swedish',
      }),
      value: 'sv',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_tamil',
        defaultMessage: 'Tamil',
      }),
      value: 'ta',
      modelOrder: 2,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_chinese',
        defaultMessage: 'Chinese',
      }),
      value: 'zh',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_russian',
        defaultMessage: 'Russian',
      }),
      value: 'ru',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_turkish',
        defaultMessage: 'Turkish',
      }),
      value: 'tr',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_catalan',
        defaultMessage: 'Catalan',
      }),
      value: 'ca',
      modelOrder: 3,
    },
    // {
    //   label: intl.formatMessage({
    //     id: 'language_selection_arabic',
    //     defaultMessage: 'Arabic',
    //   }),
    //   value: 'ar',
    //   modelOrder: 3,
    // },
    {
      label: intl.formatMessage({
        id: 'language_selection_indonesian',
        defaultMessage: 'Indonesian',
      }),
      value: 'id',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_finnish',
        defaultMessage: 'Finnish',
      }),
      value: 'fi',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_vietnamese',
        defaultMessage: 'Vietnamese',
      }),
      value: 'vi',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_hebrew',
        defaultMessage: 'Hebrew',
      }),
      value: 'he',
      modelOrder: 3,
      textDirection: 'rtl',
      unsupportedFeatures: (font?: Font) => ({
        uppercase: {
          disabled: true,
          showWarning: false,
        },
        wordBox: { disabled: !font?.supportsHebrew, showWarning: true },
      }),
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_ukrainian',
        defaultMessage: 'Ukrainian',
      }),
      value: 'uk',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_greek',
        defaultMessage: 'Greek',
      }),
      value: 'el',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_malay',
        defaultMessage: 'Malay',
      }),
      value: 'ms',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_czech',
        defaultMessage: 'Czech',
      }),
      value: 'cs',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_romanian',
        defaultMessage: 'Romanian',
      }),
      value: 'ro',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_hungarian',
        defaultMessage: 'Hungarian',
      }),
      value: 'hu',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_thai',
        defaultMessage: 'Thai',
      }),
      value: 'th',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_urdu',
        defaultMessage: 'Urdu',
      }),
      value: 'ur',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_croatian',
        defaultMessage: 'Croatian',
      }),
      value: 'hr',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_bulgarian',
        defaultMessage: 'Bulgarian',
      }),
      value: 'bg',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_lithuanian',
        defaultMessage: 'Lithuanian',
      }),
      value: 'lt',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_maori',
        defaultMessage: 'Maori',
      }),
      value: 'mi',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_malayalam',
        defaultMessage: 'Malayalam',
      }),
      value: 'ml',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_welsh',
        defaultMessage: 'Welsh',
      }),
      value: 'cy',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_slovak',
        defaultMessage: 'Slovak',
      }),
      value: 'sk',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_telugu',
        defaultMessage: 'Telugu',
      }),
      value: 'te',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_persian',
        defaultMessage: 'Persian',
      }),
      value: 'fa',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_latvian',
        defaultMessage: 'Latvian',
      }),
      value: 'lv',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_bengali',
        defaultMessage: 'Bengali',
      }),
      value: 'bn',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_serbian',
        defaultMessage: 'Serbian',
      }),
      value: 'sr',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_azerbaijani',
        defaultMessage: 'Azerbaijani',
      }),
      value: 'az',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_slovenian',
        defaultMessage: 'Slovenian',
      }),
      value: 'sl',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_kannada',
        defaultMessage: 'Kannada',
      }),
      value: 'kn',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_estonian',
        defaultMessage: 'Estonian',
      }),
      value: 'et',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_macedonian',
        defaultMessage: 'Macedonian',
      }),
      value: 'mk',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_breton',
        defaultMessage: 'Breton',
      }),
      value: 'br',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_basque',
        defaultMessage: 'Basque',
      }),
      value: 'eu',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_icelandic',
        defaultMessage: 'Icelandic',
      }),
      value: 'is',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_armenian',
        defaultMessage: 'Armenian',
      }),
      value: 'hy',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_nepali',
        defaultMessage: 'Nepali',
      }),
      value: 'ne',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_mongolian',
        defaultMessage: 'Mongolian',
      }),
      value: 'mn',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_bosnian',
        defaultMessage: 'Bosnian',
      }),
      value: 'bs',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_kazakh',
        defaultMessage: 'Kazakh',
      }),
      value: 'kk',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_albanian',
        defaultMessage: 'Albanian',
      }),
      value: 'sq',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_swahili',
        defaultMessage: 'Swahili',
      }),
      value: 'sw',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_galician',
        defaultMessage: 'Galician',
      }),
      value: 'gl',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_marathi',
        defaultMessage: 'Marathi',
      }),
      value: 'mr',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_punjabi',
        defaultMessage: 'Punjabi',
      }),
      value: 'pa',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_sinhala',
        defaultMessage: 'Sinhala',
      }),
      value: 'si',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_khmer',
        defaultMessage: 'Khmer',
      }),
      value: 'km',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_shona',
        defaultMessage: 'Shona',
      }),
      value: 'sn',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_yoruba',
        defaultMessage: 'Yoruba',
      }),
      value: 'yo',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_somali',
        defaultMessage: 'Somali',
      }),
      value: 'so',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_afrikaans',
        defaultMessage: 'Afrikaans',
      }),
      value: 'af',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_occitan',
        defaultMessage: 'Occitan',
      }),
      value: 'oc',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_georgian',
        defaultMessage: 'Georgian',
      }),
      value: 'ka',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_belarusian',
        defaultMessage: 'Belarusian',
      }),
      value: 'be',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_tajik',
        defaultMessage: 'Tajik',
      }),
      value: 'tg',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_sindhi',
        defaultMessage: 'Sindhi',
      }),
      value: 'sd',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_gujarati',
        defaultMessage: 'Gujarati',
      }),
      value: 'gu',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_amharic',
        defaultMessage: 'Amharic',
      }),
      value: 'am',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_yiddish',
        defaultMessage: 'Yiddish',
      }),
      value: 'yi',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_lao',
        defaultMessage: 'Lao',
      }),
      value: 'lo',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_uzbek',
        defaultMessage: 'Uzbek',
      }),
      value: 'uz',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_faroese',
        defaultMessage: 'Faroese',
      }),
      value: 'fo',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_haitian_creole',
        defaultMessage: 'Haitian Creole',
      }),
      value: 'ht',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_pashto',
        defaultMessage: 'Pashto',
      }),
      value: 'ps',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_turkmen',
        defaultMessage: 'Turkmen',
      }),
      value: 'tk',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_norwegian_nynorsk',
        defaultMessage: 'Norwegian Nynorsk',
      }),
      value: 'nn',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_maltese',
        defaultMessage: 'Maltese',
      }),
      value: 'mt',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_sanskrit',
        defaultMessage: 'Sanskrit',
      }),
      value: 'sa',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_luxembourgish',
        defaultMessage: 'Luxembourgish',
      }),
      value: 'lb',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_burmese',
        defaultMessage: 'Burmese',
      }),
      value: 'my',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_tibetan',
        defaultMessage: 'Tibetan',
      }),
      value: 'bo',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_tagalog',
        defaultMessage: 'Tagalog',
      }),
      value: 'tl',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_malagasy',
        defaultMessage: 'Malagasy',
      }),
      value: 'mg',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_assamese',
        defaultMessage: 'Assamese',
      }),
      value: 'as',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_tatar',
        defaultMessage: 'Tatar',
      }),
      value: 'tt',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_hawaiian',
        defaultMessage: 'Hawaiian',
      }),
      value: 'haw',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_lingala',
        defaultMessage: 'Lingala',
      }),
      value: 'ln',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_hausa',
        defaultMessage: 'Hausa',
      }),
      value: 'ha',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_bashkir',
        defaultMessage: 'Bashkir',
      }),
      value: 'ba',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_javanese',
        defaultMessage: 'Javanese',
      }),
      value: 'jw',
      modelOrder: 3,
    },
    {
      label: intl.formatMessage({
        id: 'language_selection_sundanese',
        defaultMessage: 'Sundanese',
      }),
      value: 'su',
      modelOrder: 3,
    },
  ].sort((a, b) =>
    a.modelOrder > b.modelOrder
      ? 1
      : a.modelOrder < b.modelOrder
      ? -1
      : a.label.localeCompare(b.label)
  );

export const getPProLanguageCode = (language: Locale) => {
  switch (language) {
    case 'ES':
      return 'es_ES';
    case 'FR':
      return 'fr_FR';
    case 'JP':
      return 'ja_JP';
    case 'KR':
      return 'ko_KR';
    case 'PT':
      return 'pt_BR';
    default:
      return 'en_US';
  }
};

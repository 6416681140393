import { fs, os, path } from '@autocut/lib/cep/node';
import config from '../../../cep.config';
import { CURRENT_ENV } from './currentEnv.utils';
import { getDirSize, getPproVersion } from './general.utils';
import { getOS } from '@autocut/utils/system/os.system.utils';

export type OSName = 'MAC' | 'WINDOWS';
export const MAX_CACHE_FOLDER_SIZE = 5 * 1e6; // 5 Mo

const CACHE_PATH = {
  MAC: '/Library/Caches/CSXS/cep_cache',
  WINDOWS: '/AppData/Local/Temp/cep_cache',
};

export const removeCache = async () => {
  const cacheFolder = getCacheFolderPath();

  if (fs.existsSync(cacheFolder) && typeof fs.rm === 'function') {
    fs.rm(
      cacheFolder,
      {
        recursive: true,
        force: true,
      },
      err => {
        // errors ignored as we don't want it to stop the process
      }
    );
  }
};

export const getCacheFolderPath = () => {
  const OS: OSName = getOS() as OSName;
  const homeDir = os.homedir();
  const PProVersion = getPproVersion();
  const currentID = config.id;
  const cacheFolder = path.join(
    homeDir,
    CACHE_PATH[OS],
    CURRENT_ENV === 'development' || process.env.FOR_DEV
      ? `PPRO_${PProVersion}_${currentID}.main`
      : `PPRO_${PProVersion}_${currentID}`,
    'Cache'
  );

  return cacheFolder;
};

export const getCacheFolderSize = () => {
  const cacheFolder = getCacheFolderPath();

  if (fs.existsSync(cacheFolder)) {
    return getDirSize(cacheFolder);
  }

  return 0;
};

import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import './VolumeCard.css';
import { normalizedToDB } from '@autocut/utils/waveForm.utils';

export type VolumeCardProps = {
  cursorPosition: number;
  maxWidth: number;
  value: string | number;
};

const VolumeCard = ({ cursorPosition, maxWidth, value }: VolumeCardProps) => {
  const [leftMargin, setLeftMargin] = useState(0);
  const [dBValue, setDBValue] = useState('-60');
  const volumeRef = useRef(null);

  useEffect(() => {
    if (volumeRef == null || volumeRef.current == null) {
      return;
    }

    const volumeElement = volumeRef.current as any;

    if (cursorPosition <= volumeElement.offsetWidth / 2) {
      setLeftMargin(0);
      return;
    }

    if (cursorPosition >= maxWidth - volumeElement.offsetWidth / 2) {
      setLeftMargin(maxWidth - volumeElement.offsetWidth);
      return;
    }

    setLeftMargin(cursorPosition - volumeElement.offsetWidth / 2);
  }, [volumeRef, cursorPosition]);

  useEffect(() => {
    setDBValue(normalizedToDB(value as number).toFixed(2));
  }, [value]);

  return (
    <div
      ref={volumeRef}
      className="volume-container"
      style={{ left: leftMargin }}
    >
      {dBValue}dB
    </div>
  );
};

export default VolumeCard;

import { captionValidationSchema } from '@autocut/validationSchemas/caption.validationSchema';
import { Locale } from '../../../jsx/ppro/const';
import {
  localBlurEffectName,
  localBlurrinessName,
  localScaleWidthEffectName,
  localTransformEffectName,
  localUniformScaleEffectName,
} from '../../../jsx/ppro/enums';
import { z as zod } from 'zod';

export type TransitionSlugs = zod.infer<
  typeof captionValidationSchema
>['transition']['effects'][number];

export type EffectData = (locale: Locale) => {
  componentName: string;
  properties: {
    name: string;
    keyFrames: [number, any][];
  }[];
}[];

export const CAPTIONS_TRANSITIONS: Record<TransitionSlugs, EffectData> = {
  zoomIn: (locale: Locale) => [
    {
      componentName: localTransformEffectName[locale],
      properties: [
        { name: localUniformScaleEffectName[locale], keyFrames: [[0, false]] },
        {
          name: localScaleWidthEffectName[locale],
          keyFrames: [
            [0, 93],
            [0.05, 98],
            [0.1, 100],
          ],
        },
      ],
    },
  ],
  blurIn: (locale: Locale) => [
    {
      componentName: localBlurEffectName[locale],
      properties: [
        {
          name: localBlurrinessName[locale],
          keyFrames: [
            [0, 30],
            [0.05, 10],
            [0.1, 0],
          ],
        },
      ],
    },
  ],
};

export const FRAME_SIZE: { [key: number]: number } = {
  8000: 240,
  16000: 480,
  32000: 960,
  48000: 1440,
};

export default async function (binary: any) {
  const { instance } = await WebAssembly.instantiate(binary);
  const libfvad: any = instance.exports;

  class VAD {
    _destroyed: boolean;
    _handle: any;
    mode: number;
    sampleRate: number;
    constructor(mode: number, rate: number) {
      this._destroyed = false;
      this._handle = libfvad.fvad_new();

      if (!this._handle) {
        throw new Error('Memory allocation error: cannot instanciate libfvad');
      }

      if (libfvad.fvad_set_mode(this._handle, mode) < 0) {
        throw new Error(`Invalid mode: ${mode}`);
      }
      this.mode = mode;

      if (libfvad.fvad_set_sample_rate(this._handle, rate) < 0) {
        throw new Error(`Invalid sample rate: ${rate}`);
      }
      this.sampleRate = rate;
    }

    processFrame(frame: Uint8Array) {
      if (this._destroyed) {
        throw new Error('This instance is already destroyed');
      }
      if (!(FRAME_SIZE[this.sampleRate] == frame.length)) {
        throw new Error(
          `Invalid frame size ${frame.length} for sample rate ${this.sampleRate}`
        );
      }
      const bytes = frame.length * frame.BYTES_PER_ELEMENT;
      const ptr = libfvad.malloc(bytes);
      const data = new Uint8Array(libfvad.memory.buffer, ptr, bytes);
      data.set(new Uint8Array(frame.buffer));
      const result = libfvad.fvad_process(
        this._handle,
        data.byteOffset,
        frame.length
      );
      libfvad.free(data.byteOffset);
      return result === 1;
    }

    destroy() {
      libfvad.fvad_free(this._handle);
      this._handle = null;
      this._destroyed = true;
    }
  }

  return VAD;
}

declare global {
  interface Element {
    cloneNodeWithNewId(newObjectID: string): Element;
  }

  interface Document {
    findElementWithObjectId(
      tagNames: string[],
      objectId: string
    ): Element | undefined;
    findElementWithObjectRef(
      tagNames: string[],
      objectRef: string
    ): Element | undefined;
  }
}

Element.prototype.cloneNodeWithNewId = function (newObjectID: string) {
  const newElement = this.cloneNode(true) as unknown as Element;
  const objectID = newElement.getAttribute('ObjectID');
  const objectUID = newElement.getAttribute('ObjectUID');
  const objectRef = newElement.getAttribute('ObjectRef');
  const objectURef = newElement.getAttribute('ObjectURef');

  if (objectID) {
    newElement.setAttribute('ObjectID', newObjectID);
  }

  if (objectUID) {
    newElement.setAttribute('ObjectUID', newObjectID);
  }

  if (objectRef) {
    newElement.setAttribute('ObjectRef', newObjectID);
  }

  if (objectURef) {
    newElement.setAttribute('ObjectURef', newObjectID);
  }

  this.parentElement?.appendChild(newElement);

  return newElement;
};

Document.prototype.findElementWithObjectId = function (
  tagName: string[],
  objectId: string
) {
  const conditions = tagName.map(
    tag => `${tag}[ObjectID="${objectId}"], ${tag}[ObjectUID="${objectId}"]`
  );
  const elements = this.querySelectorAll(conditions.join(', '));

  return elements[0];
};

Document.prototype.findElementWithObjectRef = function (
  tagName: string[],
  objectRef: string
) {
  const conditions = tagName.map(
    tag => `${tag}[ObjectRef="${objectRef}"], ${tag}[ObjectURef="${objectRef}"]`
  );
  const elements = this.querySelectorAll(conditions.join(', '));

  return elements[0];
};

export {};

import * as React from 'react';

import { OS_MAC } from '@autocut/constants/constants';
import { child_process, path } from '@autocut/lib/cep/node';
import logLevel from '@autocut/types/logLevel.enum';
import { logger, logPath } from '@autocut/utils/logger';
import { useCallback } from 'react';
import { usePlatform } from './usePlatform';

export const useLogger = (loggerName: string) => {
  const [currentLogPath, setCurrentLogPath] = React.useState('');
  const platform = usePlatform();

  React.useEffect(() => {
    async function loadLogPath() {
      setCurrentLogPath(logPath);
    }
    void loadLogPath();
  }, []);

  const logMessage = useCallback(
    (level: logLevel, message = 'log', objects = {}) => {
      logger(loggerName, level, message, objects);
    },
    []
  );

  const openTodayLog = useCallback(async () => {
    if (platform === OS_MAC) {
      child_process.spawn('open', [currentLogPath]);
    }
  }, []);

  const openLogFolder = useCallback(async () => {
    if (platform === OS_MAC) {
      child_process.spawn('open', [
        currentLogPath.replace(path.basename(currentLogPath), ''),
      ]);
    }
  }, []);

  return {
    logMessage,
    openTodayLog,
    openLogFolder,
    logPath: currentLogPath,
  };
};

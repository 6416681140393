import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { HighlightParameters } from './HighlightParameters';
import { WordBoxParameters } from './WordBoxParameters';
import { Switch } from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';

const AnimationsCustomization = () => {
  const { enabled, floatingText } = useAutoCutStore(state => ({
    ...state.ui.parameters.caption.animations,
  }));

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked =>
        setAutocutStore('ui.parameters.caption.animations.enabled', checked)
      }
      title={
        <TranslatedMessage
          id="captions_customization_animation"
          defaultMessage="Animation"
        />
      }
    >
      <HighlightParameters />

      <ParameterLayout
        title={
          <TranslatedMessage
            id="captions_customization_animation_floating_text"
            defaultMessage="Floating text"
          />
        }
      >
        <Switch
          checked={floatingText}
          onChange={value =>
            setAutocutStore(
              'ui.parameters.caption.animations.floatingText',
              value
            )
          }
          size={16}
        />
      </ParameterLayout>

      <WordBoxParameters />
    </ParametersGroupLayout>
  );
};

export default AnimationsCustomization;

import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/atoms/Stepper/StepperRouter/StepperRouter';
import { AutocutModes } from '@autocut/enums/modes.enum';
import CaptionsTranscriptStep from '@autocut/modes/captions/Steps/Transcript/CaptionsTranscriptStep';
import { ModeWrapper } from '@autocut/modes/ModeWrapper';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import CaptionsLanguage from './Steps/Language/CaptionsLanguage';

const CaptionsMode = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ModeWrapper
      Icon={AutocutModes.Captions.icon}
      text={AutocutModes.Captions.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/cut/caption"
        steps={[
          {
            label: intl.formatMessage({
              id: 'captions_step_script',
              defaultMessage: 'Select clips & language',
            }),
            clickable: true,
            path: 'script',
            element: <CaptionsLanguage />,
            index: true,
          },
          {
            label: intl.formatMessage({
              id: 'captions_step_transcription',
              defaultMessage: 'Check transcription',
            }),
            path: 'transcript',
            element: <CaptionsTranscriptStep />,
          },
        ]}
      />
    </ModeWrapper>
  );
};

export default CaptionsMode;

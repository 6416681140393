import React, { useRef } from 'react';

import { BRoll } from '@autocut/types/BRolls';
import { FaRegTrashAlt } from 'react-icons/fa';

import { VideoContainer } from './VideoContainer/VideoContainer';
import { TimeBadge } from '../TimeBadge/TimeBadge';

import css from './BRollTranscriptItem.module.css';
import { setAutocutStore } from '@autocut/utils/zustand';
import { TranscriptChunkContainer } from '@autocut/components/containers/TranscriptChunkContainer';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { PexelVideoRef } from '../PexelPreview/PexelPreview';

export type BRollTranscriptItemProps = {
  bRoll: BRoll;
  openChoiceModal: (bRoll: BRoll) => void;
};

export const BRollTranscriptItem = ({
  bRoll,
  openChoiceModal,
}: BRollTranscriptItemProps) => {
  const videoRef = useRef<PexelVideoRef>(null);

  const onMouseEnter = async () => {
    await videoRef.current?.play();
    videoRef.current?.zoom();
  };

  const onMouseLeave = () => {
    videoRef.current?.pause();
    videoRef.current?.unzoom();
  };

  const onVideoDelete = () => {
    setAutocutStore('onGoingProcess.bRolls', state =>
      (state.onGoingProcess.bRolls as BRoll[]).map(currentBRoll => {
        if (currentBRoll.id === bRoll.id) {
          return {
            ...currentBRoll,
            video: undefined,
          };
        }
        return currentBRoll;
      })
    );
  };
  return (
    <TranscriptChunkContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={css.bRollItemContainer}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <FlexContainer alignItems="center" gap={12}>
        <VideoContainer
          ref={videoRef}
          onDelete={onVideoDelete}
          onClick={() => {
            openChoiceModal(bRoll);
          }}
          video={bRoll.video}
        />
        <FlexContainer
          flexDirection="column"
          justifyContent="space-between"
          gap={8}
        >
          <TimeBadge from={bRoll.start} to={bRoll.end} />
          <p>{bRoll.transcript}</p>
        </FlexContainer>
      </FlexContainer>
      {bRoll.video !== undefined && (
        <FaRegTrashAlt
          className={css.trashIcon}
          onClick={onVideoDelete}
          size={16}
          color="#98A2B3"
        />
      )}
    </TranscriptChunkContainer>
  );
};

export const modifyVideoSize = (
  videoUrl: string,
  width: number,
  height: number
) => {
  const url = new URL(videoUrl);
  const searchParams = url.searchParams;
  searchParams.set('h', height.toString());
  searchParams.set('w', width.toString());

  url.search = searchParams.toString();
  const newUrl = url.toString();

  return newUrl;
};

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { defaultFont } from '@autocut/modes/captions/Steps/Customization/Parts/Fonts/fonts.utils';
import { REPEAT_ALGORITHM } from '@autocut/modes/repeat/Steps/Language/Parts/AlgorithmSelectStep';
import { TextBoxAnimation } from '@autocut/types/CaptionsParameters';
import { StatMode } from '@autocut/types/StatCategory.enum';
import { ZoomTypes } from '@autocut/types/ZoomType';
import { LicenceKeyType } from '@autocut/types/isKeyValidResponse';
import { TransitionSlugs } from '@autocut/utils/captions/effects.utils';
import { IfAny } from '@autocut/utils/type.utils';
import { autocutStoreVanilla, deepFind } from '@autocut/utils/zustand';
import { cloneDeep } from 'lodash';
import React from 'react';
import { IconType } from 'react-icons';
import { AiOutlineScissor, AiOutlineZoomIn } from 'react-icons/ai';
import { BiMessageSquareX, BiMicrophone } from 'react-icons/bi';
import { CiMobile1 } from 'react-icons/ci';
import { FaInstagram } from 'react-icons/fa6';
import { HiOutlineVideoCamera } from 'react-icons/hi';
import { LuLanguages } from 'react-icons/lu';
import { TbRepeatOff } from 'react-icons/tb';
import { TfiParagraph } from 'react-icons/tfi';

import brollThumbnail from '@autocut/assets/images/modes/broll.png?url';
import captionsThumbnail from '@autocut/assets/images/modes/captions.png?url';
import chapterThumbnail from '@autocut/assets/images/modes/chapter.png?url';
import podcastThumbnail from '@autocut/assets/images/modes/podcast.png?url';
import profanityThumbnail from '@autocut/assets/images/modes/profanity.png?url';
import repeatThumbnail from '@autocut/assets/images/modes/repeat.png?url';
import resizeThumbnail from '@autocut/assets/images/modes/resize.png?url';
import silenceThumbnail from '@autocut/assets/images/modes/silence.png?url';
import viralThumbnail from '@autocut/assets/images/modes/viral.png?url';
import zoomThumbnail from '@autocut/assets/images/modes/zoom.png?url';

import { v4 as uuidv4 } from 'uuid';
import { Traits } from '@autocut/utils';
import { bleepSounds } from '@autocut/utils/swearWords/bleepFile.enum';

export const defaultTextBox: TextBoxAnimation = {
  enabled: false,
  xPadding: 40,
  yPadding: 20,
  opacity: 75,
  color: { r: 0, g: 0, b: 0 },
  radius: 0,
};

// As AutocutModeConfig uses AutoCutStateKeys which is extracted from AutoCutState we cannot use any mode type in the AutoCutState
// To avoid circular dependencies we use this type instead
export type AutocutModeState = {
  id: string;
  name: string;
  description?: React.ReactNode;
  thumbnail: string;
  path: string;
  icon: IconType;
  statCategory: StatMode;
  parametersPath: string;
  defaultParameters: {
    [key: string]: any;
  };
  endProcessConfig: {
    goToNextMode?: string | false;
    restoreSequence?: boolean;
    rateAutoCut?: boolean;
    timeTaken?: boolean;
    timeSaved?: boolean;
  };
  available: boolean;
  status: string;
  minKeyType: LicenceKeyType;
  minPProVersion?: string;
  getStatValue: () => number;
  getStatsTraits?: () => Traits;
};

export type AutocutModeConfig = Omit<AutocutModeState, 'parametersPath'> & {
  parametersPath: string;
};

export const AutocutModes = {
  Legacy: {
    id: 'silence',
    icon: AiOutlineScissor,
    name: 'AutoCut Silences',
    description: (
      <TranslatedMessage
        id="mode_description_silence"
        defaultMessage="Remove silences in your timeline"
      />
    ),
    thumbnail: silenceThumbnail,
    path: '/cut/legacy',
    statCategory: StatMode.LEGACY,
    parametersPath: 'ui.parameters.silence',
    defaultParameters: {
      minTimeOfSilence: 0.3,
      minTimeOfTalk: 0.2,
      marginBefore: 0.2,
      marginAfter: 0.2,
      transistions: 'none',
      noiseLevel: -35,
      silences: 'delete',
      aggressivenessLevel: 3,
      languageOfTranscription: {
        label:
          localStorage.getItem('language') === 'FR' ? 'Anglais' : 'English',
        value: 'en',
        textDirection: 'ltr',
      },
    },
    endProcessConfig: {
      goToNextMode: 'zoom',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: true,
    },
    available: true,
    status: '',
    minKeyType: 'basic',
    minPProVersion: undefined,
    getStatValue: () =>
      autocutStoreVanilla().onGoingProcess?.timelineBasedSilencesTotal
        ?.length || 1,
  },
  Ai: {
    id: 'ai',
    icon: AiOutlineScissor,
    name: 'AutoCut AI',
    description: (
      <TranslatedMessage
        id="mode_description_ai"
        defaultMessage="Remove silences in your timeline"
      />
    ),
    thumbnail: silenceThumbnail,
    path: '/cut/ai',
    statCategory: StatMode.IA,
    parametersPath: 'ui.parameters.ai',
    defaultParameters: {
      aggressivenessLevel: 3,
      transistions: 'none',
      silences: 'delete',
    },
    endProcessConfig: {
      goToNextMode: 'zoom',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: true,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () =>
      autocutStoreVanilla().onGoingProcess?.timelineBasedSilencesTotal
        ?.length || 1,
  },
  Podcast: {
    id: 'podcast',
    icon: BiMicrophone,
    name: 'AutoCut Podcast',
    description: (
      <TranslatedMessage
        id="mode_description_podcast"
        defaultMessage="Edits podcast instantly"
      />
    ),
    thumbnail: podcastThumbnail,
    path: '/cut/podcast',
    statCategory: StatMode.PODCAST,
    parametersPath: 'ui.parameters.podcast',
    defaultParameters: (() => {
      const defaultTalkersId = [uuidv4(), uuidv4()];
      const defaultCamerasId = [uuidv4(), uuidv4()];

      return {
        minimumCamTime: 3,
        maximumCamTime: 10,
        toleranceTime: 0.5,
        precision: 0.1,
        deleteUnusedClips: false,
        speakers: [
          {
            id: defaultTalkersId[0],
            name: '',
            cameras: [],
            audio: {
              label: 'Track A1',
              value: '0',
            },
          },
          {
            id: defaultTalkersId[1],
            name: '',
            cameras: [],
            audio: {
              label: 'Track A2',
              value: '1',
            },
          },
        ],
        cameras: [
          {
            id: defaultCamerasId[0],
            speakerIds: [],
            label: 'Track V1',
            value: '0',
          },
          {
            id: defaultCamerasId[1],
            speakerIds: [],
            label: 'Track V2',
            value: '1',
          },
        ],
      };
    })(),
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: true,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => autocutStoreVanilla().onGoingProcess?.nbStepTotal || 1,
  },
  Repeat: {
    id: 'repeat',
    icon: TbRepeatOff,
    name: 'AutoCut Repeat',
    description: (
      <TranslatedMessage
        id="mode_description_repeat"
        defaultMessage="Removes bad takes with AI"
      />
    ),
    thumbnail: repeatThumbnail,
    path: '/cut/repeat',
    statCategory: StatMode.REPEAT,
    parametersPath: 'ui.parameters.repeat',
    defaultParameters: {
      selectedAlgorithm: REPEAT_ALGORITHM.PRECISE,
      transistions: 'none',
      silences: 'delete',
      languageOfTranscription: {
        label: 'English',
        value: 'en',
        textDirection: 'ltr',
      },
    },
    endProcessConfig: {
      goToNextMode: 'silence',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: true,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () =>
      autocutStoreVanilla().onGoingProcess?.timelineBasedSilencesTotal
        ?.length || 1,
  },
  Zoom: {
    id: 'zoom',
    icon: AiOutlineZoomIn,
    name: 'AutoZoom',
    description: (
      <TranslatedMessage
        id="mode_description_zoom"
        defaultMessage="Automatically add zooms"
      />
    ),
    thumbnail: zoomThumbnail,
    path: '/cut/zoom',
    statCategory: StatMode.AUTOZOOM,
    parametersPath: 'ui.parameters.zoom',
    defaultParameters: {
      autoZoomMinCoef: 1.2, // deprecated
      autoZoomMaxCoef: 1.4,
      anchorPosMinCoef: { xPercentage: 0.5, yPercentage: 0.5 },
      anchorPosMaxCoef: { xPercentage: 0.5, yPercentage: 0.5 },
      totalZoomPercentage: 0.6,
      dynamicZoomPercentage: 0.1,
      dynamicMinZoomTime: 0.5,
      dynamicMaxZoomTime: 5,
      dynamicMinZoomIntensity: 0.4,
      constraintZoom: true,
      smoothZoomPercentage: 0.9,
      smoothMinZoomTime: 2,
      smoothMaxZoomTime: 15,
      smoothZoomSpeed: 0.01, // deprecated
      nervousness: 0.3,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => autocutStoreVanilla().onGoingProcess?.nbStepTotal || 1,
  },
  Captions: {
    id: 'caption',
    icon: LuLanguages,
    name: 'Auto Captions',
    description: (
      <TranslatedMessage
        id="mode_description_captions"
        defaultMessage="Add animated captions with AI"
      />
    ),
    thumbnail: captionsThumbnail,
    path: '/cut/caption',
    statCategory: StatMode.CAPTIONS,
    parametersPath: 'ui.parameters.caption',
    defaultParameters: {
      userPresets: [],
      utils: {
        hasClosedInfoText: false,
      },
      languageOfTranscription: {
        label: 'English',
        value: 'en',
        textDirection: 'ltr',
      },
      position: {
        xPercentage: 0.5,
        yPercentage: 0.95,
        emojiXPercentage: 0.5,
        emojiYPercentage: 0.9,
        rotation: 0,
        offset: [0, 0] as [number, number],
        scale: [1, 1] as [number, number],
      },
      text: {
        color: { r: 255, g: 255, b: 255 },
        fontSize: 104,
        font: defaultFont,
      },
      animations: {
        enabled: true,
        highlight: {
          enabled: true,
          color: { r: 230, g: 80, b: 25 },
          revealText: {
            enabled: false,
            color: {
              r: 255,
              g: 255,
              b: 255,
            },
          },
        },
        wordBox: cloneDeep(defaultTextBox),
        boldWithText: false,
        floatingText: false,
      },
      outline: {
        enabled: true,
        color: { r: 0, g: 0, b: 0 },
        width: 8,
      },
      box: cloneDeep(defaultTextBox),
      transition: {
        enabled: true,
        effects: [] as TransitionSlugs[],
      },
      emojis: {
        enabled: false,
        size: 30,
      },
      formating: {
        uppercase: false,
        italic: false,
        nbLines: 1,
        maxWidth: 80,
        glow: {
          enabled: false,
          intensity: 0,
          color: { r: 0, g: 0, b: 0 },
        },
        removePunctuation: false,
      },
      postProcessing: {
        formattedNumbers: 0,
      },
    },
    endProcessConfig: {
      goToNextMode: false,
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: '23',
    getStatValue: () =>
      autocutStoreVanilla().onGoingProcess?.captionChunks?.length || 1,
  },
  Resize: {
    id: 'resize',
    icon: CiMobile1,
    name: 'AutoResize',
    description: (
      <TranslatedMessage
        id="mode_description_resize"
        defaultMessage="Resizes your sequence with AI"
      />
    ),
    thumbnail: resizeThumbnail,
    path: '/cut/resize',
    statCategory: StatMode.RESIZE,
    parametersPath: 'ui.parameters.resize',
    defaultParameters: {
      preset: undefined,
      watermark: undefined,
      autoReframePreset: 'default',
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => 1,
  },
  BRoll: {
    id: 'broll',
    icon: HiOutlineVideoCamera,
    name: 'Auto B-Roll',
    description: (
      <TranslatedMessage
        id="mode_description_broll"
        defaultMessage="Automatically adds B-Roll with AI"
      />
    ),
    thumbnail: brollThumbnail,
    path: '/cut/broll',
    statCategory: StatMode.BROLL,
    parametersPath: 'ui.parameters.broll',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      minimumBRollTime: 2,
      maximumBRollTime: 30,
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: true,
    },
    available: true,
    status: 'BETA',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => autocutStoreVanilla().onGoingProcess?.nbStepTotal || 1,
  },
  SwearWords: {
    id: 'swear_word',
    icon: BiMessageSquareX,
    name: 'AutoProfanity Filter',
    description: (
      <TranslatedMessage
        id="mode_description_profanity"
        defaultMessage="Bleeps out swear words"
      />
    ),
    thumbnail: profanityThumbnail,
    path: '/cut/swear_word',
    statCategory: StatMode.SWEAR_WORD,
    parametersPath: 'ui.parameters.swear_word',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      bleepFile: bleepSounds[0].value,
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: true,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => autocutStoreVanilla().onGoingProcess?.nbStepTotal || 1,
  },
  Chapters: {
    id: 'chapters',
    icon: TfiParagraph,
    name: 'Auto Chapter',
    description: (
      <TranslatedMessage
        id="mode_description_chapter"
        defaultMessage="Detect chapters and add markers with AI"
      />
    ),
    thumbnail: chapterThumbnail,
    path: '/cut/chapters',
    statCategory: StatMode.CHAPTERS,
    parametersPath: 'ui.parameters.chapters',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      colorIndex: 0,
    },
    endProcessConfig: {},
    available: true,
    status: 'NEW',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => autocutStoreVanilla().onGoingProcess?.nbStepTotal || 1,
  },
  ViralClips: {
    id: 'viral_clips',
    icon: FaInstagram,
    name: 'Auto Viral Clips',
    description: (
      <TranslatedMessage
        id="mode_description_viral"
        defaultMessage="Identify viral shorts in long videos in one click"
      />
    ),
    thumbnail: viralThumbnail,
    path: '/cut/viral_clips',
    statCategory: StatMode.VIRALCLIPS,
    parametersPath: 'ui.parameters.viral_clips',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: true,
    },
    available: true,
    status: 'NEW',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: () => autocutStoreVanilla().onGoingProcess?.nbStepTotal || 1,
    getStatsTraits: () => {
      const filteredClips =
        autocutStoreVanilla().onGoingProcess?.viralClips?.filter(
          clip => clip.selected
        );

      if (!filteredClips) return {} as Traits;

      return {
        nbClips: filteredClips.length,
        clips: JSON.stringify(
          filteredClips.map(clip => {
            const copy: any = { ...clip };
            return [copy.start, copy.end];
          })
        ),
      };
    },
  },
} as const satisfies { [key: string]: AutocutModeConfig };

export type AutocutModesKeys = keyof typeof AutocutModes;
export type AutocutMode = (typeof AutocutModes)[AutocutModesKeys];
export type AutocutModeIds = (typeof AutocutModes)[AutocutModesKeys]['id'];

export const getModeById = (id: AutocutModeIds): AutocutMode | undefined => {
  if ((id as string) === 'legacy') return AutocutModes.Legacy;

  const originalModeKey = Object.keys(AutocutModes).find(
    key => AutocutModes[key as AutocutModesKeys].id === id
  ) as AutocutModesKeys;
  if (!originalModeKey) return undefined;
  return AutocutModes[originalModeKey];
};

export const getModeStatTrait = <T extends AutocutModeState = AutocutMode>(
  mode: T
): StatMode => mode.statCategory;

export const getParametersByMode = <T extends AutocutModeState = AutocutMode>(
  mode: T
) => {
  const autocutState = autocutStoreVanilla();
  const originalMode = getModeById(mode.id as AutocutModeIds);
  if (!originalMode) return undefined;
  return deepFind(autocutState, mode.parametersPath) as IfAny<
    T['defaultParameters'],
    (typeof originalMode)['defaultParameters'],
    T['defaultParameters']
  >;
};

export const getDefaultParametersByMode = <
  T extends AutocutModeState = AutocutMode
>(
  mode: T
) => mode.defaultParameters;

export const getParametersPathByMode = <
  T extends AutocutModeState = AutocutMode
>(
  mode: T
) => mode.parametersPath as AutocutMode['parametersPath'];

export const getModeByLocation = (location: string) => {
  const mode = Object.keys(AutocutModes).find((mode: string) => {
    const path = AutocutModes[mode as AutocutModesKeys].path;
    return location.startsWith(path);
  });

  if (!mode) return undefined;

  return AutocutModes[mode as AutocutModesKeys];
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React, { useEffect, useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { getPublicCaptionsPresets } from '@autocut/utils/captionsPresets/getPublicCaptionsPresets';
import { CaptionsPresetCard } from '../CaptionsPresetCard/CaptionsPresetCard';
import { copyPresetFromPresetId } from '@autocut/utils/captionsPresets/copyPresetFromPresetId';
import { CaptionsPreset } from '@autocut/types/CaptionsParameters';
import { LoadingRing } from '@autocut/components/atoms/LoadingRing/LoadingRing';
import { Pagination } from '@autocut/components/atoms/Pagination/Pagination';
import { PRESETS_PER_LINE } from '../../PresetCustomization';
import { setAutocutStore } from '@autocut/utils/zustand';
import { PresetPreview } from '../PresetPreview';
import { handleApplyPreset } from '@autocut/modes/captions/utils/presets.captions.utils';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import { Input } from '@autocut/designSystem/components/atoms/Input/Input';

export const SearchPublicPresetModal = () => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const [selectedPreset, setSelectedPreset] = useState<CaptionsPreset | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>();

  const currentParameters = useAutoCutStore(
    state => state.ui.parameters.caption
  );

  const hasPreviousPage = selectedPage > 0;

  const [publicPresets, setPublicPresets] = useState<
    CaptionsPreset[] | undefined
  >(undefined);
  const [error, setError] = useState<React.ReactNode | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);

    void getPublicCaptionsPresets({ search, page: selectedPage }).then(
      value => {
        if (value === null) {
          setError(
            <TranslatedMessage
              id="captions_customization_get_public_presets_error"
              defaultMessage="Error while getting the public presets, please contact us on discord or at contact@autocut.fr"
            />
          );
          return;
        }

        setPublicPresets(value.captionsPresets);
        setHasNextPage(value.hasNextPage);

        setIsLoading(false);
      }
    );
  }, [search, selectedPage]);

  const handleImportPreset = async () => {
    if (selectedPreset === null) return;

    await copyPresetFromPresetId(selectedPreset?.id).then(newPresets => {
      if (newPresets === null) {
        setError(
          <TranslatedMessage
            id="captions_presetCustomization_copyPreset_error"
            defaultMessage="Error while copying the preset, please contact us on discord or at contact@autocut.fr"
          />
        );
        return;
      }

      handleApplyPreset(selectedPreset);

      setAutocutStore('ui.parameters.caption.userPresets', newPresets);

      setAutocutStore('ui.openedModalName', '');
    });
  };

  return (
    <Modal
      icon={<IoSearchOutline size="1.5rem" />}
      title={
        <TranslatedMessage
          id="captions_presetCustomization_searchPresetModal_title"
          defaultMessage="Search community preset"
        />
      }
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      fullScreen
      footer={
        <FlexContainer
          flexDirection="column"
          justifyContent="center"
          style={{
            width: '100%',
            marginTop: '-16px',
          }}
          gap={32}
        >
          <FlexContainer justifyContent="center">
            <Pagination
              page={selectedPage}
              hasNextPage={!!hasNextPage}
              hasPreviousPage={hasPreviousPage}
              setPage={setSelectedPage}
            />
          </FlexContainer>

          <FlexContainer
            justifyContent="center"
            alignItems="center"
            style={{
              height: '40px',
              fontSize: '20px',
            }}
          >
            <PresetPreview selectedPreset={selectedPreset} />
          </FlexContainer>

          <FlexContainer
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="nowrap"
            gap={128}
            style={{
              padding: '0 16px',
            }}
          >
            <Button
              onClick={() => setAutocutStore('ui.openedModalName', '')}
              variant="secondary"
              disabled={isLoading}
            >
              <TranslatedMessage
                id="captions_presetCustomization_searchPresetModal_cancel"
                defaultMessage="Cancel"
              />
            </Button>

            <Button
              onClick={handleImportPreset}
              color={colors.primary600}
              disabled={selectedPreset === null || isLoading}
            >
              <TranslatedMessage
                id="captions_presetCustomization_searchPresetModal_import"
                defaultMessage="Import preset"
              />
            </Button>
          </FlexContainer>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={16}
        style={{
          width: '100%',
        }}
      >
        <Input
          value={search}
          onChange={e => {
            setSearch(e);
            setSelectedPage(0);
          }}
          placeholder={intl.formatMessage({
            id: 'captions_presetCustomization_createPresetModal_placeholder',
            defaultMessage: 'Search a preset',
          })}
        />

        {error}

        <FlexContainer
          flexDirection="column"
          gap={8}
          justifyContent="space-between"
          style={{
            height: '100%',
          }}
        >
          {isLoading && (
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <LoadingRing />
            </FlexContainer>
          )}
          {!error && !isLoading && (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${PRESETS_PER_LINE}, 1fr)`,
                gap: 8,
                height: '100%',
                overflow: 'hidden',
              }}
            >
              {publicPresets?.map((preset, index) => (
                <CaptionsPresetCard
                  key={index}
                  preset={preset}
                  onClick={async () => {
                    if (selectedPreset?.id === preset.id) {
                      setSelectedPreset(null);
                      return;
                    }
                    setSelectedPreset(preset);
                  }}
                  withUsageCount={true}
                  isSelected={preset.id === selectedPreset?.id}
                />
              ))}
            </div>
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

import { FileInput } from '@autocut/components/atoms/FileInput/FileInput';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { useIntl } from 'react-intl';

export const WatermarkStep = () => {
  const intl = useIntl();

  const { watermark } = useAutoCutStore(state => ({
    watermark: state.ui.parameters.resize.watermark,
  }));

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'resize_watermark_step_title',
        defaultMessage: 'Watermark',
      })}
      alwaysOpen={true}
      helperId="resize_watermark_step_helper"
      helperDefault="You can add a watermark to your sequence. It will be added to the bottom right corner of your sequence."
    >
      <FileInput
        id="watermark"
        multiple={false}
        files={watermark ? [watermark] : []}
        setFiles={files => {
          if (!files || files.length <= 0) {
            setAutocutStore('ui.parameters.resize.watermark', undefined);
            return;
          }

          setAutocutStore('ui.parameters.resize.watermark', files[0]);
        }}
        textId="resize_watermark_step_text"
      />
    </StepLayout>
  );
};

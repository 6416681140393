import { ModalType } from '@autocut/enums/modals.enum';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect, createContext, useState } from 'react';

interface IWindowSizeContext {
  windowSize: { width: number; height: number };
  isTooSmall: boolean;
}

export const WindowSizeContext = createContext<IWindowSizeContext>({
  windowSize: { width: 0, height: 0 },
  isTooSmall: false,
});

export const WINDOW_MIN_WIDTH = 560;
export const WINDOW_MIN_HEIGHT = 600;

const checkIsTooSmall = (width: number, height: number) =>
  width < WINDOW_MIN_WIDTH || height < WINDOW_MIN_HEIGHT;

export const WindowSizeProvider = ({ children }: { children: any }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isTooSmall = checkIsTooSmall(windowSize.width, windowSize.height);

  useEffect(() => {
    if (
      !window.location.pathname.startsWith('/onboarding') &&
      checkIsTooSmall(windowSize.width, windowSize.height)
    ) {
      setAutocutStore('ui.openedModalName', ModalType.WindowTooSmall);
    }

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (
        !window.location.pathname.startsWith('/onboarding') &&
        checkIsTooSmall(width, height)
      ) {
        setAutocutStore('ui.openedModalName', ModalType.WindowTooSmall);
      }

      setWindowSize({
        width,
        height,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WindowSizeContext.Provider
      value={{
        windowSize,
        isTooSmall,
      }}
    >
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSize = () => {
  const context = React.useContext(WindowSizeContext);
  if (context === undefined) {
    throw new Error(
      'Please make sure you use this hook in the WindowSizeProvider'
    );
  }
  return context;
};

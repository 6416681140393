enum logLevel {
  crit = 60,
  error = 50,
  warn = 40,
  info = 30,
  debug = 20,
  notice = 10,
}

export default logLevel;

import * as React from 'react';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { AutocutModeIds } from '@autocut/enums/modes.enum';
import { parametersValidationSchema } from '@autocut/validationSchemas/parameters.validationSchema';
import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useAutoCutStore } from '../../../hooks/useAutoCutStore';
import { FooterLayout } from '../../../layouts/FooterLayout';
import css from './ModeFooter.module.css';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { InfoText } from '@autocut/components/atoms/InfoText';

export type ModeFooterProps = {
  renderButton: (isLoading: boolean, isDisabled: boolean) => React.ReactElement;
  errorId?: string;
};

export const ModeFooter = ({ renderButton, errorId }: ModeFooterProps) => {
  const intl = useIntl();
  const { isProcessing, isSelectionLoading, mode, parameters } =
    useAutoCutStore(state => ({
      isProcessing: state.ui.process.isProcessing,
      isSelectionLoading: state.sequence.parsingProcess.isLoading,
      mode: state.ui.process.mode,
      parameters: state.ui.parameters,
    }));

  const [parametersValidationError, setParametersValidationError] = useState<
    string[]
  >([]);
  const currentModeParameters = parameters[mode.id as AutocutModeIds];
  const isFirstValidationRef = useRef(true);

  const validateParameters = useCallback(() => {
    const currentModeValidator =
      parametersValidationSchema[mode.id as AutocutModeIds];

    try {
      currentModeValidator.parse(currentModeParameters);
      return [];
    } catch (error: any) {
      console.log({ error });
      return [
        ...new Set(error.issues.map((issue: any) => issue.message)),
      ] as string[];
    }
  }, [currentModeParameters, mode.id]);

  const injectedButton = useMemo(() => {
    const renderedButton = renderButton(
      isSelectionLoading || isProcessing,
      isProcessing || parametersValidationError.length > 0
    );

    const buttonOnClick = renderedButton.props.onClickFunction;

    const injectedButton = cloneElement(renderedButton, {
      onClickFunction: async () => {
        isFirstValidationRef.current = false;
        const validationErrors = validateParameters();
        setParametersValidationError(validationErrors);
        if (validationErrors.length === 0) {
          await buttonOnClick();
        }
      },
    });

    return injectedButton;
  }, [
    renderButton,
    isSelectionLoading,
    isProcessing,
    parametersValidationError.length,
    validateParameters,
  ]);

  useEffect(() => {
    if (isFirstValidationRef.current) return;

    setParametersValidationError(validateParameters());
  }, [currentModeParameters, validateParameters]);

  return (
    <FooterLayout>
      <FlexContainer
        className={css.container}
        flexDirection="column"
        gap="16px"
      >
        {parametersValidationError.length > 0 && (
          <div>
            {parametersValidationError.map((error, index) => {
              const errorFieldTranslation = intl
                .formatMessage({
                  id: error,
                })
                .match(
                  /(?<=<embedTranslation>).*(?=<\/embedTranslation>)/g
                )?.[0];
              return (
                <p key={index} className={css.additionalMessage}>
                  <TranslatedMessage
                    id={error}
                    valuePamareters={{
                      href: errorFieldTranslation
                        ? `#${errorFieldTranslation}`
                        : undefined,
                    }}
                  />
                </p>
              );
            })}
          </div>
        )}

        {Boolean(errorId) ? (
          <InfoText style="error">
            <TranslatedMessage id={errorId} />
          </InfoText>
        ) : null}

        <div className={css.cutButton}>{injectedButton}</div>
      </FlexContainer>
    </FooterLayout>
  );
};

import { Button } from '@autocut/components/atoms';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { ModalType } from '@autocut/enums/modals.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import { CaptionChunk } from '@autocut/types/Captions';
import { generateAnimatedEmojisFromTranscription } from '@autocut/utils/transcript/generateAnimatedEmojisFromTranscription.utils';
import { generateStaticEmojisFromTranscription } from '@autocut/utils/transcript/generateStaticEmojisFromTranscription.utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';

import css from './GenerateEmojisModal.module.scss';

export const GenerateEmojisModal = () => {
  const captionChunks = useAutoCutStore(
    state => state.onGoingProcess.captionChunks
  );

  if (!captionChunks) return null;

  const getEmojis = async (
    getFunction: ({
      transcription,
    }: {
      transcription: CaptionChunk[];
    }) => Promise<CaptionChunk[]>
  ) => {
    setAutocutStore('ui.openedModalName', ModalType.Processing);
    setAutocutStore('ui.cutButtonMessage', 'process_modal_generating_emojis');

    void getFunction({
      transcription: captionChunks,
    }).then(newChunks => {
      setAutocutStore('ui.openedModalName', '');
      setAutocutStore('ui.cutButtonMessage', '');

      setAutocutStore('onGoingProcess.captionChunks', newChunks);
    });
  };

  return (
    <Modal
      title={
        <TranslatedMessage
          id="generate_emojis_modal_title"
          defaultMessage="What type of emojis do you want to generate?"
        />
      }
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      fullScreen={false}
      fullHeight={false}
      fullWidth={false}
    >
      <div className={css.buttonContainer}>
        <Button
          buttonType="secondary"
          onClickFunction={() =>
            getEmojis(generateStaticEmojisFromTranscription)
          }
        >
          <TranslatedMessage
            id="generate_emojis_modal_cta_static"
            defaultMessage="Static"
          />
        </Button>
        <Button
          buttonType="secondary"
          onClickFunction={() =>
            getEmojis(generateAnimatedEmojisFromTranscription)
          }
        >
          <TranslatedMessage
            id="generate_emojis_modal_cta_animated"
            defaultMessage="Animated"
          />
        </Button>
      </div>
    </Modal>
  );
};

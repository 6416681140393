import CloseModalCross from '@autocut/components/atoms/CloseModalCross/CloseModalCross';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { Button } from '@autocut/components/atoms';
import { FileInput } from '@autocut/components/atoms/FileInput/FileInput';
import { IconAnnotationX } from '@autocut/components/atoms/IconAnnotationX/IconAnnotationX';
import { LinkToUserFileInput } from '@autocut/components/atoms/LinkToUserFileInput/LinkToUserFileInput';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import { autocutApi } from '@autocut/utils';
import {
  generateBugData,
  generateMessage,
  isUrlValid,
} from '@autocut/utils/bugReport.utils';
import { uploadFile } from '@autocut/utils/files.utils';
import { getLogPath } from '@autocut/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../../../cep.config';
import css from './ReportIssueModal.module.css';
import { CheckBox } from '@autocut/components/atoms/CheckBox/CheckBox';

const id = config.id;

export const ReportIssueModal = () => {
  const { logMessage } = useLogger('ReportIssueModal');
  const intl = useIntl();

  const [bugDescription, setBugDescription] = useState('');
  const [bugReproduction, setBugReproduction] = useState('');
  const [blocking, setBlocking] = useState(false);
  const [screenshots, setScreenshots] = useState<File[]>([]);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [linkToUserFile, setLinkToUserFile] = useState<string>('');
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const [error, setError] = useState<string>('');

  const handleSendBugReport = async () => {
    setError('');

    if (!bugDescription || bugDescription.length < 20) {
      setError(
        intl.formatMessage({
          id: 'modal_bug_report_description_error',
          defaultMessage:
            'Please provide a bug description of at least 20 characters.',
        })
      );
      return;
    }
    if (!bugReproduction || bugReproduction.length < 20) {
      setError(
        intl.formatMessage({
          id: 'modal_bug_report_reproduce_error',
          defaultMessage:
            'Please provide a reproduction of at least 20 characters.',
        })
      );
      return;
    }
    if (linkToUserFile !== '' && !isUrlValid(linkToUserFile)) {
      setError(
        intl.formatMessage({
          id: 'modal_bug_report_link_error',
          defaultMessage: 'Please provide a valid link to your file.',
        })
      );
      return;
    }

    const bugData = await generateBugData(
      screenshots,
      bugDescription,
      bugReproduction,
      blocking
    );

    const response = await autocutApi
      .post('/modals/bug-report', bugData)
      .catch((error: any) => {
        setError(error.message);
        logMessage(logLevel.error, 'Daily log send failed.', { error });
      });

    if (!response || response.data.message !== 'OK') return;

    try {
      await uploadFile(
        getLogPath(id),
        response.data.logs.uploadUrl,
        percentage => {
          setUploadPercentage(percentage);
        }
      );

      const screenshotIndex = 0;

      for (const screenshot of screenshots) {
        const fileUploadUrl =
          response.data.screenshots[
            Object.keys(response.data.screenshots)[screenshotIndex]
          ].uploadUrl;

        await uploadFile(
          // @ts-ignore
          screenshot.path,
          fileUploadUrl,
          percentage => {
            setUploadPercentage(percentage);
          },
          true,
          screenshot.type
        );
      }
    } catch (error: any) {
      setError(error.message);
      logMessage(logLevel.error, 'Daily log send failed.', { error });
      return;
    }

    const message = generateMessage(
      bugDescription,
      bugReproduction,
      linkToUserFile,
      blocking,
      bugData,
      response.data as any
    );

    const eventId = Sentry.captureMessage(`User Feedback - ${uuidv4()}`);

    Sentry.captureUserFeedback({
      event_id: eventId,
      email: bugData?.key || 'Unknown',
      name:
        bugData.host && bugData.uuid
          ? `${bugData.host} - ${bugData.uuid}`
          : 'Unknown',
      comments: message,
    });

    setUploadSuccess(true);
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modal_bug_report_title',
        defaultMessage: 'Bug report',
      })}
      icon={
        <div className={css.iconContainer}>
          <IconAnnotationX size={24} color="white" />
        </div>
      }
      footer={
        <>
          {' '}
          <span className={css.error}>{error}</span>
          <Button
            onClickFunction={async () => {
              await handleSendBugReport();
            }}
            disabled={uploadSuccess}
          >
            <TranslatedMessage
              id={
                uploadSuccess
                  ? 'modal_bug_report_button_text_success'
                  : 'modal_bug_report_button_text'
              }
              defaultMessage={
                uploadSuccess ? 'Thank you for your report' : 'Send bug report'
              }
            />
          </Button>
        </>
      }
    >
      <FlexContainer flexDirection="column" gap="16px">
        <FlexContainer
          flexDirection="column"
          gap="16px"
          className={css.textAreasContainer}
        >
          <textarea
            className={css.textArea}
            onChange={e => {
              setBugDescription(e.target.value);
            }}
            rows={10}
            placeholder={intl.formatMessage({
              id: 'modal_bug_report_description_placeholder',
              defaultMessage: 'Describe the bug here...',
            })}
          />
          <textarea
            className={css.textArea}
            onChange={e => {
              setBugReproduction(e.target.value);
            }}
            rows={10}
            placeholder={intl.formatMessage({
              id: 'modal_bug_report_reproduce_placeholder',
              defaultMessage: 'Steps to reproduce the bug here...',
            })}
          />
          <div className={css.uploadContainer}>
            <FileInput
              id={'screenshot'}
              setFiles={setScreenshots}
              files={screenshots}
              multiple
            />
          </div>
        </FlexContainer>
        <LinkToUserFileInput
          setLinkToUserFile={setLinkToUserFile}
          linkToUserFile={linkToUserFile}
        />
        <FlexContainer gap="8px" alignItems="center">
          <CheckBox
            size={16}
            checked={blocking}
            onChange={() => {
              setBlocking(prev => !prev);
            }}
          />
          <TranslatedMessage
            id="modal_bug_report_blocking"
            defaultMessage="The issue was blocking"
          />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

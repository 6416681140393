import * as React from 'react';

import { OS_MAC } from '@autocut/constants/constants';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { usePlatform } from '@autocut/hooks/usePlatform';
import logLevel from '@autocut/types/logLevel.enum';
import { Button } from '../../atoms';
import css from './LogInfo.module.css';

export default function LogInfo() {
  const platform = usePlatform();
  const isInCEPEnv = useAutoCutStore(state => state.ppro.isInCEPEnv);

  const { openTodayLog, openLogFolder, logMessage, logPath } =
    useLogger('LogInfo');

  return (
    <div className={css.log_info}>
      <p>{logPath}</p>
      <div className={css.buttons}>
        {!isInCEPEnv ? (
          <p>Not in CEP environment.</p>
        ) : platform == OS_MAC ? (
          <>
            <Button size="sm" onClickFunction={() => openTodayLog()}>
              Open Log file
            </Button>
            <Button size="sm" onClickFunction={() => openLogFolder()}>
              Open Log folder
            </Button>
          </>
        ) : null}

        <Button
          size="sm"
          onClickFunction={() => logMessage(logLevel.info, `Info from CEP`)}
        >
          Log Info
        </Button>
        <Button
          size="sm"
          onClickFunction={() => logMessage(logLevel.error, `Error from CEP`)}
        >
          Log Error
        </Button>
      </div>
    </div>
  );
}

import { AutoCutState, setAutocutStore } from '@autocut/utils/zustand';
import { Camera } from '@autocut/types/Camera';
import { updateSpeakerReducer } from '../speakers/updateSpeakerReducer';

export const removeCameraReducer =
  (cameraId: string) => (state: AutoCutState) => {
    const camera = state.ui.parameters.podcast.cameras.find(
      camera => camera.id === cameraId
    );
    if (!camera) {
      return state.ui.parameters.podcast.cameras;
    }

    const speakerIds = camera.speakerIds;
    for (const speakerId of speakerIds) {
      const speaker = state.ui.parameters.podcast.speakers.find(
        speaker => speaker.id === speakerId
      );
      if (!speaker) continue;
      const updatedCameras = speaker.cameras.filter(
        (camera: Camera) => camera.id !== cameraId
      );
      setAutocutStore(
        'ui.parameters.podcast.speakers',
        updateSpeakerReducer(speakerId, { cameras: updatedCameras })
      );
    }

    return state.ui.parameters.podcast.cameras.filter(
      (camera: Camera) => camera.id !== cameraId
    );
  };

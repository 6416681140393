import { XMLDocument } from './XMLDocument';
import './prototypes';
import { XMLTrack } from './XMLTrack';
import { crypto } from '@autocut/lib/cep/node';
import { updateComponentParam } from './utils';
import {
  localGraphicParametersName,
  localMotionEffectName,
  localOpacityEffectName,
  localRoughenEdgesEffectName,
  localTextEffectName,
  localTransformEffectName,
} from '../../../jsx/ppro/enums';
import { XMLVideoFilterComponent } from './XMLVideoFilterComponent';
import { XMLProjectItem } from './XMLProjectItem';
import { PproSourceTextParam } from '../captions/formatSourceTextData';
import { CAPTIONS_XML_PROJECT_LOCALE } from '../captions/utils';
import { XMLTrackItemMasterClip } from './XMLTrackItemMasterClip';

type VideoFilterComponent = {
  videoFilterComponentName: string;
  videoFilterComponent: Element | undefined | null;
  videoFilterComponentParams: {
    param: Element | undefined | null;
    paramName: string;
    videoComponentParam: Element | undefined | null;
  }[];
};
export class XMLTrackItem {
  private objectId: string;
  public parentDocument: XMLDocument;
  private parentTrack: XMLTrack;
  private trackItem: Element;
  private videoClipTrackItem: Element | undefined;
  private component: Element | undefined | null;
  private subClip: Element | undefined | null;
  private videoComponentChain: Element | undefined;
  private videoComponentChainComponentsArray: {
    component: Element | undefined | null;
    componentObjectRef: string;
  }[];
  private videoFilterComponents: XMLVideoFilterComponent[];

  public masterClip: XMLTrackItemMasterClip | undefined | null;
  private masterClipParams:
    | {
        param: Element;
        videoComponentParam: Element | undefined | null;
      }[]
    | null;
  private subClipObject: Element | undefined;
  private clipObject: Element | undefined | null;
  private videoClip: Element | undefined;

  constructor({
    trackItem,
    track,
    videoClipTrackItem: constructorVideoClipTrackItem,
    component: constructorComponent,
    subClip: constructorSubClip,
    videoComponentChain: constructorVideoComponentChain,
    videoComponentChainComponentsArray:
      constructorVideoComponentChainComponentsArray,
    videoFilterComponents: constructorVideoFilterComponents,
    subClipObject: constructorSubClipObject,
    clipObject: constructorClipObject,
    videoClip: constructorVideoClip,
    masterClip: constructorMasterClip,
    masterClipParams: constructorMasterClipParams,
  }: {
    trackItem: Element;
    track: XMLTrack;
    videoClipTrackItem?: Element;
    component?: Element;
    subClip?: Element;
    videoComponentChain?: Element;
    videoComponentChainComponentsArray?: {
      component: Element;
      componentObjectRef: string;
    }[];
    videoFilterComponents?: XMLVideoFilterComponent[];
    subClipObject?: Element;
    clipObject?: Element;
    videoClip?: Element;
    masterClip?: XMLTrackItemMasterClip | null;
    masterClipParams?:
      | {
          param: Element;
          videoComponentParam: Element | undefined | null;
        }[]
      | null;
  }) {
    this.trackItem = trackItem;
    this.objectId = trackItem.getAttribute('ObjectRef') ?? '';
    this.parentDocument = track.parentDocument;
    this.parentTrack = track;

    this.videoClipTrackItem = constructorVideoClipTrackItem;
    this.component = constructorComponent;
    this.subClip = constructorSubClip;

    let componentObjectRef = '';
    let subClipObjectRef = '';
    if (!this.videoClipTrackItem || !this.component || !this.subClip) {
      const {
        videoClipTrackItem,
        component,
        componentObjectRef: compObjectRef,
        subClip,
        subClipObjectRef: scObjectRef,
      } = this.getVideoClipTrackItem(this.objectId);
      this.videoClipTrackItem = videoClipTrackItem;
      this.component = component;
      this.subClip = subClip;

      componentObjectRef = compObjectRef ?? '';
      subClipObjectRef = scObjectRef ?? '';
    }

    this.videoComponentChain = constructorVideoComponentChain;
    this.videoComponentChainComponentsArray =
      constructorVideoComponentChainComponentsArray ?? [];

    if (
      !this.videoComponentChain ||
      !this.videoComponentChainComponentsArray.length
    ) {
      const { videoComponentChain, componentsArray } =
        this.getVideoComponentChain(componentObjectRef ?? '');
      this.videoComponentChain = videoComponentChain;
      this.videoComponentChainComponentsArray = componentsArray;
    }

    this.videoFilterComponents = constructorVideoFilterComponents ?? [];

    if (!this.videoFilterComponents?.length) {
      const videoFilterComponents = Array.from(
        this.videoComponentChainComponentsArray
      ).map(({ component, componentObjectRef }) => {
        const { videoFilterComponent, params } = this.getVideoFilterComponent(
          componentObjectRef ?? ''
        );

        return new XMLVideoFilterComponent(
          component as Element,
          videoFilterComponent,
          params.map(({ param, paramComponent }) => ({
            param,
            paramComponent,
          })),
          this.parentDocument
        );
      });
      this.videoFilterComponents = videoFilterComponents;
    }

    this.subClipObject = constructorSubClipObject;
    this.clipObject = constructorClipObject;

    let clipObjectRef = '';
    if (!this.subClipObject || !this.clipObject) {
      const {
        subClip: subClipObject,
        clip: clipObject,
        clipObjectRef: cObjectRef,
      } = this.getSubClip(subClipObjectRef ?? '');
      this.subClipObject = subClipObject;
      this.clipObject = clipObject;
      clipObjectRef = cObjectRef ?? '';
    }

    this.videoClip = constructorVideoClip;
    if (!this.videoClip) {
      this.videoClip = this.getVideoClip(clipObjectRef ?? '');
    }

    this.masterClip = constructorMasterClip;
    this.masterClipParams =
      constructorMasterClipParams === null
        ? null
        : constructorMasterClipParams ?? [];

    if (constructorMasterClipParams === undefined) {
      const masterClipObjectRef =
        this.subClipObject
          ?.querySelector('MasterClip')
          ?.getAttribute('ObjectURef') ?? '';
      const { bluePrintObjectRef } = this.getMasterClip(masterClipObjectRef);

      if (this.masterClip !== null) {
        this.masterClip = new XMLTrackItemMasterClip({
          trackItem: this,
          objectUID: masterClipObjectRef,
        });
      }

      const { componentsArray: masterVideoComponentChainComponentsArray } =
        this.getVideoComponentChain(bluePrintObjectRef ?? '');
      const { params: masterParams } = this.getVideoFilterComponent(
        masterVideoComponentChainComponentsArray[0]?.componentObjectRef ?? ''
      );

      this.masterClipParams = masterParams.map(({ param, paramObjectRef }) => {
        const videoComponentParam = this.getVideoComponentParam(
          paramObjectRef ?? ''
        );
        return { param, videoComponentParam };
      });
    }
  }

  public exists() {
    return !!this.parentDocument.findElementWithObjectId(
      ['TrackItem'],
      this.objectId
    );
  }

  public clone(
    {
      startTick,
      endTick,
      index,
      copiedVideoFilterComponents,
    }: {
      startTick?: string;
      endTick?: string;
      index: number;
      copiedVideoFilterComponents: string[];
    } = {
      index: 0,
      copiedVideoFilterComponents: ['Graphic Parameters'],
    }
  ) {
    // ===== GET ID =====
    const newTrackItemId = this.parentDocument.getNewObjectId();

    // ===== CLONE TRACK ITEM =====
    const newTrackItem = this.trackItem.cloneNodeWithNewId(newTrackItemId);
    newTrackItem.setAttribute('Index', index.toString());

    // ===== CLONE VIDEO CLIP TRACK ITEM =====
    const newVideoClipTrackItem =
      this.videoClipTrackItem?.cloneNodeWithNewId(newTrackItemId);

    // ===== UPDATE COMPONENT ID =====
    const newComponentId = this.parentDocument.getNewObjectId();
    const component = newVideoClipTrackItem?.querySelector(
      'ClipTrackItem ComponentOwner Components'
    );
    component?.setAttribute('ObjectRef', newComponentId);

    // ===== UPDATE SUBCLIP ID =====
    const newSubClipId = this.parentDocument.getNewObjectId();
    const subClip = newVideoClipTrackItem?.querySelector(
      'ClipTrackItem SubClip'
    );
    subClip?.setAttribute('ObjectRef', newSubClipId);

    // ===== UPDATE FILTERS ID =====
    const newVideoChainComponent =
      this.videoComponentChain?.cloneNodeWithNewId(newComponentId);

    const components =
      newVideoChainComponent?.querySelectorAll(
        'ComponentChain Components Component'
      ) ?? [];

    const newVideoFilterComponents = Array.from(components ?? [])
      .map(component => {
        const correspondingVideoFilterComponent =
          this.videoFilterComponents.find(
            vfc => vfc.objectId === component?.getAttribute('ObjectRef') ?? ''
          );

        if (
          !correspondingVideoFilterComponent ||
          !copiedVideoFilterComponents.includes(
            correspondingVideoFilterComponent.videoFilterComponentName
          )
        ) {
          component.remove();
          return undefined;
        }

        const newVideoFilterComponent =
          correspondingVideoFilterComponent.clone(component);

        return newVideoFilterComponent;
      })
      .filter(Boolean) as XMLVideoFilterComponent[];

    const newComponents =
      newVideoChainComponent?.querySelectorAll(
        'ComponentChain Components Component'
      ) ?? [];
    newComponents.forEach((component, index) => {
      component.setAttribute('Index', index.toString());
    });

    // ===== CLONE SUBCLIP =====
    const newSubClip = this.subClipObject?.cloneNodeWithNewId(newSubClipId);

    // ===== UPDATE SUBCLIP ID =====
    const newClipId = this.parentDocument.getNewObjectId();
    const clip = newSubClip?.querySelector('Clip');
    clip?.setAttribute('ObjectRef', newClipId);

    // ===== CLONE VIDEO CLIP =====
    const newVideoClip = this.videoClip?.cloneNodeWithNewId(newClipId);
    const clipIdObject = newVideoClip?.querySelector('ClipID');
    if (clipIdObject) clipIdObject.textContent = crypto.randomUUID();

    // ===== CREATE NEW TRACK ITEM =====
    const newTrackitem = new XMLTrackItem({
      trackItem: newTrackItem,
      track: this.parentTrack,
      videoClipTrackItem: newVideoClipTrackItem,
      component: component as Element,
      subClip: subClip as Element,
      videoComponentChain: newVideoChainComponent,
      videoFilterComponents: newVideoFilterComponents,
      subClipObject: newSubClip,
      clipObject: clip as Element,
      videoClip: newVideoClip,
      masterClipParams: this.masterClipParams,
      masterClip: this.masterClip,
      videoComponentChainComponentsArray: Array.from(newComponents).map(
        component => ({
          component,
          componentObjectRef: component?.getAttribute('ObjectRef') ?? '',
        })
      ),
    });

    if (startTick || endTick) {
      // ===== UPDATE TIMES =====
      newTrackitem.updateTimes({
        startTick,
        endTick,
      });
    }

    return newTrackitem;
  }

  public remove() {
    this.trackItem.remove();
    this.videoClipTrackItem?.remove();
    this.videoComponentChain?.remove();
    this.subClipObject?.remove();
    this.videoClip?.remove();
    this.videoFilterComponents.forEach(vfComponent => {
      vfComponent?.remove();
    });
  }

  public changeSource(newProjectItem?: XMLProjectItem) {
    if (!newProjectItem) {
      console.error(
        'Could not change the source of the track item because the new project item is undefined'
      );
      return;
    }

    const newMarkersId =
      newProjectItem.videoClip.markers.getAttribute('ObjectID') ?? '';
    const newSourceId = newProjectItem.videoClip.source.objectID ?? '';
    const newMasterClipId =
      newProjectItem.masterClip.getAttribute('ObjectUID') ?? '';

    const markers = this.videoClip?.querySelector('Markers');
    markers?.setAttribute('ObjectRef', newMarkersId);

    const source = this.videoClip?.querySelector('Source');
    source?.setAttribute('ObjectRef', newSourceId);

    const masterClip = this.subClipObject?.querySelector('MasterClip');
    masterClip?.setAttribute('ObjectURef', newMasterClipId);

    return this;
  }

  public updateTimes({
    startTick,
    endTick,
  }: {
    startTick?: string;
    endTick?: string;
  }) {
    if (startTick) {
      const start = this.videoClipTrackItem?.querySelector('Start');
      if (start) start.textContent = startTick;
    }
    if (endTick) {
      const end = this.videoClipTrackItem?.querySelector('End');
      if (end) end.textContent = endTick;
    }

    return this;
  }

  public updateVideoClipTimes({
    startTick,
    endTick,
  }: {
    startTick?: string;
    endTick?: string;
  }) {
    if (startTick) {
      const inPoint = this.videoClip?.querySelector('InPoint');
      if (inPoint) inPoint.textContent = startTick;
    }
    if (endTick) {
      const outPoint = this.videoClip?.querySelector('OutPoint');
      if (outPoint) outPoint.textContent = endTick;
    }

    return this;
  }

  public updateMogrtParams(params: { [key: string]: any }) {
    Object.entries(params).forEach(([paramName, value]) => {
      this.updateMogrtParam(paramName, value);
    });

    return this;
  }

  public updateMogrtParam(paramName: string, value: any) {
    const graphicParametersVideoFilterComponentName =
      localGraphicParametersName[CAPTIONS_XML_PROJECT_LOCALE];

    const param = this.videoFilterComponents
      ?.find(
        vfc =>
          vfc.videoFilterComponentName ===
          graphicParametersVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });
    const masterParam = this.masterClipParams?.find(
      ({ videoComponentParam }) => {
        return (
          videoComponentParam?.querySelector('Name')?.textContent === paramName
        );
      }
    );

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;
    const masterVideoComponentParam = masterParam?.videoComponentParam;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam,
      },
      value
    );

    return this;
  }

  public updateTransformParams(params: { [key: string]: any }) {
    Object.entries(params).forEach(([paramName, value]) => {
      this.updateTransformParam(paramName, value);
    });

    return this;
  }

  public updateTransformParam(paramName: string, value: any) {
    const motionVideoFilterComponentName =
      localTransformEffectName[CAPTIONS_XML_PROJECT_LOCALE];

    const param = this.videoFilterComponents
      ?.find(
        vfc => vfc.videoFilterComponentName === motionVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam: undefined,
      },
      value
    );

    return this;
  }

  public updateMotionParams(params: { [key: string]: any }) {
    Object.entries(params).forEach(([paramName, value]) => {
      this.updateMotionParam(paramName, value);
    });

    return this;
  }

  public updateMotionParam(paramName: string, value: any) {
    const motionVideoFilterComponentName =
      localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE];

    const param = this.videoFilterComponents
      ?.find(
        vfc => vfc.videoFilterComponentName === motionVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam: undefined,
      },
      value
    );

    return this;
  }

  public updateRoughenEdgeParam(paramName: string, value: any) {
    const motionVideoFilterComponentName =
      localRoughenEdgesEffectName[CAPTIONS_XML_PROJECT_LOCALE];

    const param = this.videoFilterComponents
      ?.find(
        vfc => vfc.videoFilterComponentName === motionVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam: undefined,
      },
      value
    );

    return this;
  }

  public updateSourceTextParam(value: PproSourceTextParam) {
    const textVideoFilterComponentName =
      localTextEffectName[CAPTIONS_XML_PROJECT_LOCALE];
    const paramName = 'Source Text';

    const param = this.videoFilterComponents
      ?.find(
        vfc => vfc.videoFilterComponentName === textVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });
    const masterParam = this.masterClipParams?.find(
      ({ videoComponentParam }) => {
        return (
          videoComponentParam?.querySelector('Name')?.textContent === paramName
        );
      }
    );

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;
    const masterVideoComponentParam = masterParam?.videoComponentParam;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam,
      },
      value
    );

    return this;
  }

  public updateTextParams(params: { [key: string]: any }) {
    Object.entries(params).forEach(([paramName, value]) => {
      this.updateTextParam(paramName, value);
    });

    return this;
  }

  public updateTextParam(paramName: string, value: any) {
    const textVideoFilterComponentName = 'Text';

    const param = this.videoFilterComponents
      ?.find(
        vfc => vfc.videoFilterComponentName === textVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });
    const masterParam = this.masterClipParams?.find(
      ({ videoComponentParam }) => {
        return (
          videoComponentParam?.querySelector('Name')?.textContent === paramName
        );
      }
    );

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;
    const masterVideoComponentParam = masterParam?.videoComponentParam;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam,
      },
      value
    );

    return this;
  }

  public updateOpacityParam(paramName: string, value: any) {
    const textVideoFilterComponentName =
      localOpacityEffectName[CAPTIONS_XML_PROJECT_LOCALE];

    const param = this.videoFilterComponents
      ?.find(
        vfc => vfc.videoFilterComponentName === textVideoFilterComponentName
      )
      ?.videoFilterComponentParams.find(({ paramName: vfName }) => {
        return vfName === paramName;
      });
    const masterParam = this.masterClipParams?.find(
      ({ videoComponentParam }) => {
        return (
          videoComponentParam?.querySelector('Name')?.textContent === paramName
        );
      }
    );

    if (!param) {
      console.log('PARAM NOT FOUND', paramName, value);
      return this;
    }

    const { paramComponent } = param;
    const masterVideoComponentParam = masterParam?.videoComponentParam;

    this.updateComponentParam(
      {
        videoComponentParam: paramComponent as Element,
        masterVideoComponentParam,
      },
      value
    );

    return this;
  }

  private updateComponentParam(
    {
      videoComponentParam,
      masterVideoComponentParam,
    }: {
      videoComponentParam: Element;
      masterVideoComponentParam: Element | null | undefined;
    },
    value: any
  ) {
    let updateFunction:
      | undefined
      | ((
          {
            videoComponentParam,
            masterVideoComponentParam,
          }: {
            videoComponentParam: Element;
            masterVideoComponentParam: Element | null | undefined;
          },
          value: any
        ) => void);

    switch (videoComponentParam?.tagName) {
      case 'ArbVideoComponentParam':
        updateFunction = this.updateArbVideoComponentParam;

        break;
      case 'VideoComponentParam':
        updateFunction = this.updateVideoComponentParam;

        break;
      case 'PointComponentParam':
        updateFunction = this.updatePointComponentParam;
        break;
    }

    if (updateFunction && videoComponentParam) {
      updateFunction(
        {
          videoComponentParam,
          masterVideoComponentParam,
        },
        value
      );
    }

    return;
  }

  private updateArbVideoComponentParam(
    {
      videoComponentParam,
      masterVideoComponentParam,
    }: {
      videoComponentParam: Element;
      masterVideoComponentParam: Element | null | undefined;
    },
    value: any
  ) {
    const startKeyframeValue =
      videoComponentParam.querySelector('StartKeyframeValue');

    let currentValue = startKeyframeValue?.textContent ?? undefined;
    if (!currentValue?.length && masterVideoComponentParam) {
      const masterStartKeyframeValue =
        masterVideoComponentParam.querySelector('StartKeyframeValue');
      currentValue = masterStartKeyframeValue?.textContent ?? '';
    }

    const newValue = updateComponentParam(
      videoComponentParam,
      value,
      currentValue
    );
    startKeyframeValue!.textContent = newValue;

    return;
  }

  private updateVideoComponentParam(
    {
      videoComponentParam,
      masterVideoComponentParam,
    }: {
      videoComponentParam: Element;
      masterVideoComponentParam: Element | null | undefined;
    },
    value: any
  ) {
    const startKeyframe = videoComponentParam.querySelector('StartKeyframe');
    const splittedValues = startKeyframe?.textContent?.split(',');
    let currentValue = splittedValues?.[1];

    if (!currentValue?.length && masterVideoComponentParam) {
      const masterStartKeyframe =
        masterVideoComponentParam.querySelector('StartKeyframe');
      const masterSplittedValues = masterStartKeyframe?.textContent?.split(',');
      currentValue = masterSplittedValues?.[1];
    }

    const newValue = updateComponentParam(
      videoComponentParam,
      value,
      currentValue
    );

    const newStartKeyframeValue = [
      splittedValues?.[0],
      newValue,
      ...(splittedValues?.slice(2) ?? []),
    ].join(',');

    startKeyframe!.textContent = newStartKeyframeValue;

    return;
  }

  private updatePointComponentParam(
    {
      videoComponentParam,
      masterVideoComponentParam,
    }: {
      videoComponentParam: Element;
      masterVideoComponentParam: Element | null | undefined;
    },
    value: any
  ) {
    const startKeyframe = videoComponentParam.querySelector('StartKeyframe');
    const splittedValues = startKeyframe?.textContent?.split(',');
    let currentValue = splittedValues?.[1];

    if (!currentValue?.length && masterVideoComponentParam) {
      const masterStartKeyframe =
        masterVideoComponentParam.querySelector('StartKeyframe');
      const masterSplittedValues = masterStartKeyframe?.textContent?.split(',');
      currentValue = masterSplittedValues?.[1];
    }

    const newValue = updateComponentParam(
      videoComponentParam,
      value,
      currentValue
    );

    const newStartKeyframeValue = [
      splittedValues?.[0],
      newValue,
      ...(splittedValues?.slice(2) ?? []),
    ].join(',');

    startKeyframe!.textContent = newStartKeyframeValue;

    return;
  }

  private getVideoClipTrackItem(objectID: string) {
    const videoClipTrackItem = this.parentDocument.findElementWithObjectId(
      ['VideoClipTrackItem'],
      objectID
    );

    const component = videoClipTrackItem?.querySelector(
      'ClipTrackItem ComponentOwner Components'
    );
    const componentObjectRef = component?.getAttribute('ObjectRef');

    const subClip = videoClipTrackItem?.querySelector('ClipTrackItem SubClip');
    const subClipObjectRef = subClip?.getAttribute('ObjectRef');

    return {
      videoClipTrackItem,
      component,
      componentObjectRef,
      subClip,
      subClipObjectRef,
    };
  }

  private getVideoComponentChain(objectID: string) {
    const videoComponentChain = this.parentDocument.findElementWithObjectId(
      ['VideoComponentChain'],
      objectID
    );

    const components = videoComponentChain?.querySelectorAll(
      'ComponentChain Components Component'
    );
    const componentsArray = Array.from(components ?? []).map(
      (component: Element) => {
        const componentObjectRef = component.getAttribute('ObjectRef') ?? '';

        return { component, componentObjectRef };
      }
    );

    return { videoComponentChain, componentsArray };
  }

  private getVideoFilterComponent(objectID: string) {
    const videoFilterComponent = this.parentDocument.findElementWithObjectId(
      ['VideoFilterComponent'],
      objectID
    );

    const queriedParams = videoFilterComponent?.querySelectorAll(
      'Component Params Param'
    );
    const params = Array.from(queriedParams ?? []).map(param => {
      const paramObjectRef = param.getAttribute('ObjectRef');

      const paramComponent = this.getVideoComponentParam(
        paramObjectRef ?? ''
      ) as Element;

      return { param, paramObjectRef, paramComponent };
    });

    return { videoFilterComponent, params };
  }

  private getVideoComponentParam(objectID: string) {
    const videoComponentParam = this.parentDocument.findElementWithObjectId(
      ['ArbVideoComponentParam', 'VideoComponentParam', 'PointComponentParam'],
      objectID
    );

    return videoComponentParam;
  }

  private getSubClip(objectID: string) {
    const subClip = this.parentDocument.findElementWithObjectId(
      ['SubClip'],
      objectID
    );

    const clip = subClip?.querySelector('Clip');
    const clipObjectRef = clip?.getAttribute('ObjectRef');

    return { subClip, clip, clipObjectRef };
  }

  private getVideoClip(objectID: string) {
    const videoClip = this.parentDocument.findElementWithObjectId(
      ['VideoClip'],
      objectID
    );

    return videoClip;
  }

  private getMasterClip(objectID: string) {
    const masterClip = this.parentDocument.findElementWithObjectId(
      ['MasterClip'],
      objectID
    );

    const bluePrintVideoComponentChain = masterClip?.querySelector(
      'BlueprintVideoComponentChain'
    );
    const bluePrintObjectRef =
      bluePrintVideoComponentChain?.getAttribute('ObjectRef');

    return { masterClip, bluePrintVideoComponentChain, bluePrintObjectRef };
  }

  private copyVideoFilterComponent({
    videoFilterComponentName,
    videoFilterComponent,
    videoFilterComponentParams,
  }: VideoFilterComponent): VideoFilterComponent {
    const newVideoFilterComponentId = this.parentDocument.getNewObjectId();
    const newVideoFilterComponent = videoFilterComponent?.cloneNodeWithNewId(
      newVideoFilterComponentId
    );

    const newVideoFilterComponentParams = videoFilterComponentParams.map(
      ({ param, paramName, videoComponentParam }) => {
        const originalParamId = param?.getAttribute('ObjectRef') ?? '';
        const newParamId = this.parentDocument.getNewObjectId();
        const newVideoComponentParam =
          videoComponentParam?.cloneNodeWithNewId(newParamId);
        const newParam = videoFilterComponent?.querySelector(
          `Param[ObjectRef="${originalParamId}"]`
        );
        newParam?.setAttribute('ObjectRef', newParamId);

        return {
          param: newParam,
          paramName,
          videoComponentParam: newVideoComponentParam,
        };
      }
    );

    return {
      videoFilterComponentName,
      videoFilterComponent: newVideoFilterComponent,
      videoFilterComponentParams: newVideoFilterComponentParams,
    };
  }

  public resize([sequenceWidth, sequenceHeight]: [number, number]) {
    const frameRectElement =
      this.videoClipTrackItem?.querySelector('FrameRect');

    if (!frameRectElement) return false;

    const newFrameRectTextContent = `0,0,${sequenceWidth},${sequenceHeight}`;

    frameRectElement.textContent = newFrameRectTextContent;

    return true;
  }
}

import React, { CSSProperties, HTMLAttributes } from 'react';

import css from './RadioButton.module.scss';
import FlexContainer from '../../molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';
import {
  InputLabel,
  InputLabelProps,
} from '../../molecules/InputLabel/InputLabel';

export type RadioButtonProps = {
  name: string;
  checked?: boolean;
  size?: number;
} & Omit<
  HTMLAttributes<HTMLInputElement>,
  'checked' | 'id' | 'value' | 'name'
> &
  InputLabelProps;

export const RadioButton = ({
  name,
  title,
  description,
  size = 20,
  checked = false,
  ...props
}: RadioButtonProps) => {
  return (
    <FlexContainer gap={Spacing.s1} alignItems="center">
      <div
        style={
          {
            '--size': `${size}px`,
          } as CSSProperties
        }
        className={css.container}
      >
        <input
          onClick={props.onClick}
          id={name}
          name={name}
          className={css.root}
          type="radio"
          checked={checked}
          {...props}
        />
        <div className={css.label}>
          <label className={css.dot} htmlFor={name} />
        </div>
      </div>
      {(title || description) && (
        <InputLabel title={title} description={description} />
      )}
    </FlexContainer>
  );
};

// returns the value between two numbers at a specified, decimal midpoint
export const lerp = (x: number, y: number, a: number) => {
  return x * (1 - a) + y * a;
};

export const clamp = (a: number, min = 0, max = 1) => {
  return Math.min(max, Math.max(min, a));
};

export const invlerp = (x: number, y: number, a: number) => {
  const divider = y - x === 0 ? 0.00000001 : y - x;

  return clamp((a - x) / divider);
};

export const range = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  a: number
) => {
  return lerp(x2, y2, invlerp(x1, y1, a));
};

export const roundToXDecimals = (value: number, decimals: number) => {
  return parseFloat(value.toFixed(decimals));
};

export const quantile = (arr: number[], q: number) => {
  const sorted = arr.sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

export const isCloseTo = (a: number, b: number, threshold = 0.1) => {
  return a - threshold <= b && b <= a + threshold;
};

export const ppcm = (a: number, b: number, ...others: number[]) => {
  const numArray = [a, b, ...others].filter(num => num !== 0 && !isNaN(num));
  if (numArray.some(num => num > Math.floor(num)))
    console.warn(
      'ppcm: at least one number is not an integer, the result may be infinity',
      numArray
    );
  return numArray.reduce((a, b) => (a * b) / pgcd(a, b));
};

export const pgcd = (a: number, b: number): number => {
  return b === 0 ? a : pgcd(b, a % b);
};

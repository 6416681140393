import * as React from 'react';

import css from './ContextualMenu.module.css';

export type ContextualMenuProps = {
  isVisible: boolean;
  top: number;
  left: number;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
};

export const ContextualMenu = ({
  isVisible,
  top,
  left,
  setIsHovering,
  children,
}: ContextualMenuProps) => {
  const menuRef = React.useRef<HTMLDivElement | null>(null);

  // Using getBoundingClientRect() to avoid rounding issues
  const elementWidth = menuRef.current
    ? menuRef.current.getBoundingClientRect().width
    : 0;
  const elementHeight = menuRef.current
    ? menuRef.current.getBoundingClientRect().height
    : 0;

  const handleOnMouseEnter = () => {
    setIsHovering(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={css.mainContainer}
      ref={menuRef}
      style={{
        visibility: `${isVisible ? 'visible' : 'hidden'}`,
        top: `${top - elementHeight * 1.15}px`,
        left: `${left - elementWidth / 2}px`,
      }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {children}
    </div>
  );
};

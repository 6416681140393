import UnsupportedScenarioInfoText from '@autocut/components/modals/UnsupportedScenarioInfoText/UnsupportedScenarioInfoText';
import { AutocutModes } from '@autocut/enums/modes.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { useLogger } from '@autocut/hooks/useLogger';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import React from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { InfoText } from '../../atoms/InfoText';
import Progress from '../../atoms/Progress/Progress';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import { LuMouse } from 'react-icons/lu';

import css from './InvalidSelectionScreen.module.scss';

const NB_CLIPS_WARNING_THRESHOLD = 100;

export type InvalidSelectionScreenProps = {
  titleId: string;
  titleDefaultMessage: string;
  texts: {
    id: string;
    defaultMessage: string;
  }[];
  linkId?: string | undefined;
  customCta?: React.ReactNode;
  selectionImage?: string;
};

export const InvalidSelectionScreen = ({
  texts,
  titleDefaultMessage,
  titleId,
  linkId = undefined,
  customCta = undefined,
  selectionImage = undefined,
}: InvalidSelectionScreenProps) => {
  const intl = useIntl();
  const {
    progressPercent,
    nbClipTotal,
    isParsingSequence,
    unsupportedScenario,
    mode,
  } = useAutoCutStore(state => ({
    progressPercent: state.sequence.parsingProcess.progress.percentage,
    nbClipTotal: state.sequence.parsingProcess.progress.max,
    isParsingSequence: state.sequence.parsingProcess.isLoading,
    unsupportedScenario: state.sequence.errors.unsupportedScenario,
    mode: state.ui.process.mode,
  }));

  const { logMessage } = useLogger('InvalidSelectionScreen');

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="space-between"
      className={css.relativeContainer}
    >
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={css.noClipContainer}
      >
        <FlexContainer
          justifyContent="center"
          gap={12}
          className={css.noClipTitle}
        >
          <div className={css.scissorsCircle}>
            <LuMouse size={24} />
          </div>
          <div className={css.title}>
            <TranslatedMessage
              id={titleId}
              defaultMessage={titleDefaultMessage}
            />
          </div>
        </FlexContainer>

        {(mode.id === AutocutModes.Ai.id ||
          mode.id === AutocutModes.Legacy.id) &&
          nbClipTotal > NB_CLIPS_WARNING_THRESHOLD && (
            <InfoText
              descriptionId="big_sequence_info_message"
              descriptionDefault="It looks like you have a lot of clips in your sequence !"
            />
          )}
        <FlexContainer
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          className={css.noClipText}
        >
          {texts.map(text => (
            <React.Fragment key={text.id}>
              <TranslatedMessage
                id={text.id}
                defaultMessage={text.defaultMessage}
              />
            </React.Fragment>
          ))}

          {customCta}
        </FlexContainer>
        {unsupportedScenario ? (
          <UnsupportedScenarioInfoText
            unsupportedScenarioId={unsupportedScenario}
          />
        ) : null}
        {isParsingSequence ? (
          <Progress
            progress={progressPercent}
            width="80%"
            height="16px"
            innerText={intl.formatMessage({
              id: 'text_noClip_loading_bar',
              defaultMessage: 'Loading - Sequence analysis in progress',
            })}
          />
        ) : null}

        {selectionImage && (
          <img
            src={selectionImage}
            alt="selection image"
            className={css.noClipImage}
          />
        )}

        {linkId && (
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={css.noClipText}
          >
            <FlexContainer justifyContent="space-between" alignItems="center">
              <TranslatedMessage
                id="text_noClip_tuto"
                defaultMessage="Need help ? Feel free to watch our"
              />
              <a
                className={'tuto_link'}
                onClick={() => {
                  logMessage(
                    logLevel.info,
                    'Go to youtube tuto from no clip panel.'
                  );
                  openLinkInBrowser(
                    intl.formatMessage({
                      id: linkId,
                      defaultMessage:
                        'https://www.youtube.com/watch?v=7MEK3rZQDHI',
                    })
                  );
                }}
              >
                <TranslatedMessage
                  id="text_noClip_tuto_link"
                  defaultMessage="video tutorial"
                />
              </a>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

import { v4 as uuidv4 } from 'uuid';
import { AutoCutState } from '@autocut/utils/zustand';

export const addCameraReducer = (state: AutoCutState) => {
  return [
    ...state.ui.parameters.podcast.cameras,
    {
      id: uuidv4(),
      speakerIds: [],
      label: '',
      value: '',
    },
  ];
};

import {
  ExportDescriptorTrackWithClips,
  ExportDescriptorTrackWithIntervals,
  ExportResult,
  ExportSequenceValue,
} from '@autocut/types/ExportedAudio';

export const isAllSequenceExport = (
  sequence: ExportSequenceValue
): sequence is 'all' => {
  return sequence === 'all';
};

export const isAllSequenceResult = (
  audioInfos: ExportResult<ExportSequenceValue>
): audioInfos is ExportResult<'all'> => {
  const sequence = audioInfos.sequence;
  return (
    sequence &&
    typeof sequence === 'object' &&
    'start' in sequence &&
    'end' in sequence &&
    'path' in sequence
  );
};

export const isSelectedSequenceExport = (
  sequence: ExportSequenceValue
): sequence is 'selected' => {
  return sequence === 'selected';
};

export const isSequenceWithIntervalsExport = (
  sequence: ExportSequenceValue
): sequence is number[][] => {
  return Array.isArray(sequence);
};

export const isSelectedOrWithIntervalsSequenceResult = (
  audioInfos: ExportResult<ExportSequenceValue>
): audioInfos is ExportResult<'selected' | number[][]> => {
  const sequence = audioInfos.sequence;

  return !('audioTracks' in sequence) && Array.isArray(sequence);
};

const isAudioTrackExport = (
  sequence: ExportSequenceValue
): sequence is {
  audioTracks:
    | 'all'
    | ExportDescriptorTrackWithIntervals[]
    | ExportDescriptorTrackWithClips[];
} => {
  return !Array.isArray(sequence) && typeof sequence === 'object';
};

export const isAllAudioTracksExport = (
  sequence: ExportSequenceValue
): sequence is { audioTracks: 'all' } => {
  return isAudioTrackExport(sequence) && sequence.audioTracks === 'all';
};

export const isAllOrClipsAudioTrackResult = (
  audioInfos: ExportResult<ExportSequenceValue>
): audioInfos is ExportResult<
  { audioTracks: 'all' } | { audioTracks: ExportDescriptorTrackWithClips[] }
> => {
  const sequence = audioInfos.sequence;

  return (
    'audioTracks' in sequence &&
    Array.isArray(sequence.audioTracks) &&
    'clips' in sequence.audioTracks[0]
  );
};

export const isAudioTracksWithIntervalsExport = (
  sequence: ExportSequenceValue
): sequence is { audioTracks: ExportDescriptorTrackWithIntervals[] } => {
  return (
    isAudioTrackExport(sequence) &&
    Array.isArray(sequence.audioTracks) &&
    Object.keys(sequence.audioTracks[0]).includes('intervalsToExport')
  );
};

export const isAudioTracksWithIntervalsResult = (
  audioInfos: ExportResult<ExportSequenceValue>
): audioInfos is ExportResult<{
  audioTracks: ExportDescriptorTrackWithIntervals[];
}> => {
  const sequence = audioInfos.sequence;

  return (
    'audioTracks' in sequence &&
    Array.isArray(sequence.audioTracks) &&
    !('clips' in sequence.audioTracks[0])
  );
};

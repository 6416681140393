import { fs, path } from '@autocut/lib/cep/node';
import { autocutStoreVanilla } from '../zustand';
import { getResourcesFolder } from '../resourceManager.utils';
import { FormattedBRollVideo } from '@autocut/types/BRolls';
import sanitize from 'sanitize-filename';
import { filterEmptyElements } from '../array.utils';

enum VideoMimeTypeToExtension {
  'video/x-msvideo' = 'avi',
  'video/mp4' = 'mp4',
  'video/mpeg' = 'mpeg',
  'video/webm' = 'webm',
  'video/ogg' = 'ogv',
  'video/mp2t' = 'ts',
  'video/3gpp' = '3gp',
  'video/3gpp2' = '3g2',
}

export const downloadPexelVideos = async (
  videos: Omit<FormattedBRollVideo, 'path'>[]
): Promise<FormattedBRollVideo[]> => {
  const sequence = autocutStoreVanilla().sequence.infos;
  if (!sequence) throw new Error('No sequence');

  const resoucesFolder = getResourcesFolder();

  const downloadFolder = path.join(
    resoucesFolder,
    'brolls',
    `${sequence.settings.name}_${sequence.id}`
  );

  fs.mkdirSync(downloadFolder, { recursive: true });

  const videosWithPath = await Promise.all(
    videos.map(async video => {
      let filePath = '';

      try {
        await fetch(video.downloadUrl, { mode: 'no-cors' }).then(async res => {
          let filename = video.id.toString();
          let fileExtension = 'mp4';
          const contentDisposition = res.headers.get('content-disposition');
          const contentType = res.headers.get('content-type');
          if (contentDisposition)
            filename = `${video.id.toString()}-${contentDisposition
              .split('filename=')[1]
              .split(';')[0]
              .replace(/"/g, '')}`.replace(/[^a-zA-Z0-9]/g, '_');
          if (contentType && (VideoMimeTypeToExtension as any)[contentType])
            fileExtension = (VideoMimeTypeToExtension as any)[contentType];

          filename = sanitize(filename + '.' + fileExtension);
          filePath = path.join(downloadFolder, filename);

          if (fs.existsSync(filePath)) {
            return {
              ...video,
              path: filePath,
            };
          }

          const arrayBuffer = await res.arrayBuffer();
          const buffer = Buffer.from(arrayBuffer);

          res.headers.get('content-disposition');

          try {
            fs.writeFileSync(filePath, buffer);
          } catch (error) {
            console.error('Error writing file:', error);
          }
        });

        return {
          ...video,
          path: filePath,
        };
      } catch (e) {
        console.error('Fail during b-roll fetching: ', JSON.stringify(e));
        return null;
      }
    })
  );

  return filterEmptyElements(videosWithPath);
};

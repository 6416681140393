import { Button } from '@autocut/components/atoms';
import { ModeLayout } from '@autocut/components/atoms/ModeLayout/ModeLayout';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect } from 'react';

import { InvalidSelectionScreen } from '@autocut/components/partials/InvalidSelectionScreen/InvalidSelectionScreen';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { TranscriptLanguageStep } from '@autocut/components/steps/TranscriptLanguageStep/TranscriptLanguageStep';
import { AlgorithmSelectStep } from './Parts/AlgorithmSelectStep';
import { useHandleProcessTranscript } from '@autocut/hooks/useHandleProcessTranscript';

import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const RepeatLanguage = () => {
  const { selectionInfos, isProviderAvailable } = useAutoCutStore(state => ({
    selectionInfos: state.sequence.infos,
    isProviderAvailable: state.ui.currentTranscription.isProviderAvailable,
  }));

  const { handleProcessTranscript, error, loading } =
    useHandleProcessTranscript('repeat');

  useEffect(() => {
    // On mounting, we enable selection detection.
    // As we disable it when processing the transcript, we have to re-enable it here in case of the user goes back to the previous step.
    setAutocutStore('ui.process.isPaused', false);
  }, []);

  const nbFiles = React.useMemo(
    () =>
      selectionInfos
        ? Array.from(
            new Set(
              selectionInfos.audioTracks.flatMap(track =>
                track.selectedClips.map(clip => clip.path)
              )
            )
          ).length
        : 0,
    [selectionInfos]
  );

  return !!selectionInfos && selectionInfos.nbClipsAudio > 0 ? (
    <ModeLayout
      footer={
        <ModeFooter
          errorId={error ?? isProviderAvailable.message}
          renderButton={(isLoading, isDisabled) => (
            <Button
              onClickFunction={() => handleProcessTranscript()}
              disabled={
                isDisabled || nbFiles <= 0 || !isProviderAvailable.isAvailable
              }
              isLoading={loading || isLoading}
            >
              <TranslatedMessage
                id="repeat_process_transcript_button"
                defaultMessage="Go to repeat editor"
              />
            </Button>
          )}
        />
      }
    >
      <TranscriptLanguageStep modeId="repeat" />
      <AlgorithmSelectStep />

      <SelectedClipsStep />
    </ModeLayout>
  ) : (
    <InvalidSelectionScreen
      titleId="repeat_invalid_selection_screen_title"
      titleDefaultMessage="Select a clip in your timeline"
      texts={[
        {
          id: 'repeat_invalid_selection_screen_text1',
          defaultMessage:
            'To use AutoCut Repeat, select an audio clip and optionally a video clip in your timeline.',
        },
        {
          id: 'text_noClip_no_selection',
          defaultMessage:
            'If the selected clips do not appear, try restarting AutoCut.',
        },
      ]}
      selectionImage={silenceSelectionImage}
    />
  );
};

export default RepeatLanguage;

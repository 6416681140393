import { evalTS } from '@autocut/lib/utils/bolt';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manuallyRefreshSequence } from '@autocut/utils/sequence.utils';
import { IntlShape } from 'react-intl';
import { addXp } from '../game/addXp';
import { handleProcessBase } from '../process/handleProcessBase';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';
import importCaptionsThroughXml from './xml/import.xml.captions.utils';
import { changeMessage } from '../cutButton/changeMessage';

const addCaptions = async (intl: IntlShape) => {
  try {
    const chunks = autocutStoreVanilla().onGoingProcess.captionChunks;
    const audioTracks = autocutStoreVanilla().sequence.infos?.audioTracks;
    const startTimes = audioTracks
      ?.filter(track => !!track.selectedClips.length)
      .map(track => track.start);
    const timeOffset = startTimes?.length
      ? startTimes.reduce((min, cur) => Math.min(min, cur))
      : 0;

    if (!chunks) {
      throw new IncrementalError('No chunks found', 'addCaptions');
    }
    const chunksTotal = chunks.length;

    changeMessage(
      intl,
      'progress_captions_modal_message_initialization',
      'Creating captions... '
    );
    const onProgress = (progress: number) => {
      changeMessage(
        intl,
        'progress_captions_modal_message',
        'Creating captions... {progress}%',
        { progress }
      );
    };
    await importCaptionsThroughXml(chunks, timeOffset, intl, onProgress);

    const xpGained = chunksTotal * 1.66;
    await addXp(xpGained);
    setAutocutStore('game.level.xpGained', xpGained);
  } catch (err: any) {
    throw new IncrementalError(err, 'addCaptions');
  }
};

export const handleCaptionsProcess = async (intl: IntlShape) => {
  await evalTS('setPlayerPosition', 0);

  await handleProcessBase({
    executeProcess: addCaptions,
  })(intl);

  await manuallyRefreshSequence();
};

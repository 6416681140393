import { CaptionChunk, CaptionChunkHighlight } from '@autocut/types/Captions';
import { splitTextIntoLines } from '@autocut/utils/captions/canvas/canvas.utils';
import {
  PPRO_TEXT_LINE_BREAK,
  getHighlightFromWord,
} from '@autocut/utils/captions/processCaptionsChunksState.utils';
import { getValue } from '@autocut/utils/captions/utils';

type OnTextChangeProps = {
  newValue: string;
  highlight: CaptionChunkHighlight;
  index: number;
  selectedChunk: CaptionChunk;
  selectedChunkIndex: number;
  chunks: CaptionChunk[];
  allowedWidth: number;
  fontParameters: any;
  params: { formating: { uppercase: boolean } };
};

export const onTextChange = ({
  newValue,
  highlight,
  index,
  selectedChunk,
  selectedChunkIndex,
  chunks,
  allowedWidth,
  fontParameters,
  params,
}: OnTextChangeProps) => {
  // Update highlight with new word
  const newHighlight = {
    ...highlight,
    word: newValue,
    confidence: 1,
  };

  // Duplicate Highlights
  const newHighlights = [...selectedChunk.highlight];

  // Replace the old highlight with the new one
  newHighlights[index] = newHighlight;
  // Update chunk lines
  const lineStartIndex = selectedChunk.lines
    .slice(0, highlight.indexLine)
    .reduce(
      (acc, line) =>
        acc + line.map(word => getValue(word)).join(' ').length + 1,
      0
    );
  const wordLine = selectedChunk.lines[highlight.indexLine];
  const wordLineIndex = wordLine.reduce(
    (acc, word, i) =>
      acc.index !== null
        ? acc
        : acc.nbChars + lineStartIndex === highlight.indexStart
        ? { index: i, nbChars: -1 }
        : {
            index: null,
            nbChars: acc.nbChars + getValue(word).length + 1,
          },
    { index: null as null | number, nbChars: 0 }
  ).index!;
  wordLine[wordLineIndex] = {
    ...wordLine[wordLineIndex],
    word: newValue,
    punctuated_word: newValue,
    confidence: 1,
  };

  // Re-process chunk with new lines
  const allWords = selectedChunk.lines.flat();
  const updatedLines = splitTextIntoLines(
    selectedChunk.lines
      .flatMap(line => line.map(w => w.word.replace(/\s/g, ' ')))
      .join(' '),
    {
      font: fontParameters,
      maxWidth: allowedWidth,
      uppercase: params.formating.uppercase,
    }
  );
  const updatedLinesPerWords = updatedLines
    .map(line => allWords.slice(line.startIndex, line.endIndex + 1))
    .filter(words => words.length > 0);

  let lastEnd = selectedChunk?.highlight[0]?.start || 0;
  let lastIndexEnd = -2;

  const updatedChunk: CaptionChunk = {
    ...selectedChunk,
    nbLines: updatedLinesPerWords.length,
    lines: updatedLinesPerWords,
    text: updatedLinesPerWords
      .map(words => words.map(word => getValue(word)).join(' '))
      .join(PPRO_TEXT_LINE_BREAK),
    highlight: updatedLinesPerWords.flatMap((line, lineIndex) => {
      let currentLine = '';
      return line
        .map(word => {
          if (!word) return null;
          const res = getHighlightFromWord(
            word,
            lastEnd,
            lastIndexEnd,
            currentLine,
            lineIndex
          );
          currentLine = currentLine + res.word + ' ';
          lastEnd = res.end;
          lastIndexEnd = res.indexEnd;
          return res;
        })
        .filter(Boolean) as CaptionChunkHighlight[];
    }),
  };

  const newChunks = chunks.map((chunk, index) =>
    index === selectedChunkIndex ? updatedChunk : chunk
  );

  return newChunks;
};

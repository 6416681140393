const styleConfig = {
  primaryColor: '#E25D2A',
  buttonSizes: {
    sm: '120',
    md: '155',
    lg: '280',
  },
  compactButtonHeight: {
    sm: '30',
    md: '40',
    lg: '50',
  },
  inputSizes: {
    padding_sm: '0.2rem 0.4rem !important',
    padding_md: '0.5rem 1rem',
    padding_lg: '0.8rem 1.6rem',
    minWidth_sm: '1.8rem !important',
    minWidth_md: '3.6rem !important',
    minWidth_lg: '100%',
  },
};

export default styleConfig;

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';
import { MdReplay } from 'react-icons/md';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

export const BackupModal = () => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'backup_modal_title',
        defaultMessage: 'AutoCut Backup',
      })}
      icon={<MdReplay size={24} />}
    >
      <FlexContainer flexDirection="column" gap={8}>
        <p>
          <TranslatedMessage
            id="backup_modal_text_1"
            defaultMessage='Before any AutoCut process, a backup is made. You can find these backups in the "AutoCut-Backup" folder.'
          />
        </p>
        <p>
          <TranslatedMessage
            id="backup_modal_text_2"
            defaultMessage="You can use them as you wish."
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};

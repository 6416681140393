import { quantile } from '../math.utils';
import { getParametersForMode } from '../parameters.utils';
import { autocutStoreVanilla } from '../zustand';
import { RMS_INTERVAL_DURATION } from './computeRMS';
import {
  computeDynamicZoomIntervals,
  computeSmoothZoomIntervals,
} from './computeZoomTypeIntervals';
import { AudioWavData } from './getAudioClipsWavData';
import { ZoomInterval } from './getZoomIntervals';
import {
  stitchZoomIntervals,
  stretchZoomIntervals,
} from './zoomIntervals.utils';

export const computeBestZoomIntervals = async (
  clipsAudioData: AudioWavData[]
) => {
  const autocutStates = autocutStoreVanilla();

  const selectionInfos = autocutStates.sequence.infos;
  if (!selectionInfos) throw new Error('Did not retrieve sequence infos');

  const {
    totalZoomPercentage,
    dynamicZoomPercentage,
    smoothZoomPercentage,
    zoomTypes,
    constraintZoom,
  } = getParametersForMode('zoom');
  const totalRMSValue = clipsAudioData.reduce<number[]>(
    (rmsValues, clipAudioData) => rmsValues.concat(clipAudioData.rmsValues),
    []
  );
  const startingAggressivityFactor = quantile(totalRMSValue, 1);
  const effectiveDynamicZoomCoverage =
    dynamicZoomPercentage * totalZoomPercentage;
  const effectiveSmoothZoomCoverage =
    smoothZoomPercentage * totalZoomPercentage;

  let bestDynamicZoomIntervals: ZoomInterval[] = [];
  if (zoomTypes.DYNAMIC) {
    bestDynamicZoomIntervals = await computeDynamicZoomIntervals({
      clipsAudioData,
      effectiveDynamicZoomCoverage,
      startingAggressivityFactor,
      totalRMSValue,
    });
  }

  let bestSmoothZoomIntervals: ZoomInterval[] = [];
  if (zoomTypes.SMOOTH) {
    const clipsAudioDataDynamicZoomExcluded = excludeZoomIntervals(
      clipsAudioData,
      bestDynamicZoomIntervals
    );

    bestSmoothZoomIntervals = await computeSmoothZoomIntervals({
      clipsAudioData: clipsAudioDataDynamicZoomExcluded,
      effectiveSmoothZoomCoverage,
      startingAggressivityFactor,
      totalRMSValue,
    });
  }

  //Merge intervals zoom et nettoyage final
  let totalZoomIntervals = [
    ...bestDynamicZoomIntervals,
    ...bestSmoothZoomIntervals,
  ].sort((intervalA, intervalB) => intervalA.start - intervalB.start);
  if (constraintZoom)
    totalZoomIntervals = stretchZoomIntervals(totalZoomIntervals);
  totalZoomIntervals = stitchZoomIntervals(totalZoomIntervals);

  return totalZoomIntervals;
};

const excludeZoomIntervals = (
  audioWavDatas: AudioWavData[],
  zoomIntervals: ZoomInterval[]
) => {
  const excludedAudioWavData: AudioWavData[] = [];
  for (const audioWavData of audioWavDatas) {
    const indexInterval = zoomIntervals.findIndex(
      interval =>
        interval.start >= audioWavData.startTimeline &&
        interval.end <= audioWavData.endTimeline
    );
    if (indexInterval === -1) {
      excludedAudioWavData.push(audioWavData);
    } else {
      excludedAudioWavData.push(
        ...wavDataDivision(audioWavData, zoomIntervals)
      );
    }
  }

  return excludedAudioWavData;
};

/**
 * L'idée derrière cette fonction est d'exclure des intervalles à un AudioWavData.
 * On exclue les intervalles récursivement : une fois un AudioWavData séparé, on sait que la partie de gauche n'est
 * pas couverte par un zoom et on rappelle la fonction avec la partie de droite jusqu'à ce que tout l'audio ai été couvert.
 * @param wavData
 * @param zoomInterval
 * @returns Un tableau de AudioWavData dont les intervalles ne couvrent pas ceux des zooms passés en paramètre
 */
const wavDataDivision = (
  wavData: AudioWavData,
  zoomInterval: ZoomInterval[]
): AudioWavData[] => {
  const interval = zoomInterval.find(
    interval =>
      interval.start >= wavData.startTimeline &&
      interval.end <= wavData.endTimeline
  );
  if (!interval) return [wavData];
  else {
    const indexStartRms = Math.floor(
      (interval.start - wavData.startTimeline) / RMS_INTERVAL_DURATION
    );

    let indexEndRms;
    if (interval.end === wavData.endTimeline)
      indexEndRms = wavData.rmsValues.length - 1;
    else
      indexEndRms = Math.floor(
        (interval.end - wavData.startTimeline) / RMS_INTERVAL_DURATION
      );

    // Si l'intervalle de zoom est en plein milieu
    const leftWavData = indexStartRms
      ? [
          {
            startTimeline: wavData.startTimeline,
            endTimeline: interval.start,
            rmsValues: wavData.rmsValues.slice(0, indexStartRms),
            sampleRate: 48000,
          },
        ]
      : [];
    const rightWavData =
      interval.end === wavData.endTimeline
        ? []
        : wavDataDivision(
            {
              startTimeline: interval.end,
              endTimeline: wavData.endTimeline,
              rmsValues: wavData.rmsValues.slice(indexEndRms),
              sampleRate: 48000,
            },
            zoomInterval
          );
    return [...leftWavData, ...rightWavData];
  }
};

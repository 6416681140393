import { fs } from '@autocut/lib/cep/node';
import logLevel from '@autocut/types/logLevel.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { manageError } from '@autocut/utils/manageError';
import { autocutApi, http } from './http.utils';
import { logger } from './logger';
import { setAutocutStore } from './zustand';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('Files', level, message, objects);
};

export type FileMetadata = Record<string, string | number>;

export const convertMetadataToHeaders = (metadata: FileMetadata) =>
  Object.keys(metadata).reduce(
    (acc, value) => ({
      ...acc,
      [`x-goog-meta-${value}`]: metadata[value].toString(),
    }),
    {}
  );

export const uploadFile = async (
  fileData: string | Buffer | Blob,
  startUrl: string,
  callBack: (percentage: number) => void,
  forceMultipart = false,
  contentType = 'text/plain',
  metadata?: FileMetadata
) => {
  let size: number;
  if (typeof fileData === 'string') {
    if (!fs.existsSync(fileData)) {
      throw new Error('File not found');
    }
    size = fs.statSync(fileData).size;
  } else {
    size = getFileSize(fileData);
  }

  const startRes = await http.post(
    startUrl,
    {},
    {
      headers: {
        'x-goog-resumable': 'start',
        'Content-Type': contentType,
        ...convertMetadataToHeaders(metadata || {}),
      },
    }
  );

  const uploadUrl = startRes.headers.location;

  if (!uploadUrl) {
    throw new Error('Upload URL not found');
  }

  if (bytesToMb(size) < 100 && !forceMultipart) {
    return singleUpload(fileData, uploadUrl);
  }
  return multipartUpload(fileData, uploadUrl, size, callBack);
};

const readFileToBuffer = async (
  filePath: string,
  size: number
): Promise<Buffer> => {
  const chunkSize = mbToBytes(100);
  const buffers: Buffer[] = [];
  let bytesRead = 0;

  await new Promise<void>((resolve, reject) => {
    fs.open(filePath, 'r', async (err: any, fd: any) => {
      if (err) {
        reject(err);
        return;
      }

      while (bytesRead < size) {
        const bufferSize = Math.min(chunkSize, size - bytesRead);
        const buffer = Buffer.alloc(bufferSize);
        const bytes = await new Promise<number>((resolveRead, rejectRead) => {
          fs.read(
            fd,
            buffer,
            0,
            bufferSize,
            bytesRead,
            (err: any, bytesRead: number) => {
              if (err) {
                rejectRead(err);
                return;
              }
              resolveRead(bytesRead);
            }
          );
        });
        bytesRead += bytes;
        buffers.push(buffer);
      }
      fs.close(fd, (err: any) => {
        if (err) {
          reject(err);
          return;
        }
        resolve();
      });
    });
  });

  return Buffer.concat(buffers);
};

const multipartUploadBuffer = async (
  buffer: Buffer,
  uploadUrl: string,
  size: number,
  callBack: (percentage: number) => void
) => {
  const chunkSize = mbToBytes(100);
  let start = 0;

  while (start < size) {
    const end = Math.min(size, start + chunkSize);
    const chunk = buffer.slice(start, end);

    try {
      const response = await http.put(uploadUrl, chunk, {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Range': `bytes ${start}-${end - 1}/${size}`,
        },
      });

      if (response.status !== 200) {
        throw new Error(`Unexpected status code: ${response.status}`);
      }

      start = end;
      const percentage = Math.round((start / size) * 100);
      callBack(percentage);
    } catch (error: any) {
      if (error.response?.status === 308 || error.status === 308) {
        const range = error.response.headers.range;
        start = parseInt(range.split('-')[1]) + 1;
      } else {
        throw error;
      }
    }
  }
};

const blobToBuffer = async (blob: Blob): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        resolve(Buffer.from(reader.result));
      } else {
        reject(new Error('Failed to convert Blob to buffer'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read Blob as data URL'));
    };

    reader.readAsArrayBuffer(blob);
  });
};

const multipartUpload = async (
  fileData: string | Blob | Buffer,
  uploadUrl: string,
  size: number,
  callBack: (percentage: number) => void
) => {
  const buffer =
    typeof fileData === 'string'
      ? await readFileToBuffer(fileData, size)
      : fileData instanceof Blob
      ? await blobToBuffer(fileData)
      : fileData;
  await multipartUploadBuffer(buffer, uploadUrl, size, callBack);
};

const singleUpload = async (
  fileData: string | Blob | Buffer,
  uploadUrl: string
) => {
  let fileContent: string | Buffer;
  if (typeof fileData === 'string') {
    if (!fs.existsSync(fileData)) {
      throw new Error('File not found');
    }
    fileContent = fs.readFileSync(fileData, 'utf8');
  } else if (fileData instanceof Blob) {
    const reader = new FileReader();
    if (!reader.result) {
      throw new Error('Failed to read Blob as data URL');
    }
    fileContent = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsArrayBuffer(fileData);
    });
  } else {
    fileContent = fileData;
  }

  await http.put(uploadUrl, fileContent, {
    headers: { 'Content-Type': 'application/octet-stream' },
  });
};

const bytesToMb = (bytes: number) => {
  return bytes / 1000 / 1000;
};

const mbToBytes = (mb: number) => {
  return mb * 1000 * 1000;
};

const getFileSize = (fileData: Buffer | Blob): number => {
  if (fileData instanceof Buffer) {
    return fileData.byteLength;
  } else {
    return fileData.size;
  }
};

export const uploadAndGetSignedUrl = async (
  fileName: string,
  filePath: string,
  uploadProgressCallback: (percent: number) => void = (percent: number) =>
    setAutocutStore('onGoingProcess.progress', percent),
  metadata?: FileMetadata
) => {
  let signedUrl;
  let uploadUrlResponse;
  try {
    const fileNameWithTimestamp = `${Date.now()}_${fileName}`;
    uploadUrlResponse = await autocutApi.post(`/bucket/uploadUrl`, {
      fileName: fileNameWithTimestamp,
      metadata: convertMetadataToHeaders(metadata || {}),
    });
    const uploadUrl = uploadUrlResponse.data;

    await uploadFile(
      filePath,
      uploadUrl,
      percentage => uploadProgressCallback(percentage),
      true,
      'audio/x-wav',
      metadata
    );
    const signedUrlResponse = await autocutApi.post(`/bucket/fileUrl`, {
      fileName: fileNameWithTimestamp,
    });
    signedUrl = signedUrlResponse.data;
    return signedUrl;
  } catch (error: any) {
    const formattedError = new IncrementalError(error, 'uploadAndGetSignedUrl');
    logMessage(logLevel.error, `Error while uploading audio file`, {
      error: formattedError,
    });

    manageError({
      error: formattedError,
    });

    throw error;
  }
};

export const downloadFile = async (
  signedUrl: string,
  outputFilePath: string,
  replace = false
) => {
  const { data: fileContent } = await http.get(signedUrl, {
    responseType: 'arraybuffer',
  });

  try {
    try {
      if (fs.statSync(outputFilePath) && replace) {
        fs.unlinkSync(outputFilePath); // delete file if exists
      } else if (!replace) {
        return;
      }
    } catch (e) {
      //Nothing, the file does not exist so we do not delete it
    }
    fs.writeFileSync(outputFilePath, Buffer.from(fileContent));
  } catch (e) {
    throw e;
  }
};

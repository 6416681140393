import { CaptionCanvasObjectHook } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionHooks.type';
import { useCanvasObject } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/captionDrawHooks.utils';
import { useDrawText } from '@autocut/modes/captions/Steps/Customization/Parts/ExampleCaption/hooks/Draw/Text/useDrawText';

export const useTextCanvasObject: CaptionCanvasObjectHook = args => {
  const drawReturn = useDrawText(args);

  return useCanvasObject({
    name: 'highlightText',
    paths: {
      x: 'ui.parameters.caption.position.xPercentage',
      y: 'ui.parameters.caption.position.yPercentage',
    },
    ...drawReturn,
    ...args,
  });
};

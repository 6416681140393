import React from 'react';

import { IconComponent, IconComponentProps } from '../Icon';

export const IconChevronDown = (props: IconComponentProps) => {
  return (
    <IconComponent {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="m6 9 6 6 6-6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconComponent>
  );
};

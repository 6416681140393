import * as React from 'react';

import { getOS } from '@autocut/utils/system/os.system.utils';

export function usePlatform() {
  const [platform, setPlatfrom] = React.useState('');

  React.useEffect(() => {
    const os = getOS();
    if (os) setPlatfrom(os);
  }, []);

  return platform;
}

import { v4 as uuidv4 } from 'uuid';
import { AutoCutState } from '@autocut/utils/zustand';

export const addSpeakerReducer = (state: AutoCutState) => {
  return [
    ...state.ui.parameters.podcast.speakers,
    {
      id: uuidv4(),
      name: '',
      cameras: [],
      audio: { label: '', value: '' },
    },
  ];
};

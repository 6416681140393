import logLevel from '@autocut/types/logLevel.enum';
import { ResourceManagerElementConfig } from '@autocut/types/ResourceManager';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { downloadFile } from '@autocut/utils/files.utils';
import { autocutApi } from '@autocut/utils/http.utils';
import { logger } from '@autocut/utils/logger';
import { manageError } from '@autocut/utils/manageError';

const logMessage = async (
  elementName: string,
  level: logLevel,
  message = 'log',
  objects = {}
) => {
  logger(
    `defaultResourceManagerState [${elementName}]`,
    level,
    message,
    objects
  );
};

export const getRessourceSignedUrl = async (fileName: string) => {
  const signedUrlResponse = await autocutApi.post(
    `/downloadableRessources/fileUrl`,
    {
      fileName,
    }
  );
  const signedUrl = signedUrlResponse.data;

  return signedUrl as string;
};

// ressourceName should correspond to a key in resourcesConfigs
export const downloadRessource =
  (ressourceName: string) =>
  (fileName: string) =>
  async (outputFilePath: string) => {
    const signedUrl = await getRessourceSignedUrl(fileName);

    try {
      await downloadFile(signedUrl, outputFilePath, true);

      await logMessage(ressourceName, logLevel.info, `${fileName} downloaded`);
    } catch (error: any) {
      await logMessage(
        ressourceName,
        logLevel.error,
        `Error while writing ressource : ${fileName}`,
        { error }
      );
      manageError({ error: new IncrementalError(error, 'downloadRessource') });
    }
  };

export const getSimpleResourceConfig = ({
  fileName,
  resourceName,
  additionalData = {},
}: {
  fileName: string;
  resourceName: string;
  additionalData?: any;
}): ResourceManagerElementConfig => ({
  fileName,
  downloadMethod: downloadRessource(resourceName)(fileName),
  scope: 'project',
  additionalData,
});

export const getGlobalResourceConfig = ({
  fileName,
  resourceName,
  additionalData = {},
}: {
  fileName: string;
  resourceName: string;
  additionalData?: any;
}): ResourceManagerElementConfig => ({
  fileName,
  downloadMethod: downloadRessource(resourceName)(fileName),
  scope: 'global',
  additionalData,
});

export const requestResourceOnInit = (
  resourceConfig: ResourceManagerElementConfig
): ResourceManagerElementConfig => ({
  ...resourceConfig,
  requestOnInit: true,
});

import * as React from 'react';

import './ToEnglishModal.css';
import { useLocale } from '@autocut/hooks/useLocale';
import { useLogger } from '@autocut/hooks/useLogger';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import logLevel from '@autocut/types/logLevel.enum';
import { Button } from '../../atoms';
import DotCube from '../../atoms/DotCube/DotCube';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import { setAutocutStore } from '@autocut/utils/zustand';

const ENGLISH_CODE = 'EN';

const ToEnglishModal = () => {
  const { setAndSaveLocale } = useLocale();
  const { logMessage } = useLogger('ToEnglishModal');

  return (
    <Modal
      title="Switch to english version."
      footer={
        <Button
          buttonType="primary"
          onClickFunction={() => {
            logMessage(logLevel.info, 'Change locale to english.');
            setAndSaveLocale(ENGLISH_CODE);
            setAutocutStore('ui.openedModalName', '');
          }}
        >
          Switch to english version
        </Button>
      }
    >
      <FlexContainer
        className="switch-lang-modal"
        alignItems="center"
        justifyContent="space-around"
      >
        <FlexContainer flexDirection="column" alignItems="flex-start">
          <p className="modal-text">
            Autocut detected that Premiere Pro was not in french.
          </p>
        </FlexContainer>
        <div className="dot-container">
          <DotCube cubeSize={3} container="page" />
        </div>
      </FlexContainer>
    </Modal>
  );
};

export default ToEnglishModal;

import { z as zod } from 'zod';

export const rgbValidationSchema = zod.object({
  r: zod
    .number()
    .int()
    .min(0, 'validation_error_rgb')
    .max(255, 'validation_error_rgb'),
  g: zod
    .number()
    .int()
    .min(0, 'validation_error_rgb')
    .max(255, 'validation_error_rgb'),
  b: zod
    .number()
    .int()
    .min(0, 'validation_error_rgb')
    .max(255, 'validation_error_rgb'),
});

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import React from 'react';
import CaptionsPreview from '../../Customization/Parts/ExampleCaption/CaptionsPreview';
import { setAutocutStore } from '@autocut/utils/zustand';
import { ModalType } from '@autocut/enums/modals.enum';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { MdOutlineModeEditOutline } from 'react-icons/md';
import { FaArrowsAlt } from 'react-icons/fa';
import { IoSearchOutline } from 'react-icons/io5';
import CaptionsPresets from './CaptionsPresets/CaptionsPresets';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';

export const CaptionsStyleStep = () => {
  const userPresets = useAutoCutStore(
    state => state.ui.parameters.caption.userPresets
  );

  return (
    <FlexContainer
      flexDirection="column"
      gap={12}
      style={{
        width: '100%',
      }}
    >
      <FlexContainer
        flexDirection="row"
        gap={12}
        style={{
          width: '100%',
        }}
        alignItems="center"
      >
        <CaptionsPreview
          withPreviewButton={false}
          maxHeight="160px"
          onClick={() =>
            setAutocutStore('ui.openedModalName', ModalType.CaptionsPreview)
          }
        />

        <FlexContainer
          flexDirection="column"
          gap={12}
          justifyContent="center"
          alignItems="center"
          style={{
            width: '100%',
          }}
        >
          <FlexContainer
            flexDirection="column"
            gap={12}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              onClick={() =>
                setAutocutStore(
                  'ui.openedModalName',
                  ModalType.CaptionsCustomization
                )
              }
              variant="secondary"
              disabled={userPresets.length === 0}
            >
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <MdOutlineModeEditOutline size="1rem" />
                <TranslatedMessage
                  id="captions_customization_modal_title"
                  defaultMessage="Edit style"
                />
              </FlexContainer>
            </Button>
            <Button
              onClick={() =>
                setAutocutStore('ui.openedModalName', ModalType.CaptionsPreview)
              }
              variant="secondary"
              color="white"
            >
              <FlexContainer
                justifyContent="center"
                alignItems="center"
                gap={4}
              >
                <FaArrowsAlt color="#F2F4F7" />
                <TranslatedMessage
                  id="captions_customization_position_size"
                  defaultMessage="Position & size"
                />
              </FlexContainer>
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer flexDirection="column" gap={8}>
        <CaptionsPresets />

        <FlexContainer flexDirection="row" flexWrap="nowrap" gap={2}>
          <Button
            onClick={() =>
              setAutocutStore('ui.openedModalName', ModalType.DeletePresets)
            }
            variant="tertiary"
            disabled={userPresets.length === 0}
          >
            <TranslatedMessage
              id="captions_presetCustomization_deletePresetsModal_title"
              defaultMessage="Delete presets"
            />
          </Button>
          <Button
            onClick={() =>
              setAutocutStore('ui.openedModalName', ModalType.SearchPreset)
            }
            variant="tertiary"
            color="white"
          >
            <FlexContainer gap={4} alignItems="center" justifyContent="center">
              <IoSearchOutline color="white" size="16px" />
              <TranslatedMessage
                id="captions_presetCustomization_searchPresetModal_title"
                defaultMessage="Community preset"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

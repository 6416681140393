import * as React from 'react';

import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import css from './UnsupportedScenarioInfoText.module.css';
import { getCorrespondingUnsupportedModalContent } from '@autocut/utils/errors/unsupported.utils';
import { UnsupportedScenarioIds } from '@autocut/enums/unsupported.enum';
import { InfoText } from '@autocut/components/atoms/InfoText';

export type UnsupportedScenarioInfoTextProps = {
  unsupportedScenarioId: UnsupportedScenarioIds;
  fileName?: string;
};

const UnsupportedScenarioInfoText = ({
  unsupportedScenarioId,
  fileName,
}: UnsupportedScenarioInfoTextProps) => {
  const intl = useIntl();
  const academyLocalizedBaseURL =
    AUTOCUT_CONSTANTS[CURRENT_ENV].ACADEMY_ADDRESS[
      intl.locale.toUpperCase() as 'FR' | 'EN'
    ];

  const unsupportedScenario = getCorrespondingUnsupportedModalContent(
    unsupportedScenarioId
  );

  const linkTextID = unsupportedScenario.isFixable
    ? 'link_not_supported_modal'
    : 'link_not_supported_modal_no_solution';
  const linkTextDefaultMessage = `To better understand this error${
    unsupportedScenario.isFixable ? 'and how to fix it' : ''
  }, go to `;

  return (
    <InfoText style="error" hideHelper className={css.infoTextOverride}>
      <FlexContainer flexDirection="column" alignItems="flex-start">
        <FlexContainer>
          <h3 className={css.notSupportedTitle}>
            <TranslatedMessage
              id={unsupportedScenario.title.id}
              defaultMessage={unsupportedScenario.title.defaultMessage}
            />
          </h3>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flexDirection="column" alignItems="flex-start">
            {unsupportedScenario.body.map(message => (
              <p className={css.unsupportedContent} key={message.id}>
                <TranslatedMessage
                  id={message.id}
                  defaultMessage={message.defaultMessage}
                  values={
                    message.id === 'text_not_supported_modal_corrupted' ||
                    message.id === 'text_not_supported_modal_remote_file'
                      ? { filename: fileName }
                      : {}
                  }
                />
              </p>
            ))}
            {unsupportedScenario.link && (
              <p>
                <TranslatedMessage
                  id={linkTextID}
                  defaultMessage={linkTextDefaultMessage}
                />
                <a
                  className={'tuto_link'}
                  onClick={() => {
                    openLinkInBrowser(
                      academyLocalizedBaseURL + unsupportedScenario.link
                    );
                  }}
                >
                  AutoCut Academy
                </a>
                <TranslatedMessage
                  id={'link_not_supported_modal_end'}
                  defaultMessage={' our documentation and tutorials site'}
                />
              </p>
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </InfoText>
  );
};

export default UnsupportedScenarioInfoText;

import * as React from 'react';

import Select, { SingleValue } from 'react-select';

import { SelectItem } from '../SendLogFile/SendLogFile';
import selectCustomStyle from './SelectStyle';
import { SelectVariant } from './SelectVariants';

interface CustomSelectTextProps<T extends string = string> {
  options: SelectItem<T>[];
  initialValue?: SelectItem<T>;
  handleSelectChange: (newValue: SingleValue<SelectItem<T>>) => void;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  customization?: SelectVariant;
  portalTarget?: HTMLElement | null;
}

export const CustomSelectText = <T extends string = string>({
  options,
  initialValue,
  handleSelectChange,
  isClearable,
  isSearchable,
  isDisabled,
  isLoading,
  customization,
  portalTarget,
}: CustomSelectTextProps<T>) => {
  return (
    <Select
      isMulti={false}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      value={initialValue}
      onChange={(newValue, actionMeta) => {
        if (actionMeta.action === 'clear') {
          handleSelectChange({ value: '' as T, label: '' });
          return;
        }
        handleSelectChange(newValue);
      }}
      styles={selectCustomStyle(customization)}
      menuPortalTarget={portalTarget}
    />
  );
};

import { evalTS } from '@autocut/lib/utils/bolt';
import { getResourcesFolder } from '../resourceManager.utils';
import { fs } from '@autocut/lib/cep/node';

export const downloadYoutubeFile = async (chapters: Chapter[]) => {
  const formattedChapters = chapters
    .map(chapter => {
      const roundedStart = Math.floor(chapter.start);
      const minutes = Math.floor(roundedStart / 60)
        .toFixed(0)
        .padStart(2, '0');
      const seconds = (roundedStart % 60).toFixed(0).padStart(2, '0');
      return `${minutes}:${seconds} - ${chapter.title}`;
    })
    .join('\n');

  const resourcesFolder = getResourcesFolder();
  const filePath = `${resourcesFolder}/chapters.txt`;
  fs.writeFileSync(filePath, formattedChapters);

  await evalTS('importFileInBin', filePath, 'Chapters', false);

  return;
};

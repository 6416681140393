import { z as zod } from 'zod';

export const aiValidationSchema = zod.object({
  aggressivenessLevel: zod.number().min(0).max(3),
  transistions: zod.enum(['none', 'j_cut', 'l_cut', 'both', 'constant_power'], {
    invalid_type_error: 'validation_error_ai_transitions',
  }),
  silences: zod.enum(['delete', 'keep', 'mute', 'delete_keep_spaces'], {
    invalid_type_error: 'validation_error_ai_silences',
  }),
});

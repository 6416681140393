import { SilenceParams } from '@autocut/types/SilenceParams';

/***
 * Supprime les silences d'une durée inférieure au paramètre "limit"
 * @param {Number[][]} silences - Tableau d'intervalles de silence
 * @param {Number} limit
 */
export const removeSilencesShorterThan = (
  silences: number[][],
  parameters: SilenceParams
) => {
  // If minTimeOfSilence is too short, it can induce cuts shorter than a frame. PPro will then cut twice at the same place,
  // messing up the count for the deletion so we're putting a limit
  return silences.filter(
    silence =>
      silence[1] - silence[0] > Math.max(parameters.minTimeOfSilence, 0.05)
  );
};

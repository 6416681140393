import { Camera, CameraWithScore } from '@autocut/types/Podcast';
import { Speaker } from '@autocut/types/Speaker';
import { addBreadcrumb } from '@sentry/react';
import { IncrementalError } from '../errors/IncrementalError';
import { getParametersForMode } from '../parameters.utils';
import { getBestCamera } from './getBestCamera';
export type GetNextCameraReturnType =
  | {
      from: number;
      to: number;
      camera: Camera;
      last?: boolean;
    }
  | undefined;
export const getNextCamera = (
  startSecond: number,
  lastCamera: Camera | undefined,
  cameras: Camera[],
  speakers: (Speaker & {
    clips: Clip[];
    talkingTimeline: number[];
  })[],
  maximumCamTime: number,
  minimumCamTime: number
): GetNextCameraReturnType => {
  addBreadcrumb({
    level: 'info',
    type: 'getNextCamera',
    message: 'starting function',
    data: {
      startSecond,
      lastCamera,
      cameras,
      speakers,
      maximumCamTime,
      minimumCamTime,
    },
  });
  let errorCode = '0';
  let bestCameraCopy, nextBestCameraCopy;
  try {
    errorCode = '1';
    const { precision: precisionParameter, toleranceTime: windowSize } =
      getParametersForMode('podcast');
    const bestCamera = getBestCamera(
      startSecond,
      startSecond + minimumCamTime,
      cameras.filter(camera => camera.value !== lastCamera?.value),
      speakers
    );
    bestCameraCopy = bestCamera;
    errorCode = '2';
    if (!bestCamera) {
      return undefined;
    }
    let nextBestCamera: CameraWithScore | undefined = bestCamera;
    nextBestCameraCopy = nextBestCamera;
    let from: number = startSecond + minimumCamTime;
    let to: number = startSecond + minimumCamTime + minimumCamTime;

    do {
      errorCode = '3';
      nextBestCamera = getBestCamera(from, to, cameras, speakers, bestCamera);
      nextBestCameraCopy = nextBestCamera;
      if (!nextBestCamera) {
        return {
          from: startSecond,
          to: to - minimumCamTime,
          camera: bestCamera,
        };
      }
      errorCode = '4';
      from += precisionParameter;
      to += precisionParameter;
    } while (
      Number(nextBestCamera.value) === Number(bestCamera.value) &&
      to - startSecond < maximumCamTime
    );
    errorCode = '5';
    if (to - startSecond >= maximumCamTime) {
      return {
        from: startSecond,
        to: startSecond + maximumCamTime,
        camera: bestCamera,
      };
    }
    errorCode = '6';
    // The camera needs to change in the next minCamTime
    // We need to find when the camera changes
    const uppperBound = to;
    const lowerBound = from;
    const nextCameraValue = nextBestCamera.value;
    errorCode = '7';
    to = lowerBound + windowSize;
    do {
      errorCode = '8';
      nextBestCamera = getBestCamera(
        from,
        to,
        cameras,
        speakers,
        bestCamera
      ) as CameraWithScore;
      nextBestCameraCopy = nextBestCamera;
      to += precisionParameter;
      from += precisionParameter;
      errorCode = '9';
    } while (
      nextBestCamera &&
      nextBestCamera.value !== nextCameraValue &&
      to < uppperBound
    );
    errorCode = '10';
    return {
      from: startSecond,
      to: from + windowSize / 2 - precisionParameter,
      camera: bestCamera,
    };
  } catch (err: any) {
    addBreadcrumb({
      level: 'error',
      type: 'getNextCamera',
      data: {
        nextBestCamera: JSON.stringify(nextBestCameraCopy, null, 1),
        bestCamera: JSON.stringify(bestCameraCopy, null, 1),
      },
    });
    throw new IncrementalError(err, `getNextCamera - ${errorCode}`);
  }
};

import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { getAllClips } from '@autocut/utils/timeline/selectedInfos.utils';
import { setAutocutStore } from '../zustand';

/**
 * Calcule une version du tableau des clips selectionné de manière à minimiser le nombre d'action "cut" réalisées par Premiere Pro en se basant sur la mechanique des clips liés puis l'insère dans le store.
 *
 * Example : A, B et C sont des clips lié. C commence un peu avant A et B. Il faudra donc couper C. En pratique la fonction renvera [A,C] ou [B,C] ou [C]
 *
 * Tags : #to-improve #safe
 **/
export const computeClipsToCut = async () => {
  try {
    const selectedClipsInfos = getAllClips();
    if (!selectedClipsInfos) return;
    let clipsToCut: Clip[] = [...selectedClipsInfos];
    const clipsToNotCut: Clip[] = [];

    for (const clip of selectedClipsInfos) {
      // Si le clip n'existe pas ou qu'il est déjà dans la liste des clips à ne pas couper, on passe au suivant
      if (
        !clip ||
        clipsToNotCut.find((clipToNotCut: Clip) => {
          return clipToNotCut.id === clip.id;
        })
      )
        continue;

      const linkedClips = clip.linkedClipsId
        .map(
          (linkedClipId: string) =>
            selectedClipsInfos.find(clip => clip.id === linkedClipId) as Clip
        )
        .filter(linkedClip => linkedClip !== undefined);

      for (const linkedClip of linkedClips) {
        if (!linkedClip) continue;

        // Si le clip lié est dans le clip sélectionné, on le retire de la liste des clips à couper et on l'ajoute à la liste des clips à ne pas couper
        if (linkedClip.start === clip.start && linkedClip.end === clip.end) {
          clipsToCut = clipsToCut.filter(
            (clipToCut: Clip) => clipToCut.id !== linkedClip.id
          );
          clipsToNotCut.push(linkedClip);
        }
      }
    }

    setAutocutStore('onGoingProcess.clipsToCut', clipsToCut);
    return clipsToCut;
  } catch (err: any) {
    throw new IncrementalError(err, 'computeClipsToCut');
  }
};

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import Modal from '@autocut/layouts/ModalLayout/BaseModal';
import React from 'react';

import { Timeline } from './Timeline/Timeline';
import { TaskList } from './TaskList/TaskList';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { TrialTask } from '@autocut/types/TrialTask';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useIntl } from 'react-intl';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { Author } from '@autocut/components/atoms/Author/Author';
import { EAuthors } from '../../atoms/Author/utils';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Text } from '@autocut/designSystem/components/atoms/Text/Text';
import { colors } from '@autocut/designSystem/colors';

export const GamingTrialModal = () => {
  const intl = useIntl();

  const { daysLeft, tasks } = useAutoCutStore(state => ({
    daysLeft: state.user.license?.left,
    tasks: state.game.trial.tasks,
  }));

  const totalElements = tasks
    .map(task => task.amount)
    .reduce((acc: number, amount: number) => {
      return acc + amount;
    }, 0);
  const completedElements = tasks
    .filter(task => task.completed)
    .map(task => task.amount)
    .reduce((acc: number, amount: number) => {
      return acc + amount;
    }, 0);
  const animateLast = tasks.some(task => task.isAnimated);

  const handleOnClose = () => {
    setAutocutStore(
      'game.trial.tasks',
      tasks.map(task => ({ ...task, isAnimated: false }))
    );
    setAutocutStore('game.trial.displayModal', false);
    setAutocutStore('ui.openedModalName', '');
  };

  return (
    <Modal
      fullScreen
      title={intl.formatMessage({
        id: 'gaming_trial_modal_title',
        defaultMessage: 'Unlock free days 👋',
      })}
      closeModalFunction={handleOnClose}
    >
      <FlexContainer gap={12} flexDirection="column">
        <FormSection title={<Author author={EAuthors.ADRIAN} />}>
          <PaddedSection>
            <TranslatedMessage
              id="gaming_trial_modal_description"
              defaultMessage="Here is a free additional day ! 🎉 {br} Your creative journey begins with a 14-day free trial of AutoCut, you unlocked 1 more and you've got {daysLeft} days left to explore!{br}But why stop there? We might have hidden additional days along the way so feel free to explore the features to get them all!"
              values={{
                daysLeft,
              }}
            />
          </PaddedSection>
        </FormSection>

        <FlexContainer gap={4} flexDirection="column">
          <Text variant="textSm.medium" color={colors.gray100}>
            <TranslatedMessage
              id="gaming_trial_modal_task_list_title"
              defaultMessage="{completed}/{maximum} tasks completed"
              values={{
                completed: tasks.filter(task => task.completed).length,
                maximum: tasks.length,
              }}
            />
          </Text>

          <Timeline
            leftDescription={intl.formatMessage({
              id: 'gaming_trial_modal_timeline_left',
              defaultMessage: '14 days free trial',
            })}
            rightDescription={intl.formatMessage({
              id: 'gaming_trial_modal_timeline_right',
              defaultMessage: '21 days free trial',
            })}
            totalElements={totalElements}
            currentElement={completedElements}
            animateLast={animateLast}
          />
        </FlexContainer>

        <TaskList tasks={tasks as TrialTask[]} />
      </FlexContainer>
    </Modal>
  );
};

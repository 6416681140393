import { Speaker } from '@autocut/types/Speaker';
import { IncrementalError } from '../errors/IncrementalError';

export const normalizeTimelines = (
  speakers: (Speaker & {
    clips: Clip[];
    talkingTimeline: number[];
  })[]
) => {
  try {
    const longestTimelineLength = speakers.reduce((acc, speaker) => {
      return Math.max(acc, speaker.talkingTimeline.length);
    }, 0);

    const normalizedSpeakers = speakers.map(speaker => {
      if (speaker.talkingTimeline.length === longestTimelineLength) {
        return speaker;
      }

      const normalizedTimeline = [
        ...speaker.talkingTimeline,
        ...Array(longestTimelineLength - speaker.talkingTimeline.length).fill(
          0
        ),
      ];

      return { ...speaker, talkingTimeline: normalizedTimeline };
    });

    return normalizedSpeakers;
  } catch (err: any) {
    throw new IncrementalError(err, 'normalizeTimelines');
  }
};

import { v4 as uuidv4 } from 'uuid';

import { debounce } from '@autocut/utils/debounce';
import React, { useEffect, useMemo, useRef } from 'react';
import css from './WaveFormSkeleton.module.css';

// Why this component is so complicated for what it is :
// https://stackoverflow.com/questions/62121872/during-heavy-javascript-function-css-animation-stops-if-i-use-translatex100
// Basically we need the css animation to be in pixel to offload it to another thread, not blocked by JS scripts
export const WaveFormSkeleton = () => {
  const uuid = useMemo(() => uuidv4(), []);
  const skeletonContainerRef = useRef<HTMLDivElement>(null);

  const setFromAndToPosition = () => {
    if (!skeletonContainerRef.current) return;

    const bbox = skeletonContainerRef.current.getBoundingClientRect();
    const fromPosition = -bbox.width;
    const toPosition = bbox.width;
    const skeletonContainerElement = document.getElementById(uuid);

    skeletonContainerElement?.style.setProperty(
      '--from-translateX',
      `${fromPosition}px`
    );
    skeletonContainerElement?.style.setProperty(
      '--to-translateX',
      `${toPosition}px`
    );

    // Trigger a restart of a css animation
    // why ? https://www.harrytheo.com/blog/2021/02/restart-a-css-animation-with-javascript/
    const skeletonElement = skeletonContainerRef.current.children[0];
    skeletonElement.classList.remove(css.waveform_skeleton);
    void skeletonContainerRef.current.offsetWidth;
    skeletonElement.classList.add(css.waveform_skeleton);
  };

  useEffect(() => {
    setFromAndToPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skeletonContainerRef.current]);

  useEffect(() => {
    const debouncedHandleResize = debounce(setFromAndToPosition, 100);
    window.addEventListener('resize', debouncedHandleResize);

    return () => window.removeEventListener('resize', debouncedHandleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={uuid}
      ref={skeletonContainerRef}
      className={css.waveform_container}
    >
      <div className={css.waveform_skeleton} />
    </div>
  );
};

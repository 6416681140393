import { ModalType } from '@autocut/enums/modals.enum';
import { evalTS } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { IncrementalError } from '@autocut/utils/errors/IncrementalError';
import { IntlShape } from 'react-intl';
import { handleBackup } from '../backup/handleBackup';
import { handleStatsPreProcess } from '../cut/handleStatsPreCut';
import { logger } from '../logger';
import { autocutStoreVanilla, setAutocutStore } from '../zustand';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleProcessStart', level, message, objects);
};

export const handleProcessStart = async (intl: IntlShape) => {
  try {
    const autocutState = autocutStoreVanilla();
    const mode = autocutState.ui.process.mode;

    if (mode.id != 'resize') {
      const isTimelineMuted = await evalTS('isTimelineMuted');
      if (isTimelineMuted) {
        throw new IncrementalError('TIMELINE_MUTED', 'handleProcessStart');
      }
    }

    logMessage(logLevel.notice, `Starting process ${mode.id}`);
    setAutocutStore('onGoingProcess.startDate', new Date());
    setAutocutStore('ui.process.isProcessing', true);
    setAutocutStore('ui.openedModalName', ModalType.Processing);

    await handleStatsPreProcess();

    await handleBackup(intl);
  } catch (err: any) {
    throw new IncrementalError(err, 'handleProcessStart');
  }
};

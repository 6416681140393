import { SWEAR_WORDS_AVAILABLE_LANGUAGES } from '@autocut/utils/swearWords/availableLanguages';
import { autocutStoreVanilla } from '@autocut/utils/zustand';

export const isSwearWordsProviderAvailable = (): {
  isAvailable: boolean;
  message?: string;
} => {
  const languageOfTranscription =
    autocutStoreVanilla().ui.parameters.swear_word.languageOfTranscription;

  if (SWEAR_WORDS_AVAILABLE_LANGUAGES.includes(languageOfTranscription.value)) {
    return {
      isAvailable: true,
    };
  }

  return {
    isAvailable: false,
    message: 'swear_words_disabled_language',
  };
};

import { setAutocutStore } from '@autocut/utils/zustand';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';

import {
  TransitionSlugs,
  CAPTIONS_TRANSITIONS,
} from '@autocut/utils/captions/effects.utils';
import '@autocut/utils/captions/effects.css';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { Switch } from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';

const DELAY_BETWEEN_TRANSITIONS_PREVIEW = '2s';
const TransitionCustomization = () => {
  const intl = useIntl();
  const { enabled, effects = [] } = useAutoCutStore(
    state => state.ui.parameters.caption.transition
  );

  // Reset transition when effects change so that they begin at the same timing
  const [resetAnimationName, setResetAnimationName] = useState<
    'none' | undefined
  >('none');
  useEffect(() => {
    setResetAnimationName('none');
    setTimeout(() => {
      setResetAnimationName(undefined);
    }, 100);
  }, [effects]);

  const onEffectClick = (effect: TransitionSlugs) => {
    setAutocutStore(
      'ui.parameters.caption.transition.effects',
      effects.includes(effect)
        ? effects.filter(e => e !== effect)
        : [...effects, effect]
    );
  };

  return (
    <ParametersGroupLayout
      style={{
        overflow: 'hidden',
      }}
      status={enabled}
      onStatusChange={checked =>
        setAutocutStore('ui.parameters.caption.transition.enabled', checked)
      }
      title={
        <TranslatedMessage
          id="captions_customization_transition"
          defaultMessage="Transition"
        />
      }
    >
      {Object.keys(CAPTIONS_TRANSITIONS).map(effect => (
        <ParameterLayout
          key={effect}
          title={
            <TranslatedMessage
              defaultMessage={effect}
              id={`captions_customization_transition_effect_${effect}`}
            />
          }
        >
          <Switch
            checked={effects.includes(effect as TransitionSlugs)}
            onChange={() => onEffectClick(effect as TransitionSlugs)}
            size={16}
          />
        </ParameterLayout>
      ))}

      <div
        style={{
          gridColumnEnd: 'span 2',
          textAlign: 'center',
          alignSelf: 'center',
          fontSize: '20px',
          lineClamp: 1,
          height: '35px',
          textOverflow: 'clip',
          overflow: 'hidden',
          willChange: 'animation, filter, opacity, transform',
          animationName: resetAnimationName,
          animation: resetAnimationName
            ? undefined
            : effects
                .map(
                  effect =>
                    `infinite ${effect} ${DELAY_BETWEEN_TRANSITIONS_PREVIEW} linear`
                )
                .join(', '),
        }}
      >
        {intl
          .formatMessage({ id: 'captions_customization_example_text' })
          .split('.')[0] + '.'}
      </div>
    </ParametersGroupLayout>
  );
};

export default TransitionCustomization;

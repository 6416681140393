import { ModalType } from '@autocut/enums/modals.enum';
import { setAutocutStore } from '@autocut/utils/zustand';

export const addModalToQueue = (modal: ModalType | ModalType[]) => {
  const modals = Array.isArray(modal) ? modal : [modal];

  setAutocutStore('ui.modalQueue', prev => {
    const newModalQueue = [...new Set([...prev.ui.modalQueue, ...modals])];

    return newModalQueue;
  });
};

import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ColorPicker from '@autocut/components/atoms/ColorPicker/ColorPicker';
import { FontSelect } from './FontSelect';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import { useLocale } from '@autocut/hooks/useLocale';
import { FormSection } from '@autocut/designSystem/components/layout/FormSection/FormSection';
import { PaddedSection } from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';

const TextCustomization = () => {
  const { locale } = useLocale();
  const { textColor } = useAutoCutStore(state => ({
    textColor: state.ui.parameters.caption.text.color,
  }));

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="captions_customization_font"
          defaultMessage="Font"
        />
      }
    >
      <PaddedSection>
        <FlexContainer flexDirection="column" gap={8}>
          <ParameterLayout
            title={
              <FlexContainer gap={4} flexWrap="wrap" alignItems="center">
                <TranslatedMessage
                  id="captions_customization_font_font"
                  defaultMessage="Font name"
                />
                <a
                  style={{ fontSize: '10px', color: 'inherit' }}
                  href="#"
                  onClick={() =>
                    openLinkInBrowser(
                      `https://www.autocut.fr/${locale.toLocaleLowerCase()}/academy/autocut-parameters/captions-fonts/`
                    )
                  }
                >
                  <TranslatedMessage
                    id="captions_customization_font_help"
                    defaultMessage="How to add a new font family?"
                  />
                </a>
              </FlexContainer>
            }
            height={31}
          >
            <FontSelect />
          </ParameterLayout>

          <ParameterLayout
            title={
              <TranslatedMessage
                id="captions_customization_font_color"
                defaultMessage="Color"
              />
            }
          >
            <ColorPicker
              value={textColor}
              onChange={color =>
                setAutocutStore('ui.parameters.caption.text.color', color)
              }
              position="bottom-left"
            />
          </ParameterLayout>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};

export default TextCustomization;

import SilenceDetectionTypeChoice from '@autocut/components/atoms/SilenceDetectionTypeChoice/SilenceDetectionTypeChoice';
import AIAgressivenessStep from '@autocut/components/steps/AiAggressivenessStep';
import { PreviewStep } from '@autocut/components/steps/PreviewStep';
import { SelectedClipsStep } from '@autocut/components/steps/SelectedClipsStep/SelectedClipsStep';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { handleCutAiProcess } from '@autocut/utils/cut/handleCutProcess';
import React from 'react';
import { AiOutlineScissor } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { ModeWrapper } from '../ModeWrapper';
import { ModeFooter } from '@autocut/components/partials/ModeFooter/ModeFooter';
import { SilencesFooterButton } from '@autocut/components/atoms/SilencesFooterButton';
import { SilenceStep } from '@autocut/components/steps/SilenceStep/SilenceStep';
import { TransitionStep } from '@autocut/components/steps/TransitionStep/TransitionStep';
import silenceSelectionImageUrl from '@autocut/assets/images/selectionScreen/silence.gif';
const silenceSelectionImage = new URL(silenceSelectionImageUrl, import.meta.url)
  .href;

export const AiSilencesMode = () => {
  const intl = useIntl();

  const { selectionInfos } = useAutoCutStore(state => ({
    selectionInfos: state.sequence.infos,
    license: state.user.license,
  }));

  return (
    <ModeWrapper
      Icon={AiOutlineScissor}
      text="AutoCut Silences"
      displayCondition={!!(selectionInfos && selectionInfos.nbClipsAudio > 0)}
      invalidSelectionScreen={{
        titleId: 'text_noClip_title',
        titleDefaultMessage: 'Select clip(s) to cut in your timeline',
        texts: [
          { id: 'text_noClip', defaultMessage: 'Nothing selected' },
          {
            id: 'text_noClip_no_selection',
            defaultMessage:
              'If the selected clips do not appear, try restarting AutoCut.',
          },
        ],
        linkId: 'silence_link_tuto',
        selectionImage: silenceSelectionImage,
      }}
    >
      <SilenceDetectionTypeChoice />
      <AIAgressivenessStep />
      <SilenceStep modeId="ai" />
      <TransitionStep modeId="ai" />

      <SelectedClipsStep />
      <PreviewStep modeId="ai" />
      <ModeFooter
        renderButton={(isLoading, isDisabled) => (
          <SilencesFooterButton
            modeId="ai"
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClickFunction={() => handleCutAiProcess(intl)}
          />
        )}
      />
    </ModeWrapper>
  );
};

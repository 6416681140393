import * as React from 'react';

import * as json from '../../../../package.json';
import { csi } from '@autocut/lib/utils/bolt';

export default function AdobeAppInfo() {
  const autoCutVersion = json.version;

  return (
    <div className="AdobeAppInfo">
      {csi.inCEPEnvironment() ? (
        <ul>
          <li>Id: {csi.getExtensionID()}</li>
          <li>Version: {csi.hostEnvironment.appVersion}</li>
          <li>Extension Version: {autoCutVersion}</li>
          <li>
            Extension Path:
            {csi.getExtensions([csi.getExtensionID()])[0].basePath}
          </li>
        </ul>
      ) : (
        <p>Not in CEP environment.</p>
      )}
    </div>
  );
}

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { convertSecondsToHMSString } from '@autocut/utils/date.utils';
import React, { useMemo, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import css from './TrackSelectionDisplay.module.css';
import { ClipTypeEnum } from '../../../../jsx/ppro/types.ppro';
import { RadioButton } from '../RadioButton/RadioButton';
import { setAutocutStore } from '@autocut/utils/zustand';

export type TrackSelectionDisplayProps = {
  track: Track;
  isDeactivable?: boolean;
};

const TrackSelectionDisplay = ({
  track,
  isDeactivable = false,
}: TrackSelectionDisplayProps) => {
  const [open, setOpen] = useState(false);

  const isChecked = useMemo(() => {
    if (!isDeactivable) return false;

    return track.isUsed === undefined ? true : track.isUsed;
  }, [track.isUsed]);

  return (
    <FlexContainer justifyContent="space-between" alignItems="flex-start">
      <FlexContainer className={css.leftContainer} alignItems="center" gap={4}>
        {isDeactivable && (
          <RadioButton
            label={''}
            name={`trackSelectionDisplay-${track.type}-${track.id}`}
            onClick={e => {
              setAutocutStore('sequence.infos', state => {
                return {
                  ...(state.sequence.infos || {}),
                  audioTracks: state.sequence.infos?.audioTracks.map(
                    currentTrack =>
                      currentTrack.index === track.index
                        ? { ...currentTrack, isUsed: e }
                        : currentTrack
                  ),
                } as Sequence;
              });
            }}
            value={track.isUsed === undefined ? false : !track.isUsed}
            default={isChecked}
          />
        )}
        <p
          className={`${css.trackName} ${!isDeactivable ? css.margin : ''}`}
        >{`${track.type === ClipTypeEnum.Video ? 'V' : 'A'}${
          track.index + 1
        }`}</p>
      </FlexContainer>

      <FlexContainer flexDirection="column" className={css.rightContainer}>
        <FlexContainer
          flexDirection="row"
          justifyContent="flex-end"
          onClick={() => setOpen(c => !c)}
          className={css.title}
        >
          <p>
            <TranslatedMessage
              id="captions_script_sequence_track_clip_unit"
              defaultMessage="{nbClips} clips | {from} → {to}"
              values={{
                nbClips: track.nbClipsSelected,
                from: convertSecondsToHMSString(track.start),
                to: convertSecondsToHMSString(track.end),
              }}
            />
          </p>
          <BiChevronDown
            className={css.chevron}
            size={20}
            style={{
              transform: `rotateZ(${open ? 180 : 0}deg)`,
            }}
          />
        </FlexContainer>
        <div className={css.clips}>
          {open
            ? track.selectedClips.map(clip => (
                <p className={css.clip} key={clip.id}>
                  <TranslatedMessage
                    id="captions_script_sequence_track_clip_unit_detail"
                    defaultMessage="{clipName} | {from} → {to}"
                    values={{
                      clipName: clip.path.split(/[\/\\]/).pop(),
                      from: convertSecondsToHMSString(clip.start),
                      to: convertSecondsToHMSString(clip.end),
                    }}
                  />
                </p>
              ))
            : null}
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TrackSelectionDisplay;

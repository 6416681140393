import { setAutocutStore } from '@autocut/utils/zustand';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { RadioButtonsForm } from '../../atoms/RadioButtonsForm/RadioButtonsForm';
import { TextWithHelper } from '../../atoms/TextWithHelper';
import css from './DynamismStep.module.css';

export enum AUTOZOOM_DYNAMISM_LEVEL {
  NORMAL = 0.2,
  HIGH = 0.5,
  EXTREME = 0.7,
  CUSTOM = -1,
}

export const DynamismStep = () => {
  const intl = useIntl();

  return (
    <div className={`${css.dynamismContainer}`}>
      <TextWithHelper helperId="autozoom_dynamism" helperDefault="Dynamism">
        <TranslatedMessage id="autozoom_dynamism" defaultMessage="Dynamism" />
      </TextWithHelper>
      <RadioButtonsForm
        radioButtonDescriptions={[
          {
            label: intl.formatMessage({
              id: 'autozoom_dynamism_normal',
              defaultMessage: 'Normal',
            }),
            value: AUTOZOOM_DYNAMISM_LEVEL.NORMAL,
            default: true,
          },
          {
            label: intl.formatMessage({
              id: 'autozoom_dynamism_high',
              defaultMessage: 'High',
            }),
            value: AUTOZOOM_DYNAMISM_LEVEL.HIGH,
          },
          {
            label: intl.formatMessage({
              id: 'autozoom_dynamism_extreme',
              defaultMessage: 'Extreme',
            }),
            value: AUTOZOOM_DYNAMISM_LEVEL.EXTREME,
          },
          {
            label: intl.formatMessage({
              id: 'autozoom_dynamism_custom',
              defaultMessage: 'Custom',
            }),
            value: AUTOZOOM_DYNAMISM_LEVEL.CUSTOM,
            withInput: true,
          },
        ]}
        onChange={newValue =>
          setAutocutStore(
            'ui.parameters.zoom.dynamicZoomPercentage',
            newValue as number
          )
        }
      />
    </div>
  );
};

import * as React from 'react';

import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { setAutocutStore } from '@autocut/utils/zustand';

import { StepLayout } from '../../../layouts/StepLayout';
import { RadioButton } from '../../atoms/RadioButton/RadioButton';
import { TextWithHelper } from '../../atoms/TextWithHelper';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import './aiAgressivenessStep.css';

const AI_AGGRESSIVENESS = [0, 1, 2, 3];

const AIAgressivenessStep = () => {
  const cuttingParameters = useAutoCutStore(state => state.ui.parameters.ai);

  const intl = useIntl();

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_Step1Ai_Title',
        defaultMessage: "Can't read the computer's UUID",
      })}
      defaultOpen={true}
    >
      <TextWithHelper
        helperId="helper_Step1Ai"
        helperDefault="Aggressiveness will determine how much silence the AI will remove, minimum is 1 and maximum is 4"
      >
        <TranslatedMessage
          id="text_Step1Ai_Silences"
          defaultMessage="Choose AI aggressiveness"
        />
      </TextWithHelper>

      <FlexContainer
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        className="full-size"
      >
        {AI_AGGRESSIVENESS.map(agr => (
          <RadioButton
            key={agr}
            label={`${agr + 1}`}
            name={`aiAgressivenessForm-${agr}-${
              agr === cuttingParameters.aggressivenessLevel
            }`}
            onClick={e => {
              setAutocutStore('ui.parameters.ai.aggressivenessLevel', agr);
            }}
            default={agr === cuttingParameters.aggressivenessLevel}
            value={agr}
          />
        ))}
      </FlexContainer>
    </StepLayout>
  );
};

export default AIAgressivenessStep;

import React, { useEffect } from 'react';

import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { csi, evalExtendscript, evalTS } from '@autocut/lib/utils/bolt';
import { Button } from '../../atoms';
import css from './CEPScriptLauncher.module.css';
import { ns } from '../../../../shared/shared';

const CEPScriptLauncher = ({}) => {
  const [isInCEPEnv, cepCallHistory] = useAutoCutStore(state => [
    state.ppro.isInCEPEnv,
    state.dev.cepCallHistory,
  ]);

  const [cepScriptName, setCepScriptName] = React.useState('');
  const [message, setMessage] = React.useState('');

  useEffect(() => {
    console.log('cepCallHistory', cepCallHistory);
  }, []);

  const launchCEPScript = async () => {
    if (!cepScriptName) {
      setMessage('Please enter a CEP script name');
      return;
    }

    const messageHeader = `Script ${cepScriptName} responded with :`;
    try {
      const result = await evalTS(cepScriptName as any);

      setMessage(
        messageHeader + JSON.stringify(result, null, 2).replace(/\n/g, '<br />')
      );
      console.log(message, result);
    } catch (err) {
      setMessage(
        messageHeader + JSON.stringify(err, null, 2).replace(/\n/g, '<br />')
      );
      console.log(message, err);
    }
  };

  return (
    <>
      <div className={css.container}>
        {isInCEPEnv ? (
          <>
            Please enter the name of the CEP script you want to launch
            <div className={css.inputContainer}>
              <input
                className={css.input}
                value={cepScriptName}
                onChange={e => setCepScriptName(e.target.value)}
              />
              <Button size="sm" onClickFunction={launchCEPScript}>
                Launch
              </Button>
            </div>
            <Button
              size="sm"
              onClickFunction={async () => {
                console.log(
                  await evalExtendscript(`$["${ns}"].secondToTick(522.910)`)
                );
              }}
            >
              run 1
            </Button>
            <Button
              size="sm"
              onClickFunction={async () => {
                console.log(
                  await evalExtendscript(`$["${ns}"].secondToTick(522.969)`)
                );
              }}
            >
              run 1 bis
            </Button>
            <Button
              size="sm"
              onClickFunction={async () => {
                console.log(
                  await evalExtendscript(`$["${ns}"].secondToTick(522.970)`)
                );
              }}
            >
              run 2
            </Button>
            <Button
              size="sm"
              onClickFunction={async () => {
                console.log(
                  await evalExtendscript(`$["${ns}"].secondToTick(524.168)`)
                );
              }}
            >
              run OK
            </Button>
            <Button
              size="sm"
              onClickFunction={async () => {
                await evalTS(
                  'createAndModifyMoGRT',
                  csi.getExtensions([csi.getExtensionID()])[0].basePath +
                    '/AutoCut_Captions.mogrt',
                  15.5,
                  5,
                  2,
                  0,
                  {
                    TEXT: [
                      [0, 'AutoCut > Firecut'],
                      [1.1, 'AutoCut > Firecut'],
                      [1.2, 'AutoCut > Firecu'],
                      [1.3, 'AutoCut > Firec'],
                      [1.4, 'AutoCut > Fire'],
                      [1.5, 'AutoCut > Fir'],
                      [1.6, 'AutoCut > Fi'],
                      [1.7, 'AutoCut > F'],
                      [1.8, 'AutoCut > '],
                      [1.9, 'AutoCut '],
                      [2, 'AutoCut'],
                      [2.1, 'AUTOCUT'],
                    ],
                    TEXT_COLOR: [
                      [0, '#ffffff'],
                      [2, '#e65019'],
                    ],
                    TEXT_HIGHLIGHT: [
                      [0, [0, 4]],
                      [0.1, [4, 7]],
                      [0.2, [0, 4]],
                      [0.3, [4, 7]],
                      [0.4, [0, 4]],
                      [0.5, [4, 7]],
                      [0.6, [0, 4]],
                      [0.7, [4, 7]],
                      [0.8, [0, 4]],
                      [0.9, [4, 7]],
                      [1.0, [0, 4]],
                      [1.1, [4, 7]],
                      [1.2, [0, 4]],
                      [1.3, [4, 7]],
                      [1.4, [0, 4]],
                      [1.5, [4, 7]],
                      [1.6, [0, 4]],
                      [1.7, [4, 7]],
                      [1.8, [0, 4]],
                      [1.9, [4, 7]],
                    ],
                    TEXT_HIGHLIGHT_COLOR: [[0, '#e65019']],
                    TEXT_FONT_SIZE: [
                      [0, 104],
                      [2.1, 208],
                    ],
                    TEXT_FONT: [[0, 'Arial']],
                    TEXT_BOLD: [[0, true]],
                    TEXT_ITALIC: [[0, false]],
                    BORDER_COLOR: [[0, '#000000']],
                    BORDER_WIDTH: [[0, 10]],
                    POS_X: [[0, 960]],
                    POS_Y: [[0, 1000]],
                    ANGLE: [[0, 0]],
                    OPACITY: [[0, 1000]],
                    LETTER_SPACING: [[0, 0]],
                  }
                );
              }}
            >
              Import MoGRT
            </Button>
          </>
        ) : (
          <>You are not in a CEP environment</>
        )}
      </div>
      {message && (
        <div className={css.message}>
          <pre dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      )}
    </>
  );
};

export default CEPScriptLauncher;

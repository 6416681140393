export enum StatCategory {
  UNSUPPORTED_SCENARIOS = 'unsupported_scenarios',
  KEYS = 'keys',
  ERROR = 'error',
}

export enum StatMode {
  LEGACY = 'legacy',
  AUTOZOOM = 'autozoom',
  IA = 'ia',
  PODCAST = 'podcast',
  REPEAT = 'repeat',
  CAPTIONS = 'captions',
  RESIZE = 'resize',
  BROLL = 'broll',
  CHAPTERS = 'chapters',
  SWEAR_WORD = 'swear_word',
  VIRALCLIPS = 'viral_clips',
}

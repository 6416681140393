import * as React from 'react';
import { useEffect, useState } from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import './promoBanner.css';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { useLogger } from '@autocut/hooks/useLogger';
import { openLinkInBrowser } from '@autocut/lib/utils/bolt';
import logLevel from '@autocut/types/logLevel.enum';
import { StatCategory } from '@autocut/types/StatCategory.enum';
import { StatType } from '@autocut/types/StatType.enum';
import { sendStats } from '@autocut/utils';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { convertSecondsToHMS } from '@autocut/utils/date.utils';
import { getTrialPromoEnd } from '@autocut/utils/localStorage.utils';
import FlexContainer from '../../../designSystem/components/molecules/FlexContainer';
import { ClockCard } from '../ClockCard/ClockCard';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';

type Props = {
  setShowTrialPromo: React.Dispatch<React.SetStateAction<boolean>>;
};

const PromoBanner = ({ setShowTrialPromo }: Props) => {
  const licenceType = useAutoCutStore(state => state.user.license?.type);

  const [timeRemaining, setTimeRemaining] = useState<number>(-1);
  const [secondsRemaining, setSecondsRemaining] = useState<string>('0');
  const [minutesRemaining, setMinutesRemaining] = useState<string>('0');
  const [hoursRemaining, setHoursRemaining] = useState<string>('0');
  const { logMessage } = useLogger('PromoBanner');

  useEffect(() => {
    const tempTrialPromoEnd = getTrialPromoEnd();
    if (tempTrialPromoEnd) {
      setTimeRemaining((tempTrialPromoEnd - new Date().getTime()) / 1000);
    }
  }, []);

  useEffect(() => {
    if (timeRemaining > 0)
      setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
    if (Math.floor(timeRemaining) === 0) setShowTrialPromo(false);
  }, [timeRemaining]);

  useEffect(() => {
    const { hours, minutes, seconds } = convertSecondsToHMS(timeRemaining) as {
      hours: string;
      minutes: string;
      seconds: string;
    };

    setHoursRemaining(hours);
    setMinutesRemaining(minutes);
    setSecondsRemaining(seconds);
  }, [timeRemaining]);
  return licenceType === 'trial' ? (
    <div className="promo-banner">
      <FlexContainer justifyContent="space-between">
        <div>
          <FlexContainer
            className="promo-text-container"
            alignItems="center"
            flexDirection="column"
          >
            <div>
              <TranslatedMessage
                id="trial_promo_text"
                defaultMessage="Up to 20% OFF in the next 24 hours"
              />
            </div>
            <div
              className="promo-link"
              onClick={async () => {
                logMessage(logLevel.notice, 'User clicked on promo link');
                await sendStats({
                  type: StatType.TRIAL_PROMO_CLICKED,
                  value: 1,
                });
                openLinkInBrowser(
                  `${AUTOCUT_CONSTANTS[CURRENT_ENV].WEBSITE_ADDRESS}/?coupon=a3Z5Uumo72nw5cW#pricing`,
                  true
                );
              }}
            >
              <TranslatedMessage id="shop_now" defaultMessage="Shop now" />
            </div>
          </FlexContainer>
        </div>
        <div className="promo-clock">
          <ClockCard value={hoursRemaining} unit="h" />
          <ClockCard value={minutesRemaining} unit="m" />
          <ClockCard value={secondsRemaining} unit="s" />
        </div>
      </FlexContainer>
    </div>
  ) : null;
};

export { PromoBanner };

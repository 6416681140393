/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { Container, Engine } from 'tsparticles-engine';

import ConfettiOption from './ConfettiOptions/ConfettiOption';
import FireWorksOptions from './ConfettiOptions/FireWorksOption';

const ConfettiBackground = () => {
  const [options, setOptions] = useState<any>();

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {},
    []
  );

  useEffect(() => {
    setOptions(
      Math.floor(Math.random() * 2) === 0 ? FireWorksOptions : ConfettiOption
    );
  }, []);

  /**
   * TS says that Particles is not a valid component
   * but its render method return the ReactNode that we display
   */
  return (
    //@ts-ignore
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={options}
    />
  );
};

export default ConfettiBackground;

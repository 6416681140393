import ColorPicker from '@autocut/components/atoms/ColorPicker/ColorPicker';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import ParameterLayout from '@autocut/layouts/ParametersGroupLayout/ParameterLayout';
import { ParametersGroupLayout } from '@autocut/layouts/ParametersGroupLayout/ParametersGroupLayout';
import { HSLtoHex, rgbToHsl } from '@autocut/utils/color.utils';
import { setAutocutStore } from '@autocut/utils/zustand';
import React from 'react';
import {
  getDefaultTranslatedMessageValues,
  TranslatedMessage,
} from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { Switch } from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';

export const HighlightParameters = () => {
  const {
    enabled: highlightEnabled,
    color: highlightColor,
    revealText: { enabled: revealTextEnabled, color: unrevealedTextColor },
    revealedTextColor,
  } = useAutoCutStore(state => ({
    ...state.ui.parameters.caption.animations.highlight,
    revealedTextColor: state.ui.parameters.caption.text.color,
  }));
  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id="captions_customization_animation_color"
          defaultMessage="The <color>color</color>"
          valuePamareters={{
            color: HSLtoHex({
              ...rgbToHsl(highlightColor),
              l: Math.max(rgbToHsl(highlightColor).l, 0.3),
            }),
          }}
        />
      }
    >
      <Switch
        checked={highlightEnabled}
        onChange={(checked: boolean) =>
          setAutocutStore(
            'ui.parameters.caption.animations.highlight.enabled',
            checked
          )
        }
        size={16}
      />
      <ParametersGroupLayout status={highlightEnabled} hiddenContent={null}>
        <ParameterLayout
          title={
            <TranslatedMessage
              id="captions_customization_animation_color_text_color"
              defaultMessage="Color"
            />
          }
        >
          <ColorPicker
            value={highlightColor}
            onChange={color =>
              setAutocutStore(
                'ui.parameters.caption.animations.highlight.color',
                color
              )
            }
          />
        </ParameterLayout>

        <ParameterLayout
          title={
            <TranslatedMessage
              id="captions_customization_animation_advanced_color_options"
              defaultMessage="Advanced color options"
              valuePamareters={{
                color: HSLtoHex({
                  ...rgbToHsl(highlightColor),
                  l: Math.max(rgbToHsl(highlightColor).l, 0.3),
                }),
              }}
              values={{
                revealed: getDefaultTranslatedMessageValues({
                  color: HSLtoHex({
                    ...rgbToHsl(revealedTextColor),
                    l: Math.max(rgbToHsl(revealedTextColor).l, 0.3),
                  }),
                }).color,
                unrevealed: getDefaultTranslatedMessageValues({
                  color: HSLtoHex({
                    ...rgbToHsl(unrevealedTextColor),
                    l: Math.max(rgbToHsl(unrevealedTextColor).l, 0.3),
                  }),
                }).color,
              }}
            />
          }
        >
          <Switch
            checked={revealTextEnabled}
            onChange={(checked: boolean) =>
              setAutocutStore(
                'ui.parameters.caption.animations.highlight.revealText.enabled',
                checked
              )
            }
            size={16}
          />
        </ParameterLayout>

        <ParameterLayout>
          <ParametersGroupLayout
            status={revealTextEnabled}
            hiddenContent={null}
          >
            <ParameterLayout
              title={
                <FlexContainer>
                  <TranslatedMessage
                    id="captions_customization_animation_color_before_highlight_color"
                    defaultMessage="Color before highlight"
                  />
                </FlexContainer>
              }
            >
              <ColorPicker
                value={revealedTextColor}
                onChange={color =>
                  setAutocutStore('ui.parameters.caption.text.color', color)
                }
              />
            </ParameterLayout>
            <ParameterLayout
              title={
                <FlexContainer>
                  <TranslatedMessage
                    id="captions_customization_animation_color_after_highlight_color"
                    defaultMessage="Color after highlight"
                  />
                </FlexContainer>
              }
            >
              <ColorPicker
                value={unrevealedTextColor}
                onChange={color =>
                  setAutocutStore(
                    'ui.parameters.caption.animations.highlight.revealText.color',
                    color
                  )
                }
              />
            </ParameterLayout>
          </ParametersGroupLayout>
        </ParameterLayout>
      </ParametersGroupLayout>
    </ParameterLayout>
  );
};

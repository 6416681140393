import React from 'react';

import css from './CamerasStep.module.css';
import { StepLayout } from '@autocut/layouts/StepLayout';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Helper } from '@autocut/components/atoms/Helper/Helper';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';
import { setAutocutStore } from '@autocut/utils/zustand';
import { useLogger } from '@autocut/hooks/useLogger';
import logLevel from '@autocut/types/logLevel.enum';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { CameraComponent } from './Camera/Camera';
import { addCameraReducer } from '@autocut/utils/cameras/addCameraReducer';

export const CamerasStep = () => {
  const intl = useIntl();
  const { logMessage } = useLogger('SpeakersStep');

  const cameras = useAutoCutStore(state => state.ui.parameters.podcast.cameras);

  return (
    <StepLayout
      title={intl.formatMessage({
        id: 'text_podcast_camera_title',
        defaultMessage: 'Cameras',
      })}
      helperId="text_podcast_camera_helper"
      helperDefault="Add your cameras and specify wich speaker is visible on each camera. You can select any number of speakers per camera."
      alwaysOpen
    >
      <FlexContainer className={css.header} justifyContent="space-between">
        <FlexContainer gap={4} alignItems="center">
          <div>
            <TranslatedMessage
              id="header_cameras_name"
              defaultMessage="Video track of the camera"
            />
          </div>

          <Helper
            id_message="header_cameras_name_helper"
            default_message="Select the video track where the camera is, you can only select one track per camera."
            position="left"
            textStyle={{
              width: '200px',
            }}
          />
        </FlexContainer>
        <FlexContainer gap={4} alignItems="center">
          <div>
            <TranslatedMessage
              id="header_cameras_speakers"
              defaultMessage="Speaker(s) on camera"
            />
          </div>

          <Helper
            id_message="header_cameras_speakers_helper"
            default_message="Select the speakers that are visible on this camera, you can select any number of speakers."
            position="left"
            textStyle={{
              width: '200px',
            }}
          />
        </FlexContainer>
      </FlexContainer>
      <div className={css.container}>
        {cameras.map(camera => (
          <CameraComponent camera={camera} key={camera.id} />
        ))}
        <div
          id="podcast-step2"
          className={css.addSpeaker}
          onClick={() => {
            logMessage(logLevel.notice, 'Adding speaker.');
            setAutocutStore('ui.parameters.podcast.cameras', addCameraReducer);
          }}
        >
          <AiOutlinePlusCircle size={16} color="white" />
          <div>
            <TranslatedMessage
              id="text_podcast_add_camera"
              defaultMessage="Add a camera"
            />
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

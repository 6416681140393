import { SwearWord } from '@autocut/types/SwearWord';
import { Utterance } from '@autocut/types/Utterance';
import { autocutApi } from '../http.utils';

export const getSwearWordsTimeCodes = async (transcript: Utterance[]) => {
  const { data } = await autocutApi.post('/deepgram/curse-words', {
    transcript,
  });

  return data as SwearWord[];
};

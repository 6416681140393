import React, { CSSProperties } from 'react';

import { TranslatedMessage } from '@autocut/components/atoms/TranslatedMessage/TranslatedMessage';

import './checkPill.css';

type Props = {
  id: string;
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
  hideLabel?: boolean;
  size?: CSSProperties['fontSize'];
  style?: CSSProperties;
};

export const CheckPill = ({
  id,
  isSelected,
  setIsSelected,
  hideLabel,
  size,
  style,
}: Props) => {
  return (
    <div
      className="pill-container"
      style={{ ...style, '--pill-size': size } as CSSProperties}
    >
      {!hideLabel && <TranslatedMessage id="text_no" defaultMessage="No" />}
      <div
        className="toggle-pill-bw"
        onClick={() => {
          setIsSelected(!isSelected);
        }}
      >
        <input type="checkbox" id={id} checked={isSelected} readOnly />
        <label htmlFor={id} onClick={e => e.stopPropagation()}></label>
      </div>
      {!hideLabel && <TranslatedMessage id="text_yes" defaultMessage="Yes" />}
    </div>
  );
};

import { StepType } from '@reactour/tour';

export const legacySteps: StepType[] = [
  {
    selector: '#legacy-step12',
    content: {
      title: 'onboarding_legacy_step1_title',
      body: 'onboarding_legacy_step1_body',
    } as any,
  },
  {
    selector: '#legacy-step12',
    content: {
      title: 'onboarding_legacy_step2_title',
      body: 'onboarding_legacy_step2_body',
    } as any,
  },
  {
    selector: '#legacy-step3',
    content: {
      title: 'onboarding_legacy_step3_title',
      body: 'onboarding_legacy_step3_body',
    } as any,
    actionAfter: () =>
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
      }),
  },
  {
    selector: '#legacy-step4',
    content: {
      title: 'onboarding_legacy_step4_title',
      body: 'onboarding_legacy_step4_body',
    } as any,
    position: 'top',
  },
  {
    selector: '#legacy-step6-podcast-step7',
    content: {
      title: 'onboarding_legacy_step6_title',
      body: 'onboarding_legacy_step6_body',
    } as any,
    position: 'top',
  },
  {
    selector: '#legacy-step7',
    content: {
      title: 'onboarding_legacy_step7_title',
      body: 'onboarding_legacy_step7_body',
    } as any,
    position: 'center',
  },
  {
    selector: '#legacy-step8',
    content: {
      title: 'onboarding_legacy_step8_title',
      body: 'onboarding_legacy_step8_body',
    } as any,
    position: 'top',
  },
];

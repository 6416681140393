import { XMLDocument } from './XMLDocument';
import { XMLVideoMediaSource } from './XMLMediaSource';

export class XMLVideoClip {
  public objectID: string;
  private element: Element;
  private document: XMLDocument;
  public markers: Element;
  public source: XMLVideoMediaSource;

  constructor({
    element,
    document,
    markers,
    mediaSource,
  }: {
    element: Element;
    document: XMLDocument;
    markers?: Element;
    mediaSource?: XMLVideoMediaSource;
  }) {
    this.element = element;
    this.document = document;

    this.objectID = element.getAttribute('ObjectID') ?? '';

    this.markers = markers ?? this.getMarkers()!;

    this.source = mediaSource ?? this.getSource()!;
  }

  public clone({
    newObjectID,
    newSource,
  }: {
    newObjectID?: string;
    newSource?: XMLVideoMediaSource;
  }) {
    const newVideoClip = this.element.cloneNodeWithNewId(
      newObjectID ?? this.document.getNewObjectId()
    );
    const newMarkersId = this.document.getNewObjectId();
    const newMarkersRef = newVideoClip.querySelector('Markers')!;
    newMarkersRef.setAttribute('ObjectRef', newMarkersId);
    const newMarkers = this.markers.cloneNodeWithNewId(newMarkersId);

    const newXmlVideoClip = new XMLVideoClip({
      element: newVideoClip,
      document: this.document,
      markers: newMarkers,
      mediaSource: this.source,
    });

    if (newSource) newXmlVideoClip.changeSource(newSource);

    return newXmlVideoClip;
  }

  public changeSource(newSource: XMLVideoMediaSource) {
    const newSourceId = newSource.objectID;
    const sourceRef = this.element.querySelector('Source')!;
    sourceRef.setAttribute('ObjectRef', newSourceId);

    const newMetadataId = newSource.metadataId;
    this.markers.querySelector('LastMetadataState')!.textContent =
      newMetadataId;
    this.markers.querySelector('LastContentState')!.textContent = newMetadataId;
  }

  getMarkers = () => {
    const markersRef = this.element.querySelector('Markers');
    const markersId = markersRef?.getAttribute('ObjectRef') ?? '';

    return this.document.findElementWithObjectId(['Markers'], markersId);
  };

  getSource = () => {
    const sourceRef = this.element.querySelector('Source');
    const sourceId = sourceRef?.getAttribute('ObjectRef') ?? '';

    const videoMediaSource = this.document.findElementWithObjectId(
      ['VideoMediaSource'],
      sourceId
    )!;

    return new XMLVideoMediaSource({
      element: videoMediaSource,
      document: this.document,
    });
  };
}

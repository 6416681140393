import { Button } from '@autocut/components/atoms/Buttons/Button';
import { AUTOCUT_CONSTANTS } from '@autocut/constants/config';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { autocutApi, isKeyValid } from '@autocut/utils';
import { CURRENT_ENV } from '@autocut/utils/currentEnv.utils';
import { initAfterLogged } from '@autocut/utils/init';
import { login } from '@autocut/utils/license/login';
import * as React from 'react';
import { useState } from 'react';
import css from './UpdateApiUrl.module.css';

export const UpdateApiUrl = () => {
  const { key } = useAutoCutStore(state => state.user);
  const [newApiUrl, setNewApiUrl] = useState('');
  const [resultMessage, setResultMessage] = useState('');

  const updateApiUrl = async (apiUrl: string) => {
    autocutApi.defaults.baseURL = apiUrl;
    if (key) {
      const keyCheckResponse = await isKeyValid(key);

      if (keyCheckResponse.result) {
        const license = {
          ...keyCheckResponse.licence,
          key,
        };
        await login({
          ...keyCheckResponse.accessTokens,
          key,
          license,
          additionalInformations: keyCheckResponse.additionalInformations,
        });

        await initAfterLogged().catch(e => {
          setResultMessage('Failed to update URL');
          throw e;
        });

        setResultMessage('URL updated successfully');
      }
    }
  };

  return (
    <div className={css.container}>
      <div className={css.inputContainer}>
        <input value={newApiUrl} onChange={e => setNewApiUrl(e.target.value)} />
        <Button size="sm" onClickFunction={() => updateApiUrl(newApiUrl)}>
          Update API URL
        </Button>
      </div>
      <Button
        size="sm"
        onClickFunction={() =>
          updateApiUrl(AUTOCUT_CONSTANTS[CURRENT_ENV].SERVER_ADDRESS)
        }
      >
        Default API URL
      </Button>
      {resultMessage}
    </div>
  );
};

import React, { ReactNode } from 'react';
import { Text } from '../../Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { IconXClose } from '../../Icon/general/IconXClose';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';

import css from './Item.module.scss';

export type ItemProps<T> = {
  children: ReactNode;
  isBadge: T;
  onDelete?: T extends true ? () => void : undefined;
  isPlaceholder?: boolean;
};

export const Item = <T extends boolean>({
  children,
  isBadge,
  onDelete,
  isPlaceholder = false,
}: ItemProps<T>) => {
  if (!isBadge) {
    return (
      <Text
        className={`${css.text} ${isPlaceholder ? css.isPlaceholder : ''}`}
        variant="textSm"
        color="white"
      >
        {children}
      </Text>
    );
  }

  return (
    <FlexContainer className={css.badge} gap={Spacing.s2} alignItems="center">
      <Text variant="textXs" color="white">
        {children}
      </Text>
      <IconXClose
        className={css.icon}
        onClick={event => {
          event.stopPropagation();
          onDelete && onDelete();
        }}
        size={14}
      />
    </FlexContainer>
  );
};

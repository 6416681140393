import * as React from 'react';

import css from './ContextualMenuItem.module.css';

export type ContextualMenuItemProps = {
  label: string;
  onClick: (event: any) => void;
};

export const ContextualMenuItem = ({
  label,
  onClick,
}: ContextualMenuItemProps) => {
  return (
    <div className={css.itemContainer} onClick={onClick}>
      {label}
    </div>
  );
};

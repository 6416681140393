export const SWEAR_WORDS_AVAILABLE_LANGUAGES = [
  'pl',
  'sv',
  'da',
  'no',
  'ja',
  'it',
  'pt',
  'pt-BR',
  'nl',
  'hi',
  'de',
  'ko',
  'fr',
  'es',
  'es-419',
  'en',
  'en-US',
  'ta',
];

import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import AdobeAppInfo from '@autocut/components/devtool/AdobeAppInfo';
import CEPScriptLauncher from '@autocut/components/devtool/CEPScriptLauncher/CEPScriptLauncher';
import LogInfo from '@autocut/components/devtool/LogInfo/LogInfo';
import SentryTest from '@autocut/components/devtool/SentryTest/SentryTest';
import StorageManager from '@autocut/components/devtool/StorageManager/StorageManager';
import { UpdateApiUrl } from '@autocut/components/devtool/UpdateApiUrl/UpdateApiUrl';
import { useAutoCutStore } from '@autocut/hooks/useAutoCutStore';
import { StepLayout } from '@autocut/layouts/StepLayout';
import { CURRENT_ENV, Env } from '@autocut/utils/currentEnv.utils';
import { safeJSONStringify } from '@autocut/utils/logger';
import { AutoCutState, autocutStoreVanilla } from '@autocut/utils/zustand';
import css from './DevelopmentDashboard.module.css';
import FontMesure from '@autocut/components/devtool/FontMesure/FontMesure';
import { deleteUnusedPresets } from '@autocut/utils/captionsPresets/deleteUnusedPresets';
import { Button } from '@autocut/designSystem/components/atoms/Button/Button';
import { colors } from '@autocut/designSystem/colors';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import { Spacing } from '@autocut/designSystem/enums/spacing.enum';

const DevelopmentDashboard = () => {
  const navigate = useNavigate();

  const store = useAutoCutStore(state => state);

  useEffect(() => {
    if (CURRENT_ENV === Env.Production) {
      navigate('/');
    }
  }, []);

  return (
    <div className={css.container}>
      <FlexContainer flexDirection="column" gap={Spacing.s2}>
        <Button
          color={colors.primary600}
          onClick={async () => {
            await deleteUnusedPresets(true);
          }}
        >
          Custom test button
        </Button>

        <Button
          color={colors.primary600}
          onClick={() => {
            navigate('/ds');
          }}
        >
          DesignSystem example page
        </Button>
      </FlexContainer>

      <StepLayout title={'Adobe App Info'} alwaysOpen>
        <AdobeAppInfo />
      </StepLayout>

      <StepLayout title={'Log Info'}>
        <LogInfo />
      </StepLayout>

      <StepLayout title={'Storage Manager'}>
        <StorageManager />
      </StepLayout>

      <StepLayout title={'Sentry test'}>
        <SentryTest />
      </StepLayout>

      <StepLayout title={'Text mesure'} alwaysOpen>
        <FontMesure />
      </StepLayout>

      <StepLayout title={'CEP script launcher'}>
        <CEPScriptLauncher />
      </StepLayout>

      <StepLayout title={'Update API URL'}>
        <UpdateApiUrl />
      </StepLayout>

      <StepLayout title={'Zustand State'}>
        <Button
          color={colors.primary600}
          onClick={() => console.log(autocutStoreVanilla())}
        >
          Display zustand state in console
        </Button>
        {Object.keys(store).map(key => (
          <details key={key}>
            <summary>{key}</summary>
            <ObjectExplorer object={store[key as keyof AutoCutState]} />
          </details>
        ))}
      </StepLayout>

      <Button
        color={colors.primary600}
        onClick={() => {
          navigate('/');
        }}
      >
        Go to Extension OnBoarding Page
      </Button>
    </div>
  );
};

const MAX_DEPTH = 2;
const ObjectExplorer = ({ object, depth }: { object: any; depth?: number }) => {
  return (
    <>
      {!object ? (
        <pre>{`\t${object}`}</pre>
      ) : (
        <div style={{ paddingLeft: '16px', background: 'rgba(255,0,0,0.1)' }}>
          {Object.keys(object).map(key =>
            Array.isArray(object[key]) ? (
              <details key={key}>
                <summary>
                  <b>{key}</b> — Array ({object[key].length})
                </summary>

                {object[key].map((obj: any, index: number) => (
                  <details key={key + index}>
                    <summary>{index}</summary>
                    <ObjectExplorer key={index} object={obj} />
                  </details>
                ))}
              </details>
            ) : typeof object[key] === 'object' && (depth || 0) < MAX_DEPTH ? (
              !object[key] ? (
                <pre key={key}>
                  <b>{key}</b> — <i>{typeof object[key]}</i>
                  {'\n\t'}
                  {safeJSONStringify(object[key], 2)}
                </pre>
              ) : (
                <details key={key}>
                  <summary>
                    <b>{key}</b> —{' '}
                    {Array.isArray(object[key]) ? 'array' : typeof object}
                  </summary>
                  <ObjectExplorer
                    key={key}
                    object={object[key]}
                    depth={(depth || 0) + 1}
                  />
                </details>
              )
            ) : (
              <pre style={{ width: '100%', whiteSpace: 'pre-wrap' }}>
                <b>{key}</b> — <i>{typeof object[key]}</i>
                {'\n\t'}
                {safeJSONStringify(object[key], 2)}
              </pre>
            )
          )}
        </div>
      )}
    </>
  );
};

export default DevelopmentDashboard;

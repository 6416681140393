import { RgbColor } from 'react-colorful';
import { rgbToInteger } from '../color.utils';
import { CanvasFontParams, measureTextOnCanvas } from './canvas/canvas.utils';
import { autocutStoreVanilla } from '../zustand';

export const getHighlightPosition = async (
  highlightLine: string,
  highlightWord: string,
  lineBeforeWord: string,
  indexLine: number,
  nbLines: number,
  properties: {
    fontParameters: CanvasFontParams;
    uppercase: boolean;
  }
) => {
  const line = properties.uppercase
    ? highlightLine.toUpperCase()
    : highlightLine;
  const word = properties.uppercase
    ? highlightWord.toUpperCase()
    : highlightWord;

  const { metrics: lineMeasure } = measureTextOnCanvas(
    line,
    properties.fontParameters,
    properties.uppercase
  );

  const { metrics: lineBeforeWordMeasure } = measureTextOnCanvas(
    lineBeforeWord,
    properties.fontParameters,
    properties.uppercase
  );

  const { metrics: wordMeasure } = measureTextOnCanvas(
    word,
    properties.fontParameters,
    properties.uppercase
  );

  let xPosition =
    (lineMeasure.width - wordMeasure.width) / 2 - lineBeforeWordMeasure.width;
  const textDirection =
    autocutStoreVanilla().ui.parameters.caption.languageOfTranscription
      .textDirection;
  if (textDirection === 'rtl') {
    xPosition = 1 - xPosition;
  }

  const lineHeight = (120 * properties.fontParameters.fontSize) / 100;
  const yPosition = -lineHeight * (1 / 2 + indexLine);

  return {
    xPosition,
    yPosition,
  };
};

export const getHighlightColor = ({
  highlightColor,
  textColor,
  unrevealedTextColor,
  indexStart,
  indexEnd,
}: {
  highlightColor: RgbColor;
  textColor: RgbColor;
  unrevealedTextColor?: RgbColor;
  indexStart: number;
  indexEnd: number;
}) => {
  const colors: [number, number][] =
    indexStart === 0 ? [] : [[0, rgbToInteger(textColor)]];
  colors.push([indexStart, rgbToInteger(highlightColor)]);
  colors.push([indexEnd + 1, rgbToInteger(unrevealedTextColor ?? textColor)]);

  return colors;
};

import React from 'react';

import { IconComponent, IconComponentProps } from '../Icon';

export const IconXClose = (props: IconComponentProps) => {
  return (
    <IconComponent {...props} viewBox="0 0 24 24" fill="none">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 6 6 18M6 6l12 12"
      />
    </IconComponent>
  );
};

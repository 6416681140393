import { ExportResult } from '@autocut/types/ExportedAudio';
import { getExportedAudioInfos } from '../export/getExportedAudioInfos';
import { range } from '../math.utils';
import { getParametersForMode } from '../parameters.utils';
import { getVideoClips } from '../timeline/selectedInfos.utils';
import { ZoomInterval } from './getZoomIntervals';

export const getZoomCoverage = async (zoomIntervals: ZoomInterval[]) => {
  const exportedAudioSequenceInfos =
    (await getExportedAudioInfos()) as ExportResult<'selected'>;
  const zoomCoverageDuration = zoomIntervals.reduce<number>(
    (currentDuration, zoomInterval) =>
      currentDuration + zoomInterval.end - zoomInterval.start,
    0
  );
  const audioDuration = exportedAudioSequenceInfos.sequence.reduce(
    (duration, currentAudio) => {
      return duration + currentAudio.end - currentAudio.start;
    },
    0
  );
  const zoomCoveragePercentage = zoomCoverageDuration / audioDuration;

  return zoomCoveragePercentage;
};

const MIN_NOT_ZOOM_DURATION = 2; // in seconds
export const stitchZoomIntervals = (zoomIntervals: ZoomInterval[]) => {
  const selectedVideoClips = getVideoClips();
  const startEndPoints = selectedVideoClips
    .flatMap(clip => [clip.start, clip.end])
    .sort((a, b) => a - b);
  const uniqueStartEndPoints = [...new Set(startEndPoints)];
  const newZoomIntervalsArray = [...zoomIntervals];

  for (let i = 0; i < newZoomIntervalsArray.length - 1; i++) {
    const intervalA = newZoomIntervalsArray[i];
    const intervalB = newZoomIntervalsArray[i + 1];

    const gap = intervalB.start - intervalA.end;
    if (gap < 0) continue;

    if (gap < MIN_NOT_ZOOM_DURATION) {
      const isIntervalAEndAtClipLimit = uniqueStartEndPoints.some(
        point => point === intervalA.end
      );
      const isIntervalBStartAtClipLimit = uniqueStartEndPoints.some(
        point => point === intervalB.start
      );
      if (isIntervalAEndAtClipLimit || isIntervalBStartAtClipLimit) continue;

      const stitchAmount = gap / 2;
      intervalA.end += stitchAmount;
      intervalB.start -= stitchAmount;
    }
  }

  return newZoomIntervalsArray;
};

export const stretchZoomIntervals = (zoomIntervals: ZoomInterval[]) => {
  const selectedVideoClips = getVideoClips();
  const startEndPoints = selectedVideoClips
    .flatMap(clip => [clip.start, clip.end])
    .sort((a, b) => a - b);
  const uniqueStartEndPoints = [...new Set(startEndPoints)];
  const newZoomIntervalsArray = [...zoomIntervals];

  // We're iterating once left to right and stretching the start of zoom intervals and then
  // right to left and stretching the end of zoom intervals to avoid overwriting a small interval
  // right before the start/end of a video interval

  // Exemple : For one video interval, there is 3 zoom intervals, Z1, Z2 & Z3
  // Z1 and Z3 are small
  // We're firt stretching Z1 to the start of the video interval,
  // and then Z3 to the end of the video interval
  // Z2 is untouched because it is in the middle
  // That way there is no risk of Z2 overwriting neither Z1 nor Z3
  for (const interval of newZoomIntervalsArray) {
    const nearestLeftPoint = uniqueStartEndPoints.reduce(
      (currentNearest, currentPoint) =>
        currentPoint > currentNearest && currentPoint < interval.start
          ? currentPoint
          : currentNearest,
      0
    );
    const isPointAlreadyZoomed = newZoomIntervalsArray.some(
      interval => interval.start === nearestLeftPoint
    );

    if (
      !isPointAlreadyZoomed &&
      interval.start - nearestLeftPoint < MIN_NOT_ZOOM_DURATION / 2
    ) {
      interval.start = nearestLeftPoint;
    }
  }

  for (
    let iterArray = newZoomIntervalsArray.length - 1;
    iterArray >= 0;
    iterArray--
  ) {
    const interval = newZoomIntervalsArray[iterArray];
    const nearestRightPoint = uniqueStartEndPoints.reduce(
      (currentNearest, currentPoint) =>
        currentPoint < currentNearest && currentPoint > interval.end
          ? currentPoint
          : currentNearest,
      Infinity
    );
    const isPointAlreadyZoomed = newZoomIntervalsArray.some(
      interval => interval.end === nearestRightPoint
    );

    if (
      !isPointAlreadyZoomed &&
      nearestRightPoint - interval.end < MIN_NOT_ZOOM_DURATION / 2
    ) {
      interval.end = nearestRightPoint;
    }
  }

  return newZoomIntervalsArray;
};

export const addAnchorAndZoomCoef = (
  zoomIntervals: ZoomInterval[],
  zoomMinCoef: number,
  zoomMaxCoef: number,
  smoothZoomSpeed = 0
) => {
  const {
    anchorPosMaxCoef,
    anchorPosMinCoef,
    autoZoomMaxCoef: upperLimitZoomMaxCoef,
  } = getParametersForMode('zoom');
  const { min, max } = calculateMinMaxMeanRms(zoomIntervals);

  for (const interval of zoomIntervals) {
    const meanRms = calculateMeanRms(interval);
    const toZoomCoef = range(min, max, zoomMinCoef, zoomMaxCoef, meanRms);

    let zoomAnchor;
    if (interval.isSmooth) {
      const [fromZoomCoef, toZoomAnchor, fromZoomAnchor] =
        calculateSmoothZoomCoefAnchor(
          interval,
          smoothZoomSpeed,
          toZoomCoef,
          upperLimitZoomMaxCoef,
          anchorPosMaxCoef
        );
      interval.toZoomCoef = toZoomCoef;
      interval.fromZoomCoef = fromZoomCoef;
      interval.fromZoomAnchor = fromZoomAnchor;
      interval.toZoomAnchor = toZoomAnchor;
    } else {
      zoomAnchor = getZoomAnchor(
        anchorPosMinCoef,
        anchorPosMaxCoef,
        zoomMinCoef,
        zoomMaxCoef,
        toZoomCoef
      );
      interval.fromZoomAnchor = interval.toZoomAnchor = zoomAnchor;
      interval.toZoomCoef = interval.fromZoomCoef = toZoomCoef;
    }
  }
};

export const addZoomCoef = (
  zoomIntervals: ZoomInterval[],
  zoomMinCoef: number,
  zoomMaxCoef: number,
  smoothZoomSpeed = 0
) => {
  const { min, max } = calculateMinMaxMeanRms(zoomIntervals);

  for (const interval of zoomIntervals) {
    const meanRms = calculateMeanRms(interval);
    const toZoomCoef = range(min, max, zoomMinCoef, zoomMaxCoef, meanRms);
    const fromZoomCoef = interval.isSmooth
      ? Math.max(
          1,
          toZoomCoef - smoothZoomSpeed * (interval.end - interval.start)
        )
      : toZoomCoef;
    interval.fromZoomCoef = fromZoomCoef;
    interval.toZoomCoef = toZoomCoef;
  }
};

const calculateMeanRms = (interval: ZoomInterval) => {
  return (
    interval.rmsArray.reduce((acc, curr) => acc + curr, 0) /
    interval.rmsArray.length
  );
};

const calculateMinMaxMeanRms = (zoomIntervals: ZoomInterval[]) => {
  return zoomIntervals.map(calculateMeanRms).reduce(
    (acc, val) => ({
      min: Math.min(acc.min, val),
      max: Math.max(acc.max, val),
    }),
    { min: Infinity, max: 0 }
  );
};

const calculateSmoothZoomCoefAnchor = (
  interval: ZoomInterval,
  smoothZoomSpeed: number,
  toZoomCoef: number,
  upperLimitZoomMaxCoef: number,
  anchorPosMaxCoef: any
) => {
  const newXAnchor = range(
    1,
    upperLimitZoomMaxCoef,
    0.5,
    anchorPosMaxCoef.xPercentage,
    toZoomCoef
  );
  const newYAnchor = range(
    1,
    upperLimitZoomMaxCoef,
    0.5,
    anchorPosMaxCoef.yPercentage,
    toZoomCoef
  );
  const intervalDuration = interval.end - interval.start;
  const fromZoomCoef = Math.max(
    1,
    toZoomCoef - smoothZoomSpeed * intervalDuration
  );
  const fromZoomAnchor = getZoomAnchor(
    { xPercentage: 0.5, yPercentage: 0.5 },
    { xPercentage: newXAnchor, yPercentage: newYAnchor },
    1,
    toZoomCoef,
    fromZoomCoef
  );
  const toZoomAnchor = getZoomAnchor(
    { xPercentage: 0.5, yPercentage: 0.5 },
    { xPercentage: newXAnchor, yPercentage: newYAnchor },
    1,
    toZoomCoef,
    toZoomCoef
  );

  return [fromZoomCoef, toZoomAnchor, fromZoomAnchor] as const;
};

export const getZoomAnchor = (
  anchorPosMinCoef: { xPercentage: number; yPercentage: number },
  anchorPosMaxCoef: { xPercentage: number; yPercentage: number },
  minCoef: number,
  maxCoef: number,
  zoomCoef: number
) => {
  const xPosZoomCoefAnchor = range(
    minCoef,
    maxCoef,
    anchorPosMinCoef.xPercentage,
    anchorPosMaxCoef.xPercentage,
    zoomCoef
  );
  const yPosZoomCoefAnchor = range(
    minCoef,
    maxCoef,
    anchorPosMinCoef.yPercentage,
    anchorPosMaxCoef.yPercentage,
    zoomCoef
  );

  return { xPercentage: xPosZoomCoefAnchor, yPercentage: yPosZoomCoefAnchor };
};

import { z as zod } from 'zod';
import { getParametersForMode } from '../parameters.utils';

import { parametersValidationSchema } from '@autocut/validationSchemas/parameters.validationSchema';
import { IncrementalError } from '../errors/IncrementalError';

const removeTalksShorterThan = (
  timeline: number[],
  minDuration: number,
  precision: number
): number[] => {
  for (let i = 0; i < timeline.length; i++) {
    let currentChunkLength = 0;
    while (timeline[i + currentChunkLength] === 1) {
      currentChunkLength++;
    }

    if (currentChunkLength < minDuration / precision) {
      for (let j = 0; j < currentChunkLength; j++) {
        timeline[i + j] = 0;
      }
    }

    i = i + currentChunkLength;
  }
  return timeline;
};

const removeSilencesShorterThan = (
  timeline: number[],
  minDuration: number,
  precision: number
) => {
  for (let i = 0; i < timeline.length; i++) {
    let currentChunkLength = 0;
    while (timeline[i + currentChunkLength] === 0) {
      currentChunkLength++;
    }

    if (currentChunkLength < minDuration / precision) {
      for (let j = 0; j < currentChunkLength; j++) {
        timeline[i + j] = 1;
      }
    }

    i = i + currentChunkLength;
  }
  return timeline;
};

const applyMargins = (
  timeline: number[],
  marginBefore: number,
  marginAfter: number
): number[] => {
  let previous = timeline[0];

  for (let i = 0; i < timeline.length; i++) {
    if (timeline[i] === 1 && previous === 0) {
      for (let j = 1; j <= marginBefore; j++) {
        if (i - j >= 0) {
          timeline[i - j] = 1;
        }
      }

      previous = 1;
      continue;
    }

    if (timeline[i] === 0 && previous === 1) {
      for (let j = 0; j < marginAfter; j++) {
        if (i + j < timeline.length) {
          timeline[i + j] = 1;
        }
      }

      previous = 0;
      continue;
    }

    previous = timeline[i];
  }
  return timeline;
};

export const getCleanTimeline = (timeline: number[]) => {
  try {
    const { precision } = getParametersForMode('podcast');
    const { minTimeOfSilence, minTimeOfTalk, marginBefore, marginAfter } =
      getParametersForMode('ai') as any as zod.infer<
        (typeof parametersValidationSchema)['silence']
      >;

    let cleanTimeline = removeTalksShorterThan(
      timeline,
      minTimeOfTalk,
      precision
    );
    cleanTimeline = removeSilencesShorterThan(
      cleanTimeline,
      minTimeOfSilence,
      precision
    );
    cleanTimeline = applyMargins(cleanTimeline, marginBefore, marginAfter);
    cleanTimeline = removeSilencesShorterThan(
      cleanTimeline,
      minTimeOfSilence,
      precision
    );

    return cleanTimeline;
  } catch (err: any) {
    throw new IncrementalError(err, 'getCleanTimeline');
  }
};

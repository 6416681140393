import { Sentence } from '@autocut/types/Deepgram';
import { addBreadcrumb } from '@sentry/react';

export const processViralClips = (
  openAiResponse: ViralClipsOpenAiResponse,
  sentences: Sentence[],
  offset = 0
): ViralClip[] => {
  addBreadcrumb({
    category: 'processViralClips',
    data: {
      stringifiedObject: `openAiResponse : ${JSON.stringify(openAiResponse)}`,
    },
    level: 'debug',
  });
  const viralClips = (
    openAiResponse
      .map(openAiClip => {
        const startSentenceIndex = parseInt(openAiClip.first_sentence.id);
        const endSentenceIndex = parseInt(openAiClip.last_sentence.id);

        const startSentence = sentences[startSentenceIndex];
        const endSentence = sentences[endSentenceIndex];

        if (!startSentence || !endSentence) return null;

        const viralClip: ViralClip = {
          title: openAiClip.title,
          text: openAiClip.text,
          start: Math.floor(startSentence.start + offset),
          end: Math.ceil(endSentence.end + offset),
          score: parseInt(openAiClip.score),
          reason: openAiClip.reason,
          selected: true,
        };

        return viralClip;
      })
      .filter(Boolean) as ViralClip[]
  ).sort((a: ViralClip, b: ViralClip) => b.score - a.score) as ViralClip[];

  return viralClips;
};

export const MARKER_COLORS = {
  0: '#718637',
  1: '#D22C36',
  2: '#AF8BB1',
  3: '#E96F24',
  4: '#D0A12B',
  5: '#FFFFFF',
  6: '#428DFC',
  7: '#19F4D6',
};
